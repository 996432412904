import { call, put, takeLatest } from 'redux-saga/effects'

import {
  BRAND_CATEGORIES_LOADING,
  BRAND_CATEGORIES_GET_LIST,
  BRAND_CATEGORIES_GET_LIST_SUCCESS,
  BRAND_CATEGORIES_GET_LIST_FAILURE,
  BRAND_PRODUCT_TYPE_LOADING,
  BRAND_PRODUCT_TYPE_GET_LIST,
  BRAND_PRODUCT_TYPE_GET_LIST_SUCCESS,
  BRAND_PRODUCT_TYPE_GET_LIST_FAILURE,
  BRAND_CATALOUGE_LOADING,
  BRAND_CATALOUGE_GET_LIST,
  BRAND_CATALOUGE_GET_LIST_FAILURE,
  BRAND_CATALOUGE_GET_LIST_SUCCESS
} from '../actions'

import {
  API_GET_BRANDS_MANAGEMENT_CATEGORIES, API_GET_BRANDS_MANAGEMENT_PRODUCT_TYPE, API_GET_BRANDS_MANAGEMENT_CATALOUGE
} from '../constants'

import { BrandManagementPostApi } from '../utilities/axios'

function * onGetBrandCategoryList (action) {
  yield put({ type: BRAND_CATEGORIES_LOADING, isLoading: true })
  try {
    const query = {
      offset: action.payload?.offset || 0,
      limit: action.payload?.limit || 20
    }
    const response = yield call(BrandManagementPostApi, API_GET_BRANDS_MANAGEMENT_CATEGORIES(action.payload.brandId), query)
    yield put({ type: BRAND_CATEGORIES_GET_LIST_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    yield put({ type: BRAND_CATEGORIES_GET_LIST_FAILURE, message: e.message })
    // This is temporary as BE is returning 404 error
    // yield put({ type: BRAND_CATEGORIES_GET_LIST_SUCCESS, data: {} })
  }
  yield put({ type: BRAND_CATEGORIES_LOADING, isLoading: false })
}

function * onGetBrandProductTypeList (action) {
  yield put({ type: BRAND_PRODUCT_TYPE_LOADING, isLoading: true })
  try {
    const query = {
      offset: action.payload?.offset || 0,
      limit: action.payload?.limit || 20
    }
    const response = yield call(BrandManagementPostApi, API_GET_BRANDS_MANAGEMENT_PRODUCT_TYPE(action.payload.brandId), query)
    yield put({ type: BRAND_PRODUCT_TYPE_GET_LIST_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    yield put({ type: BRAND_PRODUCT_TYPE_GET_LIST_FAILURE, message: e.message })
    // This is temporary as BE is returning 404 error
    // yield put({ type: BRAND_CATEGORIES_GET_LIST_SUCCESS, data: {} })
  }
  yield put({ type: BRAND_CATEGORIES_LOADING, isLoading: false })
}

function * onGetBrandCatalougeList (action) {
  yield put({ type: BRAND_CATALOUGE_LOADING, isLoading: true })
  try {
    const query = {
      offset: action.payload?.offset || 0,
      limit: action.payload?.limit || 20,
      category: action.payload?.category || [],
      productType: action.payload?.productType || [],
      skuOrNameSearch: action.payload?.skuOrNameSearch || ''
    }

    if (action.payload.sort) {
      query.sort = action.payload.sort
      query.sortOrder = action.payload.sortOrder
    }

    const response = yield call(BrandManagementPostApi, API_GET_BRANDS_MANAGEMENT_CATALOUGE(action.payload.brandId), query)
    yield put({ type: BRAND_CATALOUGE_GET_LIST_SUCCESS, data: response?.data?.data || {}, total: response?.data?.total || 0 })
  } catch (e) {
    yield put({ type: BRAND_CATALOUGE_GET_LIST_FAILURE, message: e.message })
  }
  yield put({ type: BRAND_CATALOUGE_LOADING, isLoading: false })
}

function * brandsCatalogue () {
  yield takeLatest(BRAND_CATEGORIES_GET_LIST, onGetBrandCategoryList)
  yield takeLatest(BRAND_PRODUCT_TYPE_GET_LIST, onGetBrandProductTypeList)
  yield takeLatest(BRAND_CATALOUGE_GET_LIST, onGetBrandCatalougeList)
}

export default brandsCatalogue
