import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PRODUCTS_CREATE, ROUTE_CONNECTIONS } from '../../constants'
import MuiButton from '../MuiButton'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { CircularProgress, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import {
  ModalContent,
  ModalHeader,
  StoreCardWrapper,
  StoreCard,
  StoreImgWrap,
  StoreContent,
  StoreSelectedWrap,
} from './style'

import EventEmitter, { Events } from '../../utilities/eventEmitter'
import LoadingSpinner from '../LoadingSpinner'

const StoreSelectionModal = ({
  productCreateData,
  stores,
  storeIsLoading,
  setSelectedStores,
  productID,
  toggleModal,
  isMultiple = false,
  setModalStatus,
  onClose,
  iconShow,
}) => {
  const [selectedStore, setSelectedStore] = useState([])
  const [storesList, setStoresList] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    setStoresList(
      stores.map((data) => {
        data.selected = false
        return data
      })
    )
  }, [stores])

  const addStoreBtnClick = () => {
    EventEmitter.emit(Events.PRODUCT_EDIT_LISTING, {
      order_id: productID,
      mp_selected: selectedStore,
    })
  }

  const listClicked = (store) => {
    if (!isMultiple) {
      const storesListData = storesList.map((data) => {
        data.selected = data.id === store.id ? !data.selected : false
        return data
      })
      setStoresList(storesListData)
      setSelectedStore([storesListData] || [])
    } else {
      const storesListData = storesList.map((data) => {
        if (data.id === store.id) {
          data.selected = !data.selected
        }
        return data
      })
      const selectedStores = storesListData.filter((s) => s.selected)
      setSelectedStore(selectedStores || [])
      setStoresList(storesListData)
    }
  }

  const confirmedStores = () => {
    setSelectedStores(selectedStore)
  }
  const handlerClose = () => {
    onClose()
    setModalStatus(false)
  }

  return (
    <ModalContent>
      {iconShow === 'true' && (
        <CloseIcon
          style={{ color: '#7D86A9', position: 'absolute', cursor: 'pointer', top: '10px', right: '10px' }}
          onClick={handlerClose}
        />
      )}
      {!!storesList?.length && <ModalHeader>Select Store</ModalHeader>}
      <StoreCardWrapper>
        {storesList?.map((store, idx) => (
          <StoreCard
            key={idx}
            selected={store.selected}
            onClick={() => {
              listClicked(store)
            }}
          >
            <StoreSelectedWrap isMultiple>{store.selected && <CheckOutlinedIcon size='small' />}</StoreSelectedWrap>
            <StoreContent>
              <div>
                {store.shop_name} - {store.selected}
              </div>
              <p>Shopee</p>
            </StoreContent>
            <StoreImgWrap>{store.shop_logo && <img src={store.shop_logo} alt='Store logo' />}</StoreImgWrap>
          </StoreCard>
        ))}
      </StoreCardWrapper>

      {!!storesList?.length && (
        <>
          {!isMultiple && !storeIsLoading && (
            <MuiButton
              disabled={!selectedStore}
              color='primary'
              variant='contained'
              onClick={() => {
                toggleModal()
                navigate(
                  `${ROUTE_PRODUCTS_CREATE}?store_id=${selectedStore.id}&shop_name=${selectedStore.shop_name}&marketplace_shop_id=${selectedStore.marketplace_shop_id}`
                )
              }}
            >
              Confirm
            </MuiButton>
          )}
          {isMultiple && !storeIsLoading && (
            <MuiButton
              disabled={!selectedStore?.length}
              onClick={() => confirmedStores()}
              styled='primary'
              variant='contained'
            >
              Confirm
            </MuiButton>
          )}

          {storeIsLoading && <CircularProgress />}
        </>
      )}
      {!storesList?.length && (
        <Typography variant='subtitle1' align='center'>
          <ModalHeader>Stores not found</ModalHeader>
          {/* Dont worry your Product({productCreateData?.name}) has been Saved Successfully !!<br /><br /> */}
          <MuiButton
            onClick={addStoreBtnClick}
            variant='contained'
            color='primary'
            to={ROUTE_CONNECTIONS}
            sx={{ mr: 3 }}
          >
            Add Store
          </MuiButton>
          {/* <MuiButton variant='outlined' color='primary' to={ROUTE_PRODUCTS}>Back to Products page</MuiButton> */}
        </Typography>
      )}
    </ModalContent>
  )
}

export default StoreSelectionModal
