// users
const USERS_COUNT_INCREMENT = 'USERS_COUNT_INCREMENT'
const USERS_COUNT_DECREMENT = 'USERS_COUNT_DECREMENT'
const USERS_GET_INFO = 'USERS_GET_INFO'
const USERS_LOADING = 'USERS_LOADING'
const USERS_GET_INFO_FAILURE = 'USERS_GET_INFO_FAILURE'
const USERS_GET_INFO_SUCCESS = 'USERS_GET_INFO_SUCCESS'
const USERS_UPDATE_PROFILE_IMAGE = 'USERS_UPDATE_PROFILE_IMAGE'
const USERS_UPDATE_PROFILE_IMAGE_SUCCESS = 'USERS_UPDATE_PROFILE_IMAGE_SUCCESS'
const USERS_UPDATE_PROFILE_IMAGE_FAILURE = 'USERS_UPDATE_PROFILE_IMAGE_FAILURE'

// Analytics
const GET_ANALYTICS = 'GET_ANALYTICS'
const ANALYTICS_LOADING = 'ANALYTICS_LOADING'
const ANALYTICS_GET_FAILURE = 'ANALYTICS_GET_FAILURE'
const ANALYTICS_GET_SUCCESS = 'ANALYTICS_GET_SUCCESS'
const GET_ANALYTICSOSP_LIST = 'GET_ANALYTICSOSP_LIST'
const ANALYTICSOSP_LIST_LOADING = 'ANALYTICSOSP_LIST_LOADING'
const ANALYTICSOSP_LIST_GET_FAILURE = 'ANALYTICSOSP_LIST_GET_FAILURE'
const ANALYTICSOSP_LIST_GET_SUCCESS = 'ANALYTICSOSP_LIST_GET_SUCCESS'
const GET_ANALYTICS_ORDER_STATUS = 'GET_ANALYTICS_ORDER_STATUS'
const GET_ANALYTICS_ORDER_STATUS_LOADING = 'GET_ANALYTICS_ORDER_STATUS_LOADING'
const GET_ANALYTICS_ORDER_STATUS_SUCCESS = 'GET_ANALYTICS_ORDER_STATUS_SUCCESS'
const GET_ANALYTICS_ORDER_STATUS_FAILURE = 'GET_ANALYTICS_ORDER_STATUS_FAILURE'
const GET_ANALYTICS_TOTAL_ORDER = 'GET_ANALYTICS_TOTAL_ORDER'
const ANALYTICS_TOTAL_ORDER_LOADING = 'ANALYTICS_TOTAL_ORDER_LOADING'
const ANALYTICS_TOTAL_ORDER_SUCCESS = 'ANALYTICS_TOTAL_ORDER_SUCCESS'
const ANALYTICS_TOTAL_ORDER_FAILURE = 'ANALYTICS_TOTAL_ORDER_FAILURE'
const GET_ANALTYICS_REVENUE_SUMMARY = 'GET_ANALTYICS_REVENUE_SUMMARY'
const ANALTYICS_REVENUE_SUMMARY_LOADING = 'ANALTYICS_REVENUE_SUMMARY_LOADING'
const ANALTYICS_REVENUE_SUMMARY_SUCCESS = 'ANALTYICS_REVENUE_SUMMARY_SUCCESS'
const ANALTYICS_REVENUE_SUMMARY_FAILURE = 'ANALTYICS_REVENUE_SUMMARY_FAILURE'
const GET_ANALTYICS_RECENT_ORDERS = 'GET_ANALTYICS_RECENT_ORDERS'
const ANALTYICS_RECENT_ORDERS_LOADING = 'ANALTYICS_RECENT_ORDERS_LOADING'
const ANALTYICS_RECENT_ORDERS_SUCCESS = 'ANALTYICS_RECENT_ORDERS_SUCCESS'
const ANALTYICS_RECENT_ORDERS_FAILURE = 'ANALTYICS_RECENT_ORDERS_FAILURE'

// Auth
const AUTH_GET_OTP = 'AUTH_GET_OTP'
const AUTH_GET_OTP_FAILURE = 'AUTH_GET_OTP_FAILURE'
const AUTH_GET_OTP_SUCCESS = 'AUTH_GET_OTP_SUCCESS'
const AUTH_LOADING = 'AUTH_LOADING'
const AUTH_VERIFY_OTP = 'AUTH_VERIFY_OTP'
const AUTH_VERIFY_OTP_FAILURE = 'AUTH_VERIFY_OTP_FAILURE'
const AUTH_VERIFY_OTP_SUCCESS = 'AUTH_VERIFY_OTP_SUCCESS'
const AUTH_SET_PERSONAL_PROFILE = 'AUTH_SET_PERSONAL_PROFILE'
const AUTH_SET_PERSONAL_PROFILE_FAILURE = 'AUTH_SET_PERSONAL_PROFILE_FAILURE'
const AUTH_SET_PERSONAL_PROFILE_SUCCESS = 'AUTH_SET_PERSONAL_PROFILE_SUCCESS'
const AUTH_LOGIN_MOUNT = 'AUTH_LOGIN_MOUNT'
const AUTH_LOGIN_USER = 'AUTH_LOGIN_USER'
const AUTH_LOGIN_USER_FAILURE = 'AUTH_LOGIN_USER_FAILURE'
const AUTH_LOGIN_USER_SUCCESS = 'AUTH_LOGIN_USER_SUCCESS'
const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER'
const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN'
const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING'
const RESET_PASSWORD = 'RESET_PASSWORD'
const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING'
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

// Store
const STORES_LOADING = 'STORES_LOADING'
const STORES_GET_LIST = 'STORES_GET_LIST'
const STORES_GET_LIST_FAILURE = 'STORES_GET_LIST_FAILURE'
const STORES_GET_LIST_SUCCESS = 'STORES_GET_LIST_SUCCESS'
const STORES_SELECTED_GET_LIST = 'STORES_SELECTED_GET_LIST'
const STORES_SELECTED_GET_LIST_FAILURE = 'STORES_SELECTED_GET_LIST_FAILURE'
const STORES_SELECTED_GET_LIST_SUCCESS = 'STORES_SELECTED_GET_LIST_SUCCESS'
const STORES_DISCONNECT = 'STORES_DISCONNECT'
const STORES_DISCONNECT_FAILURE = 'STORES_DISCONNECT_FAILURE'
const STORES_DISCONNECT_SUCCESS = 'STORES_DISCONNECT_SUCCESS'
const STORES_SYNC_PRODUCTS = 'STORES_SYNC_PRODUCTS'
const STORES_SYNC_PRODUCTS_FAILURE = 'STORES_SYNC_PRODUCTS_FAILURE'
const STORES_SYNC_PRODUCTS_SUCCESS = 'STORES_SYNC_PRODUCTS_SUCCESS'
const STORES_SYNC_ORDERS = 'STORES_SYNC_ORDERS'
const STORES_SYNC_ORDERS_FAILURE = 'STORES_SYNC_ORDERS_FAILURE'
const STORES_SYNC_ORDERS_SUCCESS = 'STORES_SYNC_ORDERS_SUCCESS'
const STORES_RENAME = 'STORES_RENAME'
const STORES_RENAME_SUCCESS = 'STORES_RENAME_SUCCESS'
const STORES_RENAME_FAILURE = 'STORES_RENAME_FAILURE'

// Marketplace
const MARKETPLACES_LOADING = 'MARKETPLACES_LOADING'
const MARKETPLACES_GET_LIST = 'MARKETPLACES_GET_LIST'
const MARKETPLACES_GET_LIST_FAILURE = 'MARKETPLACES_GET_LIST_FAILURE'
const MARKETPLACES_GET_LIST_SUCCESS = 'MARKETPLACES_GET_LIST_SUCCESS'
const MARKETPLACES_CONNECT_SHOPEE = 'MARKETPLACES_CONNECT_SHOPEE'
const MARKETPLACES_CONNECT_SHOPEE_FAILURE = 'MARKETPLACES_CONNECT_SHOPEE_FAILURE'
const MARKETPLACES_CONNECT_SHOPEE_SUCCESS = 'MARKETPLACES_CONNECT_SHOPEE_SUCCESS'

// Products
const RESET_PRODUCT_CREATE_FORM = 'RESET_PRODUCT_CREATE_FORM'
const PRODUCTS_LOADING = 'PRODUCTS_LOADING'
const PRODUCTS_GET_LIST = 'PRODUCTS_GET_LIST'
const PRODUCTS_GET_LIST_FAILURE = 'PRODUCTS_GET_LIST_FAILURE'
const PRODUCTS_GET_LIST_SUCCESS = 'PRODUCTS_GET_LIST_SUCCESS'
const PRODUCTS_LISTED_ON_LOADING = 'PRODUCTS_LISTED_ON_LOADING'
const PRODUCTS_GET_LISTED_ON = 'PRODUCTS_GET_LISTED_ON'
const PRODUCTS_GET_LISTED_ON_FAILURE = 'PRODUCTS_GET_LISTED_ON_FAILURE'
const PRODUCTS_GET_LISTED_ON_SUCCESS = 'PRODUCTS_GET_LISTED_ON_SUCCESS'
// Product Price update
const PRODUCTS_POST_LISTED_ON_UPDATEPRIZE = 'PRODUCTS_POST_LISTED_ON_UPDATEPRIZE'
// const PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE = 'PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE'
const PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_FAILURE = 'PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_FAILURE'
const PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_SUCCESS = 'PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_SUCCESS'

const PRODUCTS_CREATE_LOADING = 'PRODUCTS_CREATE_LOADING'
const PRODUCTS_CREATE_POST_DATA = 'PRODUCTS_CREATE_POST_DATA'
const PRODUCTS_CREATE_POST_DATA_FAILURE = 'PRODUCTS_CREATE_POST_DATA_FAILURE'
const PRODUCTS_CREATE_POST_DATA_SUCCESS = 'PRODUCTS_CREATE_POST_DATA_SUCCESS'
const PRODUCTS_CREATE_INFO_LOADING = 'PRODUCTS_CREATE_INFO_LOADING'
const PRODUCTS_CREATE_INFO_POST_DATA = 'PRODUCTS_CREATE_INFO_POST_DATA'
const PRODUCTS_CREATE_INFO_POST_DATA_FAILURE = 'PRODUCTS_CREATE_INFO_POST_DATA_FAILURE'
const PRODUCTS_CREATE_INFO_POST_DATA_SUCCESS = 'PRODUCTS_CREATE_INFO_POST_DATA_SUCCESS'
const PRODUCTS_EDIT_LOADING = 'PRODUCTS_EDIT_LOADING'
const PRODUCTS_EDIT_GET_DATA = 'PRODUCTS_EDIT_GET_DATA'
const PRODUCTS_EDIT_GET_DATA_FAILURE = 'PRODUCTS_EDIT_GET_DATA_FAILURE'
const PRODUCTS_EDIT_GET_DATA_SUCCESS = 'PRODUCTS_EDIT_GET_DATA_SUCCESS'
const PRODUCTS_EDIT_GET_DATA_CLEAR = 'PRODUCTS_EDIT_GET_DATA_CLEAR'
const PRODUCTS_UPDATE_LOADING = 'PRODUCTS_UPDATE_LOADING'
const PRODUCTS_UPDATE_SET_DATA = 'PRODUCTS_UPDATE_SET_DATA'
const PRODUCTS_UPDATE_SET_DATA_FAILURE = 'PRODUCTS_UPDATE_SET_DATA_FAILURE'
const PRODUCTS_UPDATE_SET_DATA_SUCCESS = 'PRODUCTS_UPDATE_SET_DATA_SUCCESS'
const PRODUCTS_DELETE_LOADING = 'PRODUCTS_DELETE_LOADING'
const PRODUCTS_DELETE_DATA = 'PRODUCTS_DELETE_DATA'
const PRODUCTS_DELETE_DATA_FAILURE = 'PRODUCTS_DELETE_DATA_FAILURE'
const PRODUCTS_DELETE_DATA_SUCCESS = 'PRODUCTS_DELETE_DATA_SUCCESS'
const PRODUCTS_UPDATE_SET_DATA_CLEAR = 'PRODUCTS_UPDATE_SET_DATA_CLEAR'
const PRODUCTS_ALL_RECORDS_GN = 'PRODUCTS_ALL_RECORDS_GN'
const PRODUCTS_ALL_RECORDS = 'PRODUCTS_ALL_RECORDS'
const PRODUCTS_ALL_RECORDS_LOADING = 'PRODUCTS_ALL_RECORDS_LOADING'
const UPDATE_PRODUCT_FULLFILL_VIA = 'UPDATE_PRODUCT_FULLFILL_VIA'
const UPDATE_PRODUCT_FULLFILL_VIA_LOADING = 'UPDATE_PRODUCT_FULLFILL_VIA_LOADING'
const UPDATE_PRODUCT_FULLFILL_VIA_FAILURE = 'UPDATE_PRODUCT_FULLFILL_VIA_FAILURE'
const UPDATE_PRODUCT_FULLFILL_VIA_SUCCESS = 'UPDATE_PRODUCT_FULLFILL_VIA_SUCCESS'

// Categories
const CATEGORIES_LOADING = 'CATEGORIES_LOADING'
const CATEGORIES_GET_LIST = 'CATEGORIES_GET_LIST'
const CATEGORIES_GET_LIST_FAILURE = 'CATEGORIES_GET_LIST_FAILURE'
const CATEGORIES_GET_LIST_SUCCESS = 'CATEGORIES_GET_LIST_SUCCESS'
const CATEGORIES_DROPDOWN_LOADING = 'CATEGORIES_DROPDOWN_LOADING'
const CATEGORIES_DROPDOWN_GET_LIST = 'CATEGORIES_DROPDOWN_GET_LIST'
const CATEGORIES_DROPDOWN_GET_LIST_FAILURE = 'CATEGORIES_DROPDOWN_GET_LIST_FAILURE'
const CATEGORIES_DROPDOWN_GET_LIST_SUCCESS = 'CATEGORIES_DROPDOWN_GET_LIST_SUCCESS'
const CATEGORIES_BRANDS_LOADING = 'CATEGORIES_BRANDS_LOADING'
const CATEGORIES_BRANDS_GET_LIST = 'CATEGORIES_BRANDS_GET_LIST'
const CATEGORIES_BRANDS_GET_LIST_FAILURE = 'CATEGORIES_BRANDS_GET_LIST_FAILURE'
const CATEGORIES_BRANDS_GET_LIST_SUCCESS = 'CATEGORIES_BRANDS_GET_LIST_SUCCESS'
const CATEGORIES_ATTRIBUTES_LOADING = 'CATEGORIES_ATTRIBUTES_LOADING'
const CATEGORIES_ATTRIBUTES_GET_LIST = 'CATEGORIES_ATTRIBUTES_GET_LIST'
const CATEGORIES_ATTRIBUTES_GET_LIST_FAILURE = 'CATEGORIES_ATTRIBUTES_GET_LIST_FAILURE'
const CATEGORIES_ATTRIBUTES_GET_LIST_SUCCESS = 'CATEGORIES_ATTRIBUTES_GET_LIST_SUCCESS'

// Orders
const ORDERS_LOADING = 'ORDER_LOADING'
const ORDERS_GET_LIST = 'ORDERS_GET_LIST'
const ORDERS_GET_LIST_FAILURE = 'ORDERS_GET_LIST_FAILURE'
const ORDERS_GET_LIST_SUCCESS = 'ORDERS_GET_LIST_SUCCESS'
const ORDERS_PARTIAL_FULFILLMENT = 'ORDERS_PARTIAL_FULFILLMENT'
const ORDERS_PARTIAL_FULFILLMENT_FAILURE = 'ORDERS_PARTIAL_FULFILLMENT_FAILURE'
const ORDERS_PARTIAL_FULFILLMENT_SUCCESS = 'ORDERS_PARTIAL_FULFILLMENT_SUCCESS'
const ORDER_DETAIL_GET_LOADING = 'ORDER_DETAIL_GET_LOADING'
const ORDER_DETAIL_GET = 'ORDER_DETAIL_GET'
const ORDER_DETAIL_GET_FAILURE = 'ORDER_DETAIL_GET_FAILURE'
const ORDER_DETAIL_GET_SUCCESS = 'ORDER_DETAIL_GET_SUCCESS'
const ORDER_CANCEL_LOADING = 'ORDER_CANCEL_LOADING'
const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS'
const ORDER_CANCEL_FAILURE = 'ORDER_CANCEL_FAILURE'
const ORDER_CANCEL_ORDER = 'ORDER_CANCEL_ORDER'
const ORDER_SHIP_LOADING = 'ORDER_SHIP_LOADING'
const ORDER_SHIP_SUCCESS = 'ORDER_SHIP_SUCCESS'
const ORDER_SHIP_FAILURE = 'ORDER_SHIP_FAILURE'
const ORDER_SHIP = 'ORDER_SHIP'
const ORDER_SHIP_PARAMS_LOADING = 'ORDER_SHIP_PARAMS_LOADING'
const ORDER_SHIP_PARAMS_FAILURE = 'ORDER_SHIP_PARAMS_FAILURE'
const ORDER_SHIP_PARAMS_SUCCESS = 'ORDER_SHIP_PARAMS_SUCCESS'
const ORDER_SHIP_PARAMS = 'ORDER_SHIP_PARAMS'
const ORDERS_STATUS_LOADING = 'ORDERS_STATUS_LOADING'
const ORDERS_STATUS_GET_LIST_SUCCESS = 'ORDERS_STATUS_GET_LIST_SUCCESS'
const ORDERS_STATUS_GET_LIST_FAILURE = 'ORDERS_STATUS_GET_LIST_FAILURE'
const ORDER_LIST_STATUS = 'ORDER_LIST_STATUS'

// UploadImages
const UPLOADIMAGES_LOADING = 'UPLOADIMAGES_LOADING'
const UPLOADIMAGES_POST = 'UPLOADIMAGES_POST'
const UPLOADIMAGES_POST_SUCCESS = 'UPLOADIMAGES_POST_SUCCESS'
const UPLOADIMAGES_POST_FAILURE = 'UPLOADIMAGES_POST_FAILURE'
const UPLOADIMAGES_CLEAR = 'UPLOADIMAGES_CLEAR'

// Raena Products
const RAENA_PRODUCTS_LOADING = 'RAENA_PRODUCTS_LOADING'
const RAENA_PRODUCTS_GET_LIST = 'RAENA_PRODUCTS_GET_LIST'
const RAENA_PRODUCTS_GET_LIST_FAILURE = 'RAENA_PRODUCTS_GET_LIST_FAILURE'
const RAENA_PRODUCTS_GET_LIST_SUCCESS = 'RAENA_PRODUCTS_GET_LIST_SUCCESS'

// Raena Products Add to store
const ADD_TO_STORE_RAENA_PRODUCTS_LOADING = 'ADD_TO_STORE_RAENA_PRODUCTS_LOADING'
const ADD_TO_STORE_RAENA_PRODUCTS_EXECUTE = 'ADD_TO_STORE_RAENA_PRODUCTS_EXECUTE'
const ADD_TO_STORE_RAENA_PRODUCTS_FAILURE = 'ADD_TO_STORE_RAENA_PRODUCTS_FAILURE'
const ADD_TO_STORE_RAENA_PRODUCTS_SUCCESS = 'ADD_TO_STORE_RAENA_PRODUCTS_SUCCESS'

// Raena Brand Portal Catalog
const BRAND_CATEGORIES_LOADING = 'BRAND_CATEGORIES_LOADING'
const BRAND_CATEGORIES_GET_LIST = 'BRAND_CATEGORIES_GET_LIST'
const BRAND_CATEGORIES_GET_LIST_FAILURE = 'BRAND_CATEGORIES_GET_LIST_FAILURE'
const BRAND_CATEGORIES_GET_LIST_SUCCESS = 'BRAND_CATEGORIES_GET_LIST_SUCCESS'

const BRAND_PRODUCT_TYPE_LOADING = 'BRAND_PRODUCT_TYPE_LOADING'
const BRAND_PRODUCT_TYPE_GET_LIST = 'BRAND_PRODUCT_TYPE_GET_LIST'
const BRAND_PRODUCT_TYPE_GET_LIST_FAILURE = 'BRAND_PRODUCT_TYPE_GET_LIST_FAILURE'
const BRAND_PRODUCT_TYPE_GET_LIST_SUCCESS = 'BRAND_PRODUCT_TYPE_GET_LIST_SUCCESS'

const BRAND_CATALOUGE_LOADING = 'BRAND_CATALOUGE_LOADING'
const BRAND_CATALOUGE_GET_LIST = 'BRAND_CATALOUGE_GET_LIST'
const BRAND_CATALOUGE_GET_LIST_FAILURE = 'BRAND_CATALOUGE_GET_LIST_FAILURE'
const BRAND_CATALOUGE_GET_LIST_SUCCESS = 'BRAND_CATALOUGE_GET_LIST_SUCCESS'

const BRAND_GET_LIST = 'BRAND_GET_LIST'
const BRAND_GET_LIST_SUCCESS = 'BRAND_GET_LIST_SUCCESS'
const BRAND_GET_LIST_FAILURE = 'BRAND_GET_LIST_FAILURE'
const BRAND_GET_LIST_LOADING = 'BRAND_GET_LIST_LOADING'

const BRAND_SELECT = 'BRAND_SELECT'
const BRAND_ERROR_DISPLAYED = 'BRAND_ERROR_DISPLAYED'

// Raena Brand Portal Promo

const BRAND_PROMO_LOADING = 'BRAND_PROMO_LOADING'
const BRAND_PROMO_GET_LIST = 'BRAND_PROMO_GET_LIST'
const BRAND_PROMO_GET_LIST_FAILURE = 'BRAND_PROMO_GET_LIST_FAILURE'
const BRAND_PROMO_GET_LIST_SUCCESS = 'BRAND_PROMO_GET_LIST_SUCCESS'

const BRAND_PROMO_TYPE_LOADING = 'BRAND_PROMO_TYPE_LOADING'
const BRAND_PROMO_TYPE_GET_LIST = 'BRAND_PROMO_TYPE_GET_LIST'
const BRAND_PROMO_TYPE_GET_LIST_FAILURE = 'BRAND_PROMO_TYPE_GET_LIST_FAILURE'
const BRAND_PROMO_TYPE_GET_LIST_SUCCESS = 'BRAND_PROMO_TYPE_GET_LIST_SUCCESS'

const BRAND_SKU_LOADING = 'BRAND_SKU_LOADING'
const BRAND_SKU_GET_LIST = 'BRAND_SKU_GET_LIST'
const BRAND_SKU_GET_LIST_FAILURE = 'BRAND_SKU_GET_LIST_FAILURE'
const BRAND_SKU_GET_LIST_SUCCESS = 'BRAND_SKU_GET_LIST_SUCCESS'

// Subscription
const BRAND_GET_SUBSCRIPTION_SUCCESS = 'BRAND_GET_SUBSCRIPTION_SUCCESS'
const BRAND_GET_SUBSCRIPTION_FAILURE = 'BRAND_GET_SUBSCRIPTION_FAILURE'
const BRAND_REVOKE_BRAND_ACCESS = 'BRAND_REVOKE_BRAND_ACCESS'

//MSP
const BRAND_MSP_LOADING = 'BRAND_MSP_LOADING'
const BRAND_MSP_GET_LIST = 'BRAND_MSP_GET_LIST'
const BRAND_MSP_GET_LIST_FAILURE = 'BRAND_MSP_GET_LIST_FAILURE'
const BRAND_MSP_GET_LIST_SUCCESS = 'BRAND_MSP_GET_LIST_SUCCESS'

const BRAND_MSP_EXPORT = 'BRAND_MSP_EXPORT'
const BRAND_MSP_EXPORT_SUCCESS = 'BRAND_MSP_EXPORT_SUCCESS'
const BRAND_MSP_EXPORT_LOADING = 'BRAND_MSP_EXPORT_LOADING'
const BRAND_MSP_EXPORT_FAILURE = 'BRAND_MSP_EXPORT_FAILURE'

const BRAND_MSP_CONFIG = 'BRAND_MSP_CONFIG'
const BRAND_MSP_CONFIG_SUCCESS = 'BRAND_MSP_CONFIG_SUCCESS'
const BRAND_MSP_CONFIG_FAILURE = 'BRAND_MSP_CONFIG_FAILURE'
const BRAND_MSP_CONFIG_LOADING = 'BRAND_MSP_CONFIG_LOADING'
const BRAND_MSP_CONFIG_RESET = 'BRAND_MSP_CONFIG_RESET'

//MSP Details
const BRAND_MSP_DETAIL_LOADING = 'BRAND_MSP_DETAIL_LOADING'
const BRAND_MSP_GET_DETAIL = 'BRAND_MSP_GET_DETAIL'
const BRAND_MSP_GET_DETAIL_FAILURE = 'BRAND_MSP_GET_DETAIL_FAILURE'
const BRAND_MSP_GET_DETAIL_SUCCESS = 'BRAND_MSP_GET_DETAIL_SUCCESS'

const BRAND_MSP_DETAIL_EXPORT = 'BRAND_MSP_DETAIL_EXPORT'
const BRAND_MSP_DETAIL_EXPORT_SUCCESS = 'BRAND_MSP_DETAIL_EXPORT_SUCCESS'
const BRAND_MSP_DETAIL_EXPORT_LOADING = 'BRAND_MSP_DETAIL_EXPORT_LOADING'
const BRAND_MSP_DETAIL_EXPORT_FAILURE = 'BRAND_MSP_DETAIL_EXPORT_FAILURE'

const BRAND_MSP_DETAIL_FILTERS_LOADING = 'BRAND_MSP_DETAIL_FILTERS_LOADING'
const BRAND_MSP_GET_DETAIL_FILTERS = 'BRAND_MSP_GET_DETAIL_FILTERS'
const BRAND_MSP_GET_DETAIL_FILTERS_FAILURE = 'BRAND_MSP_GET_DETAIL_FILTERS_FAILURE'
const BRAND_MSP_GET_DETAIL_FILTERS_SUCCESS = 'BRAND_MSP_GET_DETAIL_FILTERS_SUCCESS'
const BRAND_MSP_GET_DETAIL_FILTERS_RESET = 'BRAND_MSP_GET_DETAIL_FILTERS_RESET'

// Page Builder
const PAGEBUILDER_LINKS_CREATE_LOADING = 'PAGEBUILDER_LINKS_CREATE_LOADING'
const PAGEBUILDER_LINKS_CREATE_POST_DATA = 'PAGEBUILDER_LINKS_CREATE_POST_DATA'
const PAGEBUILDER_LINKS_CREATE_POST_DATA_SUCCESS = 'PAGEBUILDER_LINKS_CREATE_POST_DATA_SUCCESS'
const PAGEBUILDER_LINKS_CREATE_POST_DATA_FAILURE = 'PAGEBUILDER_LINKS_CREATE_POST_DATA_FAILURE'
const PAGEBUILDER_LINKS_UPDATE_LOADING = 'PAGEBUILDER_LINKS_UPDATE_LOADING'
const PAGEBUILDER_LINKS_UPDATE_POST_DATA = 'PAGEBUILDER_LINKS_UPDATE_POST_DATA'
const PAGEBUILDER_LINKS_UPDATE_POST_DATA_SUCCESS = 'PAGEBUILDER_LINKS_UPDATE_POST_DATA_SUCCESS'
const PAGEBUILDER_LINKS_UPDATE_POST_DATA_FAILURE = 'PAGEBUILDER_LINKS_UPDATE_POST_DATA_FAILURE'
const PAGEBUILDER_LINKS_DELETE_LOADING = 'PAGEBUILDER_LINKS_DELETE_LOADING'
const PAGEBUILDER_LINKS_DELETE_POST_DATA = 'PAGEBUILDER_LINKS_DELETE_POST_DATA'
const PAGEBUILDER_LINKS_DELETE_POST_DATA_SUCCESS = 'PAGEBUILDER_LINKS_DELETE_POST_DATA_SUCCESS'
const PAGEBUILDER_LINKS_DELETE_POST_DATA_FAILURE = 'PAGEBUILDER_LINKS_DELETE_POST_DATA_FAILURE'
const PAGEBUILDER_SECTIONS_REORDER_LOADING = 'PAGEBUILDER_SECTIONS_REORDER_LOADING'
const PAGEBUILDER_SECTIONS_REORDER_POST_DATA = 'PAGEBUILDER_SECTIONS_REORDER_POST_DATA'
const PAGEBUILDER_SECTIONS_REORDER_POST_DATA_SUCCESS = 'PAGEBUILDER_SECTIONS_REORDER_POST_DATA_SUCCESS'
const PAGEBUILDER_SECTIONS_REORDER_POST_DATA_FAILURE = 'PAGEBUILDER_SECTIONS_REORDER_POST_DATA_FAILURE'
const PAGEBUILDER_LINKS_REORDER_LOADING = 'PAGEBUILDER_LINKS_REORDER_LOADING'
const PAGEBUILDER_LINKS_REORDER_POST_DATA = 'PAGEBUILDER_LINKS_REORDER_POST_DATA'
const PAGEBUILDER_LINKS_REORDER_POST_DATA_SUCCESS = 'PAGEBUILDER_LINKS_REORDER_POST_DATA_SUCCESS'
const PAGEBUILDER_LINKS_REORDER_POST_DATA_FAILURE = 'PAGEBUILDER_LINKS_REORDER_POST_DATA_FAILURE'
const PAGEBUILDER_LINKS_FETCH_LOADING = 'PAGEBUILDER_LINKS_FETCH_LOADING'
const PAGEBUILDER_LINKS_FETCH_GET_DATA = 'PAGEBUILDER_LINKS_FETCH_GET_DATA'
const PAGEBUILDER_LINKS_FETCH_GET_DATA_SUCCESS = 'PAGEBUILDER_LINKS_FETCH_GET_DATA_SUCCESS'
const PAGEBUILDER_LINKS_FETCH_GET_DATA_FAILURE = 'PAGEBUILDER_LINKS_FETCH_GET_DATA_FAILURE'

// page builder about
const PAGEBUILDER_ABOUT_CREATE_POST_DATA = 'PAGEBUILDER_ABOUT_CREATE_POST_DATA'
const PAGEBUILDER_ABOUT_CREATE_LOADING = 'PAGEBUILDER_ABOUT_CREATE_LOADING'
const PAGEBUILDER_ABOUT_CREATE_POST_DATA_SUCCESS = 'PAGEBUILDER_ABOUT_CREATE_POST_DATA_SUCCESS'
const PAGEBUILDER_ABOUT_CREATE_POST_DATA_FAILURE = 'PAGEBUILDER_ABOUT_CREATE_POST_DATA_FAILURE'
const PAGEBUILDER_ABOUT_FETCH_LOADING = 'PAGEBUILDER_ABOUT_FETCH_LOADING'
const PAGEBUILDER_ABOUT_FETCH_POST_DATA = 'PAGEBUILDER_ABOUT_FETCH_POST_DATA'
const PAGEBUILDER_ABOUT_FETCH_POST_DATA_SUCCESS = 'PAGEBUILDER_ABOUT_FETCH_POST_DATA_SUCCESS'
const PAGEBUILDER_ABOUT_FETCH_POST_DATA_FAILURE = 'PAGEBUILDER_ABOUT_FETCH_POST_DATA_FAILURE'

// page builder raena product
const PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_LOADING = 'PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_LOADING'
const PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST = 'PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST'
const PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_SUCCESS = 'PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_SUCCESS'
const PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_FAILURE = 'PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_FAILURE'

const PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_LOADING = 'PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_LOADING'
const PAGEBUILDER_RAENAPRODUCTS_ALL_LIST = 'PAGEBUILDER_RAENAPRODUCTS_ALL_LIST'
const PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_SUCCESS = 'PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_SUCCESS'
const PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_FAILURE = 'PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_FAILURE'

const PAGEBUILDER_RAENAPRODUCTS_ADD_LOADING = 'PAGEBUILDER_RAENAPRODUCTS_ADD_LOADING'
const PAGEBUILDER_RAENAPRODUCTS_ADD = 'PAGEBUILDER_RAENAPRODUCTS_ADD'
const PAGEBUILDER_RAENAPRODUCTS_ADD_SUCCESS = 'PAGEBUILDER_RAENAPRODUCTS_ADD_SUCCESS'
const PAGEBUILDER_RAENAPRODUCTS_ADD_FAILURE = 'PAGEBUILDER_RAENAPRODUCTS_ADD_FAILURE'

const PAGEBUILDER_RAENAPRODUCTS_UPDATE_LOADING = 'PAGEBUILDER_RAENAPRODUCTS_UPDATE_LOADING'
const PAGEBUILDER_RAENAPRODUCTS_UPDATE = 'PAGEBUILDER_RAENAPRODUCTS_UPDATE'
const PAGEBUILDER_RAENAPRODUCTS_UPDATE_SUCCESS = 'PAGEBUILDER_RAENAPRODUCTS_UPDATE_SUCCESS'
const PAGEBUILDER_RAENAPRODUCTS_UPDATE_FAILURE = 'PAGEBUILDER_RAENAPRODUCTS_UPDATE_FAILURE'

const PAGEBUILDER_RAENAPRODUCTS_DELETE_LOADING = 'PAGEBUILDER_RAENAPRODUCTS_DELETE_LOADING'
const PAGEBUILDER_RAENAPRODUCTS_DELETE = 'PAGEBUILDER_RAENAPRODUCTS_DELETE'
const PAGEBUILDER_RAENAPRODUCTS_DELETE_SUCCESS = 'PAGEBUILDER_RAENAPRODUCTS_DELETE_SUCCESS'
const PAGEBUILDER_RAENAPRODUCTS_DELETE_FAILURE = 'PAGEBUILDER_RAENAPRODUCTS_DELETE_FAILURE'

// Brand
const CREATE_PROMO = 'CREATE_PROMO'
const CREATE_PROMO_SUCCESS = 'CREATE_PROMO_SUCCESS'
const CREATE_PROMO_FAILURE = 'CREATE_PROMO_FAILURE'

const GET_OFFERS = 'GET_OFFERS'
const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS'
const GET_OFFERS_FAILURE = 'GET_OFFERS_FAILURE'

const GET_SKU = 'GET_SKU'
const GET_SKU_SUCCESS = 'GET_SKU_SUCCESS'
const GET_SKU_FAILURE = 'GET_SKU_FAILURE'

// DASHBOARD ANALYTICS
const GET_DASHBOARD_PROVINCE = 'GET_DASHBOARD_PROVINCE'
const GET_DASHBOARD_PROVINCE_SUCCESS = 'GET_DASHBOARD_PROVINCE_SUCCESS'
const GET_DASHBOARD_PROVINCE_FAILURE = 'GET_DASHBOARD_PROVINCE_FAILURE'

const GET_DASHBOARD_MONTH = 'GET_DASHBOARD_MONTH'
const GET_DASHBOARD_MONTH_SUCCESS = 'GET_DASHBOARD_MONTH_SUCCESS'
const GET_DASHBOARD_MONTH_FAILURE = 'GET_DASHBOARD_MONTH_FAILURE'

const GET_DASHBOARD_YEAR = 'GET_DASHBOARD_YEAR'
const GET_DASHBOARD_YEAR_SUCCESS = 'GET_DASHBOARD_YEAR_SUCCESS'
const GET_DASHBOARD_YEAR_FAILURE = 'GET_DASHBOARD_YEAR_FAILURE'

const POST_DASHBOARD_YEAR_RANGE = 'POST_DASHBOARD_YEAR_RANGE'
const POST_DASHBOARD_YEAR_RANGE_SUCCESS = 'POST_DASHBOARD_YEAR_RANGE_SUCCESS'
const POST_DASHBOARD_YEAR_RANGE_FAILURE = 'POST_DASHBOARD_YEAR_RANGE_FAILURE'

const DAY_REVENUE = 'DAYS_REVENUE'
const DAY_REVENUE_SUCCESS = 'DAYS_REVENUE_SUCCESS'
const DAY_REVENUE_FAILURE = 'DAYS_REVENUE_FAILURE'

const MONTH_REVENUE = 'MONTH_REVENUE'
const MONTH_REVENUE_SUCCESS = 'MONTH_REVENUE_SUCCESS'
const MONTH_REVENUE_FAILURE = 'MONTH_REVENUE_FAILURE'

const DAY_UNIT_SOLD = 'DAY_UNIT_SOLD'
const DAY_UNIT_SOLD_SUCCESS = 'DAY_UNIT_SOLD_SUCCESS'
const DAY_UNIT_SOLD_FAILURE = 'DAY_UNIT_SOLD_FAILURE'

const TOTAL_UNIT_SOLD = 'TOTAL_UNIT_SOLD'
const TOTAL_UNIT_SOLD_SUCCESS = 'TOTAL_UNIT_SOLD_SUCCESS'
const TOTAL_UNIT_SOLD_FAILURE = 'TOTAL_UNIT_SOLD_FAILURE'

const MONTH_SHIPPING = 'MONTH_SHIPPING'
const MONTH_SHIPPING_SUCCESS = 'MONTH_SHIPPING_SUCCESS'
const MONTH_SHIPPING_FAILURE = 'MONTH_SHIPPING_FAILURE'

const GET_REVENUE_DATA = 'GET_REVENUE_DATA'
const GET_REVENUE_DATA_SUCCESS = 'GET_REVENUE_DATA_SUCCESS'
const GET_REVENUE_DATA_FAILURE = 'GET_REVENUE_DATA_FAILURE'

const GET_SKU_DATA = 'GET_SKU_DATA'
const GET_SKU_DATA_SUCCESS = 'GET_SKU_DATA_SUCCESS'
const GET_SKU_DATA_FAILURE = 'GET_SKU_DATA_FAILURE'

const GET_PROVINCE_DATA = 'GET_PROVINCE_DATA'
const GET_PROVINCE_DATA_SUCCESS = 'GET_PROVINCE_DATA_SUCCESS'
const GET_PROVINCE_DATA_FAILURE = 'GET_PROVINCE_DATA_FAILURE'

const GET_CATEGORY_REVENUE = 'GET_CATEGORY_REVENUE'
const GET_CATEGORY_REVENUE_SUCCESS = 'GET_CATEGORY_REVENUE_SUCCESS'
const GET_CATEGORY_REVENUE_FAILURE = 'GET_CATEGORY_REVENUE_FAILURE'

const GET_PRODUCT_DATA = 'GET_PRODUCT_DATA'
const GET_PRODUCT_DATA_SUCCESS = 'GET_PRODUCT_DATA_SUCCESS'
const GET_PRODUCT_DATA_FAILURE = 'GET_PRODUCT_DATA_FAILURE'

const RESET_FIELDS = 'RESET_FIELDS'
const RESET_VALIDATE_FIELDS = 'RESET_VALIDATE_FIELDS'

const PATCH_PROMO = 'PATCH_PROMO'
const PATCH_PROMO_SUCCESS = 'PATCH_PROMO_SUCCESS'
const PATCH_PROMO_FAILURE = 'PATCH_PROMO_FAILURE'

const VALIDATE_PROMO = 'VALIDATE_PROMO'
const VALIDATE_PROMO_SUCCESS = 'VALIDATE_PROMO_SUCCESS'
const VALIDATE_PROMO_FAILURE = 'VALIDATE_PROMO_FAILURE'

// Earnings View
const EARNINGS_VIEW_FETCH = 'EARNINGS_VIEW_FETCH'
const EARNINGS_VIEW_LOADING = 'EARNINGS_VIEW_LOADING'
const EARNINGS_VIEW_SUCCESS = 'EARNINGS_VIEW_SUCCESS'
const EARNINGS_VIEW_FAILURE = 'EARNINGS_VIEW_FAILURE'

const BRAND_DATA_SAVE = 'BRAND_DATA_SAVE'

export {
  EARNINGS_VIEW_FETCH,
  EARNINGS_VIEW_LOADING,
  EARNINGS_VIEW_SUCCESS,
  EARNINGS_VIEW_FAILURE,
  GET_ANALYTICS,
  ANALYTICS_LOADING,
  ANALYTICS_GET_FAILURE,
  ANALYTICS_GET_SUCCESS,
  GET_ANALYTICSOSP_LIST,
  ANALYTICSOSP_LIST_LOADING,
  ANALYTICSOSP_LIST_GET_FAILURE,
  ANALYTICSOSP_LIST_GET_SUCCESS,
  GET_ANALYTICS_ORDER_STATUS,
  GET_ANALYTICS_ORDER_STATUS_LOADING,
  GET_ANALYTICS_ORDER_STATUS_SUCCESS,
  GET_ANALYTICS_ORDER_STATUS_FAILURE,
  GET_ANALYTICS_TOTAL_ORDER,
  ANALYTICS_TOTAL_ORDER_LOADING,
  ANALYTICS_TOTAL_ORDER_SUCCESS,
  ANALYTICS_TOTAL_ORDER_FAILURE,
  GET_ANALTYICS_REVENUE_SUMMARY,
  ANALTYICS_REVENUE_SUMMARY_LOADING,
  ANALTYICS_REVENUE_SUMMARY_SUCCESS,
  ANALTYICS_REVENUE_SUMMARY_FAILURE,
  GET_ANALTYICS_RECENT_ORDERS,
  ANALTYICS_RECENT_ORDERS_LOADING,
  ANALTYICS_RECENT_ORDERS_SUCCESS,
  ANALTYICS_RECENT_ORDERS_FAILURE,
  ADD_TO_STORE_RAENA_PRODUCTS_LOADING,
  ADD_TO_STORE_RAENA_PRODUCTS_EXECUTE,
  ADD_TO_STORE_RAENA_PRODUCTS_FAILURE,
  ADD_TO_STORE_RAENA_PRODUCTS_SUCCESS,
  AUTH_GET_OTP,
  AUTH_GET_OTP_FAILURE,
  AUTH_GET_OTP_SUCCESS,
  AUTH_LOADING,
  AUTH_VERIFY_OTP,
  AUTH_VERIFY_OTP_FAILURE,
  AUTH_VERIFY_OTP_SUCCESS,
  AUTH_SET_PERSONAL_PROFILE,
  AUTH_SET_PERSONAL_PROFILE_FAILURE,
  AUTH_SET_PERSONAL_PROFILE_SUCCESS,
  AUTH_LOGIN_MOUNT,
  AUTH_LOGIN_USER,
  AUTH_LOGIN_USER_FAILURE,
  AUTH_LOGIN_USER_SUCCESS,
  AUTH_LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_LOADING,
  RESET_PASSWORD,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  STORES_LOADING,
  STORES_GET_LIST,
  STORES_GET_LIST_FAILURE,
  STORES_GET_LIST_SUCCESS,
  STORES_SELECTED_GET_LIST,
  STORES_SELECTED_GET_LIST_FAILURE,
  STORES_SELECTED_GET_LIST_SUCCESS,
  STORES_DISCONNECT,
  STORES_DISCONNECT_FAILURE,
  STORES_DISCONNECT_SUCCESS,
  STORES_SYNC_PRODUCTS,
  STORES_SYNC_PRODUCTS_FAILURE,
  STORES_SYNC_PRODUCTS_SUCCESS,
  STORES_SYNC_ORDERS,
  STORES_SYNC_ORDERS_FAILURE,
  STORES_SYNC_ORDERS_SUCCESS,
  STORES_RENAME,
  STORES_RENAME_SUCCESS,
  STORES_RENAME_FAILURE,
  USERS_COUNT_INCREMENT,
  USERS_COUNT_DECREMENT,
  MARKETPLACES_LOADING,
  MARKETPLACES_GET_LIST,
  MARKETPLACES_GET_LIST_FAILURE,
  MARKETPLACES_GET_LIST_SUCCESS,
  MARKETPLACES_CONNECT_SHOPEE,
  MARKETPLACES_CONNECT_SHOPEE_FAILURE,
  MARKETPLACES_CONNECT_SHOPEE_SUCCESS,
  RESET_PRODUCT_CREATE_FORM,
  PRODUCTS_LOADING,
  PRODUCTS_GET_LIST,
  PRODUCTS_GET_LIST_FAILURE,
  PRODUCTS_GET_LIST_SUCCESS,
  PRODUCTS_LISTED_ON_LOADING,
  PRODUCTS_GET_LISTED_ON,
  PRODUCTS_GET_LISTED_ON_SUCCESS,
  PRODUCTS_GET_LISTED_ON_FAILURE,
  PRODUCTS_POST_LISTED_ON_UPDATEPRIZE,
  // PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE,
  PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_FAILURE,
  PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_SUCCESS,
  PRODUCTS_CREATE_LOADING,
  PRODUCTS_CREATE_POST_DATA,
  PRODUCTS_CREATE_POST_DATA_FAILURE,
  PRODUCTS_CREATE_POST_DATA_SUCCESS,
  PRODUCTS_CREATE_INFO_LOADING,
  PRODUCTS_CREATE_INFO_POST_DATA,
  PRODUCTS_CREATE_INFO_POST_DATA_FAILURE,
  PRODUCTS_CREATE_INFO_POST_DATA_SUCCESS,
  PRODUCTS_EDIT_LOADING,
  PRODUCTS_EDIT_GET_DATA,
  PRODUCTS_EDIT_GET_DATA_FAILURE,
  PRODUCTS_EDIT_GET_DATA_SUCCESS,
  PRODUCTS_EDIT_GET_DATA_CLEAR,
  PRODUCTS_UPDATE_LOADING,
  PRODUCTS_UPDATE_SET_DATA,
  PRODUCTS_UPDATE_SET_DATA_FAILURE,
  PRODUCTS_UPDATE_SET_DATA_SUCCESS,
  PRODUCTS_DELETE_LOADING,
  PRODUCTS_DELETE_DATA,
  PRODUCTS_DELETE_DATA_FAILURE,
  PRODUCTS_DELETE_DATA_SUCCESS,
  PRODUCTS_UPDATE_SET_DATA_CLEAR,
  UPDATE_PRODUCT_FULLFILL_VIA,
  UPDATE_PRODUCT_FULLFILL_VIA_LOADING,
  UPDATE_PRODUCT_FULLFILL_VIA_FAILURE,
  UPDATE_PRODUCT_FULLFILL_VIA_SUCCESS,
  CATEGORIES_LOADING,
  CATEGORIES_GET_LIST,
  CATEGORIES_GET_LIST_FAILURE,
  CATEGORIES_GET_LIST_SUCCESS,
  CATEGORIES_BRANDS_LOADING,
  CATEGORIES_BRANDS_GET_LIST,
  CATEGORIES_BRANDS_GET_LIST_FAILURE,
  CATEGORIES_BRANDS_GET_LIST_SUCCESS,
  CATEGORIES_ATTRIBUTES_LOADING,
  CATEGORIES_ATTRIBUTES_GET_LIST,
  CATEGORIES_ATTRIBUTES_GET_LIST_FAILURE,
  CATEGORIES_ATTRIBUTES_GET_LIST_SUCCESS,
  CATEGORIES_DROPDOWN_LOADING,
  CATEGORIES_DROPDOWN_GET_LIST,
  CATEGORIES_DROPDOWN_GET_LIST_FAILURE,
  CATEGORIES_DROPDOWN_GET_LIST_SUCCESS,
  ORDER_DETAIL_GET_LOADING,
  ORDER_DETAIL_GET,
  ORDER_DETAIL_GET_FAILURE,
  ORDER_DETAIL_GET_SUCCESS,
  ORDERS_LOADING,
  ORDERS_GET_LIST,
  ORDERS_GET_LIST_FAILURE,
  ORDERS_GET_LIST_SUCCESS,
  ORDERS_PARTIAL_FULFILLMENT,
  ORDERS_PARTIAL_FULFILLMENT_SUCCESS,
  ORDERS_PARTIAL_FULFILLMENT_FAILURE,
  ORDER_CANCEL_LOADING,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAILURE,
  ORDER_CANCEL_ORDER,
  ORDER_SHIP_LOADING,
  ORDER_SHIP_SUCCESS,
  ORDER_SHIP_FAILURE,
  ORDER_SHIP,
  ORDER_SHIP_PARAMS_LOADING,
  ORDER_SHIP_PARAMS_FAILURE,
  ORDER_SHIP_PARAMS_SUCCESS,
  ORDER_SHIP_PARAMS,
  ORDERS_STATUS_LOADING,
  ORDERS_STATUS_GET_LIST_SUCCESS,
  ORDERS_STATUS_GET_LIST_FAILURE,
  ORDER_LIST_STATUS,
  UPLOADIMAGES_LOADING,
  UPLOADIMAGES_POST,
  UPLOADIMAGES_POST_SUCCESS,
  UPLOADIMAGES_POST_FAILURE,
  UPLOADIMAGES_CLEAR,
  RAENA_PRODUCTS_LOADING,
  RAENA_PRODUCTS_GET_LIST,
  RAENA_PRODUCTS_GET_LIST_FAILURE,
  RAENA_PRODUCTS_GET_LIST_SUCCESS,
  PRODUCTS_ALL_RECORDS,
  PRODUCTS_ALL_RECORDS_GN,
  BRAND_CATEGORIES_LOADING,
  BRAND_CATEGORIES_GET_LIST,
  BRAND_CATEGORIES_GET_LIST_FAILURE,
  BRAND_CATEGORIES_GET_LIST_SUCCESS,
  BRAND_PRODUCT_TYPE_LOADING,
  BRAND_PRODUCT_TYPE_GET_LIST,
  BRAND_PRODUCT_TYPE_GET_LIST_FAILURE,
  BRAND_PRODUCT_TYPE_GET_LIST_SUCCESS,
  BRAND_CATALOUGE_LOADING,
  BRAND_CATALOUGE_GET_LIST,
  BRAND_CATALOUGE_GET_LIST_FAILURE,
  BRAND_CATALOUGE_GET_LIST_SUCCESS,
  PRODUCTS_ALL_RECORDS_LOADING,
  PAGEBUILDER_LINKS_CREATE_LOADING,
  PAGEBUILDER_LINKS_CREATE_POST_DATA,
  PAGEBUILDER_LINKS_CREATE_POST_DATA_SUCCESS,
  PAGEBUILDER_LINKS_CREATE_POST_DATA_FAILURE,
  PAGEBUILDER_LINKS_UPDATE_LOADING,
  PAGEBUILDER_LINKS_UPDATE_POST_DATA,
  PAGEBUILDER_LINKS_UPDATE_POST_DATA_SUCCESS,
  PAGEBUILDER_LINKS_UPDATE_POST_DATA_FAILURE,
  PAGEBUILDER_LINKS_DELETE_LOADING,
  PAGEBUILDER_LINKS_DELETE_POST_DATA,
  PAGEBUILDER_LINKS_DELETE_POST_DATA_SUCCESS,
  PAGEBUILDER_LINKS_DELETE_POST_DATA_FAILURE,
  PAGEBUILDER_SECTIONS_REORDER_LOADING,
  PAGEBUILDER_SECTIONS_REORDER_POST_DATA,
  PAGEBUILDER_SECTIONS_REORDER_POST_DATA_SUCCESS,
  PAGEBUILDER_SECTIONS_REORDER_POST_DATA_FAILURE,
  PAGEBUILDER_LINKS_REORDER_LOADING,
  PAGEBUILDER_LINKS_REORDER_POST_DATA,
  PAGEBUILDER_LINKS_REORDER_POST_DATA_SUCCESS,
  PAGEBUILDER_LINKS_REORDER_POST_DATA_FAILURE,
  PAGEBUILDER_LINKS_FETCH_LOADING,
  PAGEBUILDER_LINKS_FETCH_GET_DATA_SUCCESS,
  PAGEBUILDER_LINKS_FETCH_GET_DATA_FAILURE,
  PAGEBUILDER_LINKS_FETCH_GET_DATA,
  PAGEBUILDER_ABOUT_CREATE_LOADING,
  PAGEBUILDER_ABOUT_CREATE_POST_DATA_SUCCESS,
  PAGEBUILDER_ABOUT_CREATE_POST_DATA_FAILURE,
  PAGEBUILDER_ABOUT_CREATE_POST_DATA,
  PAGEBUILDER_ABOUT_FETCH_LOADING,
  PAGEBUILDER_ABOUT_FETCH_POST_DATA,
  PAGEBUILDER_ABOUT_FETCH_POST_DATA_SUCCESS,
  PAGEBUILDER_ABOUT_FETCH_POST_DATA_FAILURE,

  PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST,
  PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_FAILURE,
  PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_ALL_LIST,
  PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_FAILURE,
  PAGEBUILDER_RAENAPRODUCTS_ADD_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_ADD,
  PAGEBUILDER_RAENAPRODUCTS_ADD_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_ADD_FAILURE,
  PAGEBUILDER_RAENAPRODUCTS_UPDATE_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_UPDATE,
  PAGEBUILDER_RAENAPRODUCTS_UPDATE_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_UPDATE_FAILURE,
  PAGEBUILDER_RAENAPRODUCTS_DELETE_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_DELETE,
  PAGEBUILDER_RAENAPRODUCTS_DELETE_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_DELETE_FAILURE,

  USERS_GET_INFO,
  USERS_LOADING,
  USERS_GET_INFO_FAILURE,
  USERS_GET_INFO_SUCCESS,
  USERS_UPDATE_PROFILE_IMAGE,
  USERS_UPDATE_PROFILE_IMAGE_SUCCESS,
  USERS_UPDATE_PROFILE_IMAGE_FAILURE,
  AUTH_REFRESH_TOKEN,
  BRAND_PROMO_LOADING,
  BRAND_PROMO_GET_LIST,
  BRAND_PROMO_GET_LIST_FAILURE,
  BRAND_PROMO_GET_LIST_SUCCESS,
  BRAND_PROMO_TYPE_LOADING,
  BRAND_PROMO_TYPE_GET_LIST,
  BRAND_PROMO_TYPE_GET_LIST_FAILURE,
  BRAND_PROMO_TYPE_GET_LIST_SUCCESS,
  BRAND_SKU_LOADING,
  BRAND_SKU_GET_LIST,
  BRAND_SKU_GET_LIST_FAILURE,
  BRAND_SKU_GET_LIST_SUCCESS,

  //MSP
  BRAND_MSP_LOADING,
  BRAND_MSP_GET_LIST,
  BRAND_MSP_GET_LIST_FAILURE,
  BRAND_MSP_GET_LIST_SUCCESS,
  BRAND_MSP_EXPORT,
  BRAND_MSP_EXPORT_LOADING,
  BRAND_MSP_EXPORT_SUCCESS,
  BRAND_MSP_EXPORT_FAILURE,

  BRAND_MSP_CONFIG,
  BRAND_MSP_CONFIG_SUCCESS,
  BRAND_MSP_CONFIG_FAILURE,
  BRAND_MSP_CONFIG_LOADING,
  BRAND_MSP_CONFIG_RESET,

  //MSP Details
  BRAND_MSP_DETAIL_LOADING,
  BRAND_MSP_GET_DETAIL,
  BRAND_MSP_GET_DETAIL_FAILURE,
  BRAND_MSP_GET_DETAIL_SUCCESS,
  
  BRAND_MSP_DETAIL_EXPORT,
  BRAND_MSP_DETAIL_EXPORT_SUCCESS,
  BRAND_MSP_DETAIL_EXPORT_LOADING,
  BRAND_MSP_DETAIL_EXPORT_FAILURE,

  BRAND_MSP_DETAIL_FILTERS_LOADING,
  BRAND_MSP_GET_DETAIL_FILTERS,
  BRAND_MSP_GET_DETAIL_FILTERS_FAILURE,
  BRAND_MSP_GET_DETAIL_FILTERS_SUCCESS,
  BRAND_MSP_GET_DETAIL_FILTERS_RESET,

  // DAHSBOARD ANALYTICS
  GET_DASHBOARD_PROVINCE,
  GET_DASHBOARD_PROVINCE_FAILURE,
  GET_DASHBOARD_PROVINCE_SUCCESS,
  GET_DASHBOARD_MONTH,
  GET_DASHBOARD_MONTH_SUCCESS,
  GET_DASHBOARD_MONTH_FAILURE,
  GET_DASHBOARD_YEAR,
  GET_DASHBOARD_YEAR_SUCCESS,
  GET_DASHBOARD_YEAR_FAILURE,
  POST_DASHBOARD_YEAR_RANGE,
  POST_DASHBOARD_YEAR_RANGE_SUCCESS,
  POST_DASHBOARD_YEAR_RANGE_FAILURE,
  DAY_REVENUE,
  DAY_REVENUE_SUCCESS,
  DAY_REVENUE_FAILURE,
  MONTH_REVENUE,
  MONTH_REVENUE_SUCCESS,
  MONTH_REVENUE_FAILURE,
  MONTH_SHIPPING,
  MONTH_SHIPPING_SUCCESS,
  MONTH_SHIPPING_FAILURE,
  DAY_UNIT_SOLD,
  DAY_UNIT_SOLD_SUCCESS,
  DAY_UNIT_SOLD_FAILURE,
  TOTAL_UNIT_SOLD,
  TOTAL_UNIT_SOLD_SUCCESS,
  TOTAL_UNIT_SOLD_FAILURE,
  GET_REVENUE_DATA,
  GET_REVENUE_DATA_SUCCESS,
  GET_REVENUE_DATA_FAILURE,
  GET_SKU_DATA,
  GET_SKU_DATA_SUCCESS,
  GET_SKU_DATA_FAILURE,
  GET_PROVINCE_DATA,
  GET_PROVINCE_DATA_SUCCESS,
  GET_PROVINCE_DATA_FAILURE,
  GET_CATEGORY_REVENUE,
  GET_CATEGORY_REVENUE_SUCCESS,
  GET_CATEGORY_REVENUE_FAILURE,
  GET_PRODUCT_DATA,
  GET_PRODUCT_DATA_SUCCESS,
  GET_PRODUCT_DATA_FAILURE,

  // Create Brand Promo
  CREATE_PROMO,
  CREATE_PROMO_SUCCESS,
  CREATE_PROMO_FAILURE,
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAILURE,
  GET_SKU,
  GET_SKU_SUCCESS,
  GET_SKU_FAILURE,
  RESET_FIELDS,
  RESET_VALIDATE_FIELDS,
  BRAND_GET_SUBSCRIPTION_SUCCESS,
  BRAND_GET_SUBSCRIPTION_FAILURE,
  BRAND_REVOKE_BRAND_ACCESS,
  BRAND_GET_LIST,
  BRAND_GET_LIST_SUCCESS,
  BRAND_GET_LIST_FAILURE,
  BRAND_GET_LIST_LOADING,
  BRAND_SELECT,
  BRAND_ERROR_DISPLAYED,
  PATCH_PROMO,
  PATCH_PROMO_SUCCESS,
  PATCH_PROMO_FAILURE,
  VALIDATE_PROMO,
  VALIDATE_PROMO_SUCCESS,
  VALIDATE_PROMO_FAILURE,
  BRAND_DATA_SAVE
}
