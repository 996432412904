import { store } from '../store'
import ShopeeLogo from '../assets/images/shoppe-logo.png'
import TokopediaLogo from '../assets/images/tokopedia-logo.png'

export const isLoggedIn = () => !!getAuthToken()

export const getAuthToken = () => {
  const state = store.getState()
  const token = state?.auth.get('idToken')
  return localStorage.getItem('idToken') || token
}

export const getRefreshToken = () => {
  const state = store.getState()
  const token = state?.auth.get('refreshToken')
  return localStorage.getItem('refreshToken') || token
}

export const getUrlStringInObject = (key) => {
  const object = window.location.search
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})

  return key ? object[key] : object
}

export const getStoreImage = (storeName) => {
  if (storeName?.toLowerCase() === 'shopee') {
    return ShopeeLogo
  } else if (storeName?.toLowerCase() === 'tokopedia') {
    return TokopediaLogo
  }
  return ''
}

export const messageStrToArray = (msg) => {
  return (typeof msg === 'string') ? msg : msg.join(', ')
}

export const isBrandUser = () => {
  const state = store.getState()
  const brandAccess = state?.subscription.get('brandAccess')
  return brandAccess || false
}

export const getFeatures = (allowedFeatures) => {
 let Brand_Catalogue_View = false
 let Brand_Category_Filter = false
 let Brand_Product_Type_Filter = false
 let Brand_Promo_View = false
 let Brand_Promo_Edit = false

 allowedFeatures.forEach((feature)=>{
    if(feature.name === 'Brand_Catalogue_View'){
      Brand_Catalogue_View = true
    }else if(feature.name === 'Brand_Category_Filter'){
      Brand_Category_Filter = true
    }else if(feature.name === 'Brand_Product_Type_Filter'){
      Brand_Product_Type_Filter = true
    }else if(feature.name === 'Brand_Promo_View'){
      Brand_Promo_View = true
    }else if(feature.name === 'Brand_Promo_Edit'){
      Brand_Promo_Edit = true
    }
 })

 return {
  Brand_Catalogue_View,
  Brand_Category_Filter,
  Brand_Product_Type_Filter,
  Brand_Promo_View,
  Brand_Promo_Edit
  }
} 