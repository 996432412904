
import { useState, useEffect, useRef, memo, useCallback } from 'react'

import { Box } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import MuiTextField from '../MuiTextField'
import MuiButton from '../MuiButton'
import MuiSelect from '../MuiSelect'
import UploadImages from '../../containers/UploadImages'
import Modal from '../Modal'
import MuiAlertDialog from '../MuiAlertDialog'

import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'

import update from 'immutability-helper'
import { DraggableContainer } from './DraggableContainer.js'
import LoadingSpinner from '../LoadingSpinner'
import PageBuilderLinksCard from '../PageBuilderLinksCard'
import EventEmitter, { Events } from '../../utilities/eventEmitter'

import {
  PageBuilderWrapper,
  ListWrapper,
  SectionContent,
  SectionContentTitle,
  FormLinkWrapper,
  FormOuterControl,
  UploadImageWrapper,
  DraggableContainerLoader
} from './style'

const PageBuilderLinks = (props) => {
  const { onGetPageBuilderLinksFetchData, pageBuilderLinksFetchData, pageBuilderLinksFetchIsLoading } = props

  useEffect(() => {
    onGetPageBuilderLinksFetchData({ resellerId: props?.resellerId })
  }, [])

  const opts = {
    enableTouchEvents: true,
    enableMouseEvents: true
  }

  return (
    <>
      <AddLinkForm {...props} mode='add' />
      {pageBuilderLinksFetchIsLoading && <LoadingSpinner />}
      {pageBuilderLinksFetchData?.length > 0 && <DndProvider backend={TouchBackend} options={opts}><DndContainer {...props} /></DndProvider>}
    </>
  )
}

export default PageBuilderLinks

const AddLinkForm = (props) => {
  const {
    onGetPageBuilderLinksCreateData,
    pageBuilderLinksCreateData,
    onGetPageBuilderLinksUpdateData,
    pageBuilderLinksUpdateData,
    onGetPageBuilderLinksFetchData,
    mode,
    data = {}
  } = props

  const form = useRef()
  const [validateOnBlur, setValidateOnBlur] = useState(false)
  const [uploadImageArray, setUploadImageArray] = useState([])
  const { t } = useTranslation()

  const handleImageChange = (data) => {
    EventEmitter.emit(Events.DSF_LINKS_IMAGE)
    setUploadImageArray(data)
    // const imageList = data
    // formik.setValues({ ...formik.values, ...{ imageUrl: imageList } })
    formik.setFieldError('imageUrl', '')
  }

  const validation = (values) => {
    setValidateOnBlur(true)
    const errors = {}
    // if (uploadImageArray.length === 0) errors.imageUrl = 'Image is required'
    return errors
  }

  const formikInitialValues = {
    // imageUrl: data?.imageUrl ? [{url:data?.imageUrl}] : [],
    imageUrl: [],
    title: data?.title || '',
    url: data?.url || '',
    linkType: data?.type || 'CUSTOM_LINK',
    price: data?.price || ''
  }

  const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g

  const formik = useFormik({
    validateOnChange: validateOnBlur,
    validateOnBlur: validateOnBlur,
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      url: Yup.string().optional().matches(urlRegex, 'Invalid url').required('URL is required')
      // linkType: Yup.string().required('Product List is required')
    }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = values
      updatedValues.price = (updatedValues?.price) ? Number(updatedValues.price) : ''
      // updatedValues.imageUrl = updatedValues?.imageUrl?.[0]?.url || ''
      updatedValues.imageUrl = uploadImageArray

      const sentenceCase = (val) => {
        const value = (val === 'ARTICLE') ? 'Blog' : val.replace('CUSTOM_', '')
        return value.split(' ').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ')
      }
      updatedValues.msgType = sentenceCase(updatedValues.linkType)

      if (mode === 'add') {
        onGetPageBuilderLinksCreateData(updatedValues)
        EventEmitter.emit(Events.DSF_LINKS_LINK_SAVE)
        EventEmitter.emit(Events.DSFADMIN_LINKADDED, {
          seller_id: props?.resellerId || '',
          url: data?.url || ''
        })
      }
      if (mode === 'edit') {
        updatedValues.id = data.id
        updatedValues.type = updatedValues.linkType
        onGetPageBuilderLinksUpdateData(updatedValues)
        EventEmitter.emit(Events.DSFADMIN_LINKEDITED, {
          seller_id: props?.resellerId || '',
          url: data?.url || ''
        })
      }
    }
  })
  const resetForm = () => {
    formik.values.title = ''
    formik.values.url = ''
    formik.values.price = ''
    setUploadImageArray([])
    formik.setFieldError('imageUrl', '')
  }

  const linkSelectValues = [{ name: 'Link', id: 'CUSTOM_LINK' }, { name: 'Product Listing', id: 'CUSTOM_PRODUCT' }, { name: 'Blogs', id: 'ARTICLE' }, { name: 'Video', id: 'VIDEO' }]

  useEffect(() => {
    if (pageBuilderLinksCreateData?.id) {
      resetForm()
      onGetPageBuilderLinksFetchData({ resellerId: props?.resellerId })
    }
  }, [pageBuilderLinksCreateData])

  useEffect(() => {
    if (pageBuilderLinksUpdateData?.id) {
      resetForm()
      onGetPageBuilderLinksFetchData({ resellerId: props?.resellerId })
    }
  }, [pageBuilderLinksUpdateData])

  useEffect(() => {
    if (data?.imageUrl?.length > 0) {
      setUploadImageArray([{ url: data?.imageUrl }])
    }
  }, [data])

  let countImageClick = 1
  const uploadImageClick = () => {
    if (countImageClick) EventEmitter.emit(Events.DSFADMIN_UPLOADIMAGE)

    countImageClick = -countImageClick + 1
  }

  return (
    <PageBuilderWrapper type={mode}>
      <form onSubmit={formik.handleSubmit} ref={form} autocomplete='off'>
        <SectionContent>
          <SectionContentTitle>
            {t('pageBuilderLink.sectionContentTitle')}
          </SectionContentTitle>
          <FormOuterControl>
            <UploadImageWrapper onClick={uploadImageClick}>
              {mode === 'add' &&
                <UploadImages
                  format='image/jpg, image/jpeg, image/png'
                  onImageChange={handleImageChange}
                  error={formik.errors.imageUrl}
                  // value={formik?.values?.imageUrl || []}
                  value={uploadImageArray}
                  mode='addImageUrl'
                  name='addImageUrl'
                  hideBrowse={
                    { maxLength: 1 }
                  }
                  isRequired
                />}
              {mode === 'edit' &&
                <UploadImages
                  format='image/jpg, image/jpeg, image/png'
                  onImageChange={handleImageChange}
                  error={formik.errors.imageUrl}
                  // value={formik?.values?.imageUrl || []}
                  value={uploadImageArray}
                  mode='editImageUrl'
                  name='editImageUrl'
                  hideBrowse={
                    { maxLength: 1 }
                  }
                  isRequired
                />}
            </UploadImageWrapper>

            <FormLinkWrapper>
              <MuiTextField
                error={Boolean(
                  formik.touched.title && formik.errors.title
                )}
                helperText={formik.touched.title && formik.errors.title}
                fullWidth
                margin='normal'
                name='title'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type='text'
                value={formik.values.title}
                variant='outlined'
                size='small'
                placeholder={t('pageBuilderLink.form.title')}
                InputProps={{
                  maxLength: 150
                }}
                isRequired
              />

              <MuiTextField
                error={Boolean(
                  formik.touched.url && formik.errors.url
                )}
                helperText={formik.touched.url && formik.errors.url}
                fullWidth
                margin='normal'
                name='url'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type='text'
                value={formik.values.url}
                variant='outlined'
                size='small'
                placeholder={t('pageBuilderLink.form.url')}
                isRequired
              />

              <MuiSelect
                error={formik.errors.linkType}
                placeholder={t('pageBuilderLink.form.select')}
                value={formik.values.linkType}
                multiline
                name='linkType'
                size='small'
                onChange={(e) => formik.handleChange(e)}
                data={linkSelectValues}
                selectlabel='name'
                selectvalue='id'
              />

              {formik.values.linkType === 'CUSTOM_PRODUCT' &&
                <MuiTextField
                  error={Boolean(
                    formik.touched.price && formik.errors.price
                  )}
                  helperText={formik.touched.price && formik.errors.price}
                  fullWidth
                  margin='normal'
                  name='price'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='text'
                  value={formik.values.price}
                  variant='outlined'
                  size='small'
                  dataType='number'
                  placeholder={t('pageBuilderLink.form.price')}
                  InputProps={{
                    maxLength: 10
                  }}
                />}

              {mode === 'add' && <MuiButton size='small' styled='primary' variant='contained' type='submit' minwidth='true'>{t('pageBuilderLink.form.save')}</MuiButton>}
              {mode === 'edit' && <MuiButton size='small' styled='primary' variant='contained' type='submit' minwidth='true'>{t('pageBuilderLink.form.update')}</MuiButton>}

            </FormLinkWrapper>
          </FormOuterControl>
        </SectionContent>
      </form>
    </PageBuilderWrapper>
  )
}

export const DndContainer = memo(function DndContainer (props) {
  const {
    onGetPageBuilderLinksFetchData,
    pageBuilderLinksFetchData,
    pageBuilderLinksDeleteData,
    onGetPageBuilderLinksDeleteData,
    onGetPageBuilderLinksUpdateData,
    onGetPageBuilderSectionsReorderData,
    pageBuilderSectionsReorderData,
    pageBuilderSectionsReorderIsLoading,
    onGetPageBuilderLinksReorderData,
    pageBuilderLinksReorderIsLoading
  } = props

  const [editMode, setEditMode] = useState(false)
  const [editData, setEditData] = useState()
  const [showModalFlag, setShowModalFlag] = useState(false)
  const [showDeleteModalFlag, setShowDeleteModalFlag] = useState(false)
  const [showDeleteModalId, setShowDeleteModalId] = useState()
  const [deleteHandlerTitle, setDeleteHandlerTitle] = useState('')

  const [cards, setCards] = useState(pageBuilderLinksFetchData || [])
  const [cardReordered, setCardReordered] = useState(false)

  const cardDrop = () => {
    setCardReordered(true)
    setTimeout(() => {
      setCardReordered(false)
    }, 1200)
  }

  const editHandler = (e) => {
    setEditData(e)
    setEditMode(true)
    setShowModalFlag(true)
  }
  const deleteHandler = (e) => {
    setShowDeleteModalFlag(true)
    setShowDeleteModalId(e)
    const type = e.sectionType === 'CUSTOM_LINK' ? 'link' : 'product'
    setDeleteHandlerTitle(`Delete ${type} (${e?.title}) forever?`)
  }
  const deleteHandlerConfirmed = (e) => {
    onGetPageBuilderLinksDeleteData({ id: showDeleteModalId?.id })
    onGetPageBuilderLinksFetchData({ resellerId: props?.resellerId })
    setShowDeleteModalFlag(false)
    setShowDeleteModalId('')
  }
  const deleteHandlerCancelled = (e) => {
    setShowDeleteModalFlag(false)
    setShowDeleteModalId('')
  }

  const statusHandler = (e) => {
    const sentenceCase = (val) => {
      const value = (val === 'ARTICLE') ? 'Blog' : val.replace('CUSTOM_', '')
      return value.split(' ').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ')
    }
    onGetPageBuilderLinksUpdateData({ id: e?.id, imageUrl: [{ url: e?.imageUrl }], isPublished: !e.isPublished, msgType: sentenceCase(e.type) })
  }

  useEffect(() => {
    setCards(pageBuilderLinksFetchData)
    setShowModalFlag(false)
  }, [pageBuilderLinksFetchData])

  useEffect(() => {
    onGetPageBuilderLinksFetchData({ resellerId: props?.resellerId })
  }, [pageBuilderLinksDeleteData])

  useEffect(() => {
    if (pageBuilderSectionsReorderData.length) {
      setCards(pageBuilderSectionsReorderData)
    }
  }, [pageBuilderSectionsReorderData])

  useEffect(() => {
    if (cardReordered) {
      const reorderData = cards.map((d, i) => { return { id: d.sectionId, order: (i + 1) } })
      onGetPageBuilderSectionsReorderData({ links: reorderData })
      EventEmitter.emit(Events.DSF_LINKS_REORDER)
    }
  }, [cardReordered])

  const moveCardNew = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        })
      )
    },
    [cards]
  )

  const movedHandler = (data) => {
    onGetPageBuilderLinksReorderData(data)
  }

  return (
    <>
      <ListWrapper>
        {cards.map((list, i) => {
          const typeComponent = (type) => {
            if (type === 'CUSTOM_PRODUCT' || type === 'VIDEO' || type === 'ARTICLE') return <PageBuilderLinksCard cardType={type} key={i} data={list} editHandler={editHandler} deleteHandler={deleteHandler} statusHandler={statusHandler} movedHandler={movedHandler} />
            if (type === 'CUSTOM_LINK') return <PageBuilderLinksCard cardType={type} key={i} data={list} editHandler={editHandler} deleteHandler={deleteHandler} statusHandler={statusHandler} />
            if (type === 'RAENA_PRODUCT') {
              const productList = {
                sectionId: list.sectionId,
                sectionName: list.sectionName,
                sectionOrder: list.sectionOrder,
                sectionType: list.sectionType,
                links: list?.links.map(e => {
                  return {
                    title: e?.product?.name || '',
                    imageUrl: e?.product?.thumbnailImages?.[0]?.url || '',
                    discountPrice: e?.dsf?.finalPrice || '',
                    tierIdValue: e?.dsf?.finalPrice || '',
                    price: e?.dsf?.retailPrice || '',
                    id: e?.product?.id,
                    isPublished: e?.dsf?.isPublished,
                    type: 'RAENA_PRODUCT'
                  }
                })
              }
              return (<PageBuilderLinksCard cardType={type} key={i} data={productList} horizontalArrows={false} showEditDeleteSwitch={false} />)
            }
            return false
          }

          const typeFunc = (list) => {
            const type = list.sectionType
            if ((type === 'CUSTOM_PRODUCT' || type === 'VIDEO' || type === 'ARTICLE') && list?.links?.length > 0) {
              return true
            } else if (type === 'CUSTOM_LINK' || type === 'RAENA_PRODUCT') {
              return true
            }
            return false
          }

          return (
            <Box key={list.sectionId}>
              {typeFunc(list) &&
                <DraggableContainer
                  key={list.sectionId}
                  index={i}
                  id={list.sectionId}
                  text={list.sectionId}
                  cardNode={typeComponent(list.sectionType)}
                  moveCard={moveCardNew}
                  cardDropped={cardDrop}
                  length={cards.length}
                />}
            </Box>
          )
        })}
      </ListWrapper>

      {pageBuilderSectionsReorderIsLoading && <DraggableContainerLoader><LoadingSpinner className='loader' /></DraggableContainerLoader>}
      {pageBuilderLinksReorderIsLoading && <DraggableContainerLoader><LoadingSpinner className='loader' /></DraggableContainerLoader>}

      {editMode &&
        <Modal showModal={showModalFlag} toggleModal={() => { setEditData({}); setShowModalFlag(false) }} showClose onClose={() => { setEditData({}); setShowModalFlag(false) }}>
          <AddLinkForm {...props} mode='edit' data={editData} style={{ width: '550px' }} />
        </Modal>}

      {showDeleteModalFlag &&
        <MuiAlertDialog
          open={showDeleteModalFlag}
          title={deleteHandlerTitle}
          onCancelled={deleteHandlerCancelled}
          onConfirmed={deleteHandlerConfirmed}
        />}
    </>
  )
})
