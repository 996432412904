import { Map } from 'immutable'

import {
  UPLOADIMAGES_CLEAR,
  UPLOADIMAGES_LOADING,
  UPLOADIMAGES_POST_SUCCESS,
  UPLOADIMAGES_POST_FAILURE
} from '../actions'

const initialState = new Map({
  // uploadImagesIsLoading: false,
  uploadImagesIsLoading: { value: false, mode: '' },
  uploadImagesError: '',
  uploadImagecontent: {}
})

export const uploadImages = (state = initialState, action) => {
  const {
    data,
    uploadImagesIsLoading,
    message,
    type
  } = action

  switch (type) {
    case UPLOADIMAGES_CLEAR:
      return initialState
    case UPLOADIMAGES_LOADING:
      return state.set('uploadImagesIsLoading', uploadImagesIsLoading)
    case UPLOADIMAGES_POST_SUCCESS:
      return state.merge({
        // uploadImagecontent: data || {}
        uploadImagecontent: { ...state.uploadImagecontent, ...data } || {}
      })
    case UPLOADIMAGES_POST_FAILURE:
      return state.set('uploadImagesError', message)

    default:
      return state
  }
}
