import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'

import {
  BrandsSelectWrapper,
  IconWrapper,
  SelectCircularProgressWrapper,
  SelectRenderValueWrapper,
  SelectRenderValueText
} from './style'
import MuiSelect from '../../components/MuiSelect/MuiSelect'
import SelectArrowIcon from '../../assets/images/select-arrow.svg'
import BrandsSelectLeftIcon from '../../assets/images/brands-select-left-icon.svg'
import { store } from '../../store'
import { BRAND_ERROR_DISPLAYED, BRAND_REVOKE_BRAND_ACCESS, BRAND_SELECT } from '../../actions'
import { ROUTE_CONNECTIONS } from '../../constants'

const BrandsSelect = ({
  brandsList,
  selectedBrand,
  brandIsLoading,
  brandError,
  onGetBrandList,
  brandsFetched,
  brandErrorDisplayed,
  userId
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!brandsFetched) {
      onGetBrandList({ userId: userId })
    }else if(brandsList.length === 0){
      store.dispatch({ type: BRAND_REVOKE_BRAND_ACCESS })
      navigate(ROUTE_CONNECTIONS)
    }
  }, [brandsFetched, onGetBrandList, userId, brandsList, navigate])

  useEffect(() => {
    if (brandError && !brandErrorDisplayed) {
      store.dispatch({ type: BRAND_ERROR_DISPLAYED })
      toast.error(brandError)
    }
  }, [brandError, brandErrorDisplayed])

  return (
    <BrandsSelectWrapper>
      <MuiSelect
        placeholder='Select'
        value={selectedBrand}
        name='brands'
        size='medium'
        onChange={(e) => {
          store.dispatch({ type: BRAND_SELECT, selected: e.target.value })
        }}
        data={brandsList}
        selectlabel='name'
        selectvalue='id'
        sx={{ minWidth: '150px' }}
        IconComponent={(props) => (
          <IconWrapper {...props}><img src={SelectArrowIcon} alt='arrow-icon' /></IconWrapper>
        )}
        renderValue={(val) => {
          return brandIsLoading
            ? (<SelectCircularProgressWrapper>
              <CircularProgress color='inherit' size={23} />
            </SelectCircularProgressWrapper>)
            : (<SelectRenderValueWrapper>
              <img height={25} src={BrandsSelectLeftIcon} alt='BrandsSelectLeftIcon' />
              <SelectRenderValueText>{val.name}
              </SelectRenderValueText>
            </SelectRenderValueWrapper>)
        }}
      />
    </BrandsSelectWrapper>
  )
}

BrandsSelect.displayName = 'TopNavBarBrandsSelect'
export default BrandsSelect
