import { styled } from '@mui/system'

export const NoSKUWrapper = styled('div')`
margin-top: 20vh;
`

export const NoSKUIconDiv = styled('div')`
width: 136px;
background: #E4E6EA;
border-radius: 50%;
margin-left: auto;
margin-right: auto;
`

export const NoSKUIconWrapper = styled('img')`
opacity: 1;
padding: 35px;
`

export const PrimaryText = styled('p')`
font-style: normal;
font-weight: 500;
font-size: 21px;
line-height: 24px;
text-align: center;
`

export const SecondaryText = styled('p')`
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 24px;
text-align: center;
`
