// This file contains general constants
import i18n from 'i18n'

export const SLUG_SHOPEE = 'shopee'

export const DEFAULT_MSG = () => i18n.t('defaultErrorMessage')
export const LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'id', label: 'Bahasa' }
]
export const DEFAULT_LANG = 'id'
