import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import { useState, useEffect, useRef } from 'react'
import { FormError } from '../MuiSelect/style'
import { VariableSizeList } from 'react-window'
import { Popper, styled, Typography } from '@mui/material'

export default function VirtualizedSearchAutoComplete (props) {
  const {
    data,
    name,
    onChange,
    selectvalue,
    selectLabel,
    size,
    placeholder,
    error,
    value,
    isClear,
    label
  } = props
  const [search, setSearch] = useState('')
  const [selectedValue, setSelectedValue] = useState(value || null)
  const handleChange = (event) => {
    console.log(event)
    // if value is not changed
    if (selectedValue && selectedValue[selectvalue] === event?.[selectvalue]) {
      return
    }
    const selectedData = { value: event?.[selectvalue], label: event?.[selectLabel] }
    onChange(selectedData?.value)
    setSearch(selectedData?.label)
  }

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  useEffect(() => {
    if (isClear) {
      clearValues()
    }
  }, [isClear])

  const clearValues = () => {
    setSelectedValue(null)
    setSearch('')
    onChange('')
  }

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      '& ul': {
        padding: 0,
        margin: 0
      }
    }
  })

  return (
    <>
      <Autocomplete
        size={size}
        id={name}
        options={data}
        sx={{ width: 300 }}
        inputValue={search}
        onInputChange={e => {
          setSearch(e?.target?.value || '')
          if (e === null) {
            clearValues()
          }
        }}
        ListboxComponent={VirtualizedListComponent}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} label={label} />}
        onChange={(e, item) => {
          handleChange(item)
        }}
        PopperComponent={StyledPopper}
        disableListWrap
      />
      {error && <FormError>{error}</FormError>}
    </>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

const VirtualizedListComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props
  const getItemSize = (index) => children?.[index]?.key.length + 35
  const Row = ({ data, index, style }) => {
    const inlineStyle = {
      ...style,
      top: style.top + 10
    }
    return (
      <Typography {...data[index]?.props} component='p' noWrap style={inlineStyle}>
        {data[index]?.key}
      </Typography>
    )
  }

  const useResetCache = (data) => {
    const ref = useRef(null)
    useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true)
      }
    }, [data])
    return ref
  }

  const itemCount = children?.length
  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={children}
          height={250}
          itemCount={itemCount}
          itemSize={getItemSize}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          overscanCount={5}
        >
          {Row}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})
