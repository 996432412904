import React, { useEffect, useState } from 'react'
import Header from './Header'
import InfoCard from './InfoCard'
import Graph from './Graphs'
import salesicon from '../../assets/images/icons/sales-analytics.svg'
import ordersicon from '../../assets/images/icons/icon-orders-analytics.svg'

// import AnalyticsTabs from './Tabs'
import { sortingByDate, getDate, getInveteredDate } from './helperFunctions'
import {
  ConnectionWrapper
} from './style'

const getDates = (dates, first, last) => {
  const [d1, d2] = dates[first].split('-')
  const [d3, d4] = dates[last].split('-')
  return `${d1}-${d2} to ${d3}-${d4}`
}

const calculate = a => {
  if (a) {
    const ans = Math.round(100 * (a[a.length - 1] - a[a.length - 2]) / a[a.length - 2])
    if (ans === Infinity) return 'NA'
    if (isNaN(ans)) return 0
    return ans
  } else return 0
}

const getSum = (array, first, last) => {
  return array.slice(first, last).reduce(function (a, b) { return a + b }, 0)
}

const getFirstLastWeekIndex = (data_) => {
  let data = [...data_]
  data.pop()

  const getFirstWeekIndex = () => {
    return data.findIndex(({ date }) => {
      return new Date(date).getDay() === 1
    })
  }

  const getLastWeekIndex = () => {
    let index = data.length - 1
    data.reverse().some(({ date }) => {
      if (new Date(date).getDay() === 0) return true
      index--
      return false
    })

    return index
  }

  return {
    firstWeek: getFirstWeekIndex(),
    lastWeek: getLastWeekIndex()
  }
}

const now = new Date()
const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30)
const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)

const Analytics = props => {
  const [fullWeekIndices, setFullWeekIndices] = useState({})
  const [value, setValue] = useState([oneWeekAgo, todayEnd])
  // const [dates_, setDates] = useState()
  const [datesOrder_, setDatesOrder] = useState()
  const [datesSale_, setDatesSale] = useState()
  const [datesProfit_, setDatesProfit] = useState()
  const [orders_, setOrders] = useState()
  const [sales_, setSales] = useState()
  const [profit_, setProfits] = useState()

  // const { t } = useTranslation()
  const { onGetAnalytics, analyticsData, isLoading, error } = props
  const { onGetAnalyticsOSP, analyticsOSPData, isLoadingOSP, errorOSP } = props
  // const { onGetAnalyticsOrderStatus, analyticsOrderStatusProps } = props
  // const { onGetAnalyticsTotalOrder, analyticsTotalOrderProps } = props
  // const { onGetAnalyticsRevenueSummary, analyticsRevenueSummaryProps } = props
  // const { onGetAnalyticsRecentOrder, analyticsRecentOrderProps } = props
  // const { onGetProductsList, productListProps } = props
  let [ordersArr, salesArr, profitArr, dates] = [[], [], [], []]
  sortingByDate(analyticsOSPData)
  analyticsOSPData.forEach(({
    date, orderCount, sales, profit
  }) => {
    dates = [...dates, getDate(date)]
    ordersArr = [...ordersArr, orderCount]
    salesArr = [...salesArr, sales]
    profitArr = [...profitArr, profit]
  })

  dates.pop(); salesArr.pop(); profitArr.pop(); ordersArr.pop()

  useEffect(() => {
    setFullWeekIndices(getFirstLastWeekIndex(analyticsOSPData))
  }, [analyticsOSPData])
  /*
    CASE-0
      Single day
    CASE-I
      start-random(Mon/Tues) to end-random(Sun/Fri) - same week
    CASE-II
      start(Monday) to end-random(Sun/Fri) - different week
    CASE-III
      start-random(Tuesday) to end(Sunday) - different week
    CASE-IV
      start-random(Tues/Wednes) to end-random(Fri/Sat) - different week
  */
  useEffect(() => {
    let [dates__, orders__, sales__, profit__] = [[], [], [], []]
    const { firstWeek, lastWeek } = fullWeekIndices

    const updateData = (firstIndex, lastIndex) => {
      dates__ = [...dates__, getDates(dates, firstIndex, lastIndex)]
      orders__ = [...orders__, getSum(ordersArr, firstIndex, lastIndex)]
      profit__ = [...profit__, getSum(profitArr, firstIndex, lastIndex)]
      sales__ = [...sales__, getSum(salesArr, firstIndex, lastIndex)]
    }

    let firstOne, lastOne
    // CASE-0
    if (firstWeek === lastWeek && firstWeek === -1 && dates.length) {
      lastOne = dates.length - 1
      updateData(0, lastOne)
    } else if (firstWeek <= 0 && lastWeek === -1 && dates__ === []) {
      // CASE-I
      firstOne = 0; lastOne = dates.length - 1
      updateData(firstOne, lastOne)
    } else if (firstWeek >= 0 && lastWeek >= 0) {
      // CASE - III
      firstOne = 0; lastOne = firstWeek - 1
      // Case III - First day is monday
      if (lastOne !== -1 && dates.length) {
        updateData(firstOne, lastOne)
      }
      // CASE - II
      for (let i = firstWeek; i <= lastWeek + 6; i = i + 7) {
        let last = i + 6
        if (lastWeek <= i) last = dates.length - 1
        try {
          updateData(i, last)
        } catch (e) {
          console.log('error occurred')
        }
      }
    }

    const removeZeros = (arr, dateArr) => {
      let arrWithoutZeros = []
      let dateArrWithoutZeros = []

      if (arr) {
        arr.map((value, index) => {
          if (value) {
            arrWithoutZeros = [...arrWithoutZeros, value]
            dateArrWithoutZeros = [...dateArrWithoutZeros, dateArr[index]]
          } return ''
        })
      }
      // console.log([arrWithoutZeros, dateArrWithoutZeros])
      return [arrWithoutZeros, dateArrWithoutZeros]
    }

    let arr = removeZeros(orders__, dates__); setOrders(arr[0]); setDatesOrder(arr[1])
    arr = removeZeros(sales__, dates__); setSales(arr[0]); setDatesSale(arr[1])
    arr = removeZeros(profit__, dates__); setProfits(arr[0]); setDatesProfit(arr[1])
  }, [fullWeekIndices])

  useEffect(() => {
    let startTime, endTime
    if (value[0] === null && value[1] === null) {
      startTime = getInveteredDate(oneWeekAgo)
      endTime = getInveteredDate(todayEnd)
    } else {
      startTime = getInveteredDate(value[0])
      endTime = getInveteredDate(value[1])
    }

    onGetAnalytics({ startTime, endTime })
    onGetAnalyticsOSP({ startTime, endTime })
    // onGetAnalyticsOrderStatus({ startTime, endTime })
    // onGetAnalyticsTotalOrder({ startTime, endTime })
    // onGetAnalyticsRevenueSummary({ startTime, endTime })
    // onGetAnalyticsRecentOrder({ startTime, endTime })
    // onGetProductsList({ limit: 4 })
  }, [
    onGetAnalytics,
    onGetAnalyticsOSP,
    // onGetAnalyticsOrderStatus,
    // onGetAnalyticsTotalOrder,
    // onGetAnalyticsRevenueSummary,
    // onGetAnalyticsRecentOrder,
    // onGetProductsList,
    value
  ])

  const infoCardData = [{
    heading: 'Sales',
    text: `Rp ${analyticsData?.totalSales?.toLocaleString()}`,
    bgColor: '#43C88B',
    icon: salesicon
  }, {
    heading: 'Total Orders',
    text: analyticsData?.totalOrders,
    link: '/orders',
    showArrow: true,
    bgColor: '#625CE0',
    icon: ordersicon
  }, {
    heading: 'Completed Orders',
    text: analyticsData?.completedOrderCount,
    link: '/orders',
    status: 'COMPLETED',
    showArrow: true,
    bgColor: '#43C8CC',
    icon: ordersicon
  }, {
    heading: 'Pending Orders',
    text: analyticsData?.pendingOrderCount,
    link: '/orders',
    status: 'PENDING',
    showArrow: true,
    bgColor: '#FAB912',
    icon: ordersicon
  }, {
    heading: 'Cancelled Orders',
    text: analyticsData?.cancelledOrderCount,
    link: '/orders',
    status: 'CANCELLED',
    showArrow: true,
    bgColor: '#F56A83',
    icon: ordersicon
  }]

  return (
    <ConnectionWrapper>
      <Header
        value={value}
        setValue={setValue}
      />
      <InfoCard
        isLoading={isLoading}
        error={error}
        data={infoCardData}
      />
      <Graph
        error={errorOSP}
        isLoading={isLoadingOSP}
        growthOrders={calculate(orders_)}
        growthSales={calculate(sales_)}
        growthProfits={calculate(profit_)}
        labelsOrders={datesOrder_}
        labelsSales={datesSale_}
        labelsProfits={datesProfit_}
        dataOrders={orders_}
        dataSales={sales_}
        dataProfits={profit_}
      />
      {/* <AnalyticsTabs
        orderStatusProps={analyticsOrderStatusProps}
        totalOrderProps={analyticsTotalOrderProps}
        revenueSummaryProps={analyticsRevenueSummaryProps}
        recentOrderProps={analyticsRecentOrderProps}
        productListProps={productListProps}
      /> */}
    </ConnectionWrapper>
  )
}

Analytics.displayName = 'Analytics'
export default Analytics
