// import { useCallback } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import OrderDetailsModal from '../../components/OrderDetailsModal'

// import {
//   ORDER_CANCEL_ORDER
// } from '../../actions'

const OrderDetailsModalContainer = props => {
  // const orderCancelProps = useSelector((state) => {
  //   const {
  //     ordersCancel
  //   } = state

  //   return {
  //     ordersCancel: ordersCancel?.get('success'),
  //     error: ordersCancel?.get('error'),
  //     isLoading: ordersCancel?.get('isLoading')
  //   }
  // })

  // const callDispatch = useDispatch()

  // const onPostCancelOrder = useCallback((query) => {
  //   callDispatch({ type: ORDER_CANCEL_ORDER, payload: query })
  // }, [callDispatch])

  return (
    <OrderDetailsModal {...
      {
        ...props
      }}
    />
  )
}

OrderDetailsModalContainer.displayName = 'OrderDetailsModalContainer'
export default OrderDetailsModalContainer
