import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const SelectWrapper = styled(Box)(({ theme }) => `
  width:100%;
  display:flex;
  flex-direction:column;
  & > div {
    padding:0;
    width:100%;
    background-color: white;
    border-radius: 4px;
  }
  & > div > div{
    padding-top:7px;
    padding-bottom:7px;
    padding-left:14px;
    font-size:14px;
  }
  & > div > div > div{
    background-color: transparent;
  }
`)

export const FormLabel = styled('label')(`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
  width:100%;
  padding-bottom:10px;
`)

export const FormReq = styled('span')(`
  font-size: 12px;
  color: #DA2A52;
  padding-left:5px;
`)

export const FormSelectOuterWrap = styled(Box)(`
  display:flex;
  flex-direction:column;
`)

export const FormError = styled(Box)(`
  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
`)

export const FormSelectWrap = styled(Box)(`
  position: relative;
  margin-bottom: 8px;
`)

export const InputLabel = styled('span')(`
  margin-left: 15px;
  margin-bottom: -30px;
  margin-top: 11px;
  opacity: 50%;
  font-size: 14px;
  z-index: 1;
  position: absolute;
  width: fit-content;
`)

export const SelectGrid = styled(Box)(({ theme }) => `
  width:40%;
  display:flex;
  flex-direction:column;
  & > div {
    padding:0;
    width:100%;
    background-color: white;
    border-radius: 4px;
  }
  & > div > div{
    padding-top:7px;
    padding-bottom:7px;
    padding-left:14px;
    font-size:14px;
  }
`)

export const Select = styled(Box)(({ theme }) => `
  width:40%;
  display:flex;
  flex-direction:column;
  & > div {
    padding:0;
    width:100%;
    background-color: white;
    border-radius: 4px;
  }
  & > div > div{
    padding-top:7px;
    padding-bottom:7px;
    padding-left:14px;
    font-size:14px;
  }
`)
