import React from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TitleGrid, Title, TopButtonsGroup } from './style'

const TitleRow = ({ totalPromo }) => {
  const { t } = useTranslation()

  return (
    <TitleGrid container>
      <Grid item md={6} sm={12} xs={12}>
        <Title>{t('brandCatalouge.sku')}({totalPromo})</Title>
      </Grid>
      <TopButtonsGroup item md={6} sm={12} xs={12}>
        <Grid container direction='row-reverse'>

        </Grid>
      </TopButtonsGroup>
    </TitleGrid>
  )
}

TitleRow.displayName = 'BrandPromoTitleRowComponent'
export default TitleRow
