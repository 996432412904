import React from 'react'

import Layout from '../Layout'
import BrandPromoComponent from '../../components/BrandsPromo/BrandsPromo'
import { connect } from 'react-redux'
import { BRAND_PROMO_TYPE_GET_LIST, BRAND_SKU_GET_LIST, BRAND_PROMO_GET_LIST, BRAND_DATA_SAVE, RESET_VALIDATE_FIELDS } from '../../actions'

const BrandPromo = (props) => {
  return (
    <Layout>
      <BrandPromoComponent {...props} />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { brandsPromo, brands, subscription } = state
  return {
    promoIsloading: brandsPromo.get('promoIsloading'),
    promoList: brandsPromo.get('promoList'),
    totalPromo: brandsPromo.get('totalPromo'),
    promoTypeList: brandsPromo.get('promoTypeList'),
    skuList: brandsPromo.get('skuList'),
    selectedBrand: brands.get('selectedBrand'),
    brandIsLoading: brands.get('isLoading'),
    brandDetails: brandsPromo.get('brandDetails'),
    features : subscription.get('features')
  }
}

const mapDispatchToProps = dispatch => ({
  onGetBrandPromoTypeList: (query) => {
    dispatch({ type: BRAND_PROMO_TYPE_GET_LIST, payload: query })
  },
  onGetBrandSkuList: (query) => {
    dispatch({ type: BRAND_SKU_GET_LIST, payload: query })
  },
  onGetBrandPromosList: (query) => {
    dispatch({ type: BRAND_PROMO_GET_LIST, payload: query })
  },
  saveBrandDetails: (brand) => {
    dispatch({ type: BRAND_DATA_SAVE, data: brand })
  },
  resetUpdateData: () => {
    dispatch({ type: RESET_VALIDATE_FIELDS })
  }
})

BrandPromo.displayName = 'BrandPromoContainer'
export default connect(mapStateToProps, mapDispatchToProps)(BrandPromo)
