import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Joi from 'joi'
import { serializeError } from 'serialize-error'
import { useTranslation } from 'react-i18next'
import AuthLayout from '../AuthLayout'
import EventEmitter, { Events } from '../../utilities/eventEmitter'

import {
  Button,
  CheckBox,
  CheckBoxLabel,
  CheckBoxField,
  ExtraFields,
  ForgotPassword,
  Form,
  Input,
  BottomLinkHref,
  BottomLinkWrapper,
  ForgotPasswordLink,
  Title,
  AuthParent,
  LoginImageWrapper,
  LoginImage,
  LeftSideContainer,
  ImageWrapperText
} from '../AuthLayout/style'

import { ROUTE_CONNECTIONS, ROUTE_FORGOT_PASSWORD, ROUTE_SIGNUP, ROUTE_BRANDS_DASHBOARD } from '../../constants'
import LoginImageLeft from '../../assets/images/login.svg'
import { isBrandUser } from '../../utilities/app'

const Login = props => {
  const {
    error,
    onMount,
    onLogin,
    isLoggedIn
  } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [isScroll, setIsScroll] = useState(false)
  const imageRef = useRef()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    const height = imageRef.current.offsetHeight + 20
    setIsScroll(height > document.body.offsetHeight)
  }, [imageRef])

  useEffect(() => {
    const height = imageRef.current.offsetHeight + 20
    setIsScroll(height > document.body.offsetHeight)
  }, [imageRef])

  useEffect(() => {
    onMount()
  }, [onMount])

  useEffect(() => {
    if (error) toast.error(error)
  }, [error])

  useEffect(() => {
    if (isLoggedIn) {
      if (isBrandUser()) {
        navigate(ROUTE_BRANDS_DASHBOARD)
      } else {
        navigate(ROUTE_CONNECTIONS)
      }
    }
  }, [isLoggedIn, navigate])

  const renderFormTitle = () => <Title>{t('auth.pleaseLogin')}</Title>

  const loginFormInput = Joi.object({
    email: Joi.string().pattern(/\S+@\S+\.\S+/).message({
      'string.pattern.base': t('auth.invalidEmail')
    }),
    password: Joi.string().min(4)
  })

  const handleFormSubmit = async e => {
    e.preventDefault()
    EventEmitter.emit(Events.SAAS_LOGIN, { email })

    const data = { email, password }
    const response = await loginFormInput.validate(data)
    const { message: errorMessage } = serializeError(response?.error || {})

    if (errorMessage) {
      return toast.error(errorMessage)
    }
    onLogin(data)
  }

  const renderForm = () => (
    <Form onSubmit={handleFormSubmit}>
      <Input
        id='email'
        name='email'
        onChange={e => setEmail(e.target.value)}
        placeholder={t('auth.email')}
        type='email'
        value={email}
      />
      <Input
        id='password'
        name='password'
        onChange={e => setPassword(e.target.value)}
        placeholder={t('auth.password')}
        type='password'
        value={password}
      />
      <ExtraFields>
        <CheckBoxField>
          <CheckBox
            id='rememberMe'
            name='rememberMe'
            type='checkbox'
            checked={rememberMe}
            onChange={e => setRememberMe(e.target.checked)}
          />
          <CheckBoxLabel onClick={() => setRememberMe(!rememberMe)}>
            {t('auth.rememberMe')}
          </CheckBoxLabel>
        </CheckBoxField>
        <ForgotPasswordLink to={ROUTE_FORGOT_PASSWORD}>
          <ForgotPassword>
            {t('auth.forgotPassword')}?
          </ForgotPassword>
        </ForgotPasswordLink>
      </ExtraFields>
      <Button type='submit'>{t('auth.login')}</Button>
    </Form>
  )

  const renderBottomLink = () => (
    <BottomLinkWrapper>
      {t('auth.newUser')}?
      <BottomLinkHref to={ROUTE_SIGNUP} onClick={() => EventEmitter.emit(Events.SAAS_SIGNUP)}>
        {t('auth.signUp')}
      </BottomLinkHref>
    </BottomLinkWrapper>
  )

  return (
    <AuthParent>
      <LeftSideContainer isScroll={isScroll} ref={imageRef}>
        <LoginImageWrapper>
          <LoginImage src={LoginImageLeft} />
        </LoginImageWrapper>
        <ImageWrapperText>{t('auth.imageText')}!</ImageWrapperText>
      </LeftSideContainer>
      <AuthLayout>
        {renderFormTitle()}
        {renderForm()}
        {renderBottomLink()}
      </AuthLayout>
    </AuthParent>
  )
}

Login.propTypes = {
  error: PropTypes.string,
  onMount: PropTypes.func,
  onLogin: PropTypes.func,
  isLoggedIn: PropTypes.bool
}

Login.displayName = 'LoginComponent'
export default Login
