import {
  PageBuilderWrapper,
  SectionContent,
  FormOuterControl
} from './style'

const PageBuilderAppearance = (props) => {
  return (
    <PageBuilderWrapper>
      <SectionContent>
        <FormOuterControl>
          Coming Soon
        </FormOuterControl>
      </SectionContent>
    </PageBuilderWrapper>
  )
}

export default PageBuilderAppearance
