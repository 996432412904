import { connect } from 'react-redux'
import {
  CATEGORIES_ATTRIBUTES_GET_LIST,
  CATEGORIES_BRANDS_GET_LIST,
  PRODUCTS_CREATE_INFO_POST_DATA,
  UPDATE_PRODUCT_FULLFILL_VIA
} from '../../actions'
import ProductListedOnModal from './ProductListedOnModal'

const mapStateToProps = (state) => {
  const { stores, categories } = state
  return {
    storeList: stores.get('content').toJS(),
    categoryList: categories.get('content').toJS(),
    categoryBrandsList: categories.get('categoryBrandsList').toJS(),
    categoryAttributesList: categories.get('categoryAttributesList').toJS()
  }
}

const mapDispatchToProps = dispatch => ({
  onGetCategoryBrandsList: (query) => {
    dispatch({ type: CATEGORIES_BRANDS_GET_LIST, payload: query })
  },
  onGetCategoryAttributesList: (query) => {
    dispatch({ type: CATEGORIES_ATTRIBUTES_GET_LIST, payload: query })
  },
  onUpdateProductFullFilVia: (query) => {
    dispatch({ type: UPDATE_PRODUCT_FULLFILL_VIA, payload: query })
  },
  onGetProductCreateInfoData: (query) => {
    dispatch({ type: PRODUCTS_CREATE_INFO_POST_DATA, payload: query })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductListedOnModal)
