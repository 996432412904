import { connect } from 'react-redux'
import Sidebar from './Sidebar'

const mapStateToProps = (state) => {
  const { subscription } = state

  return {
    brandAccess: subscription.get('brandAccess'),
    resellerAccess: subscription.get('resellerAccess'),
    features : subscription.get('features')
  }
}

export default connect(mapStateToProps)(Sidebar)
