import React, { useState, useEffect } from 'react'

import { TextField, InputLabel, MenuItem, FormControl, Select } from '@mui/material'

import {
  Modal,
  ModalContent,
  CloseButton,
  InputFieldsWrapper,
  ShipOrderButton,
  ShipOrderHeading,
  FormControlParent,
  ShipOrderButtonWrapper
} from './style'

const TextFieldSmall = ({
  label,
  state,
  setState,
  isError,
  errors,
  setErrors,
  name
}) => {
  const handleChange = (e) => {
    setState(e.target.value)
    const dErrors = { ...errors }
    dErrors[name] = false
    setErrors({ ...dErrors })
  }
  return (
    <FormControlParent>
      <TextField
        id={name}
        fullWidth
        error={isError}
        label={label}
        value={state}
        size='small'
        onChange={handleChange}
      />
    </FormControlParent>
  )
}

const ShipOrder = ({
  setShowModal,
  marketplaceStoreId,
  marketplaceOrderId,
  onPost,
  orderShipData,
  error,
  isLoading,
  orderParams,
  orderParamsError,
  orderParamsIsLoading,
  ...rest
}) => {
  // const orderParams = {
  //   info_needed: {
  //     dropoff: [
  //       'branch_id',
  //       'sender_real_name',
  //       'tracking_number',
  //       'slug'
  //     ]
  //   },
  //   dropoff: {
  //     branch_list: [{
  //       branch_id: 1,
  //       region: 'ID',
  //       state: 'string',
  //       city: 'string',
  //       address: 'string ADDRESS 1',
  //       zipcode: 'string',
  //       district: 'string',
  //       town: 'string'
  //     },
  //     {
  //       branch_id: 2,
  //       region: 'ID',
  //       state: 'string',
  //       city: 'string',
  //       address: 'string ADDRESS 2',
  //       zipcode: 'string',
  //       district: 'string',
  //       town: 'string'
  //     },
  //     {
  //       branch_id: 3,
  //       region: 'ID',
  //       state: 'string',
  //       city: 'string',
  //       address: 'string ADDRESS 3',
  //       zipcode: 'string',
  //       district: 'string',
  //       town: 'string'
  //     }],
  //     slug_list: [{
  //       slug: 'slug1',
  //       slug_name: 'slug_name_1'
  //     },
  //     {
  //       slug: 'slug2',
  //       slug_name: 'slug_name_2'
  //     },
  //     {
  //       slug: 'slug3',
  //       slug_name: 'slug_name_3'
  //     }]
  //   }
  // }

  // const orderParams = {
  //   info_needed: {
  //     non_integrated: [
  //       'tracking_number'
  //     ]
  //   }
  // }

  const onShipBtnClick = () => {
    let errors = {}
    let isError = false
    if (!info) errors = { ...errorsArr, info: true }
    if (info === 'pickup') {
      if (!addressId) errors = { ...errors, addressId: true }
      if (!pickupTimeId) errors = { ...errors, pickupTimeId: true }
      isError = !addressId || !pickupTimeId
    } else if (info === 'dropoff') {
      if (!branchId) errors = { ...errors, branchId: true }
      if (!senderName) errors = { ...errors, senderName: true }
      if (!dropOffTNumber) errors = { ...errors, dropOffTNumber: true }
      if (!slug) errors = { ...errors, slug: true }
      isError = !branchId || !senderName || !slug
    } else if (info === 'non_integrated') {
      if (!niTNumber) errors = { ...errors, niTNumber: true }
      isError = !niTNumber
    }
    setErrorsArr({ ...errors })

    let apiBody = { marketplaceStoreId, marketplaceOrderId }

    if (!isError) {
      if (info === 'pickup') {
        apiBody = {
          ...apiBody,
          pickup: {
            addressId,
            pickupTimeId
          }
        }
      } else if (info === 'dropoff') {
        apiBody = {
          ...apiBody,
          dropoff: {
            branchId,
            senderRealName: senderName,
            trackingNumber: dropOffTNumber,
            slug
          }
        }
      } else if (info === 'non_integrated') {
        apiBody = { ...apiBody, nonIntegrated: { trackingNumber: niTNumber } }
      }
      onPost({ ...apiBody })
    }
  }

  const [info, setInfo] = useState()
  const [addressId, setAddressId] = useState('')
  const [pickupTimeId, setPickupTimeId] = useState('')
  const [branchId, setBranchId] = useState('')
  const [senderName, setSenderName] = useState('')
  const [dropOffTNumber, setDropOffTNumber] = useState('')
  const [slug, setSlug] = useState('')
  const [niTNumber, setNITNumber] = useState('')
  const [errorsArr, setErrorsArr] = useState({})

  const FormControlComponent = ({
    label,
    values,
    state,
    error,
    name,
    setState
  }) => {
    const handleChange = (event) => {
      setState(event.target.value)
      const dErrors = { ...errorsArr }
      dErrors[name] = false
      setErrorsArr({ ...dErrors })
    }
    return (
      <FormControlParent>
        <FormControl fullWidth size='small' error={error}>
          <InputLabel id={label}>{label}</InputLabel>
          <Select
            labelId={label}
            id={label}
            value={state}
            label={label}
            onChange={handleChange}
          >
            {values.map(({ label, value }) =>
              (
                <MenuItem
                  key={value}
                  value={value}
                >
                  {label}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </FormControlParent>
    )
  }

  // On address change, time option should be refreshed
  useEffect(() => {
    setPickupTimeId('')
  }, [addressId])

  useEffect(() => {
    setAddressId('')
    setPickupTimeId('')
    setBranchId('')
    setSenderName('')
    setDropOffTNumber('')
    setSlug('')
    setNITNumber('')
    setErrorsArr({})
  }, [info])

  const getAddressList = () => orderParams?.pickup?.address_list.filter(each => each.address_id === addressId)[0]?.time_slot_list || []

  const getDate = (date_) => {
    const date = new Date(date_ * 1000)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  return (
    <Modal>
      <ModalContent>
        <ShipOrderHeading>
          Ship Order
        </ShipOrderHeading>
        <CloseButton onClick={() => setShowModal(false)}>×</CloseButton>
        <InputFieldsWrapper>
          <FormControlComponent
            label='Information Needed'
            values={Object.keys(orderParams?.info_needed)?.map(value => ({ label: value, value: value }))}
            state={info}
            name='info'
            error={errorsArr?.info || false}
            setState={setInfo}
          />
          {info === 'pickup' &&
            <FormControlComponent
              label='Addresses'
              values={orderParams?.pickup?.address_list.map(({ address_id: addressId, address }) => ({ label: address, value: addressId })) || []}
              state={addressId}
              name='addressId'
              error={errorsArr?.addressId || false}
              setState={setAddressId}
            />}
          {info === 'pickup' && addressId &&
            <FormControlComponent
              label='Pickup Time'
              values={getAddressList().map(({ pickup_time_id: tId, time_text: tT, date }) => ({ label: `${getDate(date)} ${tT}`, value: tId }))}
              state={pickupTimeId}
              setState={setPickupTimeId}
              name='pickupTimeId'
              error={errorsArr?.pickupTimeId || false}
            />}
          {info === 'dropoff' &&
            <FormControlComponent
              label='Branch'
              values={orderParams?.dropoff?.branch_list.map(({ branch_id: branchId, address }) => ({ label: address, value: branchId }))}
              state={branchId}
              name='branchId'
              error={errorsArr?.branchId || false}
              setState={setBranchId}
            />}
          {info === 'dropoff' &&
            <TextFieldSmall
              label='Sender Name'
              errors={errorsArr}
              name='senderName'
              setErrors={setErrorsArr}
              state={senderName}
              setState={setSenderName}
              isError={errorsArr?.senderName || false}
            />}
          {info === 'dropoff' &&
            <TextFieldSmall
              label='Tracking Number'
              name='dropOffTNumber'
              errors={errorsArr}
              setErrors={setErrorsArr}
              state={dropOffTNumber}
              setState={setDropOffTNumber}
              isError={errorsArr?.dropOffTNumber}
            />}
          {info === 'dropoff' &&
            <FormControlComponent
              label='Slug'
              values={orderParams?.dropoff?.slug_list.map(({ slug_name: slugName, slug }) => ({ label: slugName, value: slug }))}
              state={slug}
              name='slug'
              error={errorsArr?.slug || false}
              setState={setSlug}
            />}
          {info === 'non_integrated' &&
            <TextFieldSmall
              label='Tracking Number'
              name='niTNumber'
              errors={errorsArr}
              setErrors={setErrorsArr}
              state={niTNumber}
              setState={setNITNumber}
              isError={errorsArr?.niTNumber}
            />}
        </InputFieldsWrapper>
        <ShipOrderButtonWrapper>
          <ShipOrderButton
            onClick={onShipBtnClick}
          >
            Ship Order
          </ShipOrderButton>
        </ShipOrderButtonWrapper>
      </ModalContent>
    </Modal>
  )
}

export default ShipOrder
