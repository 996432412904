import { styled } from '@mui/system'
import { Box, Button } from '@mui/material'

export const Main = styled('div')`
`
export const BoxWraper = styled(Box)`
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32%;
  height: auto;
  background-color:white;
  padding:3vh 20px;
  border-radius:15px;
`
export const InputWraper = styled('div')`
width: 95%;
margin-top: 9px;
margin-bottom: 9px;
`
export const Label = styled('label')`
font-size: 25px;
`
export const BtnWraper = styled('div')`
    margin-top: 45px;
    float: right;
`
export const UploadImageWrapper = styled(Box)(`
  width:120px;
`)
export const FlexWraper = styled('div')(`
display: flex;
justify-content: start;
flex-direction: row;
align-items: center;
`)
export const MuiButton = styled(Button)(`
min-width: 160px;
box-sizing: border-box;
width: fit-content;
font-family:'Noto Sans';
background-color: #162E9A; 
font-weight: 500;
:hover{background-color: #162E9A}
`)
export const MuiButtonCancel = styled(Button)(`
min-width: 160px;
margin-right: 15px;
`)
