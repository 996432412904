import { useState, useEffect } from 'react'

import { IconButton, Typography, Box, Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'

import MuiButton from '../MuiButton'
import MuiSwitch from '../MuiSwitch'
import Modal from '../Modal'

import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'

import update from 'immutability-helper'
import { iconImage, iconEdit, iconDelete, iconPinned } from '../../assets/images/icons'

import { ROUTE_LINKSTORE_RAENAPRODUCTS, ROUTE_LINKSTORE_RAENAPRODUCTS_ADD } from '../../constants'

import { getIndonesianCurrency } from '../../utilities/utils'

import {
  ListItemWrap,
  ListItemInnerWrap,
  AvatarWrap,
  ButtonWrap,
  CardInnerWrapper,
  CardMedia,
  CardTitle,
  CardActions,
  CardContent,
  ListItemTextWrapper,
  ListArrayItemTextWrapper,
  HandleWrapper,
  HandleStyleArrows,
  HandleStyleDots,
  Price,
  DiscountWrapper,
  DiscountPrice,
  DiscountPercentage,
  StoreAdded,
  MoreVerticalIcon,
  RaenaProductNoDataFound,
  CardHeading,
  IconButtonWrapper,
  ImgIconPinned,
  ProfitWrapper,
  ProfitCardMedia,
  ProfitTitle1,
  ProfitTitle2,
  ProfitTitle3,
  SlideWrapper,
  CardRowWrapper,
  CountWrapper
} from './style'

const ImgIconButton = (props) => {
  return (
    <IconButton color='primary' component='span' onClick={props.onClick}>
      {typeof props.src === 'string' ? <img src={props.src} alt='' width='16px' height='16px' /> : props.src}
    </IconButton>
  )
}

const PageBuilderLinksCard = (props) => {
  const { cardType, data, editHandler, deleteHandler, statusHandler, movedHandler, horizontalArrows = true, showEditDeleteSwitch = true } = props
  const [productData, setProductData] = useState(data?.links || [])
  const [productMoved, setProductMoved] = useState(false)
  const { t } = useTranslation()
  const raenaProductContent = t('pageBuilderLink.raenaProduct', { returnObjects: true })

  const moveHandler = (dragIndex, hoverIndex) => {
    const dragCard = productData[dragIndex]
    setProductData(
      update(productData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      })
    )
    setProductMoved(true)
  }

  const handlerLeft = (index, length = data?.links?.length) => {
    const dragIndex = index - 1
    const hoverIndex = index
    if (hoverIndex === 0) {
      moveHandler(hoverIndex, length - 1)
    } else if (dragIndex !== hoverIndex) {
      moveHandler(dragIndex, hoverIndex)
    }
  }

  const handlerRight = (index, length = data?.links?.length) => {
    const dragIndex = index + 1
    const hoverIndex = index
    if (dragIndex === length) {
      moveHandler(hoverIndex, 0)
    } else if (dragIndex !== hoverIndex) {
      moveHandler(dragIndex, hoverIndex)
    }
  }

  useEffect(() => {
    setProductData(data?.links)
  }, [data?.links])

  useEffect(() => {
    if (productMoved) {
      const reorderData = productData.map((d, i) => { return { id: d.id, order: productData.length - i } })
      movedHandler({ links: reorderData })
      setProductMoved(false)
    }
  }, [productMoved])

  return (
    <>
      {cardType === 'CUSTOM_LINK' &&
        <ListItemWrap className={`${cardType}`}>
          <AvatarWrap>
            {data?.imageUrl ? <img src={data?.imageUrl} alt='' width='100%' /> : <img src={iconImage} alt='' width='40px' height='40px' />}
          </AvatarWrap>
          <ListItemTextWrapper primary={data?.title || ''} secondary={data?.url || ''} />
          <ButtonWrap>
            <ImgIconButton src={iconEdit} onClick={() => editHandler(data)} />
            <ImgIconButton src={iconDelete} onClick={() => deleteHandler(data)} />
            <MuiSwitch value={data.isPublished} name={data.id} type='ios' label=' ' onChange={() => statusHandler(data)} />
            {data?.clicks > 0 && <Typography variant='caption' component='p'>{data?.clicks || 0} {t('pageBuilderLink.clicks')}</Typography>}
          </ButtonWrap>
        </ListItemWrap>}

      {cardType !== 'CUSTOM_LINK' &&
        <ListItemWrap className={`carousel ${cardType}`}>

          {productData.length >= 1 && cardType === 'RAENA_PRODUCT' && data?.sectionName && <CardHeading>{t('pageBuilderLink.raenaProductTitle1')} <MuiButton size='small' to={ROUTE_LINKSTORE_RAENAPRODUCTS}>{t('pageBuilderLink.manage')}</MuiButton></CardHeading>}
          {productData.length >= 1 && cardType === 'VIDEO' && data?.sectionName && <CardHeading>{t('pageBuilderLink.videoTitle')}</CardHeading>}
          {productData.length >= 1 && cardType === 'ARTICLE' && data?.sectionName && <CardHeading>{t('pageBuilderLink.blogTitle')}</CardHeading>}
          {productData.length >= 1 && cardType === 'CUSTOM_PRODUCT' && data?.sectionName && <CardHeading>{t('pageBuilderLink.customProductTitle')}</CardHeading>}
          <ListItemInnerWrap>
            {productData.length >= 1 && productData.map((list, i) => {
              return (
                <PageBuilderLinksInnerCard
                  {...props}
                  cardType={cardType}
                  data={data}
                  list={list}
                  index={i}
                  key={i}
                  horizontalArrows={horizontalArrows}
                  showEditDeleteSwitch={showEditDeleteSwitch}
                  handlerLeft={(i) => handlerLeft(i)}
                  handlerRight={(i) => handlerRight(i)}
                  editHandler={(i) => editHandler(i)}
                  deleteHandler={(i) => deleteHandler(i)}
                />
              )
            })}
          </ListItemInnerWrap>
          {productData.length === 0 && cardType === 'RAENA_PRODUCT' &&
            <RaenaProductNoDataFound>
              {typeof raenaProductContent === 'string' && <ListItemTextWrapper primary={data?.sectionName || ''} secondary={data?.sectionDescription || t('pageBuilderLink.raenaProduct')} />}
              {typeof raenaProductContent === 'object' &&
                <ListArrayItemTextWrapper>
                  <CardHeading>{t('pageBuilderLink.raenaProductTitle1')}</CardHeading>
                  {raenaProductContent.map((text, index) => (
                    <p key={index}>{index+1}. {text}</p>
                  ))}
                </ListArrayItemTextWrapper>}
              <MuiButton size='small' to={ROUTE_LINKSTORE_RAENAPRODUCTS_ADD} styled='primary' variant='contained'>{t('pageBuilderLink.addRaenaProducts')}</MuiButton>
            </RaenaProductNoDataFound>
          }
        </ListItemWrap>}
    </>
  )
}

export default PageBuilderLinksCard

const PageBuilderLinksInnerCard = (props) => {
  const {
    cardType,
    list,
    index,
    addHandler,
    editHandler,
    deleteHandler,
    statusHandler,
    showAddToStore = false,
    showEditDeleteSwitch = props?.showEditDeleteSwitch || true,
    horizontalArrows,
    handlerLeft,
    handlerRight,
    menuHandler,
    menuOptions = [],
    pageBuilderRaenaProductsUpdateData
  } = props
  const { t } = useTranslation()
  const [showModalFlag, setShowModalFlag] = useState(false)
  const [mode, setMode] = useState('')

  const handlerLeftFunc = (index) => {
    handlerLeft(index)
  }

  const handlerRightFunc = (index) => {
    handlerRight(index)
  }

  const handlerAddToStoreFunc = () => {
    setMode('add')
    setShowModalFlag(true)
  }

  const editHandlerFunc = (list) => {
    if (cardType === 'RAENA_PRODUCT') { handlerEditToStoreFunc() } else { editHandler(list) }
  }

  const handlerEditToStoreFunc = () => {
    setMode('edit')
    setShowModalFlag(true)
  }

  const confirmedHandlerFunc = (e) => {
    if (mode === 'add') addHandler(e)
    if (mode === 'edit') editHandler(e)
  }

  useEffect(() => {
    if (pageBuilderRaenaProductsUpdateData) {
      setShowModalFlag(false)
    }
  }, [pageBuilderRaenaProductsUpdateData])

  const productPrice = list?.tierIdValue + (list?.margin || 0)

  return (
    <>
      <CardInnerWrapper key={index} className='cardWrapper'>
        <CardMedia className='cardImgWrapper'>
          {list?.imageUrl ? <img src={list?.imageUrl} alt={list?.title} width='100%' /> : <img src={iconImage} alt='' width='45px' height='45px' className='dummy' />}
          <LongMenu menuOptions={menuOptions} menuClicked={menuHandler} selected='' object={list} />
          {list?.isPinned && <ImgIconPinned src={iconPinned} />}
        </CardMedia>
        <CardContent>
          <CardTitle variant='body2'>{list?.title}</CardTitle>
          <CardActions>
            {cardType === 'RAENA_PRODUCT' &&
              <>
                <DiscountWrapper>
                  {list?.tierIdValue && <DiscountPrice variant='button'>IDR {getIndonesianCurrency(productPrice)}</DiscountPrice>}
                  {list?.tierIdValue < list?.price && <DiscountPercentage variant='button'>{Math.round(((list?.price - productPrice) / list?.price) * 100)}% Off</DiscountPercentage>}
                </DiscountWrapper>
                {list?.tierIdValue < list?.price && <Price variant='button'>IDR {getIndonesianCurrency(list?.price)}</Price>}
                {showAddToStore &&
                  <>
                    {list?.added && <MuiButton size='small' color='primary' variant='contained' type='submit' fullwidth='true' onClick={() => handlerAddToStoreFunc(list)}>{t('pageBuilderLink.addToStore')}</MuiButton>}
                    {!list?.added && <StoreAdded>{t('pageBuilderLink.added')}</StoreAdded>}
                  </>}
              </>}
            {cardType === 'CUSTOM_PRODUCT' && list?.price && <DiscountPrice variant='button'>{t('pageBuilderLink.price')} : IDR {list?.price}</DiscountPrice>}
            <CardRowWrapper>
              {(list.clicks || list.clicks === 0) && <CountWrapper>{list.clicks} Clicks</CountWrapper>}
              {showEditDeleteSwitch &&
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <ImgIconButton src={iconEdit} onClick={() => editHandlerFunc(list)} />
                    <ImgIconButton src={iconDelete} onClick={() => deleteHandler(list)} />
                  </Box>
                  <Box>
                    <MuiSwitch value={list.isPublished} name={list.id} type='ios' label=' ' onChange={() => statusHandler(list, list.isPublished)} />
                  </Box>
                </Box>}
            </CardRowWrapper>
          </CardActions>
        </CardContent>
        {horizontalArrows &&
          <HandleWrapper>
            <HandleStyleArrows onClick={() => handlerLeftFunc(index)}><ArrowLeftIcon /></HandleStyleArrows>
            <HandleStyleDots />
            <HandleStyleArrows onClick={() => handlerRightFunc(index)}><ArrowRightIcon /></HandleStyleArrows>
          </HandleWrapper>}
      </CardInnerWrapper>
      {cardType === 'RAENA_PRODUCT' && <PageBuilderProfitMargin mode={mode} data={list} showModal={showModalFlag} toggleModal={() => { setShowModalFlag(false) }} showClose onClose={() => { setShowModalFlag(false) }} confirmedHandler={confirmedHandlerFunc} />}
    </>
  )
}

export { PageBuilderLinksInnerCard }

const LongMenu = (props) => {
  const { menuOptions = [], menuClicked, selected = '', object = {} } = props
  const ITEM_HEIGHT = 48
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (opt) => {
    setAnchorEl(null)
    menuClicked(opt, object)
  }

  return (
    <>
      {menuOptions?.length > 0 &&
        <div>
          <IconButtonWrapper
            aria-label='more'
            id='long-button'
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleClick}
          >
            <MoreVerticalIcon />
          </IconButtonWrapper>
          <Menu
            id='long-menu'
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5
              }
            }}
          >
            {menuOptions?.map((option) => (
              <MenuItem key={option.label} selected={option.label === selected} onClick={() => handleClose(option.label)}>
                {option.icon && <img src={option.icon} alt={option.label} style={{ marginRight: '5px' }} />} {option.label}
              </MenuItem>
            ))}
          </Menu>
        </div>}
    </>
  )
}

const PageBuilderProfitMargin = (props) => {
  const { mode, data, showModal, toggleModal, onClose, confirmedHandler } = props
  const [marginValue, setMarginValue] = useState(data.margin || 0)
  const { t } = useTranslation()

  const valueMarks = [
    { value: data.tierIdValue, label: `Min. Rp ${data.tierIdValue}` },
    { value: data.productRetailPrice, label: `Max. Rp ${data.productRetailPrice}` }
  ]

  const valueChange = (value) => {
    setMarginValue(value - data.tierIdValue)
    return value
  }

  return (
    <Modal showModal={showModal} toggleModal={toggleModal} showClose onClose={onClose}>
      {data &&
        <ProfitWrapper>
          <ProfitCardMedia>
            {data?.imageUrl ? <img src={data?.imageUrl} alt='' width='100%' /> : <img src={iconImage} alt='' width='45px' height='45px' className='dummy' />}
          </ProfitCardMedia>
          <ProfitTitle1>{t('pageBuilderLink.setProfitMargin')}</ProfitTitle1>
          <ProfitTitle2>{t('pageBuilderLink.myProfit')}: Rp {Math.round(marginValue)}</ProfitTitle2>
          <ProfitTitle3>{t('pageBuilderLink.sellingPrice')}: Rp {data?.tierIdValue + Math.round(marginValue)}</ProfitTitle3>
          <Box style={{ width: '100%' }}>
            <SlideWrapper
              aria-label='Always visible'
              defaultValue={data.tierIdValue + marginValue}
              marks={valueMarks}
              getAriaValueText={valueChange}
              min={data.tierIdValue}
              max={data.productRetailPrice}
              valueLabelDisplay='on'
            />
          </Box>
          <MuiButton styled='linkStoreRaenaProductMobile' size='small' minwidth='true' color='primary' variant='contained' type='button' onClick={() => confirmedHandler({ ...data, mode: mode, margin: marginValue })}>{t('pageBuilderLink.confirm')}</MuiButton>
        </ProfitWrapper>}
    </Modal>
  )
}
