import { styled } from '@mui/system'
import { ListItemText, Paper, Box, Icon } from '@mui/material'

const media = {
  large_laptop: '@media(max-width: 2560px)',
  laptop: '@media(max-width: 1000px)',
  tablet: '@media(max-width: 768px)',
  mobile: '@media(max-width: 600px)'
}

export const PaperWrapper = styled(Paper)(`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  position: relative;
  z-index: 2; // 1
  border-radius: 0px;
`)

export const HamburgerIcon = styled('img')(`
  cursor: pointer;
  height: 20px;
  width: 20px;
  padding: 0 10px;
`)

export const Logo = styled('img')(`
  height:35px;
  display: block;
  filter: contrast(0%);
`)

export const Notification = styled(Box)(`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-left: 1px solid #3C4D7440;
  border-right: 1px solid #3C4D7440;
  padding:0 10px;
  display:flex;
  @media (max-width: 600px) {
    display: none;
  }
`)

export const NotificationIcon = styled('img')(`
  padding: 0 1.5px;
`)

export const AvatarIcon = styled('img')(`
  border-radius: 50%;
  height: 55px;
  width: 60px;
`)

export const ListItemTextNew = styled(ListItemText)(`
  padding-left: 15px;
  color: #333;
  * {line-height:17px !important;}
`)

export const BrandsSelectWrapper = styled('div')(`
  margin-left: 8vh;
  ${media.laptop} {
    margin-left: 0vh;
  } 
  & > div > div > div > div > div{
    padding: 5px !important;
  }
`)

export const IconWrapper = styled(Icon)(`
width: unset !important;
line-height: 0.8em;
`)

export const SelectCircularProgressWrapper = styled('div')(`
  margin-bottom: -5px;
`)

export const SelectRenderValueWrapper = styled('div')(`
  display: flex;
`)

export const SelectRenderValueText = styled('div')(`
  margin-left: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #536082;
`)
