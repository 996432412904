import { Map } from 'immutable'
import {
    GET_REVENUE_DATA,
    GET_REVENUE_DATA_SUCCESS,
    GET_REVENUE_DATA_FAILURE,
    GET_SKU_DATA,
    GET_SKU_DATA_SUCCESS,
    GET_SKU_DATA_FAILURE,
    GET_PROVINCE_DATA,
    GET_PROVINCE_DATA_SUCCESS,
    GET_PROVINCE_DATA_FAILURE,
    GET_CATEGORY_REVENUE,
    GET_CATEGORY_REVENUE_SUCCESS,
    GET_CATEGORY_REVENUE_FAILURE,
    GET_PRODUCT_DATA,
    GET_PRODUCT_DATA_SUCCESS,
    GET_PRODUCT_DATA_FAILURE,
} from '../actions'

const initialState = new Map({
    isRevenueLoading: false,
    isRevenueLoaded: false,
    isRevenueError: false,
    revenueDetails: [],
    isSkuDataLoading: false,
    isSkuDataLoaded: false,
    isSkuDataError: false,
    skuData: [],
    isProvinceLoading: false,
    isProvinceLoaded: false,
    isProvinceError: false,
    provinceData: [],
    isCategoryLoading: false,
    isCategoryLoaded: false,
    isCategoryError: false,
    categoryData: [],
    isProductLoading: false,
    isProductLoaded: false,
    isProductError: false,
    productData: []
});


export const brandData = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_REVENUE_DATA:
            return { ...state, isRevenueLoading: true, isRevenueLoaded: false, isRevenueError: false };
        case GET_REVENUE_DATA_SUCCESS:
            return { ...state, isRevenueLoading: false, isRevenueLoaded: true, revenueDetails: data };
        case GET_REVENUE_DATA_FAILURE:
            return { ...state, isRevenueLoading: false, isRevenueLoaded: false, isRevenueError: true };
        case GET_SKU_DATA:
            return { ...state, isSkuDataLoading: true, isSkuDataLoaded: false, isSkuDataError: false };
        case GET_SKU_DATA_SUCCESS:
            return { ...state, isSkuDataLoading: false, isSkuDataLoaded: true, skuData: data };
        case GET_SKU_DATA_FAILURE:
            return { ...state, isSkuDataLoading: false, isSkuDataLoaded: false, isSkuDataError: true };
        case GET_PROVINCE_DATA:
            return { ...state, isProvinceLoading: true, isProvinceLoaded: false, isProvinceError: false };
        case GET_PROVINCE_DATA_SUCCESS:
            return { ...state, isProvinceLoading: false, isProvinceLoaded: true, provinceData: data };
        case GET_PROVINCE_DATA_FAILURE:
            return { ...state, isProvinceLoading: false, isProvinceLoaded: false, isProvinceError: true };
        case GET_CATEGORY_REVENUE:
            return { ...state, isCategoryLoading: true, isCategoryLoaded: false, isCategoryError: false };
        case GET_CATEGORY_REVENUE_SUCCESS:
            return { ...state, isCategoryLoading: false, isCategoryLoaded: true, categoryData: data };
        case GET_CATEGORY_REVENUE_FAILURE:
            return { ...state, isCategoryLoading: false, isCategoryLoaded: false, isCategoryError: true };
        case GET_PRODUCT_DATA:
            return { ...state, isProductLoading: true, isProductLoaded: false, isProductError: false };
        case GET_PRODUCT_DATA_SUCCESS:
            return { ...state, isProductLoading: false, isProductLoaded: true, productData: data };
        case GET_PRODUCT_DATA_FAILURE:
            return { ...state, isProductLoading: false, isProductLoaded: false, isProductError: true };

        default:
            return state;
    }
}