import { styled } from '@mui/system'
import { Link } from 'react-router-dom'
import { Box } from '@mui/material'

export const SidebarWrapper = styled(Box)((props) => `
  margin: 0;
  width: 200px;
  height: 100vh;
  background: #162E9A;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
  @media (max-width: 1000px) {
    z-index: 15;
    position:fixed;
    top:0px;
    left:0px;
    display: ${!props?.isSidebarVisible ? 'none' : 'block'}
  }
`)

export const SidebarWrapperOverlay = styled(Box)((props) => `
  display: none;
  @media (max-width: 1000px) {
    display:block;
    display:none;
    z-index: 11;
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background:#ffffffcc;
    display: ${!props?.isSidebarVisible ? 'none' : 'block'}
  }

`)

export const CustomLink = styled(Link)((props) => `
  display: flex;
  height: 50px;
  width: 90%;
  font-size:14px;
  align-items: center;
  text-decoration: none;
  font-weight:normal;
  color: #fff;
  opacity: 0.5;
  padding-left: 16px;
  gap: 1rem;
  :hover {
    width:calc(100% - 16px);
    font-weight:bold;
    box-shadow: inset 6px 0px 0px white;
    background-color: rgba(60,77,116,0.6);
    opacity: 1;
  }

  ${props.active && `
    width:calc(100% - 8px);
    font-weight:bold;
    box-shadow: inset 6px 0px 0px white;
    opacity: 1;
  `}
`)

export const SidebarNavItemWrapper = styled(Box)(`
  width:100%;
  height:calc(100vh - 150px);
`)

export const SidebarNavIcon = styled('img')(`
  max-height: 20px;
  max-width: 20px;
`)

export const ImageWraper = styled('div')(`
width: 25px;
padding: 10px;
text-align:center;
`)

export const SidebarNavMuiIcon = styled(Box)(`
  padding: 0 18px 0 15px;
  max-height: 20px;
  max-width: 20px;
`)

export const SidebarNavText = styled(Box)(`
  font-size: 14px;
`)

export const LogoWrapper = styled(Box)(`
  height: 75px;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
`)

export const Logo = styled('img')(`
  height: 33px;
`)

export const MenuTextWrapper = styled('p')(`
font-weight: 600;
font-size: 18px;
line-height: 25px;
color: rgba(255, 255, 255, 0.2);
margin-left: 20px;
`)
