import React from 'react'
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined'
import {
  Main, BoxM, GridM, ItemM, TextP, Titlebottom, LinearProgressM, DashboardTitle, ItemProgress,
  Itemheading, ItemProgressp, DivWraper, HelpWraper, ImageWraper, BtnWraper, TextHelperW, TableWraper
} from './style'
import needHelpImage from '../../assets/images/need-help.png'
import MuiButton from '../MuiButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import EventEmitter, { Events } from '../../utilities/eventEmitter'

// import dashboardIcon from '../../assets/images/dashboard-icon.svg'

const Dashboard = (data) => {
  const { t } = useTranslation()
  const initialData = {
    cta: data?.cta || [
      {
        title: t('dashboard.contactNow'),
        url: 'https://www.raenabeauty.com/kontak-kami/'
      }
    ]
  }

  const contactNowClick = () => {
    EventEmitter.emit(Events.CONTACT_NOW, { page: 'Dashboard' })
  }

  function createData (name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein }
  }

  const rows = [
    createData('Sylvia Goodman', 159, 6.0, 'done'),
    createData('Rosie Bowman', 237, 9.0, 'done'),
    createData('Sally Bass', 262, 16.0, 'Cancel')
  ]
  return (
    <Main>
      {/* <img src={dashboardIcon} alt='Dashboard' /> */}
      <DivWraper>
        <DashboardTitle>
          <AutoGraphOutlinedIcon fontSize='large' sx={{ marginRight: '2%' }} />{t('dashboard.welcome')} John Doe
        </DashboardTitle>
        <Titlebottom>{t('dashboard.updatedAt')} 12 Novenber 2018 - 12:00 PM</Titlebottom>
      </DivWraper>

      <BoxM>
        {/* sx={{ width: '100%' }} rowSpacing={3} columnSpacing={{ xs: 1, sm: 12, md: 3 }} */}
        <GridM container>
          <GridM ItemM xs={6} sm={3} md={3}>
            <ItemM>13,000 <TextP>{t('dashboard.totalOrders')}</TextP> </ItemM>
          </GridM>
          <GridM ItemM xs={6} sm={3} md={3}>
            <ItemM>$ 40M <TextP>{t('dashboard.sales')}</TextP> </ItemM>
          </GridM>
          <GridM ItemM xs={6} sm={3} md={3}>
            <ItemM>12 <TextP>{t('dashboard.totalOrders')}</TextP> </ItemM>
          </GridM>
          <GridM ItemM xs={6} sm={3} md={3}>
            <ItemM>3 <TextP>{t('dashboard.cancelledOrder')}</TextP> </ItemM>
          </GridM>
        </GridM>
      </BoxM>

      <BoxM>
        <GridM container>
          <GridM md={7}>
            <ItemProgress>
              <Itemheading>{t('dashboard.revenueBreakUp')} </Itemheading>
              <ItemProgressp> {t('dashboard.top5Categories')} </ItemProgressp>
              <GridM container>
                <GridM xs={12} sm={12} md={2}>
                  <ItemProgressp>Skin Care </ItemProgressp>
                </GridM>
                <GridM xs={12} sm={12} md={8}>
                  <LinearProgressM sx={{ margin: '15px 0px', width: '90%' }} variant='determinate' value={60} />
                </GridM>
                <GridM xs={12} sm={12} md={2}>
                  <ItemProgressp> 32,123  €</ItemProgressp>
                </GridM>
                <GridM xs={12} sm={12} md={2}>
                  <ItemProgressp>Skin Care </ItemProgressp>
                </GridM>
                <GridM xs={12} sm={12} md={8}>
                  <LinearProgressM sx={{ margin: '15px 0px', width: '90%' }} variant='determinate' value={70} />
                </GridM>
                <GridM xs={12} sm={12} md={2}>
                  <ItemProgressp> 32,123  € </ItemProgressp>
                </GridM>
                <GridM xs={12} sm={12} md={2}>
                  <ItemProgressp>Skin Care </ItemProgressp>
                </GridM>
                <GridM xs={12} sm={12} md={8}>
                  <LinearProgressM sx={{ margin: '15px 0px', width: '90%' }} variant='determinate' value={60} />
                </GridM>
                <GridM xs={12} sm={12} md={2}>
                  <ItemProgressp> 32,123  € </ItemProgressp>
                </GridM>
                <GridM xs={12} sm={12} md={2}>
                  <ItemProgressp>Skin Care </ItemProgressp>
                </GridM>
                <GridM xs={12} sm={12} md={8}>
                  <LinearProgressM sx={{ margin: '15px 0px', width: '90%' }} variant='determinate' value={60} />
                </GridM>
                <GridM xs={12} sm={12} md={2}>
                  <ItemProgressp> 32,123  € </ItemProgressp>
                </GridM>
                <GridM xs={12} sm={12} md={2}>
                  <ItemProgressp>Skin Care </ItemProgressp>
                </GridM>
                <GridM xs={12} sm={12} md={8}>
                  <LinearProgressM sx={{ margin: '15px 0px', width: '90%' }} variant='determinate' value={90} />
                </GridM>
                <GridM xs={12} sm={12} md={2}>
                  <ItemProgressp> 32,123  € </ItemProgressp>
                </GridM>
              </GridM>
            </ItemProgress>
          </GridM>
          <GridM md={5}>
            <ItemProgress>

              <GridM container>
                <GridM xs={12} sm={12} md={4}>
                  <HelpWraper>{t('dashboard.needHelp')}? </HelpWraper>
                </GridM>
                <GridM ItemProgress xs={12} sm={12} md={8}>
                  <ImageWraper src={needHelpImage} alt='' />
                </GridM>
              </GridM>
              <TextHelperW>{t('dashboard.portalQuestion')}?</TextHelperW>
              <BtnWraper onClick={contactNowClick}>
                {initialData.cta.map(link => (<MuiButton color='primary' variant='contained' key={link.title} href={link.url} target='_blank'>{link.title}</MuiButton>))}
              </BtnWraper>
            </ItemProgress>
          </GridM>
        </GridM>
      </BoxM>

      <BoxM>
        <GridM container>
          <GridM md={7}>
            <TableWraper>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('dashboard.customerName')}</TableCell>
                      <TableCell align='right'>{t('dashboard.items')}</TableCell>
                      <TableCell align='right'>{t('dashboard.totalCost')}</TableCell>
                      <TableCell align='right'>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component='th' scope='row'>
                          {row.name}
                        </TableCell>
                        <TableCell align='right'>{row.calories}</TableCell>
                        <TableCell align='right'>{row.fat}</TableCell>
                        <TableCell align='right'>{row.carbs}</TableCell>
                        <TableCell align='right'>{row.protein}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableWraper>
          </GridM>
          <GridM md={5}>
            {/* <TableWraper>
            </TableWraper> */}
          </GridM>
        </GridM>
      </BoxM>

    </Main>

  )
}

export default Dashboard
