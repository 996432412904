import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Typography } from '@mui/material'
import { ROUTE_PRODUCTS } from '../../constants'
import MuiButton from '../MuiButton'
import { noop } from 'lodash'
import ProductAttribute from './ProductAttribute'
import { getStoreImage } from '../../utilities/app'

import {
  ProductWrapper,
  Title,
  FormControl,
  SectionContent,
  SubmitWrapper,
  ProductAttributeWrapper,
  CardHeaderWrap, CardOuterWrap, ImgWrap, StoreLogo
} from './style'
import VirtualizedSearchAutoComplete from '../AutoComplete/VirtualizedSearchAutoComplete'

const AdditionalInfo = (props) => {
  const {
    productCreateData,
    categoryBrandsList,
    categoryAttributesList,
    onGetProductCreateInfoData,
    selectedStores,
    isAddToStore,
    goBack,
    onAddToStoreSuccess,
    attributeListValues
  } = props

  const form = useRef()
  const ProductAttributeComponentRef = useRef()
  const navigate = useNavigate()
  const [validateOnBlur, setValidateOnBlur] = useState(false)

  const handleFormOnChange = (values) => {
    if (validateOnBlur) {
      validation()
    }
  }

  const validation = (values) => {
    setValidateOnBlur(true)
    const errors = ProductAttributeComponentRef.current.validateAttributesFunc()
    // if (imageIdList.length === 0) { errors.image = 'Image is required' } else if (imageIdList.length < 4) { errors.image = 'Minimum 3 Image should be added' }
    return errors
  }

  const formikInitialValues = {
    brands: attributeListValues?.[0]?.brand?.brandId || '' // ONCE WE HAVE ANOTHER STORE THIS CODE WILL CHANGE
  }

  const formik = useFormik({
    validateOnChange: validateOnBlur,
    validateOnBlur: validateOnBlur,
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: Yup.object({
      brands: Yup.string().required('Brands is required')
    }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const brandId = values?.brands
      const data = {
        products: [
          {
            rsellViewId: productCreateData?.rsellViewId,
            brand: {
              id: brandId,
              name: categoryBrandsList?.find(cbl => cbl.brandId === brandId)?.originalBrandName || ''
            },
            attributes: ProductAttributeComponentRef.current.getAttributesDataFunc()
          }
        ],
        selectedStores: selectedStores.map(store => ({
          storeId: store.id,
          storeName: store.shop_name,
          marketPlaceShopId: store.marketplace_shop_id
        }))
      }
      onGetProductCreateInfoData(data)

      if (isAddToStore) {
        onAddToStoreSuccess()
        return
      }
      navigate(`${ROUTE_PRODUCTS}?q=${encodeURIComponent(productCreateData?.name)}`)
    }
  })

  return (
    <>
      <ProductWrapper isAddToStore={isAddToStore}>
        <form onSubmit={formik.handleSubmit} ref={form} autocomplete='off' onChange={handleFormOnChange}>
          <SectionContent>
            <FormControl>
              <Title>Additional Information Required</Title>
            </FormControl>
          </SectionContent>

          <SectionContent sx={{ mt: 4 }}>
            <FormControl>
              <Typography variant='subtitle1'>Source Product Info</Typography>
            </FormControl>
          </SectionContent>
          <SectionContent>
            <>
              <CardOuterWrap isAddToStore={isAddToStore}>
                <CardHeaderWrap
                  avatar={<ImgWrap>{selectedStores?.[0].shop_logo && <img src={selectedStores?.[0].shop_logo} alt={selectedStores?.[0].shop_name} />}</ImgWrap>}
                  title={<><StoreLogo src={getStoreImage(selectedStores?.[0].marketplace)} alt={selectedStores?.[0].marketplace} />{selectedStores?.[0].shop_name}</>}
                  subheader={selectedStores?.[0].description}
                />
                <VirtualizedSearchAutoComplete
                  title='Brands'
                  error={formik.errors.brands}
                  placeholder='Select'
                  value={formik.values.brands}
                  multiline
                  name='brands'
                  size='small'
                  // onSelect={(value) => { handleCategorySelected(value) }}
                  onChange={(e) => formik.setFieldValue('brands', e)}
                  data={categoryBrandsList?.map(item => ({ label: item?.displayBrandName, ...item }))}
                  selectLabel='originalBrandName'
                  selectvalue='brandId'
                  sx={{ minWidth: '250px' }}
                  isRequired
                />
              </CardOuterWrap>
            </>
          </SectionContent>
          <SectionContent>
            <ProductAttributeWrapper isAddToStore={isAddToStore}>
              <ProductAttribute
                {...props}
                formik={formik}
                categorySelectedValue={productCreateData.categoryId}
                categoryAttributesList={categoryAttributesList}
                ref={ProductAttributeComponentRef}
                validateOnBlur={validateOnBlur}
                handleFormOnChange={handleFormOnChange}
                attributeListValues={attributeListValues?.[0]?.attributeList}
              />
            </ProductAttributeWrapper>
          </SectionContent>

          <SectionContent sx={{ mt: 4 }}>
            <SubmitWrapper>
              <MuiButton minwidth='true' color='secondary' variant='outlined' onClick={isAddToStore ? () => goBack() : noop} to={isAddToStore ? false : ROUTE_PRODUCTS}>Back</MuiButton>
              <MuiButton minwidth='true' styled='primary' variant='contained' type='submit'>Submit</MuiButton>
            </SubmitWrapper>
          </SectionContent>
        </form>
      </ProductWrapper>
    </>
  )
}

export default AdditionalInfo
