import { useCallback } from 'react'
import Layout from '../Layout'
import { useDispatch, useSelector } from 'react-redux'
import OrdersComponent from '../../components/Orders'
import { useLocation } from 'react-router-dom'

import {
  ORDERS_GET_LIST,
  ORDERS_PARTIAL_FULFILLMENT,
  ORDER_DETAIL_GET,
  STORES_GET_LIST,
  ORDER_CANCEL_ORDER,
  ORDER_SHIP_PARAMS,
  ORDER_SHIP,
  ORDER_LIST_STATUS
} from '../../actions'

const Orders = props => {
  const data = useLocation()
  const status = data?.state?.status || ''

  const orderProps = useSelector((state) => {
    const {
      orders
    } = state

    return {
      totalRecords: orders?.get('totalRecords'),
      orders: orders?.get('content').toJS(),
      error: orders?.get('error'),
      isLoading: orders?.get('isLoading')
    }
  })

  const orderStatusListProps = useSelector((state) => {
    const {
      orderStatusList
    } = state

    return {
      orderStatusList: orderStatusList?.get('content').toJS(),
      errorOrderStatusList: orderStatusList?.get('error'),
      orderStatusListIsLoading: orderStatusList?.get('orderStatusListIsLoading')
    }
  })

  const orderDetailProps = useSelector((state) => {
    const {
      orderDetails
    } = state
    return {
      orderDetails: orderDetails?.get('content').toJS(),
      errorOrderDetails: orderDetails?.get('error'),
      isLoadingOrderDetails: orderDetails?.get('isLoading')
    }
  })

  const storeListProps = useSelector((state) => {
    const {
      stores
    } = state
    return {
      stores: stores?.get('content').toJS()
    }
  })

  const orderParamProps = useSelector((state) => {
    const {
      orderParams
    } = state

    return {
      orderParams: orderParams?.get('content').toJS(),
      orderParamsError: orderParams?.get('error'),
      orderParamsIsLoading: orderParams?.get('isLoading')
    }
  })

  const orderCancelProps = useSelector((state) => {
    const {
      ordersCancel
    } = state

    return {
      ordersCancelStatus: ordersCancel?.get('success'),
      cancelOrderError: ordersCancel?.get('error'),
      isCancelOrderLoading: ordersCancel?.get('isLoading')
    }
  })

  const orderShipProps = useSelector((state) => {
    const {
      orderShip
    } = state

    return {
      ordersShipStatus: orderShip?.get('success'),
      shipOrderError: orderShip?.get('error'),
      isShipOrderLoading: orderShip?.get('isLoading')
    }
  })

  const partialfulfillmentOrderProps = useSelector((state) => {
    const {
      orders
    } = state;
    const updatePartialfulfillmentData = orders?.get('updatePartialfulfillmentData');
    return updatePartialfulfillmentData
  })

  const callDispatch = useDispatch()
  const callDispatchCancel = useDispatch()
  const callDispatchDetails = useDispatch()
  const callDispatchStoresList = useDispatch()
  const paramCallDispatch = useDispatch()
  const callDispatchShip = useDispatch()
  const callDispatchOrderStatusList = useDispatch()
  const onGetShipParams = useCallback((query) => {
    paramCallDispatch({ type: ORDER_SHIP_PARAMS, payload: query })
  }, [paramCallDispatch])

  const onGetOrderDetails = useCallback((query) => {
    callDispatchDetails({ type: ORDER_DETAIL_GET, payload: query })
  }, [callDispatchDetails])

  const onGetOrderList = useCallback((query) => {
    callDispatch({ type: ORDERS_GET_LIST, payload: query })
  }, [callDispatch])

  const onUpdatePartialFulfillment = useCallback((query) => {
    callDispatch({ type: ORDERS_PARTIAL_FULFILLMENT, payload: query })
  }, [callDispatch])

  const onGetStoreList = useCallback((query) => {
    callDispatchStoresList({ type: STORES_GET_LIST })
  }, [callDispatchStoresList])

  const onPostCancelOrder = useCallback((query) => {
    callDispatchCancel({ type: ORDER_CANCEL_ORDER, payload: query })
  }, [callDispatchCancel])

  const onPostShipOrder = useCallback((query) => {
    callDispatchShip({ type: ORDER_SHIP, payload: query })
  }, [callDispatchShip])

  const onGetOrderStatusList = useCallback((query) => {
    callDispatchOrderStatusList({ type: ORDER_LIST_STATUS, payload: query })
  }, [callDispatchOrderStatusList])

  return (
    <Layout>
      <OrdersComponent {...
        {
          status,
          ...orderProps,
          onGetOrderDetails,
          ...orderDetailProps,
          onGetOrderList,
          onUpdatePartialFulfillment,
          partialfulfillmentOrderProps,
          onGetStoreList,
          ...storeListProps,
          onGetShipParams,
          ...orderParamProps,
          onPostCancelOrder,
          ...orderCancelProps,
          onPostShipOrder,
          ...orderShipProps,
          onGetOrderStatusList,
          ...orderStatusListProps,
          ...props
        }}
      />
    </Layout>
  )
}

Orders.displayName = 'Orders'
export default Orders
