import { call, put, takeLatest } from 'redux-saga/effects'
import {
  GET_ANALYTICS,
  ANALYTICS_LOADING,
  ANALYTICS_GET_FAILURE,
  ANALYTICS_GET_SUCCESS,
  GET_ANALYTICSOSP_LIST,
  ANALYTICSOSP_LIST_LOADING,
  ANALYTICSOSP_LIST_GET_FAILURE,
  ANALYTICSOSP_LIST_GET_SUCCESS,
  GET_ANALYTICS_ORDER_STATUS,
  GET_ANALYTICS_ORDER_STATUS_LOADING,
  GET_ANALYTICS_ORDER_STATUS_SUCCESS,
  GET_ANALYTICS_ORDER_STATUS_FAILURE,
  GET_ANALYTICS_TOTAL_ORDER,
  ANALYTICS_TOTAL_ORDER_LOADING,
  ANALYTICS_TOTAL_ORDER_SUCCESS,
  ANALYTICS_TOTAL_ORDER_FAILURE,
  GET_ANALTYICS_REVENUE_SUMMARY,
  ANALTYICS_REVENUE_SUMMARY_LOADING,
  ANALTYICS_REVENUE_SUMMARY_SUCCESS,
  ANALTYICS_REVENUE_SUMMARY_FAILURE,
  GET_ANALTYICS_RECENT_ORDERS,
  ANALTYICS_RECENT_ORDERS_LOADING,
  ANALTYICS_RECENT_ORDERS_SUCCESS,
  ANALTYICS_RECENT_ORDERS_FAILURE
} from '../actions'

import {
  API_GET_ANALYTICS,
  API_GET_ANALYTICSOSP_LIST,
  API_GET_ANALYTICS_ORDER_STATUS,
  API_GET_ANALYTICS_TOTAL_ORDER,
  API_GET_ANALYTICS_REVENUE_SUMMARY,
  API_GET_ANALYTICS_RECENT_ORDERS
} from '../constants'

import { getApi } from '../utilities/axios'

function * onGetAnalytics (action) {
  yield put({ type: ANALYTICS_LOADING, isLoading: true })
  try {
    const response = yield call(getApi, API_GET_ANALYTICS, action?.payload)
    // console.log(response)
    // const response = {
    //   "success": true,
    //   "message": "OK",
    //   "data": [{
    //     "_id": "2",
    //     "totalOrders": 75,
    //     "totalSales": 500,
    //     "completedOrderCount": 25,
    //     "pendingOrderCount": 25,
    //     "cancelledOrderCount": 25
    //   }]
    // }
    yield put({ type: ANALYTICS_GET_SUCCESS, data: response?.data.data[0] || {} })
  } catch (e) {
    yield put({ type: ANALYTICS_GET_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: ANALYTICS_GET_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: ANALYTICS_GET_SUCCESS, data: {} })
  }
  yield put({ type: ANALYTICS_LOADING, isLoading: false })
}

function * onGetAnalyticsOSPList (action) {
  yield put({ type: ANALYTICSOSP_LIST_LOADING, isLoadingOSP: true })
  try {
    const response1 = yield call(getApi, API_GET_ANALYTICSOSP_LIST, { ...action.payload, cardType: 'Orders' })
    const response2 = yield call(getApi, API_GET_ANALYTICSOSP_LIST, { ...action.payload, cardType: 'Sales' })
    const response3 = yield call(getApi, API_GET_ANALYTICSOSP_LIST, { ...action.payload, cardType: 'Profit' })
    const response = response1.data.data.map((object, index) => {
      const { totalSales: sales } = response2.data.data[index]
      const { totalProfit: profit } = response3.data.data[index]
      return { ...object, sales, profit }
    })

    yield put({ type: ANALYTICSOSP_LIST_GET_SUCCESS, data: response || [] })

    // // console.log(response)
    // const response = {
    //   "success": true,
    //   "message": "OK",
    //   "data": [{
    //       "orders": 345,
    //       "sales": 100,
    //       "profit": 100
    //     },
    //     {
    //       "orders": 345,
    //       "sales": 100,
    //       "profit": 100
    //     },
    //     {
    //       "orders": 345,
    //       "sales": 100,
    //       "profit": 100
    //     },
    //     {
    //       "orders": 345,
    //       "sales": 100,
    //       "profit": 100
    //     },
    //     {
    //       "orders": 345,
    //       "sales": 100,
    //       "profit": 100
    //     }
    //   ]
    // }
    // yield put({ type: ANALYTICSOSP_LIST_GET_SUCCESS, data: response?.data?.data || [] })
  } catch (e) {
    yield put({ type: ANALYTICSOSP_LIST_GET_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: ANALYTICSOSP_LIST_GET_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: ANALYTICSOSP_LIST_GET_SUCCESS, data: [] })
  }
  yield put({ type: ANALYTICSOSP_LIST_LOADING, isLoadingOSP: false })
}

function * onGetAnalyticsOrderStatus (action) {
  yield put({ type: GET_ANALYTICS_ORDER_STATUS_LOADING, isLoadingOrderStatus: true })
  try {
    const response = yield call(getApi, API_GET_ANALYTICS_ORDER_STATUS, action.payload)
    // const response = {
    //   "success": true,
    //   "message": "OK",
    //   "data": [{
    //       "orders": 15,
    //       "fullfilled": 5,
    //       "pending": 5
    //     },{
    //       "orders": 15,
    //       "fullfilled": 5,
    //       "pending": 5
    //     },{
    //       "orders": 15,
    //       "fullfilled": 5,
    //       "pending": 5
    //     },{
    //       "orders": 15,
    //       "fullfilled": 5,
    //       "pending": 5
    //     },{
    //       "orders": 15,
    //       "fullfilled": 5,
    //       "pending": 5
    //     }
    //   ]
    // }
    yield put({ type: GET_ANALYTICS_ORDER_STATUS_SUCCESS, data: response?.data.data || [] })
  } catch (e) {
    yield put({ type: GET_ANALYTICS_ORDER_STATUS_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: GET_ANALYTICS_ORDER_STATUS_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: GET_ANALYTICS_ORDER_STATUS_SUCCESS, data: [] })
  }
  yield put({ type: GET_ANALYTICS_ORDER_STATUS_LOADING, isLoadingOrderStatus: false })
}

function * onGetAnalyticsTotalOrders (action) {
  yield put({ type: ANALYTICS_TOTAL_ORDER_LOADING, isLoadingTotalOrder: true })
  try {
    const response = yield call(getApi, API_GET_ANALYTICS_TOTAL_ORDER, action.payload)
    // const response = {
    //   "success": true,
    //   "message": "OK",
    //   "data": [{
    //     "ordersCompleted" : 25,
    //     "returns" :   25,
    //     "cancellations" : 25
    //   }]
    // }

    yield put({ type: ANALYTICS_TOTAL_ORDER_SUCCESS, data: response?.data.data[0] || [] })
  } catch (e) {
    yield put({ type: ANALYTICS_TOTAL_ORDER_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: ANALYTICS_TOTAL_ORDER_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: ANALYTICS_TOTAL_ORDER_SUCCESS, data: [] })
  }
  yield put({ type: ANALYTICS_TOTAL_ORDER_LOADING, isLoadingTotalOrder: false })
}

function * onGetAnalyticsRevenueSummary (action) {
  yield put({ type: ANALTYICS_REVENUE_SUMMARY_LOADING, isLoadingRevenueSummary: true })
  try {
    const response = yield call(getApi, API_GET_ANALYTICS_REVENUE_SUMMARY, action.payload)
    // const response = {
    //   "success": true,
    //   "message": "OK",
    //   "data": [{
    //     "totalDiscount": 30,
    //     "totalProfit": 100,
    //     "totalRevenue": 100,
    //     "totalShippingFee": 30
    //   }, {
    //     "totalDiscount": 30,
    //     "totalProfit": 100,
    //     "totalRevenue": 100,
    //     "totalShippingFee": 30
    //   }, {
    //     "totalDiscount": 30,
    //     "totalProfit": 100,
    //     "totalRevenue": 100,
    //     "totalShippingFee": 30
    //   }, {
    //     "totalDiscount": 30,
    //     "totalProfit": 100,
    //     "totalRevenue": 100,
    //     "totalShippingFee": 30
    //   }, {
    //     "totalDiscount": 30,
    //     "totalProfit": 100,
    //     "totalRevenue": 100,
    //     "totalShippingFee": 30
    //   }]
    // }

    yield put({ type: ANALTYICS_REVENUE_SUMMARY_SUCCESS, data: response?.data.data || [] })
  } catch (e) {
    yield put({ type: ANALTYICS_REVENUE_SUMMARY_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: ANALTYICS_REVENUE_SUMMARY_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: ANALTYICS_REVENUE_SUMMARY_SUCCESS, data: [] })
  }
  yield put({ type: ANALTYICS_REVENUE_SUMMARY_LOADING, isLoadingRevenueSummary: false })
}

function * onGetAnalyticsRecentOrders (action) {
  yield put({ type: ANALTYICS_RECENT_ORDERS_LOADING, isLoadingRecentOrder: true })
  try {
    const response = yield call(getApi, API_GET_ANALYTICS_RECENT_ORDERS, action.payload)
    // console.log(response)
    // const response = {
    //   "success": true,
    //   "message": "OK",
    //   "data": [{
    //       "storeName": "Shopee Bellflower",
    //       "totalSales": 5678,
    //       "totalProfit": 578,
    //       "totalShippingFee": 578,
    //       "totalDiscount": 578
    //     },{
    //       "storeName": "Shopee Bellflower",
    //       "totalSales": 5678,
    //       "totalProfit": 578,
    //       "totalShippingFee": 578,
    //       "totalDiscount": 578
    //     },{
    //       "storeName": "Shopee Bellflower",
    //       "totalSales": 5678,
    //       "totalProfit": 578,
    //       "totalShippingFee": 578,
    //       "totalDiscount": 578
    //     },{
    //       "storeName": "Shopee Bellflower",
    //       "totalSales": 5678,
    //       "totalProfit": 578,
    //       "totalShippingFee": 578,
    //       "totalDiscount": 578
    //     }
    //   ]
    // }

    yield put({ type: ANALTYICS_RECENT_ORDERS_SUCCESS, data: response?.data.data || [] })
  } catch (e) {
    yield put({ type: ANALTYICS_RECENT_ORDERS_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: ANALTYICS_RECENT_ORDERS_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: ANALTYICS_RECENT_ORDERS_SUCCESS, data: [] })
  }
  yield put({ type: ANALTYICS_RECENT_ORDERS_LOADING, isLoadingRecentOrder: false })
}

function * analyticsSaga () {
  yield takeLatest(GET_ANALYTICS, onGetAnalytics)
  yield takeLatest(GET_ANALYTICSOSP_LIST, onGetAnalyticsOSPList)
  yield takeLatest(GET_ANALYTICS_ORDER_STATUS, onGetAnalyticsOrderStatus)
  yield takeLatest(GET_ANALYTICS_TOTAL_ORDER, onGetAnalyticsTotalOrders)
  yield takeLatest(GET_ANALTYICS_REVENUE_SUMMARY, onGetAnalyticsRevenueSummary)
  yield takeLatest(GET_ANALTYICS_RECENT_ORDERS, onGetAnalyticsRecentOrders)
}

export default analyticsSaga
