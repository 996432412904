import { styled } from '@mui/system'
import { Box, Button } from '@mui/material'

const media = {
  xl: '@media(max-width: 1920px)',
  lg: '@media(max-width: 1200px)',
  md: '@media(max-width: 1000px)',
  sm: '@media(max-width: 600px)',
  xs: '@media(max-width: 0px)'
}
export const Main = styled('div')`
`
export const BoxWraper = styled(Box)`
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: auto;
  background-color:white;
  padding:3vh 20px;
  border-radius:15px;
  /* ${media.mobile}{
    height: 60vh;
    width: 80%;
    left: 48%;
}
  ${media.tablet}{
    height: 70vh;
    width: 80%;
    left: 50%;
}
  ${media.laptop}{
    height: 70vh;
    left: 50%;
} */
`
export const UploadImageWrapper = styled(Box)(`
`)
export const HeadingWraper = styled('h1')(`
text-align: center;
font-size: 25px;
font-weight: 500;
`)

export const FlexWraper = styled('div')(`
display: flex;
justify-content: start;
flex-direction: row;
align-items: center;
`)

export const MuiButton = styled(Button)(`
width: 120px;
float: right;
margin-top: 10px;
`)
export const MuiButtonCancel = styled(Button)(`
width: 120px;
float: right;
margin-top: 10px;
margin-right:10px;

`)
