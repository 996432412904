import { styled } from '@mui/system'
import { Box } from '@mui/material'
import MuiButton from '../MuiButton'

export const StoreCardWrapper = styled(Box)(`
  background: #FFFFFF;
  display: flex;
  min-width: 360px;
  width: calc(33.33% - 30px);
  margin:0 5px 10px;
  flex-direction: row;
  flex-wrap:wrap;
  flex-basis: 0 0 100%;
  padding: 10px;
  border-radius: 5px;
  border:0px red solid;
  @media (max-width: 1440px) {
    width: calc(50% - 30px);
  }
  @media (max-width: 1000px) {
    width: calc(50% - 30px);
  }
  @media (max-width: 600px) {
    width: calc(100% - 30px);
    min-width: 260px;
  }
`)

export const ConnectionStatus = styled(Box)(`
  margin-top:10px;
  display: flex;
  background-color: #F6F6F7;
  border-radius: 8px;
  width: 100%;
  padding:5px 15px;
  justify-content:space-between;
  align-items: center;
  @media (max-width: 600px) {
    flex-wrap:wrap;
  }
`)

export const StoreDetails = styled(Box)(`
  display: flex;
  flex-direction: column;
  width: calc(100% - 55px);
`)

export const StoreCardTop = styled(Box)(`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
`)

export const StoreImageWrapper = styled(Box)(`
  position: relative;
  padding-right: 15px;
`)

export const StoreImage = styled('img')(`
  max-height: 40px;
  max-width: 40px;
`)

export const StoreConnectionImage = styled('img')(`
  max-height: 20px;
  max-width: 20px;
  border-radius: 50%;
  background: #FFF;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 30px;
  left: 30px;
`)

export const StoreInfoWrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
`)

export const StoreStatusWrapper = styled(Box)(`
  display: flex;
  align-content: center;
`)

export const ReconnectStore = styled(MuiButton)(`
  text-transform: uppercase;
  background: #162E9A;
  padding:5px;
  color: white;
  height:auto;
  border-radius: 3px;
  font-weight: 500;
  font-size:12px;
  :hover{background-color: #162E9A}
  & svg {
    width:14px;
    height:14px;
  }
`)

export const StoreStatus = styled(Box)((props) => `
  background: ${props?.type === 'dicconnected' ? '#F3571E' : '#1AB270'};
  padding:0 5px;
  color: white;
  height:20px;
  line-height:20px;
  border-radius: 3px;
  font-weight: 500;
  font-size:12px;
  margin-left: auto;
  text-transform:uppercase;
  @media (max-width: 600px) {
    display:none;
  }
`)

export const StoreName = styled(Box)(`
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 5px;
  @media (max-width: 425px) {
    max-width: 200px;
    overflow-wrap: break-word;
  }
`)

export const StoreId = styled(Box)(`
  font-size: 12px;
  font-weight: 400;
  color: #808080;
`)
export const WraperDiv = styled('div')(`
  display: grid;
  width:200px;
  grid-template-columns: 4fr 1fr;
`)

export const WraperContent = styled('div')(`
  margin-top: 5px;
  margin-left: -2px;
`)

export const WraperHeader = styled('h4')(`
font-weight: 400;
font-size: 17px;
color: #888888;
margin-bottom: unset;
`)

export const WraperHeaderValue = styled('span')(`
  font-weight:500;
  font-size: 14px;
  color: #222222;
`)

export const ImgIconOrderProduct = styled('img')(`
  float: left;
  margin-right: 3px;
`)

export const StoreCardBottom = styled(Box)(`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 425px) {
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    line-height: 10px;
  }
`)

export const ProductInfo = styled(Box)(`
  display: flex;
  flex-direction: column;
  flex-basis: 0 0 33.33%;
  @media (max-width: 425px) {
    margin-bottom: 10px;
  }
`)

export const ProductInfoTitle = styled(Box)(`
  font-size: 14px;
  font-weight: 400;
  color: #808080;
  margin-bottom: 5px;
`)

export const ProductCount = styled(Box)(`
  display: flex;
  img {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  @media (max-width: 425px) {
    img {
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }
  }
  & div {
    font-weight: 500;
  }
`)

export const ExpiredTitle = styled(Box)(`
  font-size: 13px;
  line-height: 15px;
  min-height: 15px;
  color: #888888;
  display:flex;
  &:before {
    content:'!';
    background: #FF4D45;
    width:16px;
    height:16px;
    border-radius:50%;
    display:block;
    color:#fff;
    font-size:14px;
    font-weight:bold;
    text-align:center;
    margin-right:10px;
  }
  @media (max-width: 600px) {
    margin-bottom:5px;
  }
`)

export const OrderInfo = styled(Box)(`
  display: flex;
  flex-direction: column;
  flex-basis: 0 0 33.33%;
  @media (max-width: 425px) {
    margin-bottom: 10px;
  }
`)

export const OrderInfoTitle = styled(Box)(`
  font-size: 14px;
  font-weight: 400;
  color: #808080;
  margin-bottom: 5px;
`)

export const OrderCount = styled(Box)(`
  display: flex;
  img {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  @media (max-width: 425px) {
    img {
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }
  }

  & div {
    font-weight: 500;
  }
`)

export const Time = styled(Box)(`
  display: flex;
  flex-basis: 0 0 33.33%;
  font-size: 14px;
  font-weight: 400;
  color: #808080;
  @media (max-width: 425px) {
    margin-bottom: 10px;
  }
`)
