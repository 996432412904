import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const AuthParent = styled.div`
  display:grid;
  grid-template-columns: 1fr 1.2fr;

  @media (max-width: 1024px) {
    display: block;
  }

  @media (min-height: 730px) {
    height: ${props => props.isScroll ? '100%' : '100vh'};
  }  
`

export const LeftSideContainer = styled.div`
  background: #CBD2E5;
  display:flex;position: relative;
  
  @media (max-width: 1024px) {
    display:none !important;
  }

`

export const LoginImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;  
  margin: auto;
  background:#CBD2E5;
`

export const LoginImage = styled.img`
  height: auto;
  max-width: 450px;
`

export const ImageWrapperText = styled.div`
  position: absolute;          /* remove from document flow */
  top: 105px;
  left: 50%;                        /* center horizontally */
  transform: translate(-50%,-50%);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.5px;
  white-space: nowrap;

  @media (max-width: 1300px) {
    top: 70px;
    font-size: 18px;  
  }
`

export const Wrapper = styled.div`

  display: flex;
  background: white;  
  justify-content: center;
  max-width: 450px;
  margin: auto;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 10px;

  @media (max-width: 1024px) {
    max-width: 750px;
  }
  
  @media (max-width: 640px) {
    max-width: 95%;
  }
`

export const LogoWrapper = styled.div`
  margin-top: 80px;
  margin-bottom: 47px;
  width: 100%;
  max-width: 216px;
  height: auto;
`

export const BannerSection = styled.div`
  background-color: #F2E4E7;
`

export const Banner = styled.img`
  width: 100%;
  max-width: 550px;
  height: auto;
  opacity: 0.3;
  display: block;

  @media (max-width: 640px) {
    max-width: 370px;
  }
`

export const FormWrapper = styled.div`
  background-color: white;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 80px;
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  margin-top: 20px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
`

export const Input = styled.input`
  text-indent: 30px;
  height: 50px;
  width: 360px;
  border: 0.5px solid #7D86A9;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 16px;
  line-height: 19px;
  ::placeholder {
  }
  &:not(:first-child) {
    margin-top: 30px;
  }

  @media (max-width: 640px) {
    max-width: 300px;
  }
`

export const CheckBox = styled.input`
  width: 17px;
  height: 17px;
  &:checked {
    background: rgba(0, 0, 0, 0);
    filter: invert(1);
  }
`

export const CheckBoxField = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #7D86A9;
  margin: auto 0;
  display: flex;
`

export const CheckBoxLabel = styled.div`
  margin-top: 5px;
  cursor: pointer;
`

export const ExtraFields = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  width: 350px;

  @media (max-width: 640px) {
    max-width: 300px;
  }
`

export const ForgotPassword = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #7D86A9;
  margin: auto 0;
  cursor: pointer;
`

export const Button = styled.button`
  height: 50px;
  width: 360px;
  color: #fff;
  background: #162E9A;
  border-radius: 10px;
  margin-top: 50px;
  cursor: pointer;
  font-weight: 500;
  border: none;
  font-size: 18px;

  @media (max-width: 640px) {
    max-width: 300px;
  }
`

export const BottomLinkWrapper = styled.div`
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 19px;
  color: #7D86A9;
`

export const BottomLinkHref = styled(Link)`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin-left: 16px;
  color: #000;
  cursor: pointer;
`

export const ForgotPasswordLink = styled(Link)`
  text-decoration: none;
`
