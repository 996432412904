import { styled } from '@mui/system'
import { Grid, Box, Tooltip, IconButton, Modal, Fade } from '@mui/material'

const media = {
  large_laptop: '@media(max-width: 2560px)',
  laptop: '@media(max-width: 1000px)',
  tablet: '@media(max-width: 768px)',
  mobile: '@media(max-width: 600px)'
}

export const Main = styled(Grid)(`
margin-right: 1.5vh;
padding-left: 3vw;
padding-right: 3vw;
${media.laptop} {
  padding-left: 0vh;
  padding-right: 0vh;
} 
`)

export const BoxM = styled(Box)(`
margin-top: 3vh;
padding: 2vh;
min-height: 700px;
`)

export const TitleGrid = styled(Grid)(`
  
`)

export const Title = styled('p')(`
padding-left: 2vh;
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 33px;
letter-spacing: 0.48px;
color: #19284D;
${media.tablet}{
  text-align: center; 
}
`)

export const TopButtonsGroup = styled(Grid)(`
`)

export const ButtonGrid = styled(Grid)(`
  
`)

export const CreateNewBtn = styled(Grid)(`
margin-top: 3vh;
justify-content: centre;
width: 140px;
height: 40px;
background: #FFFFFF;
box-shadow: 0px 2px 8px rgba(129, 82, 226, 0.436134);
border-radius: 8px;
:hover {
  cursor: pointer;
}
${media.tablet}{
  margin: 0 auto; 
}
`)

export const DownloadBtn = styled('p')(`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
text-align: center;
color: #162E9A;
padding-top: 10px;
`)

export const GridM = styled(Grid)(`
${media.tablet}{
  &.center{
    justify-content: center !important;
  }
}
`)

export const SearchIconWrapper = styled('div')(`
margin: 5px 7px 0px 0px;
`)

export const SearchInputWrapper = styled('div')(`
margin-top: 28px;
${media.laptop}{
  margin-top: 10vh;
} 
& > div > div{
  width: 100%;
}
& > div > div > div{
  background-color: #F2F7FF;
  border-radius: 8px;
}
& > div > div > div > fieldset{
  border: none !important;
}
}
`)

export const SelectWrapper = styled('div')`
margin-top: 10px;
padding-right:10px;
`

export const SelectLabel = styled('div')`
margin-bottom: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
`

export const DataGridWrapper = styled(Grid)`
margin-top: 20px;
min-height: 550px;
.MuiDataGrid-iconSeparator{
display:none;
}
.MuiDataGrid-columnHeaders{
background-color: rgba(232, 235, 239, 0.4);
border-radius: 10px;
border: 1px solid rgba(0, 0, 0, 0.1);
}
.MuiDataGrid-columnHeaderTitle{
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
color: #536082;
}
.MuiDataGrid-columnHeader:focus{
  outline: none !important;
}
.MuiDataGrid-row{
  border-bottom: 2px #EEF1F7 solid !important;
  margin-top: 0px !important;  
  margin-bottom: 0px !important;
}
.MuiDataGrid-cell{
  border-bottom : none !important;
}
}
`

export const EditCell = styled('p')`
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 18px;
text-decoration-line: underline;
color: #7055D9;
:hover{
  cursor: pointer;
}
`

export const HeaderDesription = styled('p')`
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 14px;
color: #212121;
mix-blend-mode: normal;
opacity: 0.7;
position: absolute;
margin-left: -40px;
margin-top: 18px;
`

export const InfoIconWrapper = styled('img')`
padding-left: 5px; 
`

export const TooltipWrapper = styled(Tooltip)`
`

export const SelectedItemsCount = styled('p')`
margin: 0px;
`
export const DateRangeWrapper = styled('div')`
overflow: auto;
& > div > div > div{
  width: fit-content;
}
`
export const DateRangeText = styled('div')`
`
export const DateRangeDiv = styled('div')`
background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.2);
border-radius: 4px;
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 16px;
padding: 9.5px;
:hover {
cursor: pointer;
border: 1px solid black;
}
`

export const CalanderIconWrapper = styled(IconButton)`
height: 9px !important;
float: right;
width: 15px;
:hover {
  color: #162E9A;
}
;
`

export const ModalWrapper = styled(Modal)`
display: flex;
align-items: center;
justify-content: center;
`

export const ShowMoreWrapper = styled('p')`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 22px;
text-align: center;
color: #162E9A;
margin: 0px;
:hover{
  cursor: pointer;
}
`

export const FreeUnitWrapper = styled('p')`
font-style: normal;
font-family: 'Noto Sans';
font-weight: 400;
font-size: 14px;
line-height: 22px;
color: #212121;
margin: 0px;
font-weight: bold
`

export const ShowMoreRenderCellWrapper = styled('div')((props)=>`
display: block;
margin-top: ${props.marginTop};
width: calc(100%);
`)

export const ShowMoreDiv = styled('div')`
display: flex;
`

export const PMargin0 = styled('p')`
margin: 0px;
text-overflow: ellipsis;
overflow: hidden;
`

export const PopupFirstTableWrapper = styled('div')`
padding: 5px 20px 0px 25px; 
background-color: #E8EBEF;
.MuiTableRow-head{
  background-color: #E8EBEF !important;
}
.MuiTableCell-head{
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #536082 !important;
  text-align: center !important;
}
.MuiTableCell-body{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #212121;
  text-align: center;
}
`

export const PopupTablesWrapper = styled(Fade)`
z-index: 2;
outline: none;
border-radius: 8px; 
.MuiPaper-root {
  border-radius: 0px;
}
max-width: 700px;
max-height: 500px;
overflow: auto;
`

export const PopupSecondTableWrapper = styled('div')`
padding: 5px 0px 0px 30px; 
.MuiTableRow-head{
  background-color: white;
}
.MuiTableCell-head{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;  
  color: #212121;
}
.MuiTableCell-body{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #212121;
}
`

export const NoPromoWrapper = styled('div')`
margin-top: 20vh;
${media.laptop} {
  margin-top: 4vh;
} 
`

export const NoPromoIconDiv = styled('div')`
width: 120px;
background: #333;
border-radius: 50%;
margin-left: auto;
margin-right: auto;
`

export const NoPromoIconWrapper = styled('img')`
opacity: 1;
padding: 35px;
width: 40px;
`

export const PrimaryText = styled('p')`
font-style: normal;
font-weight: 500;
font-size: 21px;
line-height: 24px;
text-align: center;
`

export const SecondaryText = styled('p')`
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 24px;
text-align: center;
`

export const NoPromoButtonsDiv = styled('div')`
display: flex;
justify-content: center;
${media.laptop} {
  flex-direction: column;
} 
`

export const PromoTypesButton = styled('div')`
background: #162E9A;
box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
border-radius: 8px;
width: max-content;
margin: 10px;
:hover {
  cursor: pointer;
  opacity: 0.75;
 }
 ${media.laptop} {
  margin-left: auto;
  margin-right: auto;
} 
`

export const PromoTypesButtonText = styled('p')`
padding: 0px 25px 0px 25px;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 25px;
color: #FFFFFF;
`

export const BlankText = styled('p')`
margin: 2px;
overflow: hidden;
font-size: 28px;
`

export const ViewDetailBtn = styled('div')`
cursor: pointer;
border: 1px solid #162E9A;
padding: 0px 12px 0px 11px;
border-radius: 11px;
`

export const ViewDetailText = styled('p')`
font-weight: 600;
color: #162E9A;
`

export const MspCellDiv = styled('div')`
display: flex;
input {
  height: 35px;
  width: 85px;
  border: 2px solid lightgrey;
  border-radius: 10px;
  padding-left: 8px;
}

 & > p:nth-child(1) {
  width: 100px;
} 
`

export const MspCellEditBtn = styled('p')`
margin-left: 25px;
color: blue;
text-decoration: underline;
cursor: pointer;
`