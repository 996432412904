import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Main, BoxWraper, UploadImageWrapper, HeadingWraper, FlexWraper, MuiButton, MuiButtonCancel } from './style'
import { Modal } from '@mui/material'
import UploadImages from '../../containers/UploadImages'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  USERS_UPDATE_PROFILE_IMAGE,
  UPLOADIMAGES_CLEAR
} from '../../actions'

const ProfilePicModal = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // const ProfileimageUrl = useSelector((state) => state?.uploadImages?.get('uploadImagecontent').url)
  const userProfileImage = useSelector((state) => state?.users?.get('imageUrl'))
  const userProfileId = useSelector((state) => state?.users?.get('id'))
  const [uploadImageArray, setUploadImageArray] = useState(userProfileImage ? [{ url: userProfileImage }] : [])

  const formikInitialValues = {
    images: []
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues
  })
  const handleImageChange = (data) => {
    setUploadImageArray(data)
    // const imageList = data?.map(i => { return { key: i.key, priority: 1 } }) || []
    // formik.setValues({ ...formik.values, ...{ images: imageList } })
  }
  const updateButtonHandler = () => {
    const data = {
      // imageUrl: ProfileimageUrl,
      imageUrl: uploadImageArray?.[0]?.url || '',
      id: userProfileId
    }
    dispatch({ type: USERS_UPDATE_PROFILE_IMAGE, payload: data })
    props.handleClose()
    dispatch({ type: UPLOADIMAGES_CLEAR })
  }
  const CancelButtonHandler = () => {
    dispatch({ type: UPLOADIMAGES_CLEAR })
    props.handleClose()
  }
  return (
    <>
      <Main>
        <Modal
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <BoxWraper>
            <HeadingWraper>Change your Profile image</HeadingWraper>
            <FlexWraper>
              <UploadImageWrapper>
                <UploadImages
                  title={t('addProduct.uploadImage')}
                  description={t('addProduct.kindlyAddText')}
                  format='image/jpg, image/jpeg'
                  onImageChange={handleImageChange}
                  error={formik.errors.images}
                  // value={formikInitialValues.images || null}
                  value={uploadImageArray}
                  isRequired
                  mode='addProfilePic'
                  name='addProfilePic'
                  autoClear={false}
                  hideBrowse={
                    { maxLength: 1 }
                  }
                />
              </UploadImageWrapper>
            </FlexWraper>
            <MuiButton color='primary' size='large' variant='contained' onClick={updateButtonHandler}>Update</MuiButton>
            <MuiButtonCancel color='primary' size='large' variant='outlined' onClick={CancelButtonHandler}>Cancel</MuiButtonCancel>
          </BoxWraper>
        </Modal>
      </Main>
    </>
  )
}

export default ProfilePicModal
