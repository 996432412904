import { put, takeLatest, call } from 'redux-saga/effects';

import {
    GET_OFFERS,
    GET_OFFERS_SUCCESS,
    GET_OFFERS_FAILURE,
    GET_SKU,
    GET_SKU_SUCCESS,
    GET_SKU_FAILURE,
    CREATE_PROMO,
    CREATE_PROMO_SUCCESS,
    CREATE_PROMO_FAILURE,
    PATCH_PROMO,
    PATCH_PROMO_SUCCESS,
    PATCH_PROMO_FAILURE,
    VALIDATE_PROMO_SUCCESS,
    VALIDATE_PROMO_FAILURE,
    VALIDATE_PROMO,
} from '../actions';
import { BrandManagementPostApi, PatchPromoApi } from '../utilities/axios';
import {
    API_GET_BRANDS_MANAGEMENT_PROMO_TYPES,
    POST_CREATE_API,
    GET_SKU_API,
    UPDATE_PROMO_API,
    UALIDATE_PROMO_API
} from '../constants';

function* getOfferType(action) {
    try {
        const  brandId  = action?.payload;
        const response = yield call(BrandManagementPostApi, API_GET_BRANDS_MANAGEMENT_PROMO_TYPES(brandId), {}, {}, true);
        yield put({ type: GET_OFFERS_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: GET_OFFERS_FAILURE, message: error.message })
    }
}
function* getSkuList(action) {
    try {
        const brandId  = action?.payload;
        const response = yield call(BrandManagementPostApi, GET_SKU_API(brandId),{}, {}, false);
        yield put({ type: GET_SKU_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: GET_SKU_FAILURE, message: error.message })
    }
}

function* createBrandPromo(action) {
    try {
        const { brandId, data } = action?.payload
        const response = yield call(BrandManagementPostApi, POST_CREATE_API(brandId), data, {}, true);
        if(response?.status >= 200 && response?.status < 300){
            yield put({ type: CREATE_PROMO_SUCCESS, data: response?.data?.data || response.data || {} });
        }
    } catch (error) {
        yield put({ type: CREATE_PROMO_FAILURE, data: error.response.data })
    }
}

function* updatePromo(action) {
    try {
        const { brandId, id, data } = action?.payload
        const response = yield call(PatchPromoApi, UPDATE_PROMO_API(brandId, id), data, {}, true);
        if(response?.status >= 200 && response?.status < 300){
            yield put({ type: PATCH_PROMO_SUCCESS, data: response?.data?.data || response.data || {} });
        }
    } catch (error) {
        yield put({ type: PATCH_PROMO_FAILURE, data: error.response.data })
    }
}

function* validatePromos(action) {
    try {
        const { brandId, id, data } = action?.payload
        const response = yield call(BrandManagementPostApi, UALIDATE_PROMO_API(brandId, id), data, {}, true);
        if(response?.status >= 200 && response?.status < 300){
            yield put({ type: VALIDATE_PROMO_SUCCESS, data: response?.data?.data || response.data || {} });
        }
    } catch (error) {
        yield put({ type: VALIDATE_PROMO_FAILURE, data: error.response.data })
    }
}

function* createSaga() {
    yield takeLatest(GET_OFFERS, getOfferType);
    yield takeLatest(GET_SKU, getSkuList);
    yield takeLatest(CREATE_PROMO, createBrandPromo);
    yield takeLatest(PATCH_PROMO, updatePromo);
    yield takeLatest(VALIDATE_PROMO, validatePromos);
}

export default createSaga;



