import React, { useCallback, useState } from 'react'
import { PieChart, Pie, Sector } from 'recharts'

const data = [
  { name: 'Promo', value: 3120000, fill: '#8348FF' },
  { name: 'Bundle', value: 1200000, fill: '#F56A83' },
  { name: 'Flash', value: 1200000, fill: '#FAB912' },
  { name: 'MOV', value: 2200000, fill: '#5BB9E1' }
]

const RADIAN = Math.PI / 180

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    value
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 15) * cos
  const my = cy + (outerRadius + 15) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 10}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#999'
      >{`${(percent * 100).toFixed(2)}%`}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill='#333'
      >{`Rp. ${value / 1000}k`}

      </text>
    </g>
  )
}

const CouponRevenuePieChart = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )

  return (
    <PieChart width={427} height={400}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        cx={200}
        cy={200}
        outerRadius={80}
        dataKey='value'
        onMouseEnter={onPieEnter}
      />
    </PieChart>
  )
}

CouponRevenuePieChart.displayName = 'CouponRevenuePieChartComponent'
export default CouponRevenuePieChart
