import { useTranslation } from 'react-i18next'
import {
  TotalAmountWrapper,
  TotalAmountWrapperInner,
  CardLineWrapper,
  AmountHeaderInCardWrapper,
  AmountInCardWrapper,
  QunantityWrapper,
  AmountColoredWrapper
} from './style'

const AmountCard = ({
  shippingFee,
  amountPayable,
  voucher,
  totalAmount
}) => {
  const { t } = useTranslation()

  return (
    <TotalAmountWrapper>
      <TotalAmountWrapperInner>
        <CardLineWrapper>
          <AmountHeaderInCardWrapper>
            {t('orderDetails.amountPayable')}
          </AmountHeaderInCardWrapper>
          <AmountInCardWrapper>
            IDR {amountPayable}
          </AmountInCardWrapper>
        </CardLineWrapper>
        <CardLineWrapper>
          <QunantityWrapper>
            {t('orderDetails.shippingFee')}
          </QunantityWrapper>
          <AmountColoredWrapper>
            - IDR {shippingFee}
          </AmountColoredWrapper>
        </CardLineWrapper>
        <CardLineWrapper>
          <QunantityWrapper>
            {t('orderDetails.voucherDiscount')}
          </QunantityWrapper>
          <AmountColoredWrapper>
            - IDR {voucher}
          </AmountColoredWrapper>
        </CardLineWrapper>
        <div style={{ marginTop: '15px' }}>
          <CardLineWrapper marginBottom>
            <AmountHeaderInCardWrapper>
              {t('orderDetails.netAmountPaid')}
            </AmountHeaderInCardWrapper>
            <AmountInCardWrapper>
              IDR {totalAmount}
            </AmountInCardWrapper>
          </CardLineWrapper>
        </div>
      </TotalAmountWrapperInner>
    </TotalAmountWrapper>
  )
}


export default AmountCard
