import { Link } from 'react-router-dom'

const Logo = (props) => {
  const sLogo = <SvgLogo {...props} />
  return (props?.url ? <Link to={props.url}>{sLogo}</Link> : sLogo)
}

export default Logo

// <svg xmlns='http://www.w3.org/2000/svg' version='1.1' x='0px' y='0px' viewBox='0 0 380 80' style={{ width: logoWidth }}>
// <g style={{ transform: 'translate(-110px, -160px)' }}>
//   <g>
//     <path fill={color} d='M151.63,239.24c-21.64,0-39.24-17.6-39.24-39.24c0-21.64,17.6-39.24,39.24-39.24     c21.64,0,39.24,17.6,39.24,39.24C190.87,221.64,173.27,239.24,151.63,239.24z M151.63,165.13c-19.23,0-34.87,15.64-34.87,34.87     s15.64,34.87,34.87,34.87S186.5,219.23,186.5,200S170.86,165.13,151.63,165.13z' />
//     <path fill={color} d='M154.23,207.35c3.62,4.78,7.14,9.42,10.66,14.06c-0.09,0.15-0.17,0.3-0.26,0.44c-2.26,0-4.52,0.04-6.78-0.04     c-0.36-0.01-0.74-0.58-1.02-0.95c-2.9-3.79-5.82-7.56-8.64-11.4c-0.81-1.11-1.68-1.57-3.04-1.47c-1.55,0.11-3.12,0-4.67,0.04     c-0.93,0.02-1.33-0.32-1.27-1.29c0.06-1.17,0.06-2.34,0-3.51c-0.05-0.98,0.36-1.27,1.29-1.26c2.78,0.04,5.55,0.05,8.33,0.01     c4.77-0.07,8.23-2.8,9.18-7.19c1.17-5.37-2.87-10.51-8.4-10.61c-3.02-0.06-6.04-0.04-9.06,0.01c-1.03,0.02-1.4-0.33-1.34-1.35     c0.07-1.21-0.05-2.44,0.05-3.65c0.03-0.39,0.52-1.07,0.79-1.06c4.27,0.09,8.63-0.24,12.79,0.53c6.6,1.23,10.84,6.52,11.42,13.56     c0.43,5.29-2.3,11.3-8.47,14.33C155.32,206.78,154.86,207.02,154.23,207.35z' />
//     <path fill={color} d='M144.95,186.97c0,3.96,0,7.93,0,12.06c-2.23,0.24-3.95-0.63-5.29-2.31c-1.59-2-1.71-4.78-0.39-6.96     C140.49,187.74,142.7,186.64,144.95,186.97z' />
//   </g>
//   {type === 'full' &&
//     <g>
//       <path fill={color} d='M234.61,200.82c-1.67-0.96-3.53-1.74-5.56-2.34c-2.04-0.6-4.09-1.13-6.15-1.61c-2.06-0.48-3.93-0.97-5.6-1.48    c-1.67-0.5-3.02-1.2-4.05-2.09c-1.03-0.89-1.55-2.07-1.55-3.54c0-1.24,0.35-2.34,1.07-3.3c0.71-0.96,1.83-1.74,3.37-2.34    c1.54-0.59,3.54-0.89,6.01-0.89c2.11,0,4.3,0.31,6.59,0.93c2.29,0.62,4.51,1.57,6.66,2.85l2.82-6.94    c-2.11-1.37-4.58-2.43-7.42-3.16c-2.84-0.73-5.7-1.1-8.59-1.1c-4.4,0-8.03,0.65-10.89,1.96c-2.86,1.31-4.99,3.05-6.39,5.22    c-1.4,2.18-2.09,4.57-2.09,7.18c0,2.56,0.53,4.67,1.58,6.32c1.05,1.65,2.42,2.97,4.09,3.95s3.53,1.79,5.56,2.4    c2.04,0.62,4.08,1.16,6.12,1.61c2.04,0.46,3.89,0.95,5.56,1.48c1.67,0.53,3.03,1.24,4.09,2.13c1.05,0.89,1.58,2.07,1.58,3.54    c0,1.19-0.37,2.26-1.1,3.19c-0.73,0.94-1.88,1.68-3.43,2.23c-1.56,0.55-3.6,0.82-6.12,0.82c-2.98,0-5.89-0.5-8.73-1.51    c-2.84-1.01-5.22-2.27-7.15-3.78l-3.09,6.94c2.02,1.7,4.74,3.08,8.18,4.16c3.44,1.07,7.03,1.61,10.79,1.61    c4.4,0,8.04-0.65,10.92-1.96c2.89-1.31,5.03-3.05,6.42-5.22c1.4-2.18,2.09-4.55,2.09-7.11c0-2.52-0.51-4.6-1.54-6.25    C237.64,203.09,236.28,201.79,234.61,200.82z' />
//       <path fill={color} d='M273.74,189.83c-2.8-1.56-5.98-2.34-9.55-2.34c-3.67,0-6.93,0.8-9.79,2.4c-2.86,1.6-5.11,3.81-6.73,6.63    c-1.63,2.82-2.44,6.06-2.44,9.72c0,3.62,0.83,6.85,2.51,9.69c1.67,2.84,4.03,5.07,7.08,6.7c3.05,1.63,6.61,2.44,10.68,2.44    c3.21,0,6.06-0.51,8.55-1.55c2.5-1.03,4.57-2.51,6.22-4.43l-4.6-5.29c-1.28,1.37-2.75,2.38-4.4,3.02    c-1.65,0.64-3.5,0.96-5.56,0.96c-2.38,0-4.49-0.46-6.32-1.38c-1.83-0.92-3.25-2.22-4.26-3.92c-0.63-1.06-1.04-2.24-1.28-3.5h28.76    c0.04-0.41,0.08-0.85,0.1-1.3c0.02-0.46,0.03-0.87,0.03-1.24c0-3.85-0.8-7.19-2.4-10.03    C278.73,193.59,276.53,191.39,273.74,189.83z M254.95,199.55c0.89-1.63,2.13-2.9,3.71-3.81c1.58-0.92,3.42-1.38,5.53-1.38    c2.06,0,3.88,0.46,5.46,1.38s2.83,2.18,3.74,3.78c0.65,1.13,1.04,2.4,1.21,3.78h-20.84C253.95,201.92,254.34,200.66,254.95,199.55    z' />
//       <rect x='290.99' y='173.62' fill={color} width='8.59' height='50.98' />
//       <rect x='310.84' y='173.62' fill={color} width='8.59' height='50.98' />
//       <polygon fill={color} points='348.73,220.08 329.32,176.5 325.47,176.5 346.91,224.6 350.41,224.6 371.85,176.5 368.21,176.5' />
//       <path fill={color} d='M379.82,174.44c-0.78,0-1.44,0.28-1.99,0.82c-0.55,0.55-0.82,1.22-0.82,1.99c0,0.73,0.27,1.37,0.82,1.92    c0.55,0.55,1.21,0.82,1.99,0.82c0.82,0,1.5-0.28,2.03-0.82c0.53-0.55,0.79-1.21,0.79-1.99s-0.26-1.43-0.79-1.96    C381.32,174.71,380.64,174.44,379.82,174.44z' />
//       <rect x='378.1' y='188.73' fill={color} width='3.44' height='35.86' />
//       <path fill={color} d='M418.05,190.72c-2.59-1.51-5.51-2.27-8.76-2.27c-3.25,0-6.18,0.78-8.79,2.34c-2.61,1.56-4.66,3.7-6.15,6.43    c-1.49,2.72-2.23,5.87-2.23,9.45c0,3.53,0.79,6.66,2.37,9.41c1.58,2.75,3.75,4.9,6.53,6.46c2.77,1.56,5.97,2.34,9.58,2.34    c2.75,0,5.3-0.49,7.66-1.48c2.36-0.98,4.32-2.44,5.87-4.36l-1.99-2.27c-1.38,1.7-3.06,2.96-5.05,3.78    c-1.99,0.82-4.13,1.24-6.42,1.24c-2.93,0-5.54-0.63-7.83-1.89c-2.29-1.26-4.09-3.02-5.39-5.29c-1.2-2.09-1.84-4.46-1.94-7.11    h30.83c0-0.18,0.01-0.35,0.03-0.52c0.02-0.16,0.03-0.31,0.03-0.45c0-3.57-0.74-6.72-2.23-9.45    C422.68,194.35,420.64,192.24,418.05,190.72z M397.27,198.59c1.19-2.22,2.83-3.96,4.91-5.22s4.45-1.89,7.11-1.89    c2.7,0,5.08,0.63,7.15,1.89s3.7,3,4.91,5.22c1.02,1.87,1.59,3.94,1.72,6.22h-27.51C395.72,202.53,396.28,200.45,397.27,198.59z' />
//       <polygon fill={color} points='484.45,188.73 472.36,220.68 460.2,188.73 457.25,188.73 444.99,220.76 433.06,188.73 429.77,188.73     443.37,224.6 446.53,224.6 458.69,193.44 470.78,224.6 474.01,224.6 487.61,188.73   ' />
//     </g>}
// </g>
// </svg>

const SvgLogo = ({ color = 'black', size = 'small', type = 'full' }) => {
  let logoWidth
  switch (size) {
    case 'large': logoWidth = '350px'; break
    case 'medium': logoWidth = '250px'; break
    default: logoWidth = '150px'
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' x='0px' y='0px' viewBox='0 0 432 102' style={{ width: logoWidth }}>
      <g>
        <g>
          <g>
            <path fill={color} d='M152,33c-3.6-1.9-7.9-2.8-12.6-2.8h-18.9V75h18.9c4.8,0,9-0.9,12.6-2.8c3.6-1.9,6.5-4.5,8.5-7.9          c2-3.4,3-7.3,3-11.7s-1-8.3-3-11.7C158.5,37.5,155.6,34.9,152,33z M154.9,61.4c-1.5,2.5-3.6,4.5-6.3,5.9          c-2.7,1.4-5.9,2.1-9.6,2.1h-12.1V35.8H139c3.7,0,6.9,0.7,9.6,2.1c2.7,1.4,4.8,3.4,6.3,5.9c1.5,2.5,2.2,5.5,2.2,8.8         a C157.1,55.9,156.4,58.8,154.9,61.4z' />
          </g>
          <g>
            <path fill={color} d='M184.8,40.7c-2.7,0-5.3,0.4-7.7,1.1c-2.5,0.7-4.6,1.8-6.3,3.2l2.6,4.6c1.3-1.1,2.9-2,4.9-2.7          c1.9-0.7,3.9-1,5.9-1c3,0,5.3,0.7,6.8,2.1c1.5,1.4,2.3,3.4,2.3,6v1.2h-9.7c-3.4,0-6,0.4-8,1.3c-2,0.9-3.4,2.1-4.3,3.6          c-0.9,1.5-1.3,3.2-1.3,5c0,1.9,0.5,3.6,1.5,5.1c1,1.5,2.5,2.7,4.4,3.6c1.9,0.9,4.1,1.3,6.6,1.3c3.1,0,5.7-0.6,7.7-1.8          c1.4-0.8,2.6-1.9,3.4-3.1V75h5.8V54.5c0-4.6-1.3-8.1-3.8-10.4C193,41.9,189.4,40.7,184.8,40.7z M189.4,69.1c-1.7,1-3.7,1.5-6,1.5          c-2.3,0-4.2-0.5-5.5-1.5c-1.3-1-2-2.3-2-4c0-1.4,0.5-2.7,1.6-3.7c1.1-1,3.1-1.5,6.2-1.5h9.5v4.7          C192.4,66.6,191.1,68.1,189.4,69.1z' />
          </g>
          <g>
            <path fill={color} d='M230.8,58c-1.3-0.6-2.7-1.1-4.2-1.5s-3.1-0.7-4.6-0.9c-1.5-0.2-2.9-0.5-4.2-0.9c-1.3-0.3-2.3-0.8-3.1-1.4          s-1.2-1.5-1.2-2.7c0-1.4,0.7-2.5,2-3.4c1.3-0.9,3.3-1.3,6-1.3c1.7,0,3.3,0.2,5,0.6c1.7,0.4,3.3,1.1,4.8,2.1l2.6-4.9         c-1.4-1-3.3-1.7-5.6-2.3c-2.3-0.5-4.5-0.8-6.7-0.8c-2.9,0-5.4,0.4-7.5,1.2c-2.1,0.8-3.7,2-4.9,3.6c-1.2,1.5-1.7,3.3-1.7,5.4          c0,2,0.4,3.5,1.2,4.7c0.8,1.2,1.8,2.1,3.1,2.8c1.3,0.7,2.7,1.2,4.2,1.5s3.1,0.7,4.6,0.9c1.5,0.2,2.9,0.5,4.2,0.8          c1.3,0.3,2.3,0.7,3,1.3c0.8,0.6,1.2,1.4,1.2,2.5c0,1.4-0.6,2.6-1.9,3.4c-1.3,0.8-3.3,1.2-6.2,1.2c-2.2,0-4.4-0.3-6.5-1          c-2.1-0.7-3.9-1.5-5.4-2.5l-2.6,4.9c1.4,1.1,3.4,2,6,2.8c2.5,0.8,5.2,1.2,8,1.2c3,0,5.6-0.4,7.8-1.2c2.2-0.8,3.9-2,5.1-3.5          c1.2-1.5,1.8-3.3,1.8-5.3c0-1.9-0.4-3.5-1.2-4.6C233.1,59.6,232,58.7,230.8,58z' />
          </g>
          <g>
            <path fill={color} d='M268.1,42.3c-2.1-1.1-4.6-1.6-7.3-1.6c-3.1,0-5.8,0.6-8.1,1.9c-1.6,0.9-2.9,2-3.9,3.3V27.5h-6.1V75h6.1V57.5          c0-2.5,0.4-4.5,1.3-6.2c0.9-1.7,2.1-2.9,3.7-3.8c1.6-0.9,3.5-1.3,5.7-1.3c2.9,0,5.1,0.8,6.7,2.5c1.6,1.7,2.4,4.1,2.4,7.4V75h6.1          V55.5c0-3.3-0.6-6.1-1.8-8.3C271.9,45,270.3,43.4,268.1,42.3z' />
          </g>
          <g>
            <path fill={color} d='M310.1,52.5c-2.2-2-5.4-3.2-9.5-3.5l11.7-14.5v-4.4h-29.6v5.6h21.5l-11.2,13.8v4.5h3.6          c3.8,0,6.6,0.7,8.4,2.1s2.7,3.3,2.7,5.7c0,2.4-0.9,4.4-2.8,5.8c-1.9,1.4-4.4,2.1-7.8,2.1c-2.7,0-5.3-0.4-7.7-1.3          c-2.4-0.9-4.3-2-5.8-3.5l-2.9,5.1c1.9,1.7,4.3,3,7.3,4c3,1,6,1.5,9.2,1.5c3.8,0,7-0.6,9.5-1.8c2.5-1.2,4.4-2.9,5.6-5          c1.2-2.1,1.9-4.4,1.9-6.9C314.2,58,312.8,54.9,310.1,52.5z' />
          </g>
          <g>
            <path fill={color} d='M347.4,49.4c-2.3-1.1-4.9-1.7-7.9-1.7c-2.3,0-4.4,0.3-6.3,1c-1.9,0.7-3.6,1.7-5,3c-0.6,0.6-1.1,1.2-1.6,1.9          c0-0.6,0-1.2,0-1.7c0-5.6,1.3-9.8,4-12.6c2.7-2.8,6.3-4.3,10.9-4.3c1.6,0,3.2,0.1,4.6,0.4c1.5,0.3,2.8,0.9,3.9,1.7l2.4-5          c-1.4-0.9-3.1-1.6-5-2c-1.9-0.4-4-0.6-6.1-0.6c-4.1,0-7.8,0.9-11,2.7c-3.2,1.8-5.7,4.4-7.5,7.9c-1.8,3.5-2.7,7.8-2.7,12.9          c0,4.8,0.7,8.8,2.2,12.1s3.6,5.8,6.4,7.6c2.8,1.7,6.2,2.6,10.2,2.6c3,0,5.7-0.6,8.1-1.7c2.4-1.2,4.3-2.8,5.7-4.9          c1.4-2.1,2.1-4.6,2.1-7.5c0-2.8-0.7-5.2-2-7.2C351.5,52.1,349.7,50.6,347.4,49.4z M347.3,66.2c-0.9,1.3-2,2.3-3.5,3          c-1.5,0.7-3.2,1.1-5.1,1.1c-2.3,0-4.2-0.4-5.8-1.2c-1.6-0.8-2.8-1.9-3.6-3.2c-0.8-1.3-1.2-2.7-1.2-4.3c0-1.7,0.4-3.1,1.3-4.4          c0.9-1.3,2.1-2.3,3.6-3.1c1.5-0.8,3.3-1.2,5.4-1.2c3.2,0,5.7,0.8,7.5,2.3c1.8,1.6,2.8,3.7,2.8,6.3          C348.6,63.3,348.2,64.9,347.3,66.2z' />
          </g>
          <g>
            <path fill={color} d='M393.7,40.2c-1.6-3.4-3.8-6-6.5-7.8c-2.8-1.8-5.9-2.7-9.3-2.7c-3.5,0-6.6,0.9-9.4,2.7          c-2.8,1.8-4.9,4.4-6.5,7.8c-1.6,3.4-2.4,7.5-2.4,12.4s0.8,9,2.4,12.4c1.6,3.4,3.8,6,6.5,7.8c2.7,1.8,5.9,2.7,9.4,2.7          c3.5,0,6.6-0.9,9.3-2.7c2.8-1.8,4.9-4.4,6.5-7.8c1.6-3.4,2.4-7.5,2.4-12.4S395.3,43.6,393.7,40.2z M388.2,62.1          c-1,2.6-2.4,4.5-4.2,5.8c-1.8,1.3-3.8,1.9-6.2,1.9c-2.3,0-4.4-0.6-6.2-1.9c-1.8-1.3-3.2-3.2-4.2-5.8c-1-2.6-1.5-5.7-1.5-9.5          s0.5-7,1.5-9.5c1-2.6,2.4-4.5,4.2-5.8c1.8-1.3,3.9-1.9,6.2-1.9c2.3,0,4.4,0.6,6.2,1.9c1.8,1.3,3.2,3.2,4.2,5.8s1.5,5.7,1.5,9.5          S389.2,59.6,388.2,62.1z' />
          </g>
        </g>
        <g>
          <g>
            <path fill={color} d='M52,22.7c0.8-0.6,1.6-1.3,2.5-1.8c13-7.7,26.2-8.1,38.9,0.4c10.7,7.2,15.7,17.8,15.4,30.6          c-0.6,26.9-30,43.6-53.9,30.5C42.3,75.5,36,64.5,35.3,50.2c0-0.5,0.1-1,0.2-1.4c-2-0.5-3.8-1-5.8-1.5c4.3-4.3,8.4-8.4,12.5-12.5          c1.6,5.6,3.2,11.2,4.8,16.9c-1.8-0.4-3.4-0.9-5.3-1.3c0.1,1.4,0.1,2.6,0.3,3.9c2.8,22.1,26.5,34.5,46.3,24.2       c17.2-9,22.4-29.6,12.8-46.1c-9-15.4-28.2-20.7-44.5-12.4c-1.6,0.8-3.1,1.8-4.7,2.7C52.1,22.8,52,22.7,52,22.7z' />
          </g>
          <g>
            <g>
              <path fill={color} d='M75.6,58.3c3.4,4.5,6.7,8.8,9.9,13.1c-0.1,0.1-0.2,0.3-0.2,0.4c-2.1,0-4.2,0-6.3,0c-0.3,0-0.7-0.5-1-0.9            c-2.7-3.5-5.4-7-8-10.6c-0.8-1-1.6-1.5-2.8-1.4c-1.4,0.1-2.9,0-4.4,0c-0.9,0-1.2-0.3-1.2-1.2c0.1-1.1,0.1-2.2,0-3.3            c0-0.9,0.3-1.2,1.2-1.2c2.6,0,5.2,0,7.8,0c4.4-0.1,7.7-2.6,8.5-6.7c1.1-5-2.7-9.8-7.8-9.9c-2.8-0.1-5.6,0-8.4,0            c-1,0-1.3-0.3-1.2-1.3c0.1-1.1,0-2.3,0.1-3.4c0-0.4,0.5-1,0.7-1c4,0.1,8-0.2,11.9,0.5c6.1,1.1,10.1,6.1,10.6,12.6            c0.4,4.9-2.1,10.5-7.9,13.3C76.6,57.8,76.2,58,75.6,58.3z' />
            </g>
            <g>
              <path fill={color} d='M66.9,39.4c0,3.7,0,7.4,0,11.2c-2.1,0.2-3.7-0.6-4.9-2.2c-1.5-1.9-1.6-4.4-0.4-6.5            C62.8,40.1,64.8,39.1,66.9,39.4z' />
            </g>
          </g>
        </g>
        <g>
          <path fill={color} d='M395.9,32c-3.5,0-6.4-2.9-6.4-6.4c0-3.5,2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4C402.2,29.1,399.4,32,395.9,32z         M395.9,23.3c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3C398.1,24.3,397.1,23.3,395.9,23.3z' />
        </g>
      </g>
    </svg>
  )
}
