import React, {useRef} from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer
} from 'recharts'
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { ChartLegendWrapper, ChartLegend, LegendColorBox, BarToolTipWrapper, BarToolTipLabel, BarToolTipPaper, BarToolTipValue, BarToolTipQTY, BarToolTipLabelValDiv, BarToolTipValueWrapper, BarToolTipValuePercentage } from './style';
import { truncateText, numberFormatter, numberWithCommas } from '../../utilities/utils';

const CustomTooltip = ({ payload }) => {
  const { t } = useTranslation()

  if(!payload || !payload.length){
    return ''
  }

  return <Box><BarToolTipPaper elevation={3}>
  <BarToolTipWrapper>  
  <BarToolTipLabelValDiv>
    <BarToolTipLabel>{t('brandDashboard.revenue')}: </BarToolTipLabel>
    <BarToolTipValueWrapper>
      <BarToolTipValue color={payload[0].color}>Rp. {numberWithCommas(payload[0].payload.totalRevenue)} </BarToolTipValue>
    </BarToolTipValueWrapper>
    </BarToolTipLabelValDiv>
    <BarToolTipLabelValDiv>
    <BarToolTipLabel>{t('brandDashboard.raenaCost')}: </BarToolTipLabel>
    <BarToolTipValueWrapper>
      <BarToolTipValue color={payload[1].color}>Rp. {numberWithCommas(Math.round(payload[0].payload.shippingCost))} </BarToolTipValue>
    </BarToolTipValueWrapper>
    </BarToolTipLabelValDiv>
    <BarToolTipQTY>
    {numberWithCommas(payload[0].payload.unitsSold)} {t('brandDashboard.qty')}
    </BarToolTipQTY>
    </BarToolTipWrapper>
  </BarToolTipPaper> </Box>
}

const VerticalBarChart = (props) => {
  const { barData, YAxisProps, graph, tickTruncateTextSize, tickSpaceFromBar } = props;
  const { t, i18n } = useTranslation()
  
  const CustomizedTick = (props) => {
    const {x, y, fill, payload, tickTruncateTextSize, tickSpaceFromBar} = props;
      return <>
      <title>{payload.value}</title>
      <text 
        x={x-tickSpaceFromBar} 
        y={y+5} 
        fontSize='14'
        fill={fill}
        textAnchor="start">
        {truncateText(payload.value,tickTruncateTextSize)}
      </text>
      </>
  }

  const CustomLabelList = (props) => {
    const {x, y, value, fill, width, height} = props;
    return (
      <text 
      x={(ref.current?.current?.clientWidth || 0) -90  } 
      y={y+(height) + 10} 
      dy={-4} 
      fontSize='14' 
      fontFamily='sans-serif'
      fill={fill}
      textAnchor="right">{numberFormatter(value, i18n)}</text>
    );
  }
  
  const XAxisCustomizedTick = (props) => {
    const {x, y, payload, fill} = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text
              fontSize='14' 
              y={15}
              fill={fill}
              textAnchor="middle"
              >{numberFormatter(payload.value, i18n)}</text>
        </g>
    );
  }
  const ref = useRef(null);

  return (
    <ResponsiveContainer ref={ref} height={Math.max((barData?.data?.length || 0) * 40, 150)}
    width="99.5%">
    <BarChart
      id={graph}
      barCategoryGap={6}
      layout='vertical'
      data={barData?.data}
      margin={{ right: 95 }}
    >
      <CartesianGrid strokeDasharray='3 3' horizontal={false} />
      <XAxis type='number' tick={<XAxisCustomizedTick />} />
      <YAxis type='category' dataKey='name' width={135} axisLine={false} {...YAxisProps} tick={<CustomizedTick tickTruncateTextSize={tickTruncateTextSize} tickSpaceFromBar={tickSpaceFromBar}/>}/>
      <Tooltip content={<CustomTooltip/>} />
      <Bar isAnimationActive={false} dataKey='totalRevenue' fill='#4880FF'>
        <LabelList id={1} dataKey="totalRevenue" position="right" content={<CustomLabelList/>}/>
      </Bar>
      <Bar isAnimationActive={false} dataKey='shippingCost' fill='#F56A83' />
    </BarChart>
    </ResponsiveContainer >
  )
}
export default VerticalBarChart
