import React from 'react'
import SidebarNavItem from './SidebarNavItem'
import { ROUTE_CONNECTIONS, ROUTE_ANALYTICS, ROUTE_ORDERS, ROUTE_PRODUCTS, ROUTE_LINKSTORE, ROUTE_BRANDS_DASHBOARD, ROUTE_BRANDS_CATALOUGE, ROUTE_BRANDS_PROMO, MSP_COMPARE } from '../../constants'

import { useTranslation } from 'react-i18next'

import {
  LogoWrapper,
  Logo,
  SidebarWrapper,
  SidebarNavIcon,
  SidebarNavText,
  SidebarNavItemWrapper,
  SidebarWrapperOverlay,
  MenuTextWrapper,
  ImageWraper
} from './style'

import logo from '../../assets/images/R-dash-360-Logo-White-01.svg'
import { Analytics } from '@mui/icons-material'
// import dashboardIcon from '../../assets/images/dashboard-icon.svg'
import connectionsIcon from '../../assets/images/connectionsIcon.png'
import orderIcon from '../../assets/images/orderIcon.png'
import productIcon from '../../assets/images/productIcon.png'
import linkStoreIcon from '../../assets/images/linkStoreIcon.png'
import OverviewIcon from '../../assets/images/overview-icon.svg'
import CatalougeIcon from '../../assets/images/catalouge-icon.svg'
import BrandPromoIcon from '../../assets/images/brand-promo-icon.svg'
import MSPCompareIcon from '../../assets/images/msp-compare-icon.svg'


import EventEmitter, { Events } from '../../utilities/eventEmitter'

const onClickMenuItem = (page) => {
  let eventName = ''

  switch (page) {
    case 'Dashboard': eventName = Events.TABS_DASHBOARD; break
    case 'Connections': eventName = Events.TABS_CONNECTIONS; break
    case 'Analytics': eventName = Events.TABS_ANALYTICS; break
    case 'Product': eventName = Events.TABS_PRODUCT; break
    case 'Orders': eventName = Events.TABS_ORDERS; break
    case 'Customers': eventName = Events.TABS_CUSTOMERS; break
    case 'Settings': eventName = Events.TABS_SETTINGS; break
    case 'LinkStore':eventName = Events.TABS_MYSTORE; break
    case 'PageBuilder': eventName = Events.TABS_SUPERSTORE; break
    default: eventName = ''
  }

  EventEmitter.emit(eventName, { page })
}
const Sidebar = ({ isSidebarVisible, toggleSidebar, brandAccess, resellerAccess, features }) => {
  const { t } = useTranslation()
  return (
    <>
      <SidebarWrapper isSidebarVisible={isSidebarVisible}>
        <LogoWrapper>
          <Logo src={logo} alt='Raena' />
        </LogoWrapper>
        {/* <MenuTextWrapper>Menu</MenuTextWrapper> */}
        <SidebarNavItemWrapper>
          {/* <SidebarNavItem to={ROUTE_DASHBOARD} onClick={() => onClickMenuItem('Dashboard')}>
            <SidebarNavIcon src={dashboardIcon} alt={t('menu.dashboard')} />
            <SidebarNavText>{t('menu.dashboard')}</SidebarNavText>
          </SidebarNavItem> */}
          {(resellerAccess || (!brandAccess && !resellerAccess)) &&
            <>
              <SidebarNavItem to={ROUTE_CONNECTIONS} onClick={() => onClickMenuItem('Connections')}>
                <ImageWraper>
                  <SidebarNavIcon src={connectionsIcon} alt={t('menu.connections')} />
                </ImageWraper>
                <SidebarNavText>{t('menu.connections')}</SidebarNavText>
              </SidebarNavItem>
              <SidebarNavItem to={ROUTE_ANALYTICS} onClick={() => onClickMenuItem('Analytics')}>
                <ImageWraper>
                  <Analytics />
                </ImageWraper>
                <SidebarNavText>{t('menu.analytics')}</SidebarNavText>
              </SidebarNavItem>
              {/* <SidebarNavItem to='/customers' onClick={() => onClickMenuItem('Customers')}>
            <SidebarNavIcon src={customers} alt={t('menu.Customers')} />
            <SidebarNavText>{t('menu.Customers')}</SidebarNavText>
          </SidebarNavItem> */}
              <SidebarNavItem to={ROUTE_ORDERS} onClick={() => onClickMenuItem('Orders')}>
                <ImageWraper>
                  <SidebarNavIcon src={orderIcon} alt={t('menu.orders')} />
                </ImageWraper>
                <SidebarNavText>{t('menu.orders')}</SidebarNavText>
              </SidebarNavItem>
              <SidebarNavItem to={ROUTE_PRODUCTS} onClick={() => onClickMenuItem('Product')}>
                <ImageWraper>
                  <SidebarNavIcon src={productIcon} alt={t('menu.products')} />
                </ImageWraper>
                <SidebarNavText>{t('menu.products')}</SidebarNavText>
              </SidebarNavItem>
              {/* <SidebarNavItem to='/settings' onClick={() => onClickMenuItem('Settings')}>
                    <SidebarNavIcon src={customers} alt={t('menu.Customers')} />
                    <SidebarNavText>{t('menu.Customers')}</SidebarNavText>
                  </SidebarNavItem>
              */}
              <SidebarNavItem to={ROUTE_LINKSTORE} onClick={() => onClickMenuItem('LinkStore')}>
                <ImageWraper>
                  <SidebarNavIcon src={linkStoreIcon} alt={t('menu.pageBuilder')} />
                </ImageWraper>
                <SidebarNavText>{t('menu.pageBuilder')}</SidebarNavText>
              </SidebarNavItem>
            </>}
          {brandAccess &&
            <>
              <SidebarNavItem to={ROUTE_BRANDS_DASHBOARD} onClick={() => onClickMenuItem('brandsOverview')}>
              <ImageWraper><SidebarNavIcon src={OverviewIcon} alt={t('menu.overview')} /></ImageWraper>
                <SidebarNavText>{t('menu.overview')}</SidebarNavText>
              </SidebarNavItem>
              {features.Brand_Catalogue_View &&
                <SidebarNavItem to={ROUTE_BRANDS_CATALOUGE} onClick={() => onClickMenuItem('brandsCatalouge')}>
                  <ImageWraper><SidebarNavIcon src={CatalougeIcon} alt={t('menu.catalouge')} /></ImageWraper>
                  <SidebarNavText>{t('menu.catalouge')}</SidebarNavText>
                </SidebarNavItem>}
              {features.Brand_Promo_View &&
                <SidebarNavItem to={ROUTE_BRANDS_PROMO} onClick={() => onClickMenuItem('brandPromo')}>
                 <ImageWraper><SidebarNavIcon src={BrandPromoIcon} alt={t('menu.brandPromo')} /></ImageWraper>
                  <SidebarNavText>{t('menu.brandPromo')}</SidebarNavText>
                </SidebarNavItem>}
                  {features.Brand_Promo_View &&
                    <SidebarNavItem to={MSP_COMPARE} onClick={() => onClickMenuItem('MSPCompare')}>
                     <ImageWraper><SidebarNavIcon src={MSPCompareIcon} alt={t('menu.MSPCompare')} /></ImageWraper>
                      <SidebarNavText>{t('menu.MSPCompare')}</SidebarNavText>
                    </SidebarNavItem>}
            </>}
        </SidebarNavItemWrapper>
      </SidebarWrapper>
      <SidebarWrapperOverlay isSidebarVisible={isSidebarVisible} onClick={toggleSidebar} />
    </>
  )
}

Sidebar.displayName = 'Sidebar'
export default Sidebar
