import Tooltip from '@mui/material/Tooltip'
import { Table, TableRow, TableCell, TableContainer, TableBody } from '@mui/material';
import {
  MOQ_VALUES, MOV_VALUES, getOfferText, numberWithCommas
} from "../../utilities/utils";
import { CellText, MovHeader, OfferTitle } from './style'


const RenderCellWithToolTip = (params) => {
  const { t } = params;
  let toolTipText = params.value;
  if (toolTipText === undefined) {
    if (params?.row.type === 'MOQ') {
      toolTipText = getOfferText(params?.row?.promoSkus, t, 'MOQ')
    } else if (params?.row?.type === 'MOV') {
      toolTipText = getOfferText(params?.row?.promoMov, t, 'MOV')
    }
  }

  const renderTableView = (row, toolTipText) => {
    let data = [];
    let clms = [];
    if(row?.type ==='MOV'||row?.type==='MOQ'){
      if (row?.type === 'MOQ') {
        clms = MOQ_VALUES(t);
        data = row?.promoSkus;
      } else if (row?.type === 'MOV') {
        clms = MOV_VALUES(t);
        data = row?.promoMov;
      }
      return (
        <TableContainer style={{ maxHeight: 200, background: 'transparent' }}>
          <Table size="small">
            <MovHeader
              sx={{
                background: '#E8EBEF',
                '& th:first-child': {
                  borderRadius: '10px 0 0 10px'
                },
                '& th:last-child': {
                  borderRadius: '0 10px 10px 0'
                }
              }}
            >
              <TableRow>
                {
                  clms.map((item) => (<TableCell style={{ textTransform: 'none', letterSpacing: '0px' }} ><OfferTitle>{item}</OfferTitle></TableCell>))
                }
              </TableRow>
            </MovHeader>
            {row?.type === 'MOV' ? loadMov(data) : loadMoq(data)}
          </Table>
  
        </TableContainer>
      )
    }else{
     return toolTipText
    }
    
  }

  const loadMov = (data) => {
    return (
      <TableBody >
        {
          data.map((item) => {
            return (
              <TableRow>
                <TableCell>
                  <OfferTitle>{numberWithCommas(item?.mov)}</OfferTitle>
                </TableCell>
                <TableCell>
                  <OfferTitle>{item?.discount}</OfferTitle>
                </TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    )
  }

  const loadMoq = (data) => {
    const items = [];
    if(data && data.length>0){
      data.forEach((item) => {
        const list = item?.moq.map((model, index) => ({ ...model, name: index === 0 ? item.sku : '' }))
        items.push(...list);
      });
      return (
        <TableBody>
          {items?.map((model) => (
            <TableRow>
              <TableCell>
                <OfferTitle>{model.name}</OfferTitle>
              </TableCell>
              <TableCell>
                <OfferTitle>{numberWithCommas(model.moq)}</OfferTitle>
              </TableCell>
              <TableCell>
                <OfferTitle>{model.discount}</OfferTitle>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      )
    }
   
  }

  const getToolTip = (params, toolTip) =>{
    let text = toolTip;
    if(params?.field === 'name'){
       text = `${params?.row.sku} : ${params?.row.name}`
    }
    return text;
  }

  return (
    <Tooltip
      title={ params?.row.type === 'REGULAR' || params?.field!=='discount' ? getToolTip(params, toolTipText) : renderTableView(params?.row, toolTipText)} arrow={params?.row?.type !== 'REGULAR'} componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'white',
            border: '2px solid #E8EBEF',
            color: 'black',
            '& .MuiTooltip-arrow': {
              color: 'white',
              fontSize: 20
            },
            '& .MuiTooltip-arrow::before': {
              border: '2px solid #E8EBEF',
              boxSizing: 'border-box'
            }
          }

        }
      }}
    ><CellText style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{toolTipText}</CellText>
    </Tooltip>
  )
}


export default RenderCellWithToolTip
