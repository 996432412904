import { connect } from 'react-redux'

import {
  AUTH_LOGIN_MOUNT,
  AUTH_LOGIN_USER
} from '../../actions'
import LoginComponent from '../../components/Login'

const mapStateToProps = ({ auth }) => {
  return {
    error: auth.get('error'),
    isLoggedIn: !!auth.get('idToken')
  }
}

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch({ type: AUTH_LOGIN_MOUNT })
  },
  onLogin: data => {
    dispatch({ type: AUTH_LOGIN_USER, data })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent)
