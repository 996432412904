import { connect } from 'react-redux'

import ProductListedOnModal from '../../components/ProductListedOnModal'

import {
  PRODUCTS_GET_LISTED_ON, STORES_SELECTED_GET_LIST, PRODUCTS_POST_LISTED_ON_UPDATEPRIZE
} from '../../actions'

const ProductListedOnModalContainer = props => {
  return (
    <ProductListedOnModal {...props} />
  )
}

const mapStateToProps = (state) => {
  const {
    productsListedOn,
    stores
  } = state
  return {
    selectedStoreAttributeList: stores.get('content').toJS(),
    productsListedOn: productsListedOn.get('content').toJS(),
    error: productsListedOn.get('error'),
    isLoading: productsListedOn.get('isLoading')
  }
}

const mapDispatchToProps = dispatch => ({
  onPostProductListedOnPrize: (query) => {
    dispatch({ type: PRODUCTS_POST_LISTED_ON_UPDATEPRIZE, payload: query })
  },
  onGetProductListedOn: (query) => {
    dispatch({ type: PRODUCTS_GET_LISTED_ON, payload: query })
  },
  onGetSelectedStoreAttributeList: (query) => {
    dispatch({ type: STORES_SELECTED_GET_LIST, payload: query })
  }
})

ProductListedOnModalContainer.displayName = 'ProductsListedOnContainer'
export default connect(mapStateToProps, mapDispatchToProps)(ProductListedOnModalContainer)
