import React from 'react'
import BannerSingle from '../BannerSingle'
import BenefitCard from '../BenefitCard'
import MarketPlaceCard from '../MarketPlaceCard'
import Heading from '../Heading'
import PricingCard from '../PricingCard'
import { Hr } from './style'

const Homepage = (props) => {
  const { data } = props

  return (
    <>
      <BannerSingle content={data.banner} />
      <BenefitCard content={data.benefitCard} />
      <Hr />
      <MarketPlaceCard content={data.marketPlace} />
      <Heading content={data.marketPlace} />
      <Hr />
      <PricingCard content={data.pricing} />
      <Hr />
      <Heading content={data.testimonials} />
      <Hr />
      <Heading content={data.brands} />
      <Hr />
    </>
  )
}

Homepage.displayName = 'Homepage'
export default Homepage
