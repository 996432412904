import { useState, useEffect } from 'react'
import queryString from 'query-string'

import MuiButton from '../MuiButton'
import ProductCard from '../ProductCard'
import Search from '../Search'
/* eslint-disable */
import Select from '../Select'
import LabalSelector from '../LabelSelector'
import Loading from '../LoadingSpinner'
import Modal from '../Modal'
import StoreSelectionModal from '../StoreSelectionModal'
import { ROUTE_PRODUCTS_CREATE, AddFromRaenaCatalog } from '../../constants'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  ProductListWrapper,
  ProductTopBar,
  TitleWrapper,
  Title,
  ProductToolbar,
  ProductLabelSelectorWrapper,
  ProductCardWrapper,
  NoRecordWraper
} from './style'
import RaenaProductList from '../RaenaProductList'
import ResuablePagination from '../ReuseablePagination'
import ProductHome from '../ProductHome'

import EventEmitter, { Events } from '../../utilities/eventEmitter'
const ProductList = ({
  error,
  isLoading,
  productList,
  storeError,
  storeIsLoading,
  storeList,
  onGetProductList,
  onGetStoreList,
  onFetchProductsTotalRecords,
  productsAllRecordLoading
}) => {
  const { t } = useTranslation()

  const labelSelectorOptions = [
    {
      label: t('productListing.allProducts'),
      value: ''
    },
    {
      label: t('productListing.liveProducts'),
      value: 'published'
    },
    {
      label: t('productListing.drafts'),
      value: 'Draft'
    },
    {
      label: t('productListing.AddRaenaProducts'),
      value: AddFromRaenaCatalog
    }
  ]

  const queryParams = queryString.parse(window.location.search)
  const [selectedStore, setSelectedStore] = useState('')
  const [stores, setStores] = useState([])
  // const [storeList, setStoreList] = useState([])
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(12)
  const [labelSelectedValue, setLabelSelectedValue] = useState(queryParams?.tab || labelSelectorOptions[0].value)
  const [selectedDropDownValue, setSelectedDropDownValue] = useState('Select')
  const productAllRecords = useSelector((state) => state.products.get('productAllRecords'))
  const totalRecords = useSelector((state) => state.products.get('totalRecords'))
  const navigate = useNavigate()
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  useEffect(() => {
    if (storeList.length && !storeError && !storeIsLoading) {
      const formattedStoreList = storeList.map(s => ({ label: s.shop_name, value: s.id }))
      setStores(formattedStoreList)
    }
  }, [storeList, storeError, storeIsLoading])

  useEffect(() => {
    onGetStoreList()
  }, [onGetStoreList])

  useEffect(() => {
    setSearch(queryParams?.q || search || '')

    if (queryParams?.tab) {
      setLabelSelectedValue(queryParams?.tab || labelSelectorOptions[0].value)
    }
  }, [queryParams, search])

  useEffect(() => {
    
    if (labelSelectedValue === AddFromRaenaCatalog) {
      EventEmitter.emit(Events.PRODUCT_ADD_FROM_PARTNER)
      return
    }else if (labelSelectedValue === 'published'){
      EventEmitter.emit(Events.PRODUCT_LIVE_LIST)
    }else if (labelSelectedValue === ''){
      EventEmitter.emit(Events.PRODUCT_ALL_LIST)
    }else if (labelSelectedValue === 'Draft'){
      EventEmitter.emit(Events.PRODUCT_DRAFT_LIST)
    }

    

    if (search !== ''){
      EventEmitter.emit(Events.PRODUCT_SEARCH_TERM, {search_keyword: search})
    }
    
    onGetProductList({
      limit,
      q: search,
      storeId: selectedStore,
      status: labelSelectedValue,
      page: page + 1
    })
  }, [onGetProductList, selectedStore, labelSelectedValue, search, page, limit])

  useEffect(() => {
    onFetchProductsTotalRecords({ limit: 11 })
  }, [])

  const ModalHandler = () => {
    return storeList ? setShowModal(!showModal) : showModal
    // Plz check this
  }
  const onSelect = (value) => {
    setPage(0)
    setSelectedStore(value)
  }
  const AddProductClick = () => {
    EventEmitter.emit(Events.ADD_PRODUCT, { page: 'Product List' })
    navigate(ROUTE_PRODUCTS_CREATE)
  }

  const onClickAddNewProduct = () => {
    EventEmitter.emit(Events.ADD_PRODUCT, { page: 'Add New Product' })
  }

  const isPartnerCatalogTab = (labelSelectedValue === AddFromRaenaCatalog)

  return (
    <>
      {((productAllRecords > 0) || isPartnerCatalogTab) ? (
        <ProductListWrapper>
          <ProductTopBar>
            <TitleWrapper>
              <Title>{t('productListing.products')}</Title>
              <MuiButton to={ROUTE_PRODUCTS_CREATE} styled='primary' onClick={onClickAddNewProduct} variant='contained'>{t('productListing.addNewProducts')}</MuiButton>
            </TitleWrapper>
            <ProductToolbar style={{ opacity: isPartnerCatalogTab ? 0 : 1 }}>
              <Select
                options={stores}
                placeholderText='Select'
                onSelect={onSelect}
                selectedValue={selectedDropDownValue}
                setIsFilterApplied={setIsFilterApplied}
                setSelectedValue={setSelectedDropDownValue}
              />
            </ProductToolbar>
            <ProductLabelSelectorWrapper>
              <LabalSelector
                options={labelSelectorOptions}
                onSelect={(value) => {
                  setSearch('')
                  setPage(0)
                  setLabelSelectedValue(value)
                  setSelectedStore('')
                  navigate('/products')
                }}
                selected={labelSelectedValue}
                setSelected={setLabelSelectedValue}
              />
            </ProductLabelSelectorWrapper>
          </ProductTopBar>
          {isPartnerCatalogTab ?
            <RaenaProductList
              setTab={setLabelSelectedValue}
            /> : <>
              {!error && isLoading && <Loading />}
              <Search
                value={search}
                placeholder={t('placeHolderText.searchByProductNameId')}
                onEnter={(value) => {
                  setPage(0)
                  setSearch(value)
                  navigate('/products')
                }}
                displayCloseIcon='block'
              />
              <ProductCardWrapper>
                {
                  (!error && !isLoading) &&
                    productList.length > 0 ?
                    productList.map((product, idx) => (
                      <>
                        <ProductCard
                          key={idx}
                          product={product}
                          shouldStatusVisible={labelSelectedValue === ''}  
                          onGetProductList={onGetProductList}
                          qParams={{
                            limit,
                            q :search,
                            storeId: selectedStore,
                            status: labelSelectedValue,
                            page: page + 1
                          }}
                        />
                      </>
                    ))
                    : <>
                      {(!error && !isLoading) &&
                        <NoRecordWraper>No record Found</NoRecordWraper>
                      }</>
                }
              </ProductCardWrapper>
              <ResuablePagination page={page}
                setPage={setPage}
                rowsPerPage={limit}
                setRowsPerPage={setLimit}
                totalRecords={totalRecords}
              />
              <Modal showModal={showModal} toggleModal={ModalHandler}>
                <StoreSelectionModal stores={storeList} toggleModal={ModalHandler} />
              </Modal>
            </>
          }
        </ProductListWrapper>
      ) : (
        <>
          {
            !error && productsAllRecordLoading
              ? <Loading />
              : <ProductHome btnClick={AddProductClick} />
          }
        </>
      )}
    </>
  )
}
export default ProductList
