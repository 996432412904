import { call, put, takeLatest } from 'redux-saga/effects'

import {
  ORDERS_LOADING,
  ORDERS_GET_LIST,
  ORDERS_GET_LIST_FAILURE,
  ORDERS_GET_LIST_SUCCESS,
  ORDERS_PARTIAL_FULFILLMENT,
  ORDERS_PARTIAL_FULFILLMENT_FAILURE,
  ORDERS_PARTIAL_FULFILLMENT_SUCCESS,
  ORDER_CANCEL_LOADING,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAILURE,
  ORDER_CANCEL_ORDER,
  ORDER_SHIP_LOADING,
  ORDER_SHIP_SUCCESS,
  ORDER_SHIP_FAILURE,
  ORDER_SHIP,
  ORDERS_STATUS_LOADING,
  ORDERS_STATUS_GET_LIST_SUCCESS,
  ORDERS_STATUS_GET_LIST_FAILURE,
  ORDER_LIST_STATUS
} from '../actions'

import {
  API_GET_ORDERS,
  API_CANCEL_ORDER,
  API_SHIP_ORDER,
  API_GET_ORDERS_STATUS,
  API_UPDATE_PARTIAL_FLAG
} from '../constants'
import { getApi, postApi } from '../utilities/axios'

function * onGetOrderList (action) {
  yield put({ type: ORDERS_LOADING, isLoading: true })
  const {
    fStatus,
    payloadQueryParam,
    searchQueryParam,
    skip,
    limit,
    subStatus
  } = action?.payload

  let query = {}

  if (!subStatus) {
    const fulFillmentStatus = fStatus !== 'all' ? fStatus : null
    let q = null
    if (searchQueryParam) q = searchQueryParam
    else q = (payloadQueryParam !== 'Select' && payloadQueryParam !== 'Show All') ? payloadQueryParam || null : null
    query = {
      q,
      fulFillmentStatus,
      skip,
      limit
    }
  } else {
    query = {
      status: subStatus,
      skip,
      limit
    }
  }

  try {
    const response = yield call(getApi, API_GET_ORDERS, query)
    yield put({ type: ORDERS_GET_LIST_SUCCESS, data: response?.data?.data || response?.data || {} })
  } catch (e) {
    yield put({ type: ORDERS_GET_LIST_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: ORDERS_GET_LIST_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: ORDERS_GET_LIST_SUCCESS, data: {} })
  }
  yield put({ type: ORDERS_LOADING, isLoading: false })
}

function * onGetOrderStatusList (action) {
  yield put({ type: ORDERS_STATUS_LOADING, orderStatusListIsLoading: true })
  try {
    const response = yield call(getApi, API_GET_ORDERS_STATUS)
    yield put({ type: ORDERS_STATUS_GET_LIST_SUCCESS, data: response?.data?.data || response?.data || {} })
  } catch (e) {
    yield put({ type: ORDERS_STATUS_GET_LIST_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: ORDERS_STATUS_GET_LIST_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: ORDERS_STATUS_GET_LIST_SUCCESS, data: {} })
  }
  yield put({ type: ORDERS_STATUS_LOADING, orderStatusListIsLoading: false })
}

function * onPostCancelOrder (action) {
  console.log('Post Cancel Order')
  yield put({ type: ORDER_CANCEL_LOADING, orderCancelIsLoading: true })
  try {
    const query = action.payload || null
    const response = yield call(postApi, API_CANCEL_ORDER, query)
    yield put({ type: ORDER_CANCEL_SUCCESS, data: response?.data || {} })
  } catch (e) {
    yield put({ type: ORDER_CANCEL_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: ORDER_CANCEL_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: ORDER_CANCEL_SUCCESS, data: {} })
  }
  yield put({ type: ORDER_CANCEL_LOADING, orderCancelIsLoading: false })
}

function * onPostShipOrder (action) {
  yield put({ type: ORDER_SHIP_LOADING, orderShipIsLoading: true })
  try {
    const query = action.payload || null
    const response = yield call(postApi, API_SHIP_ORDER, query)
    yield put({ type: ORDER_SHIP_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    yield put({ type: ORDER_SHIP_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: ORDER_SHIP_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: ORDER_SHIP_SUCCESS, data: {} })
  }
  yield put({ type: ORDER_SHIP_LOADING, orderShipIsLoading: false })
}

function * onUpdatePartialFulfillment (action) {
  try {
    const query = action.payload || null
    const response = yield call(postApi, API_UPDATE_PARTIAL_FLAG, query);
    yield put({ type: ORDERS_PARTIAL_FULFILLMENT_SUCCESS, partialOrderFulfillment: response?.data})
  } catch (e) {
    yield put({ type: ORDERS_PARTIAL_FULFILLMENT_FAILURE, message: ''})
  }
}

function * ordersSaga () {
  yield takeLatest(ORDERS_GET_LIST, onGetOrderList)
  yield takeLatest(ORDERS_PARTIAL_FULFILLMENT, onUpdatePartialFulfillment)
  yield takeLatest(ORDER_CANCEL_ORDER, onPostCancelOrder)
  yield takeLatest(ORDER_SHIP, onPostShipOrder)
  yield takeLatest(ORDER_LIST_STATUS, onGetOrderStatusList)
}

export default ordersSaga
