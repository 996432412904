import React, { useEffect, useState, useRef } from 'react'
import { Grid, Paper } from '@mui/material'
import { useParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom'
import { Main, BoxM } from './style'
import DataTable from './DataTable'
import TitleRow from './TitleRow'
import FilterRow from './FilterRow'
import LoadingSpinner from '../LoadingSpinner'
import { MSP_COMPARE } from "../../constants/routes";
import SummaryRow from './SummaryRow';


const BrandsMspDetails = (props) => {
  const {
    mspDetailsIsloading,
    mspStoreList,
    mspStoreTotal,
    promoTypeList,
    onGetBrandMSPDetails,
    selectedBrand,
    brandIsLoading,
    features,
    mspDetailsExportIsloading,
    downlaodMspDetails,
    onGetBrandMSPDetailsFilters,
    mspDetailsFilterIsloading,
    filtersData
  } = props
  const { skuId } = useParams();
  const navigate = useNavigate();
  const initialStates = {
    searchText: '',
    sorting: {},
    page: 0,
  }

  const [searchText, setSearchText] = useState(initialStates.searchText)
  const [sorting, setSorting] = useState(initialStates.sorting)
  const [page, setPage] = useState(initialStates.page)
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [logos, setLogos] = useState({});

  const firstRender = useRef(true);

  const handleFilterValueChange = (filterName, value) => {
    const newValues = {...selectedFilterValues};
    newValues[filterName] = filterName === 'shipsFrom' || filterName === 'marketplaces' || filterName === 'rating' ? value : { ...newValues[filterName], ...value };
    setSelectedFilterValues(newValues);
  }

  const downloadHandler = (sku) => {
    downlaodMspDetails({
      brandId: selectedBrand.id,
      sku: skuId
    });
  }

  const resetStates = () => {
    setSearchText(initialStates.searchText)
    setSorting(initialStates.sorting)
    setPage(initialStates.page)
  }


  useEffect(() => {
    if (!selectedBrand) {
      return
    }

    resetStates()
  }, [onGetBrandMSPDetails, selectedBrand])


  useEffect(() => {
    if(firstRender.current){
      firstRender.current = false
     return; 
    }

    if (!selectedBrand) {
      return;
    }

    navigate(MSP_COMPARE)
  }, [selectedBrand])


  useEffect(()=> {
    if(filtersData?.marketplace && filtersData?.marketplace.length){
      let arrangedLogos = {}

      for(let i=0; i < filtersData?.marketplace.length; i++){
        arrangedLogos[filtersData?.marketplace?.[i].marketplace] = filtersData?.marketplace?.[i].logo
      }
  
      setLogos(arrangedLogos)
    }

  },[filtersData]);

  useEffect(() => {
    if (!selectedBrand) {
      return
    }
    onGetBrandMSPDetails({
      offset: page * 20,
      limit: 20,
      brandId: selectedBrand.id,
      sku: skuId,
      skuOrNameSearch: searchText,
      sort: sorting.sort,
      sortOrder: sorting.sortOrder,
      ...selectedFilterValues
    });

    onGetBrandMSPDetailsFilters({
      brandId: selectedBrand.id,
      sku: skuId,
      ...selectedFilterValues
    });
  
  }, [searchText, sorting, page, onGetBrandMSPDetails, selectedFilterValues])

  return (
    <>
      {(brandIsLoading || mspDetailsExportIsloading) && <LoadingSpinner />}
      {(!brandIsLoading && !mspDetailsExportIsloading) &&
        (<Main>
          <FilterRow
          promoTypeList={promoTypeList}
          searchtext={searchText}
          handleSearchTextChange={setSearchText}
          filtersData={filtersData}
          handleFilterValueChange={handleFilterValueChange}
          selectedFilterValues={selectedFilterValues}
        />
        
        <SummaryRow filtersData={filtersData} mspDetailsFilterIsloading={mspDetailsFilterIsloading}/>
            <Paper>
              <BoxM>
                <TitleRow mspStoreTotal={mspStoreTotal} downloadHandler={downloadHandler}                />
                <Grid>
                  <DataTable
                    mspStoreList={mspStoreList}
                    loading={mspDetailsIsloading}
                    sortingHandler={setSorting}
                    pageChangehandler={setPage}
                    page={page}
                    mspStoreTotal={mspStoreTotal}
                    features={features}
                    logos={logos}
                  />
                </Grid>
              </BoxM>
            </Paper>
          </Main>)}
    </>
  )
}

BrandsMspDetails.displayName = 'BrandsMspDetailsComponent'
export default BrandsMspDetails
