import { useState, useEffect } from 'react'
import queryString from 'query-string'

import MuiButton from '../MuiButton'
import Search from '../Search'
import MuiSelect from '../MuiSelect'
import Loading from '../LoadingSpinner'
import { PageBuilderLinksInnerCard } from '../PageBuilderLinksCard'
import { ROUTE_LINKSTORE, ROUTE_LINKSTORE_RAENAPRODUCTS, ROUTE_LINKSTORE_RAENAPRODUCTS_ADD } from '../../constants'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { iconPin, iconUnpin } from '../../assets/images/icons'

import {
  ProductListWrapper,
  ProductTopBar,
  TitleWrapper,
  Title,
  BackButton,
  ProductCardWrapper,
  SearchWrapper,
  NoRecordWraper
} from './style'
import ResuablePagination from '../ReuseablePagination'
import MuiAlertDialog from '../MuiAlertDialog'

const PageBuilderRaenaProducts = (props) => {
  const {
    error,

    onGetPageBuilderRaenaProductResellerList,
    pageBuilderRaenaProductsResellerIsLoading,
    pageBuilderRaenaProductsResellerList,

    onGetPageBuilderRaenaProductsUpdateData,
    pageBuilderRaenaProductsUpdateData,
    pageBuilderRaenaProductsUpdateIsLoading,

    onGetPageBuilderRaenaProductsDeleteData,
    pageBuilderRaenaProductsDeleteData,
    pageBuilderRaenaProductsDeleteIsLoading,

    onGetUserInfo
  } = props
  const { t } = useTranslation()

  const labelSelectorOptions = 'published'

  const queryParams = queryString.parse(window.location.search)
  const queryParamString = queryString.stringify(queryParams)

  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(20)
  const [labelSelectedValue, setLabelSelectedValue] = useState(queryParams?.tab || labelSelectorOptions)
  const [selectedDropDownValue, setSelectedDropDownValue] = useState('select')
  const shareId = useSelector((state) => state?.auth.get('shareId'))
  const tierId = useSelector((state) => state?.auth.get('tierId'))

  const [productAllRecords, setProductAllRecords] = useState()
  const [totalRecords, setTotalRecords] = useState()
  const navigate = useNavigate()
  const [showDeleteModalFlag, setShowDeleteModalFlag] = useState(false)
  const [showDeleteModalId, setShowDeleteModalId] = useState()
  const [deleteHandlerTitle, setDeleteHandlerTitle] = useState('')

  useEffect(() => {
    if (shareId === '' && tierId === '') {
      onGetUserInfo()
    }
  }, [shareId, tierId])

  useEffect(() => {
    setProductAllRecords(pageBuilderRaenaProductsResellerList?.products?.length || 0)
    setTotalRecords(pageBuilderRaenaProductsResellerList?.totalRecords || 0)
  }, [pageBuilderRaenaProductsResellerList])

  useEffect(() => {
    setSearch(queryParams?.q || search || '')
    if (queryParams?.tab) {
      setLabelSelectedValue(queryParams?.tab || labelSelectorOptions)
    }
  }, [queryParams, search])

  useEffect(() => {
    if (shareId) {
      onGetPageBuilderRaenaProductResellerList({
        shareId: shareId,
        name: search,
        page: page + 1,
        limit
      })
    }
  }, [shareId, onGetPageBuilderRaenaProductResellerList, selectedDropDownValue, labelSelectedValue, search, page, limit])

  const menuHandler = (e, object) => {
    if (e === 'Pin' || e === 'Unpin') {
      onGetPageBuilderRaenaProductsUpdateData({
        type: 'menu',
        isPinned: e === 'Pin',
        isPublished: object?.isPublished,
        margin: object?.margin,
        sku: object?.sku,
        shareId: object?.shareId
      })
    }
  }

  const statusHandler = (e) => {
    onGetPageBuilderRaenaProductsUpdateData({
      type: 'status',
      isPublished: !e.isPublished,
      isPinned: e.isPinned,
      margin: e.margin,
      sku: e.sku,
      shareId: e.shareId
    })
  }

  const editHandler = (e) => {
    onGetPageBuilderRaenaProductsUpdateData({
      type: 'edit',
      isPublished: e.isPublished,
      isPinned: e.isPinned,
      margin: e.margin,
      sku: e.sku,
      shareId: e.shareId
    })
  }

  const deleteHandler = (e) => {
    setShowDeleteModalFlag(true)
    setShowDeleteModalId(e)
    const type = e.sectionType === 'CUSTOM_LINK' ? 'link' : 'product'
    setDeleteHandlerTitle(`Delete ${type} (${e?.title}) forever?`)
  }
  const deleteHandlerConfirmed = (e) => {
    if (productAllRecords === 1 && page > 0) {
      setPage(page - 1)
    }
    onGetPageBuilderRaenaProductsDeleteData({ sku: showDeleteModalId.sku, shareId: showDeleteModalId.shareId })
    setShowDeleteModalFlag(false)
    setShowDeleteModalId('')
  }
  const deleteHandlerCancelled = (e) => {
    setShowDeleteModalFlag(false)
    setShowDeleteModalId('')
  }

  useEffect(() => {
    if ((pageBuilderRaenaProductsUpdateData || pageBuilderRaenaProductsDeleteData) && shareId) {
      onGetPageBuilderRaenaProductResellerList({
        shareId: shareId,
        name: search,
        page: page + 1,
        limit
      })
    }
  }, [pageBuilderRaenaProductsUpdateData, pageBuilderRaenaProductsDeleteData])

  return (
    <>
      <ProductListWrapper>
        <ProductTopBar>
          <TitleWrapper>
            <Title>
              <BackButton to={`${ROUTE_LINKSTORE}?tab=links&${queryParamString}`}><ArrowBackIosIcon /></BackButton>
              {t('pageBuilderLink.liveRaenaProducts')}
            </Title>
            <MuiButton to={ROUTE_LINKSTORE_RAENAPRODUCTS_ADD} styled='primary' variant='contained'>{t('pageBuilderLink.addRaenaProducts')}</MuiButton>
          </TitleWrapper>
        </ProductTopBar>

        {!error && pageBuilderRaenaProductsResellerIsLoading && <Loading />}
        <SearchWrapper>
          <Search
            value={search}
            placeholder={t('pageBuilderLink.searchByProductNameBrand')}
            onEnter={(value) => {
              setPage(0)
              setSearch(value)
              navigate(ROUTE_LINKSTORE_RAENAPRODUCTS)
            }}
            displayCloseIcon='block'
          />
          <MuiSelect
            placeholder='Select'
            value={selectedDropDownValue}
            name='RaenaStores'
            size='small'
            onChange={(e) => { setPage(0); setSelectedDropDownValue(e.target.value) }}
            data={[]}
            selectlabel='label'
            selectvalue='value'
            style={{ display: 'none' }}
          />
        </SearchWrapper>

        {(productAllRecords > 0 && shareId && tierId) && (
          <>
            <ProductCardWrapper className='ProductCardWrapper'>
              {
                (!error && !pageBuilderRaenaProductsResellerIsLoading) &&
                  <>
                    {pageBuilderRaenaProductsResellerList?.products.length > 0 && pageBuilderRaenaProductsResellerList?.products.map((data, i) => {
                      const list = {
                        title: data?.product?.name || '',
                        imageUrl: data?.product?.thumbnailImages?.[0]?.url || '',
                        // price: data?.dsf?.retailPrice || '',
                        price: data?.dsf?.retailPrice || '',
                        productRetailPrice: data?.product?.retailPrice || '',
                        id: data?.product?.id,
                        isPublished: data?.dsf?.isPublished,
                        margin: data?.dsf?.userMargin, // modified this as when we add the 'margin' value is showing different
                        sku: data?.product?.sku,
                        shareId: shareId,
                        isPinned: data?.dsf?.isPinned,
                        // tierIdValue: data?.dsf?.finalPrice,
                        tierIdValue: data?.product?.tierPrice?.[tierId], // commented need to confirm
                        type: 'RAENA_PRODUCT',
                        clicks: data?.clicks || 0
                      }

                      const pined = (list.isPinned
                        ? { label: 'Unpin', icon: iconUnpin }
                        : { label: 'Pin', icon: iconPin }
                      )
                      const menuOptions = [
                        pined
                      ]
                      return (
                        <PageBuilderLinksInnerCard
                          {...props}
                          cardType='RAENA_PRODUCT'
                          list={list}
                          index={i}
                          key={i}
                          horizontalArrows={false}
                          showEditDeleteSwitch
                          editHandler={editHandler}
                          deleteHandler={deleteHandler}
                          statusHandler={statusHandler}
                          menuHandler={menuHandler}
                          // menuOptions={list.isPublished ? menuOptions : []}
                          menuOptions={menuOptions}
                        />
                      )
                    })}
                  </>
              }
            </ProductCardWrapper>

            <ResuablePagination
              page={page}
              setPage={setPage}
              rowsPerPage={limit}
              setRowsPerPage={setLimit}
              totalRecords={totalRecords}
              rowsPerPageOptions={[20, 40, 80, 120]}
            />
          </>
        )}
        {!pageBuilderRaenaProductsResellerIsLoading && productAllRecords === 0 && <NoRecordWraper>{t('pageBuilderLink.noRecordFound')}</NoRecordWraper>}
      </ProductListWrapper>
      {showDeleteModalFlag &&
        <MuiAlertDialog
          open={showDeleteModalFlag}
          title={deleteHandlerTitle}
          onCancelled={deleteHandlerCancelled}
          onConfirmed={deleteHandlerConfirmed}
        />}
    </>
  )
}
export default PageBuilderRaenaProducts
