import { Grid, Backdrop, Box, Table, TableHead, TableRow, TableCell, Paper, TableBody } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridWrapper, MarketplaceCell, NewLinkIcon, LogoImgInTable } from './style'
import MuiDataGrid from '../MuiDataGrid'
import RenderCellWithToolTip from '../MuiDataGrid/RenderCellWithToolTip'
import {numberWithCommas, numberFormat} from '../../utilities/utils';
import openLinkIcon from '../../assets/images/openLinkIcon.svg'

const marketplaceRenderCell = (params) => {
  return <MarketplaceCell>
  {params.colDef.logos[params.row.marketplace] && <LogoImgInTable src={params.colDef.logos[params.row.marketplace]} alt={params.row.marketplace}/>}
  <div>{params.value}</div> 
    {params.row.listingUrl && <NewLinkIcon onClick={()=>{
      window.open(params.row.listingUrl, '_blank').focus();

    }}> <img src={openLinkIcon} alt="OpenStore"/></NewLinkIcon>} 
  </MarketplaceCell>
}

const columns = (t, logos) => ([
  {
    field: 'storeName',
    headerName: t('mspCompare.marketplaceStoreName'),
    selectable: false,
    editable: false,
    sortable: false,
    minWidth: 280,
    renderCell: marketplaceRenderCell,
    flex:1,
    logos: logos
  },
  {
    field: 'stock',
    headerName: t('mspCompare.availableStock'),
    selectable: false,
    editable: false,
    sortable: false,
    minWidth: 80,
    renderCell: RenderCellWithToolTip,
    flex:1
  },
  {
    field: 'price',
    headerName: t('productDetails.price'),
    selectable: false,
    editable: false,
    sortable: true,
    minWidth: 150,
    renderCell: RenderCellWithToolTip,
    valueGetter: (params) =>
      `Rp ${numberWithCommas(numberFormat(params?.row?.price, 0))}`,
    flex:1
  },
  {
    field: 'totalQuantitySold',
    headerName: t('mspCompare.totalUnitSold'),
    selectable: false,
    editable: false,
    sortable: false,
    minWidth: 80,
    renderCell: RenderCellWithToolTip,
    valueGetter: (params) => numberFormat(params?.row?.totalQuantitySold, 0),
    flex:1
  },
  {
    field: 'monthlyQuantitySold',
    headerName: t('mspCompare.monthlyUnitsSold'),
    selectable: false,
    editable: false,
    sortable: false,
    minWidth: 80,
    renderCell: RenderCellWithToolTip,
    valueGetter: (params) => numberFormat(params?.row?.monthlyQuantitySold, 0),
    flex:1
  }
  ,
  {
    field: 'response',
    headerName: t('mspCompare.response'),
    selectable: false,
    editable: false,
    sortable: false,
    minWidth: 80,
    renderCell: RenderCellWithToolTip,
    valueGetter: (params) => params?.row?.response ? `${params?.row?.response}%` : "--",
    flex:1
  },
  {
    field: 'matchPercentage',
    headerName: t('mspCompare.matchPercentage'),
    selectable: false,
    editable: false,
    sortable: false,
    minWidth: 80,
    renderCell: RenderCellWithToolTip,
    valueGetter: (params) => params?.row?.matchPercentage ? `${params?.row?.matchPercentage}%` : "--",
    flex:1
  },
  {
    field: 'followers',
    headerName: t('mspCompare.followers'),
    selectable: false,
    editable: false,
    sortable: true,
    renderCell: RenderCellWithToolTip,
    valueGetter: (params) => numberFormat(params?.row?.followers, 0),
    flex:1,
    minWidth: 105
  },
  {
    field: 'shipFrom',
    headerName: t('mspCompare.shipsFrom'),
    selectable: false,
    editable: false,
    sortable: false,
    minWidth: 150,
    renderCell: RenderCellWithToolTip,
    flex:1
  },
  {
    field: 'rating',
    headerName: t('mspCompare.rating'),
    selectable: false,
    editable: false,
    sortable: true,
    renderCell: RenderCellWithToolTip,
    valueGetter: (params) => numberFormat(params?.row?.rating, 1),
    flex:1,
    minWidth: 105
  }
])

const loadingRow = (t) => ([
  { marketplace: 0,  storeName: t('brandCatalouge.loading'), stock: 0, price: 0, totalQuantitySold: 0, followers: 0, monthlyQuantitySold: 0, shipFrom: "", rating: 0, id: 0}
])

const DataTable = ({ loading, mspStoreList, sortingHandler, pageChangehandler, mspStoreTotal, editPromoHandler, features, viewPromoDetailsHandler, logos }) => {
  const { t } = useTranslation()

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5
    }
  }, [])

  const handleSortModelChange = React.useCallback(sortModel => {
    if (sortModel.length) {
      let field = sortModel[0].field

      if (field) {
        sortingHandler({
          sort: field,
          sortOrder: sortModel[0].sort.toUpperCase()
        })
      }
    } else {
      sortingHandler({})
    }
  }, [sortingHandler])

  const list = mspStoreList.map((item, i) => ({ ...item, id: i }))

  return (
    <DataGridWrapper container>
      <MuiDataGrid
        loading={loading}
        getRowSpacing={getRowSpacing}
        rows={!loading ? list : loadingRow(t)}
        columns={columns(t, logos, features)}
        pageSize={20}
        onPageChange={pageChangehandler}
        rowHeight={70}
        rowCount={mspStoreTotal}
        onSortModelChange={handleSortModelChange}
        getRowId={(row) => row.id}
        headerHeight={72}
      />
    </DataGridWrapper>
  )
}

DataTable.displayName = 'BrandPromoDataTableComponent'
export default DataTable
