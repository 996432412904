import { call, put, takeLatest } from 'redux-saga/effects'

import {
  CATEGORIES_LOADING,
  CATEGORIES_GET_LIST,
  CATEGORIES_GET_LIST_SUCCESS,
  CATEGORIES_GET_LIST_FAILURE,
  CATEGORIES_BRANDS_LOADING,
  CATEGORIES_BRANDS_GET_LIST,
  CATEGORIES_BRANDS_GET_LIST_SUCCESS,
  CATEGORIES_BRANDS_GET_LIST_FAILURE,
  CATEGORIES_ATTRIBUTES_GET_LIST,
  CATEGORIES_ATTRIBUTES_GET_LIST_SUCCESS,
  CATEGORIES_ATTRIBUTES_GET_LIST_FAILURE,
  CATEGORIES_DROPDOWN_GET_LIST,
  CATEGORIES_DROPDOWN_GET_LIST_SUCCESS,
  CATEGORIES_DROPDOWN_GET_LIST_FAILURE
} from '../actions'

import {
  API_GET_CATEGORIES,
  API_GET_CATEGORY_BRANDS,
  API_GET_CATEGORY_ATTRIBUTES
} from '../constants'
import { getApi, postApi } from '../utilities/axios'
import { toast } from 'react-toastify'

import { attributesResponse } from './__mock__'

function * onGetCategoryList (action) {
  yield put({ type: CATEGORIES_LOADING, isLoading: true })
  try {
    const query = {
      q: action.payload?.q || null,
      page: action.payload?.page || 1,
      limit: action.payload?.limit || 20,
      marketplace_shop_id: action.payload?.marketplace_shop_id || null,
      parentCategoryId: action.payload?.parentCategoryId || null,
      marketplace: action.payload?.marketplace || null,
      has_childred: action.payload?.has_childred || null
    }
    const response = yield call(getApi, API_GET_CATEGORIES, query)
    yield put({ type: CATEGORIES_GET_LIST_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    yield put({ type: CATEGORIES_GET_LIST_FAILURE, message: e.message })
    // This is temporary as BE is returning 404 error
    yield put({ type: CATEGORIES_GET_LIST_SUCCESS, data: {} })
  }
  yield put({ type: CATEGORIES_LOADING, isLoading: false })
}

function * onGetCategoryBrandsList (action) {
  yield put({ type: CATEGORIES_BRANDS_LOADING, isLoading: true })
  try {
    // const query = {
    //   selectedStores: action.payload?.selectedStores,
    //   categoryId: action.payload?.categoryId,
    //   marketplace: action.payload?.marketplace
    // }
    const query = {
      q: '',
      categoryId: action.payload?.categoryId,
      limit: action?.payload?.limit || 100
    }
    const response = yield call(getApi, API_GET_CATEGORY_BRANDS, query)
    yield put({ type: CATEGORIES_BRANDS_GET_LIST_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    toast.error(e?.response?.data?.message)
    yield put({ type: CATEGORIES_BRANDS_GET_LIST_FAILURE, message: e.message })
    // This is temporary as BE is returning 404 error
    yield put({ type: CATEGORIES_BRANDS_GET_LIST_SUCCESS, data: {} })
  }
  yield put({ type: CATEGORIES_BRANDS_LOADING, isLoading: false })
}

function * onGetCategoryAttributesList (action) {
  yield put({ type: CATEGORIES_LOADING, isLoading: true })
  // yield put({ type: CATEGORIES_ATTRIBUTES_GET_LIST_SUCCESS, data: attributesResponse || {} })
  try {
    const query = {
      selectedStores: action.payload?.selectedStores,
      categoryId: action.payload?.categoryId,
      marketplace: action.payload?.marketplace
    }
    const response = yield call(postApi, API_GET_CATEGORY_ATTRIBUTES, query)
    yield put({ type: CATEGORIES_ATTRIBUTES_GET_LIST_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    toast.error(e?.response?.data?.message)
    yield put({ type: CATEGORIES_ATTRIBUTES_GET_LIST_FAILURE, message: e.message })
    // This is temporary as BE is returning 404 error
    yield put({ type: CATEGORIES_ATTRIBUTES_GET_LIST_SUCCESS, data: { content: attributesResponse } })
  }
  yield put({ type: CATEGORIES_LOADING, isLoading: false })
}

function * onGetCategoryDropdownData (action) {
  yield put({ type: CATEGORIES_LOADING, isLoading: true })
  try {
    const query = {
      q: action.payload?.q || null,
      page: action.payload?.page || 1,
      limit: action.payload?.limit || 2000,
      marketplace_shop_id: action.payload?.marketplace_shop_id || null,
      parentCategoryId: action.payload?.parentCategoryId || '0',
      marketplace: action.payload?.marketplace || null,
      has_childred: action.payload?.has_childred || null
    }
    const response = yield call(getApi, API_GET_CATEGORIES, query)
    yield put({
      type: CATEGORIES_DROPDOWN_GET_LIST_SUCCESS,
      data: {
        response: response?.data?.data || {},
        parentCategoryId: query.parentCategoryId
      }
    })
  } catch (e) {
    yield put({ type: CATEGORIES_DROPDOWN_GET_LIST_FAILURE, message: e.message })
    // This is temporary as BE is returning 404 error
    yield put({ type: CATEGORIES_DROPDOWN_GET_LIST_SUCCESS, data: {} })
  }
}

function * categoriesSaga () {
  yield takeLatest(CATEGORIES_GET_LIST, onGetCategoryList)
  yield takeLatest(CATEGORIES_BRANDS_GET_LIST, onGetCategoryBrandsList)
  yield takeLatest(CATEGORIES_ATTRIBUTES_GET_LIST, onGetCategoryAttributesList)
  yield takeLatest(CATEGORIES_DROPDOWN_GET_LIST, onGetCategoryDropdownData)
}

export default categoriesSaga
