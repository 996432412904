import React, { useEffect, useState } from 'react'
import { Grid, Paper } from '@mui/material'
import isEmpty from 'lodash/isEmpty'

import { Main, BoxM } from './style'
import DataTable from './DataTable'
import TitleRow from './TitleRow'
import FilterRow from './FilterRow'
import NoPromoComponent from './NoPromo'
import LoadingSpinner from '../LoadingSpinner'

const BrandsMspCompare = (props) => {
  const {
    mspIsloading,
    mspList,
    totalMsp,
    promoTypeList,
    skuList,
    onGetBrandMSPList,
    selectedBrand,
    brandIsLoading,
    features,
    download,
    mspExportIsloading,
    updateMspConfig,
    configIsLoading,
    configUpdateSuccess,
    resetConfigUpdateSuccess,
    mspDetailsFiltersReset
  } = props
  const initialStates = {
    selectedSKU: [],
    selectedPromoType: [],
    searchText: '',
    sorting: {},
    page: 0,
    dateRange: { startDate: null, endDate: null }
  }

  const [selectedSKU, setSelectedSKU] = useState(initialStates.selectedSKU)
  const [searchText, setSearchText] = useState(initialStates.searchText)
  const [sorting, setSorting] = useState(initialStates.sorting)
  const [page, setPage] = useState(initialStates.page)

  const mspUpdatehandler = async (sku, msp) => {
  updateMspConfig({
      brandId: selectedBrand.id,
      sku: sku,
      msp: msp
    })
  }

  const resetStates = () => {
    setSelectedSKU(initialStates.selectedSKU)
    setSearchText(initialStates.searchText)
    setSorting(initialStates.sorting)
    setPage(initialStates.page)
  }

  useEffect(()=>{
    mspDetailsFiltersReset();
  },[]);

  useEffect(()=>{
    if(configUpdateSuccess === true){
      onGetBrandMSPList({
        offset: page * 20,
        limit: 20,
        brandId: selectedBrand.id,
        sku: selectedSKU.map((item) => item.sku),
        skuOrNameSearch: searchText,
        sort: sorting.sort,
        sortOrder: sorting.sortOrder
      })
      resetConfigUpdateSuccess();
    }
  },[configUpdateSuccess])
  useEffect(() => {
    if (!selectedBrand) {
      return
    }
    onGetBrandMSPList({
      offset: 0,
      limit: 1000,
      brandId: selectedBrand.id
    })

    resetStates()
  }, [onGetBrandMSPList, selectedBrand])


  useEffect(() => {
    if (!selectedBrand) {
      return
    }
    onGetBrandMSPList({
      offset: page * 20,
      limit: 20,
      brandId: selectedBrand.id,
      sku: selectedSKU.map((item) => item.sku),
      skuOrNameSearch: searchText,
      sort: sorting.sort,
      sortOrder: sorting.sortOrder
    })
  }, [selectedSKU, searchText, sorting, page, onGetBrandMSPList])

  const isFilterApplied = () => {
    if (
      initialStates.searchText !== searchText ||
      initialStates.page !== page) {
      return true
    }
    return false
  }

  const noPromo = () => {
    if (!mspIsloading && isEmpty(mspList) && !isFilterApplied()) {
      return true
    }
    return false
  }

  const downloadHandler = () => {
    download({
      brandId: selectedBrand.id
    });
  }

  return (
    <>
      {(brandIsLoading || mspExportIsloading) && <LoadingSpinner />}
      {(!brandIsLoading && !mspExportIsloading)&&
        (noPromo()
          ? <NoPromoComponent promoTypeList={promoTypeList}/>
          : <Main>
            <Paper>
              <BoxM>
                <TitleRow totalPromo={totalMsp} />
                <FilterRow
                  skuList={skuList}
                  selectedSKU={selectedSKU}
                  handleSKUSelect={setSelectedSKU}
                  searchtext={searchText}
                  handleSearchTextChange={setSearchText}
                  downloadHandler={downloadHandler}
                />
                <Grid>
                  <DataTable
                    promoList={mspList}
                    loading={mspIsloading}
                    sortingHandler={setSorting}
                    pageChangehandler={setPage}
                    page={page}
                    totalPromo={totalMsp}
                    features={features}
                    mspUpdatehandler={mspUpdatehandler}
                    configIsLoading={configIsLoading}
                    configUpdateSuccess={configUpdateSuccess}
                  />
                </Grid>
              </BoxM>
            </Paper>
          </Main>)}
    </>
  )
}

BrandsMspCompare.displayName = 'BrandsMspCompareComponent'
export default BrandsMspCompare
