import styled from 'styled-components'

export const Modal = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
`

export const FormControlParent = styled.div`
    margin-bottom: 30px;
`

export const ModalContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 1rem 1.5rem;
    width: 24rem;
    border-radius: 0.5rem;
`

export const CloseButton = styled.div`
    padding: 5px 10px;
    float: right;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #E4E4E5;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
    border-radius: 4px;
    margin-bottom: 20px;

&:hover {
    background-color: #f7f7f7;
}
`
export const CancelOrderHeading = styled.span`
    font-size: 25px;
    font-weight: 500;
`

export const CancelOrderParent = styled.div`
    text-align: center;
    margin-top: 30px;
`

export const CancelOrderButton = styled.button`
    background: #1D2334;
    border-radius: 6px;
    border: 0;
    padding: 11px 15px;
    color: #FFF;
    font-Size: 14px;
    font-family: Noto Sans;

    &:active{
        background: #000;
    }
`
