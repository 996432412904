import { List, Map } from 'immutable'

import {
  MARKETPLACES_LOADING,
  MARKETPLACES_GET_LIST_SUCCESS,
  MARKETPLACES_GET_LIST_FAILURE,
  MARKETPLACES_CONNECT_SHOPEE_FAILURE
} from '../actions'

const initialState = new Map({
  isLoading: false,
  error: '',
  totalRecords: 0,
  content: List([])
})

export const marketplaces = (state = initialState, action) => {
  const {
    data,
    isLoading,
    message,
    type
  } = action

  switch (type) {
    case MARKETPLACES_LOADING:
      return state.set('isLoading', isLoading)
    case MARKETPLACES_GET_LIST_SUCCESS:
      return state.merge({
        totalRecords: data?.totalRecords || 0,
        content: List(data?.content || [])
      })
    case MARKETPLACES_GET_LIST_FAILURE:
    case MARKETPLACES_CONNECT_SHOPEE_FAILURE:
      return state.set('error', message)
    default:
      return state
  }
}
