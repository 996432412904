import PropTypes from 'prop-types'

import {
  LabelSelectorWrapper,
  Label
} from './style'

const LabalSelector = ({
  options,
  onSelect,
  selected,
  setSelected
  // selectTab
}) => {
  // const [selected, setSelected] = useState(options[0].value)

  const handleSelect = (option) => {
    // setSelected (option.value)
    onSelect(option.value)
  }

  return (
    <LabelSelectorWrapper className='tabWrapper'>
      {options.map((option, index) => {
        return (
          <Label
            key={index}
            onClick={() => handleSelect(option)}
            selected={selected === option.value}
            id={option?.value}
            className='tab'
          >
            {option.label}
          </Label>
        )
      })}
    </LabelSelectorWrapper>
  )
}

PropTypes.LabalSelector = {
  options: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func
}

LabalSelector.displayName = 'LabalSelector'

export default LabalSelector
