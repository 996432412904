import axios from 'axios'

import { AUTH_BASE_URL, API_BASE_URL, GRAPHQL_BASE_URL, BRAND_MANAGEMENT_BASE_URL, BRAND_MANAGEMENT_PROMO_SERVICE_BASE_URL, SUBSCRIPTION_BASE_URL } from '../config'
import { AUTH_LOGOUT_USER, AUTH_REFRESH_TOKEN } from '../actions'
import { store } from '../store'
import { getAuthToken } from './app'

export {
  getApi,
  postApi,
  putApi,
  patchApi,
  deleteApi,
  BrandManagementPostApi,
  BrandManagementGetApi,
  PatchPromoApi
}

const refreshTokenInstance = axios.create({
  baseURL: AUTH_BASE_URL
})

const axiosInstance = axios.create({
  baseURL: AUTH_BASE_URL
})

const axiosInstanceApiBase = axios.create({
  baseURL: API_BASE_URL
})

const axiosInstanceGraphql = axios.create({
  baseURL: GRAPHQL_BASE_URL
})

const axiosInstanceBrandManagementPromo = axios.create({
  baseURL: BRAND_MANAGEMENT_PROMO_SERVICE_BASE_URL
})

const axiosInstanceBrandManagement = axios.create({
  baseURL: BRAND_MANAGEMENT_BASE_URL
})

const axiosInstanceSubscription = axios.create({
  baseURL: SUBSCRIPTION_BASE_URL
})

refreshTokenInstance.interceptors.response.use(response => {
  return response
}, error => {
  const token = getAuthToken()
  if (token && (error.response?.status === 401)) {
    store.dispatch({ type: AUTH_LOGOUT_USER })
  }
  throw error
})

axiosInstance.interceptors.response.use(response => {
  return response
}, error => {
  const token = getAuthToken()
  if (token && (error.response?.status === 401)) {
    store.dispatch({ type: AUTH_REFRESH_TOKEN })
    return
  }
  throw error
})

axiosInstanceApiBase.interceptors.response.use(response => {
  return response
}, error => {
  const token = getAuthToken()
  if (token && (error.response?.status === 401)) {
    store.dispatch({ type: AUTH_REFRESH_TOKEN })
    return
  }
  throw error
})

axiosInstanceGraphql.interceptors.response.use(response => {
  return response
}, error => {
  const token = getAuthToken()
  if (token && (error.response?.status === 401)) {
    store.dispatch({ type: AUTH_REFRESH_TOKEN })
    return
  }
  throw error
})

axiosInstanceBrandManagement.interceptors.response.use(response => {
  return response
}, error => {
  const token = getAuthToken()
  if (token && (error.response?.status === 401) && error.response.data?.error !== 'Subscription not available') {
    store.dispatch({ type: AUTH_LOGOUT_USER })
  }
  throw error
})

axiosInstanceBrandManagementPromo.interceptors.response.use(response => {
  return response
}, error => {
  const token = getAuthToken()
  if (token && (error.response?.status === 401)) {
    store.dispatch({ type: AUTH_LOGOUT_USER })
  }
  throw error
})

axiosInstanceSubscription.interceptors.response.use(response => {
  return response
}, error => {
  const token = getAuthToken()
  if (token && (error.response?.status === 401)) {
    store.dispatch({ type: AUTH_LOGOUT_USER })
  }
  throw error
})

async function getApi (url, queryParams = { }, isGraphQl, idToken) {
  const token = idToken || getAuthToken()
  const headers = token ? { Authorization: `Bearer ${token}` } : {}
  const instance = token ? (isGraphQl ? axiosInstanceGraphql : axiosInstanceApiBase) : axiosInstance
  return instance.get(url, { params: queryParams, headers })
}

async function postApi (url, data = {}, options = {}, isGraphQl) {
  const { token = getAuthToken(), isLegacyApiHost = false, retried = false } = options
  const headers = token ? { Authorization: `Bearer ${token}` } : {}
  const instance = retried ? refreshTokenInstance : (token && !isLegacyApiHost) ? (isGraphQl ? axiosInstanceGraphql : axiosInstanceApiBase) : axiosInstance
  return instance.post(url, data, { headers })
}

async function putApi (url, data = {}, options = {}, isGraphQl) {
  const { token = getAuthToken(), isLegacyApiHost = false, customHeaders = {} } = options
  const headers = token ? { Authorization: `Bearer ${token}`, ...customHeaders } : customHeaders
  const instance = (token && !isLegacyApiHost) ? (isGraphQl ? axiosInstanceGraphql : axiosInstanceApiBase) : axiosInstance
  return instance.put(url, data, { headers })
}
async function patchApi (url, data = {}, options = {}, isGraphQl) {
  const { token = getAuthToken(), isLegacyApiHost = false, customHeaders = {} } = options
  const headers = token ? { Authorization: `Bearer ${token}`, ...customHeaders } : customHeaders
  const instance = (token && !isLegacyApiHost) ? (isGraphQl ? axiosInstanceGraphql : axiosInstanceApiBase) : axiosInstance
  return instance.patch(url, data, { headers })
}

async function deleteApi (url, data = {}, options = {}, isGraphQl) {
  const { token = getAuthToken(), isLegacyApiHost = false } = options
  const headers = token ? { Authorization: `Bearer ${token}` } : {}
  const instance = (token && !isLegacyApiHost) ? (isGraphQl ? axiosInstanceGraphql : axiosInstanceApiBase) : axiosInstance
  return instance.delete(url, { headers, data })
}

async function BrandManagementPostApi (url, data = {}, options = {}, isPromoServiceInstance = false) {
  const { token = getAuthToken() } = options
  const headers = token ? { Authorization: `Bearer ${token}` } : {}
  const instance = isPromoServiceInstance ? axiosInstanceBrandManagementPromo : axiosInstanceBrandManagement
  return instance.post(url, data, { headers })
}

async function BrandManagementGetApi (url, queryParams = {}, isSubscriptionService = false, authToken = '') {
  const token = authToken || getAuthToken()
  const headers = token ? { Authorization: `Bearer ${token}` } : {}
  const instance = isSubscriptionService ? axiosInstanceSubscription : axiosInstanceBrandManagement
  return instance.get(url, { params: queryParams, headers })
}

async function PatchPromoApi (url, data = {}, options = {}, isPromoServiceInstance) {
  const { token = getAuthToken() } = options
  const headers = token ? { Authorization: `Bearer ${token}` } : {}
  const instance = isPromoServiceInstance ? axiosInstanceBrandManagementPromo : axiosInstanceBrandManagement
  return instance.patch(url, data, { headers })
}
