import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { SelectAutoComplete, SelectField } from "./styles";

const SearchAutocomplete = (props) => {
    const { listData, searchId, textLabel, helper, handleChange, noOption, size, isFilterClear } = props;
    const [search, setSearch] = useState('');
    useEffect(()=>{ if(isFilterClear){setSearch('')} },[isFilterClear]);
    return (
        <>
        <Tooltip disableFocusListener   title={search !=='' && search !==null ? search : ''}>
        <SelectAutoComplete
            disablePortal
            options={listData}
            sx={{ minWidth: 120, background: '#FFF' }}
            value={search}
            noOptionsText={noOption}
            size={size}
            onChange={(e, item) => {
                if(item){
                    const {label, value } = item; 
                    setSearch(label);
                    handleChange(value);
                }else{
                    setSearch(item);
                    handleChange(item);
                }                 
            }}
            renderInput={(params) => <SelectField {...params}
                id={searchId}
                helperText={helper}
                size={size}
                placeholder={textLabel}
            />}
        />
        </Tooltip>
        </>
    );
};

export default SearchAutocomplete;