import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { useDispatch } from 'react-redux'
import PreLoginLayout from '../PreLoginLayout'
import HomepageComponent from '../../components/Homepage'

import { homepageContent } from './__mock__'
import { getAuthToken } from '../../utilities/app'
import { ROUTE_CONNECTIONS } from '../../constants'
import { AUTH_LOGIN_USER_SUCCESS } from '../../actions'

const Homepage = () => {
  const navigate = useNavigate()
  const { token: idToken, route = ROUTE_CONNECTIONS, refreshToken, hideHeader, tab = '' } = qs.parse(useLocation()?.search)
  const token = getAuthToken().length > 5
  const dispatch = useDispatch()
  useEffect(() => {
    if (idToken) {
      const data = {
        idToken: idToken,
        refreshToken: refreshToken
      }
      dispatch({ type: AUTH_LOGIN_USER_SUCCESS, data: data })
      if (hideHeader) {
        navigate(`${route}?token=${idToken}&hideHeader=true&refreshToken=${refreshToken}&tab=${tab}`)
      } else {
        navigate(`${route}?token=${idToken}&refreshToken=${refreshToken}&tab=${tab}`)
      }
    } else if (token) {
      navigate(ROUTE_CONNECTIONS)
    }
  })
  return (
    <PreLoginLayout>
      <HomepageComponent data={homepageContent} />
    </PreLoginLayout>
  )
}

Homepage.displayName = 'Homepage'
export default Homepage
