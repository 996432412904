import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Main,
    SelectGrid,
    ModalTitle,
    SvgContainer
} from './styles';
import { COLOR_CONSTANT } from "../../constants/webConstants";
import { Grid, IconButton, Box, Modal, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import confirmPromo from '../../assets/images/brandDashboard/confirmPromo.svg';

const { white, confirm } = COLOR_CONSTANT;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 8,
    outline: 'none',
    borderRadius: '10px',
    height: 'auto',
    padding: '10px'
};

const confirmButton = {
    height: '42px',
    width: '30%',
    color: '#fff',
    background: confirm,
    marginRight: '16px',
    boxShadow: '0px 2px 8px rgba(129, 82, 226, 0.436134)',
    ml: 1,
    "&.MuiButtonBase-root:hover": {
        bgcolor: confirm
    }
};

const cancelButton = {
    height: '42px',
    width: '30%',
    color: confirm,
    background: white,
    marginRight: '16px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)'
};

const ConfirmRequest = (props) => {
    const { onClose, title, subText, onConfirm, onCancel, isOpen } = props;
    const { t } = useTranslation()

    return (
        <Modal onClose={onClose} open={isOpen}>
            <Main sx={style}>
                <SelectGrid>
                    <Grid item xs>
                        <Grid container={true} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton disableRipple={true} onClick={onClose} color="primary" aria-label="upload picture" component="label">
                                <CloseIcon sx={{ color: '#161E54CC' }} />
                            </IconButton>
                        </Grid>
                        <Grid container={true} style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <SvgContainer src={confirmPromo} alt='confirmPromoImg' />
                        </Grid>

                    </Grid>
                    <SelectGrid>
                        <ModalTitle isTitle={true} type='center' size='18px' weight='700'>{title}</ModalTitle>
                        <ModalTitle type='center' size='14px' left='36px' right='36px' weight='400'>{subText}</ModalTitle>
                    </SelectGrid>
                    <Box
                        component="div"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '20px',
                            marginTop: '10px'
                        }}
                    >
                        <Button color="primary" sx={cancelButton} onClick={onCancel} variant='text'>{t('brandPromo.cancel')}</Button>
                        <Button color="primary" sx={confirmButton} onClick={onConfirm} variant='text'>{t('brandPromo.confirm')}</Button>
                    </Box>
                </SelectGrid>
            </Main>
        </Modal>
    )
};


export default ConfirmRequest;