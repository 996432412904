import PropTypes from 'prop-types'

import {
  TableWrapper,
  TableHeader,
  TableHeaderRow,
  TableHeaderData,
  TableBody,
  TableBodyRow,
  TableBodyData,
  StatusDot,
  OrderIDDate,
  StatusWrapper,
  StatusValue,
  CheckboxComponent
} from './style'


const checkBoxLabel = { inputProps: { 'aria-label': 'Checkbox demo' } };

const renderHeader = (headers) => {
  return (
    <TableHeaderRow>
      {headers.map((header, index) =>
        <TableHeaderData key={index}>
          {header.title}
        </TableHeaderData>
      )}
    </TableHeaderRow>
  )
}

const renderBody = (headers, data, toggleModal, setRowSelect, setStoreID, orderStatusLabels, onUpdatePartialFulfillment) => {
  const isDate = date => (new Date(date) !== 'Invalid Date') && !isNaN(new Date(date))

  const onClickCheckBox = (row) => {
   const payload = {
    'orderId': row.id
   }
   onUpdatePartialFulfillment(payload)
  }
  const renderCell = (index, rowIndex, value, header, row, orderStatusLabels) => {
    const { PROCESSED } = orderStatusLabels;
    const isDateHeader = header === 'orderCreationDateTime'
    // index is zero return the row number
    // if (index === 0) return rowIndex + 1
    let styleProps = {}
    if (header === 'marketPlaceOrderId' || isDateHeader) styleProps = { fontSize: '12px' }

    const getDate = (value) => {
      const date = new Date(value)

      const append0 = (dateValue) => {
        if (dateValue < 10) return `0${dateValue}`
        return dateValue
      }

      return `${append0(date.getDate())}/${append0(date.getMonth() + 1)}/${date.getFullYear()} ${append0(date.getHours())}:${append0(date.getMinutes())}`
    }
    // convert UTC date into date and year
    if (isDateHeader && isDate(value) && value !== 0) {
      return <div style={styleProps}>{getDate(value)}</div>
    }

    if (header === 'marketPlaceOrderId') {
      return (
        <>
          <div style={styleProps}>{value}</div>
          <OrderIDDate>{getDate(row.orderCreationDateTime)}</OrderIDDate>
        </>
      )
    }

    // check if value is a custom react functional component render component
    if (header === 'status') {
      return (
        <StatusWrapper>
          <StatusDot value={value} />
          <StatusValue>
            {value}
          </StatusValue>
        </StatusWrapper>
      )
    }
    if (header === 'partialfulfillment' && row?.status === PROCESSED) {
      return (
        <CheckboxComponent 
        {...checkBoxLabel} 
        checked={row.fulfilPartial}
        onChange={() => onClickCheckBox(row)}
        />
      )
    }
    // if array, show length
    if (value?.length && typeof value === 'object') return value.length

    // or text
    return value
  }

  
  const tableDataClick = (header, row, orderStatusLabels) => {
   const { PROCESSED } = orderStatusLabels;
   if (header === 'partialfulfillment' && row?.status === PROCESSED) return;
   else toggleModal();
  }
  return (
    <>
      {
        data.map(
          (row, rowIndex) => {
            return (
              <TableBodyRow key={rowIndex} onClick={() => { setRowSelect(row?.id); setStoreID(row?.storeName) }}>
                {
                  headers.map(
                    (header, itemIndex) => (
                      <TableBodyData 
                       onClick={() => tableDataClick(header.key, row, orderStatusLabels)} 
                       key={`${rowIndex}${itemIndex}`}>
                        {
                          renderCell(itemIndex, rowIndex, row[header.key], header.key, row, orderStatusLabels)
                        }
                      </TableBodyData>
                    )
                  )
                }
              </TableBodyRow>
            )
          }
        )
      }
    </>
  )
}

const Table = ({ headers, data, toggleModal, setRowSelect, setStoreID, orderStatusLabels, onUpdatePartialFulfillment }) => {

  return (
    <TableWrapper>
      <TableHeader>
        {renderHeader(headers)}
      </TableHeader>
      <TableBody>
        {renderBody(headers, data, toggleModal, setRowSelect, setStoreID, orderStatusLabels, onUpdatePartialFulfillment)}
      </TableBody>
    </TableWrapper>
  )
}

Table.propTypes = {
  header: PropTypes.array,
  data: PropTypes.array
}

Table.displayName = 'TableComponent'
export default Table
