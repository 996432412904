import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import {
  StoreCardWrapper,
  StoreImage,
  StoreConnectionImage,
  StoreImageWrapper,
  StoreInfoWrapper,
  StoreStatusWrapper,
  StoreName,
  StoreId,
  StoreStatus,
  StoreCardTop,
  StoreCardBottom,
  ProductInfo,
  ProductInfoTitle,
  ProductCount,
  OrderInfo,
  Time,
  StoreDetails,
  OrderInfoTitle,
  ReconnectStore,
  ConnectionStatus,
  OrderCount,
  ExpiredTitle,
  WraperDiv,
  WraperContent,
  WraperHeader,
  WraperHeaderValue,
  ImgIconOrderProduct
} from './style'

import storePlaceholderImage from '../../assets/images/placeholder-store.png'
import connectionProviderImage from '../../assets/images/shoppe-logo.png'
import priceTagImage from '../../assets/images/price-tag.svg'
import productStoreImg from '../../assets/images/icons/icon-product-store.svg'
import orderStoreImg from '../../assets/images/icons/icon-order-store.svg'
import RenameStore from '../RenameStore'
import EventEmitter, { Events } from '../../utilities/eventEmitter'

const StoreCard = props => {
  const {
    shop_name: shopName = '',
    // Todo: We will uncomment this later
    // shop_logo: shopLogo = storePlaceholderImage,
    is_connected: isConnected,
    id: storeId = '',
    marketplace_shop_id: shopID,
    total_products: TotalProduct,
    total_order: TotalOrder,
    marketplace_shop_id: marketPlaceShopId,
    onSyncProducts,
    onDisconnectStore,
    products,
    orders,
    onSyncOrders,
    lastSync,
    reconnectStoreClick
  } = props
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [renameStoreModal, setRenameStoreModal] = useState(false)
  const CloseModalHandle = () => setRenameStoreModal(false)
  const RenameStoreHandle = () => {
    EventEmitter.emit(Events.RENAME_STORE, { page: 'StoreCard' })
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const processStoreName = (name = '') => {
    // In our demo it was found that store name was a continuous string or 30+ characters
    // This is not a real worl scenario
    // We will process all the words in store name and using ony first 25 characters in that word
    const storeName = name.trim()
    const storeNameArray = storeName.split(' ').filter(Boolean)
    const storeNameArrayTrimmed = storeNameArray.map(str => str.substring(0, 25))
    const processedName = storeNameArrayTrimmed.join(' ')
    return processedName
  }

  const onSyncProductsClick = () => {
    onSyncProducts({ storeId, marketPlaceShopId, shopName, isConnected })
    handleClose()
  }

  const onSyncOrdersClick = () => {
    onSyncOrders({ storeId, marketPlaceShopId, shopName, isConnected })
    handleClose()
  }
  const renameStoreClick = () => {
    setRenameStoreModal(true)
  }
  const onDisconnectStoreClick = () => {
    onDisconnectStore({ storeId, marketPlaceShopId, shopName, isConnected })
    handleClose()
  }

  const renderMoreOptionsModal = () => (
    <>
      <IconButton
        aria-haspopup='true'
        onClick={handleClick}
        size='small'
        sx={{ margin: '-5px -5px 0 0' }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={onSyncProductsClick}>{t('storeCard.syncProducts')}</MenuItem>
        <MenuItem onClick={onSyncOrdersClick}>{t('storeCard.syncOrders')}</MenuItem>
        <MenuItem onClick={renameStoreClick}>{t('storeCard.renameStore')}</MenuItem>
        <MenuItem onClick={onDisconnectStoreClick}>{t('storeCard.disconnectStore')}</MenuItem>
      </Menu>
    </>
  )

  return (
    <StoreCardWrapper>
      <StoreImageWrapper>
        <StoreImage src={props?.shop_logo || storePlaceholderImage} alt='Logo' />
        <StoreConnectionImage src={connectionProviderImage} />
      </StoreImageWrapper>
      <StoreDetails>
        <StoreCardTop>
          <StoreInfoWrapper>
            <StoreName>
              {processStoreName(shopName)}
            </StoreName>
            <StoreId>
              {shopID}
            </StoreId>
            {
              (TotalProduct || TotalOrder || TotalOrder === 0 || TotalProduct === 0) &&
                <WraperDiv>
                  <div>
                    <WraperHeader>
                      {t('connections.Product')}
                    </WraperHeader>
                    <WraperContent>
                      <ImgIconOrderProduct src={productStoreImg} alt='product' />
                      <WraperHeaderValue>
                        {TotalProduct > 0 ? TotalProduct : 0} ({TotalProduct} SKUs)
                      </WraperHeaderValue>
                    </WraperContent>
                  </div>
                  <div>
                    <WraperHeader>
                      {t('connections.Order')}
                    </WraperHeader>
                    <WraperContent>
                      <ImgIconOrderProduct src={orderStoreImg} alt='order' />
                      <WraperHeaderValue>
                        {TotalOrder > 0 ? TotalOrder : 0}
                      </WraperHeaderValue>
                    </WraperContent>
                  </div>
                </WraperDiv>
            }
          </StoreInfoWrapper>
          <StoreStatusWrapper>
            {!isConnected && <StoreStatus type='dicconnected'>{t('storeCard.disconnected')}</StoreStatus>}
            {isConnected && <StoreStatus>{t('storeCard.connected')}</StoreStatus>}
            {isConnected && renderMoreOptionsModal()}
          </StoreStatusWrapper>
        </StoreCardTop>
        <StoreCardBottom>
          {products &&
            <ProductInfo>
              <ProductInfoTitle>{t('menu.products')}</ProductInfoTitle>
              <ProductCount>
                <img src={priceTagImage} alt='Products' />
                <div>{products}</div>
              </ProductCount>
            </ProductInfo>}
          {orders &&
            <OrderInfo>
              <OrderInfoTitle>{t('menu.orders')}</OrderInfoTitle>
              <OrderCount>
                <img src={storePlaceholderImage} alt='Orders' />
                <div>{orders}</div>
              </OrderCount>
            </OrderInfo>}
          {lastSync && <Time>{t('storeCard.lastSync')}, {lastSync || '12:00 AM'}</Time>}
        </StoreCardBottom>
      </StoreDetails>
      <RenameStore onClick={RenameStoreHandle} StoreObject={props} open={renameStoreModal} handleClose={CloseModalHandle} />
      {!isConnected &&
        <ConnectionStatus>
          <ExpiredTitle>{t('storeCard.shopeeCredentialsExpired')}.</ExpiredTitle>
          <ReconnectStore size='small' color='primary' variant='contained' onClick={() => reconnectStoreClick(props)}>{t('storeCard.reconnect')} <ArrowForwardIosIcon size='small' /></ReconnectStore>
        </ConnectionStatus>}
    </StoreCardWrapper>
  )
}

StoreCard.propTypes = {
  description: PropTypes.string,
  id: PropTypes.number,
  marketplace_shop_id: PropTypes.number,
  shop_logo: PropTypes.string,
  shop_name: PropTypes.string,
  onSyncProducts: PropTypes.func,
  onSyncOrders: PropTypes.func,
  is_connected: PropTypes.bool,
  products: PropTypes.string,
  orders: PropTypes.string,
  lastSync: PropTypes.string
}
StoreCard.displayName = 'StoreCard'
export default StoreCard
