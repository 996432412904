class EventEmitter {
  static subscribers = {}

  static isAlreadySubscribed (event, callback) {
    const subscribers = this.subscribers[event] || []
    return subscribers.some((s) => s.name === callback.name)
  }

  static emit (event, data) {
    const subscribers = this.subscribers[event]
    if (!subscribers) return
    subscribers.forEach((subscriber) => subscriber(data))
  }

  static on (event, callback) {
    if (this.isAlreadySubscribed(event, callback)) return
    this.subscribers[event] = this.subscribers[event] || []
    this.subscribers[event].push(callback)
  }
}

export const Events = {
  SAMPLE_EVENT: 'SAMPLE_EVENT',
  SAAS_LOGIN: 'saas_login',
  SAAS_SIGNUP: 'saas_signup',
  ENTER_OTP: 'saas_enter_otp',
  CONNECT_STORE: 'saas_connect_store',
  CREATE_NEW_STORE_OK: 'saas_create_new_store_ok',
  ADD_PRODUCT: 'saas_add_product',
  SELECT_STORE: 'saas_select_store',
  REGISTER_NOW: 'saas_register_now',
  CREATE_NEW_STORE: 'saas_create_new_store',
  CREATE_NEW_STORE_INSTANT: 'saas_create_new_store_instant',
  CONTACT_NOW: 'saas_contact_now',
  VIDEO_PLAY: 'saas_video_play',
  RENAME_STORE: 'rename_store',
  TABS_DASHBOARD: 'saas_tabs_dashboard',
  TABS_CONNECTIONS: 'saas_tabs_connections',
  TABS_ANALYTICS: 'tabs_analytics',
  TABS_SUPERSTORE: 'tabs_superstore',
  TABS_PRODUCT: 'saas_tabs_product',
  TABS_ORDERS: 'saas_tabs_orders',
  TABS_CUSTOMERS: 'saas_tabs_customers',
  TABS_SETTINGS: 'saas_tabs_settings',
  TABS_MYSTORE: 'saas_tabs_mystore',
  PRODUCT_ADD_FROM_PARTNER: 'saas_product_add_from_Partner',
  PRODUCT_LIVE_LIST: 'saas_product_live_list',
  PRODUCT_ALL_LIST: 'saas_product_all_list',
  PRODUCT_SYNC_RAENA: 'rdash_product_invsync_raena',
  PRODUCT_SYNC_SELF: 'rdash_product_invsync_self',
  PRODUCT_DRAFT_LIST: 'saaas_product_draft_list',
  PRODUCT_VIEWDETAIL: 'saas_product_viewdetail',
  PRODUCT_EDITSTOCK: 'saas_product_EditStock',
  PRODUCT_EDIT_LISTING: 'saas_product_edit_listing',
  PRODUCT_ADD_TO_STORES: 'saas_product_addtostores',
  PRODUCT_SEARCH_TERM: 'saas_product_searchterm',
  VIEW_ORDER_DETAIL: 'saas_order_viewdetail',
  ORDER_CLICK_CTA: 'saas_order_clickCTA',
  ORDER_PENDING_LIST: 'saas_order_pending_list',
  ORDER_CANCELLED_LIST: 'saas_order_cancelled_list',
  ORDERS_COMPLETED_LIST: 'saas_order_completed_list',
  ORDER_SEARCH_TERM: 'saas_order_searchterm',
  DSF_ABOUTUS_SAVE: 'saas_dsf_aboutus_save',
  DSF_ABOUTUS_COPY: 'saas_dsf_aboutus_copy',
  DSF_LINKS: 'saas_dsf_links',
  DSF_ABOUTUS: 'saas_dsf_aboutus',
  DSF_APPEARANCE: 'saas_dsf_appearance',
  DSF_LINKS_LINK_SAVE: 'saas_dsf_links_linksave',
  DSF_LINKS_IMAGE: 'saas_dsf_links_image',
  DSF_LINKS_REORDER: 'saas_dsf_links_reorder',
  DSF_LINKS_ABOUTUS_IMAGEUPLOAD: 'saas_dsf_links_aboutus_imageupload',
  DSFADMIN_LINKADDED: 'dsfadmin_linkadded',
  DSFADMIN_LINKEDITED: 'dsfadmin_linkedited',
  DSFADMIN_STORELINK_CLICK: 'dsfadmin_storelink_click',
  DSFADMIN_STORELINK_COPY: 'dsfadmin_storelink_copy',
  DSFADMIN_SAVE: 'dsfadmin_save',
  DSFADMIN_UPLOADIMAGE: 'dsfadmin_uploadimage'
}

export default EventEmitter
