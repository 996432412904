import { List, Map } from 'immutable'

import {
  CATEGORIES_GET_LIST,
  RESET_PRODUCT_CREATE_FORM,
  PRODUCTS_LOADING,
  PRODUCTS_GET_LIST_SUCCESS,
  PRODUCTS_GET_LIST_FAILURE,
  PRODUCTS_CREATE_LOADING,
  PRODUCTS_CREATE_POST_DATA_FAILURE,
  PRODUCTS_CREATE_POST_DATA_SUCCESS,
  PRODUCTS_EDIT_LOADING,
  PRODUCTS_EDIT_GET_DATA_FAILURE,
  PRODUCTS_EDIT_GET_DATA_SUCCESS,
  PRODUCTS_UPDATE_LOADING,
  PRODUCTS_UPDATE_SET_DATA_FAILURE,
  PRODUCTS_UPDATE_SET_DATA_SUCCESS,
  PRODUCTS_DELETE_LOADING,
  PRODUCTS_DELETE_DATA_FAILURE,
  PRODUCTS_DELETE_DATA_SUCCESS,
  PRODUCTS_ALL_RECORDS,
  PRODUCTS_ALL_RECORDS_LOADING,
  PRODUCTS_EDIT_GET_DATA_CLEAR,
  PRODUCTS_UPDATE_SET_DATA_CLEAR
} from '../actions'

const initialState = new Map({
  isLoading: false,
  error: '',
  totalRecords: 0,
  content: List([]),
  productCreateIsLoading: false,
  productCreateData: {},
  productEditIsLoading: false,
  productEditData: {},
  productUpdateIsLoading: false,
  productUpdateData: {},
  productDeleteIsLoading: false,
  productDeleteData: {},
  productAllRecords: 0,
  productAllRecordLoading: false
})

export const products = (state = initialState, action) => {
  const {
    data,
    isLoading,
    message,
    type,
    productCreateIsLoading,
    productEditIsLoading,
    productUpdateIsLoading,
    productDeleteIsLoading,
    productAllRecordLoading
  } = action
  switch (type) {
    case CATEGORIES_GET_LIST:
    case RESET_PRODUCT_CREATE_FORM:
      return initialState
    case PRODUCTS_LOADING:
      return state.set('isLoading', isLoading)
    case PRODUCTS_GET_LIST_SUCCESS:
      return state.merge({
        totalRecords: data?.totalRecords || 0,
        content: List(data?.content || [])
      })
    case PRODUCTS_GET_LIST_FAILURE:
      return state.set('error', message)

    case PRODUCTS_CREATE_LOADING:
      return state.set('productCreateIsLoading', productCreateIsLoading)
    case PRODUCTS_CREATE_POST_DATA_SUCCESS:
      return state.merge({ productCreateData: data || {} })
    case PRODUCTS_CREATE_POST_DATA_FAILURE:
      return state.set('error', message)

    case PRODUCTS_EDIT_LOADING:
      return state.set('productEditIsLoading', productEditIsLoading)
    case PRODUCTS_EDIT_GET_DATA_SUCCESS:
      return state.merge({ productEditData: data || {} })
    case PRODUCTS_EDIT_GET_DATA_CLEAR:
      return state.set('productEditData', {})
    case PRODUCTS_EDIT_GET_DATA_FAILURE:
      return state.set('error', message)

    case PRODUCTS_UPDATE_LOADING:
      return state.set('productUpdateIsLoading', productUpdateIsLoading)
    case PRODUCTS_UPDATE_SET_DATA_SUCCESS:
      return state.merge({ productUpdateData: data || {} })
    case PRODUCTS_UPDATE_SET_DATA_CLEAR:
      return state.set('productUpdateData', {})
    case PRODUCTS_UPDATE_SET_DATA_FAILURE:
      return state.set('error', message)

    case PRODUCTS_DELETE_LOADING:
      return state.set('productDeleteIsLoading', productDeleteIsLoading)
    case PRODUCTS_DELETE_DATA_SUCCESS:
      return state.merge({ productDeleteData: data || {} })
    case PRODUCTS_DELETE_DATA_FAILURE:
      return state.set('error', message)

    case PRODUCTS_ALL_RECORDS:
      return state.merge({ productAllRecords: data?.totalRecords || 0 })
    case PRODUCTS_ALL_RECORDS_LOADING:
      return state.set('productAllRecordLoading', productAllRecordLoading)
    default:
      return state
  }
}
