import React from 'react'
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { getDescription } from "../../utilities/utils";
import { NoPromoWrapper, NoPromoIconDiv, NoPromoIconWrapper, PrimaryText, SecondaryText, NoPromoButtonsDiv, PromoTypesButton, PromoTypesButtonText } from './style'
import NoPromoIcon from '../../assets/images/no-promo-icon.svg'

const NoPromo = ({ promoTypeList, onTypeClick }) => {
  const { t } = useTranslation()

  return (
    <NoPromoWrapper>
      <NoPromoIconDiv>
        <NoPromoIconWrapper src={NoPromoIcon} alt='NoPromoIcon' />
      </NoPromoIconDiv>
      <PrimaryText>{t(`brandDashboard.noSKUTitle`)}</PrimaryText>
      <SecondaryText>{t(`brandDashboard.noSKUMsg`)}</SecondaryText>
      <NoPromoButtonsDiv style={{}}>
        {promoTypeList.map((item) => {
          return (
            <Tooltip title={getDescription(item, t)}
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'white',
                    border: '2px solid #E8EBEF',
                    color: 'black',
                    '& .MuiTooltip-arrow': {
                      color: 'white',
                      fontSize: 20
                    },
                    '& .MuiTooltip-arrow::before': {
                      border: '2px solid #E8EBEF',
                      boxSizing: 'border-box'
                    }
                  }

                }
              }}
            >
              <PromoTypesButton onClick={() => onTypeClick(item)}><PromoTypesButtonText>{item}</PromoTypesButtonText></PromoTypesButton>
            </Tooltip>
          )
        })}
      </NoPromoButtonsDiv>
    </NoPromoWrapper>
  )
}

NoPromo.displayName = 'NoPromoComponent'
export default NoPromo
