import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SummaryTitle, SummaryBox, SummaryTotalCount, MarketPlaceGrid, MarketPlaceDivWrapper, MarketplaceItem, MarketplaceItemName, MarketplaceItemCount, CircularProgressWrapper } from './style'

const SummaryRow = ({filtersData, mspDetailsFilterIsloading}) => {
    const { t } = useTranslation();
    const [total, setTotal] = useState(0);

    useEffect(()=>{
        if(filtersData && filtersData.marketplace && filtersData.marketplace.length){
            const totalCount = filtersData.marketplace.map(item => item.count).reduce((prev, next) => prev + next);
            setTotal(totalCount);
        }
    },[filtersData])

    return (
            <Paper>
                <SummaryBox>
                {mspDetailsFilterIsloading ? <CircularProgressWrapper><CircularProgress /></CircularProgressWrapper> : (<Grid container>
                    <Grid item md={3}>
                        <SummaryTitle>
                        {t('mspCompare.totalListingBelowMsp')}
                        </SummaryTitle>

                        <SummaryTotalCount>
                        {total}
                        </SummaryTotalCount>
                    </Grid>
                    <Grid item md={1}></Grid>
                    <MarketPlaceDivWrapper item md={8}>
                        <MarketPlaceGrid container>
                            {filtersData.marketplace && filtersData.marketplace.map((item)=>{
                               return <MarketplaceItem item md={3}>
                               <img src={item.logo} alt={item.marketplace}/>
                               <div>
                               <MarketplaceItemName>
                                   {item.marketplace || "--"}
                               </MarketplaceItemName>
                               <MarketplaceItemCount>
                               {item.count}
                           </MarketplaceItemCount>
                           </div>
                               </MarketplaceItem>
                        })}
                        </MarketPlaceGrid> 
                    </MarketPlaceDivWrapper>
                </Grid>)}
                </SummaryBox>
            </Paper>
    )
}

SummaryRow.displayName = 'SummaryRowComponent'
export default SummaryRow
