import { Map } from 'immutable'
import {
    GET_DASHBOARD_PROVINCE,
    GET_DASHBOARD_PROVINCE_SUCCESS,
    GET_DASHBOARD_PROVINCE_FAILURE,
    GET_DASHBOARD_MONTH,
    GET_DASHBOARD_MONTH_SUCCESS,
    GET_DASHBOARD_MONTH_FAILURE,
    GET_DASHBOARD_YEAR,
    GET_DASHBOARD_YEAR_SUCCESS,
    GET_DASHBOARD_YEAR_FAILURE,
    DAY_UNIT_SOLD,
    DAY_UNIT_SOLD_SUCCESS,
    DAY_UNIT_SOLD_FAILURE,
    TOTAL_UNIT_SOLD,
    TOTAL_UNIT_SOLD_SUCCESS,
    TOTAL_UNIT_SOLD_FAILURE,
    DAY_REVENUE,
    DAY_REVENUE_SUCCESS,
    DAY_REVENUE_FAILURE,
    MONTH_REVENUE,
    MONTH_REVENUE_SUCCESS,
    MONTH_REVENUE_FAILURE,
    MONTH_SHIPPING,
    MONTH_SHIPPING_SUCCESS,
    MONTH_SHIPPING_FAILURE
} from '../actions'

const initialState = new Map({
    isLoading: false,
    provinces: [],
    yearRange: [],
    monthRange: [],
    isAPISuccess: false,
    isAPIError: false,
    isDayUnit: false,
    isDayUnitSuccess: false,
    dayUnits: {},
    isTotalUnit: false,
    isTotalUnitSuccess: false,
    totalUnits: {},
    isDayRevenue: false,
    isDayRevenueSuccess: false,
    dayRevenue: {},
    isMonthRevenue: false,
    isMonthRevenueSuccess: false,
    monthRevenue: {},
    isMonthShipping: false,
    isMonthShippingSuccess: false,
    monthShipping: {},
    isUnitsError: false,
    errorDetails: {}
});

const transformData = (data) => {
    return data.map((item) => ({ label: item, value: item }));
}

export const dashboard = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_DASHBOARD_PROVINCE:
        case GET_DASHBOARD_MONTH:
        case GET_DASHBOARD_YEAR:
            return { ...state, isLoading: true, isAPIError: false };
        case GET_DASHBOARD_PROVINCE_SUCCESS:
            return { ...state, isLoading: false, isAPISuccess:true, provinces: transformData(data) };
        case GET_DASHBOARD_MONTH_SUCCESS:
            return { ...state, isLoading: false, monthRange: transformData(data) };
        case GET_DASHBOARD_YEAR_SUCCESS:
            return { ...state, isLoading: false, yearRange: transformData(data) };
        case GET_DASHBOARD_PROVINCE_FAILURE:
        case GET_DASHBOARD_MONTH_FAILURE:
        case GET_DASHBOARD_YEAR_FAILURE:
            return { ...state, isLoading: false, isAPIError: true, isAPISuccess: false, provinces: [], monthRange: [], yearRange: [] };
        case DAY_UNIT_SOLD:
            return { ...state, isDayUnit: true, isDayUnitSuccess: false };
        case DAY_UNIT_SOLD_SUCCESS:
            return { ...state, isDayUnit: false, isDayUnitSuccess: true, dayUnits: data };
        case TOTAL_UNIT_SOLD:
            return { ...state, isTotalUnit: true, isTotalUnitSuccess: false };
        case TOTAL_UNIT_SOLD_SUCCESS:
            return { ...state, isTotalUnit: false, isTotalUnitSuccess: true, totalUnits: data };
        case DAY_REVENUE:
            return { ...state, isDayRevenue: true, isDayRevenueSuccess: false };
        case DAY_REVENUE_SUCCESS:
            return { ...state, isDayRevenue: false, isDayRevenueSuccess: true, dayRevenue: data };
        case MONTH_REVENUE:
            return { ...state, isMonthRevenue: true, isMonthRevenueSuccess: false };
        case MONTH_REVENUE_SUCCESS:
            return { ...state, isMonthRevenue: false, isMonthRevenueSuccess: true, monthRevenue: data };
        case MONTH_SHIPPING:
            return { ...state, isMonthShipping: true, isMonthShippingSuccess: false };
        case MONTH_SHIPPING_SUCCESS:
            return { ...state, isMonthShipping: false, isMonthShippingSuccess: true, monthShipping: data };

        case DAY_UNIT_SOLD_FAILURE:
        case TOTAL_UNIT_SOLD_FAILURE:
        case DAY_REVENUE_FAILURE:
        case MONTH_REVENUE_FAILURE:
        case MONTH_SHIPPING_FAILURE:
            return {
                ...state, isMonthRevenue: false, isMonthShipping: false, isDayRevenue: false, isTotalUnit: false, isDayUnit: false,
                isMonthShippingSuccess: false, isMonthRevenueSuccess: false, isDayRevenueSuccess: false, isTotalUnitSuccess: false, isDayUnitSuccess: false,
                isUnitsError: true, errorDetails: data
            };
        default:
            return state;
    }
};

