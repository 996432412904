import React, { useEffect, useState, useRef } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Joi from 'joi'
import { serializeError } from 'serialize-error'
import { useTranslation } from 'react-i18next'
// import EventEmitter, { Events } from '../../utilities/eventEmitter'

import AuthLayout from '../AuthLayout'
import Loading from '../LoadingSpinner'
// EventEmitter.emit(Events.SAAS_LOGIN, { email })

import {
  Button,
  Form,
  Input,
  BottomLinkHref,
  BottomLinkWrapper,
  Title,
  AuthParent,
  LoginImageWrapper,
  LoginImage,
  LeftSideContainer,
  ImageWrapperText
} from '../AuthLayout/style'

import { ROUTE_LOGIN } from '../../constants'
import LoginImageLeft from '../../assets/images/login.svg'

const ResetPassword = props => {
  const {
    error,
    onPostResetPassword,
    isLoadingResetPassword,
    resetPasswordSuccess
  } = props

  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isScroll, setIsScroll] = useState(false)
  const [searchParams] = useSearchParams()

  const imageRef = useRef()
  // const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    const height = imageRef.current.offsetHeight + 20
    setIsScroll(height > document.body.offsetHeight)
  }, [imageRef])

  // useEffect(() => {
  //   onMount()
  // }, [onMount])

  useEffect(() => {
    if (error) toast.error(error)
  }, [error])

  useEffect(() => {
    if (resetPasswordSuccess === 200) {
      toast.success('Successfully reset the password')
      navigate('/login')
    }
  }, [resetPasswordSuccess, navigate])

  // useEffect(() => {
  //   // if (isLoggedIn) {
  //   //   navigate(ROUTE_CONNECTIONS)
  //   // }
  // }, [isLoggedIn, navigate])

  const renderFormTitle = () => <Title>Reset Password</Title>

  const resetPasswordFormInput = Joi.object({
    password: Joi.string().min(8),
    confirmPassword: Joi.string().min(8)
  })

  const handleFormSubmit = async e => {
    e.preventDefault()

    if (password !== confirmPassword) {
      toast.error('Passwords does not match')
      return
    }

    // EventEmitter.emit(Events.SAAS_LOGIN, { email })

    let data = {
      password,
      confirmPassword
    }

    const response = await resetPasswordFormInput.validate(data)
    const { message: errorMessage } = serializeError(response?.error || {})

    if (errorMessage) {
      return toast.error(errorMessage)
    }

    data = { ...data, token: searchParams.get('verificationToken') }

    onPostResetPassword(data)
  }

  const renderForm = () => (
    <Form onSubmit={handleFormSubmit}>
      <Input
        id='password'
        name='password'
        onChange={e => setPassword(e.target.value)}
        placeholder={t('auth.password')}
        type='password'
        value={password}
      />
      <Input
        id='confirmPassword'
        name='confirmPassword'
        onChange={e => setConfirmPassword(e.target.value)}
        placeholder={t('auth.confirmPassword')}
        type='password'
        value={confirmPassword}
      />
      <Button type='submit'>
        {isLoadingResetPassword ? <Loading /> : 'RESET PASSWORD'}
      </Button>
    </Form>
  )

  const renderBottomLink = () => (
    <BottomLinkWrapper>
      {t('auth.loginToYourAccount')}?
      <BottomLinkHref to={ROUTE_LOGIN}>
        {t('auth.signUp')}
      </BottomLinkHref>
    </BottomLinkWrapper>
  )

  return (
    <AuthParent>
      <LeftSideContainer isScroll={isScroll} ref={imageRef}>
        <LoginImageWrapper>
          <LoginImage src={LoginImageLeft} />
        </LoginImageWrapper>
        <ImageWrapperText>{t('auth.imageText')}!</ImageWrapperText>
      </LeftSideContainer>
      <AuthLayout>
        {renderFormTitle()}
        {renderForm()}
        {renderBottomLink()}
      </AuthLayout>
    </AuthParent>
  )
}

ResetPassword.displayName = 'ResetPasswordComponent'
export default ResetPassword
