import React, { useRef, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Divider, CircularProgress } from '@mui/material';
import {
  ProductWrapper,
  FormControl,
  SectionContent,
  Main,
  SelectTitle,
  CustomGrid,
  CreateButton,
  BottomGrid,
  SelectGrid,
  ChipWrapper,
  PromoBack,
  ProgressContainer,
  ChipContainer,
  CreateTitle,
  OfferGrid,
  MOQContainer,
  OfferContainer,
  OfferBox,
  TextTitle,
  DescriptionText,
  SearchGrid,
} from './styles';
import { ROUTE_BRANDS_PROMO } from '../../constants/routes';
import { COLOR_CONSTANT } from '../../constants/webConstants';
import {
  convertText, addMOQ, removeList, addMOV, loadInitial, loadValidation, getMovList, getName,
  getMOQList, checkIsValueExist, checkIsMoqValueExist, getAllMOQ, MOQ_VALUES, MOV_VALUES, checkIsRunning,
  showToast, getDescription, checkIsQtyValueExist, checkValuesForFreeSku, getEmptySku, removeErrorKeys,
} from '../../utilities/utils';
import CreateSelect from './CreateSelect';
import MuiSearch from './SearchDropDown';
import DateSelect from './PromoDate';
import Confirm from './ConfirmRequest';
import OfferType from './OfferTypeComponent';
import MovComponent from './OfferMovComponent';
import BundleComponent from './BundleComponent';
import { BUY_N_GET_X, MOQ, MOV, REGULAR } from '../../constants/brandPromo';

const { confirm } = COLOR_CONSTANT;

// Staic Type
const DISCOUNT_PER = 'PERCENTAGE_RSP';

let skuTags = [];
let updateList = [];

const CreatePromo = (props) => {
  const { isOfferLoading, isOfferLoaded, isSkuLoading, isSkuLoaded, skuDetails, offerDetails,
    getOffers, getSkuList, createPromo, isCreate, errorDetails, isError, resetData, updateBrandPromo,
    isUpdate, isUpdateSuccess, validateUpdate, isValidate, isValidateSuccess, brandDetails, isCreateSuccess,
    selectedBrand, brandsList } = props;
  const form = useRef();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const defaultSelectedPromoType = searchParams.get('selectedPromoType');
  const navigation = useNavigate();
  const OFFER_MOQ = MOQ_VALUES(t);
  const OFFER_MOV = MOV_VALUES(t);

  const [disable, setDisable] = useState(brandDetails?.viewDetails);
  const [offers, setOffers] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [isSkuUpdate, setIsSkuUpdate] = useState(false);
  const [currentBrand, setCurrentBrand] = useState(null);
  const [moqList, setMoqList] = useState([]);
  const [baseSku, setBaseSku] = useState([]);
  const [freeSku, setFreeSku] = useState([getEmptySku()]);
  const [offerItem, setOfferItem] = useState('');
  const [movList, setMovList] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [movDataList, setMovDataList] = useState([]);
  const [moqDataList, setMoqDataList] = useState([]);
  const [isTypeDisabled, setIsTypeDisabled] = useState(false);
  const [defaultType, setDefaultType] = useState(defaultSelectedPromoType);
  const [currentDate, setCurrentDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isOfferChanged, setIsOfferChanged] = useState(false);
  const [removeSku, setRemoveSku] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);

  useEffect(() => {
    resetData();
  }, []);

  useEffect(() => {
    if (isOfferLoaded) {
      setOffers(offerDetails);
    }
    if (isSkuLoaded) {
      const list = skuDetails?.map((item) => ({ label: `${item.sku} : ${item.name}`,
        value: item.sku,
        id: item.sku,
        isSelected: false }));
      setSkuList(list);
    }
  }, [isOfferLoaded, isSkuLoaded]);

  useEffect(() => {
    if (isError && errorDetails) {
      const { skus } = errorDetails;
      if (skus && skus?.length > 0) {
        skuTags?.map((item) => {
          const sku = { ...item };
          if (skus.includes(sku.id)) {
            sku.isError = true;
          } else sku.isError = false;
          return sku;
        });
        formik.setValues({ ...formik.values });
      }
      setIsSkuUpdate(false);
    }
  }, [isError]);

  const getValues = (values) => {
    const { offer, startDate, endDate: enddate, discount } = values;
    let data = {};
    switch (offer) {
      case REGULAR:
        data = {
          type: offer,
          discount,
          discountType: DISCOUNT_PER,
          startDate,
          endDate: enddate,
          promoSkus: updateList.map((item) => ({ ...item, name: getName(item.name, ':') })),
        };
        break;

      case MOQ:
        data = {
          type: offer,
          startDate,
          endDate: enddate,
          promoSkus: moqDataList,
        };
        break;

      case MOV:
        data = {
          type: offer,
          startDate,
          endDate: enddate,
          promoMov: movDataList,
        };
        break;

      case BUY_N_GET_X:
        data = {
          type: offer,
          startDate,
          endDate: enddate,
          promoBuyNGetX: getBundleDataList(),
        };
        break;

      default:
        break;
    }

    return data;
  };

  const getBundleDataList = () => ({
    baseSku: removeErrorKeys(baseSku),
    freeSku: removeErrorKeys(freeSku),
  });

  const formik = useFormik({
    initialValues: loadInitial(offerItem, defaultType),
    enableReinitialize: true,
    validationSchema: loadValidation(offerItem, t),
    onSubmit: (values) => {
      // Values will return all stored formik form values.

      if(disable){
        setDisable(false)
        return
      }
      let isEmpty = false;
      let error = '';
      const moqs = Array.from(moqDataList);
      const movs = Array.from(movDataList);

      if (values.offer === MOV || values.offer === MOQ) {
        if (!checkIsValueExist(movs, 'mov', 'discount')) {
          isEmpty = true;
          error = t('errors.blankMOQ');
        }

        if (!checkIsMoqValueExist([...moqs], 'moq', 'discount')) {
          error = t('errors.blank');
          isEmpty = true;
        }
      }

      if (values.offer === BUY_N_GET_X) {
        if (!checkIsQtyValueExist(baseSku, setBaseSku) || !checkValuesForFreeSku(freeSku, updateFreeSku)) {
          return;
        }
      }

      if (isEmpty) {
        showToast(error, 5000);
      } else {
        const data = getValues(values);
        setCurrentDate(data?.startDate);
        setEndDate(data?.endDate);
        const { isEdit, promoData } = brandDetails;
        if (isEdit) {
          const { id, brandId } = promoData;
          validateUpdate(brandId, id, data);
        } else { createPromo(currentBrand?.id, data); }
      }
    },
  });

  useEffect(() => {
    const offerType = formik.values.offer;
    skuTags = [];
    updateList = [];
    setMoqList([]);
    setMovDataList([]);
    setMoqDataList([]);
    setOfferItem(offerType);
    if (offerType === MOV && !brandDetails?.isEdit) {
      const list = [];
      addMOV(list, '', '', false);
      setMovList(list);
    }
    setDefaultType('');
    if (offerType === BUY_N_GET_X && !brandDetails?.isEdit) {
    setBaseSku([]);
    updateFreeSku([getEmptySku()]);
    }
    setIsTypeDisabled(false);
    setIsOfferChanged(!isOfferChanged);
    setUpdatedList([]);
    setIsUpdated(!isUpdated);
  }, [formik.values.offer]);

  useEffect(() => {
    const { isEdit } = brandDetails;
    if (isEdit && selectedBrand && currentBrand) {
      if (selectedBrand?.id !== currentBrand?.id) { onBackPress(); }
    } else if (selectedBrand) {
      const { id } = selectedBrand;
      setCurrentBrand(selectedBrand);

      // Remove Current Tags
      setInitialValues();

      // Call API to GET SKUs
      getOffers(id);
      getSkuList(id);
      formik.setTouched({}, false);
      setBaseSku([]);
      setFreeSku([]);
      resetData();
    }
  }, [selectedBrand]);

  const setInitialValues = () => {
    skuTags = [];
    updateList = [];
    formik.setFieldValue('sku', '');
    formik.setFieldValue('offer', '');
    formik.setFieldValue('discount', '');
    formik.setFieldValue('startDate', '');
    formik.setFieldValue('endDate', '');
  };

  useEffect(() => {
    if (isValidateSuccess) {
      setIsSkuUpdate(!isSkuUpdate);
      if (checkIsRunning(currentDate, endDate)) {
        setIsSkuUpdate(true);
      } else {
        handleConfirm();
      }
    }
  }, [isValidateSuccess]);

  useEffect(() => {
    const { isEdit, promoData } = brandDetails;
    skuTags = [];
    updateList = [];
    formik.setValues({ ...formik.values });
    if (isEdit && formik) {
      const { brandId, promoSkus, discount, startDate, endDate: enddate, type } = promoData;
      if (type === MOQ && promoSkus && promoSkus.length > 0) {
        // Load SKUs
        const list = promoSkus?.map((item) => ({ name: `${item.sku} : ${item.name}`, isError: false, id: item.sku }));
        skuTags = list;

        const updatedPromoSKU = promoSkus.map((item) => ({ name: `${item.sku} : ${item.name}`, sku: item.sku }));
        updateList = updatedPromoSKU;
        // Set Preselected values
        formik.setFieldValue('discount', discount);
        formik.setFieldValue('startDate', startDate);
        formik.setFieldValue('endDate', enddate);
        formik.setFieldValue('sku', skuTags[0].name);
        formik.setFieldValue('offer', type);
      }

      const brand = brandsList?.filter((item) => item.id === brandId)[0]
      // Save Current Brand
      setCurrentBrand(brand);
      getSkuList(brandId);
      setOfferItem(type);
    }
    setIsSkuUpdate(false);
    setIsUpdated(!isUpdated);
  }, [brandDetails]);

  const handleDelete = (item, formField) => {
    const index = skuTags.findIndex((x) => x.name === item);
    if (index !== -1) {
      skuTags.splice(index, 1);
      skuTags.map((itemError) => {
        const error = { ...itemError };
        error.isError = false;
        return error;
      });
      removeSku.push(item);

      const data = removeList(moqList, 'skuId', item);
      setMoqList(data);
      handleListUpdate(data);
      setIsUpdated(!isUpdated);
      setRemoveSku(removeSku);

      const subIndex = updateList.findIndex((x) => x.name === item);
      updateList.splice(subIndex, 1);

      if (skuTags.length === 0) {
        formField.setValues({ ...formField.values, sku: '' });
      } else { formField.setValues({ ...formField.values }); }

      if (isError) {
        resetData();
      }
    }
  };

  const onBackPress = () => {
    navigation(ROUTE_BRANDS_PROMO, { replace: true });
  };

  useEffect(() => {
    if (isUpdateSuccess || isCreateSuccess) {
      resetData();
      onBackPress();
    }
  }, [isUpdateSuccess, isCreateSuccess]);

  const handleModalClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setIsSkuUpdate(false);
  };

  const handleCancel = () => {
    setIsSkuUpdate(false);
  };

  const handleConfirm = () => {
    const { brandId, id } = brandDetails?.promoData;
    const data = getValues(formik.values);
    updateBrandPromo(brandId, id, data);
    setIsSkuUpdate(false);
  };

  const handleListUpdate = (list) => {
    const models = [];
    const promoSku = getMOQList(list, skuTags, models);
    setMoqDataList(promoSku);
  };

  const handleMovListUpdate = (type, list) => {
    const items = getMovList(list);
    setMovDataList(items);
  };

  const handleEditView = () => {
    const { isEdit, promoData } = brandDetails;
    const { promoSkus, discount, startDate, endDate: enddate, promoMov, type, promoBuyNGetX } = promoData;
    // Load SKUs
    const updatedSkuTags = promoSkus?.map((item) => ({ name: `${item?.sku} : ${item?.name}`,
      isError: false,
      id: item.sku,
      isSelected: true }));
    skuTags = updatedSkuTags;

    const updatedPromoSKU = promoSkus?.map((item) => ({ name: `${item?.sku} : ${item?.name}`, sku: item?.sku }));
    updateList = updatedPromoSKU;

    if (isEdit && formik) {
      formik.setFieldValue('startDate', startDate);
      formik.setFieldValue('endDate', enddate);
      formik.setFieldValue('discount', discount);
      if (skuTags?.length > 0) {
        formik.setFieldValue('sku', skuTags[0].name);
      }
      setCurrentDate(startDate);
      setEndDate(enddate);
    }

    if (type === MOQ) {
      if (isEdit && formik && skuTags && skuTags.length > 0) {
        const moqs = getAllMOQ(promoSkus);
        setMoqList(moqs);
        handleListUpdate(moqs);
        setIsUpdated(!isUpdated);
      }
    } else if (type === MOV) {
      const list = [];
      promoMov.forEach((element) => {
        addMOV(list, element.mov, element.discount, false);
      });
      list[list.length - 1].isDelete = true;
      setMovList(list);
      setIsUpdated(!isUpdated);
    } else if(type === BUY_N_GET_X && !!promoBuyNGetX){

      const freeSkuList = promoBuyNGetX.freeSku
      const baseSkuList = promoBuyNGetX.baseSku

      setBaseSku(baseSkuList)
      updateFreeSku(freeSkuList)

      formik.setFieldValue('startDate', startDate);
      formik.setFieldValue('endDate', enddate);

      if(freeSkuList?.length > 0){
        formik.setFieldValue('freeSelectionCriteria', freeSkuList[0]?.selectionCriteria);
      }
      if(baseSkuList?.length > 0){
        formik.setFieldValue('selectionCriteria', baseSkuList[0]?.selectionCriteria);
        const basePromoSkus =  baseSkuList[0]?.promoSkus;
        formik.setFieldValue('paidsku',(basePromoSkus?.length > 0 && `${basePromoSkus[0].sku} : ${basePromoSkus[0].name}`) || '');
      }

      if(baseSkuList?.length > 1){
        formik.setFieldValue( 'showSku2', true );
        const basePromoSkus =  baseSkuList[1]?.promoSkus;
        formik.setFieldValue('paidsku2',(basePromoSkus?.length > 0 && `${basePromoSkus[0].sku} : ${basePromoSkus[0].name}`) || '');

      }

    }
    // Update SKU List
    if (skuList.length > 0) {
      setUpdatedList(updatedSkuTags);
      setIsUpdated(!isUpdated);
    }
  };

  const handleDisbale = (isDisable) => {
    setIsTypeDisabled(isDisable);
  };

  const handleUpdate = (skus, isAdd) => {
    if (skus && skus.length > 0 && isAdd) {
      skus.forEach((sku) => {
        let item = skuList.filter((model) => model.label === sku);
        const skuItem = { name: sku, isError: false, id: (item.length > 0 && item[0].id)|| ''};
        item = item.map((item2) => ({ name: item2.label.split(' : ')[1], sku: item2.value }));
        skuTags.push(skuItem);
        updateList.push(item[0]);
        if (formik.values.offer === MOQ) {
          addMOQ(moqList, skuItem.name, skuItem.id);
          setMoqList(moqList);
          handleListUpdate(moqList);
          setIsUpdated(!isUpdated);
        }
      });
      formik.setFieldValue('sku', skus[0]);
    } else {
      skuTags = [];
      updateList = [];
      if (formik.values.offer === MOQ) {
        setMoqList([]);
        setMoqDataList([]);
      }
      setIsUpdated(!isUpdated);
      setRemoveSku([]);
      formik.setFieldValue('sku', '');
    }
  };

  const handleSingleUpdate = (sku, isAddition) => {
    if (isAddition) {
      const items = skuTags?.filter((model) => model.name === sku);
      if (sku && sku.length > 0 && items.length === 0) {
        let item = skuList.filter((model) => model.label === sku);
        const skuItem = { name: sku, isError: false, id: (item.length > 0 && item[0].id)|| '' };
        item = item.map((item2) => ({ name: item2.label.split(' : ')[1], sku: item2.value }));
        skuTags.push(skuItem);
        updateList.push(item[0]);
        if (formik.values.offer === MOQ) {
          addMOQ(moqList, skuItem.name, skuItem.id);
          setMoqList(moqList);
          handleListUpdate(moqList);
          setIsUpdated(!isUpdated);
        }
        formik.setFieldValue('sku', sku);
      }
    } else {
      handleDelete(sku, formik);
      setRemoveSku([]);
    }
  };

  const updateFreeSku = (freeSku) => {
    setFreeSku(freeSku)
    formik.setFieldValue('freesku', freeSku)
  }

  return (
    <form
      ref={form}
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate={true}
      sx={{ mt: 1 }}
      autoComplete="off"
    >
      <Main>
        <ProductWrapper>
          {
            isSkuLoading || isOfferLoading ? (
              <ProgressContainer sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </ProgressContainer>
            ) : (
              <SectionContent>
                <FormControl sx={{ paddingBottom: '10px' }}>
                  <CreateTitle>
                    {disable ? t('createPromo.promoDetails')
                    : brandDetails?.isEdit ? t('createPromo.updatePromo') : t('createPromo.createPromo')}
                  </CreateTitle>
                  <Divider fullWidth={true} />
                </FormControl>
                <FormControl>

                  <OfferBox>
                    <OfferContainer>
                      <Grid md={3} sm={8} xs={8}>
                        <SelectGrid width={formik.values.offer === REGULAR ? `20%` : '36%'} type={formik.values.offer}>
                          <TextTitle>{t('createPromo.selectOffer')}</TextTitle>
                          <CreateSelect
                            formikId="offer"
                            formikValue={formik.values.offer}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            helper={formik.touched.offer ? formik.errors.offer : ''}
                            errorText={formik.touched.offer && Boolean(formik.errors.offer)}
                            listData={offers.map((item) => ({ value: item, label: convertText(item.toLowerCase()) }))}
                            size="small"
                            inputType="select"
                            disabale={brandDetails?.isEdit}
                          />

                        </SelectGrid>
                        <DescriptionText>{getDescription(formik.values.offer, t)}</DescriptionText>
                      </Grid>
                      <Grid container={true} rowSpacing={1} marginTop="8px">
                        <Grid item={true} xs={formik.values.offer && formik.values.offer === REGULAR ? 6 : 10}>

                          {(formik.values.offer === REGULAR || formik.values.offer === MOQ) && (
                          <SearchGrid width={formik.values.offer === '' ? '53%' : `90%`}>
                            <SelectTitle>{t('createPromo.skuId')}</SelectTitle>
                            <MuiSearch
                              formikId="sku"
                              formikValue={formik.values.sku}
                              handleChange={formik.handleChange}
                              handleBlur={formik.handleBlur}
                              helper={formik.touched.sku && skuTags.length === 0 ? formik.errors.sku : ''}
                              errorText={formik.touched.sku && Boolean(formik.errors.sku) && skuTags.length === 0}
                              listData={skuList}
                              formikField={formik}
                              height="small"
                              noOption={t('brandDashboard.noSKU')}
                              textLabel={t('brandDashboard.sku')}
                              onUpdate={handleUpdate}
                              onItemAdd={handleSingleUpdate}
                              removeData={removeSku}
                              isListUpdate={isUpdated}
                              updatePromo={updatedList}
                            />

                          </SearchGrid>
                          )}

                          { skuTags && skuTags.length > 0 && (
                          <ChipWrapper>
                            <div>
                              {' '}
                              {skuTags && skuTags.length > 0 && skuTags.map((item) => <ChipContainer
                                label={`${item.name}`}
                                sx={{
                                  '& .MuiChip-deleteIcon': {
                                    color: item.isError ? '#FF5151' : 'default',
                                  },
                                }}
                                error={item.isError}
                                variant="outlined"
                                onDelete={() => handleDelete(item.name, formik)}
                              />)}
                              {' '}

                            </div>
                          </ChipWrapper>
                          )}
                        </Grid>

                        { formik.values.offer && formik.values.offer === REGULAR && (
                        <Grid item={true} xs={6}>
                          <SelectGrid width="30%">
                            <TextTitle margin="6px">{t('createPromo.discount')}</TextTitle>
                            <CreateSelect
                              formikId="discount"
                              formikValue={formik.values.discount}
                              handleChange={formik.handleChange}
                              handleBlur={formik.handleBlur}
                              helper={formik.touched.discount ? formik.errors.discount : ''}
                              errorText={formik.touched.discount && Boolean(formik.errors.discount)}
                              size="small"
                              inputType="number"
                              defaultText="%"
                            />
                          </SelectGrid>
                        </Grid>
                        )}
                      </Grid>

                      { formik.values.offer === BUY_N_GET_X && (
                      <BundleComponent
                        formik={formik}
                        skuList={skuList}
                        baseSku={baseSku}
                        setBaseSku={setBaseSku}
                        freeSku={freeSku}
                        setFreeSku={updateFreeSku}
                        currentBrand={currentBrand}
                        disable={disable}
                      />

                      )}
                      <CustomGrid
                        width={
                            (formik.values.offer === REGULAR && '57%') || (formik.values.offer === MOV
                                && '81%') || '95%'
                        }
                        type={formik.values.offer}
                      >
                        <Grid container={true} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item={true} xs={formik.values.offer === MOV ? 6 : 5}>
                            <SelectGrid width="90%">
                              <TextTitle>{t('createPromo.startDate')}</TextTitle>
                              <DateSelect
                                formikId="startDate"
                                formikValue={formik.values.startDate}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                helper={formik.touched.startDate ? formik.errors.startDate : ''}
                                errorText={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                size="small"
                                formikField={formik}
                                loadDefault={handleEditView}
                                isEditable={brandDetails?.isEdit}
                                isOffer={isOfferChanged}
                                disable={disable}
                              />
                            </SelectGrid>
                          </Grid>
                          <Grid item={true} xs={formik.values.offer === MOV ? 6 : 5}>
                            <SelectGrid width="90%">
                              <TextTitle>{t('createPromo.endDate')}</TextTitle>
                              <DateSelect
                                formikId="endDate"
                                formikValue={formik.values.endDate}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                helper={formik.touched.endDate ? formik.errors.endDate : ''}
                                errorText={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                size="small"
                                formikField={formik}
                                isOffer={isOfferChanged}
                                disable={disable}
                              />
                            </SelectGrid>
                          </Grid>
                        </Grid>
                      </CustomGrid>
                    </OfferContainer>

                    { formik.values.offer && (formik.values.offer === MOQ) && (
                    <MOQContainer width="40%" position="start" margin="10%">
                      <OfferGrid width="100%">
                        <OfferType
                          columns={OFFER_MOQ}
                          isMov={false}
                          dataModel={moqList}
                          onListUpdate={handleListUpdate}
                          isUpdate={isUpdated}
                          isDisable={handleDisbale}
                        />
                      </OfferGrid>
                    </MOQContainer>
                    )}

                    { formik.values.offer && (formik.values.offer === MOV) && (
                    <MOQContainer width="40%" position="start" margin="10%">
                      <OfferGrid width="100%">
                        <MovComponent
                          columns={OFFER_MOV}
                          dataModel={movList}
                          onMovUpdate={handleMovListUpdate}
                          isUpdate={isUpdated}
                          isDisable={handleDisbale}
                        />
                      </OfferGrid>
                    </MOQContainer>
                    )}
                  </OfferBox>

                  {isSkuUpdate && (<Confirm
                    isOpen={isSkuUpdate}
                    onClose={handleModalClose}
                    title={t('createPromo.updateTitle')}
                    subText={t('createPromo.updateSub')}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                  />)}
                  {isError && (<SelectTitle error={true}>{(errorDetails?.message && errorDetails?.message?.includes("overlapping promos")) ? t('errors.overlappingPromos') : errorDetails.message}</SelectTitle>)}
                  <BottomGrid container={true}>
                    <Grid item={true}>
                      <PromoBack variant="text" onClick={onBackPress}>
                        {t('addProduct.back')}
                      </PromoBack>
                    </Grid>
                    <Grid item={true} xs={true}>
                      <Grid container={true} direction="row-reverse">
                        {
                          !brandDetails?.hideEditButton && (
                            <CreateButton
                            sx={{
                              ml: 1,
                              '&.MuiButtonBase-root:hover': {
                                bgcolor: confirm,
                              },
                            }}
                            isDisable={isTypeDisabled}
                            type="submit"
                            disabled={isTypeDisabled}
                            loading={isCreate}
                            >
                              {  disable ? t('createPromo.edit') : brandDetails?.isEdit ? t('createPromo.update') : t('createPromo.create')}
                            </CreateButton>
                          )
                        }
                        <ProgressContainer sx={{ display: 'flex', justifyContent: 'center' }}>
                          {(isCreate || isUpdate || isValidate) && (<CircularProgress size="2rem" />)}
                        </ProgressContainer>
                      </Grid>
                    </Grid>
                  </BottomGrid>
                </FormControl>
              </SectionContent>
            )
                    }

        </ProductWrapper>
      </Main>

    </form>
  );
};

CreatePromo.propTypes = {
  isOfferLoading: PropTypes.bool,
  isOfferLoaded: PropTypes.bool,
  isSkuLoading: PropTypes.bool,
  isSkuLoaded: PropTypes.bool,
  skuDetails: PropTypes.shape({}),
  offerDetails: PropTypes.arrayOf(),
  getOffers: PropTypes.func,
  getSkuList: PropTypes.func,
  createPromo: PropTypes.func,
  isCreate: PropTypes.bool,
  errorDetails: PropTypes.shape({
    skus: PropTypes.arrayOf(PropTypes.shape({})),
    message: PropTypes.string,
  }),
  isError: PropTypes.bool,
  resetData: PropTypes.func,
  updateBrandPromo: PropTypes.func,
  isUpdate: PropTypes.bool,
  isUpdateSuccess: PropTypes.bool,
  validateUpdate: PropTypes.func,
  isValidate: PropTypes.bool,
  isValidateSuccess: PropTypes.bool,
  brandDetails: PropTypes.shape({
    isEdit: PropTypes.bool,
    viewDetails: PropTypes.bool,
    promoData: PropTypes.shape({
      promoSkus: PropTypes.arrayOf(),
      discount: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      promoMov: PropTypes.arrayOf(),
      type: PropTypes.string,
      brandId: PropTypes.number,
      id: PropTypes.bool,
    }),
  }),
  isCreateSuccess: PropTypes.bool,
  selectedBrand: PropTypes.shape({
    id: PropTypes.string,
  }),
};

CreatePromo.defaultProps = {
  isOfferLoading: false,
  isOfferLoaded: false,
  isSkuLoading: false,
  isSkuLoaded: false,
  skuDetails: null,
  offerDetails: [],
  getOffers: () => { },
  getSkuList: () => { },
  createPromo: () => { },
  isCreate: false,
  errorDetails: {},
  isError: false,
  resetData: () => { },
  updateBrandPromo: () => { },
  isUpdate: false,
  isUpdateSuccess: false,
  validateUpdate: () => { },
  isValidate: false,
  isValidateSuccess: false,
  brandDetails: {},
  isCreateSuccess: false,
  selectedBrand: {},
};

export default CreatePromo;
