import React from 'react'
import { connect } from 'react-redux'
import Layout from '../Layout'
import ProductListComponent from '../../components/ProductList'
import { PRODUCTS_GET_LIST, STORES_GET_LIST, PRODUCTS_ALL_RECORDS_GN } from '../../actions'

const ProductList = props => {
  return (
    <Layout>
      <ProductListComponent {...props} />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { products, stores } = state
  return {
    error: products.get('error'),
    isLoading: products.get('isLoading'),
    productList: products.get('content').toJS(),
    storeError: stores.get('error'),
    storeIsLoading: stores.get('isLoading'),
    storeList: stores.get('content').toJS(),
    productAllRecords: products.get('productAllRecords'),
    productsAllRecordLoading: products.get('productAllRecordLoading')
  }
}

const mapDispatchToProps = dispatch => ({
  onFetchProductsTotalRecords: (query) => {
    dispatch({ type: PRODUCTS_ALL_RECORDS_GN, payload: query })
  },
  onGetProductList: (query) => {
    dispatch({ type: PRODUCTS_GET_LIST, payload: query })
  },
  onGetStoreList: () => {
    dispatch({ type: STORES_GET_LIST })
  }
})

ProductList.displayName = 'ProductListContainer'
export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
