import { Map } from 'immutable'

import {
  PAGEBUILDER_LINKS_CREATE_LOADING,
  PAGEBUILDER_LINKS_CREATE_POST_DATA_SUCCESS,
  PAGEBUILDER_LINKS_CREATE_POST_DATA_FAILURE,
  PAGEBUILDER_LINKS_UPDATE_LOADING,
  PAGEBUILDER_LINKS_UPDATE_POST_DATA_SUCCESS,
  PAGEBUILDER_LINKS_UPDATE_POST_DATA_FAILURE,
  PAGEBUILDER_LINKS_DELETE_LOADING,
  PAGEBUILDER_LINKS_DELETE_POST_DATA_SUCCESS,
  PAGEBUILDER_LINKS_DELETE_POST_DATA_FAILURE,
  PAGEBUILDER_LINKS_FETCH_LOADING,
  PAGEBUILDER_LINKS_FETCH_GET_DATA_SUCCESS,
  PAGEBUILDER_LINKS_FETCH_GET_DATA_FAILURE,
  PAGEBUILDER_ABOUT_CREATE_LOADING,
  PAGEBUILDER_ABOUT_CREATE_POST_DATA_SUCCESS,
  PAGEBUILDER_ABOUT_CREATE_POST_DATA_FAILURE,
  PAGEBUILDER_ABOUT_FETCH_POST_DATA_FAILURE,
  PAGEBUILDER_ABOUT_FETCH_LOADING,
  PAGEBUILDER_ABOUT_FETCH_POST_DATA_SUCCESS,
  PAGEBUILDER_SECTIONS_REORDER_LOADING,
  PAGEBUILDER_SECTIONS_REORDER_POST_DATA_SUCCESS,
  PAGEBUILDER_SECTIONS_REORDER_POST_DATA_FAILURE,
  PAGEBUILDER_LINKS_REORDER_LOADING,
  PAGEBUILDER_LINKS_REORDER_POST_DATA_SUCCESS,
  PAGEBUILDER_LINKS_REORDER_POST_DATA_FAILURE,
  EARNINGS_VIEW_LOADING,
  EARNINGS_VIEW_SUCCESS,
  EARNINGS_VIEW_FAILURE
} from '../actions'

const initialState = new Map({
  pageBuilderLinksCreateIsLoading: false,
  pageBuilderLinksCreateData: {},
  pageBuilderLinksUpdateIsLoading: false,
  pageBuilderLinksUpdateData: {},
  pageBuilderLinksDeleteIsLoading: false,
  pageBuilderLinksDeleteData: {},
  pageBuilderLinksFetchIsLoading: false,
  pageBuilderLinksFetchData: {},
  pageBuilderAboutCreateIsLoading: false,
  pageBuilderAboutCreateData: {},
  pageBuilderAboutFetchIsLoading: false,
  pageBuilderAboutFetchData: {},
  pageBuilderSectionsReorderIsLoading: false,
  pageBuilderSectionsReorderData: {},
  pageBuilderLinksReorderIsLoading: false,
  pageBuilderLinksReorderData: {},
  earningsViewIsLoading: false,
  earningsViewData: {}
})

export const pageBuilder = (state = initialState, action) => {
  const {
    earningsData,
    pageBuilderLinksCreateIsLoading,
    pageBuilderLinksCreateData,
    pageBuilderLinksUpdateIsLoading,
    pageBuilderLinksUpdateData,
    pageBuilderLinksDeleteIsLoading,
    pageBuilderLinksDeleteData,
    pageBuilderLinksFetchIsLoading,
    pageBuilderLinksFetchData,
    pageBuilderSectionsReorderIsLoading,
    pageBuilderSectionsReorderData,
    pageBuilderLinksReorderIsLoading,
    pageBuilderLinksReorderData,
    message,
    type
  } = action

  switch (type) {
    case EARNINGS_VIEW_FAILURE:
      return state.set('earningsViewIsLoading', false)
    case EARNINGS_VIEW_LOADING:
      return state.set('earningsViewIsLoading', true)
    case EARNINGS_VIEW_SUCCESS:
      return state.merge({
        earningsViewData: earningsData || {},
        earningsViewIsLoading: false
      })
    case PAGEBUILDER_ABOUT_CREATE_POST_DATA_FAILURE:
      return state.set('pageBuilderAboutCreateIsLoading', false)
    case PAGEBUILDER_ABOUT_CREATE_LOADING:
      return state.set('pageBuilderAboutCreateIsLoading', true)
    case PAGEBUILDER_ABOUT_FETCH_POST_DATA_FAILURE:
      return state.set('pageBuilderAboutFetchIsLoading', false)
    case PAGEBUILDER_ABOUT_FETCH_LOADING:
      return state.set('pageBuilderAboutFetchIsLoading', true)
    case PAGEBUILDER_ABOUT_FETCH_POST_DATA_SUCCESS:
      return state.merge({
        pageBuilderAboutFetchData: pageBuilderLinksCreateData || {},
        pageBuilderAboutFetchIsLoading: false
      })
    case PAGEBUILDER_LINKS_CREATE_LOADING:
      return state.set('pageBuilderLinksCreateIsLoading', pageBuilderLinksCreateIsLoading)
    case PAGEBUILDER_LINKS_CREATE_POST_DATA_SUCCESS:
      return state.merge({
        pageBuilderLinksCreateData: pageBuilderLinksCreateData || {}
      })
    case PAGEBUILDER_LINKS_CREATE_POST_DATA_FAILURE:
      return state.set('pageBuilderLinksCreateError', message)
    case PAGEBUILDER_LINKS_UPDATE_LOADING:
      return state.set('pageBuilderLinksUpdateIsLoading', pageBuilderLinksUpdateIsLoading)
    case PAGEBUILDER_LINKS_UPDATE_POST_DATA_SUCCESS:
      return state.merge({
        pageBuilderLinksUpdateData: pageBuilderLinksUpdateData || {}
      })
    case PAGEBUILDER_LINKS_UPDATE_POST_DATA_FAILURE:
      return state.set('pageBuilderLinksUpdateError', message)
    case PAGEBUILDER_LINKS_DELETE_LOADING:
      return state.set('pageBuilderLinksDeleteIsLoading', pageBuilderLinksDeleteIsLoading)
    case PAGEBUILDER_LINKS_DELETE_POST_DATA_SUCCESS:
      return state.merge({
        pageBuilderLinksDeleteData: pageBuilderLinksDeleteData || {}
      })
    case PAGEBUILDER_LINKS_DELETE_POST_DATA_FAILURE:
      return state.set('pageBuilderLinksDeleteError', message)
    case PAGEBUILDER_LINKS_FETCH_LOADING:
      return state.set('pageBuilderLinksFetchIsLoading', pageBuilderLinksFetchIsLoading)
    case PAGEBUILDER_LINKS_FETCH_GET_DATA_SUCCESS:
      return state.set('pageBuilderLinksFetchData', pageBuilderLinksFetchData)
    // return state.merge({pageBuilderLinksFetchData: pageBuilderLinksFetchData || []})
    case PAGEBUILDER_LINKS_FETCH_GET_DATA_FAILURE:
      return state.set('pageBuilderLinksFetchError', message)
    case PAGEBUILDER_SECTIONS_REORDER_LOADING:
      return state.set('pageBuilderSectionsReorderIsLoading', pageBuilderSectionsReorderIsLoading)
    case PAGEBUILDER_SECTIONS_REORDER_POST_DATA_SUCCESS:
      return state.set('pageBuilderSectionsReorderData', pageBuilderSectionsReorderData)
    case PAGEBUILDER_SECTIONS_REORDER_POST_DATA_FAILURE:
      return state.set('pageBuilderSectionsReorderError', message)
    case PAGEBUILDER_LINKS_REORDER_LOADING:
      return state.set('pageBuilderLinksReorderIsLoading', pageBuilderLinksReorderIsLoading)
    case PAGEBUILDER_LINKS_REORDER_POST_DATA_SUCCESS:
      return state.set('pageBuilderLinksReorderData', pageBuilderLinksReorderData)
    case PAGEBUILDER_LINKS_REORDER_POST_DATA_FAILURE:
      return state.set('pageBuilderLinksReorderError', message)
    case PAGEBUILDER_ABOUT_CREATE_POST_DATA_SUCCESS:
      return state.merge({
        pageBuilderAboutCreateData: pageBuilderLinksCreateData || {},
        pageBuilderAboutCreateIsLoading: false
      })

    default:
      return state
  }
}
