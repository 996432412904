import { styled } from '@mui/system'
import { Box, RadioGroup } from '@mui/material'

export const FormLabel = styled('label')(`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
  width:100%;
`)

export const FormError = styled(Box)(`
  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
`)

export const RadioGroupWrap = styled(RadioGroup)(`
  & span:last-child {font-size: 14px;}
`)
