/* eslint-disable consistent-return */
import * as yup from 'yup';

export const ValidationSchema = (t) => (yup.object({
  offer: yup.string().required(`${t('errors.selectError')}`),
  startDate: yup.date()
    .typeError(`${t('errors.startDate')}`)
    .required(`${t('errors.startDate')}`),
  endDate: yup.date()
    .typeError(`${t('errors.endDate')}`)
    .required(`${t('errors.endDate')}`).when('startDate', (startDate) => {
      if (startDate) {
        return yup.date().required(`${t('errors.endDate')}`)
          .min(startDate, `${t('errors.startEnd')}`)
          .typeError(`${t('errors.endDate')}`);
      }
    }),
  sku: yup.string().required(`${t('errors.skuId')}`),
  discount: yup.number().required(`${t('errors.discount')}`)
    .min(1, `${t('errors.discountZeor')}`).max(100, `${t('errors.discountMax')}`),
}));

export const ValidationMOVSchema = (t) => (yup.object({
  offer: yup.string().required(`${t('errors.selectError')}`),
  startDate: yup.date()
    .typeError(`${t('errors.startDate')}`)
    .required(`${t('errors.startDate')}`),
  endDate: yup.date()
    .typeError(`${t('errors.endDate')}`)
    .required(`${t('errors.endDate')}`).when('startDate', (startDate) => {
      if (startDate) {
        return yup.date().required(`${t('errors.endDate')}`)
          .min(startDate, `${t('errors.startEnd')}`)
          .typeError(`${t('errors.endDate')}`);
      }
    }),
}));

export const ValidationMOQSchema = (t) => (yup.object({
  offer: yup.string().required(`${t('errors.selectError')}`),
  startDate: yup.date()
    .typeError(`${t('errors.startDate')}`)
    .required(`${t('errors.startDate')}`),
  endDate: yup.date()
    .typeError(`${t('errors.endDate')}`)
    .required(`${t('errors.endDate')}`).when('startDate', (startDate) => {
      if (startDate) {
        return yup.date().required(`${t('errors.endDate')}`)
          .min(startDate, `${t('errors.startEnd')}`)
          .typeError(`${t('errors.endDate')}`);
      }
    }),
  sku: yup.string().required(`${t('errors.skuId')}`),
}));

export const ValidationBundleSchema = (t) => (yup.object({
  offer: yup.string().required(`${t('errors.selectError')}`),
  startDate: yup.date()
    .typeError(`${t('errors.startDate')}`)
    .required(`${t('errors.startDate')}`),
  endDate: yup.date()
    .typeError(`${t('errors.endDate')}`)
    .required(`${t('errors.endDate')}`).when('startDate', (startDate) => {
      if (startDate) {
        return yup.date().required(`${t('errors.endDate')}`)
          .min(startDate, `${t('errors.startEnd')}`)
          .typeError(`${t('errors.endDate')}`);
      }
    }),
  paidsku: yup.string().when('selectionCriteria', (selectionCriteria) => {
    if (selectionCriteria !== 'ANY') {
      return yup.string().required(`${t('errors.skuId')}`);
    }
  }),
  paidsku2: yup.string().when(['selectionCriteria', 'showSku2'], (selectionCriteria, showSku2) => {
    if (selectionCriteria !== 'ANY' && showSku2) {
      return yup.string().required(`${t('errors.skuId')}`);
    }
  })
}));
