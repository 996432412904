import { styled } from '@mui/system'
import { Box, InputAdornment, Typography, Divider } from '@mui/material'
import mobileBg from '../../assets/images/mobile-bg.jpg'

export const InputAdornments = styled(InputAdornment)(props => `
  opacity:.4;
  font-size:12px;
`)

export const SectionContent = styled(Box)(`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px){
    flex-direction: column;
    width: 100%; 
  }
`)

export const FormControl = styled(Box)(`
  width:100%;
  display: flex;
  flex-direction: column;
  padding-bottom:20px;
  @media (max-width: 1000px) {
    margin: .5rem 0;
  }
  @media (max-width: 600px) {
    padding: 0 10px;
  }
`)

export const FormHeading = styled(Typography)(`
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  color: #536082;
  width:100%;
`)

export const Divide = styled(Divider)(`
  width:100%;
  margin-bottom: 30px;
`)

export const MobileOuterWrapper = styled(Box)(`
  margin:0 auto;
  border-radius:35px;
  background:#333;
  padding:10px;
  width:320px;
  height:580px;
  display:flex;
`)

export const MobileInnerWrapper = styled(Box)(`
  border-radius:28px;
  overflow:hidden;
  padding:20px;
  width:100%;
  height:100%;
  background:url(${mobileBg});
  background-size: cover;
  box-shadow: 0 0 0px 5px #98989859;
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`)
