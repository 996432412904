import styled from 'styled-components'

export const ModalWrapper = styled.div`
  display: ${props => props.showModal ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  opacity:.7;
  cursor: pointer;
`

export const CloseWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index:1;
`

export const Popup = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #FFFFFF;
  width: auto;
  height: fit-content;
  padding: 2rem;
  border-radius: 10px;
  @media (max-width: 425px) {
    width: auto;
    // left: -160px; // when commented, helps with prodCreate flow testing
  }
  @media (max-width: 768px) {
    width: auto;
    // left: -200px; // when commented, helps with prodCreate flow testing
  }
`
