import React from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ButtonGrid, CreateNewBtn, DownloadBtn, SearchIconWrapper, SearchInputWrapper } from './style'
import MuiTextField from '../MuiTextField'
import SearchIcon from '../../assets/images/searchIcon.svg'
import downloadIcon from '../../assets/images/downloadIcon.svg'

const FilterRow = ({
  searchtext,
  handleSearchTextChange,
  downloadHandler
}) => {
  const { t } = useTranslation()

  return (
    <Grid container columns={16}>
      <Grid item md={6} sm={16} xs={16}>
        <SearchInputWrapper>
          <MuiTextField
            onChange={(e) => {
              handleSearchTextChange(e.target.value)
            }}
            margin='normal'
            value={searchtext}
            name='searchtext'
            type='text'
            variant='outlined'
            size='small'
            placeholder={t('brandCatalouge.searchByProdOrSku')}
            InputProps={{
              startAdornment: <SearchIconWrapper><img src={SearchIcon} alt='search-icon' /></SearchIconWrapper>
            }}
          />
        </SearchInputWrapper>
      </Grid>
      <Grid item md={10} sm={16} xs={16}>
        <Grid container direction={{ md: 'row-reverse' }} columns={16}>
        <ButtonGrid item md={4} sm={12} xs={12}>
        <CreateNewBtn onClick={()=>{
          downloadHandler();
        }}>
          <DownloadBtn>
            <img src={downloadIcon} alt={"downloadIcon"} height={14} style={{paddingRight:8}} /> 
            {t('mspCompare.download')}
          </DownloadBtn>
        </CreateNewBtn>
      </ButtonGrid>
        </Grid>
      </Grid>
    </Grid>
  )
}

FilterRow.displayName = 'BrandPromoFilterRowComponent'
export default FilterRow
