import { styled } from '@mui/system'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

const media = {
  xl: '@media(max-width: 2560px)',
  lg: '@media(max-width: 1500px)',
  md: '@media(max-width: 850px)',
  sm: '@media(max-width: 600px)'
}

export const ProductListWrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 2rem;
  padding: 0.5rem 2rem;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(136, 150, 203, 0.121312);
  border-radius: 10px;
  ${media.sm}{
    margin: 15px;
    padding: .5rem;
    overflow-x: hidden;
  }
`)

export const ProductTopBar = styled(Box)(`
  display: flex;
  flex-direction: column;
  width: 100%;
`)

export const TitleWrapper = styled(Box)(`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position:relative;
  @media (max-width: 600px){
    flex-direction:column;
    padding-bottom:10px;
  }
`)

export const Title = styled('h1')(`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.48px;
  position:relative;
  ${media.sm}{
    margin-left:20px;
  }
`)

export const ProductCardWrapper = styled(Box)(`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  & > div {
    margin-bottom:10px;
  }
  ${media.md}{
    justify-content: space-around;
  }
  ${media.sm}{
    justify-content: space-between;
    & .cardWrapper {
      width: calc(50% - 5px);
      max-width: 200px;
      margin: 0 0 5px 0;
    }
    & .cardImgWrapper {
      width:calc(100% - 0px);
      max-width:180px;
    }
  }
`)

export const NoRecordWraper = styled('p')(`
  font-size: 18px;
  margin-top: 20px;
  font-weight: 500;
`)

export const SearchWrapper = styled(Box)(`
  padding-bottom:10px;
  display:flex;
  justify-content: space-between;
  align-items: baseline;
  min-width:300px;
  & > div {
    margin:0;
  }
  @media (max-width: 600px){
    & > div {
      margin:0;
      min-width: 100%;
    }
  }
`)

export const BackButton = styled(Link)(`
  position: absolute;
  left: -17px;
  top: 2px;
  width: 18px;
  height: 25px;
  display: inline-block;
  & svg path {
    fill: #162E9A;
  }
`)
