import { useEffect, useState } from 'react'
/* eslint-disable */
import ProductCard from '../ProductCard'
import Search from '../Search'
import Loading from '../LoadingSpinner'
import Pagination from '../pagination'
import {
    ProductListWrapper,
    ProductCardWrapper,
} from './style'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_TO_STORE_RAENA_PRODUCTS_EXECUTE, RAENA_PRODUCTS_GET_LIST } from '../../actions'
import ResuablePagination from '../ReuseablePagination'
import { store } from '../../store'
import { useNavigate } from 'react-router-dom'

const ProductList = ({ setTab }) => {
    const dispatch = useDispatch()
    const history = useNavigate()
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(12)
    const { isLoading, content: productList = [], error, totalRecords } = Object.fromEntries(useSelector(state => state.raenaProducts))
    const fetchProducts = () => {
        dispatch({ type: RAENA_PRODUCTS_GET_LIST, payload: { limit, offSet: page * limit, searchTerm: search } })
    }

    useEffect(() => {
        if (page !== 0) {
            setPage(0)
            return
        }
        fetchProducts()
    }, [search])

    useEffect(() => {
        fetchProducts()
    }, [limit, page])

    const onAddToStore = (product) => {
        store.dispatch({ type: ADD_TO_STORE_RAENA_PRODUCTS_EXECUTE, payload: { data: product, history, setTab }  })
    }

    return (
        <>
            {!error && isLoading && <Loading />}
            <Search placeholder='Search by Product Name' onEnter={(value) => { setSearch(value) }} />
            <ProductCardWrapper>
                {
                    (!error && !isLoading) &&
                    productList.map((product, idx) => (
                        <>
                            <ProductCard onAddStore={() => onAddToStore(product)} key={idx} product={product} isRaena />
                        </>
                    ))
                }
            </ProductCardWrapper>
            <ResuablePagination page={page} setPage={setPage} rowsPerPage={limit} setRowsPerPage={setLimit} totalRecords={totalRecords} />
        </>
    )
}

export default ProductList
