import { styled } from '@mui/system'
import { Box, Slider, IconButton, Typography, Avatar, ListItem, ListItemText } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const media = {
  xl: '@media(max-width: 2560px)',
  lg: '@media(max-width: 1500px)',
  md: '@media(max-width: 850px)',
  sm: '@media(max-width: 600px)'
}
export const ListItemWrap = styled(ListItem)(props => `
  padding: 10px 10px 10px 34px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(136, 150, 203, 0.121312);
  border-radius: 10px;
  width: 100%;
  & p {
    word-wrap: break-word;
  }
  &.carousel{
    overflow:auto;
    align-items:normal;
  }
  &.RAENA_PRODUCT, &.VIDEO, &.ARTICLE, &.CUSTOM_PRODUCT{
    flex-wrap: wrap;
  }
  ${media.sm}{
    &.CUSTOM_LINK{
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }

`)

export const ListItemInnerWrap = styled(Box)(props => `
  width: 100%;
  display: flex;
  overflow: auto;
`)

export const CardRowWrapper = styled(Box)(`
  display: flex;
  align-items: center;
  width: 100%;
`)
export const CountWrapper = styled(Box)(`
  color:#A2A5BB;
  font-size: 12px;
  flex:1;
`)
export const ButtonWrap = styled(Box)(`
  display: flex;
  width: 110px;
  min-width: 110px;
  flex-wrap: wrap;
  justify-content: flex-end;
  & p {
    color: #A2A5BB;
  }

  ${media.sm}{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    & > p {
      padding-left:10px;
    }
  }
`)

export const CardInnerWrapper = styled(Box)(`
  box-sizing:border-box;
  position: relative;
  width:200px;
  padding:10px;
  background: #FFF;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  margin-right:10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & p {
    line-height:1;
    min-height:28px;
    padding-bottom:5px;
  }
  & .dummy{
    filter: grayscale(100%);
    opacity: 0.3;
  }
`)

export const CardMedia = styled(Box)(`
  box-sizing:border-box;
  min-height:120px;
  height:120px;
  width:180px;
  overflow:hidden;
  position:relative;
  display:flex;
  justify-content: center;
  align-items: center;
  background:#dbdde5d1;
  margin-bottom:10px;
`)

export const CardTitle = styled(Typography)(`
  word-break: break-word;
`)

export const CardActions = styled(Box)(`
  width:100%;
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
  & > span {
    width:100%;
  }
`)

export const CardContent = styled(Box)(`
  line-height:1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 130px);
`)

export const AvatarWrap = styled(Avatar)(`
  width:60px;
  height:60px;
  background: #D3DBFF;
  margin-right:10px;
  overflow:hidden;
  border:2px #D3DBFF solid;
`)

export const ListItemTextWrapper = styled(ListItemText)(`
  & span {
    line-height:17px;
  }
  & p {
    padding-top:5px;
    line-height:17px;
  }
  ${media.sm}{
    width:calc(100% - 80px);
  }
`)

export const ListArrayItemTextWrapper = styled(ListItemText)(`
  display:flex;
  justify-content: space-between;
  & span {
    line-height:17px;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    padding-bottom:5px;
  }
  & p {
    padding-top:5px;
    line-height:17px;
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: #65748B;
    display: block;
  }
`)

export const HandleWrapper = styled(Box)(`
  margin-top: 10px;
  border-top: 1px solid #E5E5E5;
  padding-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`)

export const HandleStyleArrows = styled(IconButton)(`
  width: 20px;
  height: 20px;
  & svg path {
    fill: rgb(162, 165, 187)
  }
`)

export const HandleStyleDots = styled('span')(`
  background: #E5E5E5;
  display: block;
  width: 80px;
  height: 2px;
  border-radius: 2px;
`)

export const Price = styled(Typography)(`
  line-height:1;
  font-weight: 400;
  font-size: 14px;
  text-decoration: line-through;
  color: #A2A5BB;
  padding-bottom:5px;
`)

export const DiscountWrapper = styled(Box)(`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom:5px;
`)

export const DiscountPrice = styled(Typography)(`
  line-height:1;
  text-transform:none;
  font-weight: 700;
  font-size: 15px;
  color: #162E9A;
`)

export const DiscountPercentage = styled(Typography)(`
  line-height:1;
  font-weight: 600;
  font-size: 14px;
  color: #0EC690;
  text-align:right;
  text-transform:inherit;
`)

export const StoreAdded = styled(Box)(`
  background: #F4F7FD;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #162E9A;
  display: block;
  width: 100%;
  text-align: center;
  line-height: 30px;
`)

export const MoreVerticalIcon = styled(MoreVertIcon)(`
  height: 20px;
  width: 20px;
  color: #fff;
`)

export const RaenaProductNoDataFound = styled(Box)(`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap:wrap;
`)

export const CardHeading = styled(Box)(`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom:10px;
  & > a {
    color: #162E9A;
    text-decoration:underline;
    padding-top: 0;
    padding-bottom: 0;
  }
`)

export const IconButtonWrapper = styled(IconButton)(`
  height: 20px;
  width: 20px;
  background: #0000004D;
  position: absolute;
  top: 2px;
  right: 2px;
`)

export const ImgIconPinned = styled('img')(`
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  background: #7d88c3;
  z-index: 1;
  border: 4px #7d88c3 solid;
  border-radius: 50%;
`)

export const ProfitWrapper = styled(Box)(props => `
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width:400px;
  margin-bottom:10px;
  & > * {
    max-width:300px;
  }
  ${media.sm}{
    & > * {
      max-width:240px;
    }
    align-items: center;
    min-width:240px;
    & span.MuiSlider-markLabel[data-index='0'] {
      left: 0%;
      transform: none;
    }
    & span.MuiSlider-markLabel[data-index='1'] {
      left: auto !important;
      right: 0;
      transform: none;
    }
  }
`)

export const ProfitCardMedia = styled(Box)(`
  box-sizing:border-box;
  min-height:120px;
  height:120px;
  width:180px;
  overflow:hidden;
  position:relative;
  display:flex;
  justify-content: center;
  align-items: center;
  background:#dbdde5d1;
  margin-bottom:10px;
`)

export const ProfitTitle1 = styled(Typography)(`
  line-height:1;
  font-size: 16px;
  font-weight: bold;
  margin-bottom:10px;
  ${media.sm}{
    margin: 8px auto;
  }
`)

export const ProfitTitle2 = styled(Typography)(`
  line-height:1;
  font-weight: 600;
  font-size: 14px;
  color: #0EC690;
  margin-bottom:10px;
`)

export const ProfitTitle3 = styled(Box)(`
  background: #F4F7FD;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #162E9A;
  display: block;
  width: 100%;
  line-height: 30px;
  margin-bottom:10px;
  text-align:center;
`)

export const SlideWrapper = styled(Slider)(({ theme }) => `
  color: #DA2A52;
  height: 4px;
  margin: 40px 0 20px;
  padding: 15px 0;
  & span.MuiSlider-markLabel {
    font-size: 10px;
  }
  ${media.sm}{
  margin: 10px 0 20px;
  }
`)
