import { Map } from 'immutable'
import { REHYDRATE } from 'redux-persist/lib/constants'

import {
  AUTH_LOGIN_USER_SUCCESS,
  USERS_GET_INFO_SUCCESS,
  USERS_UPDATE_PROFILE_IMAGE_SUCCESS,
  PAGEBUILDER_ABOUT_FETCH_POST_DATA_SUCCESS
} from '../actions'

const initialState = new Map({
})

export const users = (state = initialState, action) => {
  const {
    pageBuilderLinksCreateData,
    data = {},
    type,
    payload = {}
  } = action
  const { idToken = '', refreshToken = '' } = data || {}
  const { email, id, name, imageUrl } = data?.userDetail || data?.userInfo || {}

  switch (type) {
    case REHYDRATE:
      return Map(payload.users || {})
    case USERS_GET_INFO_SUCCESS:
    case AUTH_LOGIN_USER_SUCCESS:
      return initialState.merge({
        idToken,
        email,
        id,
        name,
        refreshToken,
        imageUrl
      })
    case USERS_UPDATE_PROFILE_IMAGE_SUCCESS:
      return state.set('imageUrl', data)
    case PAGEBUILDER_ABOUT_FETCH_POST_DATA_SUCCESS:
      return state.set('imageUrl', pageBuilderLinksCreateData?.imageUrl)
    default:
      return state
  }
}
