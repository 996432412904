import React, { useState } from 'react'
import { Main, Imagetag, Divwraper, TextWraper, DivwraperCenter, TextWraperNoBold } from './style'
import OHI from '../../assets/images/orders_.png'
// import Button from '../../components/Button'
// import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'
import VideoModal from '../VideoModal'
import { useTranslation } from 'react-i18next'
import EventEmitter, { Events } from '../../utilities/eventEmitter'

const OrderHomePage = (props) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  // const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const videoModalClick = () => {
    EventEmitter.emit(Events.VIDEO_PLAY, { page: 'orderHome' })
  }
  return (
    <>
      <Main>
        <Divwraper>
          <Imagetag src={OHI} />
        </Divwraper>
        <TextWraper>
          {t('orderHome.noCustomersOrders')}.
        </TextWraper>
        <TextWraperNoBold>
          {t('orderHome.startReceivingText')}
        </TextWraperNoBold>
        <DivwraperCenter>
          {/* <Button
            onClick={() => {}}
            styleProps={{
              background: '#162E9A',
              height: '60px',
              paddingLeft: '100px',
              paddingRight: '100px'
            }}
          >
            <PlayArrowOutlinedIcon style={{ margin: '-6px 0px' }} />
            {t('orderHome.watchTutorial')}
          </Button> */}
        </DivwraperCenter>
        <VideoModal onClick={videoModalClick} open={open} handleClose={handleClose} />
      </Main>
    </>
  )
}

OrderHomePage.displayName = 'OrderHomePage'
export default OrderHomePage
