import { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import dropdown from '../../assets/images/chevron-down.svg'
import { useOnOutsideClick } from '../../utilities/hooks'

import {
  SelectOuterWrapper,
  DropdownIcon,
  SelectWrapper,
  SelectedValue,
  OptionsWrapper,
  Option,
  FormInput,
  FormError,
  FormLabel,
  FormField
} from './style'

const Select = ({
  name,
  error,
  label,
  options,
  onSelect,
  placeholderText = 'Show All',
  placeholderValue = null,
  color,
  selectedValue,
  setSelectedValue,
  setIsFilterApplied
}) => {
  const ref = useRef()
  const formattedOptions = [
    { label: placeholderText, value: placeholderValue || '' },
    ...options
  ]

  const [isOpen, setIsOpen] = useState(false)

  useOnOutsideClick(ref, () => setIsOpen(false))

  const handleSelect = (value) => {
    setSelectedValue(value.label)
    setIsFilterApplied(true)
    onSelect(value.value)
  }

  return (
    <SelectOuterWrapper>
      {label && <FormLabel>{label}</FormLabel>}
      <FormField>
        <SelectWrapper ref={ref} onClick={() => setIsOpen(!isOpen)} color={`${color}`}>

          <SelectedValue>
            {selectedValue || 'Select'}
            <FormInput name={name} value={selectedValue === (placeholderValue || placeholderText) ? '' : selectedValue} />
            <DropdownIcon src={dropdown} />
          </SelectedValue>
          {
            isOpen &&
              <OptionsWrapper>
                {formattedOptions.map((option, index) => {
                  return (
                    <Option
                      key={index}
                      onClick={() => handleSelect(option)}
                    >
                      {option.label}
                    </Option>
                  )
                })}
              </OptionsWrapper>
          }
        </SelectWrapper>
      </FormField>
      {error !== '' && <FormError>{error}</FormError>}
    </SelectOuterWrapper>
  )
}

PropTypes.Select = {
  name: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  placeholderText: PropTypes.string,
  placeholderValue: PropTypes.any
}

Select.displayName = 'Select'

export default Select
