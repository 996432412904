import React from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';

import { SkuId, SkuName, FilterRowMain, SelectedItemsCount, SelectWrapper, SelectLabel } from './style'
import MuiSelect from '../MuiSelect/MuiSelect'
import MinMaxInput from './MinMaxInput'

const FilterRow = ({
  filtersData,
  handleFilterValueChange,
  selectedFilterValues
}) => {
  const { t } = useTranslation()
  const { skuId } = useParams();

  const promoTypeSelectedChip = (selected) => {
    return (
      <>
        <SelectedItemsCount>{selected.length > 0 ? selected.length + ' Item' : 'All'} </SelectedItemsCount>
      </>
    )
  }

  return (
    <FilterRowMain container columns={13}>
      <Grid item md={3} sm={13} xs={13}>
      <Grid container>
        <SkuId>
          {skuId}
        </SkuId>
      </Grid>

      <Grid container>
        <SkuName>
        {filtersData.name}
        </SkuName>
      </Grid>

      </Grid>
      <Grid item md={1}>
      </Grid>
      <Grid item md={9} sm={13} xs={13}>
      <Grid container columns={8}>
      <Grid item md={2} sm={10} xs={10}>
      <SelectWrapper>
        <SelectLabel>{t('mspCompare.marketplace')}</SelectLabel>
        <MuiSelect
          name='marketplaces'
          size='small'
          placeholder={t('brandCatalouge.all')}
          onChange={(event, selectedData) => {
            handleFilterValueChange('marketplaces', [selectedData.value])
          }}
          data={filtersData.marketplace}
          selectlabel='marketplace'
          selectvalue='marketplace'
          customSelectedChip={promoTypeSelectedChip}
          sx={{ minWidth: '100px' }}
          MenuProps={{ PaperProps: { sx: { maxHeight: 300, minWidth: 300 } } }}
        />
      </SelectWrapper>
    </Grid>
      <Grid item md={2} sm={10} xs={10}>
          <MinMaxInput title={`${t('mspCompare.totalUnitSold')} (Shopee)`} filterName='totalUnitsSold' handleFilterValueChange={handleFilterValueChange} selectedFilterValues={selectedFilterValues} minValue={filtersData?.totalQuantitySold?.min} maxValue={filtersData?.totalQuantitySold?.max} />
      </Grid>

      <Grid item md={2} sm={10} xs={10}>
          <MinMaxInput title={`${t('mspCompare.monthlyUnitsSold')} (Shopee)`} filterName='monthlyUnitsSold' handleFilterValueChange={handleFilterValueChange} selectedFilterValues={selectedFilterValues} minValue={filtersData?.monthlyQuantitySold?.min} maxValue={filtersData?.monthlyQuantitySold?.max} />
      </Grid>

      <Grid item md={2} sm={10} xs={10}>
          <MinMaxInput title={`${t('mspCompare.availableStock')}`} filterName='availableStock' handleFilterValueChange={handleFilterValueChange} selectedFilterValues={selectedFilterValues} minValue={filtersData?.stock?.min} maxValue={filtersData?.stock?.max}/>
      </Grid>

      <Grid item md={2} sm={10} xs={10}>
          <MinMaxInput title={t('productDetails.price')} filterName='price' handleFilterValueChange={handleFilterValueChange} selectedFilterValues={selectedFilterValues} minValue={filtersData?.price?.min} maxValue={filtersData?.price?.max}/>
      </Grid>

      <Grid item md={2} sm={10} xs={10}>
          <MinMaxInput title={t('mspCompare.followerRange')} filterName='followerRange' handleFilterValueChange={handleFilterValueChange} selectedFilterValues={selectedFilterValues} minValue={filtersData?.followers?.min} maxValue={filtersData?.followers?.max}/>
      </Grid>

      <Grid item md={2} sm={10} xs={10}>
          <MinMaxInput title={t('mspCompare.responseRate')} filterName='responseRate' handleFilterValueChange={handleFilterValueChange} selectedFilterValues={selectedFilterValues} minValue={filtersData?.response?.min} maxValue={filtersData?.response?.max}/>
      </Grid>
      {(filtersData.shipFrom && filtersData.shipFrom.length) && (<Grid item md={2} sm={10} xs={10}>
      <SelectWrapper>
      <SelectLabel>{t('mspCompare.shipsFrom')}</SelectLabel>
      <MuiSelect
      name='shipFrom'
      size='small'
      placeholder={t('brandCatalouge.all')}
      onChange={(event, selectedData) => {
        handleFilterValueChange('shipsFrom', selectedData === 'All' || selectedData === 'Semua' ? '' : selectedData)
      }}
      dataType='string'
      data={[t('brandCatalouge.all'), ...filtersData.shipFrom]}
      customSelectedChip={promoTypeSelectedChip}
      sx={{ minWidth: '100px' }}
      MenuProps={{ PaperProps: { sx: { maxHeight: 300, minWidth: 300 } } }}
      />
    </SelectWrapper>
    </Grid>)}

    <Grid item md={2} sm={10} xs={10}>
      <SelectWrapper>
      <SelectLabel>{t('mspCompare.rating')}</SelectLabel>
      <MuiSelect
        name='rating'
        size='small'
        onChange={(event, selectedData) => {
          handleFilterValueChange('rating', [selectedData.value])
        }}
        selectlabel='rating'
        selectvalue='value'
        placeholder={t('brandCatalouge.all')}
        data={[{rating: '4.5 and above', value: 4.5}, {rating: '4 and above', value: 4}, {rating: '3 and above', value: 3}, {rating: '2 and above', value: 2}, {rating: '1 and above', value: 1}]}
        customSelectedChip={promoTypeSelectedChip}
        sx={{ minWidth: '100px' }}
        MenuProps={{ PaperProps: { sx: { maxHeight: 300, minWidth: 300 } } }}
      />
    </SelectWrapper>
    </Grid>

      </Grid>
      </Grid>
    </FilterRowMain>
  )
}

FilterRow.displayName = 'BrandPromoFilterRowComponent'
export default FilterRow
