import React, { useEffect, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { ChartLegendWrapper, ChartLegend, LegendColorBox, BarToolTipWrapper, BarToolTipLabel, BarToolTipPaper, BarToolTipValue, BarToolTipQTY, BarToolTipLabelValDiv, BarToolTipValueWrapper, BarToolTipQTYAndChange } from './style';
import { numberFormatter, numberWithCommas } from '../../utilities/utils';

const MonthlyRevenueBarChart = (props) => {
  const { barData, isDefault } = props;
  const [barChart, setBarChart] = useState([]);

  const { t, i18n } = useTranslation()
  const CustomizedTick = (props) => {
    const {x, y, fill, payload} = props;
      return <>
      <title>{payload.value}</title>
      <text 
        x={x-50} 
        y={y+5} 
        fontSize='14'
        fill={fill}
        textAnchor="start">
        {numberFormatter(payload.value, i18n)}
      </text>
      </>
  }

  const XAxisCustomizedTick = (props) => {
    const {x, y, payload, fill} = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text
              fontSize='14' 
              y={15}
              fill={fill}
              textAnchor="middle"
              >{payload.value}</text>
        </g>
    );
  }

  const CustomTooltip = ({ payload }) => {
    if(!payload || !payload.length){
      return ''
    }
  
    return <Box><BarToolTipPaper elevation={3}>
    <BarToolTipWrapper>  
    <BarToolTipLabelValDiv>
      <BarToolTipLabel>{t('brandDashboard.totalRevenue')}: </BarToolTipLabel>
      <BarToolTipValueWrapper>
        <BarToolTipValue color={payload[0].color}>Rp. {numberWithCommas(payload[0].payload.totalRevenue)} </BarToolTipValue>
      </BarToolTipValueWrapper>
      <BarToolTipQTYAndChange>{t('brandDashboard.qty')}: {numberWithCommas(payload[0].payload.totalUnitSold)}</BarToolTipQTYAndChange>
      </BarToolTipLabelValDiv>
      <BarToolTipLabelValDiv>
      <BarToolTipLabel>{t('brandDashboard.revenueFromPromo')}: </BarToolTipLabel>
      <BarToolTipValueWrapper>
        <BarToolTipValue color={payload[1].color}>Rp. {numberWithCommas(payload[0].payload.revenueFromPromo)} </BarToolTipValue>
        </BarToolTipValueWrapper>
        <BarToolTipQTYAndChange>{t('brandDashboard.qty')}: {numberWithCommas(payload[0].payload.promoUnitSold)} </BarToolTipQTYAndChange>
      </BarToolTipLabelValDiv>
      </BarToolTipWrapper>
    </BarToolTipPaper> </Box>
  }

  useEffect(()=>{
    if(barData){
      const list = barData.map((item)=>({name: item.label, totalRevenue: item?.data?.nonPromo?.revenue, totalUnitSold: item?.data?.nonPromo?.unitsSold, totalRevenueChange: item?.data?.nonPromo?.change?.value, revenueFromPromo: item?.data?.promo?.revenue, promoUnitSold: item?.data?.promo?.unitsSold, promoRevenueChange: item?.data?.promo?.change?.value }));  
      setBarChart(list);
    }
  }, [barData]);

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ChartLegendWrapper>
        <>
        {
          payload.map((entry, index) => (
            <ChartLegend key={index}><LegendColorBox backgroundColor={index ===0 ? "#4880FF" : "#1CE2AF"} ></LegendColorBox>{t(`brandDashboard.${entry.value}`)} (Rp)</ChartLegend>
          ))
        }
        </>
      </ChartLegendWrapper>
    );
  }

  return (
    <ResponsiveContainer>
      <BarChart
        barCategoryGap={isDefault ? 15 : 5}
        width={700}
        style={{ overflowY: 'hidden' }}
        data={barChart}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 16
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis tick={<XAxisCustomizedTick />} dataKey='name' type='category' />
        <YAxis tick={<CustomizedTick />} />
        <Tooltip content={<CustomTooltip/>} />
        <Legend verticalAlign="bottom" height={36} content={renderLegend}/>
        <Bar dataKey='totalRevenue' fill='#4880FF' />
        <Bar dataKey='revenueFromPromo' fill='#1CE2AF' />
      </BarChart>
    </ResponsiveContainer>
  )
}

MonthlyRevenueBarChart.displayName = 'MonthlyRevenueBarChartComponent'
export default MonthlyRevenueBarChart
