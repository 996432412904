// import styled from 'styled-components'
import { styled } from '@mui/system'
import { Tooltip } from '@mui/material'
const media = {
  xl: '@media(max-width: 2560px)',
  lg: '@media(max-width: 1500px)',
  slg: '@media(max-width: 1000px)',
  md: '@media(max-width: 850px)',
  sm: '@media(max-width: 500px)'
}
export const ProductCardWrapper = styled('div')`
  background: #FFFFFF;
  display: flex;
  width: 220px;
  margin: 10px 10px;
  flex-direction: column;
  flex-basis: 0 0 100%;
  padding: 10px;
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  cursor: pointer;
  ${media.xl} {
    width: 22vw;
    margin: 10px;
  }
  ${media.lg} {
    width: 20vw;
    margin: 10px;
  }
  ${media.slg} {
    width: 30vw;
    margin: 10px;
  }
  ${media.md} {
    width: 30vw;
    margin: 10px;
  }
  ${media.sm} {
    width: 80vw;
    margin: 10px;
  }
`

export const ProductDetails = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`

// export const ProductImageWrapper = styled('div')`
//   display: flex;
//   /* background: #f2f2f2; */
//   justify-content: center;
// `

export const ProductName = styled('h3')`
color: #33333A;
font-size: 16px;
font-weight: 500;
text-align: left;
height: 30px;
word-break: break-all;
${media.md} {
  font-size: 14px;
}
`

export const ProductPrice = styled('h3')`
color: #62A51A;
font-size: 18 px;
font-weight: 500;
text-align: left;
margin: 0
`

export const ProductRsp = styled('h4')`
color: #727272;
font-size: 14 px;
font-weight: 500;
text-align: left;
`

export const ProductImage = styled('img')`
  height: 20vh;
  width: 100%;
  object-fit: cover;
  display: flex;
    flex-direction: column;
    align-items: center;
    & .autoPlaySwipeableViews{
        position:relative;
        padding:20px 0;
    }

    & .autoPlaySwipeableViews:before{
        content:'';
        width:55px;
        height:40px;
        position: absolute;
        top: 0;
        left: 0;
    }

    & .autoPlaySwipeableViews:after{
        content:'';
        width:55px;
        height:40px;
        position: absolute;
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
    }

    & .mobileStepper {
        background:transparent;
        padding-top:30px;
    }
    & .mobileStepper .MuiMobileStepper-dot{
        margin: 0 5px;
    }
`

export const StoreImage = styled('img')`
  height: 35px;
  width: 35px;
`
export const StoreInfo = styled('div')`
  display: flex;
  flex-direction: row;
  flex-basis: 0 0 33.33%;
  align-items: center;
  @media (max-width: 425px) {
    margin-bottom: 10px;
  }
`

export const StoreName = styled('div')`
  font-size: 16px;
  font-weight: 400;
  color: #360D20;
  margin-left: 5px
`
export const ProductStatus = styled('div')`
  font-size: 14px;
  padding-top: 5px;
  line-height: 30px;
  font-style: normal;
  font-weight: 500;
  color:#${({ status }) => status === 'Published' ? '32cd32' : 'fec20c'};
`
export const StockCount = styled('div')`
  font-size: 14px;
  padding-top: 5px;
  line-height: 30px;
  font-style: normal;
  font-weight: 500;
  color: #33333A;
`

export const ProductImageWrapper = styled('div')`
.slide-container {
  width:'60%';
  margin: auto;
  height: 100px !important;
}

&h3 {
  text-align : center;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.indicator {
  width: 30px;
  color: blue;
  text-align: center;
  cursor: pointer;
  border: 1px blue solid;
}
.indicator.active {
  background: #000;
  color: #fff;
}
img{
    height: 25vh;
    width: 100%;
    object-fit: cover;
}
.react-slideshow-container .nav{
  z-index: 1;
}
.react-slideshow-container .default-nav{
  height: 25px;
  width: 25px;
}
.react-slideshow-container .default-nav:first-of-type{
  margin-right: -25px;
}
.react-slideshow-container .default-nav:last-of-type{
  margin-left: -25px;
}
`
export const TooltipWraper = styled(Tooltip)`
`
