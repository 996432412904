import styled from 'styled-components'

export const InfoCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 15px;
  margin-bottom: 30px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 512px) {
    grid-template-columns: 1fr;
  }
`

export const SingleInfoCardWrapper = styled.div`
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
`

export const Header = styled.div`
  font-size: ${props => props.isSales ? '18px' : '24px'};
  font-weight: 600;
`

export const Content = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: ${props => props.isSales ? '6px' : '2px'};
`

export const LeftSideContent = styled.div`

`

export const RightSideContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 8px;
`
