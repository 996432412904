import React from 'react'
import { Container, Grid } from '@mui/material'
import {
  BannerWrapper,
  BannerLhsWrapper,
  BannerRhsWrapper,
  BannerLhsImage,
  TagTitle
} from './style'

const MarketPlaceCard = (props) => {
  const { content } = props
  return (
    <BannerWrapper>
      <Container>
        <Grid container direction='row' justifyContent='space-around' alignItems='stretch'>
          {content?.rhsImage &&
            <BannerLhsWrapper>
              <BannerLhsImage src={content?.rhsImage?.image} alt={content?.rhsImage?.alt} />
            </BannerLhsWrapper>}

          {content?.lhsData &&
            <BannerRhsWrapper>
              {content?.lhsData.map((list, index) => (
                <TagTitle key={index} primary={list.heading} secondary={list.content} />
              ))}
            </BannerRhsWrapper>}
        </Grid>
      </Container>
    </BannerWrapper>
  )
}

export default MarketPlaceCard
