import { Map } from 'immutable'

import {
  AUTH_GET_OTP_SUCCESS,
  AUTH_GET_OTP_FAILURE,
  AUTH_LOADING,
  AUTH_VERIFY_OTP_SUCCESS,
  AUTH_VERIFY_OTP_FAILURE,
  AUTH_SET_PERSONAL_PROFILE_SUCCESS,
  AUTH_SET_PERSONAL_PROFILE_FAILURE,
  AUTH_LOGIN_MOUNT,
  AUTH_LOGIN_USER_SUCCESS,
  AUTH_LOGIN_USER_FAILURE,
  USERS_GET_INFO_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_LOADING,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
} from '../actions'

const initialState = new Map({
  error: '',
  email: '',
  isExistingUser: false,
  isLoading: false,
  isLoadingForgotPassword: false,
  isOTPSent: false,
  isOTPVerified: false,
  isProfileSet: false,
  forgotPasswordSuccess: false,
  resetPasswordSuccess: false,
  mobile: '',
  name: '',
  token: '',
  idToken: '',
  refreshToken: '',
  userId: '',
  shareId: '',
  tierId: ''
})

export const auth = (state = initialState, action) => {
  const {
    data,
    isLoading,
    isLoadingForgotPassword,
    isLoadingResetPassword,
    message,
    type
  } = action

  switch (type) {
    case AUTH_LOADING:
      return state.set('isLoading', isLoading)
    case FORGOT_PASSWORD_LOADING:
      return state.set('isLoadingForgotPassword', isLoadingForgotPassword)
    case RESET_PASSWORD_LOADING:
      return state.set('isLoadingResetPassword', isLoadingResetPassword)
    case AUTH_GET_OTP_SUCCESS:
      return state.merge({
        email: data?.email,
        isExistingUser: data?.isExistingUser || false,
        isOTPSent: true,
        mobile: data?.mobile || '',
        name: data?.name
      })
    case AUTH_GET_OTP_FAILURE:
    case AUTH_VERIFY_OTP_FAILURE:
    case AUTH_SET_PERSONAL_PROFILE_FAILURE:
    case AUTH_LOGIN_USER_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
    case RESET_PASSWORD_FAILURE:
      return state.set('error', message)
    case AUTH_VERIFY_OTP_SUCCESS:
      return state.merge({
        isOTPVerified: true,
        token: data?.tokenDetail?.idToken,
        userId: data?.userDetail?.id
      })
    case AUTH_SET_PERSONAL_PROFILE_SUCCESS:
      return state.set('isProfileSet', true)
    case AUTH_LOGIN_MOUNT:
      return initialState
    case RESET_PASSWORD_SUCCESS:
      return state.set('resetPasswordSuccess', data)
    case FORGOT_PASSWORD_SUCCESS:
      return state.set('forgotPasswordSuccess', data)
    case USERS_GET_INFO_SUCCESS:
    case AUTH_LOGIN_USER_SUCCESS: {
      const { name, shareId } = data?.userDetail || data?.userInfo || {}
      localStorage.setItem('idToken', data.idToken)
      localStorage.setItem('refreshToken', data.refreshToken)
      if (name) {
        localStorage.setItem('userName', name)
      }
      return state.merge({
        tierId: data?.userDetail?.tierId,
        idToken: data?.idToken,
        shareId,
        refreshToken: data?.refreshToken
      })
    }
    default:
      return state
  }
}
