import { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import MuiSelect from '../MuiSelect'
import MuiTextField from '../MuiTextField'
import MuiDatePicker from '../MuiDatePicker'

import {
  FormControlCol3,
  FormControl,
  SectionTitle,
  SectionContent,
  MuiTextFieldWithSelect
} from './style'

const ProductAttribute = forwardRef((props, ref) => {
  const {
    formik,
    categorySelectedValue,
    categoryAttributesList,
    isAddToStore,
    title,
    validateOnBlur,
    handleFormOnChange,
    attributeListValues
  } = props

  useImperativeHandle(ref, () => ({
    validateAttributesFunc () {
      return validation()
    },
    getAttributesDataFunc () {
      return attributePostData
      // This is a temporary fix for demo, please revert to a more generic logic
      // return attributesList
      //   .map(aL => ({ attribute_id: aL.attributeId, attribute_value_list: aL.attribute_value_list }))
      //   .filter(aL => aL.attribute_value_list)
    }
  }))

  const [attributesList, setAttributesList] = useState([])
  const [attributesListLength, setAttributesListLength] = useState(0)

  const [attributePostData, setAttributePostData] = useState([])

  const validation = () => {
    const errors = {}
    attributesList.map(a => {
      if (a.isMandatory) {
        if (!a.attribute_value_list_new || a.attribute_value_list_new?.[0]?.displayValueName === '') {
          errors['name_' + a.attributeId] = `${a.displayAttributeName} is required`
        }
      }
      return a
    })
    formik.errors = errors
    return errors
  }

  const createPostAttributeDataFunc = (array) => {
    const attriPostData = []

    array.map(attri => {
      let object = {}
      if (attri.inputType === 'TEXT_FILED' && attri.attributeUnit?.length === 0 && attri.inputValidationType === 'STRING_TYPE') {
        object = {
          valueId: 0,
          originalValueName: attri.selectedAttributeValueInput || '',
          displayValueName: attri.selectedAttributeValueInput || ''
        }
        attri.attribute_value_list_new = [object]
      } else if (attri.inputType === 'TEXT_FILED' && attri.attributeUnit?.length === 0 && attri.inputValidationType === 'FLOAT_TYPE') {
        object = {
          valueId: 0,
          originalValueName: attri.selectedAttributeValueInput || '',
          displayValueName: attri.selectedAttributeValueInput || ''
        }
        attri.attribute_value_list_new = [object]
      } else if (attri.inputType === 'TEXT_FILED' && attri.attributeUnit?.length === 0 && attri.inputValidationType === 'DATE_TYPE') {
        object = {
          valueId: 0,
          originalValueName: attri.selectedAttributeValueInput || '',
          displayValueName: attri.selectedAttributeValueInput || ''
        }
        attri.attribute_value_list_new = [object]
      } else if (attri.inputType === 'TEXT_FILED' && attri.attributeUnit?.length > 0) {
        object = {
          valueId: 0,
          originalValueName: attri.selectedAttributeValueInput || '',
          displayValueName: attri.selectedAttributeValueInput || '',
          valueUnit: attri.selectedAttributeValueSelect || ''
        }
        attri.attribute_value_list_new = [object]
      } else if (attri.inputType === 'COMBO_BOX' || attri.inputType === 'DROP_DOWN' || attri.inputType === 'MULTIPLE_SELECT') {
        object = {
          valueId: attri.selectedAttributeValueSelect?.value || '',
          originalValueName: attri.attributeValueList.filter(a => a.valueId === attri.selectedAttributeValueSelect?.value)?.[0]?.originalValueName || '',
          displayValueName: attri.attributeValueList.filter(a => a.valueId === attri.selectedAttributeValueSelect?.value)?.[0]?.displayValueName || ''
        }
        if (attri.formatType === 'QUANTITATIVE') {
          object.valueUnit = (object.displayValueName).replace(/[0-9]/g, '')
        }

        attri.attribute_value_list_new = [object]
      } else if (attri.inputType === 'MULTIPLE_SELECT_COMBO_BOX') {
        attri.attribute_value_list_new = attri.selectedAttributeValueSelect || []
      }

      if (object.originalValueName) {
        attriPostData.push({
          attribute_id: attri.attributeId,
          attribute_value_list: attri.attribute_value_list_new
        })
      }

      return attri
    })

    setAttributePostData(attriPostData)
    setAttributesList(array)
    if (validateOnBlur) { validation() }
    handleFormOnChange()
  }

  useEffect(() => {
    setAttributesList(categoryAttributesList)
  }, [categoryAttributesList])

  useEffect(() => {
    // Initial Value
    if (attributesList.length !== attributesListLength) {
      setAttributesListLength(categoryAttributesList.length)
      const attributeListObject = {}
      attributesList.map(attribute => {
        // attribute.attributeKey = `attribute_${(attribute.originalAttributeName.replaceAll(' ', '_').replaceAll('.', '').toLowerCase())}`
        attribute.attributeKey = `name_${attribute.attributeId}`
        attribute.selectedAttributeValueInput = ''
        attribute.selectedAttributeValueSelect = (attribute.inputType === 'COMBO_BOX' || attribute.inputType === 'DROP_DOWN' || attribute.inputType === 'MULTIPLE_SELECT') ? {} : (attribute.inputType === 'MULTIPLE_SELECT_COMBO_BOX') ? [] : ''
        attribute.attribute_value_list_new = []
        attributeListObject[attribute.attributeKey] = ''

        const editVal = attributeListValues?.find(v => v?.attributeId === attribute?.attributeId)
        if (editVal?.attributeValueList) {
          if (attribute.inputType === 'TEXT_FILED' && attribute.attributeUnit.length === 0) {
            attribute.selectedAttributeValueInput = editVal?.attributeValueList?.[0]?.valueUnit || ''
          } else if (attribute.inputType === 'TEXT_FILED' && attribute.attributeUnit.length > 0) {
            attribute.selectedAttributeValueInput = editVal?.attributeValueList?.[0]?.originalValueName || ''
            attribute.selectedAttributeValueSelect = editVal?.attributeValueList?.[0]?.valueUnit || ''
          } else if (attribute.inputType === 'COMBO_BOX' || attribute.inputType === 'DROP_DOWN' || attribute.inputType === 'MULTIPLE_SELECT') {
            attribute.selectedAttributeValueSelect = {
              value: editVal?.attributeValueList?.[0]?.valueId || '',
              label: editVal?.attributeValueList?.[0]?.originalValueName || ''
            }
          } else if (attribute.inputType === 'MULTIPLE_SELECT_COMBO_BOX') {
            attribute.selectedAttributeValueSelect = editVal?.attributeValueList.map(e => {
              return {
                valueId: e?.valueId || '',
                originalValueName: e?.originalValueName || ''
              }
            })
          }
        }

        return attribute
      })

      setAttributesList(attributesList)

      createPostAttributeDataFunc(attributesList)
    }
  }, [attributesList])

  const attributeListHandleChange = (event, resData, data) => {
    let selectedAttributeValueInput = null
    let selectedAttributeValueSelect = null
    if (data.inputType === 'COMBO_BOX' || data.inputType === 'DROP_DOWN' || data.inputType === 'MULTIPLE_SELECT') {
      selectedAttributeValueSelect = resData
    } else if (data.inputType === 'MULTIPLE_SELECT_COMBO_BOX') {
      selectedAttributeValueSelect = resData
    } else if (data.inputType === 'TEXT_FILED' && data.inputValidationType === 'DATE_TYPE' && data.attributeUnit.length === 0) {
      selectedAttributeValueInput = new Date(event).getTime().toString()
    } else if (data.inputType === 'TEXT_FILED' && data.inputValidationType === 'STRING_TYPE' && data.attributeUnit.length === 0) {
      selectedAttributeValueInput = event.target.value
    } else if (data.inputType === 'TEXT_FILED' && data.inputValidationType === 'FLOAT_TYPE' && data.attributeUnit.length === 0) {
      selectedAttributeValueInput = event.target.value
    } else if (data.inputType === 'TEXT_FILED' && data.attributeUnit.length > 0) {
      if (data.attributeUnit.includes(event.target.value)) {
        selectedAttributeValueSelect = event.target.value
        selectedAttributeValueInput = data.selectedAttributeValueInput
      } else {
        selectedAttributeValueInput = event.target.value
        selectedAttributeValueSelect = data.selectedAttributeValueSelect
      }
    }

    const categoryAttributesListData = attributesList.map(attribute => {
      if (attribute.attributeId === data.attributeId) {
        const selectedValue = attribute?.attributeValueList?.find(a => (a.valueId === selectedAttributeValueInput?.value || a.valueId === selectedAttributeValueSelect?.value))
        attribute.selectedAttributeValueInput = selectedAttributeValueInput
        attribute.selectedAttributeValueSelect = selectedAttributeValueSelect
        attribute.attribute_value_list = [selectedValue]
      }
      return attribute
    })

    setAttributesList(categoryAttributesListData)

    createPostAttributeDataFunc(categoryAttributesListData)
  }

  return (
    <>
      {categorySelectedValue && attributesList && attributesList.length > 0 &&
        <>
          {title &&
            <SectionContent>
              <FormControl>
                <SectionTitle>{title}</SectionTitle>
              </FormControl>
            </SectionContent>}

          <SectionContent>
            {
              attributesList && attributesList.length > 0 && attributesList.map((data, index) => {
                // Temporary change
                // if (!data.isMandatory) return null
                const attributeId = `name_${data.attributeId}`
                return (
                  <FormControlCol3 key={index} isAddToStore={isAddToStore}>
                    {
                      (data.inputType === 'COMBO_BOX' || data.inputType === 'DROP_DOWN' || data.inputType === 'MULTIPLE_SELECT') &&
                        <MuiSelect
                          validate={() => false}
                          placeholder='Select'
                          title={data.displayAttributeName}
                          type={data.inputType}
                          size='small'
                          value={data?.selectedAttributeValueSelect?.value || ''}
                          error={formik.errors[attributeId]}
                          name={attributeId}
                          onChange={(e, resData) => attributeListHandleChange(e, resData, data)}
                          data={data.attributeValueList}
                          selectlabel='displayValueName'
                          selectvalue='valueId'
                          isRequired={data.isMandatory}
                        />
                    }
                    {
                      data.inputType === 'MULTIPLE_SELECT_COMBO_BOX' &&
                        <MuiSelect
                          placeholder='Select'
                          title={data.displayAttributeName}
                          type={data.inputType}
                          multiline
                          size='small'
                          value={data?.selectedAttributeValueSelect || []}
                          name={attributeId}
                          onChange={(e, resData) => attributeListHandleChange(e, resData, data)}
                          data={data.attributeValueList}
                          selectlabel='displayValueName'
                          selectvalue='valueId'
                          isRequired={data.isMandatory}
                        />
                    }
                    {
                      data.inputType === 'TEXT_FILED' && data.inputValidationType === ('STRING_TYPE' || 'FLOAT_TYPE') && data.attributeUnit.length === 0 &&
                        <MuiTextField
                          title={data.displayAttributeName}
                          error={Boolean(formik.touched[attributeId] && formik.errors[attributeId])}
                          helperText={formik.touched[attributeId] && formik.errors[attributeId]}
                          fullWidth
                          margin='normal'
                          name={attributeId}
                          onBlur={formik.handleBlur}
                          onChange={e => attributeListHandleChange(e, {}, data)}
                          type='text'
                          value={data.selectedAttributeValueInput}
                          variant='outlined'
                          size='small'
                          placeholder={`Enter ${data.displayAttributeName}`}
                          isRequired={data.isMandatory}
                          dataType={data.inputValidationType === 'FLOAT_TYPE' ? 'number' : ''}
                        />
                    }
                    {
                      data.inputType === 'TEXT_FILED' && data.attributeUnit.length > 0 &&
                        <MuiTextFieldWithSelect>
                          <MuiTextField
                            title={data.displayAttributeName}
                            error={Boolean(formik.touched[attributeId] && formik.errors[attributeId])}
                            helperText={formik.touched[attributeId] && formik.errors[attributeId]}
                            fullWidth
                            margin='normal'
                            name={attributeId}
                            onBlur={formik.handleBlur}
                            onChange={e => attributeListHandleChange(e, {}, data)}
                            type='text'
                            value={data.selectedAttributeValueInput}
                            variant='outlined'
                            size='small'
                            placeholder={`Enter ${data.displayAttributeName}`}
                            isRequired={data.isMandatory}
                            dataType={data.inputValidationType === 'FLOAT_TYPE' ? 'number' : ''}
                          />
                          <MuiSelect
                            validate={() => false}
                            placeholder='Select'
                            type='COMBO_BOX'
                            size='small'
                            value={data.selectedAttributeValueSelect}
                            error={formik.errors[`${attributeId}_select`]}
                            name={`${attributeId}_select`}
                            onChange={(e, resData) => attributeListHandleChange(e, resData, data)}
                            data={data.attributeUnit}
                            dataType='string'
                            isRequired={data.isMandatory}
                          />
                        </MuiTextFieldWithSelect>
                    }
                    {
                      (data.inputType === 'TEXT_FILED' && data.inputValidationType === 'DATE_TYPE') &&
                        <MuiDatePicker
                          error={false}
                          title={data.displayAttributeName}
                          name={attributeId}
                          value={new Date(parseInt(data.selectedAttributeValueInput, 10))}
                          onChange={e => attributeListHandleChange(e, {}, data)}
                          isRequired={data.isMandatory}
                        />
                    }
                  </FormControlCol3>
                )
              })
            }
          </SectionContent>
        </>}
    </>
  )
})

export default ProductAttribute
