import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  ModalWrapper,
  Overlay,
  Popup,
  CloseWrap
} from './style'

const Modal = ({ children, showModal = false, toggleModal, PopupComponent = Popup, showClose = false, onClose }) => {
  const closeModal = () => {
    showModal = false
    onClose()
  }
  return (
    <ModalWrapper showModal={showModal}>
      <Overlay onClick={toggleModal} />
      <PopupComponent>
        {showClose && <CloseWrap onClick={closeModal}><IconButton color='primary'><CloseIcon /></IconButton></CloseWrap>}
        {children}
      </PopupComponent>
    </ModalWrapper>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
}

Modal.displayName = 'Modal'

export default Modal
