import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Main, Imagetag, Divwraper, TextWraper, DivwraperCenter, DivBack, TextWraperlight, TextHeading, LiItem } from './style'
import OHI from '../../assets/images/boxopen.png'
import Button from '../../components/Button'
import { AddFromRaenaCatalog } from '../../constants'

const ProductHome = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const { btnClick } = props

  const onGoToRaenaCatalog = async () => {
    setSearchParams({ tab: AddFromRaenaCatalog })
  }
  return (
    <>
      <Main>
        <Divwraper>
          <Imagetag src={OHI} />
        </Divwraper>
        <TextWraper>
          {t('productsHome.noProductsText')}.
        </TextWraper>
        <TextWraperlight>
          {t('productsHome.getStartedText')}
        </TextWraperlight>
        <DivwraperCenter>
          <Button variant='producthomeCatelog' onClick={onGoToRaenaCatalog}>{t('productsHome.chooseFromText')}</Button>
          <Button variant='producthomeAddnewProduct' onClick={btnClick}>{t('productsHome.addNewProduct')}</Button>
        </DivwraperCenter>
        <DivBack>
          <TextHeading>{t('productsHome.whyChooseText')}?</TextHeading>
          <LiItem>{t('productsHome.oneClickText')}</LiItem>
          <LiItem>{t('productsHome.100PlusText')}</LiItem>
          <LiItem>{t('productsHome.weManageText')}</LiItem>
          <LiItem>{t('productsHome.customerCare24x7')}</LiItem>
        </DivBack>
      </Main>
    </>
  )
}

ProductHome.displayName = 'ProductHome'
export default ProductHome
