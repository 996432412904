import styled from 'styled-components'

const media = {
  xl: '@media(max-width: 2560px)',
  lg: '@media(max-width: 1500px)',
  md: '@media(max-width: 850px)',
  sm: '@media(max-width: 600px)'
}
export const OrderWrapper = styled.div`
  margin: 20px 37px;
  padding: 23px 3rem 3.5rem 46px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(136, 150, 203, 0.121312);
  border-radius: 10px;
  @media (max-width: 425px) {
    margin: 0px;
    padding: .5rem;
  }
`

export const OrderTopBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Title = styled.div`
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 700;
  letter-spacing: 0.48px;
  line-height: 33px;
  font-size: 24px;
`

export const FilterOptions = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
`

export const SelectWrapper = styled.div`
  margin-bottom: 25px;
  
  ${media.sm}{
    margin-left: 10px;
    margin-top: 5px;
  }
`

export const OrderToolbar = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  align-items: center;
  width: 100%;
  grid-gap: 10px;
  margin: 1rem 0;

  ${media.sm}{
    display: block;
    height: 120px;
    margin-bottom: 60px;
  }
`

export const OrderLabelSelectorWrapper = styled.div`
  margin: 1.25rem 0;
`

export const Popup = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;
  position: relative;
  background:#F9F9F9;  
  max-height: 100vh;
  margin-left: 60%;
  width: 100%;
  @media (max-width: 768px) {
    height:auto;
    margin-left: 0%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 30%;
  }  
`

export const EmptyTable = styled.div`
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
  font-weight: 500;
`

export const PaginationParent = styled.div`
  font-weight: 500;
  cursor : pointer;
  float: right;
  color: #889995;
`
