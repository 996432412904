import styled from 'styled-components'

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  width: 40%;
  margin-bottom: 2vh;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  background-color: #F4F7FD;
  @media (max-width: 425px) {
  width:85%;
  }
`

export const SearchIcon = styled.img`
  margin: 0 1rem;
  position: sticky;
`

export const SearchInput = styled.input`
  border: none;
  width: 100%;
  min-height: 40px;
  min-width: fit-content;
  /* padding: 0 0 0 2.5rem; */
  background: transparent;
  &:focus-visible {
    outline:none !important;
  }
`
