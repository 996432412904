import React from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route
} from 'react-router-dom'

import Homepage from './containers/Homepage'
import Dashboard from './containers/Dashboard'
import Login from './containers/Login'
import SignUp from './containers/SignUp'
import ForgotPassword from './containers/ForgotPassword'
import ResetPassword from './containers/ResetPassword'
import Connections from './containers/Connections'
import { Orders } from './containers/Orders'
import { PageBuilder, PageBuilderRaenaProducts, PageBuilderRaenaProductsAdd } from './containers/PageBuilder'
import Analytics from './containers/Analytics'
import { ProductList, ProductCreate, ProductEdit, ProductHome } from './containers/Products'
import ComingSoon from './containers/ComingSoon'
import BrandDashboard from './containers/BrandDashboard'
import BrandCatalouge from './containers/BrandCatalouge'
import BrandPromo from './containers/BrandPromo'
import CreatePromo from './containers/Promo'
import BrandsMspCompare from './containers/BrandsMspCompare'
import BrandsMspDetails from './containers/BrandsMspDetails'


import {
  isLoggedIn,
  isBrandUser
} from './utilities/app'

import {
  ROUTE_HOMEPAGE,
  ROUTE_DASHBOARD,
  ROUTE_ANALYTICS,
  ROUTE_CONNECTIONS,
  ROUTE_LOGIN,
  ROUTE_SIGNUP,
  ROUTE_ORDERS,
  ROUTE_PRODUCTS_HOME,
  ROUTE_PRODUCTS,
  ROUTE_PRODUCTS_CREATE,
  ROUTE_COMING_SOON,
  ROUTE_PRODUCTS_EDIT,
  ROUTE_LINKSTORE,
  ROUTE_LINKSTORE_RAENAPRODUCTS,
  ROUTE_LINKSTORE_RAENAPRODUCTS_ADD,
  ROUTE_BRANDS_DASHBOARD,
  ROUTE_BRANDS_CATALOUGE,
  ROUTE_BRANDS_PROMO,
  ROUTE_PAGE_BUILDER,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_RESET_PASSWORD,
  ROUTE_CREATE_PROMO,
  MSP_COMPARE,
  MSP_DETAILS
} from './constants'

const PrivateRoute = ({ children }) => {
  const isAuthenticated = isLoggedIn()
  return isAuthenticated ? children : <Navigate to={ROUTE_LOGIN} />
}
const BrandsRoute = ({ children }) => {
  const _isBrandUser = isBrandUser()
  return _isBrandUser ? children : <Navigate to={ROUTE_CONNECTIONS} />
}
const ResellerRoute = ({ children }) => {
  const _isBrandUser = isBrandUser()
  return !_isBrandUser ? children : <Navigate to={ROUTE_BRANDS_DASHBOARD} />
}
const AuthRoute = ({ children }) => {
  const isAuthenticated = isLoggedIn()
  return isAuthenticated ? (isBrandUser() ? <Navigate to={ROUTE_BRANDS_DASHBOARD} /> : <Navigate to={ROUTE_CONNECTIONS} />) : children
}

const WebRoutes = () => {
  // Ref- https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
  return (
    <Router>
      <Routes>
        <Route path={ROUTE_HOMEPAGE} element={<Homepage />} />
        <Route path={ROUTE_DASHBOARD} element={<ResellerRoute><PrivateRoute><Dashboard /></PrivateRoute></ResellerRoute>} />
        <Route path={ROUTE_SIGNUP} element={<AuthRoute><SignUp /></AuthRoute>} />
        <Route path={ROUTE_LOGIN} element={<AuthRoute><Login /></AuthRoute>} />
        <Route path={ROUTE_FORGOT_PASSWORD} element={<AuthRoute><ForgotPassword /></AuthRoute>} />
        <Route path={ROUTE_RESET_PASSWORD} element={<AuthRoute><ResetPassword /></AuthRoute>} />
        <Route path={ROUTE_CONNECTIONS} element={<ResellerRoute><PrivateRoute><Connections /></PrivateRoute></ResellerRoute>} />
        <Route path={ROUTE_ANALYTICS} element={<ResellerRoute><PrivateRoute><Analytics /></PrivateRoute></ResellerRoute>} />
        <Route path={ROUTE_ORDERS} element={<ResellerRoute><PrivateRoute><Orders /></PrivateRoute></ResellerRoute>} />
        <Route path={ROUTE_PRODUCTS_HOME} element={<ResellerRoute><PrivateRoute><ProductHome /></PrivateRoute></ResellerRoute>} />
        <Route path={ROUTE_PRODUCTS} element={<ResellerRoute><PrivateRoute><ProductList /></PrivateRoute></ResellerRoute>} />
        <Route path={ROUTE_PRODUCTS_CREATE} element={<ResellerRoute><PrivateRoute><ProductCreate /></PrivateRoute></ResellerRoute>} />
        <Route path={ROUTE_PRODUCTS_EDIT} element={<ResellerRoute><PrivateRoute><ProductEdit /></PrivateRoute></ResellerRoute>} />
        <Route path={ROUTE_LINKSTORE} element={<ResellerRoute><PrivateRoute><PageBuilder /></PrivateRoute></ResellerRoute>} />
        <Route path={ROUTE_LINKSTORE_RAENAPRODUCTS} element={<ResellerRoute><PrivateRoute><PageBuilderRaenaProducts /></PrivateRoute></ResellerRoute>} />
        <Route path={ROUTE_LINKSTORE_RAENAPRODUCTS_ADD} element={<ResellerRoute><PrivateRoute><PageBuilderRaenaProductsAdd /></PrivateRoute></ResellerRoute>} />
        {/* This is used in the mobile app */}
        <Route path={ROUTE_PAGE_BUILDER} element={<ResellerRoute><PrivateRoute><PageBuilder /></PrivateRoute></ResellerRoute>} />
        <Route path={ROUTE_COMING_SOON} element={<ComingSoon />} />
        <Route path={ROUTE_BRANDS_DASHBOARD} element={<BrandsRoute><PrivateRoute><BrandDashboard /></PrivateRoute></BrandsRoute>} />
        <Route path={ROUTE_BRANDS_CATALOUGE} element={<BrandsRoute><PrivateRoute><BrandCatalouge /></PrivateRoute></BrandsRoute>} />
        <Route path={ROUTE_BRANDS_PROMO} element={<BrandsRoute><PrivateRoute><BrandPromo /></PrivateRoute></BrandsRoute>} />
        <Route path={MSP_COMPARE} element={<BrandsRoute><PrivateRoute><BrandsMspCompare /></PrivateRoute></BrandsRoute>} />
        <Route path={MSP_DETAILS} element={<BrandsRoute><PrivateRoute><BrandsMspDetails /></PrivateRoute></BrandsRoute>} />
        <Route path={ROUTE_CREATE_PROMO} element={<BrandsRoute><PrivateRoute><CreatePromo /></PrivateRoute></BrandsRoute>} />
        <Route path='*' element={<Navigate replace to={ROUTE_LOGIN} />} />
      </Routes>
    </Router>
  )
}

WebRoutes.displayName = 'WebRoutes'
export default WebRoutes
