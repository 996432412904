import React from 'react'
import PropTypes from 'prop-types'
import { ConnectionModalData } from './_mock_'
import { SLUG_SHOPEE } from '../../constants'
import CloseIcon from '@mui/icons-material/Close'
import {
  Overlay,
  ModalWrapper,
  Popup,
  PopupHeader,
  Button,
  ButtonIcon,
  ButtonText,
  ButtonWrapper,
  DivWraper,
  DivWComing,
  DivCloseBtn
} from './style'

import EventEmitter, { Events } from '../../utilities/eventEmitter'

const NewStoreModal = ({
  showModal = false,
  toggleModal,
  onConnectShopee,
  marketplaceListContent = []
}) => {
  const BrandImages = ConnectionModalData.imagespath
  const handleButtonClick = slug => {
    EventEmitter.emit(Events.SELECT_STORE, { mp_name: slug })
    switch (slug) {
      case SLUG_SHOPEE:
        return onConnectShopee
      default:
        return () => {}
    }
  }

  const renderMarketplacesButtons = (marketplaces) => {
    return marketplaces.map(marketplace => {
      const { slug, logo, name } = marketplace
      return (
        <Button
          onClick={handleButtonClick(slug)}
          key={slug}
          colorShade='#F3571E'
        >
          <ButtonIcon src={logo} alt={name} />
          <ButtonText>{name}</ButtonText>
        </Button>
      )
    })
  }
  const renderMarketplacesImages = (BrandImages) => {
    return BrandImages.map(BrandImages => {
      const { alt, src, title } = BrandImages
      return (
        <Button
          onClick={handleButtonClick(title)}
          key={src}
          colorShade='#F3571E'
        >
          <ButtonIcon src={src} alt={alt} />
          <ButtonText>{title}</ButtonText>
        </Button>
      )
    })
  }

  return (
    <ModalWrapper showModal={showModal}>
      <Overlay onClick={toggleModal} />
      <Popup>
        <DivCloseBtn>
          <CloseIcon style={{ float: 'right', color: '#7D86A9', cursor: 'pointer' }} onClick={toggleModal} />
        </DivCloseBtn>
        <PopupHeader>Select Store For Connection</PopupHeader>
        <ButtonWrapper>
          {
            renderMarketplacesButtons(marketplaceListContent)
          }
        </ButtonWrapper>
        <DivWComing>Coming Soon</DivWComing>
        <DivWraper>
          {
            renderMarketplacesImages(BrandImages)
          }
        </DivWraper>
      </Popup>
    </ModalWrapper>
  )
}

NewStoreModal.propTypes = {
  showModal: PropTypes.bool,
  onConnectShopee: PropTypes.func,
  toggleModal: PropTypes.func,
  marketplaceListContent: PropTypes.array
}
NewStoreModal.displayName = 'NewStoreModal'
export default NewStoreModal
