import { styled } from '@mui/system'
import { TableHead } from '@mui/material'

export const SortIconWrapper = styled('img')`
padding: 0px 8px 8px 7px; 
visibility: visible;
height: 10px;
width: 10px;
`

export const CellText = styled('p')`
overflow: hidden;
text-overflow: ellipsis
`

export const MovHeader = styled(TableHead)`
background-color: #E8EBEF;
height: 34px;
'thead':{
  '& th:first-child': {
    borderRadius: '1em 0 0 1em'
  },
  '& th:last-child': {
    borderRadius: '0 1em 1em 0'
  }
}
`

export const OfferTitle = styled('div')`
display: flex;
flex:1;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 22px;
color: #536082;
padding-left:0px;
`
