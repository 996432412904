import { call, put, takeLatest } from 'redux-saga/effects'

import {
  BRAND_MSP_DETAIL_LOADING,
  BRAND_MSP_GET_DETAIL,
  BRAND_MSP_GET_DETAIL_FAILURE,
  BRAND_MSP_GET_DETAIL_SUCCESS,
  BRAND_MSP_DETAIL_EXPORT,
  BRAND_MSP_DETAIL_EXPORT_LOADING,
  BRAND_MSP_DETAIL_EXPORT_SUCCESS,
  BRAND_MSP_DETAIL_EXPORT_FAILURE,
  BRAND_MSP_DETAIL_FILTERS_LOADING,
  BRAND_MSP_GET_DETAIL_FILTERS,
  BRAND_MSP_GET_DETAIL_FILTERS_FAILURE,
  BRAND_MSP_GET_DETAIL_FILTERS_SUCCESS,
} from '../actions'

import {
    API_GET_BRANDS_MSP_DETAILS,
    API_GET_BRANDS_MSP_DETAILS_DOWNLOAD,
    API_GET_BRANDS_MSP_DETAILS_FILTERS
} from '../constants'

import { BrandManagementPostApi } from '../utilities/axios'

function * onGetBrandMSPDetails (action) {
    yield put({ type: BRAND_MSP_DETAIL_LOADING, isLoading: true })
    try {
      const query = {
        offset: action.payload?.offset || 0,
        limit: action.payload?.limit || 20
      }

      if(action.payload?.skuOrNameSearch){
        query.search = action.payload?.skuOrNameSearch
      }

      if (action.payload.sort) {
        query.sort = action.payload.sort
        query.sortOrder = action.payload.sortOrder
      }
  
    if(action.payload?.followerRange?.min || action.payload?.followerRange?.max){
        let obj = {}
        if(action.payload?.followerRange?.min){
            obj['min'] = action.payload?.followerRange?.min
        }
        if(action.payload?.followerRange?.max){
            obj['max'] = action.payload?.followerRange?.max
        }
        query.followerRange = obj
    }
      if(action.payload?.marketplaces){
        query.marketplaces = action.payload?.marketplaces
      }

      if(action.payload?.monthlyUnitsSold?.min >= 0 || action.payload?.monthlyUnitsSold?.max >= 0){
        let obj = {}
        if(action.payload?.monthlyUnitsSold?.min >= 0){
            obj['min'] = action.payload?.monthlyUnitsSold?.min
        }
        if(action.payload?.monthlyUnitsSold?.max >= 0){
            obj['max'] = action.payload?.monthlyUnitsSold?.max
        }
        query.monthlyUnitsSold = obj
      }

      if(action.payload?.price?.min || action.payload?.price?.max){
        let obj = {}
        if(action.payload?.price?.min){
            obj['min'] = action.payload?.price?.min
        }
        if(action.payload?.price?.max){
            obj['max'] = action.payload?.price?.max
        }
        query.price = obj
      }

      if(action.payload?.rating){
        query.rating = action.payload?.rating
      }

      if(action.payload?.responseRate?.min || action.payload?.responseRate?.max){
        let obj = {}
        if(action.payload?.responseRate?.min){
            obj['min'] = action.payload?.responseRate?.min
        }
        if(action.payload?.responseRate?.max){
            obj['max'] = action.payload?.responseRate?.max
        }
        query.responseRate = obj
      }

      if(action.payload?.shipsFrom){
        query.shipsFrom = [action.payload?.shipsFrom]
      }

      if(action.payload?.availableStock?.min >= 0 || action.payload?.availableStock?.max >= 0){
        let obj = {}
        if(action.payload?.availableStock?.min >= 0){
            obj['min'] = action.payload?.availableStock?.min
        }
        if(action.payload?.availableStock?.max >= 0){
            obj['max'] = action.payload?.availableStock?.max
        }
        query.availableStock = obj
      }

      if(action.payload?.totalUnitsSold?.min >= 0 || action.payload?.totalUnitsSold?.max >= 0){
        let obj = {}
        if(action.payload?.totalUnitsSold?.min >= 0){
            obj['min'] = action.payload?.totalUnitsSold?.min
        }
        if(action.payload?.totalUnitsSold?.max >= 0){
            obj['max'] = action.payload?.totalUnitsSold?.max
        }
        query.totalUnitsSold = obj
      }

      const response = yield call(BrandManagementPostApi, API_GET_BRANDS_MSP_DETAILS(action.payload.brandId, action.payload.sku), query, {}, false)
      yield put({ type: BRAND_MSP_GET_DETAIL_SUCCESS, data: response?.data || {} })
    } catch (e) {
      yield put({ type: BRAND_MSP_GET_DETAIL_FAILURE, message: e.message })
    }
    yield put({ type: BRAND_MSP_DETAIL_LOADING, isLoading: false })
  }

  function * downlaodMspDetails (action) {
    yield put({ type: BRAND_MSP_DETAIL_EXPORT_LOADING, isLoading: true })
    try {

      const response = yield call(BrandManagementPostApi, API_GET_BRANDS_MSP_DETAILS_DOWNLOAD(action.payload.brandId, action.payload.sku), {}, {}, false)
      yield put({ type: BRAND_MSP_DETAIL_EXPORT_SUCCESS, data: response?.data || {} })
    } catch (e) {
      yield put({ type: BRAND_MSP_DETAIL_EXPORT_FAILURE, message: e.message })
    }
    yield put({ type: BRAND_MSP_DETAIL_EXPORT_LOADING, isLoading: false })
  }

  function * onGetBrandMSPDetailsFilters (action) {
    yield put({ type: BRAND_MSP_DETAIL_FILTERS_LOADING, isLoading: true })
    try {
      const query = {
        brandId: action.payload.brandId,
        sku: action.payload.sku
      }

      if(action.payload?.skuOrNameSearch){
        query.search = action.payload?.skuOrNameSearch
      }

      if (action.payload.sort) {
        query.sort = action.payload.sort
        query.sortOrder = action.payload.sortOrder
      }

      if(action.payload?.followerRange?.min || action.payload?.followerRange?.max){
        let obj = {}
        if(action.payload?.followerRange?.min){
        obj['min'] = action.payload?.followerRange?.min
      }
      if(action.payload?.followerRange?.max){
          obj['max'] = action.payload?.followerRange?.max
      }
      query.followerRange = obj
    }
    
    if(action.payload?.marketplaces){
      query.marketplaces = action.payload?.marketplaces
    }

    if(action.payload?.monthlyUnitsSold?.min >= 0 || action.payload?.monthlyUnitsSold?.max >= 0){
      let obj = {}
      if(action.payload?.monthlyUnitsSold?.min >= 0){
          obj['min'] = action.payload?.monthlyUnitsSold?.min
      }
      if(action.payload?.monthlyUnitsSold?.max >= 0){
          obj['max'] = action.payload?.monthlyUnitsSold?.max
      }
      query.monthlyUnitsSold = obj
    }

    if(action.payload?.price?.min || action.payload?.price?.max){
      let obj = {}
      if(action.payload?.price?.min){
          obj['min'] = action.payload?.price?.min
      }
      if(action.payload?.price?.max){
          obj['max'] = action.payload?.price?.max
      }
      query.price = obj
    }

    if(action.payload?.rating){
      query.rating = action.payload?.rating
    }

    if(action.payload?.responseRate?.min || action.payload?.responseRate?.max){
      let obj = {}
      if(action.payload?.responseRate?.min){
          obj['min'] = action.payload?.responseRate?.min
      }
      if(action.payload?.responseRate?.max){
          obj['max'] = action.payload?.responseRate?.max
      }
      query.responseRate = obj
    }

    if(action.payload?.shipsFrom){
      query.shipsFrom = action.payload?.shipsFrom
    }

    if(action.payload?.availableStock?.min >= 0 || action.payload?.availableStock?.max >= 0){
      let obj = {}
      if(action.payload?.availableStock?.min >= 0){
          obj['min'] = action.payload?.availableStock?.min
      }
      if(action.payload?.availableStock?.max >= 0){
          obj['max'] = action.payload?.availableStock?.max
      }
      query.availableStock = obj
    }

    if(action.payload?.totalUnitsSold?.min >= 0 || action.payload?.totalUnitsSold?.max >= 0){
      let obj = {}
      if(action.payload?.totalUnitsSold?.min >= 0){
          obj['min'] = action.payload?.totalUnitsSold?.min
      }
      if(action.payload?.totalUnitsSold?.max >= 0){
          obj['max'] = action.payload?.totalUnitsSold?.max
      }
      query.totalUnitsSold = obj
    }

      const response = yield call(BrandManagementPostApi, API_GET_BRANDS_MSP_DETAILS_FILTERS(action.payload.brandId, action.payload.sku), query, {}, false)
      yield put({ type: BRAND_MSP_GET_DETAIL_FILTERS_SUCCESS, data: response?.data || {} })
    } catch (e) {
      yield put({ type: BRAND_MSP_GET_DETAIL_FILTERS_FAILURE, message: e.message })
    }
    yield put({ type: BRAND_MSP_DETAIL_FILTERS_LOADING, isLoading: false })
  }


function * brandsMSP () {
    yield takeLatest(BRAND_MSP_GET_DETAIL, onGetBrandMSPDetails)
    yield takeLatest(BRAND_MSP_DETAIL_EXPORT, downlaodMspDetails)
    yield takeLatest(BRAND_MSP_GET_DETAIL_FILTERS, onGetBrandMSPDetailsFilters)

}
  
  export default brandsMSP