import React, { useState } from 'react'
import {
  TitleOuterWrapper,
  TitleInnerWrapper,
  TitleImagesWrapper,
  TitlePrimary,
  TitleSecondary
} from './style'
import { Container, Link, MobileStepper } from '@mui/material'

import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const Heading = (props) => {
  const { content } = props

  const target = (url) => {
    return '_blank'
  }

  const generateImages = (data) => {
    return (
      <TitleImagesWrapper>
        {data.map((image) => {
          const maxHeight = image?.maxHeight && { maxHeight: image?.maxHeight, maxWidth: 'auto' }
          const maxWidth = image?.maxWidth && { maxWidth: image?.maxWidth, maxHeight: 'auto' }
          const max = maxHeight || maxWidth || {}

          const imgTag = <img src={image.src} title={image.alt} alt={image.alt} style={max} />
          return (image?.url ? <Link href={image.url} target={target(image.url)}>{imgTag}</Link> : imgTag)
        })}
      </TitleImagesWrapper>
    )
  }

  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = content?.carouselData?.length

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  return (
    <TitleOuterWrapper>
      <Container>
        <TitleInnerWrapper>
          {content?.title && <TitlePrimary>{content?.title}</TitlePrimary>}
          {content?.topImages && generateImages(content.topImages)}
          {content?.subTitle && <TitleSecondary dangerouslySetInnerHTML={{ __html: content.subTitle }} />}
          {content?.bottomImages && generateImages(content.bottomImages)}

          {content?.carouselData && content?.carouselData?.length > 0 &&
            <>
              <AutoPlaySwipeableViews
                axis='x'
                index={activeStep}
                interval={3000}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                style={{ width: '100%' }}
                className='autoPlaySwipeableViews'
              >
                {
                  content?.carouselData.map((cData, i) => {
                    return (
                      <TitleInnerWrapper key={i}>
                        {cData?.topImages && generateImages(cData.topImages)}
                        {cData?.subTitle && <TitleSecondary dangerouslySetInnerHTML={{ __html: cData.subTitle }} />}
                        {cData?.bottomImages && generateImages(cData.bottomImages)}
                      </TitleInnerWrapper>
                    )
                  })
                }
              </AutoPlaySwipeableViews>

              <MobileStepper
                steps={maxSteps}
                position='static'
                variant='dots'
                className='mobileStepper'
                activeStep={activeStep}
              />
            </>}
        </TitleInnerWrapper>
      </Container>
    </TitleOuterWrapper>
  )
}

export default Heading
