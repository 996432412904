import { call, put, takeLatest } from 'redux-saga/effects'

import {
  AUTH_GET_OTP,
  AUTH_GET_OTP_FAILURE,
  AUTH_GET_OTP_SUCCESS,
  AUTH_LOADING,
  AUTH_VERIFY_OTP,
  AUTH_VERIFY_OTP_FAILURE,
  AUTH_VERIFY_OTP_SUCCESS,
  AUTH_SET_PERSONAL_PROFILE,
  AUTH_SET_PERSONAL_PROFILE_FAILURE,
  AUTH_SET_PERSONAL_PROFILE_SUCCESS,
  AUTH_LOGIN_USER,
  AUTH_LOGIN_USER_FAILURE,
  AUTH_LOGIN_USER_SUCCESS,
  AUTH_REFRESH_TOKEN,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_LOADING,
  RESET_PASSWORD,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  BRAND_GET_SUBSCRIPTION_SUCCESS,
  BRAND_GET_SUBSCRIPTION_FAILURE
} from '../actions'
import {
  API_GET_OTP,
  API_VERIFY_OTP,
  API_LOGIN,
  API_REFRESH_TOKEN,
  API_FORGOT_PASSWORD,
  API_RESET_PASSWORD,
  API_GET_SUBSCRIPTION
} from '../constants'
import { postApi, putApi, BrandManagementGetApi } from '../utilities/axios'
import { getRefreshToken } from '../utilities/app'

function * getOtp ({ data }) {
  yield put({ type: AUTH_LOADING, isLoading: true })
  try {
    const body = {
      medium: 'mobile',
      mobile: data.mobile
    }
    const response = yield call(postApi, API_GET_OTP, body)
    yield put({ type: AUTH_GET_OTP_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    yield put({ type: AUTH_GET_OTP_FAILURE, message: e.response?.data?.message || e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: AUTH_GET_OTP_FAILURE, message: '' })
  }
  yield put({ type: AUTH_LOADING, isLoading: false })
}

function * verifyOtp ({ data }) {
  yield put({ type: AUTH_LOADING, isLoading: true })
  try {
    const { mobile, otp, isExistingUser } = data
    const body = {
      medium: 'mobile',
      mobile,
      otp,
      isExistingUser
    }
    const response = yield call(postApi, API_VERIFY_OTP, body)
    yield put({ type: AUTH_VERIFY_OTP_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    yield put({ type: AUTH_VERIFY_OTP_FAILURE, message: e.response?.data?.message || e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: AUTH_VERIFY_OTP_FAILURE, message: '' })
  }
  yield put({ type: AUTH_LOADING, isLoading: false })
}

function * setPersonalProfile ({ data }) {
  yield put({ type: AUTH_LOADING, isLoading: true })
  try {
    const { email, password, confirmPassword, userId, token, name } = data
    const body = {
      email,
      name,
      password,
      confirmPassword
    }
    const apiUrl = `/v1/users/v1/${userId}/profile-password/`
    const response = yield call(putApi, apiUrl, body, { token, isLegacyApiHost: true })

    const successData = {
      ...response?.data?.data,
      token
    }

    yield put({ type: AUTH_SET_PERSONAL_PROFILE_SUCCESS, data: successData })
  } catch (e) {
    yield put({ type: AUTH_SET_PERSONAL_PROFILE_FAILURE, message: e.response?.data?.message || e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: AUTH_SET_PERSONAL_PROFILE_FAILURE, message: '' })
  }
  yield put({ type: AUTH_LOADING, isLoading: false })
}

function * onLogin ({ data }) {
  yield put({ type: AUTH_LOADING, isLoading: true })
  try {
    const { email, password } = data
    const body = { email, password }
    const response = yield call(postApi, API_LOGIN, body)
    const userId = response?.data?.data?.userDetail?.id || ''
    const idToken = response?.data?.data?.idToken || ''
    // in future we will remove this API and we will get subscription values in login API response
    try {
      // if there will be any error in subscription API we will concider that user doesn't have any subscription
      const subscriptionResponse = yield call(BrandManagementGetApi, API_GET_SUBSCRIPTION(userId), {}, true, idToken)
      yield put({ type: BRAND_GET_SUBSCRIPTION_SUCCESS, data: subscriptionResponse?.data })
    } catch (e) {
      yield put({ type: BRAND_GET_SUBSCRIPTION_FAILURE })

    }
    yield put({ type: AUTH_LOGIN_USER_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    const message = e?.response?.data?.message || e.message || 'Something went wrong'
    yield put({ type: AUTH_LOGIN_USER_FAILURE, message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: AUTH_LOGIN_USER_FAILURE, message: '' })
  }
  yield put({ type: AUTH_LOADING, isLoading: false })
}

function * onRefreshToken () {
  yield put({ type: AUTH_LOADING, isLoading: true })
  try {
    const refreshToken = getRefreshToken()
    const body = { refreshToken }
    const response = yield call(postApi, API_REFRESH_TOKEN, body, { isLegacyApiHost: true, retried: true })
    yield put({ type: AUTH_LOGIN_USER_SUCCESS, data: response?.data?.data || {} })
    window.location.reload()
  } catch (e) {
    const message = e?.response?.data?.message || e.message || 'Something went wrong'
    yield put({ type: AUTH_LOGIN_USER_FAILURE, message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: AUTH_LOGIN_USER_FAILURE, message: '' })
  }
  yield put({ type: AUTH_LOADING, isLoading: false })
}

function * onPostForgotPassword ({ payload }) {
  yield put({ type: FORGOT_PASSWORD_LOADING, isLoadingForgotPassword: true })
  try {
    const response = yield call(postApi, API_FORGOT_PASSWORD, payload)
    yield put({ type: FORGOT_PASSWORD_SUCCESS, data: response?.status || {} })
  } catch (e) {
    const message = e?.response?.data?.message || e.message || 'Something went wrong'
    yield put({ type: FORGOT_PASSWORD_FAILURE, message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: FORGOT_PASSWORD_FAILURE, message: '' })
  }
  yield put({ type: FORGOT_PASSWORD_LOADING, isLoadingForgotPassword: false })
}

function * onPostResetPassword ({ payload }) {
  yield put({ type: RESET_PASSWORD_LOADING, isLoadingResetPassword: true })
  try {
    const response = yield call(postApi, API_RESET_PASSWORD, payload)
    yield put({ type: RESET_PASSWORD_SUCCESS, data: response?.status || {} })
  } catch (e) {
    const message = e?.response?.data?.message || e.message || 'Something went wrong'
    yield put({ type: RESET_PASSWORD_FAILURE, message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: RESET_PASSWORD_FAILURE, message: '' })
  }
  yield put({ type: RESET_PASSWORD_LOADING, isLoadingResetPassword: false })
}

function * authSaga () {
  yield takeLatest(AUTH_GET_OTP, getOtp)
  yield takeLatest(AUTH_VERIFY_OTP, verifyOtp)
  yield takeLatest(AUTH_SET_PERSONAL_PROFILE, setPersonalProfile)
  yield takeLatest(AUTH_LOGIN_USER, onLogin)
  yield takeLatest(AUTH_REFRESH_TOKEN, onRefreshToken)
  yield takeLatest(FORGOT_PASSWORD, onPostForgotPassword)
  yield takeLatest(RESET_PASSWORD, onPostResetPassword)
}

export default authSaga
