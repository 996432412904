import { call, put, takeLatest } from 'redux-saga/effects'

import {
  ORDER_DETAIL_GET_LOADING,
  ORDER_DETAIL_GET,
  ORDER_DETAIL_GET_FAILURE,
  ORDER_DETAIL_GET_SUCCESS,
  ORDER_SHIP_PARAMS_LOADING,
  ORDER_SHIP_PARAMS_FAILURE,
  ORDER_SHIP_PARAMS_SUCCESS,
  ORDER_SHIP_PARAMS
} from '../actions'

import {
  API_GET_ORDER_DETAILS,
  API_GET_SHIP_PARAMS
} from '../constants'

import { getApi } from '../utilities/axios'

function * onGetOrderDetails (action) {
  yield put({ type: ORDER_DETAIL_GET_LOADING, isLoading: true })
  try {
    const response = yield call(getApi, API_GET_ORDER_DETAILS(action.payload.order_id))
    yield put({ type: ORDER_DETAIL_GET_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    yield put({ type: ORDER_DETAIL_GET_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: ORDER_DETAIL_GET_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: ORDER_DETAIL_GET_SUCCESS, data: {} })
  }
  yield put({ type: ORDER_DETAIL_GET_LOADING, isLoading: false })
}

function * onGetShipParams (action) {
  yield put({ type: ORDER_SHIP_PARAMS_LOADING, isLoading: true })
  try {
    const response = yield call(getApi, API_GET_SHIP_PARAMS, action?.payload)
    yield put({ type: ORDER_SHIP_PARAMS_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    yield put({ type: ORDER_SHIP_PARAMS_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: ORDER_SHIP_PARAMS_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: ORDER_SHIP_PARAMS_SUCCESS, data: {} })
  }
  yield put({ type: ORDER_SHIP_PARAMS_LOADING, isLoading: false })
}

function * orderDetails () {
  yield takeLatest(ORDER_DETAIL_GET, onGetOrderDetails)
  yield takeLatest(ORDER_SHIP_PARAMS, onGetShipParams)
}

export default orderDetails
