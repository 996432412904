import { styled } from '@mui/system'
import { Box, Typography, Link } from '@mui/material'
const media = {
  xl: '@media(max-width: 2560px)',
  lg: '@media(max-width: 1500px)',
  md: '@media(max-width: 850px)',
  sm: '@media(max-width: 600px)'
}

export const PageBuilderOuterWrapper = styled(Box)(`
  padding: 20px 37px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  & div {
    box-sizing: border-box;
  }
  @media (max-width: 600px){
    padding: 15px;
  }
`)

export const HeadingWrapper = styled(Box)(props => `
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding-bottom:10px;
  ${media.sm}{
  flex-direction: column;
  display: ${props.mobileDisplay === 'hide' ? 'none' : ''};
  }
`)

export const Heading = styled(Typography)(props => `
    font-size:24px;
    font-weight: 700;
    letter-spacing: 0.48px;
    line-height: 33px;
    font-family: Noto Sans;
`)

export const CopyLink = styled(Link)(props => `
  font-size:14px;
  display:flex;
  align-items:center;
  text-decoration:none;
  font-weight: bold;
  & svg {
    margin-left:5px;
  }
`)

export const PageBuilderWrapper = styled(Box)(props => `
  padding: 23px 30px 1rem 50px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(136, 150, 203, 0.121312);
  border-radius: 10px;
  width: 100%;
  ${media.sm}{
    padding: 10px;
  }

  ${media.sm}{
    border-radius: 0;
    margin: -15px -15px 0;
    width: 100vw;

    & .tabWrapper {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0;  
    }
    & .tabWrapper .tab {
      line-height: 30px;
      margin-bottom: -2px;
    }
  }
`)
