import { connect } from 'react-redux'

import Layout from '../Layout'
import ProductCreateComponent from '../../components/ProductCreate'

import {
  RESET_PRODUCT_CREATE_FORM,
  CATEGORIES_GET_LIST,
  CATEGORIES_BRANDS_GET_LIST,
  CATEGORIES_ATTRIBUTES_GET_LIST,
  CATEGORIES_DROPDOWN_GET_LIST,
  PRODUCTS_EDIT_GET_DATA,
  PRODUCTS_UPDATE_SET_DATA
} from '../../actions'

const ProductEdit = props => {
  return (
    <Layout>
      <ProductCreateComponent {...props} />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const {
    categories,
    products
  } = state
  return {
    error: categories.get('error'),
    categoryIsLoading: categories.get('categoryIsLoading'),
    categoryList: categories.get('content').toJS(),
    // categoryBrandsError: categoriesBrands.get('error'),
    // categoryBrandsIsLoading: categoriesBrands.get('categoryBrandsIsLoading'),
    categoryBrandsList: categories.get('categoryBrandsList').toJS(),
    // categoryAttributesError: categoriesAttributes.get('error'),
    // categoryAttributesIsLoading: categoriesAttributes.get('categoryAttributesIsLoading'),
    categoryAttributesList: categories.get('categoryAttributesList').toJS(),
    productEditData: products.get('productEditData'),
    productUpdateData: products.get('productUpdateData')

  }
}

const mapDispatchToProps = dispatch => ({
  onGetCategoryBrandsList: (query) => {
    dispatch({ type: CATEGORIES_BRANDS_GET_LIST, payload: query })
  },
  onGetCategoryAttributesList: (query) => {
    dispatch({ type: CATEGORIES_ATTRIBUTES_GET_LIST, payload: query })
  },
  onGetCategoryList: (query) => {
    dispatch({ type: CATEGORIES_GET_LIST, payload: query })
  },
  onGetCategoryDropdownData: (query) => {
    dispatch({ type: CATEGORIES_DROPDOWN_GET_LIST, payload: query })
  },
  onGetProductEditData: (query) => {
    dispatch({ type: PRODUCTS_EDIT_GET_DATA, payload: query })
  },
  onGetProductUpdateData: (query) => {
    dispatch({ type: PRODUCTS_UPDATE_SET_DATA, payload: query })
  },
  onProductFormUnmount: () => {
    dispatch({ type: RESET_PRODUCT_CREATE_FORM })
  }
})

ProductEdit.displayName = 'ProductEditContainer'

export default connect(mapStateToProps, mapDispatchToProps)(ProductEdit)
