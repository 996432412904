import { List, Map } from 'immutable'

import {
  ORDERS_LOADING,
  ORDERS_GET_LIST_FAILURE,
  ORDERS_GET_LIST_SUCCESS,
  ORDERS_PARTIAL_FULFILLMENT_SUCCESS,
  ORDER_SHIP_LOADING,
  ORDER_SHIP_SUCCESS,
  ORDER_SHIP_FAILURE,
  ORDERS_STATUS_LOADING,
  ORDERS_STATUS_GET_LIST_SUCCESS,
  ORDERS_STATUS_GET_LIST_FAILURE
} from '../actions'

const initialState = new Map({
  isLoading: false,
  error: '',
  totalRecords: 0,
  totalRecordsOrderStatusList: 0,
  content: List([]),
  orderStatusListIsLoading: false,
  orderStatusListContent: List([]),
  orderCancelIsLoading: false,
  orderCancelData: {},
  orderShipIsLoading: false,
  orderShipOrder: {},
  updatePartialfulfillmentData: {}
})

export const orders = (state = initialState, action) => {
  const {
    data,
    isLoading,
    message,
    type,
    orderShipIsLoading, 
    partialOrderFulfillment
  } = action

  switch (type) {
    case ORDERS_LOADING:
      return state.set('isLoading', isLoading)
    case ORDERS_GET_LIST_SUCCESS:
      return state.merge({
        totalRecords: data?.totalRecords || 0,
        content: List(data?.content || [])
      })
    case ORDERS_GET_LIST_FAILURE:
      return state.set('error', message)
    case ORDERS_PARTIAL_FULFILLMENT_SUCCESS:
      return state.set('updatePartialfulfillmentData', partialOrderFulfillment)
    case ORDER_SHIP_LOADING:
      return state.set('orderShipIsLoading', orderShipIsLoading)
    case ORDER_SHIP_SUCCESS:
      return state.merge({
        orderShipData: data?.content || {}
      })
    case ORDER_SHIP_FAILURE:
      return state.set('error', message)

    default:
      return state
  }
}

export const orderStatusList = (state = initialState, action) => {
  const {
    data,
    message,
    type,
    orderStatusListIsLoading
  } = action

  switch (type) {
    case ORDERS_STATUS_LOADING:
      return state.set('orderStatusListIsLoading', orderStatusListIsLoading)
    case ORDERS_STATUS_GET_LIST_SUCCESS:
      return state.merge({
        totalRecordsOrderStatusList: data?.totalRecords || 0,
        content: List(data.map(d => d.name) || [])
      })
    case ORDERS_STATUS_GET_LIST_FAILURE:
      return state.set('error', message)

    default:
      return state
  }
}
