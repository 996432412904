import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { PRODUCTS_EDIT_GET_DATA_CLEAR, PRODUCTS_UPDATE_SET_DATA_CLEAR } from '../../actions'
import { ROUTE_PRODUCTS } from '../../constants'
import Modal from '../Modal'
import StoreSelectionModal from '../StoreSelectionModal'
import ProdCreate from './ProdCreate'
import AdditionalInfo from './AdditionalInfo'
import LoadingSpinner from '../LoadingSpinner'
import MuiButton from '../MuiButton'

import {
  ProductOuterWrapper,
  ProductWrapper,
  FormControl,
  SectionContent
} from './style'

import { Typography } from '@mui/material'

const ProductCreate = (props) => {
  const {
    categoryBrandsList,
    onGetCategoryBrandsList,

    categoryAttributesList,
    onGetCategoryAttributesList,

    productCreateData,

    storeList,
    storeIsLoading,

    productEditData,
    onGetProductEditData,

    productUpdateData
  } = props

  const paramID = useParams()?.productId
  const mode = (paramID ? 'edit' : 'create')
  const [selectedStores, setSelectedStores] = useState()
  const [step, setStep] = useState(1)
  const [submitType, setSubmitType] = useState('submit')
  const navigate = useNavigate()
  const [connectedStores, setConnectedStores] = useState(storeList?.filter(s => s?.is_connected === true) || [])
  const [modalStatus, setModalStatus] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (mode === 'create') {
      if (productCreateData?.rsellViewId) {
        setStep(submitType === ('submit') ? 2 : 4)
        if (submitType === 'draft') {
          navigate(`${ROUTE_PRODUCTS}?q=${encodeURIComponent(productCreateData.name)}`)
        }
      }
      if (selectedStores?.length) {
        setStep(3)
      }
    }
  }, [productCreateData, selectedStores])

  useEffect(() => {
    if (mode === 'create' && step === 3 && productCreateData?.categoryId) {
      onGetCategoryBrandsList({
        limit: 1000,
        categoryId: productCreateData.categoryId,
        marketplace: 'shopee',
        selectedStores: [
          {
            id: selectedStores[0].id,
            shopName: selectedStores[0].shop_name,
            marketplaceShopId: selectedStores[0].marketplace_shop_id
          }
        ]
      })
      onGetCategoryAttributesList({
        categoryId: productCreateData.categoryId,
        marketplace: 'shopee',
        selectedStores: [
          {
            id: selectedStores[0].id,
            shopName: selectedStores[0].shop_name,
            marketplaceShopId: selectedStores[0].marketplace_shop_id
          }
        ]
      })
    }
  }, [step])

  useEffect(() => {
    setConnectedStores(storeList?.filter(s => s?.is_connected === true) || [])
  }, [storeList])

  // EDIT CODING BELOW START
  useEffect(() => {
    if (paramID) onGetProductEditData({ rsellViewId: paramID })
  }, [onGetProductEditData])

  useEffect(() => {
    if (mode === 'edit') {
      if (productUpdateData?.rsellViewId) {
        dispatch({ type: PRODUCTS_EDIT_GET_DATA_CLEAR })
        dispatch({ type: PRODUCTS_UPDATE_SET_DATA_CLEAR })
        navigate(`${ROUTE_PRODUCTS}?q=${encodeURIComponent(productUpdateData.name)}`)
      }
    }
  }, [productUpdateData, selectedStores])
  // EDIT CODING BELOW END

  const closeHandler = () => {
    navigate(`${ROUTE_PRODUCTS}?q=${encodeURIComponent(productCreateData.name)}`)
  }

  return (
    <ProductOuterWrapper>

      {mode === 'create' &&
        <>
          {step < 3 && <ProdCreate mode={mode} setModalStatus={setModalStatus} {...props} submitType={submitType} setSubmitType={val => setSubmitType(val)} />}

          {step === 2 &&
            <Modal showModal={modalStatus === true ? productCreateData?.rsellViewId : false} toggleModal={productCreateData?.rsellViewId}>
              {storeIsLoading && <LoadingSpinner />}
              {
                !connectedStores || connectedStores?.length === 0
                  ? <><b>No Store Available, <br /> Your product is saved as delist</b><br /><MuiButton variant='outlined' color='primary' to={`${ROUTE_PRODUCTS}/?q=${encodeURIComponent(productCreateData?.name)}`}>Back to Products page</MuiButton></>
                  : <StoreSelectionModal iconShow='true' onClose={closeHandler} setModalStatus={setModalStatus} stores={connectedStores} toggleModal={productCreateData?.rsellViewId} storeIsLoading isMultiple setSelectedStores={setSelectedStores} />
              }
            </Modal>}

          {step === 3 &&
            <>
              {(categoryBrandsList?.length && categoryAttributesList?.length) ? <AdditionalInfo {...props} selectedStores={selectedStores} /> : <LoadingSpinner />}
            </>}

          {step === 4 &&
            <ProductWrapper>
              <SectionContent>
                <FormControl>
                  <Typography variant='subtitle1'>
                    Product: <b>{productCreateData?.name}</b>, <br />
                    Delist Successfully <br /><br />
                    <MuiButton variant='outlined' color='primary' to={ROUTE_PRODUCTS}>Back to Products page</MuiButton>
                  </Typography>
                </FormControl>
              </SectionContent>
            </ProductWrapper>}
        </>}

      {mode === 'edit' &&
        productEditData?.rsellViewId &&
          step < 3 && <ProdCreate mode={mode} {...props} submitType={submitType} setSubmitType={val => setSubmitType(val)} />}

    </ProductOuterWrapper>
  )
}

export default ProductCreate
