import { styled } from '@mui/system'
import { Grid, Box, Popper, Tooltip } from '@mui/material'

const media = {
  large_laptop: '@media(max-width: 2560px)',
  laptop: '@media(max-width: 1000px)',
  tablet: '@media(max-width: 768px)',
  mobile: '@media(max-width: 600px)'
}

export const Main = styled(Grid)(`
margin-right: 1.5vh;
padding-left: 3vw;
padding-right: 3vw;
${media.laptop} {
  padding-left: 0vh;
  padding-right: 0vh;
} 
`)

export const BoxM = styled(Box)(`
margin-top: 3vh;
padding: 2vh;
min-height: 700px;
`)

export const TitleGrid = styled(Grid)(`
  
`)

export const Title = styled('p')(`
padding-left: 2vh;
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 33px;
letter-spacing: 0.48px;
color: #19284D;
${media.tablet}{
  text-align: center; 
}
`)

export const TopButtonsGroup = styled(Grid)(`
`)

export const ButtonGrid = styled(Grid)(`
  
`)

export const UploadHistoryBtn = styled(Grid)(`
margin-top: 5vh;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
text-align: center;
text-decoration-line: underline;
color: #162E9A;
:hover {
  cursor: pointer;
}
`)

export const BulkCreateBtn = styled(Grid)(`
margin-top: 3vh;
width: 140px;
height: 40px;
background: #FFFFFF;
box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
border-radius: 8px;
:hover {
  cursor: pointer;
}
${media.tablet}{
  margin: 0 auto; 
}
`)

export const BulkCreateBtnText = styled('p')(`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
margin-top: 11px;
text-align: center;
padding-top: 10px;
color: #162E9A;
`)

export const CreateNewBtn = styled(Grid)(`
margin-top: 3vh;
justify-content: centre;
width: 140px;
height: 40px;
background: #162E9A;
box-shadow: 0px 2px 8px rgba(129, 82, 226, 0.436134);
border-radius: 8px;
:hover {
  cursor: pointer;
}
${media.tablet}{
  margin: 0 auto; 
}
`)

export const CreateNewBtnText = styled('p')(`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
text-align: center;
color: #FFFFFF;
padding-top: 10px;
`)

export const GridM = styled(Grid)(`
${media.tablet}{
  &.center{
    justify-content: center !important;
  }
}
`)

export const SearchIconWrapper = styled('div')(`
margin: 5px 7px 0px 0px;
`)

export const SearchInputWrapper = styled('div')(`
margin-top: 28px;
${media.laptop} {
  margin-top: 10vh;
} 
& > div > div{
  width: 100%;
}
& > div > div > div {
  background-color: #F2F7FF;
  border-radius: 8px;
}
& > div > div > div > fieldset{
  border: none !important;
}
& > div > div > div > fieldset ::inpu{
    border : 5px red;
}
}
`)

export const SelectWrapper = styled('div')`
margin-top: 10px;
padding-right:10px;
`

export const SelectLabel = styled('div')`
margin-bottom: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
`

export const DataGridWrapper = styled(Grid)`
margin-top: 20px;
min-height: 550px;
.MuiDataGrid-iconSeparator{
  display:none;
}
.MuiDataGrid-columnHeaders{
  background-color: rgba(232, 235, 239, 0.4);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.MuiDataGrid-columnHeaderTitle{
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
color: #536082;
}
.MuiDataGrid-columnHeader:focus{
  outline: none !important;
}
.MuiDataGrid-row{
  border-bottom: 2px #EEF1F7 solid !important;
  margin-top: 0px !important;  
  margin-bottom: 0px !important;
}
.MuiDataGrid-cell{
  border-bottom : none !important;
}
}

`

export const EditCell = styled('p')`
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 18px;
text-decoration-line: underline;
color: #7055D9;
:hover{
  cursor: pointer;
}
`

export const HeaderDesription = styled('p')`
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 14px;
color: #212121;
mix-blend-mode: normal;
opacity: 0.7;
position: absolute;
margin-left: -40px;
margin-top: 18px;
`
export const InfoIconWrapper = styled('img')`
padding-left: 5px; 
`

export const TooltipWrapper = styled(Tooltip)`

`

export const BulkCreatePopper = styled(Popper)`
z-index: 2;
border: 1px #E5E5E5 solid;
& > .MuiPaper-root {
  border-radius: 0px !important;
}
`

export const SelectedItemsCount = styled('p')`
margin: 0px;
`
