import React from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TitleGrid, Title, ButtonGrid, TopButtonsGroup, CreateNewBtn, CreateNewBtnText } from './style'

const TitleRow = ({ totalPromo, createNewPromoHandler }) => {
  const { t } = useTranslation()

  return (
    <TitleGrid container>
      <Grid item md={6} sm={12} xs={12}>
        <Title>{t('brandPromo.brandOffer')}({totalPromo})</Title>
      </Grid>
      <TopButtonsGroup item md={6} sm={12} xs={12}>
        <Grid container direction='row-reverse'>
          <ButtonGrid item md={4} sm={12} xs={12}>
            <CreateNewBtn onClick={createNewPromoHandler}>
              <CreateNewBtnText>
                + {t('brandPromo.createNew')}
              </CreateNewBtnText>
            </CreateNewBtn>
          </ButtonGrid>
        </Grid>
      </TopButtonsGroup>
    </TitleGrid>
  )
}

TitleRow.displayName = 'BrandPromoTitleRowComponent'
export default TitleRow
