import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import {
  USERS_GET_INFO,
  USERS_GET_INFO_FAILURE,
  USERS_GET_INFO_SUCCESS,
  USERS_LOADING,
  USERS_UPDATE_PROFILE_IMAGE,
  USERS_UPDATE_PROFILE_IMAGE_SUCCESS,
  USERS_UPDATE_PROFILE_IMAGE_FAILURE
} from '../actions'
import {
  API_GET_USER_INFO,
  API_UPDATE_USER_PROFILE_IMAGE
} from '../constants'
import { getApi, patchApi } from '../utilities/axios'
import { getAuthToken, getRefreshToken } from '../utilities/app'

function * getUserInfo ({ data }) {
  yield put({ type: USERS_LOADING, isLoading: true })
  try {
    const response = yield call(getApi, API_GET_USER_INFO, {}, false, data)
    const token = data || getAuthToken()
    const refreshToken = getRefreshToken()
    yield put({ type: USERS_GET_INFO_SUCCESS, data: { ...response?.data?.data, idToken: token, refreshToken: refreshToken } || {} })
  } catch (e) {
    yield put({ type: USERS_GET_INFO_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: USERS_GET_INFO_FAILURE, message: '' })
  }
  yield put({ type: USERS_LOADING, isLoading: false })
}
function * UserProfileUpdate (action) {
  const { imageUrl, id } = action?.payload
  const body = { imageUrl: imageUrl }
  try {
    const response = yield call(patchApi, API_UPDATE_USER_PROFILE_IMAGE(id),
      body, { isLegacyApiHost: true })
    yield put({ type: USERS_UPDATE_PROFILE_IMAGE_SUCCESS, data: response.data.data.imageUrl })
    toast.success('User Profile Image Update SuccessFully')
  } catch (e) {
    toast.error(e.message)
    yield put({ type: USERS_UPDATE_PROFILE_IMAGE_FAILURE, message: e.message })
    yield put({ type: USERS_UPDATE_PROFILE_IMAGE_FAILURE, message: '' })
  }
}

function * userSaga () {
  yield takeLatest(USERS_GET_INFO, getUserInfo)
  yield takeLatest(USERS_UPDATE_PROFILE_IMAGE, UserProfileUpdate)
}

export default userSaga
