import styled from 'styled-components'

export const PaymentDetailsWrapper = styled.div`
  margin: 20px 20px 0px 30px;
  letter-spacing: 0px;
  font-family: Noto Sans;  
`

export const Header = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
`

export const Content = styled.div`
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 2fr;
  margin-top: 17px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

export const MethodHeaderWrapper = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: #21212199;
`

export const MethodContentWrapper = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: #000;
`

export const PaymentCard = styled.div`
  background: #F6F7FB99;
  min-width: 291px;
  padding: 9px 21px 17px 10px;
`

export const CardLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const TotalAmountPaidH = styled.div`
  margin-bottom: 13px;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: #212121;
`

export const TotalAmountPaid = styled.div`
  font-size: 14px;
  display: flex; margin-left: auto;
  font-weight: 500;
  line-height: 19px;
`

export const SubAmountH = styled.div`
  font-size: 11px;
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 15px;
  color: #21212199;
`

export const SubAmount = styled.div`
  color: #F56A83;
  font-size: 11px;
  font-weight: 500;
  display: flex; margin-left: auto;
  line-height: 15px; 
`

export const TotalOrderIncomeH = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  margin-top: 10px;
`

export const TotalOrderIncome = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex; margin: 10px 0 0 auto;
  line-height: 22px;
`
