import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

/* eslint-disable */
import { ProductListedOnModalContainer } from '../../containers/Products'
import {
  ProductCardWrapper,
  ProductImage,
  ProductImageWrapper,
  StoreImage,
  StoreInfo,
  StoreName,
  StockCount,
  ProductDetails,
  ProductName,
  ProductPrice,
  ProductRsp,
  TooltipWraper,
  ProductStatus
} from './style'
import storePlaceholderImage from '../../assets/images/placeholder-store.png'
import connectionProviderImage from '../../assets/images/shoppe-logo.png'
// import SwipeableViews from 'react-swipeable-views'
// import { autoPlay } from 'react-swipeable-views-utils'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from 'react-i18next'
import MuiButton from '../MuiButton'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProductCard = ({ product, isRaena, shouldStatusVisible, onAddStore, ...props }) => {
  let loc =   useLocation()
  const [showModal, setShowModal] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [selectedProductID, setSelectedProductID] = useState('')
  const imageMove = useRef()
  const [current, setCurrent] = useState(0)
  const [isAddToStoreModalVisible,setAddToStoreModalVisibility] = useState(false)
  const { t } = useTranslation()

  // const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

  const handleStepChange = (step) => {
    setActiveStep(step)
  }
  const ModalClick=()=>{
    setShowModal(!showModal)
    setSelectedProductID(product?.rsellViewId)
    setAddToStoreModalVisibility(false)
  }

  useEffect(()=> {
    const {productCatalog, q} = qs.parse(location.search);
    if (productCatalog && q){
      ModalClick()
      setAddToStoreModalVisibility(true)
    }
  }, [])

  const toggleModalHandler=()=>{
      setShowModal(!showModal)
    }

  const properties = {
    // duration: 2000,
    autoplay: false,
    transitionDuration: 500,
    arrows: true,
    infinite: true,
    easing: 'ease'
  }
  let productNameValue = ''
  let ProductNameIndex
  productNameValue = product?.name
  if (productNameValue.length > 55) {
    ProductNameIndex = productNameValue.substring(0, 55)
    productNameValue = ProductNameIndex.substring(0, ProductNameIndex.lastIndexOf(' ')) + "..."
  }
  if (isRaena) {
    return (
      <div>
        <ProductCardWrapper>
          <ProductImageWrapper>
            <Slide ref={imageMove} {...properties}>
              {
                product.productImages
                  ? product.productImages.map((value, index) => {
                    return (
                      <div key={index} className='each-slide'>
                        <LazyLoadImage
                         src={value.url ? value.url : storePlaceholderImage} 
                        />
                        {/* <img className='lazy' src={value.url ? value.url : storePlaceholderImage} alt='storePlaceholderImage' /> */}
                      </div>
                    )
                  })
                  : <ProductImage src={storePlaceholderImage} alt='storePlaceholderImage' />
              }
            </Slide>
          </ProductImageWrapper>
          <TooltipWraper title={product.name}>
            <ProductName>
              {productNameValue}
            </ProductName>
          </TooltipWraper>
          <ProductPrice>{t('products.sellerPrice')}: IDR {product.wholesalePrice}</ProductPrice>
          <ProductRsp>RSP: IDR {product.retailPrice}</ProductRsp>
          {/* <ProductDetails>
            <StoreInfo>
              <StoreImage src={connectionProviderImage} />
              <StoreName>
                {product.marketplace_name}
                {product.brand?.name}
              </StoreName>
            </StoreInfo>
            <StockCount>stock:{product.stockLimit}</StockCount>
          </ProductDetails> */}
      <MuiButton   onClick={() => {onAddStore()}} styled='primary' variant='contained'>{t('products.addToStore')}</MuiButton>
        </ProductCardWrapper>
      </div>
    )
  }
  return (
    <div>
      <ProductCardWrapper>
        <ProductImageWrapper>
          <Slide ref={imageMove} {...properties}>
            {
              product.images.length > 0
                ? product.images.map((value, index) => {
                  const url = value?.key?.startsWith('http') ? value.key : value?.url
                  return (
                    <div key={value.priority} className='each-slide' onClick={ModalClick}>
                          <LazyLoadImage
                           src={url ? url : storePlaceholderImage} 
                          />
                      {/* <img onClick={ModalClick}
                       className='lazy' src={url ? url : storePlaceholderImage} alt='sample' /> */}
                    </div>
                  )
                })
                : <ProductImage onClick={ModalClick} src={storePlaceholderImage} alt='storePlaceholderImage' />
            }
          </Slide>
        </ProductImageWrapper>
        <div onClick={ModalClick}>
          <TooltipWraper title={product.name}>
            <ProductName>
              {productNameValue}
            </ProductName>
          </TooltipWraper>
        <ProductDetails>
          <StoreInfo>
            <StoreImage src={connectionProviderImage} />
            <StoreName>{product.marketplace_name}{product.brand?.name}</StoreName>
          </StoreInfo>
          <StockCount>
            {t('productDetails.stock')}:{product.stock?.available}
          </StockCount>
        </ProductDetails>
        </div>
      </ProductCardWrapper>
      {showModal &&
      <ProductListedOnModalContainer
        showModal={showModal}
        toggleModal={toggleModalHandler}
        selectedID={selectedProductID}
        isAddToStoreModalVisible={isAddToStoreModalVisible}
        {...props}
      />}
    </div>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object
}

ProductCard.displayName = 'ProductCard'
export default ProductCard
