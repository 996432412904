import styled from 'styled-components'

export const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
  width:100%;
  margin-bottom: 10px;
`

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 8px;
  border: 1px solid white;
`

export const FormReq = styled('span')(`
  font-size: 12px;
  color: #DA2A52;
  padding-left:5px;
`)

export const FormError = styled('div')(`
  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
`)
