import React from 'react';
import Layout from '../Layout';
import CreatePromoComponent from '../../components/Promo/CreatePromoComponent';
import { connect } from 'react-redux';
import { CREATE_PROMO, GET_OFFERS, GET_SKU, RESET_FIELDS, PATCH_PROMO, VALIDATE_PROMO } from '../../actions'
const CreatePromo = (props) => {
  return (
    <Layout>
      <CreatePromoComponent {...props} />
    </Layout>
  )
}

const mapStateToProps = state => ({
  isOfferLoading: state.createPromo.isOfferLoading,
  isOfferLoaded: state.createPromo.isOfferLoaded,
  isOfferFailed: state.createPromo.isOfferFailed,
  offerDetails: state.createPromo.offerDetails,
  isSkuLoading: state.createPromo.isSkuLoading,
  isSkuLoaded: state.createPromo.isSkuLoaded,
  isError: state.createPromo.isError,
  skuDetails: state.createPromo.skuDetails,
  isCreate: state.createPromo.isCreate,
  isCreateSuccess: state.createPromo.isCreateSuccess,
  errorDetails: state.createPromo.errorDetails,
  isUpdate: state.createPromo.isUpdate,
  isUpdateSuccess: state.createPromo.isUpdateSuccess,
  isValidate: state.createPromo.isValidate,
  isValidateSuccess: state.createPromo.isValidateSuccess,
  selectedBrand: state.brands.get('selectedBrand'),
  brandDetails: state.brandsPromo.get('brandDetails'),
  brandsList: state.brands.get('brandsList'),
})

const mapDispatchToProps = dispatch => ({
  createPromo: (brandId, data) => {
    dispatch({ type: CREATE_PROMO, payload: { brandId, data } })
  },
  getOffers: (brandId) => {
    dispatch({ type: GET_OFFERS, payload: brandId })
  },
  getSkuList: (brandId) => {
    dispatch({ type: GET_SKU, payload: brandId })
  },
  resetData: () => {
    dispatch({ type: RESET_FIELDS })
  },
  updateBrandPromo: (brandId, id, data) => {
    dispatch({ type: PATCH_PROMO, payload: {brandId, id, data} })
  },
  validateUpdate: (brandId, id, data) => {
    dispatch({ type: VALIDATE_PROMO, payload: {brandId, id, data} })
  }
});

CreatePromo.displayName = 'CreatePromoContainer'
export default connect(mapStateToProps, mapDispatchToProps)(CreatePromo)
