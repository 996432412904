import styled, { css } from 'styled-components'

const RobotoNormalClass = css`
    font-style: normal;
`

const RobotoNormalWeight400Class = css`
    font-style: normal;
    font-weight: 400;
`

export const OrderItems = styled.div``

export const OrderItemCard = styled.div`
    display:flex;
    font-family: Noto Sans;
    letter-spacing: 0px;
    margin: 20px 0 10px 20px;
`

export const OrderItemImage = styled.img`
    width: 120px;
    height: 100px;
    margin-right: 10px;

    @media screen and (max-width: 1024px) {
        margin-right: 8px;
    }    
`

export const OrderItemCardInfo = styled.div`
    flex-grow:1;
    margin-top: 2px;
`

export const SellerSKU = styled.div`
    ${RobotoNormalWeight400Class}{
        font-size: 13px;
        color: #888;
        margin-top: 5px;
    }
`

export const RestCardInfo = styled.div`
    display: flex;
    column-gap: 35px;
    margin-top: 25px;

    @media screen and (max-width: 1024px) {
        column-gap: 20px;
        margin-top: 10px;    
    }

`

export const OrderItemDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;

`

export const CardItemsHeader = styled.div`
    ${SellerSKU}{
        margin-top: 0;        
    }
`

export const CardItemsContent = styled.div``

export const ItemsTotal = styled.div`
    ${SellerSKU}
`

export const ItemTotalPrice = styled.div`
    margin-top: 5px;
`

export const PriceHeader = styled.span`
    ${RobotoNormalClass}{
        font-size: 14px;
        color: #3A8D01;
        font-weight: 500;        
    }
`

export const QunantityWrapper = styled.span`    
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    margin-right: 6px;
    color: #21212199;
`

export const QuantityContent = styled.span`
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    color: #222222;
`

export const AmountWrapper = styled.span`
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0px;
    color: #212121;
`

export const PricePlusQuantity = styled.span`
    ${RobotoNormalClass}{
        font-size: 12px;
        color: #888;
        margin-top: 5px;
        font-weight: 500;        
    }
`

export const TotalAmountWrapper = styled.div`
    margin: 0px 10px 15px 0;
    padding-bottom: 15px;
    border-bottom: 10px solid #F6F7FB;
    display: flex;
`

export const TotalAmountWrapperInner = styled.div`
    margin: 0 10px 0 auto;
    background: #F6F7FB99;
    min-width: 291px;
    padding: 10px 20px 11px 10px;
`
export const CardLineWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: ${({ marginBottom }) => marginBottom ? '0' : '12px'};
    margin-top: ${({ marginBottom }) => marginBottom ? '20px' : '0'};
`
export const AmountHeaderInCardWrapper = styled.div`
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    
`
export const AmountInCardWrapper = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    display: flex;
    margin-left: auto;
`
export const AmountColoredWrapper = styled.div`
    font-size: 11px;
    font-weight: 500;
    margin-right: 7px;
    line-height: 15px;
    display: flex;
    color: #F56A83;
    margin-left: auto;
`
