import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ForgotPasswordComponent from '../../components/ForgotPassword'
import {
  FORGOT_PASSWORD
} from '../../actions'

const ForgotPassword = () => {
  const forgotPasswordProps = useSelector((state) => {
    const {
      auth
    } = state

    return {
      error: auth?.get('error'),
      isLoadingForgotPassword: auth?.get('isLoadingForgotPassword'),
      forgotPasswordSuccess: auth?.get('forgotPasswordSuccess')
    }
  })

  const callDispatch = useDispatch()

  const onPostForgotPassword = useCallback((query) => {
    callDispatch({ type: FORGOT_PASSWORD, payload: query })
  }, [callDispatch])

  return (
    <ForgotPasswordComponent {...
      {
        ...forgotPasswordProps,
        onPostForgotPassword
      }}
    />
  )
}

export default ForgotPassword
