import React from 'react'
import { connect, useSelector } from 'react-redux'

import Layout from '../Layout'
import ConnectionsComponent from '../../components/Connnections'

import {
  STORES_GET_LIST,
  MARKETPLACES_CONNECT_SHOPEE,
  MARKETPLACES_GET_LIST,
  STORES_DISCONNECT,
  STORES_SYNC_PRODUCTS,
  STORES_SYNC_ORDERS,
  USERS_GET_INFO
} from '../../actions'

import {
  integratedMarketplacesSelector
} from '../../selectors/marketplaces'
import {
  getConnectedStores,
  getDisconnectedStores
} from '../../selectors/stores'

const Connections = props => {
  const integratedMarketplaces = useSelector(integratedMarketplacesSelector)
  const connectedStores = useSelector(getConnectedStores)
  const disconnectedStores = useSelector(getDisconnectedStores)

  return (
    <Layout>
      <ConnectionsComponent
        integratedMarketplaces={integratedMarketplaces}
        connectedStores={connectedStores}
        disconnectedStores={disconnectedStores}
        {...props}
      />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const {
    stores,
    marketplaces
  } = state
  return {
    error: stores.get('error'),
    isLoading: stores.get('isLoading'),
    marketplaceListError: marketplaces.get('error'),
    marketplaceListIsLoading: marketplaces.get('isLoading'),
    storesTotalRecords: stores.get('totalRecords')
  }
}

const mapDispatchToProps = dispatch => ({
  onGetStoreList: () => {
    dispatch({ type: STORES_GET_LIST })
  },
  onGetUserInfo: () => {
    dispatch({ type: USERS_GET_INFO })
  },
  onGetMarketplaceList: () => {
    dispatch({ type: MARKETPLACES_GET_LIST })
  },
  onConnectShopee: () => {
    dispatch({ type: MARKETPLACES_CONNECT_SHOPEE })
  },
  onDisconnectStore: data => {
    dispatch({ type: STORES_DISCONNECT, data })
  },
  onSyncProducts: data => {
    dispatch({ type: STORES_SYNC_PRODUCTS, data })
  },
  onSyncOrders: data => {
    dispatch({ type: STORES_SYNC_ORDERS, data })
  }
})

Connections.displayName = 'ConnectionsContainer'
export default connect(mapStateToProps, mapDispatchToProps)(Connections)
