import styled from 'styled-components'

export const ConnectionContainer = styled.div`
  margin: 0 0px 10px 30px;
  padding: 46px 30px 0 0px;
  overflow: auto;
  height: calc(100vh - 210px);
  @media (max-width: 1000px) {
    height: auto;
  }
`

export const PageHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`

export const PageTitle = styled.div`
  font-family: Noto Sans;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.48px;
  line-height: 33px;
`

export const ModalWrapper = styled.div`
  position: relative;
`

export const Button = styled.button`
  background: #162E9A;
  border-radius: 4px;
  color: #FFFFFF;
  width: 200px;
  height: 40px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  border: none;
  cursor: pointer;
`

export const ConnectedStoreList = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin: 20px -5px 30px;
`

export const DisconnectedStoresWrapper = styled.div`
  margin-top: 20px;
`
