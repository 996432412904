import { call, put, takeLatest } from 'redux-saga/effects'

import {
  BRAND_MSP_LOADING,
  BRAND_MSP_GET_LIST,
  BRAND_MSP_GET_LIST_FAILURE,
  BRAND_MSP_GET_LIST_SUCCESS,
  BRAND_MSP_EXPORT,
  BRAND_MSP_EXPORT_LOADING,
  BRAND_MSP_EXPORT_SUCCESS,
  BRAND_MSP_EXPORT_FAILURE,
  BRAND_MSP_CONFIG_LOADING,
  BRAND_MSP_CONFIG_SUCCESS,
  BRAND_MSP_CONFIG_FAILURE,
  BRAND_MSP_CONFIG
} from '../actions'

import {
    API_GET_BRANDS_MANAGEMENT_MSP,
    API_BRANDS_MANAGEMENT_MSP_EXPORT,
    API_BRANDS_MANAGEMENT_MSP_CONFIG
} from '../constants'

import { BrandManagementPostApi, PatchPromoApi } from '../utilities/axios'

function * onGetBrandMSPList (action) {
    yield put({ type: BRAND_MSP_LOADING, isLoading: true })
    try {
      const query = {
        offset: action.payload?.offset || 0,
        limit: action.payload?.limit || 20
      }

      if(action.payload?.skuOrNameSearch){
        query.search = action.payload?.skuOrNameSearch
      }

      if (action.payload.sort) {
        query.sort = action.payload.sort
        query.sortOrder = action.payload.sortOrder
      }
  
      const response = yield call(BrandManagementPostApi, API_GET_BRANDS_MANAGEMENT_MSP(action.payload.brandId), query, {}, false)
      yield put({ type: BRAND_MSP_GET_LIST_SUCCESS, data: response?.data || {} })
    } catch (e) {
      yield put({ type: BRAND_MSP_GET_LIST_FAILURE, message: e.message })
    }
    yield put({ type: BRAND_MSP_LOADING, isLoading: false })
  }

  function * downlaodMsp (action) {
    yield put({ type: BRAND_MSP_EXPORT_LOADING, isLoading: true })
    try {

      const response = yield call(BrandManagementPostApi, API_BRANDS_MANAGEMENT_MSP_EXPORT(action.payload.brandId), {}, {}, false)
      yield put({ type: BRAND_MSP_EXPORT_SUCCESS, data: response?.data || {} })
    } catch (e) {
      yield put({ type: BRAND_MSP_EXPORT_FAILURE, message: e.message })
    }
    yield put({ type: BRAND_MSP_EXPORT_LOADING, isLoading: false })
  }


  function * updateMspConfig (action) {
    yield put({ type: BRAND_MSP_CONFIG_LOADING, isLoading: true })
    try {
        let data = {
            "brandId": action.payload.brandId,
            "sku": action.payload.sku,
            "msp": parseInt(action.payload.msp),
        }
      const response = yield call(PatchPromoApi, API_BRANDS_MANAGEMENT_MSP_CONFIG(action.payload.brandId,action.payload.sku), data, {}, false)
      yield put({ type: BRAND_MSP_CONFIG_SUCCESS, data: response?.data || {} })

    } catch (e) {
      yield put({ type: BRAND_MSP_CONFIG_FAILURE, message: e.message })
    }
    yield put({ type: BRAND_MSP_CONFIG_LOADING, isLoading: false })
  }

function * brandsMSP () {
    yield takeLatest(BRAND_MSP_GET_LIST, onGetBrandMSPList)
    yield takeLatest(BRAND_MSP_EXPORT, downlaodMsp)
    yield takeLatest(BRAND_MSP_CONFIG, updateMspConfig)
}
  
  export default brandsMSP