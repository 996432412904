
import { styled } from '@mui/system'
import { TextField, Autocomplete } from '@mui/material'


export const SelectAutoComplete = styled(Autocomplete)(props => `
padding: 0px;
& > label {
  width: 100%;
}
& > div {
  height: 38px;
  border-radius: 4px;
}
`)

export const SelectField = styled(TextField)(props => `
    margin-top: 0px;
    justify-content: space-between;
    & > label {
      width: 100%;
    }
    & > div {
      height: 38px;
      border-radius: 4px;
    }
`)