import { styled } from '@mui/system'
import { Box, InputLabel } from '@mui/material'

export const FileUploadWrapper = styled(Box)(`
  width:100%;
  display: flex;
  flex-wrap: wrap;
`)

export const FormLabel = styled(InputLabel)(`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
  width:100%;
  padding-bottom:10px;
`)

export const FormReq = styled('span')(`
  font-size: 12px;
  color: #DA2A52;
  padding-left:5px;
`)

export const FormDescription = styled(InputLabel)(`
  width:100%;
  padding-top:10px;
  font-size: 12px;
  color: #889995;
  white-space: break-spaces;
  line-height: 15px;
`)

export const ImageList = styled(Box)(`
  // width: 76px;
  // height: 76px;
  width: 84px;
  height: 84px;
  border-radius:6px;
  border:2px #d3d3d3 dashed;
  position:relative;
  margin:4px 20px 4px 0;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: inherit;

  & img {
    max-width:80px;
    max-height:80px;
    border-radius:6px;
  }
  & button {
    position:absolute;
    right:-14px;
    top:-14px;
  }
  & button:hover svg{
    fill:#D0021B;
  }
  & svg{
    background: #fff;
    border-radius: 50%;
  }
`)

export const FileUploadBox = styled('label')(`
  width: 80px;
  height: 80px;
  margin-right:20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px #0EC690 dashed;
  background: #F4F9F8;
  border-radius: 6px;
  cursor:pointer;
  // @media (max-width: 1000px){
    margin:4px 20px 4px 0;
  // }

  &:before {
    content: '+';
    border: 1px #d3d3d3 dashed;
    color: #0EC690;
    font-size:30px;
    width:20px;
    height:20px;
    line-height:20px;
    display:block;
    text-align:center;
  }

  &:hover {
    border: 2px #0EC690 solid;
  }

  & input{
    display:none;
  }
`)

export const FileUploadBoxLoader = styled('div')(`
  width: 84px;
  height: 84px;
  margin-right:20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px #0EC690 dashed;
  background: #F4F9F8;
  border-radius: 6px;
  cursor:pointer;
  margin:4px 20px 4px 0;

  & > div {
    position: relative;
    top: 20px;
    width: 65px;
  }
`)

export const FormError = styled(Box)(`
  width:100%;
  line-height: 20px;

  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 0px;
  color: #D14343;
`)
