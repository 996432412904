import React, { useState, useEffect } from 'react'
import { FormLabel, FormError, FormReq, TextFieldWrapper, TextFieldStyled, IconWrap } from './style'

const MuiTextField = (props) => {
  const [value, setValue] = useState(props?.value || '')
  const [inputError, setInputError] = useState()
  const maxLen = props?.InputProps?.maxLength || props?.InputProps?.max || 0

  const validate = (event) => {
    let val = event.target.value

    if (props?.dataType === 'number') {
      val = val.replace(/[^0-9]/g, '')
    } else if (props?.dataType === 'decimal') {
      let decimalCount = 0
      val = val
        .replace(/[^0-9.]/g, '')
        .replace(/\./g, match => ++decimalCount > 1 ? '' : match)
    }

    if (maxLen > 0 && val.length > maxLen) {
      setInputError(`Max length is ${maxLen}`)
      val = val.slice(0, maxLen)
      setTimeout(() => {
        setInputError('')
      }, 4000)
    }

    setValue(val)
    props?.onChange(event)
  }

  const Req = () => {
    return (
      <>
        {props.isRequired && <FormReq>*</FormReq>}
      </>
    )
  }

  useEffect(() => {
    setValue(props?.value)
  }, [props?.value])

  const Icon = () => {
    return (
      <>
        {props?.icon && props?.icon?.src && <IconWrap><img src={props?.icon?.src} alt='' /></IconWrap>}
      </>
    )
  }

  return (
    <>
      {props.title && <FormLabel>{props.title}<Req /></FormLabel>}
      {props.name &&
        <TextFieldWrapper>
          <Icon />
          <TextFieldStyled
            {...props}
            value={value}
            onKeyDown={(e) => validate(e)}
            onKeyUp={(e) => validate(e)}
            autoComplete='off'
            inputProps={{ ...props?.inputProps, maxLength: maxLen || null }}
          />
        </TextFieldWrapper>}
      {inputError && <FormError>{inputError}</FormError>}
    </>
  )
}

export default MuiTextField
