import styled from 'styled-components'

export const OrderModalHeaderWrapper = styled.div`
    padding: 0 6px 25px 29px;
    margin-right: 25px;
    font-family: Noto Sans;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 12fr 8fr 10fr;
    border-bottom: 10px solid #F6F7FB;
    
    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr 1fr;
    }  
`

export const BackImg = styled.img`
  margin-top: 6px;
  cursor: pointer;
`

export const HeadingSection = styled.div`
  display:flex;
  flex-direction: column;
`

export const HeadingTitle = styled.div`
  font-size: 11px;
  color: #21212199;
  line-height:15px;
`

export const HeadingContent = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
`

export const StatusWrapper = styled.div`
  background:#FFF8E7;
  padding: 9px 17px;
  text-align: center;
  color: #E7A900;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr 12fr;
  }  
`
