import styled from 'styled-components'

export const SelectOuterWrapper = styled.div`
`

export const SelectWrapper = styled.div`
  position: relative;
  width: 200px;
  height: 40px;
  margin-top: -20px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  cursor: pointer;
  
  background: ${props => props.color === 'grey' ? '#F9F9F9' : '#FFFFFF'};
  width: ${props => props.color === 'grey' ? '100%' : '200px'};
  font-size: ${props => props.color === 'grey' && '13px'};

  @media screen and (max-width: 600px) {
    width: 290px;
  }
`

export const SelectedValue = styled.div`
  display: flex;
  padding-left: 1rem;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 40px;
  max-width: 100%;
  max-height: 100%;
`

export const DropdownIcon = styled.img`
  width: 10px;
  height: 10px;
  padding-right: .5rem;
`

export const OptionsWrapper = styled.div`
  position: absolute;
  top: 40px;
  min-width: 100%;
  max-width: 100%;
  background: #FFFFFF;
  max-height: 250px;
  overflow: auto;
  z-index: 1;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
`

export const Option = styled.div`
  display: flex;
  padding-left: 1rem;
  align-items: center;
  min-height: 40px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  :hover {
    background: #F5F5F5;
  }
`

export const FormInput = styled.input`
  font-size: 14px;
  position: absolute;
  top:8px;
  opacity:0;
`

export const FormError = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: red;
`

export const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
`

export const FormField = styled.div`
  margin-top: 10px;
  width: 100%;
  & > div {
    min-width: 100%;
  }
`
