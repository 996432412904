import React from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SearchIconWrapper, SearchInputWrapper, SelectWrapper, SelectLabel, SelectedItemsCount } from './style'
import MuiTextField from '../MuiTextField'
import MuiSelect from '../../components/MuiSelect/MuiSelect'
import SearchIcon from '../../assets/images/searchIcon.svg'

const FilterRow = ({
  categoryList,
  productTypeList,
  selectedCategory,
  handleCategorySelect,
  selectedProductType,
  handleProductTypeSelect,
  searchtext,
  handleSearchTextChange,
  features
}) => {
  const { t } = useTranslation()

  const customSelectedChip = (selected) => {
    return (
      <>
        <SelectedItemsCount>{selected.length > 0 ? selected.length + t('brandCatalouge.item') : t('brandCatalouge.all')} </SelectedItemsCount>
      </>
    )
  }
  return (
    <Grid container columns={16}>
      <Grid item md={6} sm={16} xs={16}>
        <SearchInputWrapper>
          <MuiTextField
            onChange={(e) => {
              handleSearchTextChange(e.target.value)
            }}
            margin='normal'
            value={searchtext}
            name='searchtext'
            type='text'
            variant='outlined'
            size='small'
            placeholder={t('brandCatalouge.searchByProdOrSku')}
            InputProps={{
              startAdornment: <SearchIconWrapper><img src={SearchIcon} alt='search-icon' /></SearchIconWrapper>
            }}
          />
        </SearchInputWrapper>
      </Grid>
      <Grid item md={10} sm={16} xs={16}>
        <Grid container direction='row-reverse' columns={16}>
          {/* <Grid item md={3} sm={8} xs={8}>
                  <SelectWrapper>
                    <SelectLabel>Status</SelectLabel>
                    <MuiSelect
                      value='All'
                      name='brands'
                      size='small'
            // onSelect={(value) => { handleCategorySelected(value) }}
                      onChange={(e) => {}}
                      data={[{ id: 'All', name: 'All' }, { id: 'b', name: 'abcd' }]}
                      selectlabel='name'
                      selectvalue='id'
                      sx={{ minWidth: '100px' }}
                    />
                  </SelectWrapper>

                </Grid> */}
         {features['Brand_Product_Type_Filter'] && <Grid item md={3} sm={8} xs={8}>
            <SelectWrapper>
              <SelectLabel>{t('brandCatalouge.productType')}</SelectLabel>
              <MuiSelect
                name='productType'
                size='small'
                type='MULTIPLE_SELECT_COMBO_BOX'
                value={selectedProductType}
                onChange={(event, selectedData) => {
                  handleProductTypeSelect(
                    selectedData, event
                  )
                }}
                data={productTypeList}
                selectlabel='productType'
                selectvalue='productType'
                customSelectedChip={customSelectedChip}
                sx={{ minWidth: '100px' }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                dropdownMaxWidth="15%"
              />
            </SelectWrapper>
          </Grid>}
          {/*     <Grid item md={3} sm={8} xs={8}>
                  <SelectWrapper>
                    <SelectLabel>Sub Category</SelectLabel>
                    <MuiSelect
                      value='All'
                      name='brands'
                      size='small'
          // onSelect={(value) => { handleCategorySelected(value) }}
                      onChange={(e) => {}}
                      data={[{ id: 'All', name: 'All' }, { id: 'b', name: 'abcd' }]}
                      selectlabel='name'
                      selectvalue='id'
                      sx={{ minWidth: '100px' }}/>
                  </SelectWrapper>
                </Grid> */}
         {features['Brand_Category_Filter'] && <Grid item md={3} sm={8} xs={8}>
            <SelectWrapper>
              <SelectLabel>{t('addProduct.category')}</SelectLabel>
              <MuiSelect
                name='category'
                size='small'
                type='MULTIPLE_SELECT_COMBO_BOX'
                value={selectedCategory}
                onChange={(event, selectedData) => {
                  handleCategorySelect(
                    selectedData, event
                  )
                }}
                data={categoryList}
                selectlabel='name'
                selectvalue='categoryId'
                customSelectedChip={customSelectedChip}
                sx={{ width: '100px' }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                dropdownMaxWidth="15%"
              />
            </SelectWrapper>
          </Grid>}
        </Grid>
      </Grid>
    </Grid>
  )
}

FilterRow.displayName = 'BrandCatalougeFilterRowComponent'
export default FilterRow
