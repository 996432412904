import { isEmpty } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  ValidationSchema, ValidationMOVSchema, ValidationMOQSchema, ValidationBundleSchema,
} from '../components/Promo/Validation';
import { ALL, ANY, BUY_N_GET_X, MOQ, MOV, REGULAR, SAME } from '../constants/brandPromo';

const months = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER',
  'OCTOBER', 'NOVEMBER', 'DECEMBER'];

export const getYear = () => new Date().getFullYear();

export const getMonth = (format) => moment().format(format).toUpperCase();

export const updateObject = (input, key, value) => {
  const obj = [];
  if (input.hasOwnProperty(key)) {
    obj.push(value);
    input[`${key}`] = obj;
  } else {
    input[`${key}`] = [`${value}`];
  }
  return input;
};

export const sortByNumber = (array, key, sortType) => {
  if (sortType === 'AES') {
    return array.sort((a, b) => a[`${key}`] - b[`${key}`]);
  }
  return array.sort((a, b) => b[`${key}`] - a[`${key}`]);
};

export const sortDescending = (array, key) => array.sort((a, b) => b[`${key}`] - a[`${key}`]);

export const sortByText = (array, key, sortType) => {
  if (sortType === 'AES') {
    return array.sort((a, b) => a[`${key}`].localeCompare(b[`${key}`]));
  }
  return array.sort((a, b) => b[`${key}`].localeCompare(a[`${key}`]));
};

export const sortList = (array, key, sortType) => {
  let list = [];
  if (key === 'name') {
    list = sortByText(array, key, sortType);
  } else {
    list = sortByNumber(array, key, sortType);
  }
  return list;
};

export const numberFormat = (value, decimal = 2) => (value === 100 ? value : (Math.round(value * 100) / 100).toFixed(decimal));

export const addPreFix = (value) => {
  const num = numberFormat(value);
  if (num > 0) {
    return `+${num}`;
  } if (num < 0) {
    return `${num}`;
  }
  return num;
};

export const getBarData = (barChart) => barChart.map((item) => ({
  name: item.label,
  totalRevenue: item?.data?.revenue,
  shippingCost: item?.data?.shippingCost,
  unitsSold: item?.data?.unitsSold,
  revenueChange: item?.data?.revenueChange?.value,
  shippingCostChange: item?.data?.shippingCostChange?.value,
}));

export const getShortMonth = (text) => {
  const index = months.findIndex((x) => x === text);
  const name = moment(index + 1, 'M').format('MMM');
  return name.toUpperCase();
};

export const camalize = (str) => {
  const char = str.substring(0, 1);
  return `${char.toUpperCase()}${str.substring(1, str.length)}`;
};

export const numberFormatter = (num, i18n) => {
  let billion = 'B';
  let million = 'M';
  if (i18n && i18n.language === 'id') {
    billion = 'M';
    million = 'Jt';
  }
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + billion;
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + million;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  if (num > 0) {
    const value = num % 1 !== 0;
    return value ? numberFormat(num) : num;
  }
  return num;
};

export const getMonths = () => months.map((item) => ({ id: item, name: camalize(item.toLowerCase()) }));

export const getYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  let startYear = 2022;
  for(let i = startYear; i <= currentYear; i++){
      years.push(startYear++);
    }
    return years.map((item) => ({ id: item, name: item }));;
}

export const checkIsExist = (input, key) => input.hasOwnProperty(key);

export const truncateText = (source, size) => (source.length > size ? `${source.slice(0, size - 1)}…` : source);

export const numberWithCommas = (num) => num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const removeKey = (input, key) => {
  delete input[key];
  return input;
};

export const removeElement = (arr, index) => {
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const removeObj = (arr, key, value) => {
  const index = arr.findIndex((x) => x[`${key}`] === value);
  return removeElement(arr, index);
};

export const removeList = (arr, key, value) => {
  const list = arr.filter((x) => x[`${key}`] !== value);
  return list;
};

export const addMOQ = (arr, sku, id) => {
  const items = arr.filter((model) => model.name === sku);
  let initial = false;
  if (items.length > 1) {
    initial = true;
  }
  const obj = {
    skuId: sku,
    name: id,
    qty: '',
    discount: '',
    discountType: '',
    isAdd: true,
    isDelete: initial,
    isParent: true,
  };
  arr.push(obj);
  return arr;
};

export const addMOV = (arr, min, discount, isDelete) => {
  const obj = {
    min,
    discount,
    discountType: '',
    isAdd: true,
    isDelete,
    isParent: true,
  };
  arr.push(obj);
  return arr;
};

export const loadInitial = (offerType, defaultSelectedPromoType) => {
  const INIT_DEFAULT = {
    offer: defaultSelectedPromoType || offerType || '',
    startDate: '',
    endDate: '',
    sku: '',
    discount: '',
  };

  const INIT_MOV = {
    offer: defaultSelectedPromoType || offerType || '',
    startDate: '',
    endDate: '',
  };

  const INIT_MOQ = {
    offer: defaultSelectedPromoType || offerType || '',
    startDate: '',
    endDate: '',
    sku: '',
  };

  const INIT_BUNDLE = {
    offer: defaultSelectedPromoType || offerType || '',
    startDate: '',
    endDate: '',
    paidsku: '',
    showSku2: false,
    paidsku2: '',
    freesku: [''],
    selectionCriteria: 'ANY',
    freeSelectionCriteria: 'ALL',
  };

  if (offerType === MOQ) {
    return INIT_MOQ;
  } if (offerType === MOV) {
    return INIT_MOV;
  } if (offerType === BUY_N_GET_X) {
    return INIT_BUNDLE;
  }
  return INIT_DEFAULT;
};

export const loadValidation = (offerType, t) => {
  if (offerType === MOV) {
    return ValidationMOVSchema(t);
  } if (offerType === MOQ) {
    return ValidationMOQSchema(t);
  } if (offerType === BUY_N_GET_X) {
    return ValidationBundleSchema(t);
  }
  return ValidationSchema(t);
};

export const getMovList = (arr) => arr.map((item) => ({
  mov: item.min, discount: item.discount, discountType: 'PERCENTAGE_RSP',
}));

export const getMOQList = (arr, skus, promoSkus) => {
  skus?.forEach((element) => {
    const items = arr.filter((item) => item.name === element.id).map((model) => ({
      moq: model.moq, discount: model.discount, discountType: 'PERCENTAGE_RSP',
    }));
    const obj = {
      sku: element.id,
      name: element.name.split(' : ')[1],
      moq: items,
    };
    promoSkus.push(obj);
  });
  return promoSkus;
};

export const checkIsValueExist = (arr, name, qty) => {
  const demo = JSON.parse(JSON.stringify(arr));
  const list = demo.filter((item) => (item[`${name}`] === '') || item[`${qty}`] === '');
  if (list.length > 0) {
    return false;
  }
  return true;
};

export const checkIsMoqValueExist = (arr, name, qty) => {
  const demo = JSON.parse(JSON.stringify(arr));
  const list = demo.map((item) => {
    const obj = item;
    const items = obj?.moq.filter((model) => model[`${name}`] === '' || model[`${qty}`] === '');
    obj.moq = items;
    return { ...obj };
  }).filter((model) => model[`${name}`].length > 0);
  if (list.length > 0) {
    return false;
  }
  return true;
};

export const checkIsQtyValueExist = (arr, updateList, IsFreeSkuCheck) => {
  // const demo = JSON.parse(JSON.stringify(arr));
  let isError = true;
  const list = arr.map((item) => {
    if (isEmpty(item?.qty) || !item?.qty?.[0] || ( item?.selectionCriteria !== ANY && item?.selectionCriteria !== SAME  && IsFreeSkuCheck && item?.qty?.length !== item?.promoSkus?.length) ) {
      isError = false;
      return { ...item, isQtyError: true };
    }
    return { ...item, isQtyError: false  };
  });
  updateList(list);

  return isError;
};


export const checkValuesForFreeSku = (arr, updateList) => {
  // const demo = JSON.parse(JSON.stringify(arr));
  let isError = true;
  let isPromo1Error = false;
  let isQty1Error = false;
  let isPromo2Error = false;
  let isQty2Error = false;

  const list = arr.map((item) => {
    if(item?.selectionCriteria !== ANY && item?.selectionCriteria !== SAME && isEmpty(item?.promoSkus)) {
      isError = false;
      isPromo1Error = true;
    } 
    if (isEmpty(item?.qty) || !item?.qty?.[0]) {
      isError = false;
      isQty1Error = true;
    }
    if(item?.selectionCriteria !== ANY && item?.selectionCriteria !== SAME  && item?.qty?.length > item?.promoSkus?.length){
      isError = false;
      isPromo2Error = true;
    }
    if(item?.selectionCriteria !== ANY && item?.selectionCriteria !== SAME  && item?.qty?.length < item?.promoSkus?.length){
      isError = false;
      isQty2Error = true;
    }

    return { ...item, isPromo1Error, isQty1Error, isPromo2Error, isQty2Error };
  });
  updateList(list);

  return isError;
};

export const removeErrorKeys = (arr) => {
  const list = arr?.map(({promoSkus, isQtyError, ...item}) => {
    const updatedPromos = promoSkus?.map(({isError, ...sku}) => sku)
    return {...item, promoSkus: updatedPromos}
  });
return list;
};

export const getAllMOQ = (arr) => {
  const demo = JSON.parse(JSON.stringify(arr));
  const list = [];
  demo.forEach((item) => {
    const models = item?.moq?.map((model, index) => {
      const obj = {
        skuId: `${item.sku} : ${item.name}`,
        name: item.sku,
        moq: model.moq,
        discount: model.discount,
        discountType: '',
        isAdd: (index === item?.moq.length - 1),
        isDelete: !!((index !== 0 && index === item?.moq.length - 1)),
        isParent: (index === 0),
      };
      return { ...obj };
    });
    list.push(...models);
  });

  return list;
};

export const checkValuePercentage = (arr, index, min, qty, isQty) => {
  const current = arr[index];
  let isDiscountFailed = false;
  for (let i = 0; i < arr.length; i++) {
    if (isQty) {
      if (index > 0 && index !== i && Number(current[`${qty}`]) === Number(arr[i][`${qty}`])) {
        isDiscountFailed = true;
      }
    }
    if (Number(current[`${min}`]) > 0 && Number(current[`${min}`]) < Number(arr[i][`${min}`])
    && Number(current[`${qty}`]) > 0 && Number(current[`${qty}`]) >= Number(arr[i][`${qty}`])) {
      isDiscountFailed = true;
    }
    if (Number(current[`${min}`]) > 0 && Number(current[`${min}`]) > Number(arr[i][`${min}`])
    && Number(current[`${qty}`]) > 0 && Number(current[`${qty}`]) <= Number(arr[i][`${qty}`])) {
      isDiscountFailed = true;
    }
  }
  return isDiscountFailed;
};

export const checkMoqValue = (arr, index, item, min, qty, isQty) => {
  const items = arr.filter((x) => x.name === arr[index].name);
  let isDiscountFailed = false;
  const current = item;
  if (items.length > 1) {
    for (let i = 0; i < items.length; i++) {
      if (isQty) {
        if (index > 0 && index !== i && Number(current[`${qty}`]) === Number(arr[i][`${qty}`])) {
          isDiscountFailed = true;
        }
      }

      if (Number(current[`${min}`]) > 0 && Number(current[`${min}`]) < Number(items[i][`${min}`])
      && Number(current[`${qty}`]) > 0 && Number(current[`${qty}`]) >= Number(items[i][`${qty}`])) {
        isDiscountFailed = true;
      }
      if (Number(current[`${min}`]) > 0 && Number(current[`${min}`]) > Number(items[i][`${min}`])
      && Number(current[`${qty}`]) > 0 && Number(current[`${qty}`]) <= Number(items[i][`${qty}`])) {
        isDiscountFailed = true;
      }
    }
  }
  return isDiscountFailed;
};

export const MOQ_VALUES = (t) => {
  const moq = [t('createPromo.sku'), t('createPromo.min'), t('createPromo.discountRsp')];
  return moq;
};

export const MOV_VALUES = (t) => {
  const mov = [t('createPromo.minorder'), t('createPromo.discountRsp')];
  return mov;
};

export const checkISDisable = (selectedDate) => {
    const selected = new Date(selectedDate);
    const current = new Date().setDate(new Date().getDate());
    if (selected < current) {
        return true
    }
    return false
}

export const checkIsRunning = (selectedStartDate, selectedEndDate) => {
  const startDate = new Date(selectedStartDate);
  const endDate = new Date(selectedEndDate);
  const currentDate = new Date().setDate(new Date().getDate() + 1);
  if (startDate <= currentDate && currentDate <= endDate) {
    return true;
  }
  return false;
};

export const showToast = (message, time) => {
  toast.error(message, { autoClose: time });
};

export const getOfferText = (arr, t, type) => {
  let text = t('brandPromo.offerApplies');
  let count = 0;
  if (arr && type === MOV) {
    count = arr.length;
  }
  if (arr && type === MOQ) {
    arr.forEach((item) => count += item.moq.length);
  }
  if (count > 1) {
    text = `${count} ${t('brandPromo.offersApplied')}`;
  }
  return text;
};

export const convertText = (str) => {
  if (str === 'moq' || str === 'mov') {
    return str.toUpperCase();
  }
  if(str === 'buy_n_get_x'){
    return str.split("_").map((item) => camalize(item)).join(' ')
  }
  return camalize(str);
};

export const getDescription = (str, t) => {
  let description = '';
  switch (str) {
    case REGULAR:
      description = t('createPromo.regular');
      break;

    case MOV:
      description = t('createPromo.mov');
      break;

    case MOQ:
      description = t('createPromo.moq');
      break;
    case BUY_N_GET_X:
      description = t('createPromo.bundle');
      break;
    default:
      break;
  }
  return description;
};

export const addDefaultItem = (title) => {
  const defaultItem = { label: title, id: '', isSelected: false };
  return defaultItem;
};

export const getFilterList = (arr, filters) => {
  const data = [];
  arr.forEach((item) => {
    const demo = filters.filter((x) => x.label === item.label);
    if (demo.length > 0) {
      data.push(demo[0]);
    } else {
      data.push(item);
    }
  });

  return data;
};

export const getSkuList = (arr, sku) => arr.filter((item) => (
  item.isSelected && (item.label !== sku))).map((x) => (x.label));

export const getSkusList = (arr, filters) => {
  const promoList = filters.map((item) => ({ label: item.name, value: item.id, id: item.id, isSelected: true }));
  const data = [];
  arr.forEach((item) => {
    const demo = promoList.filter((x) => x.label === item.label);
    if (demo.length > 0) {
      data.push(demo[0]);
    } else {
      data.push(item);
    }
  });
  return data;
};

export const getIndonesianCurrency = (value) => value;
// const data = value.toString();
// if (data.length > 3) {
//   let count = Math.trunc(data.length / 3);
//   const mod = data.length % 3;
//   if (mod === 0) {
//     count = count - 1;
//   }
//   let output = data;
//   let position = data.length - 3;
//   while (count > 0) {
//     output = [output.slice(0, position), "#", output.slice(position)].join("");
//     position = position - 3;
//     count--;
//   }
//   output = output.replaceAll(/[.]/ig, ',').replaceAll(/[#]/ig, '.');
//   return output;
// } else {
//   return data;
// }

export const getName = (str, delimeter) => {
  if (str.includes(delimeter)) {
    return str.split(delimeter)[1].trim();
  }
  return str;
};

export const getEmptySku = (skuCriteria = ALL) => (
  {
    promoSkus: [],
    qty: [],
    selectionCriteria: skuCriteria
  }
);
