import { styled } from '@mui/system'
import { Link, Box, Typography } from '@mui/material'

const media = {
  large_laptop: '@media(max-width: 2560px)',
  laptop: '@media(max-width: 1100px)',
  tablet: '@media(max-width: 768px)',
  mobile: '@media(max-width: 600px)'
}
export const Main = styled('div')(`
  ${media.large_laptop} {
  }
`)
export const ButtonCenter = styled('div')`
 text-align: center;
 margin: 3vh 0px;
`
export const DivWraper = styled('div')(`
  background-color: white;
  height: 30vh;
  margin: 0 auto;
  position: absolute;
`)
export const OuterContent = styled(Link)((props) => `
  color: blue;
  text-decoration:none;
   /* ${!props.isSidebarVisible && `
      width: 100%;
    `} */
`)

export const BoxWraper = styled(Box)`
  position: absolute;
  top: 50vh;
  left: 53%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
  background-color:white;
  padding: 0px 30px;
  border-radius:15px;
  ${media.laptop}{
    height: auto;
    left: 50%;
}
${media.tablet}{
    height: auto;
    width: 80%;
    left: 50%;
}
  ${media.mobile}{
    height: auto;
    width: 75%;
    left: 48%;
} 
`

export const CloseIconWrapper = styled('div')(`
  position: absolute;
  right: 20px;
  margin-top: 20px;

`)

export const TypographyWraperCenter = styled(Typography)`
margin-top: 35px;
/* font-family: Noto Sans; */
font-size: 30px;
font-weight: 700;
text-align: center;
${media.mobile}{
  font-size: 15px;
}
`
export const TypographyWraper = styled(Typography)`
font-size: 14px;
margin-top: 12px;
letter-spacing: 0.4px;
${media.mobile}{
  font-size: 13px;
}
`

export const TypographyWraperBold = styled(Typography)`
font-size: 14px;
margin-top: 12px;
font-weight: 600;
letter-spacing: 0.4px;
${media.mobile}{
  font-size: 13px;
}
`

export const BlockWraper = styled('div')`
background-color: #F2F7FF;
padding:5px 13px;
`
