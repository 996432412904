import { List, Map } from 'immutable'

import {
  ANALYTICS_LOADING,
  ANALYTICS_GET_FAILURE,
  ANALYTICS_GET_SUCCESS,
  ANALYTICSOSP_LIST_LOADING,
  ANALYTICSOSP_LIST_GET_FAILURE,
  ANALYTICSOSP_LIST_GET_SUCCESS,
  GET_ANALYTICS_ORDER_STATUS_LOADING,
  GET_ANALYTICS_ORDER_STATUS_SUCCESS,
  GET_ANALYTICS_ORDER_STATUS_FAILURE,
  ANALYTICS_TOTAL_ORDER_LOADING,
  ANALYTICS_TOTAL_ORDER_SUCCESS,
  ANALYTICS_TOTAL_ORDER_FAILURE,
  ANALTYICS_REVENUE_SUMMARY_LOADING,
  ANALTYICS_REVENUE_SUMMARY_SUCCESS,
  ANALTYICS_REVENUE_SUMMARY_FAILURE,
  ANALTYICS_RECENT_ORDERS_LOADING,
  ANALTYICS_RECENT_ORDERS_SUCCESS,
  ANALTYICS_RECENT_ORDERS_FAILURE
} from '../actions'

const initialState = new Map({
  isLoading: false,
  error: '',
  content: Map({}),
  isLoadingOSP: false,
  errorOSP: '',
  contentOSP: List([]),
  isLoadingOrderStatus: false,
  errorOrderStatus: '',
  contentOrderStatus: List([]),
  isLoadingTotalOrder: false,
  errorTotalOrder: '',
  contentTotalOrder: List([]),
  isLoadingRevenueSummary: false,
  errorRevenueSummary: '',
  contentRevenueSummary: List([]),
  isLoadingRecentOrder: false,
  errorRecentOrder: '',
  contentRecentOrder: List([])
})

export const analytics = (state = initialState, action) => {
  const {
    data,
    isLoading,
    isLoadingOSP,
    isLoadingOrderStatus,
    isLoadingTotalOrder,
    isLoadingRevenueSummary,
    isLoadingRecentOrder,
    message,
    type
  } = action

  switch (type) {
    case ANALYTICS_LOADING:
      return state.set('isLoading', isLoading)
    case ANALYTICS_GET_SUCCESS:
      return state.merge({
        content: Map(data || {})
      })
    case ANALYTICS_GET_FAILURE:
      return state.set('error', message)

    case ANALYTICSOSP_LIST_LOADING:
      return state.set('isLoadingOSP', isLoadingOSP)
    case ANALYTICSOSP_LIST_GET_SUCCESS:
      return state.merge({
        contentOSP: List(data || [])
      })
    case ANALYTICSOSP_LIST_GET_FAILURE:
      return state.set('errorOSP', message)

    case GET_ANALYTICS_ORDER_STATUS_LOADING:
      return state.set('isLoadingOrderStatus', isLoadingOrderStatus)
    case GET_ANALYTICS_ORDER_STATUS_SUCCESS:
      return state.merge({
        contentOrderStatus: List(data || [])
      })
    case GET_ANALYTICS_ORDER_STATUS_FAILURE:
      return state.set('errorOrderStatus', message)

    case ANALYTICS_TOTAL_ORDER_LOADING:
      return state.set('isLoadingTotalOrder', isLoadingTotalOrder)
    case ANALYTICS_TOTAL_ORDER_SUCCESS:
      return state.merge({
        contentTotalOrder: Map(data || {})
      })
    case ANALYTICS_TOTAL_ORDER_FAILURE:
      return state.set('errorTotalOrder', message)

    case ANALTYICS_REVENUE_SUMMARY_LOADING:
      return state.set('isLoadingRevenueSummary', isLoadingRevenueSummary)
    case ANALTYICS_REVENUE_SUMMARY_SUCCESS:
      return state.merge({
        contentRevenueSummary: List(data || [])
      })
    case ANALTYICS_REVENUE_SUMMARY_FAILURE:
      return state.set('errorRevenueSummary', message)

    case ANALTYICS_RECENT_ORDERS_LOADING:
      return state.set('isLoadingRecentOrder', isLoadingRecentOrder)
    case ANALTYICS_RECENT_ORDERS_SUCCESS:
      return state.merge({
        contentRecentOrder: List(data || [])
      })
    case ANALTYICS_RECENT_ORDERS_FAILURE:
      return state.set('errorRecentOrder', message)

    default:
      return state
  }
}
