import React from 'react'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Wrapper } from './style'

// Multiple Info About Library
// Zoom, Flip, Bounce = transition
// toast. info,error, success, warn
// https://fkhadra.github.io/react-toastify/introduction

const AppWrapper = ({
  children, theme, position, autoClose, hideProgressBar, newestOnTop, rtl, transition
}) => {
  return (
    <Wrapper>
      {children}
      <ToastContainer
        theme={theme || 'colored'}
        position={position || 'bottom-center'}
        autoClose={autoClose || 4000}
        hideProgressBar={hideProgressBar || 'true'}
        newestOnTop={newestOnTop || false}
        closeOnClick='true'
        rtl={rtl || false}
        // draggable
        pauseOnHover='true'
        // limit='3'
        transition={transition || Slide}
        // progress='0'
      />
    </Wrapper>
  )
}

AppWrapper.displayName = 'AppWrapper'
export default AppWrapper
