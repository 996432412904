import { call, put, takeLatest } from 'redux-saga/effects'
import { messageStrToArray } from '../utilities/app'

import {
  PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST,
  PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_FAILURE,
  PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_ALL_LIST,
  PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_FAILURE,
  PAGEBUILDER_RAENAPRODUCTS_ADD_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_ADD,
  PAGEBUILDER_RAENAPRODUCTS_ADD_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_ADD_FAILURE,
  PAGEBUILDER_RAENAPRODUCTS_UPDATE_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_UPDATE,
  PAGEBUILDER_RAENAPRODUCTS_UPDATE_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_UPDATE_FAILURE,
  PAGEBUILDER_RAENAPRODUCTS_DELETE_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_DELETE,
  PAGEBUILDER_RAENAPRODUCTS_DELETE_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_DELETE_FAILURE
} from '../actions'

import {
  API_GET_PAGEBUILDER_RAENAPRODUCTS_RESELLER,
  API_GET_PAGEBUILDER_RAENAPRODUCTS_ALL,
  API_GET_PAGEBUILDER_RAENAPRODUCTS_UPDATE,
  API_GET_PAGEBUILDER_RAENAPRODUCTS_DELETE,
  API_CREATE_PAGEBUILDER_LINKS
} from '../constants'
import { getApi, postApi } from '../utilities/axios'
import { toast } from 'react-toastify'

function * onGetPageBuilderRaenaProductResellerList (action) {
  yield put({ type: PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_LOADING, pageBuilderRaenaProductsResellerIsLoading: true })
  try {
    const query = {
      name: action.payload?.name || null,
      brandIds: action.payload?.brandIds || null,
      page: action.payload?.page || null,
      limit: action.payload?.limit || null,
      sku: action.payload?.sku || null
    }
    const response = yield call(getApi, API_GET_PAGEBUILDER_RAENAPRODUCTS_RESELLER(action.payload?.shareId), query)
    yield put({ type: PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_SUCCESS, pageBuilderRaenaProductsResellerList: response?.data?.data || [] })
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_FAILURE, pageBuilderRaenaProductsResellerList: [] })
  }
  yield put({ type: PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_LOADING, pageBuilderRaenaProductsResellerIsLoading: false })
}

function * onGetPageBuilderRaenaProductAllList (action) {
  yield put({ type: PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_LOADING, pageBuilderRaenaProductsAllIsLoading: true })
  try {
    const query = {
      name: action.payload?.name || null,
      brandIds: action.payload?.brandIds || null,
      page: action.payload?.page || null,
      limit: action.payload?.limit || null
    }
    const response = yield call(getApi, API_GET_PAGEBUILDER_RAENAPRODUCTS_ALL, query)
    yield put({ type: PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_SUCCESS, pageBuilderRaenaProductsAllList: response?.data?.data || {} })
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_FAILURE, pageBuilderRaenaProductsAllList: {} })
  }
  yield put({ type: PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_LOADING, pageBuilderRaenaProductsAllIsLoading: false })
}

function * onGetPageBuilderRaenaProductsAddData (action) {
  yield put({ type: PAGEBUILDER_RAENAPRODUCTS_ADD_LOADING, pageBuilderRaenaProductsAddIsLoading: true })
  try {
    const query = {
      imageUrl: action.payload?.imageUrl?.[0]?.url || null,
      price: action.payload?.price,
      title: action.payload?.title,
      url: action.payload?.url
    }
    const response = yield call(postApi, API_CREATE_PAGEBUILDER_LINKS(action.payload?.linkType), query)
    yield put({ type: PAGEBUILDER_RAENAPRODUCTS_ADD_SUCCESS, pageBuilderRaenaProductsAddData: response?.data?.data || {} })
    toast.success(`Your ${action.payload?.msgType} has been added successfully`)
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_RAENAPRODUCTS_ADD_FAILURE, pageBuilderRaenaProductsAddData: {} })
  }
  yield put({ type: PAGEBUILDER_RAENAPRODUCTS_ADD_LOADING, pageBuilderRaenaProductsAddIsLoading: false })
}

function * onGetPageBuilderRaenaProductsUpdateData (action) {
  const successMessage = () => {
    if (action.payload?.type === 'add') {
      return 'Product added to your store'
    } else if (action.payload?.type === 'status') {
      return 'Your Product status has been changed'
    } else {
      return 'Product updated successfully'
    }
  }
  yield put({ type: PAGEBUILDER_RAENAPRODUCTS_UPDATE_LOADING, pageBuilderRaenaProductsUpdateIsLoading: true })
  try {
    const query = {
      sku: action.payload?.sku,
      shareId: action.payload?.shareId,
      margin: action.payload?.margin,
      isPublished: action.payload?.isPublished,
      isPinned: action.payload?.isPinned
    }
    const response = yield call(postApi, API_GET_PAGEBUILDER_RAENAPRODUCTS_UPDATE, query)
    yield put({ type: PAGEBUILDER_RAENAPRODUCTS_UPDATE_SUCCESS, pageBuilderRaenaProductsUpdateData: response?.data?.data || {} })
    toast.success(successMessage)
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_RAENAPRODUCTS_UPDATE_FAILURE, pageBuilderRaenaProductsUpdateData: {} })
  }
  yield put({ type: PAGEBUILDER_RAENAPRODUCTS_UPDATE_LOADING, pageBuilderRaenaProductsUpdateIsLoading: false })
}

function * onGetPageBuilderRaenaProductsDeleteData (action) {
  yield put({ type: PAGEBUILDER_RAENAPRODUCTS_DELETE_LOADING, pageBuilderRaenaProductsDeleteIsLoading: true })
  try {
    const query = {
      sku: action.payload?.sku,
      shareId: action.payload?.shareId
    }
    const response = yield call(postApi, API_GET_PAGEBUILDER_RAENAPRODUCTS_DELETE, query)
    yield put({ type: PAGEBUILDER_RAENAPRODUCTS_DELETE_SUCCESS, pageBuilderRaenaProductsDeleteData: response?.data?.data || {} })
    toast.success('Your Product is Deleted successfully')
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_RAENAPRODUCTS_DELETE_FAILURE, pageBuilderRaenaProductsDeleteData: {} })
  }
  yield put({ type: PAGEBUILDER_RAENAPRODUCTS_DELETE_LOADING, pageBuilderRaenaProductsDeleteIsLoading: false })
}

function * pageBuilderSaga () {
  yield takeLatest(PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST, onGetPageBuilderRaenaProductResellerList)
  yield takeLatest(PAGEBUILDER_RAENAPRODUCTS_ALL_LIST, onGetPageBuilderRaenaProductAllList)
  yield takeLatest(PAGEBUILDER_RAENAPRODUCTS_ADD, onGetPageBuilderRaenaProductsAddData)
  yield takeLatest(PAGEBUILDER_RAENAPRODUCTS_UPDATE, onGetPageBuilderRaenaProductsUpdateData)
  yield takeLatest(PAGEBUILDER_RAENAPRODUCTS_DELETE, onGetPageBuilderRaenaProductsDeleteData)
}

export default pageBuilderSaga
