import { logEvent } from 'firebase/analytics'
import EventEmitter, { Events } from './eventEmitter'
import { analytics } from './firebase'

function sampleEvent ({ data }) {
  try {
    logEvent(analytics, Events.SAMPLE_EVENT, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function loginEvent ({ data }) {
  try {
    logEvent(analytics, Events.SAAS_LOGIN, { ...data })
    window.clevertap.event.push(Events.SAAS_LOGIN, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function signUpEvent () {
  try {
    logEvent(analytics, Events.SAAS_SIGNUP)
    window.clevertap.event.push(Events.SAAS_SIGNUP)
  } catch (err) {
    console.log(err)
  }
}

function enterOTPEvent () {
  try {
    logEvent(analytics, Events.ENTER_OTP)
    window.clevertap.event.push(Events.ENTER_OTP)
  } catch (err) {
    console.log(err)
  }
}

function connectStoreEvent ({ data }) {
  try {
    logEvent(analytics, Events.CONNECT_STORE, { ...data })
    window.clevertap.event.push(Events.CONNECT_STORE, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function createNewStoreOkEvent () {
  try {
    logEvent(analytics, Events.CREATE_NEW_STORE_OK)
    window.clevertap.event.push(Events.CREATE_NEW_STORE_OK)
  } catch (err) {
    console.log(err)
  }
}

function addProductEvent ({ page }) {
  try {
    logEvent(analytics, Events.ADD_PRODUCT, { page })
    window.clevertap.event.push(Events.ADD_PRODUCT, { page })
  } catch (err) {
    console.log(err)
  }
}

function selectStoreEvent ({ data }) {
  try {
    logEvent(analytics, Events.SELECT_STORE, { ...data })
    window.clevertap.event.push(Events.SELECT_STORE, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function registerNowEvent () {
  try {
    logEvent(analytics, Events.REGISTER_NOW)
    window.clevertap.event.push(Events.REGISTER_NOW)
  } catch (err) {
    console.log(err)
  }
}

function createNewStoreEvent () {
  try {
    logEvent(analytics, Events.CREATE_NEW_STORE)
    window.clevertap.event.push(Events.CREATE_NEW_STORE)
  } catch (err) {
    console.log(err)
  }
}

function createNewStoreInstant () {
  try {
    logEvent(analytics, Events.CREATE_NEW_STORE_INSTANT)
    window.clevertap.event.push(Events.CREATE_NEW_STORE_INSTANT)
  } catch (err) {
    console.log(err)
  }
}

function contactNowEvent () {
  try {
    logEvent(analytics, Events.CONTACT_NOW)
    window.clevertap.event.push(Events.CONTACT_NOW)
  } catch (err) {
    console.log(err)
  }
}

function videoPlayEvent () {
  try {
    logEvent(analytics, Events.VIDEO_PLAY)
    window.clevertap.event.push(Events.VIDEO_PLAY)
  } catch (err) {
    console.log(err)
  }
}

function addFromPartnerEvent () {
  try {
    logEvent(analytics, Events.PRODUCT_ADD_FROM_PARTNER)
    window.clevertap.event.push(Events.PRODUCT_ADD_FROM_PARTNER)
  } catch (err) {
    console.log(err)
  }
}

function productLiveListEvent () {
  try {
    logEvent(analytics, Events.PRODUCT_LIVE_LIST)
    window.clevertap.event.push(Events.PRODUCT_LIVE_LIST)
  } catch (err) {
    console.log(err)
  }
}

function syncProductRaena () {
  try {
    logEvent(analytics, Events.PRODUCT_SYNC_RAENA)
    window.clevertap.event.push(Events.PRODUCT_SYNC_RAENA)
  } catch (err) {
    console.log(err)
  }
}

function syncProductSelf () {
  try {
    logEvent(analytics, Events.PRODUCT_SYNC_SELF)
    window.clevertap.event.push(Events.PRODUCT_SYNC_SELF)
  } catch (err) {
    console.log(err)
  }
}

function productAllListEvent () {
  try {
    logEvent(analytics, Events.PRODUCT_ALL_LIST)
    window.clevertap.event.push(Events.PRODUCT_ALL_LIST)
  } catch (err) {
    console.log(err)
  }
}

function productDraftListEvent () {
  try {
    logEvent(analytics, Events.PRODUCT_DRAFT_LIST)
    window.clevertap.event.push(Events.PRODUCT_DRAFT_LIST)
  } catch (err) {
    console.log(err)
  }
}

function productViewDetailEvent ({ ...data }) {
  try {
    logEvent(analytics, Events.PRODUCT_VIEWDETAIL, { ...data })
    window.clevertap.event.push(Events.PRODUCT_VIEWDETAIL, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function productEditStockEvent ({ ...data }) {
  try {
    logEvent(analytics, Events.PRODUCT_EDITSTOCK, { ...data })
    window.clevertap.event.push(Events.PRODUCT_EDITSTOCK, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function productEditListingEvent ({ data }) {
  try {
    logEvent(analytics, Events.PRODUCT_EDIT_LISTING, { ...data })
    window.clevertap.event.push(Events.PRODUCT_EDIT_LISTING, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function productAddToStoreEvent ({ data }) {
  try {
    logEvent(analytics, Events.PRODUCT_ADD_TO_STORES, { ...data })
    window.clevertap.event.push(Events.PRODUCT_ADD_TO_STORES, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function productSearchEvent ({ data }) {
  try {
    logEvent(analytics, Events.PRODUCT_SEARCH_TERM, { ...data })
    window.clevertap.event.push(Events.PRODUCT_SEARCH_TERM, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function viewOrderDetailEvent ({ data }) {
  try {
    logEvent(analytics, Events.VIEW_ORDER_DETAIL, { ...data })
    window.clevertap.event.push(Events.VIEW_ORDER_DETAIL, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function orderClickCTAEvent ({ data }) {
  try {
    logEvent(analytics, Events.ORDER_CLICK_CTA, { ...data })
    window.clevertap.event.push(Events.ORDER_CLICK_CTA, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function orderPendingListEvent () {
  try {
    logEvent(analytics, Events.ORDER_PENDING_LIST)
    window.clevertap.event.push(Events.ORDER_PENDING_LIST)
  } catch (err) {
    console.log(err)
  }
}

function orderCancelledListEvent () {
  try {
    logEvent(analytics, Events.ORDER_CANCELLED_LIST)
    window.clevertap.event.push(Events.ORDER_CANCELLED_LIST)
  } catch (err) {
    console.log(err)
  }
}

function orderCompletedListEvent () {
  try {
    logEvent(analytics, Events.ORDERS_COMPLETED_LIST)
    window.clevertap.event.push(Events.ORDERS_COMPLETED_LIST)
  } catch (err) {
    console.log(err)
  }
}

function orderSearchTermEvent ({ data }) {
  try {
    logEvent(analytics, Events.ORDER_SEARCH_TERM, { ...data })
    window.clevertap.event.push(Events.ORDER_SEARCH_TERM, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function tabEvents (eventName, page) {
  try {
    logEvent(analytics, eventName, { page })
    window.clevertap.event.push(eventName, { page })
  } catch (err) {
    console.log(err)
  }
}

function tabDashBoardEvent ({ page }) {
  tabEvents(Events.TABS_DASHBOARD, page)
}

function tabConnectionEvent ({ page }) {
  tabEvents(Events.TABS_CONNECTIONS, page)
}

function tabAnalyticsEvent ({ page }) {
  tabEvents(Events.TABS_ANALYTICS, page)
}

function tabProductEvent ({ page }) {
  tabEvents(Events.TABS_PRODUCT, page)
}

function tabOrdersEvent ({ page }) {
  tabEvents(Events.TABS_ORDERS, page)
}

function tabCustomersEvent ({ page }) {
  tabEvents(Events.TABS_CUSTOMERS, page)
}

function tabSuperStoreEvent ({ page }) {
  tabEvents(Events.TABS_SUPERSTORE, page)
}

function tabSettingsEvent ({ page }) {
  tabEvents(Events.TABS_SETTINGS, page)
}

function tabMyStoreEvent ({ page }) {
  tabEvents(Events.TABS_MYSTORE, page)
}

function dsfAboutUsSaveEvent () {
  try {
    logEvent(analytics, Events.DSF_ABOUTUS_SAVE)
    window.clevertap.event.push(Events.DSF_ABOUTUS_SAVE)
  } catch (err) {
    console.log(err)
  }
}

function dsfAboutUsCopyEvent () {
  try {
    logEvent(analytics, Events.DSF_ABOUTUS_COPY)
    window.clevertap.event.push(Events.DSF_ABOUTUS_COPY)
  } catch (err) {
    console.log(err)
  }
}

function dsfLinksEvent () {
  try {
    logEvent(analytics, Events.DSF_LINKS)
    window.clevertap.event.push(Events.DSF_LINKS)
  } catch (err) {
    console.log(err)
  }
}

function dsfAboutUsEvent () {
  try {
    logEvent(analytics, Events.DSF_ABOUTUS)
    window.clevertap.event.push(Events.DSF_ABOUTUS)
  } catch (err) {
    console.log(err)
  }
}

function dsfAppearanceEvent () {
  try {
    logEvent(analytics, Events.DSF_APPEARANCE)
    window.clevertap.event.push(Events.DSF_APPEARANCE)
  } catch (err) {
    console.log(err)
  }
}

function dsfLinksLinkEvent () {
  try {
    logEvent(analytics, Events.DSF_LINKS_LINK_SAVE)
    window.clevertap.event.push(Events.DSF_LINKS_LINK_SAVE)
  } catch (err) {
    console.log(err)
  }
}

function dsfLinksImageEvent () {
  try {
    logEvent(analytics, Events.DSF_LINKS_IMAGE)
    window.clevertap.event.push(Events.DSF_LINKS_IMAGE)
  } catch (err) {
    console.log(err)
  }
}

function dsfLinksReorderEvent () {
  try {
    logEvent(analytics, Events.DSF_LINKS_REORDER)
    window.clevertap.event.push(Events.DSF_LINKS_REORDER)
  } catch (err) {
    console.log(err)
  }
}

function dsfLinksAboutUsImageUploadEvent () {
  try {
    logEvent(analytics, Events.DSF_LINKS_ABOUTUS_IMAGEUPLOAD)
    window.clevertap.event.push(Events.DSF_LINKS_ABOUTUS_IMAGEUPLOAD)
  } catch (err) {
    console.log(err)
  }
}

function dsfAdminLinkAddedEvent ({ data }) {
  try {
    logEvent(analytics, Events.DSFADMIN_LINKADDED, { ...data })
    window.clevertap.event.push(Events.DSFADMIN_LINKADDED, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function dsfAdminLinkEditedEvent ({ data }) {
  try {
    logEvent(analytics, Events.DSFADMIN_LINKEDITED, { ...data })
    window.clevertap.event.push(Events.DSFADMIN_LINKEDITED, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function dsfAdminStoreLinkClickEvent ({ data }) {
  try {
    logEvent(analytics, Events.DSFADMIN_STORELINK_CLICK, { ...data })
    window.clevertap.event.push(Events.DSFADMIN_STORELINK_CLICK, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function dsfAdminStoreLinkCopyEvent () {
  try {
    logEvent(analytics, Events.DSFADMIN_STORELINK_COPY)
    window.clevertap.event.push(Events.DSFADMIN_STORELINK_COPY)
  } catch (err) {
    console.log(err)
  }
}

function dsfAdminSave () {
  try {
    logEvent(analytics, Events.DSFADMIN_SAVE)
    window.clevertap.event.push(Events.DSFADMIN_SAVE)
  } catch (err) {
    console.log(err)
  }
}

function dsfAdminImageUpload () {
  try {
    logEvent(analytics, Events.DSFADMIN_UPLOADIMAGE)
    window.clevertap.event.push(Events.DSFADMIN_UPLOADIMAGE)
  } catch (err) {
    console.log(err)
  }
}

EventEmitter.on(Events.SAMPLE_EVENT, sampleEvent)
EventEmitter.on(Events.SAAS_LOGIN, loginEvent)
EventEmitter.on(Events.SAAS_SIGNUP, signUpEvent)
EventEmitter.on(Events.ENTER_OTP, enterOTPEvent)
EventEmitter.on(Events.CONNECT_STORE, connectStoreEvent)
EventEmitter.on(Events.ADD_PRODUCT, addProductEvent)
EventEmitter.on(Events.SELECT_STORE, selectStoreEvent)
EventEmitter.on(Events.REGISTER_NOW, registerNowEvent)
EventEmitter.on(Events.CREATE_NEW_STORE, createNewStoreEvent)
EventEmitter.on(Events.CREATE_NEW_STORE_INSTANT, createNewStoreInstant)
EventEmitter.on(Events.CREATE_NEW_STORE_OK, createNewStoreOkEvent)
EventEmitter.on(Events.CONTACT_NOW, contactNowEvent)
EventEmitter.on(Events.VIDEO_PLAY, videoPlayEvent)
EventEmitter.on(Events.TABS_DASHBOARD, tabDashBoardEvent)
EventEmitter.on(Events.TABS_CONNECTIONS, tabConnectionEvent)
EventEmitter.on(Events.TABS_ANALYTICS, tabAnalyticsEvent)
EventEmitter.on(Events.TABS_PRODUCT, tabProductEvent)
EventEmitter.on(Events.TABS_ORDERS, tabOrdersEvent)
EventEmitter.on(Events.TABS_CUSTOMERS, tabCustomersEvent)
EventEmitter.on(Events.TABS_SUPERSTORE, tabSuperStoreEvent)
EventEmitter.on(Events.TABS_SETTINGS, tabSettingsEvent)
EventEmitter.on(Events.TABS_MYSTORE, tabMyStoreEvent)
EventEmitter.on(Events.PRODUCT_ADD_FROM_PARTNER, addFromPartnerEvent)
EventEmitter.on(Events.PRODUCT_SYNC_RAENA, syncProductRaena)
EventEmitter.on(Events.PRODUCT_SYNC_SELF, syncProductSelf)
EventEmitter.on(Events.PRODUCT_LIVE_LIST, productLiveListEvent)
EventEmitter.on(Events.PRODUCT_ALL_LIST, productAllListEvent)
EventEmitter.on(Events.PRODUCT_DRAFT_LIST, productDraftListEvent)
EventEmitter.on(Events.PRODUCT_VIEWDETAIL, productViewDetailEvent)
EventEmitter.on(Events.PRODUCT_EDITSTOCK, productEditStockEvent)
EventEmitter.on(Events.PRODUCT_EDIT_LISTING, productEditListingEvent)
EventEmitter.on(Events.PRODUCT_ADD_TO_STORES, productAddToStoreEvent)
EventEmitter.on(Events.PRODUCT_SEARCH_TERM, productSearchEvent)
EventEmitter.on(Events.VIEW_ORDER_DETAIL, viewOrderDetailEvent)
EventEmitter.on(Events.ORDER_CLICK_CTA, orderClickCTAEvent)
EventEmitter.on(Events.ORDER_PENDING_LIST, orderPendingListEvent)
EventEmitter.on(Events.ORDER_CANCELLED_LIST, orderCancelledListEvent)
EventEmitter.on(Events.ORDERS_COMPLETED_LIST, orderCompletedListEvent)
EventEmitter.on(Events.ORDER_SEARCH_TERM, orderSearchTermEvent)
EventEmitter.on(Events.DSF_ABOUTUS_SAVE, dsfAboutUsSaveEvent)
EventEmitter.on(Events.DSF_ABOUTUS_COPY, dsfAboutUsCopyEvent)
EventEmitter.on(Events.DSF_LINKS, dsfLinksEvent)
EventEmitter.on(Events.DSF_ABOUTUS, dsfAboutUsEvent)
EventEmitter.on(Events.DSF_APPEARANCE, dsfAppearanceEvent)
EventEmitter.on(Events.DSF_LINKS_LINK_SAVE, dsfLinksLinkEvent)
EventEmitter.on(Events.DSF_LINKS_IMAGE, dsfLinksImageEvent)
EventEmitter.on(Events.DSF_LINKS_REORDER, dsfLinksReorderEvent)
EventEmitter.on(Events.DSF_LINKS_ABOUTUS_IMAGEUPLOAD, dsfLinksAboutUsImageUploadEvent)
EventEmitter.on(Events.DSFADMIN_LINKADDED, dsfAdminLinkAddedEvent)
EventEmitter.on(Events.DSFADMIN_LINKEDITED, dsfAdminLinkEditedEvent)
EventEmitter.on(Events.DSFADMIN_STORELINK_CLICK, dsfAdminStoreLinkClickEvent)
EventEmitter.on(Events.DSFADMIN_STORELINK_COPY, dsfAdminStoreLinkCopyEvent)
EventEmitter.on(Events.DSFADMIN_SAVE, dsfAdminSave)
EventEmitter.on(Events.DSFADMIN_UPLOADIMAGE, dsfAdminImageUpload)
