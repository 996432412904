import React from 'react'
import { Main, BoxWraper, CloseIconWrapper, IframeWrapper } from './style'
import { Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const VideoModal = (props) => {
  return (
    <>
      <Main>
        <Modal
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <BoxWraper>
            <CloseIconWrapper>
              <CloseIcon style={{ float: 'right', color: '#7D86A9', cursor: 'pointer' }} onClick={props.handleClose} />
            </CloseIconWrapper>
            <IframeWrapper width='100%' height='100%' src='https://www.youtube.com/embed/5qAL3i1NpH8' title='YouTube video player' frameBorder={0} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
          </BoxWraper>
        </Modal>
      </Main>
    </>
  )
}

VideoModal.displayName = 'VideoModal'
export default VideoModal
