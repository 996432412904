import React from 'react'
import { Container, Grid } from '@mui/material'
import { ROUTE_LOGIN } from '../../constants'
import { LayoutContainer, HeaderWrapper } from './style'
import Logo from '../Logo'
import MuiButton from '../MuiButton'

const PreLoginLayout = (props) => {
  const {
    children
  } = props
  return (
    <LayoutContainer>
      <Header />
      {children}
    </LayoutContainer>
  )
}

PreLoginLayout.displayName = 'PreLoginLayout'
export default PreLoginLayout

const Header = () => {
  return (
    <HeaderWrapper>
      <Container>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Logo color='white' />
          <MuiButton color='secondary' size='medium' variant='contained' to={ROUTE_LOGIN} minwidth='true'>Login</MuiButton>
        </Grid>
      </Container>
    </HeaderWrapper>
  )
}
