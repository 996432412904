import { auth } from './auth'
import { stores } from './stores'
import { users } from './users'
import { marketplaces } from './marketplaces'
import { products } from './products'
import { productsListedOn } from './productsListedOn'
import { categories } from './categories'
import { orderDetails, orderParams } from './orderDetails'
import { uploadImages } from './uploadImages'
import { orders, orderStatusList } from './orders'
import { raenaProducts } from './raenaProducts'
import { ordersCancel, ordersShip } from './orderActions'
import { brandsCatalogue } from './brandsCatalogue'
import { brandsPromo } from './brandsPromo'
import { brandsMsp } from './brandsMsp'
import { brandMspDetails } from './brandMspDetails'
import { subscription } from './subscription'
import { brands } from './brands'

import { pageBuilder } from './pageBuilder'
import { pageBuilderRaenaProducts } from './pageBuilderRaenaProducts'
import { analytics } from './analytics'
import { createPromo } from './createPromo'
import { dashboard } from './dashboardAnalytics'
import { brandData } from './brandData'

export const reducers = {
  auth,
  analytics,
  stores,
  users,
  marketplaces,
  products,
  productsListedOn,
  categories,
  orderDetails,
  uploadImages,
  orders,
  orderStatusList,
  raenaProducts,
  ordersCancel,
  ordersShip,
  orderParams,
  brandsCatalogue,
  pageBuilder,
  pageBuilderRaenaProducts,
  brandsPromo,
  brandsMsp,
  brandMspDetails,
  createPromo,
  subscription,
  brands,
  dashboard,
  brandData
}
