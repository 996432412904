/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Modal from '../Modal'
import Loading from '../LoadingSpinner'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import CloseIcon from '@mui/icons-material/Close';
import Input from '../Input'
import MuiButton from '../MuiButton'
import { useSelector } from 'react-redux'
import {
  ModalContent,
  Popup,
  DetailHeader,
  MainImage,
  ProductHeader,
  ProductSmallHeader,
  DetailTopText,
  ProductDetailCardWrapper,
  DetailFooter,
  ListedOnHeader,
  ProductImage,
  HeaderDetail,
  StoreNameLogo,
  StoreProductNameLogo,
  StoreLogoImage,
  StoreName,
  ProductName,
  Details,
  EditButtonHeader,
  EditButton,
  ProductOnStoreRowHeadings,
  ProductOnStoreRow,
  AddToStoreButtonParent,
  AddToStoreButton,
  StoreTableHeadingCell,
  PriceHeading,
  FooterPricing,
  PriceText,
  StoreTableCell,
  SelectStoreWrapper,
  InputPriceText,
  EditIconSpan,
  SaveIconSpan,
  CloseIconWrapper,
  ModalMenu
} from './style'
import ShopeeLogo from '../../assets/images/shoppe-logo.png'
import TokopediaLogo from '../../assets/images/tokopedia-logo.png'
// import storePlaceholderImage from '../../assets/images/placeholder-store.png'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import storePlaceholderImage from '../../assets/images/placeholder-store.png'
import './styles.css'
import { store } from '../../store'
import { STORES_GET_LIST } from '../../actions'
import StoreSelectionModal from '../StoreSelectionModal'
import AdditionalInfo from '../ProductCreate/AdditionalInfo'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ROUTE_E_PRODUCT } from '../../constants'
import EventEmitter, { Events } from '../../utilities/eventEmitter'

const ProductListedOnModal = ({
  showModal,
  toggleModal,
  onGetProductListedOn,
  productsListedOn,
  error,
  onGetProductList,
  qParams,
  isAddToStoreModalVisible=false,
  isLoading,
  selectedID,
  storeList = [],
  onGetCategoryBrandsList,
  onGetCategoryAttributesList,
  categoryList,
  onUpdateProductFullFilVia,
  categoryBrandsList,
  categoryAttributesList,
  onGetProductCreateInfoData,
  onPostProductListedOnPrize,
  onGetSelectedStoreAttributeList,
  selectedStoreAttributeList
}) => {
  const { t } = useTranslation()
  const storeTableHeadings = ['SKU ID', t('productDetails.unitsSold'), t('productDetails.stock')]
  const [radioBtnStatus, setRadioBtnStatus] = useState(productsListedOn?.fullFillVia || null)
  const [order, setOrder] = useState(isAddToStoreModalVisible ? 1 : 0)
  const [selectedStores, setSelectedStores] = useState()
  const [openSucces, setOpenSucces] = useState(false)
  const [btnVisible, setBtnVisible] = useState(true)
  const productListStore = useSelector((state) => state.productsListedOn.get('content').toJS())
  const StoreData = useSelector((state) => state.stores.get('content').toJS())
  const [price, setPrice] = useState()
  const [render, setRender] = useState(false)
  const [storeLoading, setStoreLoading] = useState(false)
  const navigate = useNavigate()

  const activeStoreList = storeList.filter(s => s.is_connected === true)
  // Todo: Move to constant
  const isDraft = productsListedOn?.status === 'Draft'
  // check if more stores can be connected
  const productStoresId = productsListedOn?.stores?.filter(store => store.status !== 'Publish_Error')?.map(store => store.marketplaceShopId) || []
  const unConnectedStoresList = activeStoreList.filter(asl => !productStoresId.includes(asl.marketplace_shop_id))
  const isAddToStoreButtonVisible = unConnectedStoresList?.length > 0

  useEffect(() => {
    if(!isAddToStoreButtonVisible && order === 1) {
      setOrder(0)
    }
  }, [isAddToStoreButtonVisible, order])

  useEffect(() => {
    if (showModal === false) {
      setOrder(0)
      setSelectedStores()
      setOpenSucces(false)
      setStoreLoading(false)
    }
  }, [showModal])

  useEffect(() => {
    store.dispatch({ type: STORES_GET_LIST })
  }, [])

  useEffect(() => {
    if (productListStore?.rsellViewId) EventEmitter.emit(Events.PRODUCT_VIEWDETAIL, { product_id: productListStore?.rsellViewId })
  }, [productListStore?.rsellViewId])

  useEffect(() => {
    if (!selectedStores?.length) return
    setStoreLoading(true)
    // setOrder(2)
    if (productsListedOn?.stores?.length) {
      onGetSelectedStoreAttributeList({
        rsellViewId:selectedID,
        selectedStores: productsListedOn?.stores.map((s, i) => {
          return {
            id:i,
            shopName:s.shopName,
            marketplaceShopId:s.marketplaceShopId,
            marketplace:s.marketplace
          }
        })
      })
    }
    onGetCategoryBrandsList({
      limit: 30000,
      categoryId: productsListedOn.categoryId,
      marketplace: 'shopee',
      selectedStores: [
        {
          id: selectedStores[0].id,
          shopName: selectedStores[0].shop_name,
          marketplaceShopId: selectedStores[0].marketplace_shop_id
        }
      ]
    })
    onGetCategoryAttributesList({
      categoryId: productsListedOn.categoryId,
      marketplace: 'shopee',
      selectedStores: [
        {
          id: selectedStores[0].id,
          shopName: selectedStores[0].shop_name,
          marketplaceShopId: selectedStores[0].marketplace_shop_id
        }
      ]
    })
  }, [selectedStores])

  useEffect(() => {
    if (selectedStoreAttributeList.length >= 0 && categoryBrandsList?.length && categoryAttributesList?.length && selectedStores?.length) {
      setOrder(2)
      setStoreLoading(false)
    }
  }, [selectedStoreAttributeList, categoryBrandsList, categoryAttributesList])

  useEffect(() => {
    setRadioBtnStatus(productsListedOn?.fullFillVia)
  }, [productsListedOn?.fullFillVia])

  useEffect(() => {
    onGetProductListedOn({ id: selectedID })
  }, [onGetProductListedOn, selectedID, render])

  const onClickAddToStore = () => {
    setOrder(1)
    setTimeout(
      () => onGetProductList({...qParams}), 180000
    )
    EventEmitter.emit(Events.PRODUCT_ADD_TO_STORES, { product_id: productListStore?.rsellViewId })
  }

  const getStoreImage = (storeName) => {
    if (storeName === 'Shopee') {
      return ShopeeLogo
    } else if (storeName === 'Tokopedia') {
      return TokopediaLogo
    }
    return ''
  }

  const goBack = (val) => {
    if (val === 'Info') {
      setOrder(1)
    }
  }

  const onAddToStoreSuccess = () => {
    setOrder(0)
    setOpenSucces(true)
    setSelectedStores()
    onGetProductListedOn({ id: selectedID })
  }
  useEffect(() => {
    if (error) toast.error(error + '  Price is not saved')
  }, [error])
  const inputchangeHandler = (value) => {
    setPrice(value)
  }
  const priceEditHandler = (e, value, index) => {
    document.getElementById('labelID' + index).style.display = "none"
    document.getElementById('textID' + index).style.display = "block"
    document.getElementById('btnID' + index).style.display = "block"
    const pricePicker = value.price ? value.price : productsListedOn?.pricing?.price
    setPrice(pricePicker)
    // setVisible(!visible)
    setBtnVisible(!btnVisible)
    // console.log(productListStore?.rsellViewId,'e');
  }
  const priceCloseHandler = (e) => {
    setTimeout(() => {
      setBtnVisible(!btnVisible)
      // setVisible(!visible)
      setRender(!render)
    }, 300)
  }
  const priceSaveHandler = (value) => {
    const id = productListStore?.rsellViewId
    const storeMarketPlace = value?.marketplace
    const storeID = value?.marketplaceShopId
    const StorePrice = price
    const obj = {
      price: StorePrice,
      marketplaces: [
        {
          marketplace: storeMarketPlace,
          storeIds: [storeID]
        }
      ]
    }
    onPostProductListedOnPrize({ id, obj })
    priceCloseHandler()
  }
  const editProductHandler = () => {
    EventEmitter.emit(
      Events.PRODUCT_EDITSTOCK, {
        status: productsListedOn?.status,
        product_id: productListStore?.rsellViewId
      }
    )

    navigate(ROUTE_E_PRODUCT + productListStore?.rsellViewId)
  }

  const onClickRadioButtonFullFillVia = (e) => {
    e.preventDefault()
    const {value: currentValue} = e.target
    setRadioBtnStatus(currentValue)

    if (currentValue === 'Raena') EventEmitter.emit(Events.PRODUCT_SYNC_RAENA)
    else if (currentValue === 'OwnInventory') EventEmitter.emit(Events.PRODUCT_SYNC_SELF)

    onUpdateProductFullFilVia({
      rsellViewId: productsListedOn?.rsellViewId,
      fullFillVia: currentValue
    })
  }
  const closeButtonHandler=()=>{
    toggleModal()
  }
  const getConnectionStatus = (key) => {
    switch (key) {
      case 'Publish_Error': return 'Error while publishing'
      default: return key
    }
  }

  const setRadioBtnColor = value => value ? '#162E9A' : '#889995'

  const setRadioLabelColor = value => radioBtnStatus === value ? '#19284D' : '#889995'
  const imageUrl = productsListedOn?.images?.[0]?.key?.startsWith('http')
    ? productsListedOn?.images?.[0].key
    : productsListedOn?.images?.[0]?.url

  let urlFilter;
  return (
    <Modal PopupComponent={Popup} showModal={showModal} toggleModal={toggleModal}>
      {!error && isLoading && <Loading />}
      {(!error && !isLoading) &&
        <ModalContent>
          <ModalMenu>
            {/* {order === 0 && isAddToStoreButtonVisible && <MuiButton size='small' style='primary' variant='contained' onClick={onClickAddToStore}>{isDraft ? t('productDetails.addtoStore') : t('productDetails.addtoAnotherStore')}</MuiButton>} */}
            <MuiButton size='small' color='secondary' variant='outlined' className='shadow' onClick={editProductHandler}>{t('productDetails.edit')}</MuiButton>
            <MuiButton size='small' color='secondary' variant='outlined' className='shadow' onClick={closeButtonHandler}>Close</MuiButton>
          </ModalMenu>

          { order > 0 &&
            <ProductSmallHeader>
                <ProductHeader>
                  <div>
                    <MainImage src={imageUrl || storePlaceholderImage} />
                  </div>
                  <HeaderDetail>
                    <div>
                      <div>
                        <DetailFooter status={productsListedOn?.status}>
                          {productsListedOn?.status}
                        </DetailFooter>
                      </div>
                      <DetailHeader>
                        {productsListedOn?.name}
                      </DetailHeader>
                    </div>
                  </HeaderDetail>
                </ProductHeader>

            </ProductSmallHeader>
          }

          { order === 0 &&
            <>
              <ProductDetailCardWrapper>
                {/* <CloseIconWrapper onClick={() => toggleModal()}><CloseIcon /></CloseIconWrapper> */}
                <ProductHeader>
                  <div>
                    <MainImage src={imageUrl || storePlaceholderImage} />
                  </div>
                  <HeaderDetail>
                    <div>
                      <div>
                        <DetailTopText>
                          {/* {productsListedOn?.rsellViewId?.substring(0, 8)}... */}
                        </DetailTopText>
                        <DetailFooter status={productsListedOn?.status}>
                          {productsListedOn?.status}
                        </DetailFooter>
                      </div>
                      <DetailHeader>
                        {productsListedOn?.name}
                      </DetailHeader>
                      {/* radio button will come here */}
                      { productsListedOn?.productSource === 'Raena' &&
                        <RadioGroup
                          style={{ marginBottom: '20px' }}
                          defaultValue={productsListedOn?.fullFillVia}
                          name='radio-buttons-group'
                          onChange={onClickRadioButtonFullFillVia}
                        >
                          <FormControlLabel
                            value='Raena'
                            style={{
                              marginBottom:'-15px',
                              color: setRadioLabelColor('Raena'),
                              fontWeight: radioBtnStatus === 'Raena' ? '600' :'400'
                            }}
                            control={
                              <Radio
                                size='small'
                                style={{ color: setRadioBtnColor(radioBtnStatus === 'Raena')}}
                              />
                            }
                            label={
                              <span
                                style={{
                                  fontWeight: radioBtnStatus === 'Raena' ? '600' :'400'
                                }}
                              >
                                {t('productDetails.fullfillViaRaena')}
                              </span>
                            }
                          />
                          <FormControlLabel
                            value='OwnInventory'
                            control={
                              <Radio
                                size='small'
                                style={{
                                  color: setRadioBtnColor(radioBtnStatus === 'OwnInventory')
                                }}
                              />
                            }
                            style={{
                              color: setRadioLabelColor('OwnInventory')
                            }}
                            label={
                              <span
                                style={{fontWeight: radioBtnStatus === 'OwnInventory' ? '600' :'400'}}
                              >
                                {t('productDetails.fullfillViaOwn')}
                                </span>
                            }
                          />
                        </RadioGroup>}
                    </div>
                    {/* <EditButtonHeader><EditButton onClick={editProductHandler}>{t('productDetails.edit')}</EditButton></EditButtonHeader> */}
                  </HeaderDetail>
                </ProductHeader>
                <ProductOnStoreRowHeadings>
                  {storeTableHeadings.map((heading, idx) => {
                    return (
                      <StoreTableHeadingCell key={idx}>
                        {heading}
                      </StoreTableHeadingCell>
                    )
                  })}
                </ProductOnStoreRowHeadings>
                <ProductOnStoreRow>
                  {[
                    productsListedOn?.sku,
                    productsListedOn?.stock?.sold,
                    productsListedOn?.stock?.available
                  ].map((heading, idx) => {
                    return (
                      <StoreTableCell key={idx}>
                        {heading}
                      </StoreTableCell>
                    )
                  })}
                </ProductOnStoreRow>
              </ProductDetailCardWrapper>
              {productsListedOn?.stores?.length > 0 && (
                <ListedOnHeader>
                  {t('productDetails.listedOn')}
                </ListedOnHeader>
              )}
              {
                productsListedOn?.stores?.map((value, index) => {
                  urlFilter = StoreData.find((data) => data.shop_name === value.shopName)
                  return (
                    <>
                      <Details>
                        <ProductImage src={urlFilter?.shop_logo ? urlFilter.shop_logo : storePlaceholderImage} alt='listing_image' />
                        <StoreProductNameLogo>
                          <StoreNameLogo>
                            <StoreLogoImage src={getStoreImage('Shopee') || storePlaceholderImage} alt='logo' />
                            <StoreName>
                              {value.marketplace}
                            </StoreName>
                          </StoreNameLogo>
                          <div>
                            <ProductName>
                              {value.shopName}
                            </ProductName>
                            <DetailFooter status={value?.status}>
                              {getConnectionStatus(value?.status)}
                            </DetailFooter>
                          </div>
                          <FooterPricing>
                            <PriceHeading>
                              {t('productDetails.price')}
                            </PriceHeading>
                            <PriceText id={'labelID' + index} none={true}>
                              {value?.currency} {value.price ? value.price : productsListedOn?.pricing?.price}
                            </PriceText>
                            <InputPriceText id={'textID' + index} none={false}>
                              <Input
                                onInput={inputchangeHandler}
                                placeholder={t('productDetails.enterPrice')}
                                // min={price}
                                value={price}
                                type='number'
                                // onChange={inputchangeHandler}
                                onEnter={inputchangeHandler}
                              />
                            </InputPriceText>
                            <EditIconSpan none={btnVisible}>
                              <EditIcon
                                onClick={(e) => priceEditHandler(e, value, index)}
                                style={{
                                  height: '20px',
                                  width: '20px',
                                  padding: '1px',
                                  boxShadow: '0px 2px 10px rgb(0 0 0 / 10%)',
                                  borderRadius: '4px',
                                  border: '1px solid #e4e4e5',
                                  marginLeft: '35px'
                                }}
                              />
                            </EditIconSpan>
                            <SaveIconSpan id={'btnID' + index} none={false}>
                              <CloseIcon
                                onClick={priceCloseHandler}
                                style={{
                                  height: '20px',
                                  width: '20px',
                                  padding: '1px',
                                  boxShadow: '0px 2px 10px rgb(0 0 0 / 10%)',
                                  borderRadius: '4px',
                                  border: '1px solid #e4e4e5',
                                  marginLeft: '5px'
                                }}
                              />
                              <SaveIcon
                                onClick={(e) => priceSaveHandler(value)}
                                style={{
                                  height: '20px',
                                  width: '20px',
                                  padding: '1px',
                                  boxShadow: '0px 2px 10px rgb(0 0 0 / 10%)',
                                  borderRadius: '4px',
                                  border: '1px solid #e4e4e5',
                                  marginLeft: '5px'
                                }}
                              />
                            </SaveIconSpan>
                          </FooterPricing>
                        </StoreProductNameLogo>
                      </Details>
                    </>
                  )
                })
              }
              {isAddToStoreButtonVisible && (
                <AddToStoreButtonParent>
                  <AddToStoreButton onClick={onClickAddToStore}>
                    {isDraft ? t('productDetails.addtoStore') : t('productDetails.addtoAnotherStore')}
                  </AddToStoreButton>
                </AddToStoreButtonParent>
              )}
            </>}

          { order === 1 &&
            <SelectStoreWrapper>
              <StoreSelectionModal
                stores={unConnectedStoresList}
                isMultiple
                setSelectedStores={setSelectedStores}
                productID={productsListedOn?.rsellViewId}
                storeIsLoading={storeLoading}
              />
            </SelectStoreWrapper>}

          { order === 2 &&
            <>
              { Boolean(productsListedOn?.stores?.length && selectedStoreAttributeList) &&
                <AdditionalInfo
                  onAddToStoreSuccess={onAddToStoreSuccess} isAddToStore
                  goBack={() => goBack('Info')} onGetProductCreateInfoData={onGetProductCreateInfoData}
                  productCreateData={productsListedOn} categoryBrandsList={categoryBrandsList}
                  categoryAttributesList={categoryAttributesList} selectedStores={selectedStores}
                  attributeListValues = {selectedStoreAttributeList}
                />}
              { !productsListedOn?.stores?.length &&
                <AdditionalInfo
                  onAddToStoreSuccess={onAddToStoreSuccess} isAddToStore
                  goBack={() => goBack('Info')} onGetProductCreateInfoData={onGetProductCreateInfoData}
                  productCreateData={productsListedOn} categoryBrandsList={categoryBrandsList}
                  categoryAttributesList={categoryAttributesList} selectedStores={selectedStores}
                />}
            </>}
        </ModalContent>}
    </Modal>
  )
}

export default ProductListedOnModal
