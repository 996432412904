import PropTypes from 'prop-types'

import {
  InputWrapper,
  Input as InputComponent,
  FormError,
  FormLabel,
  FormField
} from './style'

const Input = ({ type, onChange, min, value, label, error, name, onInput, onEnter, placeholder = '', disabled }) => {
  const inputHandler = (event) => {
    onInput(event.target.value)
  }

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      onEnter(event.target.value)
    }
  }

  return (
    <InputWrapper>
      {label && <FormLabel>{label}</FormLabel>}
      <FormField>
        <InputComponent
          type={type}
          min={min}
          onEnter={onEnter}
          name={name}
          value={value}
          placeholder={placeholder}
          onInput={inputHandler}
          onKeyDown={keyDownHandler}
          onChange={onChange}
          disabled={disabled}
        />
      </FormField>
      {error !== '' && <FormError>{error}</FormError>}
    </InputWrapper>
  )
}

Input.propTypes = {
  value: [
    PropTypes.string,
    PropTypes.number
  ],
  label: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onEnter: PropTypes.string
}

Input.displayName = 'Input'

export default Input
