import { Map } from 'immutable'

import {
  PRODUCTS_LISTED_ON_LOADING,
  PRODUCTS_GET_LISTED_ON_SUCCESS,
  PRODUCTS_GET_LISTED_ON_FAILURE,
  PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_FAILURE,
  PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_SUCCESS
} from '../actions'

const initialState = new Map({
  isLoading: false,
  error: '',
  totalRecords: 0,
  content: Map({})
})

export const productsListedOn = (state = initialState, action) => {
  const {
    data,
    isLoading,
    message,
    type
  } = action
  switch (type) {
    case PRODUCTS_LISTED_ON_LOADING:
      return state.set('isLoading', isLoading)
    case PRODUCTS_GET_LISTED_ON_SUCCESS:
      return state.merge({
        totalRecords: data?.totalRecords || 0,
        content: Map(data || {})
      })
    case PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_SUCCESS:
      return state.merge({
        price: data
      })
    case PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_FAILURE:
      return state.set('error', message)
    case PRODUCTS_GET_LISTED_ON_FAILURE:
      return state.set('error', message)
    default:
      return state
  }
}
