import React from 'react'
import { Grid, Box, Paper, Table, TableHead, TableCell, TableBody, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { numberWithCommas } from "../../utilities/utils";
import { DataGridWrapper, EditCell, HeaderDesription, InfoIconWrapper, TooltipWrapper } from './style'
import InfoIconSVG from '../../assets/images/infoIcon.svg'
import MuiDataGrid from '../MuiDataGrid'
import RenderCellWithToolTip from '../MuiDataGrid/RenderCellWithToolTip'

const StockToolTip = (rows, totalInhand) => (
  <Box>
    <Grid container>
      <Paper>
        <>
          <Table size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell>WareHouse</TableCell>
                <TableCell>In hand({totalInhand})</TableCell>
                <TableCell>In Transit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{row.warehouse}</TableCell>
                  <TableCell>{row.inhand}</TableCell>
                  <TableCell>{row.intransit}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      </Paper>
    </Grid>
  </Box>
)

const StockRenderCell = (params) => {
  let totalInStock = 0
  const stockArray = params.value || []
  if (Array.isArray(stockArray)) {
    totalInStock = stockArray.map(item => item.inhand)
      .reduce((prev, curr) => prev + curr, 0)
  }

  return (
    <Grid container>
      <Grid item md={6} sm={6} xs={6}>
        <TooltipWrapper
          title={StockToolTip(stockArray, totalInStock)} enterTouchDelay={0} componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'white',
                border: '2px solid #E8EBEF',
                '& .MuiTooltip-arrow': {
                  color: 'white',
                  fontSize: 20
                },
                '& .MuiTooltip-arrow::before': {
                  border: '2px solid #E8EBEF',
                  boxSizing: 'border-box'
                }
              }

            },
            arrow: {
              fontSize: 50
            }
          }} arrow
        >
          <p>{totalInStock}<InfoIconWrapper src={InfoIconSVG} /></p>
        </TooltipWrapper>
      </Grid>
      {false &&
        <Grid item md={6} sm={6} xs={6}>
          <EditCell>
            Edit
          </EditCell>
        </Grid>}
    </Grid>
  )
}

const StockRenderHeader = () => {
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid>
        <div className='MuiDataGrid-columnHeaderTitle'>Stock</div>
      </Grid>
      <Grid>
        <HeaderDesription>({t('brandCatalouge.inStock')})</HeaderDesription>
      </Grid>
    </Grid>
  )
}

const columns = (t) => ([
  {
    field: 'sku',
    headerName: t('brandCatalouge.skuID'),
    selectable: false,
    editable: false,
    renderCell: RenderCellWithToolTip
  },
  {
    field: 'name',
    headerName: t('addProduct.productName'),
    renderCell: RenderCellWithToolTip,
    minWidth: 350,
    flex: 1
  },
  {
    field: 'category',
    headerName: t('addProduct.category'),
    sortable: false,
    renderCell: RenderCellWithToolTip,
    flex: 1
  },
  //   {
  //     field: 'subCategory',
  //     headerName: 'Sub Category',
  //     minWidth: 110,
  //     sortable: false

  //   },
  {
    field: 'productType',
    headerName: t('brandCatalouge.productType'),
    sortable: false,
    renderCell: RenderCellWithToolTip,
    flex: 1
  },
  {
    field: 'retailPrice',
    headerName: t('brandCatalouge.retailPrice'),
    renderCell: RenderCellWithToolTip,
    valueGetter: (params) =>
      `Rp ${numberWithCommas(params?.row?.retailPrice)}`,
    flex: 1
  },
  {
    field: 'cogs',
    headerName: t('brandCatalouge.cogs'),
    renderCell: RenderCellWithToolTip,
    valueGetter: (params) =>
      `Rp ${numberWithCommas(params?.row?.cogs)}`,
    flex: 1
  },
  {
    field: 'stock',
    headerName: t('productDetails.stock'),
    renderCell: StockRenderCell,
    renderHeader: StockRenderHeader,
    flex: 1
  }
])

const loadingRow = (t) => ([
  { id: 0, sku: t('brandCatalouge.loading'), name: t('brandCatalouge.loading'), category: t('brandCatalouge.loading'), subCategory: t('brandCatalouge.loading'), productType: t('brandCatalouge.loading'), retailPrice: 0, cogs: 0, stock: 0, status: '' }
])

const DataTable = ({ loading, catalougeList, sortingHandler, pageChangehandler, totalCatalouge }) => {
  const { t } = useTranslation()

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5
    }
  }, [])

  const handleSortModelChange = React.useCallback(sortModel => {
    if (sortModel.length) {
      let field = null
      if (sortModel[0].field === 'sku') {
        field = 'SKU'
      } else if (sortModel[0].field === 'name') {
        field = 'NAME'
      } else if (sortModel[0].field === 'retailPrice') {
        field = 'RETAIL_PRICE'
      } else if (sortModel[0].field === 'cogs') {
        field = 'COGS'
      } else if (sortModel[0].field === 'stock') {
        field = 'TOTAL_STOCK'
      }

      if (field) {
        sortingHandler({
          sort: field,
          sortOrder: sortModel[0].sort.toUpperCase()
        })
      }
    } else {
      sortingHandler({})
    }
  }, [sortingHandler])

  return (
    <DataGridWrapper container>
      <MuiDataGrid
        loading={loading}
        getRowSpacing={getRowSpacing}
        rows={!loading ? catalougeList : loadingRow(t)}
        columns={columns(t)}
        pageSize={20}
        onPageChange={pageChangehandler}
        rowHeight={70}
        rowCount={totalCatalouge}
        onSortModelChange={handleSortModelChange}
      />
    </DataGridWrapper>
  )
}

DataTable.displayName = 'BrandCatalougeDataTableComponent'
export default DataTable
