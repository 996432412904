import styled from 'styled-components'

export const NeedHelpOuterWrapper = styled.div`
  margin : 0 30px;
  @media (max-width: 1000px) {
    display: float;
    margin-bottom: 30px;
  }
  & * {
    box-sizing:border-box;
  }
`

export const NeedHelpWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px;
  width: 100%;
  display: flex;
  margin-top: -5px;
  @media (max-width: 1000px) {
    padding: 20px 10px;
  }
  @media (max-width: 600px) {
    width: auto;
    flex-wrap: wrap;
  }
  & > div{
    min-width: 180px;
  }
`

export const Image = styled.img`
  width: 90px;
  height: 70px;
  @media (max-width: 1000px) {
    display: none;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 20px 0 40px;
  @media (max-width: 1000px) {
    padding: 0 10px;
  }
`

export const Heading = styled.h3`
  font-weight: 700;
  font-size: 25px;
  line-height: 1;
  padding-bottom: 5px;
  color: #222222;
  margin: 0;
  @media (max-width: 1000px) {
    font-size: 20px;
  }
`

export const SubHeading = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #222222;
  opacity: 0.7;
  margin: 0;
`

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  @media (max-width: 1000px) {
    padding: 10px;
  }
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: start;
  }
  & a {
    min-width:150px;
  }
`
