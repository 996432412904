import { applyMiddleware, createStore, combineReducers, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { reducers } from './reducers'
import rootSaga from './sagas'
import { AUTH_LOGOUT_USER } from './actions'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [
  sagaMiddleware
]

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['users', 'subscription']
}

const composeEnhancers = typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose

const reducer = combineReducers({ ...reducers })
const rootReducer = (defaultState, action) => {
  let state = defaultState
  if (action.type === AUTH_LOGOUT_USER) {
    localStorage.removeItem('idToken')
    // Todo: Check if there is a better way to redirect user
    // window.location.reload()
    state = {}
  }
  return reducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = composeEnhancers(applyMiddleware(...middlewares))(createStore)(persistedReducer)
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
