import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const PageBuilderWrapper = styled(Box)(props => `
  padding: 1rem 20px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(136, 150, 203, 0.121312);
  border-radius: 10px;
  margin-top: 20px;
`)

export const SectionContent = styled(Box)(`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px){
    flex-direction: column;
    width: 100%; 
  }
`)

export const FormOuterControl = styled(Box)(`
  width:100%;
  display: flex;
`)
