import { List, Map } from 'immutable'

import {
  CATEGORIES_LOADING,
  CATEGORIES_GET_LIST_SUCCESS,
  CATEGORIES_GET_LIST_FAILURE,
  CATEGORIES_BRANDS_LOADING,
  CATEGORIES_BRANDS_GET_LIST_SUCCESS,
  CATEGORIES_BRANDS_GET_LIST_FAILURE,
  CATEGORIES_ATTRIBUTES_LOADING,
  CATEGORIES_ATTRIBUTES_GET_LIST_SUCCESS,
  CATEGORIES_ATTRIBUTES_GET_LIST_FAILURE,
  CATEGORIES_DROPDOWN_LOADING,
  CATEGORIES_DROPDOWN_GET_LIST_SUCCESS,
  CATEGORIES_DROPDOWN_GET_LIST_FAILURE
} from '../actions'

const initialState = new Map({
  categoryIsLoading: false,
  categoryBrandsIsLoading: false,
  categoryBrandsList: List([]),
  categoryAttributesIsLoading: false,
  categoryAttributesList: List([]),
  categoryDropdownIsLoading: false,
  error: '',
  totalRecords: 0,
  content: List([]),
  selectMenuData: Map({})
})

export const categories = (state = initialState, action) => {
  const {
    data,
    categoryIsLoading,
    categoryBrandsIsLoading,
    categoryAttributesIsLoading,
    categoryDropdownIsLoading,
    message,
    type
  } = action

  switch (type) {
    case CATEGORIES_LOADING:
      return state.set('categoryIsLoading', categoryIsLoading)
    case CATEGORIES_GET_LIST_SUCCESS:
      return state.merge({
        totalRecords: data?.totalRecords || 0,
        content: List(data?.content || [])
      })
    case CATEGORIES_GET_LIST_FAILURE:
      return state.set('error', message)

    case CATEGORIES_BRANDS_LOADING:
      return state.set('categoryBrandsIsLoading', categoryBrandsIsLoading)
    case CATEGORIES_BRANDS_GET_LIST_SUCCESS:
      return state.merge({
        categoryBrandsList: List(data?.content || [])
        // categoryBrandsList: List(data?.content || [])
      })
    case CATEGORIES_BRANDS_GET_LIST_FAILURE:
      return state.set('error', message)

    case CATEGORIES_ATTRIBUTES_LOADING:
      return state.set('categoryAttributesIsLoading', categoryAttributesIsLoading)
    case CATEGORIES_ATTRIBUTES_GET_LIST_SUCCESS:
      return state.merge({
        categoryAttributesList: List(data || [])
        // categoryAttributesList: List(data?.content || [])
      })
    case CATEGORIES_ATTRIBUTES_GET_LIST_FAILURE:
      return state.set('error', message)

    case CATEGORIES_DROPDOWN_LOADING:
      return state.set('categoryDropdownIsLoading', categoryDropdownIsLoading)
    case CATEGORIES_DROPDOWN_GET_LIST_SUCCESS:
      return state.merge({
        selectMenuData: state.get('selectMenuData').setIn([data.parentCategoryId], data?.response?.content)
      })
    case CATEGORIES_DROPDOWN_GET_LIST_FAILURE:
      return state.set('error', message)
    default:
      return state
  }
}
