import { ROUTE_SIGNUP } from '../../constants'

// import bannerImage from '../../assets/images/homepage/banner-background.webp'
import bannerImage from '../../assets/images/homepage/Web-Bg.png'
import icon1 from '../../assets/images/homepage/icon-1.svg'
import icon2 from '../../assets/images/homepage/icon-2.svg'
import icon3 from '../../assets/images/homepage/icon-3.svg'
import icon4 from '../../assets/images/homepage/icon-4.svg'

import logoAvoskin from '../../assets/images/homepage/logo-avoskin.png'
import logoAzarine from '../../assets/images/homepage/logo-azarine.png'
import logoBreyLee from '../../assets/images/homepage/logo-breylee.png'
import logoEverwhite from '../../assets/images/homepage/logo-everwhite.png'
import logoEmina from '../../assets/images/homepage/logo-emina.png'
import logoLacoco from '../../assets/images/homepage/logo-lacoco.png'
import logoNutrishe from '../../assets/images/homepage/logo-nutrishe.webp'
import logoScarlett from '../../assets/images/homepage/logo-scarlett.png'
import logoStudioTropik from '../../assets/images/homepage/logo-studiotropik.png'
import logoTrueToSkin from '../../assets/images/homepage/logo-truetoskin.webp'
import logoWardah from '../../assets/images/homepage/logo-wardah.png'
import logoBellflower from '../../assets/images/homepage/logo-bellflower.jpg'
import logoIngrid from '../../assets/images/homepage/logo-ingrid.jpg'
import logoWdressroom from '../../assets/images/homepage/logo-wdressroom.png'
import logoShopee from '../../assets/images/homepage/logo-shopee.svg'
import logoTokopedia from '../../assets/images/homepage/logo-tokopedia.svg'
import logoBukalapak from '../../assets/images/homepage/logo-bukalapak.svg'
import logoLazada from '../../assets/images/homepage/logo-lazada.svg'
import logoJsCentral from '../../assets/images/homepage/logo-js-central.svg'
import logoBlibli from '../../assets/images/homepage/logo-blibli.svg'
import logoJdId from '../../assets/images/homepage/logo-jd.id.svg'

import marketPlace from '../../assets/images/homepage/marketplace.webp'
import rating from '../../assets/images/homepage/icon-rating.svg'

import logoAxis from '../../assets/images/homepage/logo-axis.webp'
import logoHanasui from '../../assets/images/homepage/logo-hanasui.webp'
import logoLuxcrime from '../../assets/images/homepage/logo-luxcrime.webp'
import logoWhitelab from '../../assets/images/homepage/logo-whitelab.webp'

import Logo from '../../components/Logo'

export const homepageContent = {
  banner: {
    title_: 'Raena RSV',
    title: <Logo size='medium' />,
    description: 'Manage your Omni-channel online sales like a Pro using rDash360',
    links: [{
      title: 'Register Now',
      url: ROUTE_SIGNUP
    }],
    background: bannerImage
  },
  benefitCard: {
    title: 'Key Seller Benefits',
    data: [
      {
        heading: 'Omni-Channel Retailing',
        content: 'Connect all your multiple marketplace Stores and get a central view to manage Products, Customer & Sales',
        image: icon1
      },
      {
        heading: 'Monitor Performance',
        content: 'Monitor performance of all your stores, sales, profits and much more through our detailed analytics dashboards',
        image: icon3
      },
      {
        heading: 'Real Time Stock Sync',
        content: 'Keep your inventory in sync on all stores and ensure high customer satisfaction',
        image: icon2
      },
      {
        heading: 'Order Dropshipping',
        content: 'Add products from our partner catalog and kickstart your dropship business with zero capital investment',
        image: icon4
      }
    ]
  },
  marketPlace: {
    rhsImage: {
      image: marketPlace,
      alt: 'Market place'
    },
    lhsData: [
      {
        heading: '100+',
        content: 'Trusted Sellers'
      },
      {
        heading: 'Save 70% Time',
        content: 'Managing Marketplaces'
      },
      {
        heading: '1 System',
        content: 'For All Your Marketplace Stores'
      }
    ],
    subTitle: 'Multiple marketplaces integrations available or coming soon on rDash360',
    bottomImages: [
      {
        title: 'Shoppee',
        src: logoShopee,
        alt: 'Shoppee',
        url: 'https://shopee.in/'
      },
      {
        title: 'Tokopedia',
        src: logoTokopedia,
        alt: 'Tokopedia',
        url: 'https://www.tokopedia.com/'
      },
      {
        title: 'Bukalapak',
        src: logoBukalapak,
        alt: 'Bukalapak'
      },
      {
        title: 'Lazada',
        src: logoLazada,
        alt: 'Lazada'
      },
      {
        title: 'JD Central',
        src: logoJsCentral,
        alt: 'JD Central'
      },
      {
        title: 'blibli',
        src: logoBlibli,
        alt: 'blibli'
      },
      {
        title: 'JD.ID',
        src: logoJdId,
        alt: 'JD.ID'
      }
    ]
  },
  testimonials: {
    title: 'Testimonials',
    carouselData: [
      {
        topImages: [{ src: rating, alt: 'Rating' }],
        subTitle: 'This platform is so easy to use. I was able to create my own Shopee store in a few minutes by following rDash360 Tutorials.<br><br><b> - Febby M</b>'
      },
      {
        topImages: [{ src: rating, alt: 'Rating' }],
        subTitle: 'Dropshipping products is quite easy with rDash360. They offer a large variety of beauty products to choose from and deliver to customers without me doing anything at all.<br><br><b>- Autarkeia Novita</b>'
      },
      {
        topImages: [{ src: rating, alt: 'Rating' }],
        subTitle: 'I love how easy it is to manage my stock on multiple stores here. This is the perfect solution if you need to manage stock on different e-commerce stores.<br><br><b>- Ayu Olya</b>'
      }
    ]
  },
  pricing: {
    title: 'Pricing',
    data: [
      {
        chipColor: '#F0F0CE',
        title: 'Standard',
        currency: 'IDR',
        amount: '10,000,000',
        tenure: 'Yearly',
        points: ['Connect Upto 5 Marketplace Stores', 'Manage Upto 100 products per store', 'Manage Unlimited Orders', 'Social Media and mail support']
      },
      {
        chipColor: '#F6D8DC',
        title: 'Premium',
        currency: 'IDR',
        amount: '15,000,000',
        tenure: 'Yearly',
        points: ['Connect Upto 50 Shopee Stores', 'Manage Upto Unlimited products', 'Access to All Basic Seller Tutorials', 'Social Media and mail support']
      },
      {
        chipColor: '#D2D7F5',
        title: 'Business',
        currency: 'IDR',
        amount: '30,000,000',
        tenure: 'Yearly',
        offer: 'IDR 15,000,000',
        points: ['Connect unlimited stores', 'Managed Unlimited Products', 'Manage Unlimited Orders', 'Access to All Advanced Seller Tutorials', 'Access to All Advanced Seller Tutorials', '24x7 Social Media and mail support'],
        links: [
          { title: 'Register Now', url: ROUTE_SIGNUP }
        ]
      }
    ]
  },
  brands: {
    title: '100+ Brands Available',
    subTitle: 'Option to do one-click addition of products from 100+ brands across Indonesia through our delivery partner Ecosystem',
    bottomImages: [
      { src: logoAvoskin, maxWidth: '110px', alt: 'Avoskin' },
      { src: logoAzarine, maxWidth: '110px', alt: 'Azarine' },
      { src: logoBreyLee, maxWidth: '110px', alt: 'Breylee' },
      { src: logoEverwhite, maxWidth: '110px', alt: 'Everwhite' },
      { src: logoEmina, maxWidth: '110px', alt: 'Emina' },
      { src: logoHanasui, maxWidth: '110px', alt: 'Hanasui' },
      { src: logoLacoco, maxWidth: '110px', alt: 'Lacoco' },
      { src: logoLuxcrime, maxWidth: '110px', alt: 'Luxcrime' },
      { src: logoNutrishe, maxWidth: '110px', alt: 'Nutrishe' },
      { src: logoScarlett, maxWidth: '110px', alt: 'Scarlett' },
      { src: logoStudioTropik, maxWidth: '110px', alt: 'Studio Topik' },
      { src: logoTrueToSkin, maxWidth: '110px', alt: 'True To Skin' },
      { src: logoWardah, maxWidth: '110px', alt: 'Wardah' },
      { src: logoWhitelab, maxWidth: '110px', alt: 'Whitelab' },
      { src: logoAxis, maxWidth: '110px', alt: 'AXIS-X' },
      { src: logoBellflower, maxWidth: '110px', alt: 'Bellflower' },
      { src: logoIngrid, maxWidth: '110px', alt: 'Ingrid' },
      { src: logoWdressroom, maxWidth: '110px', alt: 'W.Dressroom' }
    ]
  }
}
