import { all } from 'redux-saga/effects'

import authSaga from './auth'
import storesSaga from './stores'
import marketplacesSaga from './marketplaces'
import productsSaga from './products'
import productsListedOnSaga from './productsListedOn'
import categoriesSaga from './categories'
import orderDetails from './orderDetails'
import uploadImages from './uploadImages'
import ordersSaga from './orders'
import brandsCatalogue from './brandsCatalogue'
import brandsPromo from './brandsPromo'
import brandsMsp from './brandsMsp'
import brandsMspDetails from './brandsMspDetails'
import brands from './brands'
import analyticsSaga from './analytics'
import pageBuilder from './pageBuilder'
import pageBuilderRaenaProducts from './pageBuilderRaenaProducts'
import users from './users'
import createPromo from './createPromo'
import dashboardAnalytics from './dashboardAnalytics'
import brandData from './brandData'

function * rootSaga () {
  yield all([
    authSaga(),
    storesSaga(),
    marketplacesSaga(),
    productsSaga(),
    productsListedOnSaga(),
    categoriesSaga(),
    ordersSaga(),
    orderDetails(),
    uploadImages(),
    brandsCatalogue(),
    brandsPromo(),
    brandsMsp(),
    brandsMspDetails(),
    brands(),
    analyticsSaga(),
    pageBuilder(),
    pageBuilderRaenaProducts(),
    users(),
    createPromo(),
    dashboardAnalytics(),
    brandData()
  ])
}
export default rootSaga
