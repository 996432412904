import { Map } from 'immutable'

import {
  BRAND_CATEGORIES_LOADING,
  BRAND_CATEGORIES_GET_LIST_FAILURE,
  BRAND_CATEGORIES_GET_LIST_SUCCESS,
  BRAND_PRODUCT_TYPE_LOADING,
  BRAND_PRODUCT_TYPE_GET_LIST_FAILURE,
  BRAND_PRODUCT_TYPE_GET_LIST_SUCCESS,
  BRAND_CATALOUGE_LOADING,
  BRAND_CATALOUGE_GET_LIST_FAILURE,
  BRAND_CATALOUGE_GET_LIST_SUCCESS
} from '../actions'

const initialState = new Map({
  categoryIsLoading: false,
  categoryList: [],
  productTypeIsLoading: false,
  productTypeList: [],
  catalougeIsLoading: false,
  catalougeList: [],
  totalCatalouge: 0
})

export const brandsCatalogue = (state = initialState, action) => {
  const {
    data,
    message,
    type,
    isLoading,
    total
  } = action

  switch (type) {
    case BRAND_CATEGORIES_LOADING:
      return state.set('categoryIsLoading', isLoading)
    case BRAND_CATEGORIES_GET_LIST_SUCCESS:
      return state.merge({
        categoryList: data || []
      })
    case BRAND_CATEGORIES_GET_LIST_FAILURE:
      return state.merge({
        categoryList: [],
        errorCategoryList: message
      })
    case BRAND_PRODUCT_TYPE_LOADING:
      return state.set('productTypeIsLoading', isLoading)
    case BRAND_PRODUCT_TYPE_GET_LIST_SUCCESS:
      return state.merge({
        productTypeList: data || []
      })
    case BRAND_PRODUCT_TYPE_GET_LIST_FAILURE:
      return state.set('errorProductTypeList', message)
    case BRAND_CATALOUGE_LOADING:
      return state.set('catalougeIsLoading', isLoading)
    case BRAND_CATALOUGE_GET_LIST_SUCCESS:
      return state.merge({
        catalougeList: data || [],
        totalCatalouge: total || 0
      })
    case BRAND_CATALOUGE_GET_LIST_FAILURE:
      return state.merge({
        catalougeList: [],
        totalCatalouge: 0,
        errorCatalougeList: message
      })
    default:
      return state
  }
}
