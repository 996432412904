import { call, put, takeLatest } from 'redux-saga/effects'

import {
  BRAND_PROMO_LOADING,
  BRAND_PROMO_GET_LIST,
  BRAND_PROMO_GET_LIST_FAILURE,
  BRAND_PROMO_GET_LIST_SUCCESS,
  BRAND_PROMO_TYPE_LOADING,
  BRAND_PROMO_TYPE_GET_LIST,
  BRAND_PROMO_TYPE_GET_LIST_FAILURE,
  BRAND_PROMO_TYPE_GET_LIST_SUCCESS,
  BRAND_SKU_LOADING,
  BRAND_SKU_GET_LIST,
  BRAND_SKU_GET_LIST_FAILURE,
  BRAND_SKU_GET_LIST_SUCCESS
} from '../actions'

import {
  API_GET_BRANDS_MANAGEMENT_PROMO_TYPES, API_GET_BRANDS_MANAGEMENT_SKU, API_GET_BRANDS_MANAGEMENT_PROMO
} from '../constants'

import { BrandManagementPostApi } from '../utilities/axios'

function * onGetBrandPromoTypeList (action) {
  yield put({ type: BRAND_PROMO_TYPE_LOADING, isLoading: true })
  try {
    const query = {
      offset: action.payload?.offset || 0,
      limit: action.payload?.limit || 20
    }
    const response = yield call(BrandManagementPostApi, API_GET_BRANDS_MANAGEMENT_PROMO_TYPES(action.payload.brandId), query, {}, true)
    yield put({ type: BRAND_PROMO_TYPE_GET_LIST_SUCCESS, data: response?.data?.data || [] })
  } catch (e) {
    yield put({ type: BRAND_PROMO_TYPE_GET_LIST_FAILURE, message: e.message })
  }

  yield put({ type: BRAND_PROMO_TYPE_LOADING, isLoading: false })
}

function * onGetBrandSkuList (action) {
  yield put({ type: BRAND_SKU_LOADING, isLoading: true })
  try {
    const query = {
      offset: action.payload?.offset || 0,
      limit: action.payload?.limit || 20
    }
    const response = yield call(BrandManagementPostApi, API_GET_BRANDS_MANAGEMENT_SKU(action.payload.brandId), query, {}, true)
    yield put({ type: BRAND_SKU_GET_LIST_SUCCESS, data: response?.data?.data || [] })
  } catch (e) {
    yield put({ type: BRAND_SKU_GET_LIST_FAILURE, message: e.message })
    // This is temporary as BE is returning 404 error
    // yield put({ type: BRAND_CATEGORIES_GET_LIST_SUCCESS, data: {} })
  }
  yield put({ type: BRAND_SKU_LOADING, isLoading: false })
}

function * onGetBrandPromosList (action) {
  yield put({ type: BRAND_PROMO_LOADING, isLoading: true })
  try {
    const query = {
      offset: action.payload?.offset || 0,
      limit: action.payload?.limit || 20,
      skuOrNameSearch: action.payload?.skuOrNameSearch || '',
      isArchived: action.payload?.isArchived || false
    }

    if (action.payload.sku && action.payload.sku.length) {
      query.skus = action.payload.sku
    }

    if (action.payload.promoType && action.payload.promoType.length) {
      query.promoType = action.payload.promoType
    }

    if (action.payload.sort) {
      query.sort = action.payload.sort
      query.sortOrder = action.payload.sortOrder
    }

    if (action.payload.startDate && action.payload.endDate) {
      query.startDate = action.payload.startDate
      query.endDate = action.payload.endDate
    }

    const response = yield call(BrandManagementPostApi, API_GET_BRANDS_MANAGEMENT_PROMO(action.payload.brandId), query, {}, true)
    yield put({ type: BRAND_PROMO_GET_LIST_SUCCESS, data: response?.data?.data || {}, total: response?.data?.total || 0 })
  } catch (e) {
    yield put({ type: BRAND_PROMO_GET_LIST_FAILURE, message: e.message })
  }
  yield put({ type: BRAND_PROMO_LOADING, isLoading: false })
}

function * brandsCatalogue () {
  yield takeLatest(BRAND_PROMO_TYPE_GET_LIST, onGetBrandPromoTypeList)
  yield takeLatest(BRAND_SKU_GET_LIST, onGetBrandSkuList)
  yield takeLatest(BRAND_PROMO_GET_LIST, onGetBrandPromosList)
}

export default brandsCatalogue
