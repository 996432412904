import React from 'react'

import Layout from '../Layout'
import BrandsMspCompareComponent from '../../components/BrandsMspCompare'
import { connect } from 'react-redux'
import { BRAND_MSP_GET_LIST, BRAND_MSP_EXPORT, BRAND_MSP_CONFIG, BRAND_MSP_CONFIG_RESET, BRAND_MSP_GET_DETAIL_FILTERS_RESET } from '../../actions'

const BrandsMspCompare = (props) => {
  return (
    <Layout>
      <BrandsMspCompareComponent {...props} />
    </Layout>
  )
}
const mapStateToProps = (state) => {
  const { brandsMsp, brands, subscription } = state
  return {
    mspIsloading: brandsMsp.get('mspIsloading'),
    mspList: brandsMsp.get('mspList'),
    totalMsp: brandsMsp.get('totalMsp'),
    selectedBrand: brands.get('selectedBrand'),
    brandIsLoading: brands.get('isLoading'),
    features : subscription.get('features'),
    mspExportIsloading: brandsMsp.get('mspExportIsloading'),
    configIsLoading: brandsMsp.get('configIsLoading'),
    configUpdateSuccess: brandsMsp.get('configUpdateSuccess')
  }
}

const mapDispatchToProps = dispatch => ({
  onGetBrandMSPList: (query) => {
    dispatch({ type: BRAND_MSP_GET_LIST, payload: query })
  },
  download: (query) => {
    dispatch({ type: BRAND_MSP_EXPORT, payload: query })
  },
  updateMspConfig: (query) => {
    dispatch({ type: BRAND_MSP_CONFIG, payload: query })
  },
  resetConfigUpdateSuccess: () => {
    dispatch({ type: BRAND_MSP_CONFIG_RESET})
  },
  mspDetailsFiltersReset: () => {
    dispatch({ type: BRAND_MSP_GET_DETAIL_FILTERS_RESET })
  }
})

BrandsMspCompare.displayName = 'BrandsMspCompareContainer'
export default connect(mapStateToProps, mapDispatchToProps)(BrandsMspCompare)
