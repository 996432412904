import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import {
  MARKETPLACES_LOADING,
  MARKETPLACES_GET_LIST,
  MARKETPLACES_GET_LIST_SUCCESS,
  MARKETPLACES_GET_LIST_FAILURE,
  MARKETPLACES_CONNECT_SHOPEE,
  MARKETPLACES_CONNECT_SHOPEE_FAILURE
} from '../actions'

import {
  API_GET_MARKETPLACES,
  API_MARKETPLACES_CONNECT_SHOPEE
} from '../constants'
import { getApi } from '../utilities/axios'

function * onGetStoreList ({ data }) {
  yield put({ type: MARKETPLACES_LOADING, isLoading: true })
  try {
    const response = yield call(getApi, API_GET_MARKETPLACES)
    yield put({ type: MARKETPLACES_GET_LIST_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    yield put({ type: MARKETPLACES_GET_LIST_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: MARKETPLACES_GET_LIST_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: MARKETPLACES_GET_LIST_SUCCESS, data: {} })
  }
  yield put({ type: MARKETPLACES_LOADING, isLoading: false })
}

function * onConnectShopee () {
  yield put({ type: MARKETPLACES_LOADING, isLoading: true })
  try {
    const response = yield call(getApi, API_MARKETPLACES_CONNECT_SHOPEE)
    const url = response?.data?.data?.auth_url || ''
    if (url) {
      window.location.href = url
    }
    toast.success('Store connection is successful')
  } catch (e) {
    yield put({ type: MARKETPLACES_CONNECT_SHOPEE_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: MARKETPLACES_CONNECT_SHOPEE_FAILURE, message: '' })
  }
  yield put({ type: MARKETPLACES_LOADING, isLoading: false })
}

function * marketplacesSaga () {
  yield takeLatest(MARKETPLACES_GET_LIST, onGetStoreList)
  yield takeLatest(MARKETPLACES_CONNECT_SHOPEE, onConnectShopee)
}

export default marketplacesSaga
