import { useState, useEffect } from 'react'
import {
  Select,
  MenuItem,
  Chip,
  Checkbox,
  ListItemText
} from '@mui/material'
import {
  FormLabel,
  FormSelectWrap,
  SelectWrapper,
  FormError,
  InputLabel,
  FormSelectOuterWrap,
  FormReq
} from './style'
import MuiSwitch from '../MuiSwitch'

const MuiSelect = (props) => {
  const Req = () => {
    return (
      <>
        {props.isRequired && <FormReq>*</FormReq>}
      </>
    )
  }

  return (
    <FormSelectOuterWrap>
      {props?.title && <FormLabel>{props.title}<Req /></FormLabel>}
      <FormSelectWrap>
        {props?.data &&
          <>
            {props?.type !== 'MULTIPLE_SELECT_COMBO_BOX' && <SingleSelect {...props} />}
            {props?.type === 'MULTIPLE_SELECT_COMBO_BOX' && <MultiSelect {...props} />}
          </>}
      </FormSelectWrap>
    </FormSelectOuterWrap>
  )
}

export default MuiSelect

const SingleSelect = (props) => {
  const {
    data,
    name,
    onChange,
    selectlabel,
    selectvalue,
    size,
    placeholder,
    error,
    dataType = 'object',
    value,
    isPlaceHolder,
    isClear
  } = props

  const [selectedValue, setSelectedValue] = useState(value || null)

  const handleChange = (event) => {
    // if value is not changed
    if (dataType === 'object' && selectedValue && selectedValue[selectvalue] === event.target.value) {
      return
    }
    const selectedData = (dataType === 'object') ? { value: event.target.value, label: event.target.name } : event.target.value
    setSelectedValue(event.target.value)
    onChange(event, selectedData)
  }

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  useEffect(() => {
    if (isClear) {
      setSelectedValue(null)
    }
  }, [isClear])

  return (
    <>
      {(isPlaceHolder && selectedValue === null) && <InputLabel>{placeholder}</InputLabel>}
      {/* {!(selectedValue) && placeholder && <InputLabel>{placeholder}</InputLabel>} */}
      <SelectWrapper>
        <Select {...props} name={name} onChange={handleChange} size={size} value={selectedValue}>
          {dataType === 'object' && data?.map((s, i) => <MenuItem key={i} value={s[selectvalue]} selected={String(selectedValue) === String(s[selectvalue])}>{s[selectlabel]}</MenuItem>)}
          {dataType === 'string' && data?.map((s, i) => <MenuItem key={i} value={s} selected={String(selectedValue) === String(s)}>{s}</MenuItem>)}
        </Select>
      </SelectWrapper>
      {error && <FormError>{error}</FormError>}
    </>
  )
}

const MultiSelect = (props) => {
  const {
    data,
    name,
    onChange,
    selectlabel,
    selectvalue,
    size,
    placeholder,
    error,
    defaultValue,
    defaultChipValue,
    // dataType = 'object',
    value,
    customSelectedChip,
    dropdownMaxWidth
  } = props
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8

  const MenuProps = {
    MenuListProps: {
      style: { marginRight: 5 }
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: dropdownMaxWidth
      }
    }
  }

  const _value = (option) => { return option?.[selectvalue] || option?.value }
  const _label = (option) => { return option?.[selectlabel] || option?.label }

  const [selectedOptionValue, setSelectedOptionValue] = useState(defaultValue || [])
  const [selectedOptionObject, setSelectedOptionObject] = useState(defaultChipValue || [])

  const handleChange = (event) => {
    const { target: { value } } = event
    setSelectedOptionValue(typeof value === 'string' ? value.split(',') : value)

    const selectedData = data.filter(d => (value.indexOf(_value(d)) !== -1))
    setSelectedOptionObject(selectedData)

    onChange(event, selectedData)
  }

  useEffect(() => {
    const defaultVal = value?.map(d => _value(d)) || []
    const defaultObj = value?.length && data?.length ? data.filter(d => defaultVal.indexOf(_value(d)) !== -1) : []
    setSelectedOptionValue(defaultVal)
    setSelectedOptionObject(defaultObj)
  }, [value])

  const selectedChip = (selected) => {
    return (

      // <>{selected.join(', ')}</>
      <>
        {selectedOptionObject.map((sData, index) => {
          return <Chip sx={{ height: '24px', mr: 1 }} key={index} label={_label(sData)} />
        })}
      </>
    )
  }

  return (
    <>
      {!selectedOptionValue && placeholder && <InputLabel>{placeholder}</InputLabel>}
      <SelectWrapper>
        <Select
          multiple
          value={selectedOptionValue}
          name={name}
          onChange={handleChange}
          renderValue={customSelectedChip || selectedChip}
          MenuProps={MenuProps}
          label=''
          size={size}
          // value={selectedValue}
          displayEmpty
        >
          {data.map((option, index) => (
            <MenuItem key={index} value={_value(option)}>
              {!option.switchButton
                ? <>
                  <Checkbox checked={selectedOptionValue.indexOf(_value(option)) > -1} />
                  <ListItemText primaryTypographyProps={{ style: { whiteSpace: "normal" } }} primary={_label(option)} />
                </>
                : <MuiSwitch externalValueHandling label={_label(option)} name='multiselect_switch' type='ios' value={selectedOptionValue.indexOf(_value(option)) > -1} onChange={(e) => { console.log(e) }} />}
            </MenuItem>
          ))}
        </Select>
      </SelectWrapper>
      {error && <FormError>{error}</FormError>}
    </>
  )
}
