import { useTranslation } from 'react-i18next'

import AmountCard from './AmountCard'

import {
  OrderItemCard,
  OrderItemImage,
  OrderItemCardInfo,
  OrderItemDescription,
  SellerSKU,
  RestCardInfo,
  CardItemsContent,
  // ItemsTotal,
  // ItemTotalPrice,
  // PriceHeader,
  QunantityWrapper,
  QuantityContent,
  AmountWrapper,
  OrderItems
  // PricePlusQuantity
} from './style'

const OrderProducts = ({ items, ...rest }) => {
  const { t } = useTranslation()
  return (
    <OrderItems>
      {items.map(
        (item, keyIndex) => (
          <OrderItemCard key={keyIndex}>
            <OrderItemImage src={item?.imageInfo?.imageUrl} />
            <OrderItemCardInfo>
              <OrderItemDescription>
                {item?.name}
              </OrderItemDescription>
              <SellerSKU>
                {t('orderDetails.sellerSKU')}: {item?.sku}
              </SellerSKU>
              <RestCardInfo>
                <CardItemsContent>
                  <QunantityWrapper>Qty:</QunantityWrapper>
                  <QuantityContent>{item?.quantity}</QuantityContent>
                </CardItemsContent>
                <CardItemsContent>
                  <QunantityWrapper>{t('orderDetails.itemTotal')}:</QunantityWrapper>
                  <AmountWrapper>
                    {item?.currency}.{' '} {item?.discountedPrice}
                  </AmountWrapper>
                </CardItemsContent>
              </RestCardInfo>
            </OrderItemCardInfo>
          </OrderItemCard>
        )
      )}
      <AmountCard {...rest} />
    </OrderItems>
  )
}

export default OrderProducts
