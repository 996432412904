import React from 'react'
import {
    GraphTitle, SectionTitleGrid, BarContainerLeft, BarContainerRight, SortWrapper, SortIconWrapper, IconWrapper
  } from './style'
import UpArrow from '../../assets/images/upArrow.svg'
import DownArrow from '../../assets/images/downArrow.svg'
import UpDownArrow from '../../assets/images/upDownArrow.svg'

const VerticalBarChartTitleRow = ({barData, handleUpClick, handleDownClick , clearSorting, leftTitle, rightTitle}) => {

  return (
    <SectionTitleGrid container columns={18}>
    <BarContainerLeft item md={13} xs>
      <GraphTitle>{leftTitle}</GraphTitle>
      <SortWrapper style={{ display: 'flex', flexDirection: 'column' }}>
        {barData.key === "name" && barData.isSorted ? 
          <>
          {barData.order === "AES" && <SortIconWrapper onClick={() => handleUpClick('name')}><IconWrapper src={UpArrow} alt='upDownArrow' /></SortIconWrapper>}
          {barData.order === "DES" && <SortIconWrapper onClick={() => { clearSorting() }}><IconWrapper src={DownArrow} alt='upDownArrow' /></SortIconWrapper>}
          </> : 
          <SortIconWrapper onClick={() => { handleDownClick('name') }}><IconWrapper src={UpDownArrow} alt='upDownArrow' /></SortIconWrapper>
        }
      </SortWrapper>
    </BarContainerLeft>
    <BarContainerRight item md={5} xs>
      <SortWrapper>
      {barData.key === "totalRevenue" && barData.isSorted ? 
        <>
        {barData.order === "AES" && <SortIconWrapper onClick={() => handleUpClick('totalRevenue')}><IconWrapper src={UpArrow} alt='upDownArrow' /></SortIconWrapper>}
        {barData.order === "DES" && <SortIconWrapper onClick={() => { clearSorting() }}><IconWrapper src={DownArrow} alt='upDownArrow' /></SortIconWrapper>}
        </> : 
        <SortIconWrapper onClick={() => { handleDownClick('totalRevenue') }}><IconWrapper src={UpDownArrow} alt='upDownArrow' /></SortIconWrapper>
      }
      </SortWrapper>
      <GraphTitle >{rightTitle}</GraphTitle>
    </BarContainerRight>
  </SectionTitleGrid>
  )
}
export default VerticalBarChartTitleRow
