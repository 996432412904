import styled from 'styled-components'

export const LogisticsWrapper = styled.div`
  border-bottom: 10px solid #F6F7FB;
  padding: 14px 0px 11px 27px;
  margin: 0 10px 0 0;
  font-family: Noto Sans;
  letter-spacing: 0px;
`

export const HeaderWrapper = styled.div`  
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 12px;
`

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  
`

export const ContentHeading = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 5px;
`

export const ContentTextWrapper = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`

export const SectionWrapper = styled.div`
  margin-bottom: 19px;
`

export const ArrangePickup = styled.button`
    background: #162E9A;
    border-radius: 6px;
    border: none;
    color: #FFF;
    display: ${(props) => props.show ? 'relative' : 'none'};
    font-Size: 14px;
    width: 122px;
    height: 46px;
    font-family: Noto Sans;
    cursor: pointer;   
`
