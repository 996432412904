import { styled } from '@mui/system'
import { Card, Typography, Box } from '@mui/material'

export const CardsOuterWrapper = styled(Box)(`
    padding-top:40px;
`)

export const CardsInnerWrapper = styled(Box)(`
    display: flex;
    justify-content: space-around;
`)

export const CardOuterWrapper = styled(Card)(`
    box-sizing:border-box;
    border: 1px solid #cacfda;
    border-radius: 20px;
    padding: 20px 28px;
    background-color: white;
    width: calc(25% - 20px);
    margin-bottom: 20px;
    @media (max-width: 1000px) {
        width: calc(50% - 20px);
        padding: 20px;
    }
    @media (max-width: 600px) {
        width:100%;
    }
`)

export const CardImgWrapper = styled(Box)(`
    display: flex;
    border-radius: 50%;
    background-color: #fafbfd;
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 135px;
    margin: 0 0 20px 0;
    & img {
        max-width: 70px;
        max-height: 70px;
    }
    @media (max-width: 1000px) {
        width: 100px;
        height: 100px;
        & img {
            max-width: 50px;
            max-height: 50px;
        }
    }
`)

export const CardTitle = styled(Typography)(`
    font-size: 24px;
    color: #3a4c74;
    padding: 0 0 20px 0;
    font-weight: 600;
    @media (max-width: 1000px) {
        font-size: 20px;
    }
`)

export const CardSubTitle = styled(Typography)(`
    font-size: 17px;
    color: #848484;
    padding: 0 0 20px 0;
    @media (max-width: 1000px) {
        font-size: 15px;
    }
`)
