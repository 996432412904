import { Map } from 'immutable'
import { REHYDRATE } from 'redux-persist/lib/constants'

import {
  BRAND_GET_SUBSCRIPTION_SUCCESS, BRAND_REVOKE_BRAND_ACCESS, BRAND_GET_SUBSCRIPTION_FAILURE
} from '../actions'
import { getFeatures } from '../utilities/app'

const initialState = new Map({
  brandAccess: false,
  resellerAccess: true,
  subscription: {},
  features: {}
})

export const subscription = (state = initialState, action) => {
  const {
    data = {},
    type,
    payload = {}
  } = action
  switch (type) {
    case REHYDRATE:
      return Map(payload.subscription || {})
    case BRAND_GET_SUBSCRIPTION_SUCCESS:
      if (data.subscription && data.subscription.id) {
        return initialState.merge({
          brandAccess: true,
          resellerAccess: false,
          subscription: {
            id: data.subscription.id,
            name: data.subscription.name,
            segment: data.subscription.segment
          },
          features: getFeatures(data.subscription.features || [])
        })
      } else {
        return initialState.merge({
          brandAccess: false,
          resellerAccess: true,
          subscription: {},
          features: {}
        })
      }
      case BRAND_GET_SUBSCRIPTION_FAILURE:
      case BRAND_REVOKE_BRAND_ACCESS:
        return initialState.merge({
          brandAccess: false,
          resellerAccess: true,
            subscription: {},
            features: {}
          })
    default:
      return state
  }
}
