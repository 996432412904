import OrderCancel from '../../components/OrdersActions/CancelOrder'

const OrderCancelContainer = props => {
  return (
    <OrderCancel {
        ...props
      }
    />
  )
}

OrderCancelContainer.displayName = 'OrderCancelContainer'
export default OrderCancelContainer
