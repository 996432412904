import { styled } from '@mui/system'
import { Box, Grid, CardHeader, InputAdornment } from '@mui/material'
import MuiButton from '../MuiButton'

export const FileUploadWrapper = styled(Box)(`
  width:100%;
  display: flex;
  flex-wrap: wrap;
`)

export const ProductOuterWrapper = styled(Box)(`
  padding: 2rem 2rem 2rem 1rem;
  width: 90%;
  margin: 0 auto;
  & div {
    box-sizing: border-box;
  }
  @media (max-width: 600px){
    padding: 1rem 1rem 1rem .5rem;
  }
`)

export const MuiSelectWrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
`)

export const ProductWrapper = styled(Box)(props => `
  padding: 1rem 20px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(136, 150, 203, 0.121312);
  border-radius: 10px;
  ${props.isAddToStore && `
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  `}
  & > div:last-child {
    padding-bottom:0;
  }
  @media (max-width: 425px) {
    margin: 0px;
    padding: .5rem;
  }
`)

export const Title = styled('h1')(`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.48px;
`)

export const FormControl = styled(Box)(`
  width:100%;
  display: flex;
  flex-direction: column;
  padding:0 20px;
  @media (max-width: 1000px) {
    margin: .5rem 0;
    // width: auto;
  }
  @media (max-width: 600px) {
    padding: 0 10px;
  }
`)

export const FormButtonControl = styled(Box)(`
  display: flex;
  flex-direction: row;

  padding:0 20px 15px;

  & button {
    margin: 0 5px;
  }

  & button svg {
    margin-left:4px;
  }

  @media (max-width: 1000px) {
    margin: .5rem 0;
    width: auto;
  }
  @media (max-width: 600px) {
    padding: 0 10px;
    & > div > a {
      width: calc(100% - 100px);
    }
  }
`)

export const FormLabel = styled('label')(`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
  width:100%;
`)

export const FormField = styled(Box)(`
  width: 100%;
  & > div {
    min-width: 100%;
  }
`)

export const SectionTitle = styled('h2')(`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #360D20;
  border-bottom: #360D20 solid 2px;
  width: fit-content;
  @media (max-width: 600px){
    font-size: 15px;
  }
`)

export const SectionContent = styled(Box)(`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px){
    flex-direction: column;
    width: 100%; 
  }
`)

export const InputAdornments = styled(InputAdornment)(props => `
  opacity:.4;
  font-size:12px;
`)

export const FormControlCol1 = styled(Box)(`
  display:flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 30px;
  @media (max-width: 1000px){
    width: 100%;
  }
  @media (max-width: 600px){
    padding: 0 10px 20px;
  }
`)

export const FormControlCol2 = styled(Box)(`
  display:flex;
  flex-direction:column;
  width: 50%;
  padding:0 20px 30px;
  @media (max-width: 1000px){
    width: 100%;
  }
  @media (max-width: 600px){
    padding: 0 10px 20px;
  }
`)

export const FormControlCol3 = styled(Box)(props => `
  display:flex;
  flex-direction:column;
  width: 33.33%;
  padding:0 20px 30px;
  ${props.isAddToStore && `
    // border: 1px red solid;
  `}
  @media (max-width: 1000px){
  }
  @media (max-width: 600px){
    width: 100%;
    padding: 0 10px 20px;
  }
`)

export const FormControlInput = styled(Box)(`
  width: 66.33%;
  display:flex;
  @media (max-width: 1000px){
    flex-direction: column;
    width: 100%;
  }
`)

export const VariationWrapper = styled(Box)(`
  display: flex;
  width: 100%;
  @media (max-width: 600px){
    width: 380px;
  }
`)

export const VariationFormWrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom:15px;
  }
`)

export const ProductVarientWrapper = styled(Box)(`
  padding:0 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (max-width: 600px){
    padding: 0 10px;
  }
  & > button {
    @media (max-width: 600px){
      min-width: 120px;
    }
  }
`)

export const BackButton = styled(MuiButton)(`
  margin-right: 15px;
  @media (max-width: 600px){
    width: 100%;
    margin-bottom: 12px;
  }
`)

export const SubmitWrapper = styled(Box)(`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0 20px;
  @media (max-width: 1000px){
    padding: 0 10px;
  }
  @media (max-width: 600px){
    flex-direction: column;
  }

  & > div > button {
    @media (max-width: 600px){
      flex-direction: column;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  & > div > button:last-child{
    @media (max-width: 600px){
      margin-bottom: 0;
    }
  }
  & > a > button {
    @media (max-width: 600px){
      margin-bottom: 10px;
      width: 100%;
    }
  }
`)

export const FormControlSwitchWrap = styled(Box)(`
  border: 1px #DADADA solid;
  background-color: #F9FBFD;
  border-radius: 4px;
  padding:0px 10px 10px;
  margin-top:8px;
`)

export const CardHeaderWrap = styled(CardHeader)(`
  padding:10px 10px 10px 0;
  align-items: flex-start;
  span {
    display: flex;
    align-items: center;
  }
`)

export const CardOuterWrap = styled(Grid)(props => `
  border-bottom: 1px #f1f1f1 solid;
  margin-bottom: 10px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
  ${props?.isAddToStore && `
    flex-direction: column;
    & > div {
      width: calc(100% - 20px);
      padding: 10px 20px;
    }
  `}
  @media (max-width: 1000px){
    flex-direction: column;
    & > div{
      width: 100%;
      padding: 10px 20px 0;
    }
  }
  @media (max-width: 600px){
    & > div{
      padding: 0 10px 0;
    }
  }
`)

export const ImgWrap = styled(Grid)(`
  width:115px;
  height:90px;
  background:#d3d3d3;
  overflow:hidden;
  img {
    max-width:100%;
  }
`)

export const StoreLogo = styled('img')(`
  width:30px;
  max-height:30px;
`)

export const ProductAttributeWrapper = styled('div')(props => `
  width: 100%;
  ${props.isAddToStore && `
    & > div {
      width: 100%;
    }
    & > div > div{
      width: calc(50% - 20px);
      padding:0 10px 30px;
      @media (max-width: 600px){
        width: calc(100% - 20px);
      }
    }
  `};
`)

export const MuiTextFieldWithSelect = styled('div')(props => `
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between;
    & > label {
      width: 100%;
    }
    & > div {
      width: 48%;
    }
`)
