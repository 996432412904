import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import {
  Box,
  IconButton,
  Typography,
  Menu
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import MenuItem from '@mui/material/MenuItem'
// import Badge from '@mui/material/Badge'
import ListItem from '@mui/material/ListItem'
import { useTranslation } from 'react-i18next'
import Language from '../Language'
import BrandsSelect from './BrandsSelect'

import {
  AvatarIcon,
  Logo,
  Notification,
  // NotificationIcon,
  HamburgerIcon,
  ListItemTextNew,
  PaperWrapper
} from './style'

import logo from '../../assets/images/R-dash-360-Logo-White-01.svg'
// import notification from '../../assets/images/notification.svg'
import hamburgerIcon from '../../assets/images/hamburger.svg'
import { useNavigate } from 'react-router-dom'
import { ROUTE_LOGIN } from '../../constants'
import ProfilePicModal from '../ProfilePicModal'

const TopNavbar = props => {
  const {
    userName = '',
    userEmail = '',
    onLogout,
    toggleSidebar,
    brandAccess,
    brandsList,
    selectedBrand,
    brandIsLoading,
    brandError,
    onGetBrandList,
    brandsFetched,
    brandErrorDisplayed,
    userId
  } = props
  const { t } = useTranslation()
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const navigate = useNavigate()
  const userimageUrl = useSelector((state) => state?.users?.get('imageUrl'))
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)
  const smallScreen = useMediaQuery('(max-width: 768px)')

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const handleOnLogout = () => {
    onLogout()
    navigate(ROUTE_LOGIN)
  }
  const _brandSelect = () => {
    return (
      <BrandsSelect
        onGetBrandList={onGetBrandList}
        brandsList={brandsList}
        selectedBrand={selectedBrand}
        brandIsLoading={brandIsLoading}
        brandError={brandError}
        userId={userId}
        brandsFetched={brandsFetched}
        brandErrorDisplayed={brandErrorDisplayed}
      />
    )
  }
  return (
    <><PaperWrapper elevation={12}>
      <Box display={{ xs: 'flex', md: 'none' }}>
        <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
          <IconButton size='large'>
            <HamburgerIcon src={hamburgerIcon} alt='Menu' onClick={toggleSidebar} />
          </IconButton>
          <Logo src={logo} alt='Raena' />
        </Box>
      </Box>
      <Box>
        {brandAccess && !smallScreen &&
          <Box display={{ md: 'flex' }}>
            {_brandSelect()}
          </Box>}
      </Box>
      <Box display='flex'>
        <Notification>
          <Language />
        </Notification>
        {/* <Notification>
          <IconButton size='large'>
            <Badge badgeContent={0} color='warning'>
              <NotificationIcon src={notification} alt='' />
            </Badge>
          </IconButton>
        </Notification> */}
        <ListItem button onClick={handleOpenUserMenu}>
          {
          userimageUrl ? <AvatarIcon alt={userName} src={userimageUrl} /> : <AccountCircleIcon fontSize='large' />
          }
          <ListItemTextNew
            primary={!!userName && userName}
            secondary={!!userEmail && userEmail}
            sx={{ display: { xs: 'none', sm: 'block' } }}
          />
        </ListItem>
        <Menu
          sx={{ mt: '65px' }}
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <ListItemTextNew
            primary={!!userName && userName}
            secondary={!!userEmail && userEmail}
            sx={{ display: { xs: 'block', sm: 'none' }, pr: '15px' }}
          />
          <Notification
            sx={{ display: { xs: 'block', sm: 'none' }, border: 'none', height: '40px' }}
          >
            <Language popup />
          </Notification>
          {/* <MenuItem onClick={() => setOpen(true)}>
            <Typography>Change Profile Pic</Typography>
          </MenuItem> */}
          <ProfilePicModal open={open} handleClose={handleClose} />
          <MenuItem onClick={handleOnLogout}>
            <Typography>{t('menu.logout')}</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </PaperWrapper>
      {brandAccess && smallScreen &&
        <PaperWrapper elevation={12} sx={{ display: { xs: 'block' }, paddingLeft: '20px', paddingRight: '20px' }}>
          {_brandSelect()}
        </PaperWrapper>}
    </>
  )
}

TopNavbar.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string
}
TopNavbar.displayName = 'TopNavBar'
export default TopNavbar
