import { call, put, takeLatest } from 'redux-saga/effects'
import { messageStrToArray } from '../utilities/app'

import {
  PAGEBUILDER_LINKS_CREATE_LOADING,
  PAGEBUILDER_LINKS_CREATE_POST_DATA,
  PAGEBUILDER_LINKS_CREATE_POST_DATA_SUCCESS,
  PAGEBUILDER_LINKS_CREATE_POST_DATA_FAILURE,
  PAGEBUILDER_LINKS_UPDATE_LOADING,
  PAGEBUILDER_LINKS_UPDATE_POST_DATA,
  PAGEBUILDER_LINKS_UPDATE_POST_DATA_SUCCESS,
  PAGEBUILDER_LINKS_UPDATE_POST_DATA_FAILURE,
  PAGEBUILDER_LINKS_DELETE_LOADING,
  PAGEBUILDER_LINKS_DELETE_POST_DATA,
  PAGEBUILDER_LINKS_DELETE_POST_DATA_SUCCESS,
  PAGEBUILDER_LINKS_DELETE_POST_DATA_FAILURE,
  PAGEBUILDER_SECTIONS_REORDER_LOADING,
  PAGEBUILDER_SECTIONS_REORDER_POST_DATA,
  PAGEBUILDER_SECTIONS_REORDER_POST_DATA_SUCCESS,
  PAGEBUILDER_SECTIONS_REORDER_POST_DATA_FAILURE,
  PAGEBUILDER_LINKS_REORDER_LOADING,
  PAGEBUILDER_LINKS_REORDER_POST_DATA,
  PAGEBUILDER_LINKS_REORDER_POST_DATA_SUCCESS,
  PAGEBUILDER_LINKS_REORDER_POST_DATA_FAILURE,
  PAGEBUILDER_LINKS_FETCH_LOADING,
  PAGEBUILDER_LINKS_FETCH_GET_DATA,
  PAGEBUILDER_LINKS_FETCH_GET_DATA_SUCCESS,
  PAGEBUILDER_LINKS_FETCH_GET_DATA_FAILURE,
  PAGEBUILDER_ABOUT_CREATE_LOADING,
  PAGEBUILDER_ABOUT_CREATE_POST_DATA_SUCCESS,
  PAGEBUILDER_ABOUT_CREATE_POST_DATA_FAILURE,
  PAGEBUILDER_ABOUT_CREATE_POST_DATA,
  PAGEBUILDER_ABOUT_FETCH_LOADING,
  PAGEBUILDER_ABOUT_FETCH_POST_DATA_SUCCESS,
  PAGEBUILDER_ABOUT_FETCH_POST_DATA_FAILURE,
  PAGEBUILDER_ABOUT_FETCH_POST_DATA,
  EARNINGS_VIEW_LOADING,
  EARNINGS_VIEW_SUCCESS,
  EARNINGS_VIEW_FAILURE,
  EARNINGS_VIEW_FETCH
} from '../actions'

import {
  API_CREATE_PAGEBUILDER_LINKS,
  API_GET_PAGEBUILDER_LINKS,
  API_CREATE_PAGEBUILDER_SECTIONS_REORDER,
  API_CREATE_PAGEBUILDER_LINKS_REORDER,
  API_CREATE_PAGEBUILDER_ABOUT,
  API_FETCH_PAGEBUILDER_ABOUT,
  API_FETCH_EARNINGS_VIEW
} from '../constants'
import { getApi, patchApi, postApi, deleteApi } from '../utilities/axios'
import { toast } from 'react-toastify'
import { store } from '../store'

function * onFetchEarningsData ({ startDate, endDate }) {
  yield put({ type: EARNINGS_VIEW_LOADING, isLoading: true })
  try {
    const response = yield call(getApi, (startDate && endDate) ? `${API_FETCH_EARNINGS_VIEW}?startDate=${startDate}&endDate=${endDate}` : API_FETCH_EARNINGS_VIEW)
    yield put({ type: EARNINGS_VIEW_SUCCESS, earningsData: response?.data?.data || {} })
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: EARNINGS_VIEW_SUCCESS, earningsData: {} })
  }
  yield put({ type: EARNINGS_VIEW_FAILURE, isLoading: false })
}

function * onCreatePageBuilderAboutCreateData (action) {
  yield put({ type: PAGEBUILDER_ABOUT_CREATE_LOADING, isLoading: true })
  try {
    const { resellerId, ...requestBody } = action?.payload || {}
    const response = yield call(patchApi, API_CREATE_PAGEBUILDER_ABOUT, requestBody)
    toast.success('Store details saved successfully')
    store.dispatch({ type: PAGEBUILDER_ABOUT_FETCH_POST_DATA, payload: { resellerId } })
    yield put({ type: PAGEBUILDER_ABOUT_CREATE_POST_DATA_SUCCESS, pageBuilderLinksCreateData: response?.data?.data || {} })
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_ABOUT_CREATE_POST_DATA_SUCCESS, pageBuilderLinksCreateData: {} })
  }
  yield put({ type: PAGEBUILDER_ABOUT_CREATE_POST_DATA_FAILURE, isLoading: false })
}

function * onFetchPageBuilderAboutCreateData (action) {
  yield put({ type: PAGEBUILDER_ABOUT_FETCH_LOADING, isLoading: true })
  try {
    const query = action.payload
    const response = yield call(getApi, API_FETCH_PAGEBUILDER_ABOUT(query?.resellerId))
    yield put({ type: PAGEBUILDER_ABOUT_FETCH_POST_DATA_SUCCESS, pageBuilderLinksCreateData: response?.data?.data || {} })
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_ABOUT_FETCH_POST_DATA_SUCCESS, pageBuilderLinksCreateData: {} })
  }
  yield put({ type: PAGEBUILDER_ABOUT_FETCH_POST_DATA_FAILURE, isLoading: false })
}

function * onGetPageBuilderLinksCreateData (action) {
  yield put({ type: PAGEBUILDER_LINKS_CREATE_LOADING, pageBuilderLinksCreateIsLoading: true })
  try {
    const query = {
      imageUrl: action.payload?.imageUrl?.[0]?.url || null,
      price: action.payload?.price,
      title: action.payload?.title,
      url: action.payload?.url
    }
    const response = yield call(postApi, API_CREATE_PAGEBUILDER_LINKS(action.payload?.linkType), query)
    yield put({ type: PAGEBUILDER_LINKS_CREATE_POST_DATA_SUCCESS, pageBuilderLinksCreateData: response?.data?.data || {} })
    toast.success(`Your ${action.payload?.msgType} has been added successfully`)
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_LINKS_CREATE_POST_DATA_FAILURE, pageBuilderLinksCreateData: {} })
  }
  yield put({ type: PAGEBUILDER_LINKS_CREATE_LOADING, pageBuilderLinksCreateIsLoading: false })
}

function * onGetPageBuilderLinksUpdateData (action) {
  yield put({ type: PAGEBUILDER_LINKS_UPDATE_LOADING, pageBuilderLinksUpdateIsLoading: true })
  try {
    const query = {
      // imageUrl: action.payload?.imageUrl,
      imageUrl: action.payload?.imageUrl?.[0]?.url || null,
      price: action.payload?.price,
      title: action.payload?.title,
      url: action.payload?.url,
      isPublished: action.payload?.isPublished,
      type: action.payload?.type
    }
    const response = yield call(patchApi, API_CREATE_PAGEBUILDER_LINKS(action.payload?.id), query)
    yield put({ type: PAGEBUILDER_LINKS_UPDATE_POST_DATA_SUCCESS, pageBuilderLinksUpdateData: response?.data?.data || {} })
    toast.success(`Your ${action.payload?.msgType} has been updated successfully`)
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_LINKS_UPDATE_POST_DATA_FAILURE, pageBuilderLinksUpdateData: {} })
  }
  yield put({ type: PAGEBUILDER_LINKS_UPDATE_LOADING, pageBuilderLinksUpdateIsLoading: false })
}

function * onGetPageBuilderLinksDeleteData (action) {
  yield put({ type: PAGEBUILDER_LINKS_DELETE_LOADING, pageBuilderLinksDeleteIsLoading: true })
  try {
    const response = yield call(deleteApi, API_CREATE_PAGEBUILDER_LINKS(action.payload?.id))
    yield put({ type: PAGEBUILDER_LINKS_DELETE_POST_DATA_SUCCESS, pageBuilderLinksDeleteData: response?.data?.data || {} })
    toast.success('Deleted successfully')
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_LINKS_DELETE_POST_DATA_FAILURE, pageBuilderLinksDeleteData: {} })
  }
  yield put({ type: PAGEBUILDER_LINKS_DELETE_LOADING, pageBuilderLinksDeleteIsLoading: false })
}

function * onGetPageBuilderSectionsReorderData (action) {
  yield put({ type: PAGEBUILDER_SECTIONS_REORDER_LOADING, pageBuilderSectionsReorderIsLoading: true })
  try {
    const response = yield call(postApi, API_CREATE_PAGEBUILDER_SECTIONS_REORDER, action.payload)
    yield put({ type: PAGEBUILDER_SECTIONS_REORDER_POST_DATA_SUCCESS, pageBuilderSectionsReorderData: response?.data?.data || {} })
    toast.success('Your item Reordering is successful')
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_SECTIONS_REORDER_POST_DATA_FAILURE, pageBuilderSectionsReorderData: {} })
  }
  yield put({ type: PAGEBUILDER_SECTIONS_REORDER_LOADING, pageBuilderSectionsReorderIsLoading: false })
}

function * onGetPageBuilderLinksReorderData (action) {
  yield put({ type: PAGEBUILDER_LINKS_REORDER_LOADING, pageBuilderLinksReorderIsLoading: true })
  try {
    const response = yield call(postApi, API_CREATE_PAGEBUILDER_LINKS_REORDER, action.payload)
    yield put({ type: PAGEBUILDER_LINKS_REORDER_POST_DATA_SUCCESS, pageBuilderLinksReorderData: response?.data?.data || {} })
    toast.success('Your item Reordering is successful')
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_LINKS_REORDER_POST_DATA_FAILURE, pageBuilderLinksReorderData: {} })
  }
  yield put({ type: PAGEBUILDER_LINKS_REORDER_LOADING, pageBuilderLinksReorderIsLoading: false })
}

function * onGetPageBuilderLinksFetchData (action) {
  yield put({ type: PAGEBUILDER_LINKS_FETCH_LOADING, pageBuilderLinksFetchIsLoading: true })
  try {
    const response = yield call(getApi, API_GET_PAGEBUILDER_LINKS(action.payload?.resellerId))
    yield put({ type: PAGEBUILDER_LINKS_FETCH_GET_DATA_SUCCESS, pageBuilderLinksFetchData: response?.data?.data || {} })
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PAGEBUILDER_LINKS_FETCH_GET_DATA_FAILURE, pageBuilderLinksFetchData: {} })
  }
  yield put({ type: PAGEBUILDER_LINKS_FETCH_LOADING, pageBuilderLinksFetchIsLoading: false })
}

function * pageBuilderSaga () {
  yield takeLatest(PAGEBUILDER_LINKS_CREATE_POST_DATA, onGetPageBuilderLinksCreateData)
  yield takeLatest(PAGEBUILDER_LINKS_UPDATE_POST_DATA, onGetPageBuilderLinksUpdateData)
  yield takeLatest(PAGEBUILDER_LINKS_DELETE_POST_DATA, onGetPageBuilderLinksDeleteData)
  yield takeLatest(PAGEBUILDER_SECTIONS_REORDER_POST_DATA, onGetPageBuilderSectionsReorderData)
  yield takeLatest(PAGEBUILDER_LINKS_REORDER_POST_DATA, onGetPageBuilderLinksReorderData)
  yield takeLatest(PAGEBUILDER_LINKS_FETCH_GET_DATA, onGetPageBuilderLinksFetchData)
  yield takeLatest(PAGEBUILDER_ABOUT_CREATE_POST_DATA, onCreatePageBuilderAboutCreateData)
  yield takeLatest(PAGEBUILDER_ABOUT_FETCH_POST_DATA, onFetchPageBuilderAboutCreateData)
  yield takeLatest(EARNINGS_VIEW_FETCH, onFetchEarningsData)
}

export default pageBuilderSaga
