import { Map } from 'immutable'
import { toast } from 'react-toastify'
import i18next from 'i18next'

import {
    GET_OFFERS,
    GET_OFFERS_SUCCESS,
    GET_OFFERS_FAILURE,
    GET_SKU,
    GET_SKU_SUCCESS,
    GET_SKU_FAILURE,
    CREATE_PROMO,
    CREATE_PROMO_SUCCESS,
    CREATE_PROMO_FAILURE,
    RESET_FIELDS,
    RESET_VALIDATE_FIELDS,
    PATCH_PROMO,
    PATCH_PROMO_SUCCESS,
    PATCH_PROMO_FAILURE,
    VALIDATE_PROMO,
    VALIDATE_PROMO_SUCCESS,
    VALIDATE_PROMO_FAILURE
} from '../actions'
import {
    APP_CONSTANT
} from '../constants';

const errorMessageTranslations = (error) => {
    switch (error){
        case "No skus present": 
            return i18next.t('createPromo.noSkuPresent');

        case "Unsupported selection criteria": 
            return i18next.t('createPromo.unsupportedSelectionCriteria');

        case "Free sku combination repeated": 
            return i18next.t('createPromo.freeSkuCombinationRepeated');

        case "Free sku quantity is greater than paid sku":
            return i18next.t('createPromo.freeSkuQuantityIsGreater');

        case "Total free sku qty exceeds base sku qty": 
            return i18next.t('createPromo.totalFreeSkuQtyExceeds');

        case "Qty should be greater than 0": 
            return i18next.t('createPromo.qtyShouldBeGreaterThanZero');

        case "Base SKU Qty value should be present while Buy N Get X Promo creation": 
            return i18next.t('createPromo.baseSkuQtyRequired');

        case "Base Sku should contain at least 1 entry": 
            return i18next.t('createPromo.baseSkuQtyRequired');

        case "Free SKU Qty value should be present while Buy N Get X Promo creation": 
            return i18next.t('createPromo.freeSkuQtyRequired');

        default: 
            return error;

    }
};

const initialState = new Map({
    isOfferLoading: false,
    isOfferLoaded: false,
    isOfferFailed: false,
    offerDetails: {},
    isSkuLoading: false,
    isSkuLoaded: false,
    isError: false,
    skuDetails: {},
    isCreate: false,
    isCreateSuccess: false,
    errorDetails: {},
    isUpdate: false,
    isUpdateSuccess: false,
    updateDetails: {},
    isValidate: false,
    isValidateSuccess: false,
    validateData: {}
});

const { CREATE_ERROR } = APP_CONSTANT;

export const createPromo = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_OFFERS:
            return { ...state, isOfferLoading: true, isOfferLoaded:false};
        case GET_OFFERS_SUCCESS:
            return { ...state, isOfferLoading: false, isOfferLoaded: true, offerDetails: data };
        case GET_OFFERS_FAILURE:
            return { ...state, isOfferLoading: false, isOfferLoaded: false, isOfferFailed: true };
        case GET_SKU:
            return { ...state, isSkuLoading: true, isSkuLoaded:false, skuDetails: {} };
        case GET_SKU_SUCCESS:
            return { ...state, isSkuLoading: false, isSkuLoaded: true, skuDetails: data };
        case GET_SKU_FAILURE:
            return { ...state, isSkuLoading: false, isSkuLoaded: false, skuDetails: {}, isError: false };
        case CREATE_PROMO:
            return { ...state, isCreate: true, isCreateSuccess: false, isError: false, errorDetails: {} };
        case CREATE_PROMO_SUCCESS:
            toast.info(i18next.t('createPromo.promoCreateSuccess'));
            return { ...state, isCreate: false, isCreateSuccess: true, errorDetails: {}, isError: false };
        case CREATE_PROMO_FAILURE:
            let errorMsg = ''
            if (data?.errorType === CREATE_ERROR) {
                errorMsg = data;
            } else {
                errorMsg = {...data, message:`${errorMessageTranslations(data?.message) || data?.message || 'Promo Creation Failed.'}` }
                // toast.error(`${errorMessageTranslations(data?.message) || data?.message || 'Promo Creation Failed.'}`);
            }
            return { ...state, isCreate: false, isCreateSuccess: false, errorDetails: errorMsg, isError: true };

        case RESET_FIELDS:
            return { ...state, isCreate: false, isCreateSuccess: false, isUpdateSuccess: false, errorDetails: {}, isError: false };
        
        case RESET_VALIDATE_FIELDS:
            return { ...state, isValidate: false, isValidateSuccess: false, errorDetails: {}, isError: false };
        case VALIDATE_PROMO:
            return { ...state, isValidate: true, isValidateSuccess: false, errorDetails: {}, isError: false };
        case VALIDATE_PROMO_SUCCESS:
            return { ...state, isValidate: false, isValidateSuccess: true, validateData: data, isError: false };
        case VALIDATE_PROMO_FAILURE:
            return { ...state, isValidate: false, isValidateSuccess: false, errorDetails: {...data, message: errorMessageTranslations(data.message) }, isError: true };

        case PATCH_PROMO:
            return { ...state, isUpdate: true, isUpdateSuccess: false, updateDetails: {} };
        case PATCH_PROMO_SUCCESS:
            toast.info(i18next.t('createPromo.promoUpdateSuccess'));
            return { ...state, isUpdate: false, isUpdateSuccess: true, updateDetails: data };
        case PATCH_PROMO_FAILURE:
            let error = ''
            if (data?.errorType === CREATE_ERROR) {
                error = data;
            } else {
                toast.error(`${errorMessageTranslations(data?.message) || data?.message || 'Promo Creation Failed.'}`);
            }
            return { ...state, isUpdate: false, isUpdatSuccess: false, updateDetails: {} };
        default:
            return state;
    }
};

