import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import {
  STORES_LOADING,
  STORES_GET_LIST,
  STORES_GET_LIST_FAILURE,
  STORES_GET_LIST_SUCCESS,
  STORES_SELECTED_GET_LIST,
  STORES_SELECTED_GET_LIST_FAILURE,
  STORES_SELECTED_GET_LIST_SUCCESS,
  STORES_DISCONNECT,
  STORES_DISCONNECT_FAILURE,
  STORES_SYNC_PRODUCTS,
  STORES_SYNC_PRODUCTS_FAILURE,
  STORES_SYNC_PRODUCTS_SUCCESS,
  STORES_SYNC_ORDERS,
  STORES_SYNC_ORDERS_FAILURE,
  STORES_SYNC_ORDERS_SUCCESS,
  STORES_RENAME,
  STORES_RENAME_SUCCESS,
  STORES_RENAME_FAILURE
} from '../actions'

import {
  API_DISCONNECT_STORE,
  API_GET_STORES,
  API_GET_SELECTED_STORES,
  API_SYNC_PRODUCTS,
  API_SYNC_ORDERS,
  API_STORE_RENAME
} from '../constants'
import { getApi, deleteApi, postApi, putApi } from '../utilities/axios'

function * onGetStoreList () {
  yield put({ type: STORES_LOADING, isLoading: true })
  try {
    const response = yield call(getApi, API_GET_STORES)
    yield put({ type: STORES_GET_LIST_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    yield put({ type: STORES_GET_LIST_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: STORES_GET_LIST_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: STORES_GET_LIST_SUCCESS, data: {} })
  }
  yield put({ type: STORES_LOADING, isLoading: false })
}

function * onGetSelectedStoreAttributeList (data) {
  const { rsellViewId, selectedStores } = data?.payload
  const body = { selectedStores: selectedStores }

  yield put({ type: STORES_LOADING, isLoading: true })
  try {
    const response = yield call(postApi, API_GET_SELECTED_STORES(rsellViewId), body)
    yield put({ type: STORES_SELECTED_GET_LIST_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    yield put({ type: STORES_SELECTED_GET_LIST_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: STORES_SELECTED_GET_LIST_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: STORES_SELECTED_GET_LIST_SUCCESS, data: {} })
  }
  yield put({ type: STORES_LOADING, isLoading: false })
}

function * onDisconnectStore ({ data }) {
  const { storeId } = data
  yield put({ type: STORES_LOADING, isLoading: true })
  try {
    yield call(deleteApi, API_DISCONNECT_STORE(storeId))
    // Fetch the store list again from BE
    toast.success('Store disconnected successfully')
    yield put({ type: STORES_GET_LIST })
  } catch (e) {
    yield put({ type: STORES_DISCONNECT_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: STORES_DISCONNECT_FAILURE, message: '' })
  }
  yield put({ type: STORES_LOADING, isLoading: false })
}

function * onSyncProducts ({ data }) {
  const {
    storeId,
    marketPlaceShopId,
    shopName: name,
    isConnected
  } = data
  const body = {
    name,
    marketplace_shop_id: marketPlaceShopId,
    is_connected: isConnected
  }

  yield put({ type: STORES_LOADING, isLoading: true })
  try {
    yield call(postApi, API_SYNC_PRODUCTS(storeId), body)
    yield put({ type: STORES_SYNC_PRODUCTS_SUCCESS, message: 'Products sync completed' })
    yield put({ type: STORES_SYNC_PRODUCTS_SUCCESS, message: '' })
  } catch (e) {
    yield put({ type: STORES_SYNC_PRODUCTS_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: STORES_SYNC_PRODUCTS_FAILURE, message: '' })
  }
  yield put({ type: STORES_LOADING, isLoading: false })
}

function * onSyncOrders ({ data }) {
  const {
    storeId,
    marketPlaceShopId,
    shopName: name,
    isConnected
  } = data
  const body = {
    name,
    marketplace_shop_id: marketPlaceShopId,
    is_connected: isConnected
  }

  yield put({ type: STORES_LOADING, isLoading: true })
  try {
    yield call(postApi, API_SYNC_ORDERS(storeId), body)
    yield put({ type: STORES_SYNC_ORDERS_SUCCESS, message: 'Orders sync completed' })
    yield put({ type: STORES_SYNC_ORDERS_SUCCESS, message: '' })
  } catch (e) {
    yield put({ type: STORES_SYNC_ORDERS_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: STORES_SYNC_ORDERS_FAILURE, message: '' })
  }
  yield put({ type: STORES_LOADING, isLoading: false })
}
function * onRenameStore (action) {
  const { shopName, shoplogourl, description, id } = action?.payload
  const body = {
    shop_name: shopName,
    shop_logo_url: shoplogourl,
    description: description
  }
  yield put({ type: STORES_LOADING, isLoading: true })
  try {
    const response = yield call(putApi, API_STORE_RENAME(id), body)
    toast.success('Store ReName is Successfully')
    yield put({ type: STORES_RENAME_SUCCESS, message: response?.data?.message })
  } catch (e) {
    toast.error(e?.response?.data?.message, { autoClose: 7000 })
    yield put({ type: STORES_RENAME_FAILURE, message: e.message })
    yield put({ type: STORES_RENAME_FAILURE, message: '' })
  }
  yield put({ type: STORES_LOADING, isLoading: false })
}

function * storesSaga () {
  yield takeLatest(STORES_GET_LIST, onGetStoreList)
  yield takeLatest(STORES_SELECTED_GET_LIST, onGetSelectedStoreAttributeList)
  yield takeLatest(STORES_DISCONNECT, onDisconnectStore)
  yield takeLatest(STORES_SYNC_PRODUCTS, onSyncProducts)
  yield takeLatest(STORES_SYNC_ORDERS, onSyncOrders)
  yield takeLatest(STORES_RENAME, onRenameStore)
}

export default storesSaga
