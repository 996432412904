export const sortingByDate = (arr) => {
  if (arr[0]?.startDateTime) {
    return arr.sort(function (a, b) {
      return new Date(a.startDateTime) - new Date(b.startDateTime)
    })
  }

  return arr.sort(function (a, b) {
    return new Date(a.date) - new Date(b.date)
  })
}

export const getDate = date => {
  const date1 = new Date(date)
  return `${date1.getDate()}-${date1.getMonth() + 1}-${date1.getFullYear()}`
}

export const getInveteredDate = date => {
  const date1 = new Date(date)
  return `${date1.getFullYear()}-${date1.getMonth() + 1}-${date1.getDate()}`
}
