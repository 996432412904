import React, { useState } from 'react'
import PropTypes from 'prop-types'
import qs from 'query-string'
import TopNavbar from '../TopNavbar/TopNavbar'
import Sidebar from '../Sidebar'
import { OuterContent, InnerContent, LayoutContainer } from './style'

const Layout = (props) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const toggleSidebar = () => {
    setIsSidebarVisible(state => !state)
  }
  const {
    userName = '',
    userEmail = '',
    userId,
    brandAccess,
    brandsList,
    selectedBrand,
    brandIsLoading,
    brandError,
    onGetBrandList,
    onLogout,
    children,
    brandsFetched,
    brandErrorDisplayed
  } = props
  const topNavbarProps = {
    userName,
    userEmail,
    onLogout,
    toggleSidebar,
    brandAccess,
    brandsList,
    selectedBrand,
    brandIsLoading,
    brandError,
    onGetBrandList,
    userId,
    brandsFetched,
    brandErrorDisplayed
  }

  const { hideHeader } = qs.parse(window.location.search)

  return (
    <LayoutContainer>
      <Sidebar isSidebarVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
      <OuterContent>
        {!hideHeader && <TopNavbar {...topNavbarProps} />}
        <InnerContent hideHeader={hideHeader}>
          {children}
        </InnerContent>
      </OuterContent>
    </LayoutContainer>
  )
}

Layout.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  onLogout: PropTypes.func,
  children: PropTypes.element.isRequired
}
Layout.displayName = 'Layout'
export default Layout
