import { List, Map } from 'immutable'

import {
  STORES_LOADING,
  STORES_GET_LIST_SUCCESS,
  STORES_GET_LIST_FAILURE,
  STORES_SELECTED_GET_LIST_SUCCESS,
  STORES_SELECTED_GET_LIST_FAILURE,
  STORES_DISCONNECT_FAILURE,
  STORES_SYNC_PRODUCTS_SUCCESS,
  STORES_SYNC_PRODUCTS_FAILURE,
  STORES_SYNC_ORDERS_FAILURE,
  STORES_SYNC_ORDERS_SUCCESS,
  STORES_RENAME_SUCCESS,
  STORES_RENAME_FAILURE
} from '../actions'

const initialState = new Map({
  isLoading: false,
  error: '',
  totalRecords: 0,
  content: List([]),
  renameStore: ''
})

export const stores = (state = initialState, action) => {
  const {
    data,
    isLoading,
    message,
    type
  } = action

  switch (type) {
    case STORES_LOADING:
      return state.set('isLoading', isLoading)
    case STORES_GET_LIST_SUCCESS:
      return state.merge({
        totalRecords: data?.totalRecords || 0,
        content: List(data?.content || [])
      })
    case STORES_RENAME_SUCCESS:
      return state.set('renameStore', message)
    case STORES_RENAME_FAILURE:
      return state.set('renameStore', message)
    case STORES_GET_LIST_FAILURE:
    case STORES_SELECTED_GET_LIST_SUCCESS:
      return state.merge({
        content: List(data?.shopee || [])
      })
    case STORES_SELECTED_GET_LIST_FAILURE:
    case STORES_SYNC_PRODUCTS_SUCCESS:
    case STORES_SYNC_PRODUCTS_FAILURE:
    case STORES_SYNC_ORDERS_SUCCESS:
    case STORES_SYNC_ORDERS_FAILURE:
    case STORES_DISCONNECT_FAILURE:
      return state.set('error', message)
    default:
      return state
  }
}
