import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'

export const PageBuilderWrapper = styled(Box)(props => `
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(136, 150, 203, 0.121312);
  border-radius: 10px;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: 600px){
  padding-top: 0px;
  padding-bottom: 0px;
  }
`)

export const SectionContent = styled(Box)(`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px){
    flex-direction: column;
    width: 100%; 
  }
`)

export const FormOuterControl = styled(Box)(`
  width:100%;
  display: flex;
`)

export const EarningsContainer = styled(Box)(`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`)

export const EarningsBox = styled(Box)(`
  width: 100%;
  max-width: 600px;
`)

export const CurrentBalanceContainer = styled(Box)(`
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;

  @media (max-width: 600px){
    padding: 10px;
    }
`)

export const BalanceHeading = styled(Typography)(`
  color: white;
  font-size: 1rem;

  @media (max-width: 600px){
    font-size: 14px;
    }
`)

export const PriceHeading = styled(Typography)(`
  color: white;
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 1rem;

  @media (max-width: 600px){
    font-size: 24px;
    }
`)

export const TotalEarningsContainer = styled(Box)(`
  background-color: white;
  width: 100%;
  padding: 20px;

  @media (max-width: 600px){
    padding: 10px;
    }
`)

export const TotalEarningsHeading = styled(Typography)(`
  font-size: 12px;
  color: #A2A5BB;

  @media (max-width: 600px){
    font-size: 10px;
    }
`)

export const Type = styled(Typography)(`
  font-size: 14px;

  @media (max-width: 600px){
    font-size: 12px;
    }

    @media (max-width: 400px){
      font-size: 11px;
      }
`)

export const OrderId = styled(Typography)(`
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 600px){
    font-size: 14px;
    }

  @media (max-width: 400px){
    font-size: 11px;
    }
`)

export const Date = styled(Typography)(`
  font-size: 16px;
  color: #00000080;

  @media (max-width: 600px){
    font-size: 14px;
    }

    @media (max-width: 400px){
      font-size: 11px;
      }
`)

export const WithdrawEarninsPrice = styled(Typography)(`
  font-size: 16px;
  color: #A2A5BB;
  font-weight: 600;
  margin-left: 12px;

  @media (max-width: 600px){
    font-size: 14px;
    }
`)

export const WithdrawEarninsBox = styled(Box)(`
  margin-left: 30px;

  @media (max-width: 600px){
    margin-left: 20px;
    }
`)

export const TransactionBox = styled(Box)(`
display: flex;
align-items: center;
justify-content: space-between;
  background-color: #F7F8FF;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;

  @media (max-width: 600px){
    padding-left: 10px;
  padding-right: 10px;
    }
`)

export const AboutHeading = styled(Typography)(`
  font-size: 16px;
  color: #A2A5BB;
  font-weight: 600;
`)
