import React, { useState } from 'react'
import { LANGUAGES, DEFAULT_LANG } from '../../constants'
import { useTranslation } from 'react-i18next'

import TranslateIcon from '@mui/icons-material/Translate'

import {
  Tooltip,
  Box,
  MenuItem,
  Menu,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@mui/material'

const Language = (props) => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const [lng, setLng] = useState(LANGUAGES)
  const open = Boolean(anchorEl)
  let activeLang = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : DEFAULT_LANG
  const handleIconClick = (event) => {
    setAnchorEl(event.target)
  }
  const handleMenuClick = (p) => {
    window.localStorage.setItem('i18nextLng', p.value)
    activeLang = p.value
    const lang = lng.map(l => { l.active = (l.value === p.value); return l })
    setLng(lang)

    i18n.changeLanguage(p.value)

    handleClose()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {!props.popup &&
        <>
          <Tooltip title={t('language') || 'Language'}>
            <IconButton onClick={handleIconClick} sx={{ width: 46, height: 46 }}>
              <TranslateIcon sx={{ width: 20, height: 20 }} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
          >
            {lng.map((p, i) => (
              <MenuItem value={p.value} key={i} onClick={() => handleMenuClick(p)} selected={activeLang === p.value}>{p.label}</MenuItem>
            ))}
          </Menu>
        </>}

      {props.popup &&
        <Box sx={{ display: 'flex' }}>
          <Tooltip title={t('language') || 'Language'}>
            <IconButton sx={{ mr: '5px', width: 40, height: 40 }}>
              <TranslateIcon sx={{ width: 20, height: 20 }} />
            </IconButton>
          </Tooltip>
          <RadioGroup defaultValue={activeLang} sx={{ flexDirection: 'row' }} size='small'>
            {lng.map((p, i) => (
              <FormControlLabel value={p.value} key={i} control={<Radio />} label={p.label} onClick={() => handleMenuClick(p)} />
            ))}
          </RadioGroup>
        </Box>}
    </>
  )
}

export default Language
