import styled from 'styled-components'

const media = {
  large_laptop: '@media(max-width: 2560px)',
  laptop: '@media(max-width: 1000px)',
  tablet: '@media(max-width: 768px)',
  mobile: '@media(max-width: 600px)'
}

export const Main = styled.div`
  /* background: #FAFCFE; */
`

export const Divwraper = styled.div`
text-align: center;
   ${media.laptop}{
    }
`
export const DivwraperCenter = styled.div`
text-align: center;
margin-top: 2vh;
`

export const Imagetag = styled.img`
  background-color: #E4E6EA;
    width: 110px;
    height: 110px;
    border-radius: 105px;
    object-fit: scale-down;
    margin: 46px 0px 2vh 0px;

`
export const TextWraper = styled.p`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: center;
    ${media.mobile}{
      padding: 0px 5%;
    }
`
export const TextWraperlight = styled.p`
    font-family: Noto Sans;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: center;
`
export const DivBack = styled.div`
  width: 60%;
  background: #FFFFFF;
  padding-bottom: 15px;
  border: 1px solid rgba(125, 134, 169, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow:  0px 2px 8px rgba(0, 0, 0, 0.350579);
  margin: 19px auto 0px auto;
  ${media.mobile}{
    width: 95%;
    margin-top: 0px;
  }
`
export const TextHeading = styled.div`
text-align: left;
font-size: larger;
margin: 3vh 0px 3vh 6%;
`
export const LiItem = styled.li`
margin-left: 10%;
color: #727272;
font-size: 14px;
margin-bottom: 4px;
${media.mobile}{
   font-size: 12px;
 }
`
