import React from 'react'
import { useResolvedPath, useMatch } from 'react-router-dom'

import { CustomLink } from './style'

const SidebarNavItem = ({ children, to, ...props }) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: false })

  return (
    <CustomLink
      active={match}
      to={to}
      {...props}
    >
      {children}
    </CustomLink>
  )
}

export default SidebarNavItem
