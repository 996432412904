import { styled } from '@mui/system'
import { Box } from '@mui/material'

const media = {
  xl: '@media(max-width: 1920px)',
  lg: '@media(max-width: 1200px)',
  md: '@media(max-width: 1000px)',
  sm: '@media(max-width: 600px)',
  xs: '@media(max-width: 0px)'
}
export const Main = styled('div')`
`
export const BoxWraper = styled(Box)`
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 80vh;
  background-color:white;
  padding:1vh 20px 5vh 20px;
  border-radius:15px;
  /* ${media.mobile}{
    height: 60vh;
    width: 80%;
    left: 48%;
}
  ${media.tablet}{
    height: 70vh;
    width: 80%;
    left: 50%;
}
  ${media.laptop}{
    height: 70vh;
    left: 50%;
} */
`
export const CloseIconWrapper = styled('div')(`
  position: absolute;
  right: 10px;
  top: 10px;
`)

export const IframeWrapper = styled('iframe')(`
  margin-top: 30px;
`)
