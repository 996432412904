import React, { useEffect, useState } from 'react'
import { Grid, Paper } from '@mui/material'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import { useNavigate } from 'react-router-dom'
import { Main, BoxM } from './style'
import DataTable from './DataTable'
import TitleRow from './TitleRow'
import FilterRow from './FilterRow'
import NoPromoComponent from './NoPromo'
import LoadingSpinner from '../LoadingSpinner'
import { ROUTE_CREATE_PROMO } from "../../constants/routes";


const BrandsPromo = (props) => {
  const {
    promoIsloading,
    promoList,
    totalPromo,
    promoTypeList,
    skuList,
    onGetBrandPromoTypeList,
    onGetBrandSkuList,
    onGetBrandPromosList,
    selectedBrand,
    brandIsLoading,
    saveBrandDetails,
    features,
    resetUpdateData
  } = props
  const navigate = useNavigate();

  const initialStates = {
    selectedSKU: [],
    selectedPromoType: [],
    searchText: '',
    sorting: {},
    page: 0,
    dateRange: { startDate: null, endDate: null }
  }

  const [selectedSKU, setSelectedSKU] = useState(initialStates.selectedSKU)
  const [selectedPromoType, setSelectedPromoType] = useState(initialStates.selectedPromoType)
  const [searchText, setSearchText] = useState(initialStates.searchText)
  const [sorting, setSorting] = useState(initialStates.sorting)
  const [page, setPage] = useState(initialStates.page)
  const [dateRange, setDateRange] = useState(initialStates.dateRange)


  const resetStates = () => {
    setSelectedSKU(initialStates.selectedSKU)
    setSelectedPromoType(initialStates.selectedPromoType)
    setDateRange(initialStates.dateRange)
    setSearchText(initialStates.searchText)
    setSorting(initialStates.sorting)
    setPage(initialStates.page)
  }

  useEffect(() => {
    resetUpdateData();
  })

  useEffect(() => {
    if (!selectedBrand) {
      return
    }
    onGetBrandSkuList({
      offset: 0,
      limit: 1000,
      brandId: selectedBrand.id
    })

    onGetBrandPromoTypeList({
      offset: 0,
      limit: 1000,
      brandId: selectedBrand.id
    })
    resetStates()
  }, [onGetBrandSkuList, onGetBrandPromoTypeList, selectedBrand])

  useEffect(() => {
    if (!selectedBrand) {
      return
    }
    onGetBrandPromosList({
      offset: page * 20,
      limit: 20,
      brandId: selectedBrand.id,
      sku: selectedSKU.map((item) => item.sku),
      promoType: selectedPromoType.filter((item) => item.id !== 'archived').map((item) => item.name),
      isArchived: selectedPromoType.filter((item) => item.id === 'archived').length > 0,
      skuOrNameSearch: searchText,
      startDate: dateRange.startDate ? moment(dateRange.startDate).format("YYYY-MM-DD") : null,
      endDate: dateRange.endDate ? moment(dateRange.endDate).format("YYYY-MM-DD") : null,
      sort: sorting.sort,
      sortOrder: sorting.sortOrder
    })
  }, [selectedSKU, selectedPromoType, dateRange, searchText, sorting, page, onGetBrandPromosList])

  const createNewPromoHandler = () =>{
    if(selectedBrand && selectedBrand?.id){
      const { id } = selectedBrand;
      const input = { brandId: id, isEdit: false, promoData: {} };
      saveBrandDetails(input);
      navigate(ROUTE_CREATE_PROMO);
    }
  }

  const editPromoHandler = (promo, e) => {
    e.stopPropagation();
    const input = { promoData: promo, isEdit: true};
    saveBrandDetails(input);
    navigate(ROUTE_CREATE_PROMO);
  }

  const viewPromoDetailsHandler = (promo, showBtn) => {
    const input = { promoData: promo, isEdit: true, viewDetails: true, hideEditButton: !showBtn};
    saveBrandDetails(input);
    navigate(ROUTE_CREATE_PROMO);
  }

  const isFilterApplied = () => {
    if (
      !isEmpty(selectedSKU) ||
      !isEmpty(selectedPromoType) ||
      initialStates.searchText !== searchText ||
      initialStates.page !== page ||
      !isEqual(initialStates.dateRange, dateRange)) {
      return true
    }
    return false
  }

  const noPromo = () => {
    if (!promoIsloading && isEmpty(promoList) && !isFilterApplied()) {
      return true
    }
    return false
  }

  const onTypeClick = (promoType) => {
      const { id } = selectedBrand;
      const input = { brandId: id, isEdit: false, promoData: {} };
      saveBrandDetails(input);
      navigate(ROUTE_CREATE_PROMO + '?selectedPromoType=' + promoType);
  }

  return (
    <>
      {brandIsLoading && <LoadingSpinner />}
      {!brandIsLoading &&
        (noPromo()
          ? <NoPromoComponent promoTypeList={promoTypeList} onTypeClick={onTypeClick}/>
          : <Main>
            <Paper>
              <BoxM>
                <TitleRow totalPromo={totalPromo} createNewPromoHandler={createNewPromoHandler} />
                <FilterRow
                  skuList={skuList}
                  promoTypeList={promoTypeList}
                  selectedSKU={selectedSKU}
                  handleSKUSelect={setSelectedSKU}
                  selectedPromoType={selectedPromoType}
                  handlePromoTypeSelect={setSelectedPromoType}
                  searchtext={searchText}
                  handleSearchTextChange={setSearchText}
                  dateRange={dateRange}
                  handleDateRangeSelect={setDateRange}
                />
                <Grid>
                  <DataTable
                    promoList={promoList}
                    loading={promoIsloading}
                    sortingHandler={setSorting}
                    pageChangehandler={setPage}
                    page={page}
                    totalPromo={totalPromo}
                    editPromoHandler={editPromoHandler}
                    viewPromoDetailsHandler={viewPromoDetailsHandler}
                    features={features}
                  />
                </Grid>
              </BoxM>
            </Paper>
          </Main>)}
    </>
  )
}

BrandsPromo.displayName = 'BrandsPromoComponent'
export default BrandsPromo
