import React from 'react'
import { Container, Grid } from '@mui/material'

import Heading from '../Heading'
import tick from '../../assets/images/tick.svg'
import MuiButton from '../MuiButton'

import { PricingCardWrapper, CardTitle, CardWrapper, CardOffers, ListItemNew, ListText, CardList, CardPricingCard, CardCurrency, CardAmount, CardTenure } from './style'

const PricingCard = props => {
  const { content } = props

  return (
    <>
      <Heading content={content} />
      <PricingCardWrapper container direction='row' justifyContent='space-between'>
        <Container>
          <Grid container direction='row' justifyContent='space-around' flex-wrap='wrap'>
            {
              content?.data.map((card, index) => (
                <CardWrapper key={index} item>
                  <CardTitle label={card.title} bgColor={card.chipColor} />

                  {card?.offer && <CardOffers>{card?.offer}</CardOffers>}

                  <CardPricingCard item justifyContent='start' offer={card?.offer}>
                    {card.currency && <CardCurrency variant='body2' component='body2' offer={card?.offer}> {card.currency} </CardCurrency>}
                    {card.amount && <CardAmount variant='body2' component='body2' offer={card?.offer}> {card.amount} </CardAmount>}
                    {card.tenure && <CardTenure variant='body2' component='body2'> {card.tenure} </CardTenure>}
                  </CardPricingCard>

                  <CardList>
                    {
                      card?.points && card?.points.map((point, index) => (
                        <ListItemNew key={index}>
                          <img src={tick} alt='tick' />
                          <ListText>{point}</ListText>
                        </ListItemNew>
                      ))
                    }
                  </CardList>
                  {
                    card?.links && card?.links.map((link, index) => (
                      <MuiButton key={index} color='primary' variant='contained' to={link.url} fullwidth='true'> {link.title} </MuiButton>
                    ))
                  }
                </CardWrapper>
              ))
            }
          </Grid>
        </Container>
      </PricingCardWrapper>
    </>
  )
}

export default PricingCard
