import { Map } from 'immutable'

import {
  PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_FAILURE,

  PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_FAILURE,

  PAGEBUILDER_RAENAPRODUCTS_ADD_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_ADD_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_ADD_FAILURE,

  PAGEBUILDER_RAENAPRODUCTS_UPDATE_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_UPDATE_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_UPDATE_FAILURE,

  PAGEBUILDER_RAENAPRODUCTS_DELETE_LOADING,
  PAGEBUILDER_RAENAPRODUCTS_DELETE_SUCCESS,
  PAGEBUILDER_RAENAPRODUCTS_DELETE_FAILURE
} from '../actions'

const initialState = new Map({
  pageBuilderRaenaProductsResellerIsLoading: false,
  pageBuilderRaenaProductsResellerList: {},

  pageBuilderRaenaProductsAllIsLoading: false,
  pageBuilderRaenaProductsAllList: {},

  pageBuilderRaenaProductsAddIsLoading: false,
  pageBuilderRaenaProductsAddData: {},

  pageBuilderRaenaProductsUpdateIsLoading: false,
  pageBuilderRaenaProductsUpdateData: {},

  pageBuilderRaenaProductsDeleteIsLoading: false,
  pageBuilderRaenaProductsDeleteData: {}
})

export const pageBuilderRaenaProducts = (state = initialState, action) => {
  const {
    pageBuilderRaenaProductsResellerIsLoading,
    pageBuilderRaenaProductsResellerList,

    pageBuilderRaenaProductsAllIsLoading,
    pageBuilderRaenaProductsAllList,

    pageBuilderRaenaProductsAddIsLoading,
    pageBuilderRaenaProductsAddData,

    pageBuilderRaenaProductsUpdateIsLoading,
    pageBuilderRaenaProductsUpdateData,

    pageBuilderRaenaProductsDeleteIsLoading,
    pageBuilderRaenaProductsDeleteData,

    message,
    type
  } = action

  switch (type) {
    case PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_LOADING:
      return state.set('pageBuilderRaenaProductsResellerIsLoading', pageBuilderRaenaProductsResellerIsLoading)
    case PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_SUCCESS:
      return state.set('pageBuilderRaenaProductsResellerList', pageBuilderRaenaProductsResellerList)
    case PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST_FAILURE:
      return state.set('pageBuilderRaenaProductsResellerFailure', message)

    case PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_LOADING:
      return state.set('pageBuilderRaenaProductsAllIsLoading', pageBuilderRaenaProductsAllIsLoading)
    case PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_SUCCESS:
      return state.set('pageBuilderRaenaProductsAllList', pageBuilderRaenaProductsAllList)
    case PAGEBUILDER_RAENAPRODUCTS_ALL_LIST_FAILURE:
      return state.set('pageBuilderRaenaProductsAllFailure', message)

    case PAGEBUILDER_RAENAPRODUCTS_ADD_LOADING:
      return state.set('pageBuilderRaenaProductsAddIsLoading', pageBuilderRaenaProductsAddIsLoading)
    case PAGEBUILDER_RAENAPRODUCTS_ADD_SUCCESS:
      return state.merge({
        pageBuilderRaenaProductsAddData: pageBuilderRaenaProductsAddData || {}
      })
    case PAGEBUILDER_RAENAPRODUCTS_ADD_FAILURE:
      return state.set('pageBuilderRaenaProductsAddFailure', message)

    case PAGEBUILDER_RAENAPRODUCTS_UPDATE_LOADING:
      return state.set('pageBuilderRaenaProductsUpdateIsLoading', pageBuilderRaenaProductsUpdateIsLoading)
    case PAGEBUILDER_RAENAPRODUCTS_UPDATE_SUCCESS:
      return state.merge({
        pageBuilderRaenaProductsUpdateData: pageBuilderRaenaProductsUpdateData || {}
      })
    case PAGEBUILDER_RAENAPRODUCTS_UPDATE_FAILURE:
      return state.set('pageBuilderRaenaProductsUpdateFailure', message)

    case PAGEBUILDER_RAENAPRODUCTS_DELETE_LOADING:
      return state.set('pageBuilderRaenaProductsDeleteIsLoading', pageBuilderRaenaProductsDeleteIsLoading)
    case PAGEBUILDER_RAENAPRODUCTS_DELETE_SUCCESS:
      return state.merge({
        pageBuilderRaenaProductsDeleteData: pageBuilderRaenaProductsDeleteData || {}
      })
    case PAGEBUILDER_RAENAPRODUCTS_DELETE_FAILURE:
      return state.set('pageBuilderLinksDeleteError', message)

    default:
      return state
  }
}
