import styled, { css } from 'styled-components'

export const OrderDetailsModalWrapper = styled.div`
    padding: 30px 0px 30px 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #FFF;
`

export const OrderModalHeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`

export const HeadingTitle = styled.div`
    
`

export const OrderModalBodyWrapper = styled.div`
    background-color: #FFF;
    display: flex;
    margin: 5px;
    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
        margin-bottom: 15px !important;
        margin: 0px;
    }    

`

export const OrderHeadingWrapper = styled.div`
    flex-grow: 1;
    align-items: center;
    display: flex;
`

export const BackIcon = styled.span`
    padding: 5px 7px 1px 7px;
    background: #FFF;
    position: absolute;
    top:5px;left:5px;
    border: 1px solid #E4E4E5;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
    border-radius: 5px;
    margin-right: 20px;
    font-weight: 800;
    cursor: pointer;
`

const RobotoNormalClass = css`
    font-family: Noto Sans;
    font-style: normal;
`

const RobotoNormalWeight400Class = css`
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 400;
`

export const HeadingDetailText = styled.div`
    ${RobotoNormalWeight400Class}{
        font-size: 24px;
        color: #262928;
        margin-left: 21px;
        
        @media screen and (max-width: 1024px) {
            margin-left: 10px;
        }
    }
`

export const BodyWrapper = styled.div`
    float: right;
    margin-right: 50px;
    text-align: center;
    align-content: center;
`

export const StatusHeadingWrapper = styled.div``

export const StatusTextWrapper = styled.div``

export const StatusHeading = styled.span`
    font-weight: 400;
    font-size: 16px;
    color: #888;
`

export const StatusText = styled.span`
    ${RobotoNormalClass}{
        font-weight: 700;
        font-size:  18px;
        color: #F7B500;
    }
`

export const OrderInfoWrapper = styled.span`
    margin-bottom: 15px;
    display: flex;

    @media screen and (max-width: 1024px) {
        display: block;
    }
`

export const OrderPersonName = styled.div``
export const OrdererName = styled.div``

export const OrderNameHeading = styled.span`
    ${RobotoNormalWeight400Class}{
        font-size: 16px;
        text-align: center;
        color: #222;
    }
`

export const OrdererNameText = styled.div``

export const StoreName = styled.span`
    ${OrderNameHeading}
`

export const OrderDetailInfo = styled.div`
    column-gap: 20px;
    display: flex;
    flex-grow: 1;
    margin-left: 60px;

    @media screen and (max-width: 1024px) {
        margin-left: 0px;
        margin-top: 20px;
    }
`

export const ProductName = styled.div`
    ${RobotoNormalClass}{
        font-weight: 500;
        font-size: 13px;
        text-align: center;
        color: #222;
    }
`

export const BorderLineGray = styled.div`
    border-left: 2px solid #eee;
    height: inherit;    
`

export const OrderPaymentTotal = css`
    ${RobotoNormalClass}{
        font-weight: 500;
        font-size: 13px;
        text-align: center;
    }
`

export const OrderPaymentTotalHeader = styled.div`
    ${OrderPaymentTotal}{
        color: #888;
    }
`

export const OrderPaymentTotalText = styled.div`
    ${OrderPaymentTotal}{
        color: #222;
    }
`

export const PaymentMethod = styled.div``
export const OrderTotal = styled.div``
export const StoreInfo = styled.div``

export const OrderAddress = styled.div`
    ${RobotoNormalWeight400Class}{
        margin-top: 0;
        margin-bottom:15px;
        font-size: 14px;
        color: #888;    
    }
`

export const BoldAddressText = styled.span`
    font-weight:bold;
    color: #000;
`

export const OtherInfo = styled.div`
    margin-top: 20px;
    margin-bottom:80px;

    @media screen and (max-width: 1024px) {
        margin-bottom:20px;
    }

`

export const ShipperParent = css`
    color: #1D2334;
    font-size: 14px;
    // font-family: 'Apercu';
    font-weight: 500;
`

export const ShipperInfo = styled.span`
    ${ShipperParent}{
        float: left;

        @media screen and (max-width: 1024px) {
            float: none;
            display:block;
        }
    }
`

export const ShipperName = styled.span`
    margin-left: 10px;
`

export const ShippingLabel = styled.div`
    ${ShipperParent}{       
        float: right;

        @media screen and (max-width: 1024px) {
            margin-top: 10px;
            float: none;
        }
    }
`

export const ImageInfo = styled.span`
    background: #F1F1F1;
    border-radius: 10px;
    padding: 7px 12px;
    color: #777;
    font-size: 14px;
    cursor: pointer;
    margin-left: 30px;

    @media screen and (max-width: 1024px) {
        padding: 5px 8px;
        margin-left: 10px;
    }
`

export const OrderItemCard = styled.div`
    display:flex;
    margin-bottom: 30px;
`

export const OrderItemImage = styled.img`
    width: 120px;
    height: 100px;
    margin-right: 15px;

    @media screen and (max-width: 1024px) {
        margin-right: 8px;
    }    
`

export const OrderItemDescription = styled.div`
    ${ProductName}{
        font-size: 5px;
    }
`

export const OrderItemCardInfo = styled.div`
    flex-grow:1;
`

export const SellerSKU = styled.div`
    ${RobotoNormalWeight400Class}{
        font-size: 13px;
        color: #888;
        margin-top: 5px;
    }
`

export const RestCardInfo = styled.div`
    display: flex;
    column-gap: 35px;
    margin-top: 22px;

    @media screen and (max-width: 1024px) {
        column-gap: 20px;
        margin-top: 10px;    
    }

`

export const CardItemsContent = styled.div`
    ${RobotoNormalClass}{
        font-size : 14px;
        color : #888;
        margin-top : 5px;
    }
`

export const ItemsTotal = styled.div`
    ${SellerSKU}
`

export const ItemTotalPrice = styled.div`
    margin-top: 5px;
`

export const PriceHeader = styled.span`
    ${RobotoNormalClass}{
        font-size: 14px;
        color: #3A8D01;
        margin-top: 5px;
        font-weight: 500;        
    }
`

export const PricePlusQuantity = styled.span`
    ${RobotoNormalClass}{
        font-size: 12px;
        color: #888;
        margin-top: 5px;
        font-weight: 500;        
    }
`

export const ArrangePickupButton = styled.div`
    margin-top: 45px;
    text-align: center;
`
