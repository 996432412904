
/*  eslint-disable */
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'

import LabalSelector from '../LabelSelector'
import PageBuilderLinks from '../PageBuilderLinks'
import PageBuilderAbout from '../PageBuilderAbout'
import PageBuilderAppearance from '../PageBuilderAppearance'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import { LINKSTORE_BASE_URL } from '../../config'

import { Box } from '@mui/material'
import {
  PageBuilderOuterWrapper,
  PageBuilderWrapper,
  HeadingWrapper,
  Heading,
  CopyLink
} from './style'
import { store } from '../../store'
import { PAGEBUILDER_ABOUT_FETCH_POST_DATA, EARNINGS_VIEW_FETCH } from '../../actions'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import LoadingSpinner from '../LoadingSpinner'
import PageBuilderMobilePreview from '../PageBuilderMobilePreview/PageBuilderMobilePreview'
import { Grid, Typography } from '@mui/material'
import { FormHeading } from '../PageBuilderLinks/style'
import MuiButton from '../MuiButton'
import { useLocation } from 'react-router-dom'
import PageBuilderEarnings from '../PageBuilderEarnings'

const PageBuilder = (props) => {
  const { token: idToken } = queryString.parse(useLocation()?.search)
  const user = useSelector(state => state.users.toJS())
  const auth = useSelector(state => state.auth.toJS())
  const { pageBuilderAboutFetchData, pageBuilderAboutFetchIsLoading } = useSelector(state => state.pageBuilder.toJS())
  const { t } = useTranslation()
  const queryParams = queryString.parse(window.location.search)

  const {
    onGetUserInfo
  } = props

  const labelSelectorOptions = [
    {
      label: t('pageBuilderTab.links'),
      value: 'links'
    },
    {
      label: t('pageBuilderTab.about'),
      value: 'about'
    },
    {
      label: t('pageBuilderTab.appearance'),
      value: 'appearance'
    },
    {
      label: t('pageBuilderTab.earnings'),
      value: 'earnings'
    }
  ]
  const [queryParamsTab, setQueryParamsTab] = useState(true)
  const [labelSelectedValue, setLabelSelectedValue] = useState()

  useEffect (() => {
    switch(labelSelectedValue){
      case 'links':
        EventEmitter.emit(Events.DSF_LINKS); break
      case 'about':
        EventEmitter.emit(Events.DSF_ABOUTUS); break
      case 'appearance':
        EventEmitter.emit(Events.DSF_APPEARANCE); break
    }
  }, [labelSelectedValue])

  useEffect(() => {
    onGetUserInfo(idToken)
  }, [onGetUserInfo])

  useEffect(() => {
    if (queryParamsTab && queryParams?.tab) {
      setLabelSelectedValue(queryParams?.tab || labelSelectorOptions?.[0].value)
    }
  }, [queryParams])

  useEffect(() => {
      setLabelSelectedValue(labelSelectorOptions?.[0].value)
  }, [])

  const copyTextValue = `${LINKSTORE_BASE_URL}/${auth?.shareId}`
  const copyText = () => {
    const copyTxt = copyTextValue
    EventEmitter.emit(Events.DSFADMIN_STORELINK_COPY)
    EventEmitter.emit(Events.DSF_ABOUTUS_COPY)
    navigator.clipboard.writeText(copyTxt)
    toast.success('Link copied successfully!')
  }
  const openLinkStore = () => {
    EventEmitter.emit(Events.DSFADMIN_STORELINK_CLICK, {
      seller_id: user?.id || '',
      url: copyTextValue || ''
    })

    window.open(copyTextValue, '_blank')
  }
  useEffect(() => {
    if (!user?.id) return
    store.dispatch({ type: PAGEBUILDER_ABOUT_FETCH_POST_DATA, payload: { resellerId: user?.id } })
    store.dispatch({ type: EARNINGS_VIEW_FETCH })
  }, [user?.id])

  const labelSelectedValueFunc = (value) => {
    setQueryParamsTab(false)
    setLabelSelectedValue(value)
  }

  return (
    <PageBuilderOuterWrapper>

      <PageBuilderWrapper style={{ paddingBottom: 0 }}>
        <HeadingWrapper  mobileDisplay='hide'>
          <Heading variant='h5'>{t('pageBuilderTab.StoreSettings')}</Heading>
          <Grid spacing={2} style={{ justifyContent: 'space-between', display: 'flex' }}>
            <Grid item>
              <FormHeading>My Store URL:</FormHeading>
              <Typography onClick={openLinkStore} style={{ cursor: 'pointer', marginTop: -10, color: '#0000EE', textDecoration: 'underline' }}>{copyTextValue}</Typography>
            </Grid>
            <Grid style={{ marginLeft: 20 }} item>
              <MuiButton onClick={copyText} color='primary' variant='outlined' type='button'>Copy</MuiButton>
            </Grid>
          </Grid>
          {/* {labelSelectedValue === 'about' && <CopyLink onClick={copyText}>{copyTextValue} <ContentCopyIcon fontSize='small' /></CopyLink>} */}
        </HeadingWrapper>
        <LabalSelector
          options={labelSelectorOptions}
          onSelect={(value) => { labelSelectedValueFunc(value) }}
          selected={labelSelectedValue}
          setSelected={setLabelSelectedValue}
        />
      </PageBuilderWrapper>
      {/* <PageBuilderWrapper style={{ width: 'calc(60% - 30px)' }}> */}
      {/* <PageBuilderWrapper style={{ width: 'calc(100% - 30px)' }}> */}
      {user?.id ?
        <Box style={{ width: 'calc(100%)' }}>
          {labelSelectedValue === 'about' && (pageBuilderAboutFetchIsLoading ? <LoadingSpinner /> : <PageBuilderAbout pageBuilderAboutFetchData={pageBuilderAboutFetchData} />)}
          {labelSelectedValue === 'links' && <PageBuilderLinks {...props} resellerId={user?.id} />}
          {labelSelectedValue === 'appearance' && <PageBuilderAppearance />}
          {labelSelectedValue === 'earnings' && <PageBuilderEarnings />}
        </Box>
        :
        <Box style={{ width: 'calc(100%)' }}>
          <LoadingSpinner />
        </Box>
      }
      {/* <PageBuilderWrapper style={{ width: 'calc(40% - 30px)' }}>
        <PageBuilderMobilePreview />
      </PageBuilderWrapper> */}

    </PageBuilderOuterWrapper>
  )
}

PageBuilder.defaultProps = {
  onGetUserInfo: () => {},
  onGetPageBuilderLinksCreateData: () => {},
  onGetPageBuilderLinksUpdateData: () => {},
  onGetPageBuilderLinksDeleteData: () => {},
  onGetPageBuilderSectionsReorderData: () => {},
  onGetPageBuilderLinksReorderData: () => {},
  onGetPageBuilderLinksFetchData: () => {}
}

PageBuilder.propTypes = {
  onGetUserInfo: PropTypes.func,
  onGetPageBuilderLinksCreateData: PropTypes.func,
  onGetPageBuilderLinksUpdateData: PropTypes.func,
  onGetPageBuilderLinksDeleteData: PropTypes.func,
  onGetPageBuilderSectionsReorderData: PropTypes.func,
  onGetPageBuilderLinksReorderData: PropTypes.func,
  onGetPageBuilderLinksFetchData: PropTypes.func,
}

PageBuilder.displayName = 'PageBuilder'
export default PageBuilder
