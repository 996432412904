import { connect } from 'react-redux'

import {
  AUTH_GET_OTP,
  AUTH_VERIFY_OTP,
  AUTH_SET_PERSONAL_PROFILE
} from '../../actions'
import SignUpComponent from '../../components/SignUp'

const mapStateToProps = ({ auth }) => {
  return {
    error: auth.get('error'),
    email: auth.get('email'),
    isExistingUser: auth.get('isExistingUser'),
    isLoading: auth.get('isLoading'),
    isOTPSent: auth.get('isOTPSent'),
    isOTPVerified: auth.get('isOTPVerified'),
    isProfileSet: auth.get('isProfileSet'),
    mobile: auth.get('mobile'),
    name: auth.get('name'),
    token: auth.get('token'),
    userId: auth.get('userId')
  }
}

const mapDispatchToProps = (dispatch) => ({
  getOTP: data => {
    dispatch({ type: AUTH_GET_OTP, data })
  },
  verifyOTP: data => {
    dispatch({ type: AUTH_VERIFY_OTP, data })
  },
  setPersonalProfile: data => {
    dispatch({ type: AUTH_SET_PERSONAL_PROFILE, data })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpComponent)
