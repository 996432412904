import { Map } from 'immutable'

import {
  BRAND_MSP_LOADING,
  BRAND_MSP_GET_LIST_FAILURE,
  BRAND_MSP_GET_LIST_SUCCESS,
  BRAND_MSP_EXPORT_LOADING,
  BRAND_MSP_EXPORT_SUCCESS,
  BRAND_MSP_EXPORT_FAILURE,
  BRAND_MSP_CONFIG_SUCCESS,
  BRAND_MSP_CONFIG_FAILURE,
  BRAND_MSP_CONFIG_LOADING,
  BRAND_MSP_CONFIG_RESET
} from '../actions'

const initialState = new Map({
  mspIsloading: false,
  mspList: [],
  totalMsp: 0,
  mspExportIsloading: false,
  configIsLoading: false,
  configUpdateSuccess: null
})

export const brandsMsp = (state = initialState, action) => {
  const {
    data,
    type,
    isLoading
  } = action
  switch (type) {
    case BRAND_MSP_LOADING:
      return state.set('mspIsloading', isLoading)
    case BRAND_MSP_GET_LIST_SUCCESS:
      return state.merge({
        mspList: data?.data || [],
        totalMsp: data?.total || 0
      })
    case BRAND_MSP_GET_LIST_FAILURE:
      return state.merge({
        mspList: []
      })

      case BRAND_MSP_EXPORT_LOADING:
        return state.set('mspExportIsloading', isLoading)

    case BRAND_MSP_EXPORT_SUCCESS:
        if(action?.data?.url){
            window.location = action?.data?.url
        }
      return state;
    case BRAND_MSP_EXPORT_FAILURE:
      return state.merge({
        exportError: ""
      })

      case BRAND_MSP_CONFIG_LOADING:
        return state.set('configIsLoading', isLoading)

      case BRAND_MSP_CONFIG_SUCCESS:
        return state.set('configUpdateSuccess', true)

      case BRAND_MSP_CONFIG_FAILURE: 
      return state.set('configUpdateSuccess', false)

      case BRAND_MSP_CONFIG_RESET: 
      return state.set('configUpdateSuccess', null)


    default:
      return state
  }
}
