import React from 'react'
import { Spinner } from './style.js'

const LoadingSpinner = () => (
  <Spinner data-testid='loadingSpinner'>
    <div className='bounce1' />
    <div className='bounce2' />
    <div />
  </Spinner>
)

LoadingSpinner.displayName = 'LoadingSpinner'
export default LoadingSpinner
