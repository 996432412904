import React from 'react'
import { connect } from 'react-redux'
import Layout from '../Layout'
import PageBuilderRaenaProductsAddComponent from '../../components/PageBuilderRaenaProductsAdd'
import {
  PAGEBUILDER_RAENAPRODUCTS_ALL_LIST,
  PAGEBUILDER_RAENAPRODUCTS_UPDATE,
  USERS_GET_INFO
} from '../../actions'

const PageBuilderRaenaProductsAdd = props => {
  return (
    <Layout>
      <PageBuilderRaenaProductsAddComponent {...props} />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { pageBuilderRaenaProducts } = state
  return {
    pageBuilderRaenaProductsAllList: pageBuilderRaenaProducts.get('pageBuilderRaenaProductsAllList'),
    pageBuilderRaenaProductsAllIsLoading: pageBuilderRaenaProducts.get('pageBuilderRaenaProductsAllIsLoading'),

    pageBuilderRaenaProductsUpdateData: pageBuilderRaenaProducts.get('pageBuilderRaenaProductsUpdateData'),
    pageBuilderRaenaProductsUpdateIsLoading: pageBuilderRaenaProducts.get('pageBuilderRaenaProductsUpdateIsLoading')
  }
}

const mapDispatchToProps = dispatch => ({
  onGetPageBuilderRaenaProductAllList: (query) => {
    dispatch({ type: PAGEBUILDER_RAENAPRODUCTS_ALL_LIST, payload: query })
  },
  onGetPageBuilderRaenaProductsUpdateData: (query) => {
    dispatch({ type: PAGEBUILDER_RAENAPRODUCTS_UPDATE, payload: query })
  },
  onGetUserInfo: () => {
    dispatch({ type: USERS_GET_INFO })
  }
})

PageBuilderRaenaProductsAdd.displayName = 'ProductListContainer'
export default connect(mapStateToProps, mapDispatchToProps)(PageBuilderRaenaProductsAdd)
