import React from 'react'
import Typography from '@mui/material/Typography'
// import Grid from '@mui/material/Grid'
import MuiButton from '../MuiButton'

import {
  BannerOuterWrapper,
  BannerInnerWrapper,
  GridWraper
} from './style'

import EventEmitter, { Events } from '../../utilities/eventEmitter'

const BannerSingle = ({ content }) => {
  const registerNowBtnClick = () => {
    EventEmitter.emit(Events.REGISTER_NOW)
  }
  return (
    <BannerOuterWrapper bgImage={content?.background || ''}>
      <BannerInnerWrapper>
        {content?.title && <Typography variant='h4' component='h2'><b>{content.title}</b></Typography>}
        {content?.description && <Typography variant='h6' component='h6'>{content.description}</Typography>}
        {content?.links &&
          <GridWraper container>
            {content?.links.map((link, index) => (
              <MuiButton sx={{ minWidth: '160px' }} color='primary' size='large' variant='contained' key={index} to={link.url} onClick={registerNowBtnClick}>{link?.title}</MuiButton>
            ))}
          </GridWraper>}
      </BannerInnerWrapper>
    </BannerOuterWrapper>
  )
}

export default BannerSingle
