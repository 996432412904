import { useTranslation } from 'react-i18next'
import Wallet from '../../assets/images/earningWallet.svg'
import GreenEarning from '../../assets/images/greenEarning.svg'
import WithdrawnEarning from '../../assets/images/withdrawnEarning.svg'
import {
  PageBuilderWrapper,
  SectionContent,
  FormOuterControl,
  EarningsContainer,
  EarningsBox,
  CurrentBalanceContainer,
  BalanceHeading,
  PriceHeading,
  TotalEarningsContainer,
  TotalEarningsHeading,
  WithdrawEarninsPrice,
  WithdrawEarninsBox,
  AboutHeading
} from './style'
import './styles.css'
import { useState, useEffect } from 'react'
import { EarningsView } from './EarningsView'
import { useSelector } from 'react-redux'
import { store } from '../../store'
import { EARNINGS_VIEW_FETCH } from '../../actions'
import moment from 'moment'
import { FormControl, MenuItem, Select } from '@mui/material'
import MuiButton from '../MuiButton'
import { WithdrawlRules } from './WithdrawlRules'

const PageBuilderEarnings = () => {
  const [withdrawalView, setWithdrawalView] = useState(false)
  const { earningsViewData = {} } = useSelector((state) => state.pageBuilder.toJS())
  const {
    currentBalance = 0,
    earningInProcess: inProcessBalance = 0,
    totalEarning: totalEarnings = 0,
    amountWithdrawn: withdrawnEarning = 0,
    ledger = []
  } = earningsViewData
  const { t } = useTranslation()
  const [dateRange, setDateRange] = useState('')
  const goBack = () => setWithdrawalView(false)

  const selectableValues = [
    {
      name: t('brandPromo.dateRange') === 'Date Range' ? 'Date Range' : 'Rentang Tanggal',
      id: t('brandPromo.dateRange') === 'Date Range' ? 'Date Range' : 'Rentang Tanggal',
    },
    { name: 'Year', id: 'year' },
    { name: 'Month', id: 'month' },
    { name: 'Week', id: 'week' },
    { name: 'Day', id: 'day' }
  ]

  const onChangeDate = (val) => {
    setDateRange(val)
  }

  useEffect(() => {
    setDateRange(t('brandPromo.dateRange') === 'Date Range' ? 'Date Range' : 'Rentang Tanggal')
  }, [t('brandPromo.dateRange')])

  useEffect(() => {
    store.dispatch({
      type: EARNINGS_VIEW_FETCH,
      ...(dateRange !== '' && {
        startDate:
          dateRange === 'year'
            ? moment().subtract(365, 'days').format('YYYY-MM-DD')
            : dateRange === 'month'
              ? moment().subtract(31, 'days').format('YYYY-MM-DD')
              : dateRange === 'week'
                ? moment().subtract(7, 'days').format('YYYY-MM-DD')
                : dateRange === 'day'
                  ? moment().subtract(1, 'day').format('YYYY-MM-DD')
                  : '',
        endDate: moment().format('YYYY-MM-DD')
      })
    })
  }, [dateRange])

  return (
    <PageBuilderWrapper>
      <SectionContent>
        <FormOuterControl>
          <EarningsContainer>
            {withdrawalView ?
              (
                <EarningsBox>
                  <WithdrawlRules goBack={goBack} />
                </EarningsBox>
                ) : (
                  <EarningsBox>
                    <CurrentBalanceContainer className='linear-back'>
                      <div className='currentBalanceBox'>
                        <div>
                          <BalanceHeading>{t('pageBuilderAbout.currentBalance')}</BalanceHeading>
                          <PriceHeading>Rp{currentBalance}</PriceHeading>
                          <MuiButton
                            size='small'
                            styled='pageBuilderAboutWhite'
                            variant='contained'
                            type='submit'
                            minwidth='true'
                            onClick={() => setWithdrawalView(true)}
                          >
                            {t('pageBuilderAbout.Withdraw')}
                          </MuiButton>
                        </div>

                        <div className='walletBox'>
                          <img src={Wallet} alt='wallet' />
                          <BalanceHeading>
                            +{t('pageBuilderAbout.inProcess')}: {inProcessBalance}
                          </BalanceHeading>
                        </div>
                      </div>
                    </CurrentBalanceContainer>
                    <TotalEarningsContainer>
                      <div className='currentBalanceBox'>
                        <div className='currentBalanceBox'>
                          <div>
                            <TotalEarningsHeading>{t('pageBuilderAbout.totalEarnings')}</TotalEarningsHeading>
                            <div className='flex'>
                              <img src={GreenEarning} alt='earning' />
                              <WithdrawEarninsPrice>Rp{totalEarnings}</WithdrawEarninsPrice>
                            </div>
                          </div>
                          <WithdrawEarninsBox>
                            <TotalEarningsHeading>{t('pageBuilderAbout.Withdrawn')}</TotalEarningsHeading>
                            <div className='flex'>
                              <img src={WithdrawnEarning} alt='earning' />
                              <WithdrawEarninsPrice>Rp{withdrawnEarning}</WithdrawEarninsPrice>
                            </div>
                          </WithdrawEarninsBox>
                        </div>
                        <div className='dateRangeBox'>
                          <FormControl fullWidth>
                            <Select
                              labelId='select-label'
                              value={dateRange}
                              label=''
                              onChange={(e) => onChangeDate(e.target?.value)}
                              placeholder={t('brandPromo.dateRange')}
                              size='small'
                            >
                              {selectableValues?.map((item) => (
                                <MenuItem key={item?.name} value={item?.id}>
                                  {item?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className='mt-40px'>
                        <AboutHeading>{t('pageBuilderAbout.transactionHistory')}</AboutHeading>
                      </div>
                    </TotalEarningsContainer>
                    <div className='w-full'>
                      {ledger?.map((item, i) => (
                        <EarningsView
                          key={i}
                          orderId={item?.title}
                          date={item?.createdAt}
                          earning={item?.amount}
                          type={item?.type}
                        />
                      ))}
                    </div>
                  </EarningsBox>
                )}
          </EarningsContainer>
        </FormOuterControl>
      </SectionContent>
    </PageBuilderWrapper>
  )
}

export default PageBuilderEarnings
