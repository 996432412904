import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';

export const TableWrapper = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: scroll;
`

export const TableHeader = styled.thead`
  display: flex;
  width: 100%;
  min-width: fit-content;
`

export const TableHeaderRow = styled.tr`
  display: flex;
  width: 100%;
  background: #F5F6F9;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
  padding: 1rem 0;
  margin-bottom: 3px;
`

export const TableHeaderData = styled.td`
  flex: 1;
  padding-left: 1rem;
  min-width: 100px;
`

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: fit-content;
`

export const TableBodyRow = styled.tr`
  display: flex;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #222222;
  padding: 1rem 0;
  margin-bottom: 3px;
`

export const TableBodyData = styled.td`
  flex: 1;
  word-break: break-all;
  cursor: pointer;
  min-width: 100px;
  padding-left: 1rem;
  
  @media (max-width: 800px) {
    padding-left: 0.95rem;
  }
`
const changeColor = (value) => {
  if (value === 'Cancelled') return 'red'
  if (value === 'Processed') return 'blue'
  if (value === 'Ready_to_ship') return 'green'
  if (value === 'Completed') return 'brown'
  if (value === 'Pending') return 'yellow'
  return 'gray'
}

export const StatusWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 20fr;
`

export const StatusValue = styled.span`
  word-break: break-all;
`

export const StatusDot = styled.div`
  height: 8px;
  width: 8px;
  margin-right: 5px;
  margin:5px 5px 0 0;
  background-color: ${props => changeColor(props.value)};
`

export const OrderIDDate = styled.div`
  color: #A2A5BB;
  font-size: 10px;
`
export const CheckboxComponent = styled(Checkbox)`
 padding: 0;
 background-color: red;
 z-index: 100;
`