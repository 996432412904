import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const MenuFieldWrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 335px;
  position: relative;
  z-index: 2;
  @media (max-width: 600px) {
    min-width: calc(90vw - 80px);
  }
`)

export const FormError = styled(Box)(`
  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
`)

export const FormLabel = styled('label')(`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
`)

export const FormReq = styled('span')(`
  font-size: 12px;
  color: #DA2A52;
  padding-left:5px;
`)

export const FormInput = styled('input')(`
  font-size: 14px;
  position: absolute;
  top:8px;
  opacity:0;
  width:2px;
  left:0;
`)

export const MenuFieldIconArrow = styled('img')(props => `
  position: absolute;
  right: 20px;
  top: 12px;
  opacity: ${props.disabled ? 0.2 : 0.7};
`)

export const MenuFieldIconArrow2 = styled('img')(`
  position: absolute;
  right: 20px;
  top: calc(50% - 8px);
  opacity: 0.5;
`)

export const MenuFieldIconClose = styled('img')(`
  cursor: pointer;
  position: absolute;
  opacity: .5;
  z-index: 9999;
  right: 20px;
  top: 13px;
`)

export const MenuFieldListWrapper = styled(Box)(`
  position: absolute;
  top: 90%;
  display: flex;
  flex-direction: row;
  width: calc(100vw - 330px);
  overflow: auto;
  max-width:880px;
  height:310px;
  padding:0 5px;
  @media (max-width: 1000px) {
    width: calc(100vw - 90px);
  }
  @media (max-width: 600px) {
    width: calc(100vw - 70px);
  }
`)

export const MenuPopWrapper = styled('div')(props => `
  position:relative;
  background: #fff;
  overflow: auto;
  border: 1px #E5E5E5 solid;
  height: calc(50px * 5.7);
  width: 290px;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
  box-sizing: border-box;
  margin-top: 2px;

  ${props?.level === '1' && `
    margin-left: 0;
  `};
  ${props?.level === '2' && `
    margin-left: -1px;
    margin-top: 8px;
  `};
  ${props?.level === '3' && `
    margin-left: -2px;
    margin-top: 16px;
  `};
  ${props?.level === '4' && `
    margin-left: -3px;
    margin-top: 24px;
  `};
  ${props?.level === '5' && `
    margin-left: -4px;
    margin-top: 32px;
  `};

  @media (max-width: 1200px){
    min-width: 220px;
  }
  @media (max-width: 600px){
    min-width: 190px;
  }
`)
export const MenuItems = styled('a')(props => `
  padding: 18px 40px 18px 30px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 1;
  font-size: 14px;
  border-bottom: 1px solid #ECECEC;
  position:relative;
  font-weight: 500;
  color: #262928;
  :hover {
    background: #F1F3F9;
  }
  ${props.selected === true && `
    background: #F1F3F9;
  `};

  @media (max-width: 1200px){
    padding: 15px 30px 15px 20px;
  }
  @media (max-width: 600px){
    padding: 12px 30px 12px 12px;
  }
`)

export const MenuFieldButton = styled('a')((props) => `
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  min-width: fit-content;
  margin-top:10px;
  position:relative;
  padding: 0 2.5rem 0 1rem;
  background-color: ${props?.theme?.components?.MuiInput?.styleOverrides?.input?.backgroundColor || '#f9f9f9'};
  border: 1px solid ${props?.theme?.components?.MuiOutlinedInput?.styleOverrides?.notchedOutline?.borderColor || '#DADADA'};
  border-radius: 10px;
  color: ${props.placeholderactive === '' ? '#9B9B9B' : '#333'};
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  ${props.error === 'yes' && `
    border-color: red;
  `};
  ${props.disabled && `
    cursor: default;
    color: #9B9B9B !important;
  `};
`)
