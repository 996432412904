import { styled } from '@mui/system'

export const InputWrapper = styled('div')(`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`)

export const Input = styled('input')(({ theme }) => `
  width: 100%;
  min-height: 40px;
  min-width: fit-content;
  padding: 8.5px 14px;
  background-color: ${theme?.components?.MuiInput?.styleOverrides?.input?.backgroundColor || '#f9f9f9'};
  border: 1px solid ${theme?.components?.MuiOutlinedInput?.styleOverrides?.notchedOutline?.borderColor || '#DADADA'};
  border-radius: 10px;
  box-sizing: border-box;
  :focus-visible{
    border: 1px black solid;
  }
  ::placeholder {
    opacity: 50%;
  }
`)

export const FormError = styled('div')(`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: red;
`)

export const FormLabel = styled('label')(`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
`)

export const FormField = styled('div')(`
  margin-top: 10px;
  width: 100%;
  & > div {
    min-width: 100%;
  }
`)
