import React, { useEffect, useState } from 'react'
import CancelSharpIcon from '@mui/icons-material/CancelSharp'
import IconButton from '@mui/material/IconButton'
import LoadingSpinner from '../LoadingSpinner'

import { FileUploadWrapper, FileUploadBoxLoader, FileUploadBox, ImageList, FormLabel, FormReq, FormDescription, FormError } from './style'

const MuiFileUpload = ({
  onPostUploadImages,
  uploadImages,
  clearUploadedImage = () => {},
  title,
  description = '',
  onImageChange,
  marketplaceShopId,
  error,
  value,
  autoClear = true,
  isRequired,
  hideBrowse = {},
  maxFileSize = 2000000, // 2MB
  uploadImagesIsLoading,
  mode = '',
  name = '',
  format = 'image/*' // format = 'image/x-png,image/gif,image/jpeg,image/jpg,image/webp'
}) => {
  const [imageLists, setImageLists] = useState([])
  const [imageError, setImageError] = useState()

  useEffect(() => {
    if (value.length === 0 && imageLists.length !== 0) {
      setImageLists([])
    } else {
      setImageLists(value)
    }
  }, [value])

  const getImageSizeDetails = (imageSize) => {
    let size = imageSize
    let type = 'byte'
    if (size > 1000) {
      size = size / 1000; type = Math.round(size) + ' KB'
    }
    if (size > 1000) {
      size = size / 1000; type = Math.round(size) + ' MB'
    }
    return { originalSize: imageSize, size: Math.round(size), type: type }
  }

  const handleFileChange = async (e) => {
    const imgSize = getImageSizeDetails(e?.target?.files?.[0]?.size)
    const maxSize = getImageSizeDetails(maxFileSize)?.type
    if (imgSize?.originalSize > maxFileSize) {
      setImageError(`Image cannot be greater than ${maxSize} (Current image size is ${imgSize?.type})`)
      setTimeout(() => {
        setImageError('')
      }, 6000)
    } else {
      const fd = {
        urlType: 'upload',
        ContentType: e.target.files[0]?.type,
        docType: 'SAAS_PRODUCT_IMAGES',
        file: e.target.files[0]
      }
      await onPostUploadImages({ [mode]: fd, type: mode })
    }
  }

  const handleRemove = (i) => {
    const imagesArray = imageLists
    imagesArray.splice(i, 1)
    setImageLists([...imagesArray])
    onImageChange([...imagesArray])
  }

  useEffect(() => {
    if (uploadImages?.url) {
      setImageLists([...imageLists, uploadImages])
      onImageChange([...imageLists, uploadImages])
      if (autoClear) clearUploadedImage()
    }
  }, [uploadImages])

  const Req = () => {
    return (
      <>
        {isRequired && <FormReq>*</FormReq>}
      </>
    )
  }

  return (
    <>
      {(mode === '' || name === '') && <b>Kindly Provide <i>unique name && mode</i> in the component</b>}
      {!(mode === '' || name === '') &&
        <FileUploadWrapper>
          {title && <FormLabel>{title}<Req /></FormLabel>}

          {uploadImagesIsLoading.value && uploadImagesIsLoading.mode === mode && <FileUploadBoxLoader><LoadingSpinner className='loader' /></FileUploadBoxLoader>}
          {!uploadImagesIsLoading.value &&
            <>
              {!(hideBrowse && hideBrowse?.maxLength === imageLists?.length) &&
                <FileUploadBox htmlFor={`contained-button-file-${name}`}>
                  <input type='file' id={`contained-button-file-${name}`} name={`contained-button-file-${name}`} accept={format} onChange={handleFileChange} />
                </FileUploadBox>}
            </>}

          {imageLists?.length > 0 && imageLists?.map((imageList, i) => {
            const imageUrl = imageList?.key?.startsWith('http') ? imageList.key : imageList?.url
            return (
              <ImageList key={i}>
                <img src={imageUrl} alt='' />
                <IconButton onClick={() => handleRemove(i)} size='small'>
                  <CancelSharpIcon />
                </IconButton>
              </ImageList>
            )
          })}
          {error && <FormError>{error}</FormError>}
          {imageError && <FormError>{imageError}</FormError>}
          {description && <FormDescription>{description}</FormDescription>}
        </FileUploadWrapper>}
    </>
  )
}

export default MuiFileUpload
