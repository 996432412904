import React, { useState, useEffect, useRef } from 'react'
import Table from '../Table'
import Search from './OrderSearch'
import Select from '../Select'
import LabalSelector from '../LabelSelector'
import OrderDetailsModal from '../../components/OrderDetailsModal'
import Modal from '../Modal'
import OrderHomePage from '../OrderHomePage'
import Loading from '../LoadingSpinner'
import TablePagination from '@mui/material/TablePagination'
import { useTranslation } from 'react-i18next'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import {
  PaginationParent,
  OrderWrapper,
  OrderTopBar,
  OrderToolbar,
  OrderLabelSelectorWrapper,
  Title,
  Popup,
  EmptyTable,
  SelectWrapper
} from './style'
import { isArray } from 'lodash'
// import { SkipPreviousSharp } from '@mui/icons-material'

const onSelect = (value) => {
}

const Orders = ({
  onGetOrderList,
  onUpdatePartialFulfillment,
  partialfulfillmentOrderProps,
  orders,
  error,
  isLoading,
  totalRecords,
  onGetOrderDetails,
  orderDetails,
  errorOrderDetails,
  isLoadingOrderDetails,
  stores,
  onGetStoreList,
  onGetShipParams,
  orderParams,
  orderParamsError,
  orderParamsIsLoading,
  onPostCancelOrder,
  ordersCancelStatus,
  cancelOrderError,
  isCancelOrderLoading,
  onPostShipOrder,
  ordersShipStatus,
  shipOrderError,
  isShipOrderLoading,
  onGetOrderStatusList,
  orderStatusList,
  errorOrderStatusList,
  orderStatusListIsLoading,
  status,
  ...restProps
}) => {
  const { t } = useTranslation()
  const labelSelectorOptions = [
    {
      label: t('orders.allOrders'),
      value: 'all'
    },
    {
      label: t('orders.pendingOrders'),
      value: 'PENDING'
    },
    {
      label: t('orders.cancelledOrders'),
      value: 'CANCELLED'
    },
    {
      label: t('orders.completedOrders'),
      value: 'COMPLETED'
    }
  ]

  const orderStatusLabels = {
    PROCESSED: 'Processed'
  }
  const headers = [
    {
      title: t('ordersTableHeader.orderID'),
      key: 'marketPlaceOrderId',
      sortable: false
    },
    {
      title: t('ordersTableHeader.customerName'),
      key: 'customerFullName',
      sortable: false
    },
    {
      title: 'Items',
      key: 'orderItems',
      sortable: false
    },
    {
      title: t('ordersTableHeader.totalCost'),
      key: 'totalAmount',
      sortable: false
    },
    {
      title: t('ordersTableHeader.storeName'),
      key: 'storeName',
      sortable: false
    },
    {
      title: t('ordersTableHeader.dateTime'),
      key: 'orderCreationDateTime',
      sortable: false
    },
    {
      title: 'Status',
      key: 'status',
      sortable: false
    },
    {
      title: t('ordersTableHeader.partialfulfillment'),
      key: 'partialfulfillment',
      sortable: false
    }
  ]

  const [showModal, setShowModal] = useState(false)
  const [selectOrder, setSelectOrder] = useState(0)
  const [orderSubStatus, setOrderSubStatus] = useState('')
  const [labelSelectedValue, setLabelSelectedValue] = useState(status === 'PENDING' || status === 'CANCELLED' ? status : labelSelectorOptions[0].value)
  const [selectedDropDownValue, setSelectedDropDownValue] = useState('Select')
  const [searchText, setSearchText] = useState()
  const [storeList, setStoreList] = useState([])
  const [selectedStoreID, setSelectedStoreID] = useState('')
  const [skip, setSkip] = useState(0)
  const firstTime = useRef(true)
  const aproachLast = useRef(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const changeStatusColumnCapitalToSmallLetter = (list) => {
    const capitalizeFirstLetter = ([first, ...rest]) => first === undefined ? '' : first.toUpperCase() + rest.join('').toLowerCase()

    return list.map(({ status: st_, ...rest }) => {
      return { status: capitalizeFirstLetter(st_), ...rest }
    })
  }

  const setOptionsForDropDown = (values) => {
    return values.map(value => ({
      label: value,
      value: value
    }))
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setSkip(0)
  }

  const handleChangePage = (event, newPage) => {
    const delta = newPage - page
    if (delta === 1) skipOp('inc')
    else if (delta === -1) skipOp('dec')
    else if (delta < -1) setSkip(0)
    else if (delta > 1) setSkip(totalRecords - Math.abs(totalRecords % rowsPerPage))
    setPage(newPage)
  }

  useEffect(() => {
    if (skip > 0 && orders.length === 0 && !aproachLast.current) {
      setSkip(skip - rowsPerPage)
      aproachLast.current = true
    }
  }, [skip, orders.length, rowsPerPage])

  const labelOnSelect = value => {
    setIsFilterApplied(true)
    setLabelSelectedValue(value)
    setOrderSubStatus('')
    setPage(0)
    setSkip(0)
  }

  useEffect(() => {
    setOrderSubStatus('')
  }, [selectedDropDownValue])

  const onSubStatusOrderChange = (target) => {
    setIsFilterApplied(true)
    setSearchText('')
    setLabelSelectedValue(labelSelectorOptions[0].value)
    setSelectedDropDownValue('Select')
    setSelectedStoreID('')
    setPage(0)
    setSkip(0)
    setOrderSubStatus(target)
  }

  useEffect(() => {
    let event = 'All'
    if (labelSelectedValue === 'PENDING') event = Events.ORDER_PENDING_LIST
    else if (labelSelectedValue === 'CANCELLED') event = Events.ORDER_CANCELLED_LIST
    else if (labelSelectedValue === 'COMPLETED') event = Events.ORDERS_COMPLETED_LIST
    EventEmitter.emit(event)
  }, [labelSelectedValue])

  useEffect(() => { firstTime.current = false }, [])

  const skipOp = (op) => {
    setIsFilterApplied(true)
    if (op === 'dec' && skip !== 0) {
      setSkip(skip - rowsPerPage)
      aproachLast.current = false
    }
    if (op === 'inc' && !aproachLast.current) setSkip(skip + rowsPerPage)
  }

  const setAndGetStoreID = (storeName) => {
    const storeListValuesParentArray = storeList.filter(store => store.label === storeName)?.['0']

    if (Array.isArray(storeList) && storeListValuesParentArray) {
      setSelectedStoreID(storeListValuesParentArray?.value)
    }
  }

  useEffect(() => {
    if (searchText !== '') {
      EventEmitter.emit(Events.ORDER_SEARCH_TERM, { search_keyword: searchText })
    }

    onGetOrderStatusList()

    onGetOrderList({
      fStatus: labelSelectedValue,
      payloadQueryParam: selectedDropDownValue,
      searchQueryParam: searchText,
      skip: skip,
      limit: rowsPerPage,
      subStatus: orderSubStatus !== 'Show All' ? orderSubStatus : ''
    })
  }, [orderSubStatus, rowsPerPage, onGetOrderStatusList, onGetOrderList, labelSelectedValue, selectedDropDownValue, searchText, skip, partialfulfillmentOrderProps])

  useEffect(() => {
    if (selectOrder) onGetOrderDetails({ order_id: selectOrder })
  }, [onGetOrderDetails, selectOrder])

  useEffect(() => {
    if (selectedStoreID && orderDetails?.marketPlaceOrderId) {
      onGetShipParams({
        marketplaceOrderId: orderDetails?.marketPlaceOrderId,
        marketplaceStoreId: selectedStoreID
      })
    }
  }, [onGetShipParams, orderDetails?.marketPlaceOrderId, selectedStoreID])

  useEffect(() => {
    setSkip(0)
    aproachLast.current = false
  }, [labelSelectedValue, selectedDropDownValue, searchText])

  useEffect(() => {
    onGetStoreList()
  }, [onGetStoreList])

  useEffect(() => {
    if (stores.length > 0) {
      setStoreList(stores.map(storeObject => {
        return { label: storeObject.shop_name, value: storeObject.marketplace_shop_id }
      }))
    }
  }, [stores])

  const updatePartialfulfillment = async (data) => {
   await onUpdatePartialFulfillment(data);
  }

  const OrderList = () => {
    return (
      <>
        <OrderWrapper>
          <OrderTopBar>
            <Title>{t('orders.orders')}</Title>
            <OrderToolbar>
              <Search
                placeholder={t('placeHolderText.searchByOrderId')}
                onEnter={setSearchText}
                setIsFilterApplied={setIsFilterApplied}
                onInput={(value) => { setSearchText(value) }}
                setOrderSubStatus={setOrderSubStatus}
              />
              <div>
                <SelectWrapper>Select Order Status:</SelectWrapper>
                <Select
                  type='orderStatus'
                  onSelect={onSelect}
                  options={setOptionsForDropDown(orderStatusList)}
                  setIsFilterApplied={setIsFilterApplied}
                  selectedValue={orderSubStatus}
                  setSelectedValue={onSubStatusOrderChange}
                />
              </div>
              <div>
                <SelectWrapper>Select Your Store:</SelectWrapper>
                <Select
                  options={storeList}
                  onSelect={onSelect}
                  setIsFilterApplied={setIsFilterApplied}
                  selectedValue={selectedDropDownValue}
                  setSelectedValue={setSelectedDropDownValue}
                  setOrderSubStatus={setOrderSubStatus}
                />
              </div>
            </OrderToolbar>
            <OrderLabelSelectorWrapper>
              <LabalSelector
                options={labelSelectorOptions}
                onSelect={(value) => { labelOnSelect(value) }}
                selected={labelSelectedValue}
                setSelected={setLabelSelectedValue}
              />
            </OrderLabelSelectorWrapper>
          </OrderTopBar>
          {(!error && isLoading)
            ? <Loading />
            : (orders.length === 0 && isFilterApplied)
                ? <EmptyTable>No records found</EmptyTable>
                : <Table
                    headers={headers}
                    statusValue={orderSubStatus}
                    onChangeStatus={onSubStatusOrderChange}
                    data={changeStatusColumnCapitalToSmallLetter(orders)}
                    ordersSubStatusList={orderStatusList}
                    toggleModal={() => setShowModal(true)}
                    setRowSelect={setSelectOrder}
                    setStoreID={setAndGetStoreID}
                    orderStatusLabels={orderStatusLabels}
                    onUpdatePartialFulfillment={updatePartialfulfillment}
                  />}
          <PaginationParent>
            <TablePagination
              component='div'
              rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
              count={totalRecords}
              labelRowsPerPage={t('placeHolderText.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              showFirstButton
              showLastButton
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* <span onClick={() => skipOp('dec')}>{'<  '}</span>Pages<span onClick={() => skipOp('inc')}>{'  >'}</span> */}
          </PaginationParent>
        </OrderWrapper>
        <Modal showModal={showModal} PopupComponent={Popup} toggleModal={() => setShowModal(!showModal)}>
          <OrderDetailsModal
            details={orderDetails}
            error={errorOrderDetails}
            isLoading={isLoadingOrderDetails}
            storeID={selectedStoreID}
            onGetShipParams={onGetShipParams}
            orderParams={orderParams}
            orderParamsError={orderParamsError}
            orderParamsIsLoading={orderParamsIsLoading}
            onPostCancelOrder={onPostCancelOrder}
            cancelStatus={ordersCancelStatus}
            cancelError={cancelOrderError}
            isCancelLoading={isCancelOrderLoading}
            onPostShipOrder={onPostShipOrder}
            setModalVisibility={setShowModal}
          />
        </Modal>
      </>
    )
  }

  if (firstTime.current || (isLoading && !error && !isFilterApplied)) return <Loading />

  return (
    <>
      {(isFilterApplied || orders?.length > 0 || skip > 0 || labelSelectedValue !== labelSelectorOptions[0].value || selectedDropDownValue !== 'Select') || orderSubStatus || isLoading ? <OrderList /> : <OrderHomePage />}
    </>
  )
}

export default Orders
