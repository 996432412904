import { useCallback } from 'react'
import Layout from '../Layout'
import { useDispatch, useSelector } from 'react-redux'
import AnalyticsComponent from '../../components/Analytics'

import {
  GET_ANALYTICS,
  GET_ANALYTICSOSP_LIST,
  GET_ANALYTICS_ORDER_STATUS,
  GET_ANALYTICS_TOTAL_ORDER,
  GET_ANALTYICS_REVENUE_SUMMARY,
  GET_ANALTYICS_RECENT_ORDERS,
  PRODUCTS_GET_LIST
} from '../../actions'

const Analytics = props => {
  const analyticsProps = useSelector((state) => {
    const {
      analytics
    } = state

    return {
      analyticsData: analytics?.get('content').toJS(),
      error: analytics?.get('error'),
      isLoading: analytics?.get('isLoading')
    }
  })

  const analyticsOSPProps = useSelector((state) => {
    const {
      analytics
    } = state

    return {
      analyticsOSPData: analytics?.get('contentOSP').toJS(),
      errorOSP: analytics?.get('errorOSP'),
      isLoadingOSP: analytics?.get('isLoadingOSP')
    }
  })

  const analyticsOrderStatusProps = useSelector((state) => {
    const {
      analytics
    } = state

    return {
      contentOrderStatus: analytics?.get('contentOrderStatus').toJS(),
      errorOrderStatus: analytics?.get('errorOrderStatus'),
      isLoadingOrderStatus: analytics?.get('isLoadingOrderStatus')
    }
  })

  const analyticsTotalOrderProps = useSelector((state) => {
    const {
      analytics
    } = state

    return {
      contentTotalOrder: analytics?.get('contentTotalOrder').toJS(),
      errorTotalOrder: analytics?.get('errorTotalOrder'),
      isLoadingTotalOrder: analytics?.get('isLoadingTotalOrder')
    }
  })

  const analyticsRevenueSummaryProps = useSelector((state) => {
    const {
      analytics
    } = state

    return {
      contentRevenueSummary: analytics?.get('contentRevenueSummary').toJS(),
      errorRevenueSummary: analytics?.get('errorRevenueSummary'),
      isLoadingRevenueSummary: analytics?.get('isLoadingRevenueSummary')
    }
  })

  const analyticsRecentOrderProps = useSelector((state) => {
    const {
      analytics
    } = state

    return {
      contentRecentOrder: analytics?.get('contentRecentOrder').toJS(),
      errorRecentOrder: analytics?.get('errorRecentOrder'),
      isLoadingRecentOrder: analytics?.get('isLoadingRecentOrder')
    }
  })

  const productListProps = useSelector((state) => {
    const {
      products
    } = state
    // console.log(state)
    // console.log(products.get('content').toJS())
    return {
      productList: products.get('content').toJS(),
      isLoading: products.get('isLoading')
    }
  })

  const callAnalyticsDispatch = useDispatch()
  const callAnalyticsOSPDispatch = useDispatch()
  const callAnalyticsOrderStatusDispatch = useDispatch()
  const totalOrderDispatch = useDispatch()
  const revenueSummaryDispatch = useDispatch()
  const recentOrderDispatch = useDispatch()
  const productListDispatch = useDispatch()

  const onGetAnalytics = useCallback((query) => {
    callAnalyticsDispatch({ type: GET_ANALYTICS, payload: query })
  }, [callAnalyticsDispatch])

  const onGetAnalyticsOSP = useCallback((query) => {
    callAnalyticsOSPDispatch({ type: GET_ANALYTICSOSP_LIST, payload: query })
  }, [callAnalyticsOSPDispatch])

  const onGetAnalyticsOrderStatus = useCallback((query) => {
    callAnalyticsOrderStatusDispatch({ type: GET_ANALYTICS_ORDER_STATUS, payload: query })
  }, [callAnalyticsOrderStatusDispatch])

  const onGetAnalyticsTotalOrder = useCallback((query) => {
    totalOrderDispatch({ type: GET_ANALYTICS_TOTAL_ORDER, payload: query })
  }, [totalOrderDispatch])

  const onGetAnalyticsRevenueSummary = useCallback((query) => {
    revenueSummaryDispatch({ type: GET_ANALTYICS_REVENUE_SUMMARY, payload: query })
  }, [revenueSummaryDispatch])

  const onGetAnalyticsRecentOrder = useCallback((query) => {
    recentOrderDispatch({ type: GET_ANALTYICS_RECENT_ORDERS, payload: query })
  }, [recentOrderDispatch])

  const onGetProductsList = useCallback((query) => {
    productListDispatch({ type: PRODUCTS_GET_LIST, payload: query })
  }, [productListDispatch])

  return (
    <Layout>
      <AnalyticsComponent {...
        {
          ...analyticsProps,
          ...analyticsOSPProps,
          analyticsOrderStatusProps,
          analyticsTotalOrderProps,
          analyticsRevenueSummaryProps,
          analyticsRecentOrderProps,
          productListProps,
          onGetAnalytics,
          onGetAnalyticsOSP,
          onGetAnalyticsOrderStatus,
          onGetAnalyticsTotalOrder,
          onGetAnalyticsRevenueSummary,
          onGetAnalyticsRecentOrder,
          onGetProductsList,
          ...props
        }
      }
      />
    </Layout>
  )
}

Analytics.displayName = 'Analytics'
export default Analytics
