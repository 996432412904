import OrderShip from '../../components/OrdersActions/ShipOrder'

const OrderShipContainer = (props) => {
  return (
    <OrderShip {...props} />
  )
}

OrderShipContainer.displayName = 'OrderShipContainer'
export default OrderShipContainer
