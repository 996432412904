import { connect } from 'react-redux'
import Layout from '../../components/Layout'
import {
  AUTH_LOGOUT_USER,
  BRAND_GET_LIST
} from '../../actions'

const mapStateToProps = (state) => {
  const {
    users,
    subscription,
    brands
  } = state
  return {
    userId: users.get('id'),
    userEmail: users.get('email'),
    userName: users.get('name'),
    brandAccess: subscription.get('brandAccess'),
    brandsList: brands.get('brandsList'),
    selectedBrand: brands.get('selectedBrand'),
    brandIsLoading: brands.get('isLoading'),
    brandError: brands.get('error'),
    brandsFetched: brands.get('fetched'),
    brandErrorDisplayed: brands.get('errorDisplayed')
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => {
    dispatch({ type: AUTH_LOGOUT_USER })
  },
  onGetBrandList: (query) => {
    dispatch({ type: BRAND_GET_LIST, payload: query })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
