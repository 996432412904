import React, { useState } from 'react'
import CHI from '../../assets/images/Bitmap.png'
import StoreCreatePopup from '../StoreCreatePopup'
import {
  Main,
  Text,
  BtnConnect,
  Textsmall,
  BtnCreate,
  BtnCreateInstantStore,
  Imagetag
} from './style'
import { useTranslation } from 'react-i18next'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import { Link } from 'react-router-dom'

const Connections = props => {
  const { t } = useTranslation()
  const { NewStoreClick } = props
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    EventEmitter.emit(Events.CREATE_NEW_STORE)
    setOpen(true)
  }

  const handleClose = () => {
    EventEmitter.emit(Events.CREATE_NEW_STORE_OK)
    setOpen(false)
  }

  const handleClickCreateOnInstantStore = () => {
    EventEmitter.emit(Events.CREATE_NEW_STORE_INSTANT)
  }
  return (
    <>
      <Main>
        <Imagetag src={CHI} />
        <Text>{t('connectionHome.gotAnExistingStore')}?</Text>
        <BtnConnect onClick={NewStoreClick}>{t('connectionHome.connectStore')}</BtnConnect>
        <Textsmall>{t('connectionHome.dontHaveAStore')}?</Textsmall>
        <BtnCreateInstantStore onClick={handleOpen}>{t('connectionHome.createNewStoreOnShoppee')}</BtnCreateInstantStore>
        <Link to='/linkstore' onClick={handleClickCreateOnInstantStore}>
          <BtnCreate>
            {t('connectionHome.createInstantStore')}
          </BtnCreate>
        </Link>
        <StoreCreatePopup open={open} handleClose={handleClose} />
      </Main>
    </>
  )
}

Connections.displayName = 'ConnectionsHome'
export default Connections
