import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import IconButton from '@mui/material/IconButton'

const handleStyle = {
  width: '25px',
  height: 'calc(100% - 4px)',
  borderRight: '3px solid #F0F2FF',
  background: '#fff',
  display: 'flex',
  position: 'absolute',
  top: '2px',
  left: '1px',
  zIndex: 1,
  cursor: 'move',
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px'
}

const handleStyleUp = {
  width: '20px',
  height: '20px',
  position: 'absolute',
  top: 0,
  left: '3px',
  zIndex: 20
}

const handleStyleDown = {
  ...handleStyleUp,
  ...{ top: 'auto', bottom: 0 }
}

const handleStyleDots = {
  position: 'absolute',
  top: 'calc(50% - 13px)',
  left: '9px',
  zIndex: 1,
  background: '#A2A5BB',
  boxShadow: '0 10px 0 0 #A2A5BB, 0 20px 0 0 #A2A5BB',
  width: '6px',
  height: '6px',
  borderRadius: '6px'
}

export const DraggableContainer = ({ id, text, cardNode, index, moveCard, cardDropped, showArrows = true, showDraggable = true, length }) => {
  const ref = useRef(null)
  const [{ isOver }, drop] = useDrop({
    accept: 'ItemTypes.CARD',
    drop (item, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex !== hoverIndex) {
        moveCard(dragIndex, hoverIndex)
      }
      cardDropped()
    },
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  })
  const [, drag, preview] = useDrag({
    type: 'ItemTypes.CARD',
    item: { id, index }
  })
  drag(drop(ref))

  const handlerUp = () => {
    const dragIndex = index - 1
    const hoverIndex = index
    if (hoverIndex === 0) {
      moveCard(hoverIndex, length - 1)
      cardDropped()
    } else if (dragIndex !== hoverIndex) {
      moveCard(dragIndex, hoverIndex)
      cardDropped()
    }
  }

  const handlerDown = () => {
    const dragIndex = index + 1
    const hoverIndex = index
    if (dragIndex === length) {
      moveCard(hoverIndex, 0)
      cardDropped()
    } else if (dragIndex !== hoverIndex) {
      moveCard(dragIndex, hoverIndex)
      cardDropped()
    }
  }

  return (
    <>
      <div style={{ border: `2px solid ${isOver ? 'green' : 'transparent'}` }} />
      <div ref={ref} style={{ position: 'relative' }}>
        <div ref={preview}>
          {showArrows && <IconButton style={handleStyleUp} onClick={handlerUp}><ArrowDropUpIcon style={{ color: '#a3a5bb' }} /></IconButton>}
          {showArrows && <IconButton style={handleStyleDown} onClick={handlerDown}><ArrowDropDownIcon style={{ color: '#a3a5bb' }} /></IconButton>}
          {showDraggable && <div ref={(node) => drag(drop(node))} style={handleStyle} className='_dragHandler'><span style={handleStyleDots} /></div>}
          {cardNode}
        </div>
      </div>
    </>
  )
}
