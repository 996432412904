import { Map } from 'immutable'
import { toast } from 'react-toastify'

import {
  ORDER_CANCEL_LOADING,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAILURE,
  ORDER_SHIP_LOADING,
  ORDER_SHIP_SUCCESS,
  ORDER_SHIP_FAILURE
} from '../actions'

const initialState = new Map({
  isLoading: false,
  error: '',
  success: false
})

export const ordersCancel = (state = initialState, action) => {
  const {
    data,
    message,
    type,
    isLoading
  } = action

  switch (type) {
    case ORDER_CANCEL_LOADING:
      return state.set('isLoading', isLoading)
    case ORDER_CANCEL_SUCCESS:
      toast.info('Successfully cancelled')
      return state.set('success', data?.success)
    case ORDER_CANCEL_FAILURE:
      toast.info('Order is not cancelled')
      return state.set('error', message)

    default:
      return state
  }
}

export const ordersShip = (state = initialState, action) => {
  const {
    data,
    message,
    type,
    isLoading
  } = action

  switch (type) {
    case ORDER_SHIP_LOADING:
      return state.set('isLoading', isLoading)
    case ORDER_SHIP_SUCCESS:
      toast.info('Shipping request is sent')
      return state.set('success', data?.success)
    case ORDER_SHIP_FAILURE:
      toast.error('Not successfull')
      return state.set('error', message)

    default:
      return state
  }
}
