import { Radio, FormControlLabel } from '@mui/material'
import { FormLabel, FormError, RadioGroupWrap } from './style'

const MuiRadio = (props) => {
  return (
    <>
      {props?.title && <FormLabel>{props.title}</FormLabel>}
      {props?.options && <RadioButton {...props} />}
      {props?.error && <FormError>{props?.error}</FormError>}
    </>
  )
}

export default MuiRadio

const RadioButton = (props) => {
  const {
    options,
    name,
    onChange,
    selectlabel,
    selectvalue,
    size,
    defaultValue
  } = props

  const handleChange = (event) => {
    const selectedData = { value: event.target.value, label: event.target.name }
    onChange(event, selectedData)
  }

  const defaultVal = options[0][selectvalue || 'value']

  return (
    <RadioGroupWrap row {...props} name={name} onChange={handleChange} size={size} defaultValue={defaultValue || defaultVal}>
      {options.map((s, i) =>
        <FormControlLabel key={i} value={(s.value) || s[selectvalue]} control={<Radio />} label={(s.label) || s[selectlabel]} />
      )}
    </RadioGroupWrap>
  )
}
