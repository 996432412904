import React from 'react'
import { useTranslation } from 'react-i18next';
import { NoSKUWrapper, NoSKUIconDiv, NoSKUIconWrapper, PrimaryText, SecondaryText } from './style'
import NoSKUIcon from '../../assets/images/no-sku-icon.svg'

const NoSKU = () => {
  const { t } = useTranslation()
  return (
    <NoSKUWrapper>
      <NoSKUIconDiv>
        <NoSKUIconWrapper src={NoSKUIcon} alt='NoSKUIcon' />
      </NoSKUIconDiv>
      <PrimaryText>{t(`brandDashboard.noBrand`)}</PrimaryText>
      <SecondaryText>{t(`brandDashboard.noTrack`)}</SecondaryText>
    </NoSKUWrapper>
  )
}

NoSKU.displayName = 'NoSKUComponent'
export default NoSKU
