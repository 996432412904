export const brandsResponse = [
  {
    id: '0',
    name: 'NoBrand'
  },
  {
    id: '1014018',
    name: 'A Active C'
  },
  {
    id: '1014041',
    name: 'AA Glowskin'
  },
  {
    id: '1014042',
    name: 'B Natura'
  },
  {
    id: '1014045',
    name: 'AA Skin Care'
  },
  {
    id: '1014049',
    name: 'B Skin'
  },
  {
    id: '1014050',
    name: 'CA Beauty Care'
  },
  {
    id: '1014053',
    name: 'CA Face Care'
  },
  {
    id: '1014064',
    name: 'BB Cream Ratu'
  },
  {
    id: '1014068',
    name: 'BB Glow'
  },
  {
    id: '1014079',
    name: 'BBC'
  },
  {
    id: '1014095',
    name: 'AB Series'
  },
  {
    id: '1014114',
    name: 'BCI'
  },
  {
    id: '1014117',
    name: 'BCL'
  },
  {
    id: '1014124',
    name: 'BDL'
  },
  {
    id: '1014133',
    name: 'BEC Skinmart'
  },
  {
    id: '1014187',
    name: 'BH Skin'
  },
  {
    id: '1014188',
    name: 'ACL'
  },
  {
    id: '1014191',
    name: 'ACL Skincare'
  },
  {
    id: '1014255',
    name: 'BLP by Lizzie Parra'
  },
  {
    id: '1014261',
    name: 'CMB'
  },
  {
    id: '1014285',
    name: 'AF Glow'
  },
  {
    id: '1014286',
    name: 'BMKS'
  },
  {
    id: '1014302',
    name: 'BNC'
  },
  {
    id: '1014304',
    name: 'CNP Laboratory'
  },
  {
    id: '1014305',
    name: 'BNCskin Solutions'
  },
  {
    id: '1014326',
    name: 'BOG'
  },
  {
    id: '1014334',
    name: 'COW'
  },
  {
    id: '1014336',
    name: 'BOH Botanic Heal'
  },
  {
    id: '1014341',
    name: 'AGT Skin Glow'
  },
  {
    id: '1014350',
    name: 'AH Beauty'
  },
  {
    id: '1014354',
    name: 'AH Skincare'
  },
  {
    id: '1014361',
    name: 'AHC'
  },
  {
    id: '1014364',
    name: 'CR'
  },
  {
    id: '1014368',
    name: 'CRD'
  },
  {
    id: '1014387',
    name: 'BRTC'
  },
  {
    id: '1014395',
    name: 'CS Skin Care'
  },
  {
    id: '1014399',
    name: 'CSD'
  },
  {
    id: '1014403',
    name: 'CSI'
  },
  {
    id: '1014424',
    name: 'BSI'
  },
  {
    id: '1014467',
    name: 'AM Skincare'
  },
  {
    id: '1014471',
    name: 'BVR'
  },
  {
    id: '1014474',
    name: 'BWS'
  },
  {
    id: '1014497',
    name: 'BYPHASSE'
  },
  {
    id: '1014529',
    name: 'AMR Beauty'
  },
  {
    id: '1014562',
    name: 'Babies Glow'
  },
  {
    id: '1014564',
    name: 'ANR'
  },
  {
    id: '1014571',
    name: 'Babiqi'
  },
  {
    id: '1014594',
    name: 'Babor'
  },
  {
    id: '1014626',
    name: 'Baby Arauna'
  },
  {
    id: '1014640',
    name: 'AQ'
  },
  {
    id: '1014674',
    name: 'Baby Bright'
  },
  {
    id: '1014695',
    name: 'Cahaya Dunia Abadi'
  },
  {
    id: '1014699',
    name: 'Cahaya Estetika'
  },
  {
    id: '1014718',
    name: 'ARS Beauty Care'
  },
  {
    id: '1014723',
    name: 'Cahaya Sarah Skincare'
  },
  {
    id: '1014727',
    name: 'Cahaya Skincare'
  },
  {
    id: '1014739',
    name: 'AS Glowing Expert'
  },
  {
    id: '1014750',
    name: 'Caiji'
  },
  {
    id: '1014751',
    name: 'ASG'
  },
  {
    id: '1014756',
    name: 'Cailyn'
  },
  {
    id: '1014782',
    name: 'ASQ Jelita'
  },
  {
    id: '1014821',
    name: 'Baby Glow'
  },
  {
    id: '1014848',
    name: 'AV Beauty Mask'
  },
  {
    id: '1014893',
    name: 'AWS Beauty'
  },
  {
    id: '1014898',
    name: 'Calendula'
  },
  {
    id: '1014901',
    name: 'Calenduna'
  },
  {
    id: '1014963',
    name: 'Baby Pink'
  },
  {
    id: '1014967',
    name: 'Aavreeda'
  },
  {
    id: '1014981',
    name: 'Callista'
  },
  {
    id: '1014990',
    name: 'Callista Skin Treatment'
  },
  {
    id: '1015019',
    name: 'Calm Skin'
  },
  {
    id: '1015026',
    name: 'Calmedi Natural Skin Expert'
  },
  {
    id: '1015029',
    name: 'Calmia'
  },
  {
    id: '1015108',
    name: 'Babyface'
  },
  {
    id: '1015133',
    name: 'Calys'
  },
  {
    id: '1015137',
    name: 'Calysta'
  },
  {
    id: '1015146',
    name: 'Abib'
  },
  {
    id: '1015155',
    name: 'Abiellabeauty'
  },
  {
    id: '1015218',
    name: 'Abloom'
  },
  {
    id: '1015222',
    name: 'Camelia Organic'
  },
  {
    id: '1015248',
    name: 'About Me'
  },
  {
    id: '1015276',
    name: 'Bad Lab'
  },
  {
    id: '1015309',
    name: 'Absolute New York'
  },
  {
    id: '1015311',
    name: 'Badger'
  },
  {
    id: '1015338',
    name: 'Badskin'
  },
  {
    id: '1015347',
    name: 'Baemask'
  },
  {
    id: '1015355',
    name: 'Baepricawijaya'
  },
  {
    id: '1015367',
    name: 'Cancer Council'
  },
  {
    id: '1015390',
    name: 'Acanthe'
  },
  {
    id: '1015437',
    name: 'Caneza'
  },
  {
    id: '1015439',
    name: 'Bahan Alami'
  },
  {
    id: '1015459',
    name: 'Canmake'
  },
  {
    id: '1015490',
    name: 'Acek'
  },
  {
    id: '1015507',
    name: 'Baimiss'
  },
  {
    id: '1015528',
    name: 'Achiera'
  },
  {
    id: '1015529',
    name: 'CantikHouse Skincare'
  },
  {
    id: '1015551',
    name: 'Acio Skincare'
  },
  {
    id: '1015584',
    name: 'Caolion'
  },
  {
    id: '1015586',
    name: 'Acne Feldin'
  }
]

export const attributesResponse = [
  {
    attributeId: 100037,
    originalAttributeName: 'Region of Origin',
    displayAttributeName: 'Country of Origin',
    isMandatory: false,
    inputValidationType: 'STRING_TYPE',
    formatType: 'NORMAL',
    dateFormatType: 'YEAR_MONTH_DATE',
    inputType: 'COMBO_BOX',
    attributeUnit: [],
    attributeValueList: [
      {
        valueId: 171,
        originalValueName: 'Bangladesh',
        displayValueName: 'Bangladesh'
      },
      {
        valueId: 35,
        originalValueName: 'Mainland China',
        displayValueName: 'China'
      },
      {
        valueId: 154,
        originalValueName: 'India',
        displayValueName: 'India'
      },
      {
        valueId: 68,
        originalValueName: 'Indonesia',
        displayValueName: 'Indonesia'
      },
      {
        valueId: 1,
        originalValueName: 'Japan',
        displayValueName: 'Japan'
      },
      {
        valueId: 48,
        originalValueName: 'Korea',
        displayValueName: 'Korea'
      },
      {
        valueId: 93,
        originalValueName: 'Others',
        displayValueName: 'Others'
      },
      {
        valueId: 112,
        originalValueName: 'Taiwan',
        displayValueName: 'Taiwan'
      },
      {
        valueId: 129,
        originalValueName: 'Thailand',
        displayValueName: 'Thailand'
      },
      {
        valueId: 110,
        originalValueName: 'America',
        displayValueName: 'America'
      },
      {
        valueId: 136,
        originalValueName: 'Vietnam',
        displayValueName: 'Vietnam'
      },
      {
        valueId: 6737,
        originalValueName: 'Brasil',
        displayValueName: 'Brasil'
      },
      {
        valueId: 6738,
        originalValueName: 'Colombia',
        displayValueName: 'Colombia'
      },
      {
        valueId: 6739,
        originalValueName: 'Chile',
        displayValueName: 'Chile'
      },
      {
        valueId: 6740,
        originalValueName: 'Argentina',
        displayValueName: 'Argentina'
      },
      {
        valueId: 6741,
        originalValueName: 'Mexico',
        displayValueName: 'Mexico'
      }
    ]
  }
]

export const productEditData = {
  content: {
    store_id: '423412341234',
    store_name: 'Test Store',
    marketplace_shop_id: 36653,
    name: 'IPhone 13',
    condition: 'NEW',
    sku: 'RAEN103',
    price: 89.877,
    stock: 100,
    weight: 1.1,
    description: 'test',
    image_id_list: ['94765b2cc68f9e5b693b02f32da4135d'],
    brand: {
      id: '1014129',
      name: 'CDL'
    },
    category: [
      { id: '0', name: '', display_name: '' },
      { id: '100011', name: 'Men Clothes', display_name: 'Men Clothes' },
      { id: '100061', name: 'Others', display_name: 'Others' }
    ],
    category_: {
      id: '100227',
      name: 'Massage & Therapy Devices',
      displayName: 'Massage & Therapy Devices'
    },
    attributes: [
      {
        id: 100131,
        name: 'Electrical Device',
        isMandatory: false,
        values: [
          {
            id: 1123,
            name: 'No',
            unit: ''
          }
        ]
      }
    ],
    tier_variations: [
      {
        name: 'Color',
        options: [
          {
            option: 'Red',
            image_id: '94765b2cc68f9e5b693b02f32da4135d'
          },
          {
            option: 'Blue',
            image_id: '94765b2cc68f9e5b693b02f32da4135d'
          }
        ]
      },
      {
        name: 'Size',
        options: [
          {
            option: 's',
            image_id: '94765b2cc68f9e5b693b02f32da4135d'
          },
          {
            option: 'm',
            image_id: '94765b2cc68f9e5b693b02f32da4135d'
          }
        ]
      }
    ],
    models: [
      {
        original_price: 100,
        model_sku: 'RN100',
        normal_stock: 100,
        tier_index: [0, 1]
      }
    ],
    dimension: {
      package_length: 0,
      package_width: 0,
      package_height: 0
    }
  }
}

export const ProductListData = {
  success: true,
  message: 'OK',
  data: [{
    totalPages: 1,
    payloadSize: 1,
    hasNext: false,
    content: {
      product_image: 'https://www.urbangardengal.com/wp-content/uploads/2020/01/bluebell-flower.jpg',
      code: '2134721374',
      title: 'Pueraria Mirifica And Study Phyto Estrogens',
      listed: false,
      store_details: [{
        store_name: 'Shopee',
        product_name: 'Bellflower Jakarta',
        store_image: 'https://cdn-icons-png.flaticon.com/512/13/13398.png',
        mSKUID: '3214',
        listings: [{
          status: 'live',
          units_sold: '0',
          sku_id: '1221',
          stock: '300',
          price: '34,240'
        }, {
          status: 'in_active',
          sku_id: '1221',
          units_sold: '0',
          stock: '300',
          price: '34,240'
        }]
      }, {
        store_name: 'Tokopedia',
        store_image: 'https://cdn-icons-png.flaticon.com/512/13/13398.png',
        mSKUID: '3214',
        product_name: 'Bellflower Jakarta',
        listings: [{
          status: 'live',
          units_sold: '0',
          sku_id: '1221',
          stock: '300',
          price: '34,240'
        }, {
          sku_id: '1221',
          status: 'in_active',
          units_sold: '0',
          stock: '300',
          price: '34,240'
        }]
      }],
      id: '1'
    }
  }],
  currentPage: 1,
  skippedRecords: 0,
  totalRecords: 1
}

export const productAddData = {
  categoryId: 1,
  name: 'Test Product',
  type: 'Test Product',
  shortDescription: 'Test Product',
  description: 'This is a test product for testing',
  images: [{
    key: 'S3_image_key',
    priority: 1
  }],
  condition: 'New',
  brand: {
    id: 1,
    name: 'Test Brand'
  },
  productSource: 'Merchant',
  fullFillVia: 'OwnInventory',
  variant: [{
    color: 'White',
    size: 'Small',
    sku: 'TST-001',
    pricing: {
      minOrderQty: 1,
      maxOrderQty: 10,
      price: 100,
      currency: 'INR'
    },
    images: [{
      key: 'S3_image_key',
      priority: 1
    }],
    wholesale: {
      minOrderQty: 10,
      maxOrderQty: 0,
      price: 100,
      currency: 'INR'
    },
    isPrimary: true,
    type: 'New'
  }],
  pricing: {
    minOrderQty: 0,
    maxOrderQty: 10,
    price: 100,
    currency: 'INR'
  },
  stock: {
    available: 10
  },
  details: {
    countryId: 1,
    composition: 'Test composition',
    storageTime: '',
    skinType: '',
    sku: 'TST-01',
    raenaSku: ''
    // expirationDate: '2022-05-13'
  },
  shipping: {
    weight: {
      value: 12,
      unit: 'kg'
    },
    dimension: {
      length: 1.1,
      width: 2.1,
      height: 1.1
    },
    fee: {
      type: 'Fixed',
      shippingCovered: false
    },
    isMustInsurance: false
  },
  wholesale: {
    minOrderQty: 10,
    maxOrderQty: 0,
    price: 100,
    currency: 'INR'
  },
  others: {
    preOrder: false,
    days: 1
  }
}

export const RaenaProductsData = {
  sectionId: '62d03627459536d6fc14ba53',
  sectionName: 'Raena Product',
  sectionType: 'RAENA_PRODUCT',
  sectionOrder: 4,
  links: [
    {
      id: '3d4e08c7-5e47-47b6-8d3d-d58baccd761b',
      resellerId: 'eb0006dd-dd2a-4187-8251-644c5f0eb286',
      title: 'testetetetete',
      url: 'ASD.ASD',
      order: 2,
      type: 'RAENA_PRODUCT',
      imageUrl: 'https://dev-raena-saas-image.s3.ap-southeast-1.amazonaws.com/products/fb1c0d5b-0eb7-4d86-9a87-c18eaf915b8f',
      price: 150,
      discountPrice: 500,
      discountPercentage: '50%',
      clicks: 5,
      isPublished: true
    },
    {
      id: '26099d4f-e6da-4f5f-b198-c7151792346d',
      resellerId: 'eb0006dd-dd2a-4187-8251-644c5f0eb286',
      title: 'asd',
      url: 'asd.asd',
      order: 2,
      type: 'RAENA_PRODUCT',
      imageUrl: '',
      price: 2222,
      discountPrice: 2222,
      discountPercentage: '50%',
      clicks: 0,
      isPublished: true
    },
    {
      id: '5d716adb-d9fc-4598-945d-4bd84f50f8aa',
      resellerId: 'eb0006dd-dd2a-4187-8251-644c5f0eb286',
      title: 'asd',
      url: 'asd.asd',
      order: 1,
      type: 'RAENA_PRODUCT',
      imageUrl: 'https://dev-raena-saas-image.s3.ap-southeast-1.amazonaws.com/products/041c2fea-5d7c-4148-84ce-c066449afd6d',
      price: 9999999,
      discountPrice: 9999999,
      discountPercentage: '50%',
      clicks: 0,
      isPublished: true
    }
  ]
}
