import { useTranslation } from 'react-i18next'

import {
  PaymentDetailsWrapper,
  Header,
  Content,
  MethodHeaderWrapper,
  MethodContentWrapper,
  PaymentCard,
  CardLine,
  TotalAmountPaidH,
  TotalAmountPaid,
  SubAmountH,
  SubAmount,
  TotalOrderIncomeH,
  TotalOrderIncome
} from './style'

const PaymentDetails = ({
  paymentMethod,
  tax,
  commission,
  shippingFee,
  totalAmount,
  orderIncome = 0
}) => {
  const { t } = useTranslation()
  console.log(orderIncome)
  return (
    <PaymentDetailsWrapper>
      <Header>
        {t('orderDetails.paymentDetails')}
      </Header>
      <Content>
        <div>
          <MethodHeaderWrapper>
            {t('orderDetails.paymentMethod')}
          </MethodHeaderWrapper>
          <MethodContentWrapper>
            {paymentMethod}
          </MethodContentWrapper>
        </div>
        <PaymentCard>
          <CardLine>
            <TotalAmountPaidH>
              {t('orderDetails.totalAmountPaid')}
            </TotalAmountPaidH>
            <TotalAmountPaid>
              IDR {totalAmount}
            </TotalAmountPaid>
          </CardLine>
          <CardLine>
            <SubAmountH>
              {t('orderDetails.marketplaceComission')}
            </SubAmountH>
            <SubAmount>
              -IDR {commission}
            </SubAmount>
          </CardLine>
          <CardLine>
            <SubAmountH>
              {t('orderDetails.serviceTax')}
            </SubAmountH>
            <SubAmount>
              -IDR {tax}
            </SubAmount>
          </CardLine>
          <CardLine>
            <SubAmountH>
              {t('orderDetails.shippingFee')}
            </SubAmountH>
            <SubAmount>
              -IDR {shippingFee}
            </SubAmount>
          </CardLine>
          <CardLine>
            <TotalOrderIncomeH>
              {t('orderDetails.totalOrderIncome')}
            </TotalOrderIncomeH>
            <TotalOrderIncome>
              IDR {orderIncome}
            </TotalOrderIncome>
          </CardLine>
        </PaymentCard>
      </Content>
    </PaymentDetailsWrapper>
  )
}

export default PaymentDetails
