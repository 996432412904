import React from 'react'
import {
  Main, Title
} from './style'

const ComingSoon = () => {
  return (
    <Main>
      <Title>
        Coming Soon
      </Title>
    </Main>
  )
}

export default ComingSoon
