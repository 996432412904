import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  TransactionBox,
  OrderId,
  Date,
  Type
} from './style'
import './styles.css'

export const EarningsView = ({ orderId, date, earning, type }) => {
  const { t } = useTranslation()
  const isCredited = type === 'Credit'
  const isCancelled = type === 'Cancelled' || type === 'Debit'
  const isProcessed = type === 'In Process'
  const formattedDate = moment(date).format('D MMM YYYY')
  return (
    <TransactionBox>
      <div>
        <OrderId>{t('connections.Order')} ID: {orderId}</OrderId>
        <Date>{formattedDate}</Date>
      </div>

      <div className='moneyBox'>
        <OrderId>Rp {earning}</OrderId>
        <Type
          style={{
            color: isCredited ? '#0EC690' : isCancelled ? '#F68787' : isProcessed ? '#F4A045' : '#F7F8FF'
          }}
        >
          {type}
        </Type>
      </div>
    </TransactionBox>
  )
}
