import React, { useEffect, useState } from 'react'
import { Grid, Paper } from '@mui/material'
import isEmpty from 'lodash/isEmpty'

import { Main, BoxM } from './style'
import DataTable from './DataTable'
import TitleRow from './TitleRow'
import FilterRow from './FilterRow'
import LoadingSpinner from '../LoadingSpinner'
import BrandsNoSKU from '../BrandsNoSKU'

const BrandCatalouge = (props) => {
  const { categoryList, onGetBrandCategoryList, productTypeList, onGetBrandProductTypeList, onGetBrandCatalougeList, catalougeList, catalougeIsLoading, totalCatalouge, selectedBrand, brandIsLoading, features } = props
  const initialStates = {
    selectedCategory: [],
    selectedProductType: [],
    searchText: '',
    sorting: {},
    page: 0
  }

  const [selectedCategory, setSelectedCategory] = useState(initialStates.selectedCategory)
  const [selectedProductType, setSelectedProductType] = useState(initialStates.selectedProductType)
  const [searchText, setSearchText] = useState(initialStates.searchText)
  const [sorting, setSorting] = useState(initialStates.sorting)
  const [page, setPage] = useState(initialStates.page)

  const resetStates = () => {
    setSelectedCategory(initialStates.selectedCategory)
    setSelectedProductType(initialStates.selectedProductType)
    setSearchText(initialStates.searchText)
    setSorting(initialStates.sorting)
    setPage(initialStates.page)
  }

  useEffect(() => {
    if (!selectedBrand) {
      return
    }
    onGetBrandCategoryList({
      offset: 0,
      limit: 1000,
      brandId: selectedBrand.id
    })

    onGetBrandProductTypeList({
      offset: 0,
      limit: 1000,
      brandId: selectedBrand.id
    })
    resetStates()
  }, [onGetBrandCategoryList, onGetBrandProductTypeList, selectedBrand])

  useEffect(() => {
    if (!selectedBrand) {
      return
    }

    onGetBrandCatalougeList({
      offset: page * 20,
      limit: 20,
      brandId: selectedBrand.id,
      category: selectedCategory.map((item) => item.categoryId),
      productType: selectedProductType.map((item) => item.productType),
      skuOrNameSearch: searchText,
      sort: sorting.sort,
      sortOrder: sorting.sortOrder
    })
  }, [selectedCategory, selectedProductType, searchText, sorting, page])

  const noSKU = () => {
    if (!catalougeIsLoading && isEmpty(catalougeList) && !isFilterApplied()) {
      return true
    }
    return false
  }

  const isFilterApplied = () => {
    if (
      !isEmpty(selectedCategory) ||
      !isEmpty(selectedProductType) ||
      initialStates.searchText !== searchText ||
      initialStates.page !== page) {
      return true
    }
    return false
  }

  return (
    <>
      {brandIsLoading && <LoadingSpinner />}
      {!brandIsLoading &&
      (noSKU()
        ? <BrandsNoSKU />
        : <Main>
          <Paper>
            <BoxM>
              <TitleRow totalCatalouge={totalCatalouge} />
              <FilterRow
                categoryList={categoryList}
                productTypeList={productTypeList}
                selectedCategory={selectedCategory}
                handleCategorySelect={setSelectedCategory}
                selectedProductType={selectedProductType}
                handleProductTypeSelect={setSelectedProductType}
                searchtext={searchText}
                handleSearchTextChange={setSearchText}
                features={features}
              />
              <Grid>
                <DataTable
                  catalougeList={catalougeList}
                  loading={catalougeIsLoading}
                  sortingHandler={setSorting}
                  pageChangehandler={setPage}
                  page={page}
                  totalCatalouge={totalCatalouge}
                />
              </Grid>
            </BoxM>
          </Paper>
          </Main>)}
    </>
  )
}

BrandCatalouge.displayName = 'BrandCatalougeComponent'
export default BrandCatalouge
