import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const LayoutContainer = styled(Box)(`
  background: #F2F7FF;
  display: flex;
`)

export const OuterContent = styled(Box)((props) => `
  height: 100vh;
  width: calc(100% - 200px);
  @media (max-width: 1000px) {
    ${!props.isSidebarVisible && `
      width: 100%;
    `}
  }
`)

export const InnerContent = styled(Box)(props => `
  height: ${props?.hideHeader ? '100vh' : 'calc(100vh - 70px)'};
  overflow: auto;
  ${props?.hideHeader && `
    & > div {
      padding-top:0;
    }
  `}
`)
