import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'
import quote from '../../assets/images/homepage/quote.svg'

export const TitleOuterWrapper = styled(Box)(`
    padding-top:45px;
`)

export const TitleInnerWrapper = styled(Box)(`
    display: flex;
    flex-direction: column;
    align-items: center;
    & .autoPlaySwipeableViews{
        position:relative;
        padding:20px 0;
    }

    & .autoPlaySwipeableViews:before{
        content:'';
        background:url(${quote}) no-repeat;
        width:55px;
        height:40px;
        position: absolute;
        top: 0;
        left: 0;
    }

    & .autoPlaySwipeableViews:after{
        content:'';
        background:url(${quote}) no-repeat;
        width:55px;
        height:40px;
        position: absolute;
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
    }

    & .mobileStepper {
        background:transparent;
        padding-top:30px;
    }
    & .mobileStepper .MuiMobileStepper-dot{
        margin: 0 5px;
    }
`)

export const TitlePrimary = styled(Typography)(`
    color: #212121;
    font-size: 35px;
    font-weight: 600;
    margin-bottom:40px;
    position:relative;
    
    &:before {
        content:'';
        position:absolute;
        bottom:-20px;
        left:calc(50% - 40px);
        width:80px;
        height:4px;
        border-radius:6px;
        background:#1d2235;
        display:inline-block;
    }
`)

export const TitleSecondary = styled(Typography)(`
    font-size: 22px;
    color: #222;
    text-align:center;
    margin-bottom: 10px;
    width:80%;
    @media (max-width: 1000px) {
        width:100%;
    }
`)

export const TitleImagesWrapper = styled(Box)(`
    display: flex;
    width: 100%;
    justify-content:center;
    align-items:center;
    flex-wrap: wrap;
    margin-top: 10px;
    & img{
        max-height:90px;
        padding:15px;
    }
`)
