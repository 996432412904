import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import WebRoutes from './routes'
import AppWrapper from './components/AppWrapper'
import ErrorBoundary from './components/ErrorBoundary'

import reportWebVitals from './reportWebVitals'
import { store, persistor } from './store'

import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'

import { DEFAULT_LANG } from './constants'
import './i18n'
import './utilities/analytics'

const getRoot = () => {
  if (!!window && !!window.localStorage && !window.localStorage.getItem('i18nextLng')) {
    window.localStorage.setItem('i18nextLng', DEFAULT_LANG)
  }
  return document.getElementById('root')
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppWrapper>
            <ThemeProvider theme={theme}>
              <WebRoutes />
            </ThemeProvider>
          </AppWrapper>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  getRoot()
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
