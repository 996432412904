import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  SearchWrapper,
  SearchIcon,
  SearchInput
} from './style'
import searchIcon from '../../assets/images/search.svg'
import CloseIcon from '@mui/icons-material/Close'

const Search = ({
  value: propsValue = '',
  placeholder = 'Search',
  onInput = () => {},
  onEnter = () => {},
  setIsFilterApplied = () => null,
  displayCloseIcon
  // onClickCloseIcon
}) => {
  const [value, setValue] = useState(propsValue)
  const inputHandler = (event) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    setValue(propsValue)
  }, [propsValue])

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      setIsFilterApplied(true)
      onEnter(event.target.value)
    }
  }
  const closeSearchHanlder = (value) => {
    setValue('')
    onEnter()
  }
  const searchHandler = (e) => {
    const value = document.getElementById('searchinputName')?.value?.length
    if (value === 0) {
      setValue('')
      onEnter()
    }
  }

  return (
    <SearchWrapper>
      <SearchIcon src={searchIcon} alt='Search' />
      <SearchInput
        placeholder={placeholder} onInput={inputHandler}
        tabIndex='0' onKeyDown={keyDownHandler} value={value} onChange={searchHandler} id='searchinputName'
      />
      {!!value &&
        <CloseIcon
          onClick={closeSearchHanlder}
          style={{
            height: '20px',
            width: '20px',
            padding: '1px',
            display: displayCloseIcon,
            // boxShadow: '0px 2px 10px rgb(0 0 0 / 10%)',
            borderRadius: '4px',
            marginRight: '10px',
            opacity: '0.7'
          }}
        />}
    </SearchWrapper>
  )
}

Search.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func
}

Search.displayName = 'Search'
export default Search
