import styled from 'styled-components'

export const Main = styled.div`
  text-align: center;
  margin: 0px;
  padding-bottom: 30px;
`

export const Imagetag = styled.img`
  height: 150px;
  margin-top: -23px;
  margin-bottom: 2vh;
`
export const Text = styled.p`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: center;
  margin-top: unset;
`
export const BtnConnect = styled.button`
  height: 40px;
  width: 200px;
  border-radius: 6px;
  background-color: #162E9A;
  color: #FFFFFF;
  font-size: 15px;
  margin-bottom: 5vh;
  border: 0px solid;
  font-weight: 500;
`
export const Textsmall = styled.p``
export const BtnCreate = styled.button`
  height: 41px;
  width: 250px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  color: #fff;
  background-color: #162E9A;
  margin: 20px 40px 0 0;

  @media (max-width: 618px) {
    margin: 20px 0px 0 0;
  }
`

export const BtnCreateInstantStore = styled.button`
  height: 40px;
  width: 250px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  margin: 20px;
  background-color: #FFFFFF;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.350579);
`
