import React from 'react'

import Layout from '../Layout'
import BrandCatalougeComponent from '../../components/BrandsCatalogue/BrandCatalouge'
import { connect } from 'react-redux'
import { BRAND_CATEGORIES_GET_LIST, BRAND_PRODUCT_TYPE_GET_LIST, BRAND_CATALOUGE_GET_LIST } from '../../actions'

const BrandCatalouge = (props) => {
  return (
    <Layout>
      <BrandCatalougeComponent {...props} />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { brandsCatalogue, brands, subscription } = state
  return {
    categoryList: brandsCatalogue.get('categoryList'),
    productTypeList: brandsCatalogue.get('productTypeList'),
    catalougeList: brandsCatalogue.get('catalougeList'),
    catalougeIsLoading: brandsCatalogue.get('catalougeIsLoading'),
    totalCatalouge: brandsCatalogue.get('totalCatalouge'),
    selectedBrand: brands.get('selectedBrand'),
    brandIsLoading: brands.get('isLoading'),
    features : subscription.get('features')
  }
}

const mapDispatchToProps = dispatch => ({
  onGetBrandCategoryList: (query) => {
    dispatch({ type: BRAND_CATEGORIES_GET_LIST, payload: query })
  },
  onGetBrandProductTypeList: (query) => {
    dispatch({ type: BRAND_PRODUCT_TYPE_GET_LIST, payload: query })
  },
  onGetBrandCatalougeList: (query) => {
    dispatch({ type: BRAND_CATALOUGE_GET_LIST, payload: query })
  }
})

BrandCatalouge.displayName = 'BrandCatalougeContainer'
export default connect(mapStateToProps, mapDispatchToProps)(BrandCatalouge)
