import { styled } from '@mui/system'
import { Box, TextField } from '@mui/material'

export const FormLabel = styled('label')(`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
  width:100%;
  margin-bottom: 10px;
`)

export const FormReq = styled('span')(`
  font-size: 12px;
  color: #DA2A52;
  padding-left:5px;
`)

export const TextFieldStyled = styled(TextField)(`
  & div > textarea {
    font-size: 14px;
    resize: vertical;
  }
  & div > textarea::placeholder {
    font-size: 14px;
  }
  & div > input {
    font-size: 14px;
  }
  & div > input::placeholder {
    font-size: 14px;
  }
`)

export const IconWrap = styled(Box)(({ theme }) => `
  width:40px;
  height:40px;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    max-width:20px;
    max-height:20px;
  }
`)

export const TextFieldWrapper = styled(Box)(({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items:flex-start;
  border-radius: 10px;
  margin-bottom: 8px;
  & > div {
    margin: 0;
  }
  & > div > div  {
    background-color: ${theme?.components?.MuiInput?.styleOverrides?.input?.backgroundColor || '#f9f9f9'};
  }
`)

export const FormError = styled(Box)(`
  width:100%;
  display:block;
  line-height: 20px;

  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
  background-color: transparent !important;
`)
