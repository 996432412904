import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import Joi from 'joi'
import { serializeError } from 'serialize-error'
import { useTranslation } from 'react-i18next'

import AuthLayout from '../AuthLayout'
import EventEmitter, { Events } from '../../utilities/eventEmitter'

import {
  Button,
  Form,
  Input,
  BottomLinkHref,
  BottomLinkWrapper,
  Title,
  AuthParent,
  LoginImageWrapper,
  LoginImage,
  LeftSideContainer,
  ImageWrapperText
} from '../AuthLayout/style'

import { ROUTE_LOGIN } from '../../constants'
import LoginImageLeft from '../../assets/images/login.svg'

// There are three Possible Screens here
// Send OTP
// Verify OTP
// Add profile + Personal details

const SignUp = props => {
  const {
    error,
    email: propsEmail,
    getOTP,
    isExistingUser = false,
    // isLoading,
    isOTPSent,
    isOTPVerified = false,
    isProfileSet,
    name: propsName,
    setPersonalProfile,
    userId = '',
    verifyOTP,
    token = ''
  } = props
  const { t } = useTranslation()
  const [mobile, setMobile] = useState('')
  const [otp, setOTP] = useState('')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [waitForOTP, setWaitForOTP] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (isOTPSent) {
      toast.info(t('auth.otpSent'))
      setWaitForOTP(false)
    }
  }, [isOTPSent, t])

  useEffect(() => {
    if (isOTPVerified) {
      toast.info(t('auth.otpVerified'))
      EventEmitter.emit(Events.ENTER_OTP)
    }
  }, [isOTPVerified, t])

  useEffect(() => {
    if (isProfileSet) {
      toast.info(`${t('auth.passwordSet')}!`)
      navigate(ROUTE_LOGIN)
    }
  }, [isProfileSet, navigate, t])

  useEffect(() => {
    setWaitForOTP(false)
    if (error) toast.error(error)
  }, [error])

  useEffect(() => {
    if (propsEmail) setEmail(propsEmail)
  }, [propsEmail])

  useEffect(() => {
    if (propsName) setName(propsName)
  }, [propsName])

  const renderFormTitle = () => <Title>{t('auth.pleaseSignUp')}</Title>

  const CircularProgres = () => <CircularProgress style={{ color: '#fff', width: '18px', height: '18px', margin: '-5px 10px 0 0' }} />

  const getOTPFormInput = Joi.object({
    mobile: Joi.string().pattern(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/).message({
      'string.pattern.base': 'Invalid Mobile Number'
    })
  })

  const handleGetOTPFormSubmit = async e => {
    e.preventDefault()
    let updatedMobile = mobile

    if (updatedMobile.substring(0, 1) === '0') {
      updatedMobile = updatedMobile.substring(1)
    }

    if (updatedMobile.substring(0, 1) !== '+') {
      updatedMobile = `+62${updatedMobile}`
    }

    const data = { mobile: updatedMobile }

    const response = await getOTPFormInput.validate(data)
    const { message: errorMessage } = serializeError(response?.error || {})

    if (errorMessage) {
      return toast.error(errorMessage)
    }

    // setWaitForOTP(true)
    getOTP({ mobile: updatedMobile })
  }

  const renderGetOTPForm = () => (
    <Form onSubmit={handleGetOTPFormSubmit}>
      <Input
        id='mobile'
        name='mobile'
        onChange={e => setMobile(e.target.value)}
        placeholder={t('auth.mobile')}
        type='mobile'
        value={mobile}
      />
      <Button type='submit'>
        {waitForOTP && !isOTPSent && <CircularProgres />}
        {t('auth.getOtp')}
      </Button>
    </Form>
  )

  const verifyOTPFormInput = Joi.object({
    otp: Joi.string().length(6)
  })

  const handleVerifyOTPFormSubmit = async e => {
    e.preventDefault()

    const data = { otp }
    const response = await verifyOTPFormInput.validate(data)
    const { message: errorMessage } = serializeError(response?.error || {})

    if (errorMessage) return toast(errorMessage)

    setWaitForOTP(true)
    verifyOTP({ mobile, otp, isExistingUser })
  }

  const renderVerifyOTPForm = () => (
    <Form onSubmit={handleVerifyOTPFormSubmit}>
      <Input
        id='otp'
        name='otp'
        onChange={e => setOTP(e.target.value)}
        placeholder='123456'
        type='text'
        value={otp}
      />
      <Button type='submit'>
        {waitForOTP && !isOTPVerified && <CircularProgres />}
        {t('auth.verifyOtp')}
      </Button>
    </Form>
  )

  const personalDetailsFormInput = Joi.object({
    email: Joi.string().pattern(/\S+@\S+\.\S+/).message({
      'string.pattern.base': t('auth.invalidEmail')
    }),
    name: Joi.string().min(4),
    password: Joi.string().min(4),
    confirmPassword: Joi.any().equal(Joi.ref('password')).required()
      .label(t('auth.password'))
      .messages({ 'any.only': `{{#label}} ${t('auth.doesNotMatch')}` })
  })

  const handlePersonalDetailsFormSubmit = async e => {
    e.preventDefault()

    const data = { name, email, password, confirmPassword }
    const response = await personalDetailsFormInput.validate(data)
    const { message: errorMessage } = serializeError(response?.error || {})

    if (errorMessage) {
      return toast.error(errorMessage)
    }

    setPersonalProfile({ name, email, password, confirmPassword, userId, token })
  }

  const renderPersonalDetailsForm = () => (
    <Form onSubmit={handlePersonalDetailsFormSubmit}>
      <Input
        id='name'
        name='name'
        onChange={e => setName(e.target.value)}
        placeholder={t('auth.name')}
        type='name'
        value={name}
      />
      <Input
        id='email'
        name='email'
        onChange={e => setEmail(e.target.value)}
        placeholder={t('auth.email')}
        type='email'
        disabled={!!propsEmail}
        value={email}
      />
      <Input
        id='password'
        name='password'
        onChange={e => setPassword(e.target.value)}
        placeholder={t('auth.password')}
        type='password'
        value={password}
      />
      <Input
        id='confirmPassword'
        name='confirmPassword'
        onChange={e => setConfirmPassword(e.target.value)}
        placeholder={t('auth.confirmPassword')}
        type='password'
        value={confirmPassword}
      />
      <Button type='submit'>{t('auth.submit')}</Button>
    </Form>
  )

  const renderForm = () => {
    if (isOTPVerified) return renderPersonalDetailsForm()
    if (!isOTPSent) return renderGetOTPForm()
    if (isOTPSent) return renderVerifyOTPForm()
  }

  const renderBottomLink = () => (
    <BottomLinkWrapper>
      {t('auth.alreadyAUser')}?
      <BottomLinkHref to={ROUTE_LOGIN}>
        {t('auth.signIn')}
      </BottomLinkHref>
    </BottomLinkWrapper>
  )

  return (
    <AuthParent>
      <LeftSideContainer>
        <LoginImageWrapper>
          <LoginImage src={LoginImageLeft} />
        </LoginImageWrapper>
        <ImageWrapperText>{t('auth.imageText')}!</ImageWrapperText>

      </LeftSideContainer>
      <AuthLayout>
        {renderFormTitle()}
        {renderForm()}
        {renderBottomLink()}
      </AuthLayout>
    </AuthParent>
  )
}

SignUp.propTypes = {
  email: PropTypes.string,
  error: PropTypes.string,
  getOTP: PropTypes.func,
  isLoading: PropTypes.bool,
  isOTPSent: PropTypes.bool,
  isOTPVerified: PropTypes.bool,
  isProfileSet: PropTypes.bool,
  isExistingUser: PropTypes.bool,
  mobile: PropTypes.string,
  name: PropTypes.string,
  setPersonalProfile: PropTypes.func,
  userId: PropTypes.string,
  verifyOTP: PropTypes.func
}

SignUp.displayName = 'SignUpComponent'
export default SignUp
