import { styled } from '@mui/system'
import { Chip, Typography, Grid, List, ListItem } from '@mui/material'

export const PricingCardWrapper = styled(Grid)(`
    padding:30px 0 15px;
`)

export const CardTitle = styled(Chip)((props) => `
    padding:0;
    background-color:${props?.bgColor || 'inherit'} !important;
    margin:-15px 0 0 -15px;
    font-weight: normal;
    font-size: 20px;
    height: 38px;
    border-radius: 20px;
`)

export const CardWrapper = styled(Grid)(`
    width:calc(30% - 20px);
    border: 1px solid rgba(60, 77, 116, 0.4);
    box-sizing: border-box;
    box-shadow: 0px 1px 20px rgba(136, 150, 203, 0.114374);
    border-radius: 20px;
    overflow:hidden;
    padding:20px;
    min-width: 300px;
    margin-bottom: 30px;

    & > div {
      boxShadow:none;
    }
    & a {
      width:100%;
      border-radius:15px;
    }
`)

export const CardList = styled(List)(`
    height:290px;
    overflow:auto;
`)

export const CardPricingCard = styled(Grid)(`
    position:relative;
    margin:15px 0;
    flex-wrap:wrap;
`)

export const CardOffers = styled(Grid)(`
    background: linear-gradient(270deg, rgba(18, 143, 34, 0.0001) 0%, #1C9C0D 100%);
    border-radius: 6px;
    font-size: 20px;
    line-height: 36px;
    padding-left: 10px;
    color: #FFFFFF;
    height:36px;
    margin-top:10px;
`)

export const CardAmount = styled(Typography)((props) => `
    font-weight: 600;
    font-size: ${props?.offer ? '20px' : '30px'};
    line-height: 1;
    color: #212121;
    text-decoration: ${props?.offer ? 'line-through' : 'none'};
`)

export const CardCurrency = CardAmount

export const CardTenure = styled(Typography)(`
    width:100%;
    display:block;
    font-size: 17px;
    line-height: 23px;
    color: #727272;
`)

export const CardListing = styled(Typography)(`
    font-weight: 400;
    font-size: 15px;
    line-height: 29px;
    color: #212121;
`)

export const ListText = styled(Typography)(`
    font-weight: 400;
    font-size: 15px;
    line-height: 29px;
    color: #212121;
    padding-left: 10px;
    line-height: 18px;
`)

export const ListItemNew = styled(ListItem)(`
    padding:10px 0;
    display: flex;
    align-items: flex-start;
`)
