/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import {
  TextTitle,
  Separator,
  ImgIconInfo,
  AddOfferButton,
  BorderContainer,
  CreateTitle,
  AddSkuButton,
  ImgIconAddSku,
  ImgIconDelteSku
} from './styles';
import MuiSwitch from '../MuiSwitch';
import { iconInfo, iconAddSku, deleteIcon } from '../../assets/images/icons';
import SelectSkuComponent from './SelectSkuComponent';
import { ALL, ANY, SINGLE, SAME } from '../../constants/brandPromo';
import FreeSKUComponent from './FreeSkuComponent';
import { getEmptySku } from '../../utilities/utils';

const BundleComponent = (props) => {
  const { currentBrand, formik, baseSku, setBaseSku, freeSku, setFreeSku, skuList, disable } = props;
  const { t } = useTranslation();

  const [baseSkuCriteria, setBaseSkuCriteria] = useState((baseSku?.length > 0 && baseSku[0]?.selectionCriteria) || ANY);
  const [freeSkuCriteria, setFreeSkuCriteria] = useState((freeSku?.length > 0 && freeSku[0]?.selectionCriteria) || ALL);
  const [showSku2, toggleSku2] = useState(baseSku?.length > 1);
  const [showAddSku, setShowAddSku] = useState(true);
  const [sku2List, setSku2List] = useState([...skuList]);
  const [showFreeSku2, setShowFreeSku2] = useState(freeSku.map((item)=>{ return item?.promoSkus?.length > 1}));

  useEffect(()=> {
    formik.setFieldValue('paidsku', '');
    formik.setFieldValue('paidsku2','');

  }, [baseSkuCriteria]);

  const baseCriteriaHandler = (e, type) => {
    if (e) {
      setBaseSkuCriteria(type);
      handleUpdateSku(type, setBaseSku)
      if(type === ANY && freeSkuCriteria === SAME){
        setFreeSkuCriteria(ALL)
      }
    } else {
      let selectedType = ALL;
      if(type === ANY){
        selectedType = SINGLE
      } else if (type === SINGLE){
        selectedType = ANY
      }
      setBaseSkuCriteria(selectedType);
      handleUpdateSku(selectedType, setBaseSku)
    }
   
    formik.setFieldValue(
      'selectionCriteria',
      e ? type : ALL,
    );
  };

  const freeCriteriaHandler = (e, type) => {
    if (e) {
      setFreeSkuCriteria(type);
      handleUpdateSku(type, setFreeSku)
    } else {
      setFreeSkuCriteria(ALL);
      handleUpdateSku(ALL, setFreeSku)
    }
   
    formik.setFieldValue(
      'freeSelectionCriteria',
      e ? type : ALL,
    );
  };

  const handleUpdateSku = (skuCriteria, updateSku ) => {
    if (skuCriteria === ANY) {
      const sku = {
        brandId: currentBrand?.id,
        qty: [],
        selectionCriteria: skuCriteria,
      };
      updateSku([{...sku}]);
    } else if (skuCriteria === SAME) {
      const sku = {
        qty: [],
        selectionCriteria: skuCriteria,
      };
      updateSku([{...sku}]);
    } else {
      updateSku([getEmptySku(skuCriteria)]);
    }
  }

  const handleAddSku = () => {
    toggleSku2(!showSku2);
    formik.setFieldValue(
      'showSku2',
      !showSku2,
    );
    if(freeSkuCriteria === SAME){
      setFreeSkuCriteria(ALL)
    }
  };

  const handleRemoveSku = () => {
    toggleSku2(!showSku2);
    formik.setFieldValue(
      'showSku2',
      !showSku2,
    );

    if(baseSku?.length > 1) {
      baseSku.splice(1, 1);
      setBaseSku(baseSku);
      formik.setFieldValue('paidsku2','');

    }

  }

  const handleAddOffer = () => {
    freeSku.push(getEmptySku(freeSkuCriteria));
    setFreeSku([...freeSku]);
  };

  const getUpdatedList = () => {
    if(baseSku?.length > 0){
      const updatedList = skuList.filter(el => {
        return !baseSku[0].promoSkus?.find(element => {
          return element.sku === el.id;
        });
      }).map((item)=> {
          return {...item,isSelected: false}
      });

      if(updatedList?.length > 1){
        setShowAddSku(true)
        setSku2List(updatedList);
      } else {
        setShowAddSku(false)
      }

      if(baseSku?.[1]?.promoSkus?.length > 0){
        baseSku[1].promoSkus = [];
        setBaseSku(baseSku);
        formik.setFieldValue('paidsku2','');
      }
    } else {
      setSku2List(skuList);
    }
  }

  const renderPaidSku = () => (
    <SelectSkuComponent
      {...props}
      formikId="paidsku"
      formikValue={formik.values.paidsku}
      margintop="16px"
      label={t('createPromo.selectSku1')}
      skuCriteria={baseSkuCriteria}
      formikTouch={formik.touched.paidsku}
      formikError={formik.errors.paidsku}
      sku={baseSku}
      updateSku={setBaseSku}
      index={0}
      updateSkuList={getUpdatedList}
      disable={disable}
    />);

  const paidSkuComponent = renderPaidSku();

  return (
    <Grid>
      <Grid container={true} rowSpacing={1} marginTop="20px">
        <Grid item={true} xs={3}>
          <TextTitle>{t('createPromo.selectPaidSku')}</TextTitle>
        </Grid>
        <Grid item={true} xs={3}>
          <MuiSwitch
            value={baseSkuCriteria === ANY}
            bgColor="#75BD27"
            name="anyBaseSKU"
            type="ios"
            label={t('createPromo.anySKU').replace('{brandName}', currentBrand?.name)}
            onChange={(e) => baseCriteriaHandler(e, ANY)}
            disable={disable}
          />
        </Grid>
        <Grid item={true} xs={3}>
          <MuiSwitch
            value={baseSkuCriteria === SINGLE}
            bgColor="#75BD27"
            name="multiCheck"
            type="ios"
            label={t('createPromo.multiCheck')}
            onChange={(e) => baseCriteriaHandler(e, SINGLE)}
            disable={disable}
          />
        </Grid>
      </Grid>

      {
        baseSkuCriteria !==ANY && showAddSku
          ? showSku2 || baseSku?.length > 1
            ? (
              <Grid container={true}>
                {paidSkuComponent}
                <CreateTitle marginleft="10px" margintop="63px">+</CreateTitle>
                <SelectSkuComponent
                  {...props}
                  formikId="paidsku2"
                  formikValue={formik.values.paidsku2}
                  formikTouch={formik.touched.paidsku2}
                  formikError={formik.errors.paidsku2}
                  margintop="16px"
                  marginleft="16px"
                  skuList={sku2List}
                  label={t('createPromo.selectSku2')}
                  skuCriteria={baseSkuCriteria}
                  sku={baseSku}
                  updateSku={setBaseSku}
                  index={1}
                  disable={disable || !baseSku?.[0]?.promoSkus?.length}
                />
                {!disable && <ImgIconDelteSku src={deleteIcon} onClick={handleRemoveSku} margintop='69px' />}
              </Grid>
            )
            : (
              <Grid container={true}>
                {paidSkuComponent}
                {
                  !disable && (
                    <AddSkuButton onClick={handleAddSku} height={'fit-content'} margintop='67px'>
                      <ImgIconAddSku src={iconAddSku} />
                      {t('createPromo.addSku')}
                      </AddSkuButton>
                  )
                }
              </Grid>
            )
          : paidSkuComponent
      }

      <Separator />
      <Grid container={true} rowSpacing={1} marginTop="10px">
        <Grid item={true} xs={3}>
          <Grid>
            <TextTitle>{t('createPromo.freeSkuOptions')}</TextTitle>
          </Grid>
        </Grid>
        <Grid item={true} xs={3}>
          <MuiSwitch
            value={freeSkuCriteria === ANY}
            bgColor="#75BD27"
            name="anyFreeSKU"
            type="ios"
            label={t('createPromo.anySKU').replace('{brandName}', currentBrand?.name)}
            onChange={(e) => freeCriteriaHandler(e, ANY)}
            disable={disable}
          />
        </Grid>
        {
          baseSkuCriteria !== ANY && !showSku2 && (
            <Grid item={true} xs={3}>
              <MuiSwitch
              value={freeSkuCriteria === SAME}
              bgColor="#75BD27"
              name="sameAsPaidSku"
              type="ios"
              label={t('createPromo.sameAsPaidSku')}
              onChange={(e) => freeCriteriaHandler(e, SAME)}
              disable={disable}
            />
          </Grid>
          )
        }
      </Grid>

      <BorderContainer>
        {
          freeSku?.map((sku, index) => (
            <div key={index}>
              <FreeSKUComponent
              freeSkuCriteria={freeSkuCriteria}
              index={index}
              {...props}
              showFreeSku2={showFreeSku2}
              setShowFreeSku2={setShowFreeSku2}
              />
            </div>
          ))
        }
        {
          ( freeSkuCriteria !== ANY && freeSkuCriteria !== SAME ) && (
            <AddOfferButton onClick={handleAddOffer} disabled={disable}>
              {t('createPromo.addAnotherOffer')}
            </AddOfferButton>
          )
        }
      </BorderContainer>

    </Grid>

  );
};

BundleComponent.propTypes = {
  currentBrand: PropTypes.shape({
    name: PropTypes.string,
  }),
  formik: PropTypes.shape({}),
  baseSku: PropTypes.arrayOf(PropTypes.shape({})),
  setBaseSku: PropTypes.func,
  freeSku: PropTypes.arrayOf(PropTypes.shape({})),
  setFreeSku: PropTypes.func,
  skuList: PropTypes.arrayOf(PropTypes.shape({})),
  disable: PropTypes.bool
};

BundleComponent.defaultProps = {
  currentBrand: {},
  formik: null,
  baseSku: null,
  setBaseSku: null,
  freeSku: null,
  setFreeSku: null,
  skuList: [],
  disable: false
};

export default BundleComponent;
