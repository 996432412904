import React from 'react';
import { MenuItem } from '@mui/material';
import {
    SelectField
  } from './styles'

const CreateSelect = (props) =>{
   const {formikValue, handleChange, handleBlur, listData, formikId , textLabel, helper, errorText, inputType, defaultText, disabale} = props;    
   return(
    <>
    {inputType === 'select' && (
        <SelectField
        select
        id={formikId}
        label={textLabel}
        value={formikValue}
        onChange={handleChange(formikId)}
        onBlur={handleBlur}
        helperText={helper}
        error={errorText}
        margin="normal"
        variant="outlined"
        name={textLabel}
        defaultValue=''
        disabled={disabale}
        >
        {listData.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </SelectField>
    )}
    {inputType !== 'select' && (
        <SelectField
        type={inputType}
        id={formikId}
        label={textLabel}
        value={formikValue}
        onChange={handleChange(formikId)}
        onBlur={handleBlur}
        helperText={helper}
        error={errorText}
        margin="normal"
        variant="outlined"
        name={textLabel}
        placeholder={defaultText}     
      >
      </SelectField>
    )}
    </>
   )
};

export default CreateSelect;