import TextField from '@mui/material/TextField'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { FormLabel, DatePickerWrapper, FormReq } from './style'

const MuiDatePicker = (props) => {
  const Req = () => {
    return (
      <>
        {props.isRequired && <FormReq>*</FormReq>}
      </>
    )
  }
  return (
    <>
      {props.title && <FormLabel>{props.title}<Req /></FormLabel>}
      <DatePickerWrapper>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...props}
            renderInput={(params) => <TextField {...params} error={props?.error || false} size='small' />}
          />
        </LocalizationProvider>
      </DatePickerWrapper>
    </>
  )
}

export default MuiDatePicker
