import { call, put, takeLatest } from 'redux-saga/effects'

import {
  PRODUCTS_LISTED_ON_LOADING,
  PRODUCTS_GET_LISTED_ON,
  PRODUCTS_GET_LISTED_ON_SUCCESS,
  PRODUCTS_GET_LISTED_ON_FAILURE,
  PRODUCTS_POST_LISTED_ON_UPDATEPRIZE,
  // PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE,
  PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_FAILURE,
  PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_SUCCESS
} from '../actions'

import { API_GET_PRODUCTS_LISTED_ON, API_PATCH_PRODUCTS_LISTED_ON } from '../constants'
import { getApi, patchApi } from '../utilities/axios'

function * onPostProductListedOnPrize (action) {
  // yield put({ type: PRODUCTS_LISTED_ON_LOADING, isLoading: true })
  try {
    const { id, obj } = action?.payload
    yield call(patchApi, API_PATCH_PRODUCTS_LISTED_ON(id), obj)
    yield put({ type: PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_SUCCESS, data: obj.price || {} })
  } catch (e) {
    // console.log(e, 'e.')
    yield put({ type: PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_FAILURE, message: e.message })
    yield put({ type: PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_FAILURE, message: '' })
    yield put({ type: PRODUCTS_POST_LISTED_ON_UPDATE_PRIZE_SUCCESS, data: {} })
  }
  // yield put({ type: PRODUCTS_LISTED_ON_LOADING, isLoading: false })
}
function * onGetProductListedOn (action) {
  yield put({ type: PRODUCTS_LISTED_ON_LOADING, isLoading: true })
  try {
    // console.log(action?.payload?.id)
    const response = yield call(getApi, API_GET_PRODUCTS_LISTED_ON(action?.payload?.id))
    // console.log(response)
    yield put({ type: PRODUCTS_GET_LISTED_ON_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    console.log(e)
    yield put({ type: PRODUCTS_GET_LISTED_ON_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: PRODUCTS_GET_LISTED_ON_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: PRODUCTS_GET_LISTED_ON_SUCCESS, data: {} })
  }
  yield put({ type: PRODUCTS_LISTED_ON_LOADING, isLoading: false })
}

function * productsListedOnSaga () {
  yield takeLatest(PRODUCTS_GET_LISTED_ON, onGetProductListedOn)
  yield takeLatest(PRODUCTS_POST_LISTED_ON_UPDATEPRIZE, onPostProductListedOnPrize)
}

export default productsListedOnSaga
