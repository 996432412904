import { Map } from 'immutable'

import {
  BRAND_GET_LIST_SUCCESS,
  BRAND_GET_LIST_FAILURE,
  BRAND_GET_LIST_LOADING,
  BRAND_SELECT,
  BRAND_ERROR_DISPLAYED
} from '../actions'

const initialState = new Map({
  brandsList: [],
  selectedBrand: null,
  isLoading: false,
  error: false,
  fetched: false,
  errorDisplayed: false
})

export const brands = (state = initialState, action) => {
  const {
    data,
    message,
    type,
    isLoading,
    selected
  } = action

  switch (type) {
    case BRAND_GET_LIST_LOADING:
      return state.set('isLoading', isLoading)
    case BRAND_GET_LIST_SUCCESS:
      return state.merge({
        brandsList: data,
        selectedBrand: data[0],
        fetched: true
      })
    case BRAND_GET_LIST_FAILURE:
      return state.merge({
        fetched: true,
        error: message
      })
    case BRAND_SELECT:
      return state.merge({
        selectedBrand: state.get('brandsList').filter((item) => item.id === selected)[0]
      })
    case BRAND_ERROR_DISPLAYED:
      return state.merge({
        errorDisplayed: true
      })
    default:
      return state
  }
}
