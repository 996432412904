import { styled } from '@mui/system'
import { Box, Grid, Paper, LinearProgress } from '@mui/material'

const media = {
  large_laptop: '@media(max-width: 2560px)',
  laptop: '@media(max-width: 1000px)',
  tablet: '@media(max-width: 768px)',
  mobile: '@media(max-width: 600px)'
}

export const Main = styled('div')(`
`)
export const DivWraper = styled('div')(`
  margin: 3vh 2% 3vh;
`)
export const DashboardTitle = styled('h1')`
  height: 41px;
  font: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 41px;
  color: #360D20;
  margin: unset;
  ${media.tablet}{
  font-size: 24px;
}
`
export const Titlebottom = styled('p')`
    font-size: 12px;
    color: #7D86A9;
`
export const BoxM = styled(Box)(`
`)
export const GridM = styled(Grid)(`
${media.tablet}{
  &.center{
    justify-content: center !important;
  }
}
`)
export const ItemM = styled(Paper)(`
margin: 15px 25px;
font-size: 30px;
text-align: left;
font-weight: 600;
padding: 1.4rem 1.4rem;
height: 12vh;
${media.large_laptop}{
  height: 10vh;
}
${media.laptop}{
  height: 13vh;
}
${media.tablet}{
  height: 13vh;
  font-size: 19px;
}
`)

export const TextP = styled('p')`
font-size: 18px;
font-weight: 400;
margin: 1.7vh 0px 0px 0px;
`
export const LinearProgressM = styled(LinearProgress)`
`
export const ItemProgress = styled(Paper)(`
height: 12vh;
margin: 15px 25px;
padding: 0.4rem 1.4rem;
height: auto;
`)
export const Itemheading = styled('h3')(`
font-size: 1.7rem;
font-weight: normal;
margin: unset;
margin-top: 1vh;
`)
export const ItemProgressp = styled('p')(`
font-size: 12px;
color: #7D86A9;
`)
export const HelpWraper = styled('p')(`
font-size: 2rem;
margin: 10vh 0px 0px;
`)
export const TextHelperW = styled('p')(`

`)
export const ImageWraper = styled('img')(`
float: right;
margin: 10px 0px;
`)
export const BtnWraper = styled('div')(`
margin-bottom:4vh;
`)
export const TableWraper = styled(Paper)(`
height: 12vh;
margin: 15px 25px;
padding: 0.4rem 1.4rem;
height: auto;
`)
