import styled from 'styled-components'

export const GraphWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    display: block;
  }
`
export const SummaryGraphh = styled.div`
  background: #FFF;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;

  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }
`

export const HeadingWrapper = styled.div`
  display:flex;
  justify-content: space-between;
`

export const Heading = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
`

export const HamburgerIcon = styled.img`
  cursor: pointer;
`
export const GrowthWrapper = styled.div`
  color: ${({ green }) => green ? '#51C03E' : '#F64A3F'};
  font-size: 10px;
`

export const GrowthText = styled.div`
  margin: -16px 0 0 20px;
`
