import { useState, useEffect } from 'react'
import queryString from 'query-string'

import Search from '../Search'
import Loading from '../LoadingSpinner'
import { PageBuilderLinksInnerCard } from '../PageBuilderLinksCard'
import { ROUTE_LINKSTORE_RAENAPRODUCTS, ROUTE_LINKSTORE_RAENAPRODUCTS_ADD } from '../../constants'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  ProductListWrapper,
  ProductTopBar,
  TitleWrapper,
  Title,
  BackButton,
  ProductCardWrapper,
  SearchWrapper,
  NoRecordWraper
} from './style'
import ResuablePagination from '../ReuseablePagination'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

const PageBuilderRaenaProducts = (props) => {
  const {
    error,

    onGetPageBuilderRaenaProductsUpdateData,
    pageBuilderRaenaProductsUpdateData,
    pageBuilderRaenaProductsUpdateIsLoading,

    onGetPageBuilderRaenaProductAllList,
    pageBuilderRaenaProductsAllIsLoading,
    pageBuilderRaenaProductsAllList,

    onGetUserInfo
  } = props

  const { t } = useTranslation()

  const labelSelectorOptions = 'published'

  const queryParams = queryString.parse(window.location.search)
  const queryParamString = queryString.stringify(queryParams)

  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(20)
  const [labelSelectedValue, setLabelSelectedValue] = useState(queryParams?.tab || labelSelectorOptions)
  const [productAllRecords, setProductAllRecords] = useState(0)
  const [totalRecords, setTotalRecords] = useState()
  const shareId = useSelector((state) => state.auth.get('shareId'))
  const tierId = useSelector((state) => state.auth.get('tierId'))

  const navigate = useNavigate()

  useEffect(() => {
    setProductAllRecords(pageBuilderRaenaProductsAllList?.products?.length || 0)
    setTotalRecords(pageBuilderRaenaProductsAllList?.totalRecords || 0)
  }, [pageBuilderRaenaProductsAllList])

  useEffect(() => {
    setSearch(queryParams?.q || search || '')
    if (queryParams?.tab) {
      setLabelSelectedValue(queryParams?.tab || labelSelectorOptions)
    }
  }, [queryParams, search])

  useEffect(() => {
    onGetPageBuilderRaenaProductAllList({
      shareId: shareId || null,
      name: search,
      page: page + 1,
      limit
    })
  }, [onGetPageBuilderRaenaProductAllList, search, page, limit])

  const addHandler = (e) => {
    onGetPageBuilderRaenaProductsUpdateData({
      type: 'add',
      margin: e.margin,
      sku: e.sku,
      shareId: e.shareId
    })
  }

  useEffect(() => {
    if (pageBuilderRaenaProductsUpdateData) {
      onGetPageBuilderRaenaProductAllList({
        shareId: shareId,
        name: search,
        page: page + 1,
        limit
      })
    }
  }, [pageBuilderRaenaProductsUpdateData])

  useEffect(() => {
    if (shareId === '' && tierId === '') {
      onGetUserInfo()
    }
  }, [shareId, tierId])

  return (
    <>
      <ProductListWrapper>
        <ProductTopBar>
          <TitleWrapper>
            <Title>
              <BackButton to={`${ROUTE_LINKSTORE_RAENAPRODUCTS}/?${queryParamString}`}><ArrowBackIosIcon /></BackButton>
              {t('pageBuilderLink.addRaenaProducts')}
            </Title>
          </TitleWrapper>
        </ProductTopBar>

        {!error && pageBuilderRaenaProductsAllIsLoading && <Loading />}
        <SearchWrapper>
          <Search
            value={search}
            placeholder={t('pageBuilderLink.searchByProductNameBrand')}
            onEnter={(value) => {
              setPage(0)
              setSearch(value)
              navigate(ROUTE_LINKSTORE_RAENAPRODUCTS_ADD)
            }}
            displayCloseIcon='block'
          />
        </SearchWrapper>

        {(productAllRecords > 0 && shareId && tierId) && (
          <>
            <ProductCardWrapper className='ProductCardWrapper'>
              {
                (!error && !pageBuilderRaenaProductsAllIsLoading) &&
                  <>
                    {pageBuilderRaenaProductsAllList?.products.length > 0 && pageBuilderRaenaProductsAllList?.products.map((data, i) => {
                      const list = {
                        dsf: data?.dsf,
                        added: !data?.dsf,
                        title: data?.product?.name || '',
                        imageUrl: data?.product?.thumbnailImages?.[0]?.url || '',
                        price: data?.product?.retailPrice || '',
                        productRetailPrice: data?.product?.retailPrice,
                        id: data?.product?.id,
                        isPublished: data?.dsf?.isPublished || false,
                        margin: data?.dsf?.margin || 0,
                        sku: data?.product?.sku,
                        shareId: shareId,
                        isPinned: data?.dsf?.isPinned || false,
                        // tierIdValue: data?.dsf?.finalPrice,
                        tierIdValue: data?.product?.tierPrice?.[tierId], // commented need to confirm
                        type: 'RAENA_PRODUCT'
                      }
                      return (
                        <PageBuilderLinksInnerCard
                          {...props}
                          cardType='RAENA_PRODUCT'
                          list={list}
                          index={i}
                          key={i}
                          horizontalArrows={false}
                          showEditDeleteSwitch={false}
                          showAddToStore={true}
                          addHandler={addHandler}
                        />
                      )
                    })}
                  </>
              }
            </ProductCardWrapper>

            <ResuablePagination
              page={page}
              setPage={setPage}
              rowsPerPage={limit}
              setRowsPerPage={setLimit}
              totalRecords={totalRecords}
              rowsPerPageOptions={[20, 40, 80, 120]}
            />
          </>
        )}
        {!pageBuilderRaenaProductsAllIsLoading && productAllRecords === 0 && <NoRecordWraper>{t('pageBuilderLink.noRecordFound')}</NoRecordWraper>}
      </ProductListWrapper>
    </>
  )
}
export default PageBuilderRaenaProducts
