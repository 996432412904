import PropTypes from 'prop-types'

import {
  Button as ButtonComponent
} from './style'

const Button = ({ children, onClick, disabled = false, type = 'button', variant = 'primary', styleProps }) => {
  return (
    <ButtonComponent type={type} onClick={onClick} disabled={disabled} variant={variant} style={styleProps}>{children}</ButtonComponent>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['sumbit', 'reset', 'button']),
  variant: PropTypes.oneOf(['primary', 'secondary'])
}

Button.displayName = 'ButtonComponent'

export default Button
