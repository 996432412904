
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  MenuFieldWrapper,
  MenuFieldIconArrow,
  MenuFieldIconArrow2,
  MenuFieldIconClose,
  MenuFieldButton,
  MenuPopWrapper,
  MenuItems,
  FormLabel,
  FormInput,
  MenuFieldListWrapper,
  FormError,
  FormReq
} from './style'

import iconRightArrow from '../../assets/images/right-arrow.svg'
import iconClose from '../../assets/images/close.svg'
import LoadingSpinner from '../LoadingSpinner'

const MenuField = ({
  name = '',
  error = '',
  label = 'Category',
  placeholder = 'Select Category',
  setCategorySelectedValue,
  categoryList = [],
  onGetCategoryList,
  editObjectArray = null,
  disabled = false,
  isRequired,
  onClose = () => { }
}) => {
  const editValue = editObjectArray
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuLevel1List, setMenuLevel1List] = useState([])
  const [menuLevel2List, setMenuLevel2List] = useState([])
  const [menuLevel3List, setMenuLevel3List] = useState([])
  const [menuLevel4List, setMenuLevel4List] = useState([])
  const [menuLevel5List, setMenuLevel5List] = useState([])
  const [menuHasChildren, setMenuHasChildren] = useState(false)
  const [menuShow, setMenuShow] = useState(false)

  const [openLevel, setOpenLevel] = useState({ level1: Boolean(anchorEl), level2: false, level3: false, level4: false, level5: false })
  // const [selectedName, setSelectedName] = useState({ level1: editValue?.[1]?.name || '', level2: editValue?.[2]?.name || '', level3: editValue?.[3]?.name || '', level4: editValue?.[4]?.name || '', level5: editValue?.[5]?.name || '' })
  const len = (ind) => { return editValue.length - ind }
  const [selectedName, setSelectedName] = useState({ level1: editValue?.[len(1)]?.name || '', level2: editValue?.[len(2)]?.name || '', level3: editValue?.[len(3)]?.name || '', level4: editValue?.[len(4)]?.name || '', level5: editValue?.[len(5)]?.name || '' })
  const [selectedDisplayObject, setSelectedDisplayObject] = useState({ level0: { masterCategoryId: 0, name: '', displayName: '' }, level1: {}, level2: {}, level3: {}, level4: {}, level5: {} })
  const [selectedNameId, setSelectedNameId] = useState({ level1: 0, level2: null, level3: null, level4: null, level5: null })

  const [selectedValue, setSelectedValue] = useState('')
  const [selectedIdValue, setSelectedIdValue] = useState(0)

  useEffect(() => {
    if (editValue?.length > 0) {
      let selVal = `${selectedName.level1} > ${selectedName.level2}`
      if (selectedName.level3 !== '') selVal = `${selVal} > ${selectedName.level3}`
      if (selectedName.level4 !== '') selVal = `${selVal} > ${selectedName.level4}`
      if (selectedName.level5 !== '') selVal = `${selVal} > ${selectedName.level5}`

      setSelectedValue(selVal)
      // onGetCategoryList({
      //   page: 1,
      //   limit: 200
      //   parentCategoryId: editValue[0].masterCategoryId
      // })
    } else {
      if (openLevel) {
        onGetCategoryList({
          page: 1,
          limit: 200,
          parentCategoryId: selectedIdValue
        })
        updateMenuLevels()
      }
    }
  }, [onGetCategoryList, selectedIdValue, openLevel, selectedName])

  const updateMenuLevels = () => {
    if (selectedIdValue === selectedNameId?.level1) {
      setMenuLevel1List(categoryList)
    } else if (selectedIdValue === selectedNameId?.level2) {
      setMenuLevel2List(categoryList)
    } else if (selectedIdValue === selectedNameId?.level3) {
      setMenuLevel3List(categoryList)
    } else if (selectedIdValue === selectedNameId?.level4) {
      setMenuLevel4List(categoryList)
    } else if (selectedIdValue === selectedNameId?.level5) {
      setMenuLevel5List(categoryList)
    }
  }

  const handleClickLevel0 = (event) => {
    event.stopPropagation()
    if (disabled) return
    setAnchorEl(event.currentTarget)
    setMenuShow(true)

    if (selectedName.level5 !== '') {
      setOpenLevel({ level1: event.currentTarget, level2: true, level3: true, level4: true, level5: true })
    } else if (selectedName.level4 !== '') {
      setOpenLevel({ level1: event.currentTarget, level2: true, level3: true, level4: true })
    } else if (selectedName.level3 !== '') {
      setOpenLevel({ level1: event.currentTarget, level2: true, level3: true })
    } else if (selectedName.level2 !== '') {
      setOpenLevel({ ...openLevel, level1: event.currentTarget, level2: true })
    } else {
      setOpenLevel({ ...openLevel, level1: event.currentTarget })
    }
  }

  const handleClickLevel1 = (object) => {
    if (object?.hasChildren) {
      setCategorySelectedValue('')
      setOpenLevel({ ...openLevel, level2: true, level3: false, level4: false, level5: false })
    } else { handleClose(object?.masterCategoryId, 0, object) }

    setMenuHasChildren(object?.hasChildren)

    setSelectedName({ level1: object?.name, level2: '', level3: '', level4: '', level5: '' })
    setSelectedDisplayObject({ ...selectedDisplayObject, level1: { masterCategoryId: object.masterCategoryId, name: object.name, displayName: object.displayName }, level2: {}, level3: {}, level4: {}, level5: {} })
    setSelectedNameId({ ...selectedNameId, level2: object?.masterCategoryId })
    setSelectedValue(object?.displayName)
    setSelectedIdValue(object?.masterCategoryId)
  }

  const handleClickLevel2 = (object) => {
    if (object?.hasChildren) {
      setCategorySelectedValue('')
      setOpenLevel({ ...openLevel, level3: true, level4: false, level5: false })
    } else { handleClose(object?.masterCategoryId, 1, object) }

    setMenuHasChildren(object?.hasChildren)

    setSelectedName({ ...selectedName, level2: object?.name, level3: '', level4: '', level5: '' })
    setSelectedDisplayObject({ ...selectedDisplayObject, level2: { masterCategoryId: object.masterCategoryId, name: object.name, displayName: object.displayName }, level3: {}, level4: {}, level5: {} })
    setSelectedNameId({ ...selectedNameId, level3: object?.masterCategoryId })
    setSelectedValue(`${selectedName.level1} > ${object?.displayName} `)
    setSelectedIdValue(object?.masterCategoryId)
  }

  const handleClickLevel3 = (object) => {
    if (object?.hasChildren) {
      setCategorySelectedValue('')
      setOpenLevel({ ...openLevel, level4: true })
    } else { handleClose(object?.masterCategoryId, 1, object) }

    setMenuHasChildren(object?.hasChildren)

    setSelectedName({ ...selectedName, level3: object?.name, level4: '', level5: '' })
    setSelectedDisplayObject({ ...selectedDisplayObject, level3: { masterCategoryId: object.masterCategoryId, name: object.name, displayName: object.displayName }, level4: {}, level5: {} })
    setSelectedNameId({ ...selectedNameId, level4: object?.masterCategoryId })
    setSelectedValue(`${selectedName.level1} > ${selectedName.level2} > ${object?.displayName} `)
    setSelectedIdValue(object?.masterCategoryId)
  }

  const handleClickLevel4 = (object) => {
    if (object?.hasChildren) {
      setCategorySelectedValue('')
      setOpenLevel({ ...openLevel, level4: true })
    } else { handleClose(object?.masterCategoryId, 1, object) }

    setMenuHasChildren(object?.hasChildren)

    setSelectedName({ ...selectedName, level4: object?.name, level5: '' })
    setSelectedDisplayObject({ ...selectedDisplayObject, level4: { masterCategoryId: object.masterCategoryId, name: object.name, displayName: object.displayName }, level5: {} })
    setSelectedNameId({ ...selectedNameId, level5: object?.masterCategoryId })
    setSelectedValue(`${selectedName.level1} > ${selectedName.level2} > ${selectedName.level3} > ${object?.displayName} `)
    setSelectedIdValue(object?.masterCategoryId)
  }

  const handleClickLevel5 = (object) => {
    setMenuHasChildren(object?.hasChildren)
    setSelectedName({ ...selectedName, level5: object?.name })
    setSelectedDisplayObject({ ...selectedDisplayObject, level5: { masterCategoryId: object.masterCategoryId, name: object.name, displayName: object.displayName } })
    setSelectedValue(`${selectedName.level1} > ${selectedName.level2} > ${selectedName.level3} > ${selectedName.level4} > ${object?.displayName} `)
    handleClose(object?.masterCategoryId, 2, object)
  }

  const handleClose = (closeId, level, object) => {
    setOpenLevel({ ...openLevel, level1: false, level2: false, level3: false, level4: false, level5: false })
    setAnchorEl(null)
    setCategorySelectedValue(selectedValue)

    const lastObject = { masterCategoryId: object.masterCategoryId, name: object.name, displayName: object.displayName }
    const categoryArray = [selectedDisplayObject.level0, selectedDisplayObject.level1, selectedDisplayObject.level2, selectedDisplayObject.level3, selectedDisplayObject.level4, lastObject]
    if (level === 1) {
      categoryArray[1] = lastObject
      categoryArray.pop()
    }
    const allPreviousValues = `${selectedNameId.level1} ${selectedNameId.level2} ${selectedNameId.level3} ${selectedNameId.level4} ${selectedNameId.level5} ${closeId}`
    onClose(allPreviousValues, closeId, categoryArray)
  }

  const handleCloseButton = (event) => {
    event.stopPropagation()
    setMenuShow(menuHasChildren)
  }

  // EDIT CODING STARTS
  /*
  const onloadEditFunc = (onloadEditId) => {
    EDIT INCOMMING JSON FORMAT
    Level 0: {masterCategoryId: 0, name: '', displayName: ''}
    Level 1: {masterCategoryId: 100017, name: 'Women Clothes', displayName: 'Women Clothes'}
    Level 2: {masterCategoryId: 100118, name: 'Socks & Stockings', displayName: 'Socks & Stockings'}
    Level 3: {masterCategoryId: 100418, name: 'Pantyhose', displayName: 'Pantyhose'}

    RIGHT NOW
    0: {id: 101636, name: 'Eyeshadow', displayName: 'Eyeshadow', hasChild: false, parentCategoryId: 100882, …}
    1: {id: 100882, name: 'Eyes', displayName: 'Eyes', hasChild: true, parentCategoryId: 100662, …}
    2: {id: 100662, name: 'Makeup', displayName: 'Makeup', hasChild: true, parentCategoryId: 100630, …}
    3: {id: 100630, name: 'Beauty', displayName: 'Beauty', hasChild: true, parentCategoryId: 0, …}
  }
  */

  useEffect(() => {
    if (editValue.length !== 0) { // EDIT CODING
      const tempStr = editValue.map(e => e.masterCategoryId).join(' ')
      const editData = tempStr.split(' ').splice(1).map(n => n * 1)
      if (selectedIdValue === editData[0]) { // LEVEL-1
        setMenuLevel1List(categoryList)
        if (categoryList.length > 0 && selectedIdValue === editData[0]) { // LEVEL-1
          const levelData = categoryList.filter(c => c.masterCategoryId === editData[1])[0]
          if (levelData?.parentCategoryId === editData[0]) {
            if (levelData?.hasChildren) {
              setCategorySelectedValue('')
            }
            setMenuHasChildren(levelData?.hasChildren)
            setSelectedName({ level1: levelData?.name, level2: '', level3: '' })
            setSelectedDisplayObject({ level1: levelData?.displayName, level2: {}, level3: {} })
            setSelectedNameId({ ...selectedNameId, level2: levelData?.masterCategoryId })
            setSelectedValue(levelData?.displayName)
            setSelectedIdValue(levelData?.masterCategoryId)
            setCategorySelectedValue(selectedValue)
          }
        }
      } else if (selectedIdValue === editData[1]) { // LEVEL-2
        setMenuLevel2List(categoryList)
        if (categoryList.length > 0 && selectedIdValue === editData[1]) { // LEVEL-2
          const levelData = categoryList.filter(c => c.masterCategoryId === editData[2])[0]
          if (levelData?.parentCategoryId === editData[1]) {
            if (levelData?.hasChildren) {
              setCategorySelectedValue('')
            }
            setMenuHasChildren(levelData?.hasChildren)
            setSelectedName({ ...selectedName, level2: levelData?.name, level3: '' })
            setSelectedDisplayObject({ ...selectedName, level2: levelData?.displayName, level3: {} })
            setSelectedNameId({ ...selectedNameId, level3: levelData?.masterCategoryId })
            setSelectedValue(`${selectedName.level1} > ${levelData?.displayName} `)
            setSelectedIdValue(levelData?.masterCategoryId)
            setCategorySelectedValue(selectedValue)
          }
        }
      } else if (selectedIdValue === editData[2]) { // LEVEL-3
        setMenuLevel3List(categoryList)
        if (categoryList.length > 0 && selectedIdValue === editData[2]) { // LEVEL-3
          const levelData = categoryList.filter(c => c.masterCategoryId === editData[3])[0]
          if (levelData?.parentCategoryId === editData[2]) {
            if (levelData?.hasChildren) {
              setCategorySelectedValue('')
            }
            setMenuHasChildren(levelData?.hasChildren)
            setSelectedName({ ...selectedName, level3: levelData?.name, level4: '' })
            setSelectedDisplayObject({ ...selectedName, level3: levelData?.displayName, level4: {} })
            setSelectedNameId({ ...selectedNameId, level4: levelData?.masterCategoryId })
            setSelectedValue(`${selectedName.level1} > ${selectedName.level2} > ${levelData?.displayName} `)
            setSelectedIdValue(levelData?.masterCategoryId)
            setCategorySelectedValue(selectedValue)
          }
        }
      } else if (selectedIdValue === editData[3]) { // LEVEL-4
        setMenuLevel4List(categoryList)
        if (categoryList.length > 0 && selectedIdValue === editData[3]) { // LEVEL-4
          const levelData = categoryList.filter(c => c.masterCategoryId === editData[4])[0]
          if (levelData?.parentCategoryId === editData[3]) {
            if (levelData?.hasChildren) {
              setCategorySelectedValue('')
            }
            setMenuHasChildren(levelData?.hasChildren)
            setSelectedName({ ...selectedName, level4: levelData?.name, level5: '' })
            setSelectedDisplayObject({ ...selectedName, level4: levelData?.displayName, level5: {} })
            setSelectedNameId({ ...selectedNameId, level5: levelData?.masterCategoryId })
            setSelectedValue(`${selectedName.level1} > ${selectedName.level2} > ${selectedName.level3} > ${levelData?.displayName} `)
            setSelectedIdValue(levelData?.masterCategoryId)
            setCategorySelectedValue(selectedValue)
          }
        }
      } else if (selectedIdValue === editData[4]) { // LEVEL-5
        setMenuLevel5List(categoryList)
        if (categoryList.length > 0 && selectedIdValue === editData[4]) { // LEVEL-5
          const levelData = categoryList.filter(c => c.masterCategoryId === editData[5])[0]
          if (levelData?.parentCategoryId === editData[4]) {
            setMenuHasChildren(levelData?.hasChildren)
            setSelectedValue(`${selectedName.level1} > ${selectedName.level2} > ${selectedName.level3} > ${selectedName.level4} > ${levelData?.displayName}`)
            setCategorySelectedValue(selectedValue)
          }
        }
      }
    }
  }, [categoryList, selectedIdValue])

  useEffect(() => {
    // IMPORTNAT CODE DONT DELETE OR MODIFY
    if (!(menuLevel1List.length > 0 && menuLevel1List[0].parentCategoryId === selectedNameId.level1)) {
      setMenuLevel1List(categoryList)
    } else if (!(menuLevel2List.length > 0 && menuLevel2List[0].parentCategoryId === selectedNameId.level2)) {
      setMenuLevel2List(categoryList)
    } else if (!(menuLevel3List.length > 0 && menuLevel3List[0].parentCategoryId === selectedNameId.level3)) {
      setMenuLevel3List(categoryList)
    } else if (!(menuLevel4List.length > 0 && menuLevel4List[0].parentCategoryId === selectedNameId.level4)) {
      setMenuLevel4List(categoryList)
    } else if (!(menuLevel5List.length > 0 && menuLevel5List[0].parentCategoryId === selectedNameId.level5)) {
      setMenuLevel5List(categoryList)
    }
  }, [categoryList])

  const Req = () => {
    return (
      <>
        {isRequired && <FormReq>*</FormReq>}
      </>
    )
  }

  return (
    <MenuFieldWrapper>

      <FormLabel>{label}<Req /></FormLabel>
      <MenuFieldButton
        aria-controls={openLevel.level1 ? 'menu-level1' : undefined}
        aria-haspopup='true'
        aria-expanded={openLevel.level1 ? 'true' : undefined}
        onClick={handleClickLevel0}
        title={selectedValue === '' ? placeholder : selectedValue}
        placeholderactive={selectedValue}
        error={error === '' ? 'no' : 'yes'}
        disabled={disabled}
      >
        <FormInput value={selectedValue} />
        {selectedValue === '' ? placeholder : selectedValue}
        {/* <MenuFieldIconArrow src={iconRightArrow} alt='Right Arrow' /> */}
        {!(menuShow && openLevel.level1) && <MenuFieldIconArrow src={iconRightArrow} alt='Right Arrow' disabled={disabled} />}
        {menuShow && openLevel.level1 && <MenuFieldIconClose src={iconClose} alt='Close' onClick={handleCloseButton} />}
      </MenuFieldButton>

      {menuShow && openLevel.level1 &&
        <MenuFieldListWrapper>
          {
            menuShow && openLevel.level1 &&
              <MenuPopWrapper level='1'>
                {!(menuLevel1List.length > 0 && menuLevel1List[0].parentCategoryId === selectedNameId.level1) && <LoadingSpinner />}
                {menuLevel1List.length > 0 && menuLevel1List[0].parentCategoryId === selectedNameId.level1 && menuLevel1List.map((menu, index) => {
                  return (
                    <MenuItems key={index} onClick={() => handleClickLevel1(menu)} selected={selectedName.level1 === menu?.displayName}>
                      {menu?.displayName}
                      {menu?.hasChildren && <MenuFieldIconArrow2 src={iconRightArrow} alt='Right Arrow' />}
                    </MenuItems>
                  )
                })}
              </MenuPopWrapper>
          }

          {
            menuShow && openLevel.level1 && openLevel.level2 &&
              <MenuPopWrapper level='2'>
                {!(menuLevel2List.length > 0 && menuLevel2List[0].parentCategoryId === selectedNameId.level2) && <LoadingSpinner />}
                {menuLevel2List.length > 0 && menuLevel2List[0].parentCategoryId === selectedNameId.level2 && menuLevel2List.map((menu, index) => {
                  return (
                    <MenuItems key={index} onClick={() => handleClickLevel2(menu)} selected={selectedName.level2 === menu?.displayName}>
                      {menu?.displayName}
                      {menu?.hasChildren && <MenuFieldIconArrow2 src={iconRightArrow} alt='Right Arrow' />}
                    </MenuItems>
                  )
                })}
              </MenuPopWrapper>
          }

          {
            menuShow && openLevel.level1 && openLevel.level2 && openLevel.level3 &&
              <MenuPopWrapper level='3'>
                {!(menuLevel3List.length > 0 && menuLevel3List[0].parentCategoryId === selectedNameId.level3) && <LoadingSpinner />}
                {menuLevel3List.length > 0 && menuLevel3List[0].parentCategoryId === selectedNameId.level3 && menuLevel3List.map((menu, index) => {
                  return (
                    <MenuItems key={index} onClick={() => handleClickLevel3(menu)} selected={selectedName.level3 === menu?.displayName}>
                      {menu?.displayName}
                      {menu?.hasChildren && <MenuFieldIconArrow2 src={iconRightArrow} alt='Right Arrow' />}
                    </MenuItems>
                  )
                })}
              </MenuPopWrapper>
          }

          {
            menuShow && openLevel.level1 && openLevel.level2 && openLevel.level3 && openLevel.level4 &&
              <MenuPopWrapper level='4'>
                {!(menuLevel4List.length > 0 && menuLevel4List[0].parentCategoryId === selectedNameId.level4) && <LoadingSpinner />}
                {menuLevel4List.length > 0 && menuLevel4List[0].parentCategoryId === selectedNameId.level4 && menuLevel4List.map((menu, index) => {
                  return (
                    <MenuItems key={index} onClick={() => handleClickLevel4(menu)} selected={selectedName.level4 === menu?.displayName}>
                      {menu?.displayName}
                    </MenuItems>
                  )
                })}
              </MenuPopWrapper>
          }

          {
            menuShow && openLevel.level1 && openLevel.level2 && openLevel.level3 && openLevel.level4 && openLevel.level5 &&
              <MenuPopWrapper level='5'>
                {!(menuLevel5List.length > 0 && menuLevel5List[0].parentCategoryId === selectedNameId.level5) && <LoadingSpinner />}
                {menuLevel5List.length > 0 && menuLevel5List[0].parentCategoryId === selectedNameId.level5 && menuLevel5List.map((menu, index) => {
                  return (
                    <MenuItems key={index} onClick={() => handleClickLevel5(menu)} selected={selectedName.level5 === menu?.displayName}>
                      {menu?.displayName}
                    </MenuItems>
                  )
                })}
              </MenuPopWrapper>
          }
        </MenuFieldListWrapper>}
      {error !== '' && <FormError>{error}</FormError>}
    </MenuFieldWrapper>
  )
}

MenuField.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  categoryIsLoading: PropTypes.object,
  categoryBrandsIsLoading: PropTypes.object,
  categories: PropTypes.array,
  onGetCategoryDropdownData: PropTypes.object,
  onClose: PropTypes.func
}

MenuField.displayName = 'MenuField'

export default MenuField
