/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import {
  FreeSKUContainer,
  CreateTitle,
  ImgFreeSkuTag,
  AddSkuButton,
  ImgIconAddSku,
  ImgIconDelteSku
} from './styles';
import { freeSkuTag, iconAddSku, deleteIcon } from '../../assets/images/icons';
import SelectSkuComponent from './SelectSkuComponent';
import { ANY, SAME } from '../../constants/brandPromo';

const FreeSKUComponent = (props) => {
  const { formik, freeSku, setFreeSku, freeSkuCriteria, index, disable, skuList, showFreeSku2, setShowFreeSku2 } = props;
  const { t } = useTranslation();
  // const [showFreeSku2, toggleFreeSku2] = useState(freeSku[index]?.promoSkus?.length > 1);
  const [sku2List, setSku2List] = useState([...skuList]);
  const [excludedSkuList, setExcludedSkuList]  = useState([...skuList]);
  const promoSkuError = (formik.errors.freesku?.length > 0 && formik.errors.freesku[index]?.promoSkus) || null;
  const promoSkus = (formik.values.freesku?.length > 0 && formik.values.freesku[index]?.promoSkus?.length > 0
  && formik.values.freesku[index]?.promoSkus) || []

  const toggleFreeSku2 = () => {
    let newShowFreeSku2 = [...showFreeSku2];
    newShowFreeSku2[index] = !showFreeSku2[index]
    setShowFreeSku2(newShowFreeSku2)
  }

  const handleAddFreeSku = () => {
    toggleFreeSku2();
  };

  const handleRemoveSku = () => {

    if(!showFreeSku2[index]) {
      let newShowSku2values = [...showFreeSku2]
      newShowSku2values[index] = newShowSku2values[index+1]
      newShowSku2values.splice(index+1, 1);
      setShowFreeSku2(newShowSku2values);

      freeSku.splice(index, 1);
    } else {
      toggleFreeSku2();

      const freePromo = freeSku[index].promoSkus;
      const freeQty = freeSku[index].qty;
      if(freePromo?.length > 1){
        freePromo.splice(1, 1);
        freeSku[index].promoSkus = freePromo;
      }

      if(freeQty?.length > 1){
        freeQty.splice(1, 1);
        freeSku[index].qty = freeQty;
      }
    }

    setFreeSku([...freeSku]);

  }

  const getUpdatedList = () => {
    if(freeSku?.length > 0){
      const updatedList = skuList.filter(el => {
        return !freeSku[index].promoSkus?.find(element => {
          return element.sku === el.id;
        });
      });

      setSku2List(updatedList);
    } else {
      setSku2List(skuList);
    }
  }


  const ExcludeSelectedSkus = () => {
    return skuList.filter((el ) => {
      for(let i = 0; i < freeSku.length ;i++){
        let currentFreeSku = freeSku[i].promoSkus;
        if(currentFreeSku){
          for(let j = 0; j < currentFreeSku.length ;j++){
            let currentPromoSku = currentFreeSku[j];
            if(promoSkus[0]?.sku !== currentPromoSku?.sku && currentPromoSku?.sku === el.id){
              return false;
            }   
          }
        }
      }
      return true;
    });
  }

  useEffect(()=> {
    setExcludedSkuList(ExcludeSelectedSkus())
  }, [freeSku, skuList])
  
  const renderFreeSku = () => (
    <SelectSkuComponent
      {...props}
      skuList={skuList}
      formikValue={promoSkus[0] ? `${promoSkus[0].sku} : ${promoSkus[0].name}` : ''}
      marginleft="23px"
      label={t('createPromo.enterFreeSku')}
      skuCriteria={freeSkuCriteria}
      formikTouch={formik.touched.freesku}
      formikError={promoSkuError}
      sku={freeSku}
      updateSku={setFreeSku}
      index={index}
      subIndex={0}
      updateSkuList={getUpdatedList}
      disable={disable}
    />);

  const freeSkuComponent = renderFreeSku();

  return (
    <FreeSKUContainer>
      <ImgFreeSkuTag src={freeSkuTag} />
      {
        (freeSkuCriteria !== ANY && freeSkuCriteria !== SAME )
          ? showFreeSku2[index]
            ? (
              <Grid container={true}>
                {freeSkuComponent}
                <CreateTitle marginleft="10px" margintop="50px">+</CreateTitle>
                <SelectSkuComponent
                  {...props}
                  formikValue={(promoSkus.length > 1 && promoSkus[1]) ? `${promoSkus[1].sku} : ${promoSkus[1].name}` : ''}
                  formikTouch={formik.touched.freesku2}
                  formikError={promoSkuError}
                  marginleft="10px"
                  label={t('createPromo.enterFreeSku')}
                  skuList={sku2List}
                  skuCriteria={freeSkuCriteria}
                  sku={freeSku}
                  updateSku={setFreeSku}
                  index={index}
                  subIndex={1}
                  disable={disable || !(promoSkus.length > 0)}
                />
                 {!disable &&<ImgIconDelteSku src={deleteIcon} onClick={handleRemoveSku} />}
              </Grid>
            )
            : (
              <Grid container={true}>
                {freeSkuComponent}
                {
                  !disable && (
                    <AddSkuButton onClick={handleAddFreeSku} margintop={freeSku[index]?.promoSkus?.length > 0 ? '' : '33px'}>
                      <ImgIconAddSku src={iconAddSku} />
                      {t('createPromo.addSku')}
                    </AddSkuButton>
                  )
                }
                {
                  (freeSku.length > 1 && !disable) && <ImgIconDelteSku src={deleteIcon} onClick={handleRemoveSku} />
                }
              </Grid>
            )
          : freeSkuComponent
      }
    </FreeSKUContainer>

  );
};

FreeSKUComponent.propTypes = {
  formik: PropTypes.shape({}),
  freeSku: PropTypes.arrayOf(PropTypes.shape({})),
  setFreeSku: PropTypes.func,
  freeSkuCriteria: PropTypes.string,
  index: PropTypes.number,
  skuList: PropTypes.arrayOf(PropTypes.shape({}))
};

FreeSKUComponent.defaultProps = {
  formik: null,
  freeSku: null,
  setFreeSku: null,
  freeSkuCriteria: null,
  index: null,
  skuList: []
};

export default FreeSKUComponent;
