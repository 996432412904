import styled from 'styled-components'

export const ModalContent = styled.div`
  position: relative;
  background:#F9F9F9;
  z-index: 99;
  overflow: auto;
`

export const ModalMenu = styled.div`
  display: flex;
  justify-content: end;
  padding: 5px;
  & button{
    margin:0 5px;
  }
`

export const ProductDetailCardWrapper = styled.div`
  background:#FFF;
  padding-bottom: 5px;
  margin-bottom: 30px;
`

export const ProductHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 15px; 
  margin-top: 20px;

  @media (max-width: 1200px) {
    display: block;
  }
`

export const ProductSmallHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  & div {
    margin-top:0
  }
  & h3 {
    margin-top:5px
  }
  & img {
    max-width: 70px;
    max-height: 70px;
  }
`

export const MainImage = styled.img`
  width: 143px;
  height: 116px;
`

export const HeaderDetail = styled.div`
  margin-left: 15px;
  display: flex;
  width: 100%;
  flex-direction: row; 

  @media (max-width: 1200px) {
    margin-left: 0;    
  }
`
export const ModalHeader = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #360D20;
`

export const DetailTopText = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height:6px;
  font-size: 12px;
  /* margin-right: 20px; */
  color: #525665;
`

export const DetailHeader = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #162E9A;
  width: 95%;
`

const setDetailFooterColor = status => {
  if (status === 'Published') return '#75BD27'
  if (status === 'Publishing') return '#ffbc59'
  if (status === 'Draft') return '#ffc100'
  return '#CA3433'
}
export const DetailFooter = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color:  ${props => setDetailFooterColor(props.status)};
`

export const ListedOnHeader = styled.div`
  font-style: normal;
  font-weight: 800;
  line-height:6px;
  margin-left:21px;
  margin-bottom:10px;
  color: #000;
`

export const ListedOnCardWrapper = styled.div`
  border-radius: 10px;
  background:#fff;
  margin-left:5px;
  margin-bottom: 30px;
  margin-right:5px;
`
export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 5px; left: 5px;
  padding: 5px 7px 1px 7px;
  border: 1px solid #E4E4E5;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  border-radius: 2px;
  cursor: pointer;
`

export const StoreDetailHeader = styled.div`
  display: flex;
  padding:15px;
`

export const StoreDetailHeaderRightSection = styled.div`
  display: flex;
  width: 50%;
`

export const Details = styled.div`
  display: flex;
  background: white;
  flex-direction: row;
  padding: 20px;
`

export const StoreProductNameLogo = styled.div`
  /* display: flex;  
  flex-direction: column;
  width: 50%;
  background: white; */
`

export const StoreNameLogo = styled.div`
  display: flex; 
`

export const ProductImage = styled.img`
 height: 100px;
width: 70px;
margin-right: 15px;
object-fit: cover;
`

export const StoreLogoImage = styled.img`
  height:18px;
`

export const StoreName = styled.div`
  color: #525665;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ProductName = styled.span`
  color: #162E9A;
  font-size: 16px;
  font-style: normal;  
  margin-top: 10px;
  margin-left: 2px;
  margin-right: 16px;
`

export const FooterPricing = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
`
export const PriceHeading = styled.span`
    color: #999;
    margin-right: 15px;
    font-size: 14px;
    font-weight: 800;
    margin-left: 10px;
`

export const PriceText = styled.span`
  color: #1D2334;
  font-size: 14px;
  font-weight: 800;
  display: ${props => props.none === true ? 'block' : 'none'};
`
export const InputPriceText = styled.span`
  color: #1D2334;
  font-size: 14px;
  font-weight: 800;
  display: ${props => props.none === true ? 'block' : 'none'};
`
export const EditIconSpan = styled.span`
   display: ${props => props.none === true ? 'block' : 'none'};
`
export const SaveIconSpan = styled.span`
   display: ${props => props.none === true ? 'block' : 'none'};
`
export const StoreDetailHeaderLeftSection = styled.div`
  display: flex;
  margin-left: auto;
`

export const SkuID = styled.div`
  font-size: 10px;
  color: #525665;
  margin-right:34px;
  margin-top: 5px;
`

export const EditButtonHeader = styled.div`
  margin-left: auto;
`

export const EditButton = styled.button`
  width: 60px;
  height: 40px;
  background: #FFF;
  border: 1px solid #E4E4E5;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 20px;
`

export const ProductOnStoreRowHeadings = styled.div`
  display: grid;
  grid-template-columns: repeat(3,1fr);
  background: #F9F9F9;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
`

export const ProductOnStoreRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3,1fr);
  font-size: 12px;
  padding-top: 4px;
  color: #1D2334;
  padding-bottom: 4px;
  margin-top: 5px;
`

export const StoreTableHeadingCell = styled.div`
  text-align: center;
  color: #999;
  font-size: 12px;
`
export const StoreTableCell = styled.div`
  text-align: center;
  color: #162E9A;
  font-size: 12px;
`

export const StoreTableContentCell = styled.div`
  text-align: center;
  color:  ${props => props.color};
  font-size: 14px;
`

export const ProductOnStoreContent = styled.div`
  display: grid;
  grid-template-columns: repeat(5,1fr);
  background: #FFF;
  font-size: 12px;
  padding-left:60px;
  padding-top: 4px;
  padding-bottom: 4px;
`

export const StoreDetails = styled.span`
  position: relative;
`

export const SelectStoreWrapper = styled.div`
  padding: 1rem 20px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgb(136 150 203 / 12%);
  border-radius: 10px;
  overflow: scroll;
  overflow-x: hidden;
`

export const AddToStoreButtonParent = styled.div`
  text-align:center;
  margin: 20px 0;
`

export const AddToStoreButton = styled.button`
  font-size: 16px;
  font-weight: 500;
  width: 209px;
  height: 40px;
  border: 1px solid #162E9A;
  border-radius: 4px;
  color: white;
  background: #162E9A;
  cursor: pointer
`

export const Popup = styled.div`
  z-index: 99;
  display: flex;
  flex-direction: column;
  position: relative;
  background:#F9F9F9;  
  margin-left: 60%;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    left: 0;
    margin-left:0;
    height: auto;
    margin-top:20px;
    margin-bottom:20px;
  }
`
