import React from 'react'

import Logo from '../../components/Logo'
import authBanner from '../../assets/images/authbanner.svg'

import {
  FormWrapper,
  Banner,
  BannerSection,
  Wrapper,
  LogoWrapper
} from './style'

const AuthLayout = ({ children }) => {
  const renderLogo = () => <LogoWrapper><Logo size='medium' /></LogoWrapper>

  const renderBanner = () => (
    <BannerSection>
      <Banner src={authBanner} alt='Raena Banner' />
    </BannerSection>
  )

  return (
    <Wrapper>
      {renderLogo()}
      <FormWrapper>
        {renderBanner()}
        {children}
      </FormWrapper>
    </Wrapper>
  )
}

AuthLayout.displayName = 'AuthLayout'
export default AuthLayout
