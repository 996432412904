import React from 'react'

import Layout from '../Layout'
import DashboardComponent from '../../components/Dashboard/Dashboard'

const Dashboard = () => {
  return (
    <Layout>
      <DashboardComponent />
    </Layout>
  )
}

Dashboard.displayName = 'DashboardContainer'
export default Dashboard
