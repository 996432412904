import { useState, useRef, useEffect } from 'react'
import { RWebShare } from 'react-web-share'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import MuiTextField from '../MuiTextField'
import MuiButton from '../MuiButton'
// import MuiSelect from '../MuiSelect'
import { Grid, Box } from '@mui/material'
import UploadImages from '../../containers/UploadImages'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import { LINKSTORE_BASE_URL } from '../../config'

import { iconEmail, iconTiktok, iconInstagram, iconSnapchat, iconFacebook, iconWhatsapp, iconYoutube, iconPhone, iconTelegram, iconPlaystore, iconAppstore, logoLazada, logoBlibli, logoBukalapak, logoShopee, logoTokopedia, mask } from '../../assets/images/icons'

import {
  PageBuilderWrapper,
  SectionContent,
  FormControl,
  FormHeading,
  InputAdornments,
  ViewMoreWrapper,
  UploadImageWrapper,
  DivWraper,
  MobileShareButtons,
  WebShareButtons,
  CountContainer
} from './style'
import { store } from '../../store'
import { PAGEBUILDER_ABOUT_CREATE_POST_DATA } from '../../actions'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g
const phoneRegex = /^[+]?[0-9]{4,17}$/g
const descRegex = /^[\w\W]{0,400}$/g
const nameRegex = /^[\w\W]{0,200}$/g

const PageBuilderAbout = ({ pageBuilderAboutFetchData }) => {
  const form = useRef()
  const user = useSelector(state => state.users.toJS())
  const [validateOnBlur, setValidateOnBlur] = useState(false)
  const [viewAllSocial, setViewAllSocial] = useState(false)
  const [viewAllMarket, setViewAllMarket] = useState(false)
  const { pageBuilderAboutCreateIsLoading } = useSelector(state => state.pageBuilder)
  const { t } = useTranslation()
  const auth = useSelector(state => state.auth.toJS())

  const instagram = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'instagram') || {}
  const facebook = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'facebook') || {}
  const tiktok = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'tiktok') || {}
  const youtube = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'youtube') || {}
  const snapchat = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'snapchat') || {}
  const telegram = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'telegram') || {}
  const shopee = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'shopee') || {}
  const tokopedia = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'tokopedia') || {}
  const lazada = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'lazada') || {}
  const blibli = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'blibli') || {}
  const bukallapak = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'bukallapak') || {}
  const playstore = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'playstore') || {}
  const appstore = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'appstore') || {}
  const offline = pageBuilderAboutFetchData?.storeProfiles?.find(item => item.type === 'offline') || {}
  const contact = pageBuilderAboutFetchData?.contactFieldsClicks || {}

  const clicks = {
    mobile: contact.dsfMobile || 0,
    whatsapp: contact.waMobile || 0,
    email: contact.dsfEmail || 0,
    instagram: instagram?.clicks || 0,
    facebook: facebook?.clicks || 0,
    tiktok: tiktok?.clicks || 0,
    youtube: youtube?.clicks || 0,
    snapchat: snapchat?.clicks || 0,
    telegram: telegram?.clicks || 0,
    shopee: shopee?.clicks || 0,
    tokopedia: tokopedia?.clicks || 0,
    lazada: lazada?.clicks || 0,
    blibli: blibli?.clicks || 0,
    bukallapak: bukallapak?.clicks || 0,
    playstore: playstore?.clicks || 0,
    appstore: appstore?.clicks || 0
  }

  const copyTextValue = `${LINKSTORE_BASE_URL}/${auth?.shareId}`
  const copyText = () => {
    EventEmitter.emit(Events.DSFADMIN_STORELINK_COPY)
    EventEmitter.emit(Events.DSF_ABOUTUS_COPY)
  }
  const openLinkStore = () => {
    EventEmitter.emit(Events.DSFADMIN_STORELINK_CLICK, {
      seller_id: user?.id || '',
      url: copyTextValue || ''
    })
  }

  const handleImageChange = (data) => {
    // setImageIdList(data)
    EventEmitter.emit(Events.DSF_LINKS_ABOUTUS_IMAGEUPLOAD)
    const imageList = data?.map(i => { return { key: i.key, priority: 1, url: i.url } }) || []
    formik.setValues({ ...formik.values, ...{ profile_images: imageList } })
    setFormikInitialValues({ ...formik.values, profile_images: imageList })
  }

  const validation = (values) => {
    setValidateOnBlur(true)
    const errors = {}
    // if (name.length === 0) { errors.naaaaame = 'name is required' }
    return errors
  }

  const [formikInitialValues, setFormikInitialValues] = useState({
    profile_images: [],
    profile_title: '',
    profile_description: '',
    contact_phone: '',
    contact_whatsapp: '',
    contact_email: '',
    social_instagram: '',
    social_facebook: '',
    social_tiktok: '',
    social_youtube: '',
    social_snapchat: '',
    social_telegram: '',
    market_shopee: '',
    market_tokopedia: '',
    market_lazada: '',
    market_blibli: '',
    market_bukallapak: '',
    app_playstore: '',
    app_appstore: '',
    paymentAccepted: ''
  })

  useEffect(() => {
    if (pageBuilderAboutFetchData?.resellerId) {
      setFormikInitialValues(
        {
          profile_images: (pageBuilderAboutFetchData?.imageUrl?.length) ? [{ key: '0', priority: 1, url: pageBuilderAboutFetchData?.imageUrl }] : [],
          profile_title: pageBuilderAboutFetchData?.store || '',
          profile_description: pageBuilderAboutFetchData?.storeDescription || '',
          contact_phone: pageBuilderAboutFetchData?.dsfMobile || '',
          contact_whatsapp: pageBuilderAboutFetchData?.waMobile || '',
          contact_email: pageBuilderAboutFetchData?.dsfEmail || '',
          social_instagram: instagram?.url || '',
          social_facebook: facebook?.url || '',
          social_tiktok: tiktok?.url || '',
          social_youtube: youtube?.url || '',
          social_snapchat: snapchat?.url || '',
          social_telegram: telegram?.url || '',
          market_shopee: shopee?.url || '',
          market_tokopedia: tokopedia?.url || '',
          market_lazada: lazada?.url || '',
          market_blibli: blibli?.url || '',
          market_bukallapak: bukallapak?.url || '',
          app_playstore: playstore?.url || '',
          app_appstore: appstore?.url || '',
          paymentAccepted: ''
        }
      )
    }
  }, [])

  const formik = useFormik({
    validateOnChange: validateOnBlur,
    validateOnBlur: validateOnBlur,
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: Yup.object({
      profile_title: Yup.string().optional().matches(nameRegex, 'Max 200 characters'),
      profile_description: Yup.string().optional().matches(descRegex, 'Max 400 characters'),
      contact_whatsapp: Yup.string().optional().matches(phoneRegex, 'Invalid number'),
      contact_phone: Yup.string().optional().matches(phoneRegex, 'Invalid number'),
      market_shopee: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      market_tokopedia: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      market_lazada: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      market_blibli: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      market_bukallapak: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      app_playstore: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      app_appstore: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      social_instagram: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      social_facebook: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      social_tiktok: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      social_youtube: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      social_snapchat: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      social_telegram: Yup.string().optional().matches(urlRegex, 'Invalid url'),
      contact_email: Yup.string().optional().email('Invalid Email')
    }),
    validate: (values) => validation(values),
    onSubmit: () => onSubmitAboutData()
  })

  const onSubmitAboutData = () => {
    const profilesList = {
      social_instagram: '',
      social_facebook: '',
      social_tiktok: '',
      social_youtube: '',
      social_snapchat: '',
      social_telegram: '',
      market_shopee: '',
      market_tokopedia: '',
      market_lazada: '',
      market_blibli: '',
      market_bukallapak: '',
      app_playstore: '',
      app_appstore: ''
    }
    if(!isEmpty(offline)) profilesList['offline'] = '';
    const profilesKey = Object.keys(profilesList)
    const profiles = Array.from(profilesKey).map((item) => ({
      id: item.includes('offline') ? item : item.split('_')[1],
      type: item.includes('offline') ? item : item.split('_')[1],
      url: item.includes('offline') ? true : formik.values[item],
      meta: item.includes(
        'market' || 'market' || 'market' || 'market' || 'market'
      )
        ? 'Store'
        : item.includes('app' || 'app') ? 'App' : item.includes('offline') ? null : 'Social'
    }))
    const data = {
      imageUrl: formik.values.profile_images?.[0]?.url || '',
      store: formik.values.profile_title,
      storeDescription: formik.values.profile_description,
      dsfMobile: formik.values.contact_phone || null,
      waMobile: formik.values.contact_whatsapp || null,
      dsfEmail: formik.values.contact_email || null,
      storeProfiles: profiles, // profiles.filter(item => item.url),
      resellerId: user?.id
    }
    EventEmitter.emit(Events.DSF_ABOUTUS_SAVE)
    EventEmitter.emit(Events.DSFADMIN_SAVE)
    store.dispatch({ type: PAGEBUILDER_ABOUT_CREATE_POST_DATA, payload: data })
  }
  let countImageClick = 1

  const uploadImageClick = () => {
    if (countImageClick) EventEmitter.emit(Events.DSFADMIN_UPLOADIMAGE)

    countImageClick = -countImageClick + 1
  }

  return (
    <PageBuilderWrapper>
      <form onSubmit={formik.handleSubmit} ref={form} autocomplete='off'>
        <SectionContent>
          <FormControl onClick={uploadImageClick}>
            <FormHeading>{t('pageBuilderAbout.profileDetails')}</FormHeading>
            <UploadImageWrapper>
              {formikInitialValues?.profile_images.length > 0 &&
                <UploadImages
                  description={t('pageBuilderAbout.profileUploadImage')}
                  format='image/jpg, image/jpeg'
                  onImageChange={handleImageChange}
                  error={formik.errors.profile_images}
                  value={[{ url: formikInitialValues.profile_images?.[0]?.url }]}
                  mode='editAbout'
                  name='editAbout'
                  hideBrowse={
                    { maxLength: 1 }
                  }
                />}
              {formikInitialValues?.profile_images.length === 0 &&
                <UploadImages
                  description={t('pageBuilderAbout.profileUploadImage')}
                  format='image/jpg, image/jpeg'
                  onImageChange={handleImageChange}
                  error={formik.errors.profile_images}
                  value={[]}
                  mode='addAbout'
                  name='addAbout'
                  hideBrowse={
                    { maxLength: 1 }
                  }
                />}
            </UploadImageWrapper>
          </FormControl>
          <FormControl>
            <FormHeading>{t('pageBuilderAbout.profileStoreTitle')}</FormHeading>
            <MuiTextField
              error={Boolean(
                formik.touched.profile_title && formik.errors.profile_title
              )}
              helperText={formik.touched.profile_title && formik.errors.profile_title}
              fullWidth
              margin='normal'
              name='profile_title'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.profile_title}
              variant='outlined'
              size='small'
              placeholder={t('pageBuilderAbout.profileTitle')}
            />
          </FormControl>
          <FormControl>
            <FormHeading>{t('pageBuilderAbout.profileDescription')}</FormHeading>
            <MuiTextField
              error={Boolean(
                formik.touched.profile_description && formik.errors.profile_description
              )}
              helperText={formik.touched.profile_description && formik.errors.profile_description}
              fullWidth
              margin='normal'
              name='profile_description'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.profile_description}
              variant='outlined'
              size='small'
              placeholder={t('pageBuilderAbout.enterProfileDescription')}
              multiline
              rows={4}
              InputProps={{
                endAdornment: (<InputAdornments position='end'>{formik.values.profile_description.length} - 400</InputAdornments>)
              }}
            />
          </FormControl>
        </SectionContent>

        <SectionContent>
          <FormControl>
            <FormHeading>{t('pageBuilderAbout.contactInformation')}</FormHeading>
            <MuiTextField
              error={Boolean(
                formik.touched.contact_phone && formik.errors.contact_phone
              )}
              helperText={formik.touched.contact_phone && formik.errors.contact_phone}
              fullWidth
              margin='normal'
              name='contact_phone'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.contact_phone}
              variant='outlined'
              size='small'
              placeholder={t('pageBuilderAbout.enterPhoneNumber')}
              icon={{
                direction: 'left',
                src: iconPhone
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornments position='end'>
                    {
                      clicks.mobile > 0 &&
                        <CountContainer>
                          <img src={mask} alt='maskIcon' />
                          <p>{clicks.mobile}</p>
                        </CountContainer>
                    }
                  </InputAdornments>
                )
              }}
            />
            <MuiTextField
              error={Boolean(
                formik.touched.contact_whatsapp && formik.errors.contact_whatsapp
              )}
              helperText={formik.touched.contact_whatsapp && formik.errors.contact_whatsapp}
              fullWidth
              margin='normal'
              name='contact_whatsapp'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.contact_whatsapp}
              variant='outlined'
              size='small'
              placeholder={t('pageBuilderAbout.enterWhatsappNumber')}
              icon={{
                direction: 'left',
                src: iconWhatsapp
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornments position='end'>
                    {
                      clicks.whatsapp > 0 &&
                        <CountContainer>
                          <img src={mask} alt='maskIcon' />
                          <p>{clicks.whatsapp}</p>
                        </CountContainer>
                    }
                  </InputAdornments>
                )
              }}
            />
            <MuiTextField
              error={Boolean(
                formik.touched.contact_email && formik.errors.contact_email
              )}
              helperText={formik.touched.contact_email && formik.errors.contact_email}
              fullWidth
              margin='normal'
              name='contact_email'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.contact_email}
              variant='outlined'
              size='small'
              placeholder={t('pageBuilderAbout.enterEmailId')}
              icon={{
                direction: 'left',
                src: iconEmail
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornments position='end'>
                    {
                      clicks.email > 0 &&
                        <CountContainer>
                          <img src={mask} alt='maskIcon' />
                          <p>{clicks.email}</p>
                        </CountContainer>
                    }
                  </InputAdornments>
                )
              }}
            />
          </FormControl>
        </SectionContent>

        <SectionContent>
          <FormControl>
            <FormHeading>{t('pageBuilderAbout.socialProfile')}</FormHeading>
            <MuiTextField
              error={Boolean(
                formik.touched.social_instagram && formik.errors.social_instagram
              )}
              helperText={formik.touched.social_instagram && formik.errors.social_instagram}
              fullWidth
              margin='normal'
              name='social_instagram'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.social_instagram}
              variant='outlined'
              size='small'
              placeholder={t('pageBuilderAbout.enterInstagramURL')}
              icon={{
                direction: 'left',
                src: iconInstagram
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornments position='end'>
                    {
                      clicks.instagram > 0 &&
                        <CountContainer>
                          <img src={mask} alt='maskIcon' />
                          <p>{clicks.instagram}</p>
                        </CountContainer>
                    }
                  </InputAdornments>
                )
              }}
            />
            <MuiTextField
              error={Boolean(
                formik.touched.social_facebook && formik.errors.social_facebook
              )}
              helperText={formik.touched.social_facebook && formik.errors.social_facebook}
              fullWidth
              margin='normal'
              name='social_facebook'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.social_facebook}
              variant='outlined'
              size='small'
              placeholder={t('pageBuilderAbout.enterFacebookURL')}
              icon={{
                direction: 'left',
                src: iconFacebook
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornments position='end'>
                    {
                      clicks.facebook > 0 &&
                        <CountContainer>
                          <img src={mask} alt='maskIcon' />
                          <p>{clicks.facebook}</p>
                        </CountContainer>
                    }
                  </InputAdornments>
                )
              }}
            />
            {viewAllSocial &&
              <>
                <MuiTextField
                  error={Boolean(
                    formik.touched.social_tiktok && formik.errors.social_tiktok
                  )}
                  helperText={formik.touched.social_tiktok && formik.errors.social_tiktok}
                  fullWidth
                  margin='normal'
                  name='social_tiktok'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='text'
                  value={formik.values.social_tiktok}
                  variant='outlined'
                  size='small'
                  placeholder={t('pageBuilderAbout.enterTiktokURL')}
                  icon={{
                    direction: 'left',
                    src: iconTiktok
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornments position='end'>
                        {
                          clicks.tiktok > 0 &&
                            <CountContainer>
                              <img src={mask} alt='maskIcon' />
                              <p>{clicks.tiktok}</p>
                            </CountContainer>
                        }
                      </InputAdornments>
                    )
                  }}
                />
                <MuiTextField
                  error={Boolean(
                    formik.touched.social_youtube && formik.errors.social_youtube
                  )}
                  helperText={formik.touched.social_youtube && formik.errors.social_youtube}
                  fullWidth
                  margin='normal'
                  name='social_youtube'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='text'
                  value={formik.values.social_youtube}
                  variant='outlined'
                  size='small'
                  placeholder={t('pageBuilderAbout.enterYoutubeURL')}
                  icon={{
                    direction: 'left',
                    src: iconYoutube
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornments position='end'>
                        {
                          clicks.youtube > 0 &&
                            <CountContainer>
                              <img src={mask} alt='maskIcon' />
                              <p>{clicks.youtube}</p>
                            </CountContainer>
                        }
                      </InputAdornments>
                    )
                  }}
                />
                <MuiTextField
                  error={Boolean(
                    formik.touched.social_snapchat && formik.errors.social_snapchat
                  )}
                  helperText={formik.touched.social_snapchat && formik.errors.social_snapchat}
                  fullWidth
                  margin='normal'
                  name='social_snapchat'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='text'
                  value={formik.values.social_snapchat}
                  variant='outlined'
                  size='small'
                  placeholder={t('pageBuilderAbout.enterSnapchatURL')}
                  icon={{
                    direction: 'left',
                    src: iconSnapchat
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornments position='end'>
                        {
                          clicks.snapchat > 0 &&
                            <CountContainer>
                              <img src={mask} alt='maskIcon' />
                              <p>{clicks.snapchat}</p>
                            </CountContainer>
                        }
                      </InputAdornments>
                    )
                  }}
                />
                <MuiTextField
                  error={Boolean(
                    formik.touched.social_telegram && formik.errors.social_telegram
                  )}
                  helperText={formik.touched.social_telegram && formik.errors.social_telegram}
                  fullWidth
                  margin='normal'
                  name='social_telegram'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='text'
                  value={formik.values.social_telegram}
                  variant='outlined'
                  size='small'
                  placeholder={t('pageBuilderAbout.enterTelegramURL')}
                  icon={{
                    direction: 'left',
                    src: iconTelegram
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornments position='end'>
                        {
                          clicks.telegram > 0 &&
                            <CountContainer>
                              <img src={mask} alt='maskIcon' />
                              <p>{clicks.telegram}</p>
                            </CountContainer>
                        }
                      </InputAdornments>
                    )
                  }}
                />
              </>}
            <ViewMoreWrapper>
              <MuiButton size='small' onClick={() => setViewAllSocial(!viewAllSocial)}>{viewAllSocial ? t('pageBuilderAbout.viewLess') : t('pageBuilderAbout.viewAll')}</MuiButton>
            </ViewMoreWrapper>
          </FormControl>
        </SectionContent>

        <SectionContent>
          <FormControl>
            <FormHeading>{t('pageBuilderAbout.marketPlace')}</FormHeading>
            <MuiTextField
              error={Boolean(
                formik.touched.market_shopee && formik.errors.market_shopee
              )}
              helperText={formik.touched.market_shopee && formik.errors.market_shopee}
              fullWidth
              margin='normal'
              name='market_shopee'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.market_shopee}
              variant='outlined'
              size='small'
              placeholder={t('pageBuilderAbout.enterShopeeURL')}
              icon={{
                direction: 'left',
                src: logoShopee
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornments position='end'>
                    {
                      clicks.shopee > 0 &&
                        <CountContainer>
                          <img src={mask} alt='maskIcon' />
                          <p>{clicks.shopee}</p>
                        </CountContainer>
                    }
                  </InputAdornments>
                )
              }}
            />
            <MuiTextField
              error={Boolean(
                formik.touched.market_tokopedia && formik.errors.market_tokopedia
              )}
              helperText={formik.touched.market_tokopedia && formik.errors.market_tokopedia}
              fullWidth
              margin='normal'
              name='market_tokopedia'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.market_tokopedia}
              variant='outlined'
              size='small'
              placeholder={t('pageBuilderAbout.enterTokopediaURL')}
              icon={{
                direction: 'left',
                src: logoTokopedia
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornments position='end'>
                    {
                      clicks.tokopedia > 0 &&
                        <CountContainer>
                          <img src={mask} alt='maskIcon' />
                          <p>{clicks.tokopedia}</p>
                        </CountContainer>
                    }
                  </InputAdornments>
                )
              }}
            />
            {viewAllMarket &&
              <>
                <MuiTextField
                  error={Boolean(
                    formik.touched.market_lazada && formik.errors.market_lazada
                  )}
                  helperText={formik.touched.market_lazada && formik.errors.market_lazada}
                  fullWidth
                  margin='normal'
                  name='market_lazada'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='text'
                  value={formik.values.market_lazada}
                  variant='outlined'
                  size='small'
                  placeholder={t('pageBuilderAbout.enterLazadaURL')}
                  icon={{
                    direction: 'left',
                    src: logoLazada
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornments position='end'>
                        {
                          clicks.lazada > 0 &&
                            <CountContainer>
                              <img src={mask} alt='maskIcon' />
                              <p>{clicks.lazada}</p>
                            </CountContainer>
                        }
                      </InputAdornments>
                    )
                  }}
                />
                <MuiTextField
                  error={Boolean(
                    formik.touched.market_blibli && formik.errors.market_blibli
                  )}
                  helperText={formik.touched.market_blibli && formik.errors.market_blibli}
                  fullWidth
                  margin='normal'
                  name='market_blibli'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='text'
                  value={formik.values.market_blibli}
                  variant='outlined'
                  size='small'
                  placeholder={t('pageBuilderAbout.enterBlibliURL')}
                  icon={{
                    direction: 'left',
                    src: logoBlibli
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornments position='end'>
                        {
                          clicks.blibli > 0 &&
                            <CountContainer>
                              <img src={mask} alt='maskIcon' />
                              <p>{clicks.blibli}</p>
                            </CountContainer>
                        }
                      </InputAdornments>
                    )
                  }}
                />
                <MuiTextField
                  error={Boolean(
                    formik.touched.market_bukallapak && formik.errors.market_bukallapak
                  )}
                  helperText={formik.touched.market_bukallapak && formik.errors.market_bukallapak}
                  fullWidth
                  margin='normal'
                  name='market_bukallapak'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='text'
                  value={formik.values.market_bukallapak}
                  variant='outlined'
                  size='small'
                  placeholder={t('pageBuilderAbout.enterBukalapakURL')}
                  icon={{
                    direction: 'left',
                    src: logoBukalapak
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornments position='end'>
                        {
                          clicks.bukallapak > 0 &&
                            <CountContainer>
                              <img src={mask} alt='maskIcon' />
                              <p>{clicks.bukallapak}</p>
                            </CountContainer>
                        }
                      </InputAdornments>
                    )
                  }}
                />
              </>}
            <ViewMoreWrapper>
              <MuiButton size='small' onClick={() => setViewAllMarket(!viewAllMarket)}>{viewAllMarket ? t('pageBuilderAbout.viewLess') : t('pageBuilderAbout.viewAll')}</MuiButton>
            </ViewMoreWrapper>
          </FormControl>
        </SectionContent>

        <SectionContent>
          <FormControl>
            {/* <Grid container spacing={2} columns={16}> */}
            {/* <Grid item xs={16} md={8}>
                <FormHeading>{t('pageBuilderAbout.paymentAccepted')}</FormHeading>
                <MuiSelect
                  error={formik.errors.paymentAccepted}
                  placeholder='Select'
                  value={formik.values.paymentAccepted}
                  multiline
                  name='paymentAccepted'
                  size='small'
                  // onSelect={(value) => { handleCategorySelected(value) }}
                  onChange={(e) => formik.handleChange(e)}
                  data={['DOKU', 'GoPay', 'Visa', 'Mastercard']}
                  dataType='string'
                  sx={{ minWidth: '250px' }}
                  isRequired
                />
              </Grid> */}
            <Grid item xs={16} md={8}>
              <FormHeading>{t('pageBuilderAbout.appLink')}</FormHeading>
              <MuiTextField
                error={Boolean(
                  formik.touched.app_playstore && formik.errors.app_playstore
                )}
                helperText={formik.touched.app_playstore && formik.errors.app_playstore}
                fullWidth
                margin='normal'
                name='app_playstore'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type='text'
                value={formik.values.app_playstore}
                variant='outlined'
                size='small'
                placeholder={t('pageBuilderAbout.enterPlaystoreURL')}
                icon={{
                  direction: 'left',
                  src: iconPlaystore
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornments position='end'>
                      {
                        clicks.playstore > 0 &&
                          <CountContainer>
                            <img src={mask} alt='maskIcon' />
                            <p>{clicks.playstore}</p>
                          </CountContainer>
                      }
                    </InputAdornments>
                  )
                }}
              />
              <MuiTextField
                error={Boolean(
                  formik.touched.app_appstore && formik.errors.app_appstore
                )}
                helperText={formik.touched.app_appstore && formik.errors.app_appstore}
                fullWidth
                margin='normal'
                name='app_appstore'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type='text'
                value={formik.values.app_appstore}
                variant='outlined'
                size='small'
                placeholder={t('pageBuilderAbout.enterAppstoreURL')}
                icon={{
                  direction: 'left',
                  src: iconAppstore
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornments position='end'>
                      {
                        clicks.appstore > 0 &&
                          <CountContainer>
                            <img src={mask} alt='maskIcon' />
                            <p>{clicks.appstore}</p>
                          </CountContainer>
                      }
                    </InputAdornments>
                  )
                }}
              />
            </Grid>
            {/* </Grid> */}
          </FormControl>
        </SectionContent>

        <MobileShareButtons>
          <DivWraper>
            <a href={copyTextValue} onClick={openLinkStore} target='_blank' rel='noreferrer'>{copyTextValue}</a>
          </DivWraper>
          <Box>
            <Grid container>
              <Grid item sm={6} xs={6}>
                <RWebShare
                  data={{
                  // text: 'Like humans, flamingos make friends for life',
                    url: copyTextValue
                    // title: 'Flamingos'
                  }}
                  onClick={copyText}
                >
                  <MuiButton styled='pageBuilderAboutWhite' variant='contained'>{t('product-details-share')}</MuiButton>
                </RWebShare>
              </Grid>
              <Grid item sm={6} xs={6}>
                <MuiButton disabled={pageBuilderAboutCreateIsLoading} isLoading={pageBuilderAboutCreateIsLoading} styled='pageBuilderAboutBlue' variant='contained' type='submit'>{t('product-details-save')}</MuiButton>
              </Grid>
            </Grid>
          </Box>
        </MobileShareButtons>

        <WebShareButtons>
          <MuiButton disabled={pageBuilderAboutCreateIsLoading} isLoading={pageBuilderAboutCreateIsLoading} styled='primary' variant='contained' type='submit'>{t('product-details:save')}</MuiButton>
        </WebShareButtons>
      </form>
    </PageBuilderWrapper>
  )
}

export default PageBuilderAbout
