import React, { memo, useState } from 'react'

import { InputLabel, MenuItem, FormControl, Select } from '@mui/material'

import {
  Modal,
  ModalContent,
  CloseButton,
  CancelOrderParent,
  CancelOrderButton,
  FormControlParent,
  CancelOrderHeading
} from './style'

const CancelOrder = ({
  setShowModal,
  marketplaceStoreId,
  orderId,
  itemIds,
  onPost
}) => {
  const [reason, setReason] = useState('')
  const [dropDownError, setDropDownError] = useState(false)
  const [currentItem, setCurrentItem] = useState('')
  const [currentItemError, setCurrentItemError] = useState(false)

  const handleChangeItemId = event => {
    setCurrentItem(event.target.value)
    setCurrentItemError(false)
  }

  const handleChange = (event) => {
    setReason(event.target.value)
    setDropDownError(false)
  }

  const onCancelBtnClick = () => {
    if (!reason) setDropDownError(true)
    else if (currentItem === '' && reason === 'OUT_OF_STOCK') setCurrentItemError(true)
    else {
      let args = { orderId, marketplaceStoreId, reason }
      if (reason === 'OUT_OF_STOCK') {
        const [itemId, itemModelId] = currentItem.split(',')
        args = { ...args, itemId, itemModelId }
      }
      onPost(args)
    }
  }

  const reasons = [
    { label: 'Out of Stock', value: 'OUT_OF_STOCK' },
    { label: 'Customer Request', value: 'CUSTOMER_REQUEST' },
    { label: 'Undeliverable Area', value: 'UNDELIVERABLE_AREA' },
    { label: 'COD Not Supported', value: 'COD_NOT_SUPPORTED' }
  ]

  return (
    <Modal>
      <ModalContent>
        <CancelOrderHeading>
          Cancel Order
        </CancelOrderHeading>
        <CloseButton onClick={() => setShowModal(false)}>×</CloseButton>
        <FormControlParent>
          <FormControl fullWidth error={dropDownError}>
            <InputLabel id='reason-select-label'>Reason</InputLabel>
            <Select
              labelId='reason-select-label'
              id='reason-select'
              value={reason}
              label='Reason'
              onChange={handleChange}
            >
              {reasons.map(({ label, value }) => (
                <MenuItem
                  key={value}
                  value={value}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormControlParent>
        {reason === 'OUT_OF_STOCK' &&
          <FormControlParent>
            <FormControl fullWidth error={currentItemError}>
              <InputLabel id='current-item-select-label'>Item ID</InputLabel>
              <Select
                labelId='current-item-select-label'
                id='current-item-select'
                value={currentItem}
                label='itemId'
                onChange={handleChangeItemId}
              >
                {itemIds.map(({ itemId, modelId }, index) => (
                  <MenuItem
                    key={index}
                    value={itemId + ',' + modelId}
                  >
                    {itemId}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControlParent>}
        <CancelOrderParent>
          <CancelOrderButton
            onClick={onCancelBtnClick}
          >
            Cancel Order
          </CancelOrderButton>
        </CancelOrderParent>
      </ModalContent>
    </Modal>
  )
}

export default memo(CancelOrder)
