import { styled } from '@mui/system'

const media = {
  large_laptop: '@media(max-width: 2560px)',
  laptop: '@media(max-width: 1000px)',
  tablet: '@media(max-width: 768px)',
  mobile: '@media(max-width: 600px)'
}

export const Main = styled('div')(`
  height: 80%;
  display: flex;
`)

export const Title = styled('h1')`
  height: 41px;
  font: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 41px;
  color: #360D20;
  margin: auto;
  ${media.tablet}{
  font-size: 24px;
}
`
