import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Paper } from '@mui/material'
import Shimmer from "react-shimmer-effect";
import {
  Main, DashboardTitle, GridTop, GridM, BoxM, ItemM, AggCardTitle, AggCardValue, AggCardImg, AggCardImprove, GridSelect, SelectWrapper, SelectLabel, GraphTitle, PieGraphTitle
  , GraphGrid, SectionTitleGrid, GridP, ShimmerView, ShimmerGrid, LegendColorBox, ChartLegend, LegendGroup, AggCardValuesGrid
} from './style'
import VerticalBarChartTitleRow from './VerticalBarChartTitleRow'
import MonthlyRevenueBarChart from './MonthlyRevenueBarChart'
import CouponRevenuePieChart from './CouponRevenuePieChart'
import VerticalBarChart from './VerticalBarChart'
import { getMonth, getYear, updateObject, addPreFix, getBarData, sortList, numberFormatter, getMonths, getYears, checkIsExist, removeKey } from "../../utilities/utils";

// icons
import MuiSelect from '../../components/MuiSelect/MuiSelect'
import SearchAutoComplete from '../../components/AutoComplete/SearchAutoComplete'
import dailyRevenueImg from '../../assets/images/brandDashboard/dailyRevenue.svg'
import dailyUnitSoldImg from '../../assets/images/brandDashboard/dailyUnitSold.svg'
import totalUnitsSoldImg from '../../assets/images/brandDashboard/totalUnitsSold.svg'
import raenaCostImg from '../../assets/images/brandDashboard/raenaCost.svg'
import monthlyRevenueImg from '../../assets/images/brandDashboard/monthlyRevenue.svg'


const CURRENT_MONTH = getMonth('MMMM');
const CURRENT_YEAR = getYear();

const ALL_MONTHS = getMonths();
const ALL_YEARS = getYears();

const initial = {
  year: CURRENT_YEAR,
  month: CURRENT_MONTH
};

const BrandDashboard = (props) => {
  const { userName, getDashboards, isAPISuccess, isAPIError, provinces, selectedBrand,
    getUnits, isDayUnit, isSkuLoaded, skuDetails, getRevenueDetails, isRevenueLoading,
    revenueDetails, isTotalUnit, totalUnits, isDayRevenue, dayRevenue,
    isMonthRevenue, monthRevenue, isMonthShipping, monthShipping, dayUnits, isSkuDataLoading, skuData,
    isProvinceLoading, provinceData, isCategoryLoading, categoryData, isProductLoading, productData, isSkuDataLoaded, isProvinceLoaded,
    isCategoryLoaded, isProductLoaded, getMTD
  } = props;


  const [skuList, setSkuList] = useState([]);
  const [dashboardParam, setDashboardParam] = useState({
    year: CURRENT_YEAR
  });
  const [skuBarData, setSkuBarData] = useState({});
  const [provinceBarData, setProvinceBarData] = useState([]);
  const [categoryBarData, setCategoryBarData] = useState([]);
  const [productBarData, setProductBarData] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [isSort, setIsSort] = useState(false);
  const [isDefault, setIsDefault] = useState(true);
  const [isClearMonth, setIsClearMonth] = useState(false);
  const [isMonthSelected, updateMonthSelection] = useState(false);

  const { t,i18n } = useTranslation()
  useEffect(() => {
    if (selectedBrand) {
      loadDefaultState(initial);
    }
  }, [selectedBrand?.id])

  useEffect(() => {
    if (isSkuLoaded) {
      const list = skuDetails?.map((item) => ({ label: item.sku + ' : ' + item.name, value: item.sku }));
      setSkuList(list);
    }
    if (provinces) {
      setProvinceList(provinces);
    }
  }, [isAPISuccess, isSkuLoaded]);


  const loadDefaultState = () => {
    getDashboards(selectedBrand?.id);
    const filter = removeKey(initial, 'month');
    getMTD(selectedBrand?.id, filter)
    getRevenueDetails(selectedBrand?.id, filter);
    getUnits(selectedBrand?.id, {});
    setIsClearMonth(true);
    setIsDefault(true);
    setDashboardParam(filter);
  }

  useEffect(() => {
    if (isSkuDataLoaded) {
      const list = getBarData(skuData);
      setSkuBarData({ isSorted: false, data: list });
    }
    if (isProvinceLoaded) {
      const list = getBarData(provinceData);
      setProvinceBarData({ isSorted: false, data: list });
    }
    if (isCategoryLoaded) {
      const list = getBarData(categoryData);
      setCategoryBarData({ isSorted: false, data: list });
    }
    if (isProductLoaded) {
      const list = getBarData(productData);
      setProductBarData({ isSorted: false, data: list });
    }
  }, [isSkuDataLoaded, isProvinceLoaded, isCategoryLoaded, isProductLoaded]);

  useEffect(() => {
    if (isAPIError) {
    }
  }, [isAPIError]);

  const callAPIs = (input, isDefault) => {
    getDashboards(selectedBrand?.id);
    getMTD(selectedBrand?.id, input);
    if (isDefault) {
      let json = { ...input };
      json = removeKey(json, 'month');
      getRevenueDetails(selectedBrand?.id, json);
      json = removeKey(json, 'year');
      getUnits(selectedBrand?.id, json);
    } else {
      getRevenueDetails(selectedBrand?.id, input);
      getUnits(selectedBrand?.id, input);
    }
  };


  const handleSKUChange = (sku) => {
    let data = { ...dashboardParam }
    if(sku){
      data = updateObject(dashboardParam, 'sku', sku);
    }else{
      data = removeKey(data, 'sku');
    }
    if(!isMonthSelected) data = removeKey(data, 'month');
    callAPIs(data, isDefault);
    setDashboardParam(data);
    setIsClearMonth(false);
  }

  const handleProvinceChange = (selected) => {
    let data = { ...dashboardParam }
    if(selected){
      data = updateObject(dashboardParam, 'province', selected);
    }else{
      data = removeKey(data, 'province');
    }
    if(!isMonthSelected) data = removeKey(data, 'month');
    callAPIs(data, isDefault);
    setDashboardParam(data);
    setIsClearMonth(false);
  }

  const handleMonthChange = (month) => {
    dashboardParam['month'] = month;
    setDashboardParam(dashboardParam);
    setIsDefault(false);
    setIsClearMonth(false);
    updateMonthSelection(true);
    callAPIs(dashboardParam, false);
  }

  const handleYearChange = (currentSelectedYear) => {
    dashboardParam['year'] = currentSelectedYear;
    setDashboardParam(dashboardParam);
    setIsDefault(false);
    setIsClearMonth(false);
    callAPIs(dashboardParam, false);
  }

  const handleUpClick = (type, barChart, key) => {
    switch (type) {
      case 1:
        const list = sortList(barChart?.data, key, "DES");
        setSkuBarData({ isSorted: true, data: list, order: "DES", key });
        break
      case 2:
        const provinceList = sortList(barChart?.data, key, "DES");
        setProvinceBarData({ isSorted: true, data: provinceList, order: "DES", key });
        break
      case 3:
        const categoryList = sortList(barChart?.data, key, "DES");
        setCategoryBarData({ isSorted: true, data: categoryList, order: "DES", key });
        break
      case 4:
        const productList = sortList(barChart?.data, key, "DES");
        setProductBarData({ isSorted: true, data: productList, order: "DES", key });
        break
      default:
    }
    setIsSort(true);
  }

  const handleDownClick = (type, barChart, key) => {
    switch (type) {
      case 1:
        const list = sortList(barChart?.data, key, "AES");
        setSkuBarData({ isSorted: true, data: list, order: "AES", key });
        break
      case 2:
        const provinceList = sortList(barChart?.data, key, "AES");
        setProvinceBarData({ isSorted: true, data: provinceList, order: "AES", key });
        break
      case 3:
        const categoryList = sortList(barChart?.data, key, "AES");
        setCategoryBarData({ isSorted: true, data: categoryList, order: "AES", key });
        break
      case 4:
        const productList = sortList(barChart?.data, key, "AES");
        setProductBarData({ isSorted: true, data: productList, order: "AES", key });
        break
      default:
    }
    setIsSort(true);
  }

  const clearSorting = (type) => {
    switch (type) {
      case 1:
        const list = getBarData(skuData);
        setSkuBarData({ isSorted: false, data: list, order: "", key: null });
        break
      case 2:
        const provinceList = getBarData(provinceData);
        setProvinceBarData({ isSorted: false, data: provinceList, order: "", key: null });
        break
      case 3:
        const categoryList = getBarData(categoryData);
        setCategoryBarData({ isSorted: false, data: categoryList, order: "", key: null });
        break
      case 4:
        const productList = getBarData(productData);
        setProductBarData({ isSorted: false, data: productList, order: "", key: null });
        break
      default:
    }
    setIsSort(true);
  }
  const getUnitsTitle = (type, title) => {
    let custom = '';
    if (checkIsExist(dashboardParam, 'month') && !isDefault) {
      custom = (type === 1 && `${t('brandDashboard.monthly')} ${title}`) || (type === 2 && `${t('brandDashboard.dayAvg')} ${title}`);
    } else {
      custom = (type === 1 && `${t('brandDashboard.mtd')} ${title}`) || (type === 2 && `${t('brandDashboard.yesterday')} ${title}`);
    }
    return custom;
  }

  const Legend = () => (<LegendGroup>
    <ChartLegend key={0}><LegendColorBox backgroundColor={"#4880FF"} ></LegendColorBox>{t(`brandDashboard.totalRevenue`)} (Rp)</ChartLegend>
    <ChartLegend key={1}><LegendColorBox backgroundColor={"#F56A83"} ></LegendColorBox>{t(`brandDashboard.shippingCost`)} (Rp)</ChartLegend>
  </LegendGroup>)

  return (
    <Main>
      <GridTop container>
        <Grid item md={4} sm={12} xs={12}>
          <DashboardTitle>
            {t('dashboard.welcome')}! {userName}
          </DashboardTitle>
        </Grid>
        <GridSelect item md={4}>
          <SelectWrapper>
            <SelectLabel>SKU</SelectLabel>
            <SearchAutoComplete
              searchId="sku"
              handleChange={(value) => handleSKUChange(value)}
              listData={skuList}
              noOption={t('brandDashboard.noSKU')}
              textLabel="All"
              size="small"
              isFilterClear={isClearMonth}
            />
          </SelectWrapper>
        </GridSelect>
        <GridSelect item md={2}>
          <SelectWrapper>
            <SelectLabel>{t('brandDashboard.province')}</SelectLabel>
            <SearchAutoComplete
              searchId="province"
              handleChange={(value) => handleProvinceChange(value)}
              listData={provinceList}
              noOption={t('brandDashboard.noProvince')}
              textLabel="All"
              size="small"
              isFilterClear={isClearMonth}
            />
          </SelectWrapper>
        </GridSelect>
        <GridSelect item md={2}>
          <SelectWrapper>
            <SelectLabel>{t('brandDashboard.month')}</SelectLabel>
            <MuiSelect
              placeholder={t('brandPromo.select')}
              value={null}
              name='brands'
              size='small'
              onChange={(e) => { handleMonthChange(e.target.value) }}
              data={ALL_MONTHS}
              selectlabel='name'
              selectvalue='id'
              sx={{ minWidth: '120px' }}
              md={{ width: '120px' }}
              isPlaceHolder={true}
              isClear={isClearMonth}
            />
          </SelectWrapper>
        </GridSelect>
        <GridSelect item md={2}>
          <SelectWrapper>
            <SelectLabel>{t('brandDashboard.year')}</SelectLabel>
            <MuiSelect
              placeholder={t('brandPromo.select')}
              value={CURRENT_YEAR}
              name='brands'
              size='small'
              onChange={(e) => { handleYearChange(e.target.value) }}
              data={ALL_YEARS}
              selectlabel='name'
              selectvalue='id'
              sx={{ minWidth: '120px' }}
              md={{ width: '120px' }}
              isPlaceHolder={true}
              // isClear={isClearMonth}
            />
          </SelectWrapper>
        </GridSelect>
      </GridTop>
      <BoxM>
        <GridM container>
          <GridM item xs={6} md>
            {isTotalUnit ? (
              <Shimmer>
                <ShimmerView backgroundColor='#FFF'>
                </ShimmerView>
              </Shimmer>) :
              (
                <ItemM backgroundColor='#625CE0'>
                  <Grid container>
                    <AggCardTitle height={i18n.language==='id' && '38px'}>{getUnitsTitle(1, t('brandDashboard.units'))}</AggCardTitle>
                  </Grid>
                  <AggCardValuesGrid container>
                    <AggCardImg src={totalUnitsSoldImg} alt='totalUnitsSoldImg' />
                    <AggCardValue>
                      {numberFormatter(totalUnits?.unitsSold, i18n)}
                    </AggCardValue>
                    <AggCardImprove>
                      {totalUnits?.change && `${addPreFix(totalUnits?.change?.value)}%`}
                    </AggCardImprove>
                  </AggCardValuesGrid>
                </ItemM>
              )
            }
          </GridM>
          <GridM item xs={6} md>
            {isMonthRevenue ? (<Shimmer>
              <ShimmerView backgroundColor='#FFF'>
              </ShimmerView>
            </Shimmer>) : (
                <ItemM backgroundColor='#FAB912'>
                  <AggCardTitle height={i18n.language==='id' && '38px'}>{getUnitsTitle(1, t('brandDashboard.revenue'))}</AggCardTitle>
                  <AggCardValuesGrid container>
                    <AggCardImg src={monthlyRevenueImg} alt='monthlyRevenueImg' />
                    <AggCardValue>
                      {numberFormatter(monthRevenue?.revenue, i18n)}
                    </AggCardValue>
                    <AggCardImprove>
                      {monthRevenue?.change && `${addPreFix(monthRevenue?.change?.value)}%`}
                    </AggCardImprove>
                  </AggCardValuesGrid>
                </ItemM>
              )}
          </GridM>
          <GridM item xs={6} md>
            {
              isMonthShipping ? (
                <Shimmer>
                  <ShimmerView backgroundColor='#FFF'>
                  </ShimmerView>
                </Shimmer>
              ) : (
                  <ItemM backgroundColor='#F56A83'>
                    <AggCardTitle height={i18n.language==='id' && '38px'}>{getUnitsTitle(1, t('brandDashboard.raenaCost'))}</AggCardTitle>
                    <AggCardValuesGrid container>
                      <AggCardImg src={raenaCostImg} alt='raenasCostImg' />
                      <AggCardValue>
                        {numberFormatter(monthShipping?.shippingCost, i18n)}
                      </AggCardValue>
                      <AggCardImprove>
                        {monthShipping?.change && `${addPreFix(monthShipping?.change?.value)}%`}
                      </AggCardImprove>
                    </AggCardValuesGrid>
                  </ItemM>
                )
            }

          </GridM>
          <GridM item xs={6} md>
            {
              isDayUnit ? (
                <Shimmer>
                  <ShimmerView backgroundColor='#FFF'>
                  </ShimmerView>
                </Shimmer>
              ) : (
                  <ItemM backgroundColor='#5BB9E1'>
                    <AggCardTitle height={ i18n.language==='id' && '38px'}>{getUnitsTitle(2, `${t('brandDashboard.units')}`)}</AggCardTitle>
                    <AggCardValuesGrid container>
                      <AggCardImg src={dailyUnitSoldImg} alt='dailyUnitSoldImg' />
                      <AggCardValue>
                        {numberFormatter(dayUnits?.unitsSold, i18n)}
                      </AggCardValue>
                      <AggCardImprove>
                        {dayUnits?.change && `${addPreFix(dayUnits?.change?.value)}%`}
                      </AggCardImprove>
                    </AggCardValuesGrid>
                  </ItemM>
                )
            }

          </GridM>
          <GridM item xs={6} md>
            {
              isDayRevenue ? (
                <Shimmer>
                  <ShimmerView backgroundColor='#FFF'>
                  </ShimmerView>
                </Shimmer>
              ) : (
                  <ItemM backgroundColor='#43C88B'>
                    <AggCardTitle height={ i18n.language==='id' && '38px'}>{getUnitsTitle(2, `${t('brandDashboard.revenue')}`)}</AggCardTitle>
                    <AggCardValuesGrid container>
                      <AggCardImg src={dailyRevenueImg} alt='dailyRevenueImg' />
                      <AggCardValue>
                        {numberFormatter(dayRevenue?.revenue, i18n)}
                      </AggCardValue>
                      <AggCardImprove>
                        {dayRevenue?.change && `${addPreFix(dayRevenue?.change?.value)}%`}
                      </AggCardImprove>
                    </AggCardValuesGrid>
                  </ItemM>
                )
            }
          </GridM>
        </GridM>
      </BoxM>
      <BoxM>
        <Paper>
          <GridM container>
            <GridM item sm={12} xs={12} md={12}>
              <SectionTitleGrid container>
                <GraphTitle item md={12} sm={9} xs={8}>{t('brandDashboard.revenue')}</GraphTitle>
              </SectionTitleGrid>
              <GraphGrid>
                {isRevenueLoading ? (
                  <Shimmer>
                    <ShimmerGrid>
                    </ShimmerGrid>
                  </Shimmer>
                ) : <MonthlyRevenueBarChart
                    barData={revenueDetails}
                    isDefault={isDefault}
                  />}
              </GraphGrid>
            </GridM>
            {false &&
              <GridM item sm={12} xs={12} md={5}>
                <PieGraphTitle>Coupon Used (Revenue Wise)</PieGraphTitle>
                <CouponRevenuePieChart
                  barData={skuData}
                />
                <GridM container>
                  <GridM item sm={12} xs={12} md={6} />
                  <GridM item sm={12} xs={12} md={6} />
                </GridM>
              </GridM>}
          </GridM>
        </Paper>
      </BoxM>
      <BoxM>
        <GridM container>
          <GridP item sm={12} xs={12} md={6}>
            <Paper>
              <VerticalBarChartTitleRow
                barData={skuBarData}
                handleUpClick={(key) => {
                  handleUpClick(1, skuBarData, key)
                }}
                handleDownClick={(key) => {
                  handleDownClick(1, skuBarData, key)
                }}
                clearSorting={() => {
                  clearSorting(1)
                }}
                leftTitle={t('brandDashboard.sku')}
                rightTitle={t('brandDashboard.revenue')}
              />
              <GraphGrid>
                {isSkuDataLoading ? (<Shimmer>
                  <ShimmerGrid>
                  </ShimmerGrid>
                </Shimmer>) : <VerticalBarChart
                    barData={skuBarData}
                    type="SKU"
                    graph={1}
                    YAxisProps={{ width: 85 }}
                    tickTruncateTextSize={8}
                    tickSpaceFromBar={60}
                  />}
              </GraphGrid>
              {Legend()}
            </Paper>
          </GridP>
          <GridP item sm={12} xs={12} md={6}>
            <Paper>
              <VerticalBarChartTitleRow
                barData={provinceBarData}
                handleUpClick={(key) => {
                  handleUpClick(2, provinceBarData, key)
                }}
                handleDownClick={(key) => {
                  handleDownClick(2, provinceBarData, key)
                }}
                clearSorting={() => {
                  clearSorting(2)
                }}
                leftTitle={t('brandDashboard.province')}
                rightTitle={t('brandDashboard.revenue')}
              />
              <GraphGrid>
                {isProvinceLoading ? (<Shimmer>
                  <ShimmerGrid>
                  </ShimmerGrid>
                </Shimmer>) : <VerticalBarChart
                    barData={provinceBarData}
                    type="PROVINCE"
                    graph={2}
                    tickTruncateTextSize={15}
                    tickSpaceFromBar={110}
                  />}
              </GraphGrid>
              {Legend()}
            </Paper>
          </GridP>
        </GridM>
        <GridM container>
          <GridP item sm={12} xs={12} md={6}>
            <Paper>
              <VerticalBarChartTitleRow
                barData={categoryBarData}
                handleUpClick={(key) => {
                  handleUpClick(3, categoryBarData, key)
                }}
                handleDownClick={(key) => {
                  handleDownClick(3, categoryBarData, key)
                }}
                clearSorting={() => {
                  clearSorting(3)
                }}
                leftTitle={t('brandDashboard.category')}
                rightTitle={t('brandDashboard.revenue')}
              />
              <GraphGrid>
                {isCategoryLoading ? (<Shimmer>
                  <ShimmerGrid>
                  </ShimmerGrid>
                </Shimmer>) : <VerticalBarChart
                    barData={categoryBarData}
                    type="CATEGORY"
                    YAxisProps={{ width: 130 }}
                    graph={3}
                    tickTruncateTextSize={15}
                    tickSpaceFromBar={110}
                  />}
              </GraphGrid>
              {Legend()}
            </Paper>
          </GridP>
          <GridP item sm={12} xs={12} md={6}>
            <Paper>
              <VerticalBarChartTitleRow
                barData={productBarData}
                handleUpClick={(key) => {
                  handleUpClick(4, productBarData, key)
                }}
                handleDownClick={(key) => {
                  handleDownClick(4, productBarData, key)
                }}
                clearSorting={() => {
                  clearSorting(4)
                }}
                leftTitle={t('brandDashboard.product')}
                rightTitle={t('brandDashboard.revenue')}
              />
              <GraphGrid>
                {isProductLoading ? (<Shimmer>
                  <ShimmerGrid>
                  </ShimmerGrid>
                </Shimmer>) : <VerticalBarChart
                    barData={productBarData}
                    type="PRODUCT"
                    graph={4}
                    tickTruncateTextSize={15}
                    tickSpaceFromBar={110}
                  />}
              </GraphGrid>
              {Legend()}
            </Paper>
          </GridP>
        </GridM>
      </BoxM>
    </Main>
  )
}

BrandDashboard.displayName = 'BrandDashboardComponent'
export default BrandDashboard
