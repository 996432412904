import { Map } from 'immutable'

import {
  ORDER_DETAIL_GET_LOADING,
  ORDER_DETAIL_GET_FAILURE,
  ORDER_DETAIL_GET_SUCCESS,
  ORDER_SHIP_PARAMS_LOADING,
  ORDER_SHIP_PARAMS_FAILURE,
  ORDER_SHIP_PARAMS_SUCCESS
} from '../actions'

const initialState = new Map({
  isLoading: false,
  error: '',
  totalRecords: 0,
  content: Map({})
})

const initialParamsState = new Map({
  isLoading: false,
  error: '',
  totalRecords: 0,
  content: Map({})
})

export const orderDetails = (state = initialState, action) => {
  const {
    data,
    isLoading,
    message,
    type
  } = action
  switch (type) {
    case ORDER_DETAIL_GET_LOADING:
      return state.set('isLoading', isLoading)
    case ORDER_DETAIL_GET_SUCCESS:
      return state.merge({
        totalRecords: data?.totalRecords || 0,
        content: Map(data || {})
      })
    case ORDER_DETAIL_GET_FAILURE:
      return state.set('error', message)

    default:
      return state
  }
}

export const orderParams = (state = initialParamsState, action) => {
  const {
    data,
    isLoading,
    message,
    type
  } = action

  switch (type) {
    case ORDER_SHIP_PARAMS_LOADING:
      return state.set('isLoading', isLoading)
    case ORDER_SHIP_PARAMS_SUCCESS:
      return state.merge({
        totalRecords: data?.totalRecords || 0,
        content: Map(data || {})
      })
    case ORDER_SHIP_PARAMS_FAILURE:
      return state.set('error', message)

    default:
      return state
  }
}
