import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ResetPasswordComponent from '../../components/ResetPassword'
import {
  RESET_PASSWORD
} from '../../actions'

const ResetPassword = () => {
  const resetPasswordProps = useSelector((state) => {
    const {
      auth
    } = state

    return {
      error: auth?.get('error'),
      isLoadingResetPassword: auth?.get('isLoadingResetPassword'),
      resetPasswordSuccess: auth?.get('resetPasswordSuccess')
    }
  })

  const callDispatch = useDispatch()

  const onPostResetPassword = useCallback((query) => {
    callDispatch({ type: RESET_PASSWORD, payload: query })
  }, [callDispatch])

  return (
    <ResetPasswordComponent {...
      {
        ...resetPasswordProps,
        onPostResetPassword
      }}
    />
  )
}

export default ResetPassword
