import styled from 'styled-components'
const media = {
  xl: '@media(max-width: 2560px)',
  lg: '@media(max-width: 1500px)',
  md: '@media(max-width: 850px)',
  sm: '@media(max-width: 600px)'
}

export const LabelSelectorWrapper = styled.div`
  display: flex;
  align-items: end;
  // overflow: hidden;
  flex-wrap: nowrap;
  ${media.sm}{
  /* align-items: center;
  overflow: auto;
  width: 100%;
  flex-wrap: nowrap;
  display: grid; */
  
  display: flex;
  align-items: end;
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 12px;
  ::-webkit-scrollbar {
    height: 0px;
  }
  }
`

export const Label = styled.div`
  padding: .5rem 2.5rem;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.32px;
  color: #889995;
  border-bottom: 3px solid #ECECEC;
  :hover {
    cursor: pointer;
  }
  ${({ selected }) => selected && `
    font-weight: bold;
    color: #262928;
    border-bottom: 4px solid #DA2A52;
  `}
  ${media.sm}{
    padding: .5rem .9rem;
  }
`
