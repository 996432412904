import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import {
  SelectTitle,
  SelectGrid,
  ChipWrapper,
  ChipContainer,
  TextTitle,
  SearchGrid,
  SelectField,
  ErrorText,
} from './styles';
import MuiSearch from './SearchDropDown';
import { ANY, SINGLE, SAME } from '../../constants/brandPromo';
import { isEmpty } from 'lodash';

const SelectSkuComponent = (props) => {
  const {
    margintop, marginleft, label, formik, skuList, sku, updateSku, index, currentBrand, skuCriteria,
    formikId, formikValue, formikTouch, formikError, subIndex, updateSkuList, disable
  } = props;
  
  const defaultBrandId = (skuCriteria === ANY && currentBrand?.id) || null
  let minQty = null;
  let brandId = defaultBrandId;

  const promoSkus = (sku?.length > 0 && sku[index]?.promoSkus) || [];
  const formikTouched = (Array.isArray(formikTouch) && formikTouch.length > 0 && formikTouch[0]) || formikTouch || null;

  const { t } = useTranslation();

  const [isUpdated, setIsUpdated] = useState(false);
  const [removeSku, setRemoveSku] = useState([]);

  useEffect(() => {
    minQty = (sku?.length > 0 && sku[index]?.qty?.length > 0 && sku[index]?.qty) || null;    
    brandId = (sku?.length > 0 && sku[index]?.brandId) || defaultBrandId;
  },[sku])

  const handleQtyChange = (newValue) => {
    let qty = 1
    if(newValue !== "" && (newValue === "-" || newValue < 1)){
      qty = 1
    }else{
      qty = newValue
    }

    if (sku[index] && (minQty || !isEmpty(promoSkus) || subIndex === 1 || brandId)) {
      if(subIndex){
        sku[index].qty[subIndex] = qty
      } else {
        sku[index].qty = [qty];
      }
      sku[index].selectionCriteria = skuCriteria;
      updateSku([...sku]);

    } else {
      let newQty = [qty];
      if(subIndex === 0 && sku?.[index]?.qty?.[1]){
        newQty[1] = sku[index].qty[1];
      }
      handleListUpdate([], newQty);
    }
  };

  const handleSkuUpdate = (updatedSku, isSelected) => {
    if (isSelected) {
      let item = skuList.filter((model) => model.label === updatedSku);

      item = item.map((item2) => ({ name: item2.label.split(' : ')[1], sku: item2.value }));
      const skuItem = { name: item[0].name, isError: false, sku: item[0].sku };

      if (skuCriteria !== SINGLE) {
        if(subIndex){
          promoSkus[subIndex] = skuItem;
          handleListUpdate(promoSkus);
        } else {
          handleListUpdate([skuItem]);
        }
      } else {
        promoSkus.push(skuItem);
        handleListUpdate(promoSkus);
      }
    } else {
      const skus = updatedSku.split(' : ');
      setRemoveSku([]);
      handleDelete(skus[0], skus[1]);
    }

    setFormikIdValue(isSelected ? updatedSku
      : ((promoSkus?.length > 0 && promoSkus[0]) && `${promoSkus[0].sku} : ${promoSkus[0].name}`) || '');
  };

  const handleUpdate = (skus, isAdd) => {
    if (skus && skus.length > 0 && isAdd) {
      const promoSku = [];
      skus.forEach((skuItem) => {
        let item = skuList.filter((model) => model.label === skuItem);

        item = item.map((item2) => ({ name: item2.label.split(' : ')[1], sku: item2.value }));
        const promoSkuItem = { name: item[0].name, isError: false, sku: item[0].sku };
        promoSku.push(promoSkuItem);
      });
      handleListUpdate(promoSku);
      setFormikIdValue(skus[0]);
    } else {
      handleListUpdate();
      setFormikIdValue('');
    }
  };

  const handleListUpdate = (promoSku = [], updateQty) => {
    const obj = {
      promoSkus: promoSku,
      qty: updateQty || sku[index]?.qty || [],
      selectionCriteria: skuCriteria,
      brandId: sku[index]?.brandId || defaultBrandId
    };
    sku[index] = obj;
    updateSku(sku);

  };

  const handleDelete = (skuItem, name) => {
    const promoSku = sku[index]?.promoSkus;
    const skuIndex = promoSku?.findIndex((obj) => obj?.sku === skuItem);
    if (skuIndex > -1) {
      promoSku.splice(skuIndex, 1);
      handleListUpdate(promoSku, sku[index]?.qty);

      removeSku.push(`${skuItem} : ${name}`);
      setIsUpdated(!isUpdated);
      setRemoveSku(removeSku);
      setFormikIdValue(promoSku?.length > 0 ? `${promoSku?.[0]?.sku} : ${promoSku?.[0]?.name}` : '');
    }
  };
  const setFormikIdValue = (value) => {
    if (formik) {
      formik.setFieldValue(formikId, value);
    }
    if(updateSkuList){
      updateSkuList()
    }
  };

  const getQty = () => {
    const minQty = (sku?.length > 0 && sku[index]?.qty?.length > 0 && sku[index]?.qty) || null
    return (minQty?.length > 0 && minQty[subIndex || 0]) || undefined
  }

  return (
    <Grid container={true} xs={5} marginTop={margintop} marginLeft={marginleft}>
      <Grid item={true} xs={9}>
        <SearchGrid width="95%">
          <SelectTitle>{label}</SelectTitle>
          <MuiSearch
            formikId={formikId}
            formikValue={skuCriteria === ANY ? currentBrand?.name : skuCriteria === SAME ? t('createPromo.sameAsPaid') : formikValue}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            helper={formikTouched && promoSkus?.length === 0 ? formikError : ''}
            errorText={formikTouched && Boolean(formikError) && promoSkus?.length === 0}
            listData={skuList}
            formikField={formik}
            height="small"
            textLabel={skuCriteria === ANY ? currentBrand?.name : t('brandDashboard.sku')}
            onUpdate={handleUpdate}
            onItemAdd={handleSkuUpdate}
            removeData={removeSku}
            setRemoveData={setRemoveSku}
            isListUpdate={isUpdated}
            disable={disable || skuCriteria === ANY || skuCriteria === SAME}
            singleSelect={skuCriteria !== SINGLE}
          />
        </SearchGrid>
        {promoSkus?.length > 0 && (
          <ChipWrapper>
            <div>
              {' '}
              {promoSkus?.slice(subIndex > 0 ? subIndex : 0, subIndex === null ? promoSkus?.length : (subIndex+1))?.map((item, index) => (
                <ChipContainer
                  key={index}
                  formikId={formikId}
                  label={`${item.sku}: ${item.name}`}
                  sx={{
                    '& .MuiChip-deleteIcon': {
                      color: item.isError ? '#FF5151' : 'default',
                    },
                  }}
                  error={item.isError || null}
                  variant="outlined"
                  onDelete={disable ? undefined : () => handleDelete(item.sku, item.name)}
                />
              ))}
              {' '}

            </div>
          </ChipWrapper>
        )}
        {sku?.length > 0 && 
          (
            (subIndex === 0 && sku[index]?.isPromo1Error) 
            || (subIndex === 1 && sku[index]?.isPromo2Error)
            )&& <ErrorText error={true}>{t('errors.skuId')}</ErrorText>}
      </Grid>

      <Grid item={true} xs={3}>
        <SelectGrid>
          <TextTitle margintop="12px">{(subIndex || subIndex === 0) ? t('createPromo.qty') : t('createPromo.minQty')}</TextTitle>
          <SelectField
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            id={formikId ? `minQty_${formikId}` : subIndex ? `minQty_${index}_${subIndex}` : `minQty_${index}`}
            value={getQty()}
            onChange={(e) => handleQtyChange(e.target.value)}
            margin="normal"
            variant="outlined"
            placeholder=""
            width="90px"
            disabled={disable}
            error={sku?.length > 0 && sku[index]?.isQtyError}
            bottom={sku[index]?.isQtyError ? '48px' : '0px'}
            onKeyPress={(e) => {
              if (isNaN(e.key)) {
                  e.preventDefault();
              }
          }}
          />
          {sku?.length > 0 && 
            (sku[index]?.isQtyError || 
            ((subIndex === 0 && sku[index]?.isQty1Error) 
              || (subIndex === 1 && sku[index]?.isQty2Error))
              )&& <ErrorText error={true}>{t('errors.qty')}</ErrorText>}

        </SelectGrid>
      </Grid>

    </Grid>
  );
};

SelectSkuComponent.propTypes = {
  margintop: PropTypes.string,
  marginleft: PropTypes.string,
  label: PropTypes.string,
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  skuList: PropTypes.arrayOf(PropTypes.shape({})),
  sku: PropTypes.arrayOf(PropTypes.shape({
    promoSkus: PropTypes.arrayOf(PropTypes.shape({})),
    qty: PropTypes.arrayOf(PropTypes.string),
    isQtyError: PropTypes.bool,
  })),
  updateSku: PropTypes.func,
  index: PropTypes.number,
  currentBrand: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  skuCriteria: PropTypes.string,
  formikId: PropTypes.string,
  formikValue: PropTypes.string,
  formikTouch: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.bool),
  ]),
  formikError: PropTypes.string,
  subIndex: PropTypes.number,
  updateSkuList: PropTypes.func,
};

SelectSkuComponent.defaultProps = {
  margintop: null,
  marginleft: null,
  label: null,
  formik: null,
  skuList: [],
  sku: [],
  updateSku: null,
  index: null,
  currentBrand: null,
  skuCriteria: null,
  formikId: null,
  formikValue: null,
  formikTouch: null,
  formikError: null,
  subIndex: null,
  updateSkuList: null
};

export default SelectSkuComponent;
