import React, { useState, useEffect, useRef } from 'react';
import { Popover, Checkbox, Divider, List, ListItem, ListItemButton, ListItemIcon, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import {
  TextView,
  ListText,
  SearchListContainer,
  SearchContainer,
  NoSKUText
} from './styles';
import { addDefaultItem, getFilterList, getSkuList, getSkusList } from '../../utilities/utils';

const SearchDropDownComponent = (props) => {
  const { formikValue, handleBlur, listData, textLabel, helper, errorText, onItemAdd, onUpdate, removeData, setRemoveData,
    isListUpdate, updatePromo, disable, singleSelect } = props;
  const divRef = useRef();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [items, setItems] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [defaultList, setDefaultList] = useState([]);
  const [searchValue, setSearchValue] = useState(formikValue);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setSearchValue(formikValue);
  }, [formikValue]);

  useEffect(() => {
    if (listData && listData.length > 0 && listData[0].label !== t('brandDashboard.selectSku')) {
      listData.unshift(addDefaultItem(t('brandDashboard.selectSku')));
    }
    const defaultList = listData;
    setDefaultList(defaultList);
    setItems(defaultList);
  }, [listData]);

  useEffect(() => {
    if (updatePromo && updatePromo.length > 0 && listData && listData.length > 0) {
      if (listData[0].label !== t('brandDashboard.selectSku')) {
        listData.unshift(addDefaultItem(t('brandDashboard.selectSku')));
      }
      const updateSkus = getSkusList(listData, updatePromo);
      setItems(updateSkus);
    } else if (listData.length > 0) {
      if (listData[0].label !== t('brandDashboard.selectSku')) {
        listData.unshift(addDefaultItem(t('brandDashboard.selectSku')));
      }
      const resetData = listData.map((item) => ({ ...item, isSelected: false }));
      setItems(resetData);
    }
  }, [updatePromo]);

  useEffect(() => {
    if (removeData?.length > 0) {
      const updateList = items.map((model) => ({
        ...model, isSelected: removeData?.includes(model.label) ? false : model.isSelected,
      }));
      setRemoveData([])
      setItems(updateList);
    }
  }, [isListUpdate]);

  const handleToggle = (value, index) => () => {
    if (singleSelect) {
      const updatedList = items.map((model) => ({ ...model, isSelected: false }));
      updatedList[index].isSelected = !value.isSelected;
      onItemAdd(value.label, updatedList[index].isSelected);
      setItems(updatedList);
    } else if (value.label === t('brandDashboard.selectSku') || value.label === t('brandDashboard.searchSku')) {
      const updatedList = items.map((model) => ({ ...model, isSelected: !value.isSelected }));
      setItems(updatedList);
      onUpdate(getSkuList(updatedList, value.label), !value.isSelected);
    } else {
      items[0].isSelected = false;
      items[index].isSelected = !value.isSelected;
      onItemAdd(value.label, items[index].isSelected);
      setItems(items);
    }
    setIsUpdated(!isUpdated);
  };
  const handleSearch = (value) => {
    const searchValue = value;
    const models = defaultList.filter((x) => x.label.toLowerCase().includes(searchValue.toLowerCase()));
    if (models.length < defaultList.length && value !== '') {
      models.unshift(addDefaultItem(t('brandDashboard.searchSku')));
      setItems(models);
    } else {
      setItems(getFilterList(defaultList, items));
    }
    setSearchValue(value);
  };
  const handleClearClick = () => {
    setSearchValue('');
    setItems(getFilterList(defaultList, items));
    if (singleSelect) {
      const updatedList = items.map((model) => ({ ...model, isSelected: false }));
      onItemAdd('', false);
      setItems(updatedList);
    }
  };

  const handleClick = () => {
    setAnchorEl(divRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SearchContainer ref={divRef}>
        <TextView
          fullWidth={true}
          type="text"
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
          onBlur={handleBlur}
          helperText={helper}
          error={errorText}
          margin="normal"
          variant="outlined"
          name={textLabel}
          disabled={disable}
          onFocus={!disable ? handleClick : null}
          aria-describedby={id}
          placeholder={textLabel}
          InputProps={{
            endAdornment: !disable && (
              <IconButton
                sx={{ visibility: searchValue ? 'visible' : 'hidden' }}
                onClick={handleClearClick}
              >
                <ClearIcon height={10} width={10} />
              </IconButton>
            ),
          }}
          sx={{
            m: 0,
            '& .Mui-focused .MuiIconButton-root': { color: 'primary.main' },
          }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onBlur={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
        >
          <SearchListContainer>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {items?.map((item, index) => {
                const labelId = `checkbox-list-label-${item}`;
                if (singleSelect && index === 0) {
                  return null;
                }
                return (
                  <div key={index}>
                    <ListItem
                      disablePadding={true}
                    >
                      <ListItemButton role={undefined} onClick={handleToggle(item, index)} dense={true}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={item.isSelected}
                            tabIndex={-1}
                            disableRipple={true}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListText id={labelId} primary={item.label} disableTypography={true} />
                      </ListItemButton>
                    </ListItem>
                    {index !== items.length - 1 && <Divider />}
                  </div>
                );
              })}
              {(!items || !items.length) && <NoSKUText>{t('brandDashboard.noSKU')}</NoSKUText>}
            </List>
          </SearchListContainer>
        </Popover>
      </SearchContainer>
    </>
  );
};

SearchDropDownComponent.propTypes = {
  formikValue: PropTypes.string,
  handleBlur: PropTypes.func,
  listData: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
  textLabel: PropTypes.string,
  helper: PropTypes.string,
  errorText: PropTypes.bool,
  onItemAdd: PropTypes.func,
  onUpdate: PropTypes.func,
  removeData: PropTypes.arrayOf(PropTypes.shape({})),
  isListUpdate: PropTypes.bool,
  updatePromo: PropTypes.arrayOf(PropTypes.shape({})),
  disable: PropTypes.bool,
  singleSelect: PropTypes.bool,
  setRemoveData: PropTypes.func
};

SearchDropDownComponent.defaultProps = {
  formikValue: null,
  handleBlur: null,
  listData: null,
  textLabel: null,
  helper: null,
  errorText: false,
  onItemAdd: null,
  onUpdate: null,
  removeData: null,
  isListUpdate: false,
  updatePromo: null,
  disable: false,
  singleSelect: false,
  setRemoveData: ()=> {}
};

export default SearchDropDownComponent;
