import React from 'react'
import { CloseIconWrapper, Main, BoxWraper, OuterContent, TypographyWraperBold, TypographyWraper, BlockWraper, TypographyWraperCenter, ButtonCenter } from './style'
import { Modal } from '@mui/material'
import ButtonCustom from '../Button'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'

const StoreCreatePopup = (props) => {
  const { t } = useTranslation()
  const { open, handleClose } = props
  return (
    <>
      <Main>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <BoxWraper>
            <CloseIconWrapper>
              <CloseIcon style={{ float: 'right', color: '#7D86A9', cursor: 'pointer' }} onClick={handleClose} />
            </CloseIconWrapper>
            <TypographyWraperCenter>
              {t('createStorePopup.stepsToCreateShopeeStore')}
            </TypographyWraperCenter>
            <BlockWraper>
              <TypographyWraper>
                1. {t('createStorePopup.gotoThisURL')}:<OuterContent target='_blank' href='https://mall.shopee.co.id/seller/signup?next=https%3A%2F%2Fseller.shopee.co.id'> https://bit.ly/3QCoUUk</OuterContent>
              </TypographyWraper>
              {/* <TypographyWraper>
                2.{t('createStorePopup.pleaseProvideText')}:
              </TypographyWraper> */}
              <TypographyWraper>
                2. {t('createStorePopup.onceYouSubmit')}
              </TypographyWraper>
              <TypographyWraper>
                3. {t('createStorePopup.longText')}:
              </TypographyWraper>
              <b>
                <TypographyWraperBold>
                  {t('createStorePopup.loginToBusinessProAndConnect')}
                </TypographyWraperBold>
              </b>
            </BlockWraper>
            <ButtonCenter>
              <ButtonCustom onClick={handleClose}>{t('createStorePopup.okGotIt')}</ButtonCustom>
            </ButtonCenter>
          </BoxWraper>
        </Modal>
      </Main>
    </>
  )
}

// Connections.propTypes = {}
StoreCreatePopup.displayName = 'StoreCreatePopup'
export default StoreCreatePopup
