import React from 'react'
import { connect } from 'react-redux'
import Layout from '../Layout'
import PageBuilderLinksComponent from '../../components/PageBuilder'
import {
  PAGEBUILDER_LINKS_CREATE_POST_DATA,
  PAGEBUILDER_LINKS_UPDATE_POST_DATA,
  PAGEBUILDER_LINKS_DELETE_POST_DATA,
  PAGEBUILDER_SECTIONS_REORDER_POST_DATA,
  PAGEBUILDER_LINKS_REORDER_POST_DATA,
  PAGEBUILDER_LINKS_FETCH_GET_DATA,
  USERS_GET_INFO
} from '../../actions'

const PageBuilder = props => {
  return (
    <Layout>
      <PageBuilderLinksComponent {...props} />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { pageBuilder } = state
  return {
    // pageBuilderLinksCreateError: pageBuilder.get('error'),
    // pageBuilderLinksCreateIsLoading: pageBuilder.get('isLoading'),
    // pageBuilderLinksCreateData: pageBuilder.get('content').toJS()
    pageBuilderLinksDeleteIsLoading: pageBuilder.get('pageBuilderLinksDeleteIsLoading'),
    pageBuilderLinksDeleteData: pageBuilder.get('pageBuilderLinksDeleteData'),
    pageBuilderLinksUpdateIsLoading: pageBuilder.get('pageBuilderLinksUpdateIsLoading'),
    pageBuilderLinksUpdateData: pageBuilder.get('pageBuilderLinksUpdateData'),
    pageBuilderLinksFetchIsLoading: pageBuilder.get('pageBuilderLinksFetchIsLoading'),
    pageBuilderLinksFetchData: pageBuilder.get('pageBuilderLinksFetchData'),
    pageBuilderLinksCreateIsLoading: pageBuilder.get('pageBuilderLinksCreateIsLoading'),
    pageBuilderLinksCreateData: pageBuilder.get('pageBuilderLinksCreateData'),
    pageBuilderSectionsReorderIsLoading: pageBuilder.get('pageBuilderSectionsReorderIsLoading'),
    pageBuilderSectionsReorderData: pageBuilder.get('pageBuilderSectionsReorderData'),
    pageBuilderLinksReorderIsLoading: pageBuilder.get('pageBuilderLinksReorderIsLoading'),
    pageBuilderLinksReorderData: pageBuilder.get('pageBuilderLinksReorderData')
  }
}

const mapDispatchToProps = dispatch => ({
  onGetPageBuilderLinksCreateData: (query) => {
    dispatch({ type: PAGEBUILDER_LINKS_CREATE_POST_DATA, payload: query })
  },
  onGetPageBuilderLinksUpdateData: (query) => {
    dispatch({ type: PAGEBUILDER_LINKS_UPDATE_POST_DATA, payload: query })
  },
  onGetPageBuilderLinksDeleteData: (query) => {
    dispatch({ type: PAGEBUILDER_LINKS_DELETE_POST_DATA, payload: query })
  },
  onGetPageBuilderSectionsReorderData: (query) => {
    dispatch({ type: PAGEBUILDER_SECTIONS_REORDER_POST_DATA, payload: query })
  },
  onGetPageBuilderLinksReorderData: (query) => {
    dispatch({ type: PAGEBUILDER_LINKS_REORDER_POST_DATA, payload: query })
  },
  onGetPageBuilderLinksFetchData: (query) => {
    dispatch({ type: PAGEBUILDER_LINKS_FETCH_GET_DATA, payload: query })
  },
  onGetUserInfo: (data) => {
    dispatch({ type: USERS_GET_INFO, data })
  }
})

PageBuilder.displayName = 'PageBuilderLinksContainer'
export default connect(mapStateToProps, mapDispatchToProps)(PageBuilder)
