import { Map } from 'immutable'

import {
  BRAND_PROMO_LOADING,
  BRAND_PROMO_GET_LIST_FAILURE,
  BRAND_PROMO_GET_LIST_SUCCESS,
  BRAND_PROMO_TYPE_LOADING,
  BRAND_PROMO_TYPE_GET_LIST_FAILURE,
  BRAND_PROMO_TYPE_GET_LIST_SUCCESS,
  BRAND_SKU_LOADING,
  BRAND_SKU_GET_LIST_FAILURE,
  BRAND_SKU_GET_LIST_SUCCESS,
  BRAND_DATA_SAVE
} from '../actions'

const initialState = new Map({
  promoIsloading: false,
  promoList: [],
  totalPromo: 0,
  promoTypeIsLoading: false,
  promoTypeList: [],
  skuIsLoading: false,
  skuList: [],
  brandDetails: {}
})

export const brandsPromo = (state = initialState, action) => {
  const {
    data,
    type,
    isLoading,
    total
  } = action

  switch (type) {
    case BRAND_PROMO_LOADING:
      return state.set('promoIsloading', isLoading)
    case BRAND_PROMO_GET_LIST_SUCCESS:
      return state.merge({
        promoList: data || [],
        totalPromo: total || 0
      })
    case BRAND_PROMO_GET_LIST_FAILURE:
      return state.merge({
        promoList: []
      })
    case BRAND_PROMO_TYPE_LOADING:
      return state.set('promoTypeIsLoading', isLoading)
    case BRAND_PROMO_TYPE_GET_LIST_SUCCESS:
      return state.merge({
        promoTypeList: data || []
      })
    case BRAND_PROMO_TYPE_GET_LIST_FAILURE:
      return state.merge({
        promoTypeList: []
      })
    case BRAND_SKU_LOADING:
      return state.set('skuIsLoading', isLoading)
    case BRAND_SKU_GET_LIST_SUCCESS:
      return state.merge({
        skuList: data || []
      })
    case BRAND_SKU_GET_LIST_FAILURE:
      return state.merge({
        skuList: []
      })
    case BRAND_DATA_SAVE:
      return state.merge({
        brandDetails: data
      })
    default:
      return state
  }
}
