import React,{useEffect, useState} from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { SelectField } from './styles'
const DatePickerComponent = (props) => {
    const { helper, errorText, formikValue, formikLabel, formikId, formikField, loadDefault, isEditable, isOffer, disable } = props;
    const[currentValue, setCurrentValue]= useState(formikValue);
    const checkISDisable = (selectedDate) =>{
      const selected=new Date(selectedDate);
      const current = new Date();
      if(selected < current){
          return true
      }
      return false
    }

    useEffect(()=>{
      if(isEditable ){
          loadDefault() 
      }
  },[isEditable])

    useEffect(()=>{
      if(formikValue){
        setCurrentValue(formikValue)
      }else{setCurrentValue(null)} 
    },[isOffer, formikValue])

    const onKeyDown = (e) => {
      e.preventDefault();
   };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={currentValue}
        minDate={new Date().setDate(new Date().getDate() + 1)}
        onChange={(value) => {
          const today = new Date(value)
          const date = today.getFullYear() + '/' + parseInt(today.getMonth() + 1) + '/' + today.getDate()
          formikField.setFieldValue(
            formikId,
            value !== null ? date : ''
          )
          setCurrentValue(value !== null ? date : '')
        }}
        disabled={disable}
        inputFormat="dd/MM/yyyy"
        renderInput={(params) => 
        <SelectField 
          {...params}
        id={formikId}
        error={errorText}
        helperText={helper}
        label={formikLabel}
        margin="normal"
        disabled={disable || checkISDisable(formikValue)}
        onKeyDown={onKeyDown}
          />}

      />
    </LocalizationProvider>
  )
}

export default DatePickerComponent;
