import { call, put, takeLatest } from 'redux-saga/effects'
import {
    GET_DASHBOARD_PROVINCE,
    GET_DASHBOARD_PROVINCE_SUCCESS,
    GET_DASHBOARD_PROVINCE_FAILURE,
    GET_DASHBOARD_MONTH,
    GET_DASHBOARD_MONTH_SUCCESS,
    GET_DASHBOARD_MONTH_FAILURE,
    GET_DASHBOARD_YEAR,
    GET_DASHBOARD_YEAR_SUCCESS,
    GET_DASHBOARD_YEAR_FAILURE,
    DAY_UNIT_SOLD,
    DAY_UNIT_SOLD_SUCCESS,
    DAY_UNIT_SOLD_FAILURE,
    TOTAL_UNIT_SOLD,
    TOTAL_UNIT_SOLD_SUCCESS,
    TOTAL_UNIT_SOLD_FAILURE,
    DAY_REVENUE,
    DAY_REVENUE_SUCCESS,
    DAY_REVENUE_FAILURE,
    MONTH_REVENUE,
    MONTH_REVENUE_SUCCESS,
    MONTH_REVENUE_FAILURE,
    MONTH_SHIPPING,
    MONTH_SHIPPING_SUCCESS,
    MONTH_SHIPPING_FAILURE
} from '../actions'

import { BrandManagementGetApi, BrandManagementPostApi } from '../utilities/axios';
import {
    GET_PROVINCE,
    GET_DASHBOARD_MONTH_RANGE,
    GET_DASHBOARD_YEAR_SKU,
    DAYS_UNITS_SOLD,
    MONTH_SHIPPING_COST,
    MONTHLY_REVENUE,
    DAYS_REVENUE,
    TOTAL_UNITS_SOLD
} from '../constants';


function* getDashboardProvince() {
    try {
        const response = yield call(BrandManagementGetApi, GET_PROVINCE, {}, false, false);
        yield put({ type: GET_DASHBOARD_PROVINCE_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: GET_DASHBOARD_PROVINCE_FAILURE, message: error.message })
    }
}

function* getMonthRange() {
    try {
        const response = yield call(BrandManagementGetApi, GET_DASHBOARD_MONTH_RANGE, {}, {}, false);
        yield put({ type: GET_DASHBOARD_MONTH_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: GET_DASHBOARD_MONTH_FAILURE, message: error.message })
    }
}

function* getYearRange() {
    try {
        const response = yield call(BrandManagementGetApi, GET_DASHBOARD_YEAR_SKU, {}, {}, false);
        yield put({ type: GET_DASHBOARD_YEAR_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: GET_DASHBOARD_YEAR_FAILURE, message: error.message })
    }
}

function* getDayUnit(action) {
    try {
        const {brandId, data } = action?.payload;
        const response = yield call(BrandManagementPostApi, DAYS_UNITS_SOLD(brandId), data, {}, false);
        yield put({ type: DAY_UNIT_SOLD_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: DAY_UNIT_SOLD_FAILURE, message: error.message })
    }
}

function* getTotalUnit(action) {
    try {
        const {brandId, data } = action?.payload;
        const response = yield call(BrandManagementPostApi, TOTAL_UNITS_SOLD(brandId), data, {}, false);
        yield put({ type: TOTAL_UNIT_SOLD_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: TOTAL_UNIT_SOLD_FAILURE, message: error.message })
    }
}

function* getMontRevenue(action) {
    try {
        const {brandId, data } = action?.payload;
        const response = yield call(BrandManagementPostApi, MONTHLY_REVENUE(brandId), data, {}, false);
        yield put({ type: MONTH_REVENUE_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: MONTH_REVENUE_FAILURE, message: error.message })
    }
}

function* getDayRevenue(action) {
    try {
        const {brandId, data } = action?.payload;
        const response = yield call(BrandManagementPostApi, DAYS_REVENUE(brandId), data, {}, false);
        yield put({ type: DAY_REVENUE_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: DAY_REVENUE_FAILURE, message: error.message })
    }
}

function* getMonthlyShipping(action) {
    try {
        const {brandId, data } = action?.payload;
        const response = yield call(BrandManagementPostApi, MONTH_SHIPPING_COST(brandId), data, {}, false);
        yield put({ type: MONTH_SHIPPING_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: MONTH_SHIPPING_FAILURE, message: error.message })
    }
}


function* createSaga() {
    yield takeLatest(GET_DASHBOARD_PROVINCE, getDashboardProvince);
    yield takeLatest(GET_DASHBOARD_MONTH, getMonthRange);
    yield takeLatest(GET_DASHBOARD_YEAR, getYearRange);
    yield takeLatest(DAY_UNIT_SOLD, getDayUnit);
    yield takeLatest(TOTAL_UNIT_SOLD, getTotalUnit);
    yield takeLatest(MONTH_SHIPPING, getMonthlyShipping);
    yield takeLatest(DAY_REVENUE, getDayRevenue);
    yield takeLatest(MONTH_REVENUE, getMontRevenue);
}

export default createSaga;

