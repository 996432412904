import { styled } from '@mui/system'
import { Box, ListItemText } from '@mui/material'

export const BannerWrapper = styled(Box)(`
    display: flex;
    justify-content: space-between;
    padding: 70px 0 50px 0;
`)

export const BannerLhsWrapper = styled(Box)(`
    @media screen and (max-width: 1000px) {
        padding-bottom: 30px;
    }
`)

export const BannerLhsImage = styled('img')(`
    max-width: 580px;
    width:100%;
`)

export const BannerRhsWrapper = styled(Box)(`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
    @media screen and (max-width: 1000px) {
        width: 100%;
        flex-direction: row;
        align-content: space-evenly;
        flex-wrap:wrap;
        padding-top:20px;
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
`)

export const TagTitle = styled(ListItemText)(`
    box-sizing:border-box;
    max-width: 380px;
    border: 1px solid #cacfda;
    border-radius: 20px;
    background-color: white;
    overflow:hidden;
    position:relative;
    padding: 25px 30px 25px 50px;
    width: 100%;

    &:before{
        background-color: #3b4d73;
        height: 100%;
        width: 10px;
        left:0;
        top:0;
        z-index: 1;
        position:absolute;
        content:'';
    }

    & > span {
        color: #3c4d73;
        font-size: 33px;
        font-weight: 600;
        @media screen and (max-width: 1000px) {
            font-size: 23px;
        }
    }
    & > p {
        color: #2f2f2f;
        font-size: 16px;
        @media screen and (max-width: 1000px) {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 1000px) {
        flex-direction: row;
    }
`)
