import React from 'react'
import {
  CardsOuterWrapper,
  CardTitle,
  CardImgWrapper,
  CardOuterWrapper,
  CardSubTitle
} from './style'
import { Grid, Container } from '@mui/material'
import Heading from '../Heading'

const BenefitCard = (props) => {
  const { content } = props
  return (
    <>
      <Heading content={content} />
      <CardsOuterWrapper>
        <Container>
          <Grid container direction='row' justifyContent='space-around'>
            {content?.data.map((list, index) => (
              <Card
                key={index}
                img={list.image}
                title={list.heading}
                subtitle={list.content}
              />
            ))}
          </Grid>
        </Container>
      </CardsOuterWrapper>
    </>
  )
}

export default BenefitCard

const Card = (props) => {
  const { title, subtitle, img } = props
  return (
    <CardOuterWrapper>
      {img && <CardImgWrapper><img src={img} alt='img' /></CardImgWrapper>}
      {title && <CardTitle gutterBottom variant='h5' component='h5'>{title}</CardTitle>}
      {subtitle && <CardSubTitle variant='body2'>{subtitle}</CardSubTitle>}
    </CardOuterWrapper>
  )
}
