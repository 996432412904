import { Grid, Backdrop, Box, Table, TableHead, TableRow, TableCell, Paper, TableBody } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { PopupSecondTableWrapper, PopupTablesWrapper, PopupFirstTableWrapper, ModalWrapper, PMargin0, ShowMoreDiv,
  DataGridWrapper, EditCell, TooltipWrapper, ShowMoreWrapper, ShowMoreRenderCellWrapper, BlankText, FreeUnitWrapper } from './style'
import MuiDataGrid from '../MuiDataGrid'
import RenderCellWithToolTip from '../MuiDataGrid/RenderCellWithToolTip'
import {checkISDisable} from '../../utilities/utils';
import { ANY, BUY_N_GET_X } from '../../constants/brandPromo'

const handleClickRow = (data, viewPromoDetailsHandler) => {
  viewPromoDetailsHandler(data.row, false);
}

const EndDateRenderCell = (params) => {
  return (
    <Grid container>
      <Grid item md={9} sm={9} xs={9}>
        <TooltipWrapper
          title={moment(params.value).format('YYYY-MM-DD')} enterTouchDelay={0} componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'white',
                color: 'black',
                border: '2px solid #E8EBEF',
                '& .MuiTooltip-arrow': {
                  color: 'white',
                  fontSize: 20
                },
                '& .MuiTooltip-arrow::before': {
                  border: '2px solid #E8EBEF',
                  boxSizing: 'border-box'
                }
              }
            },
            arrow: {
              fontSize: 50
            }
          }} arrow
        >
          <p>{params.value ? moment(params.value).format('YYYY-MM-DD') : ''}</p>
        </TooltipWrapper>
      </Grid>
     {params.colDef.canUserEdit && !checkISDisable(params.value) && <Grid item md={3} sm={3} xs={3}>
        <EditCell onClick={(e)=>{
          params.colDef.editPromoHandler(params.row, e)
        }}>
          {params.colDef.t('brandPromo.edit')}
        </EditCell>
      </Grid>}
    </Grid>
  )
}

const StartDateRenderCell = (params) => {
  return (
    <Grid container>
      <TooltipWrapper
        title={moment(params.value).format('YYYY-MM-DD')} enterTouchDelay={0} componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'white',
              color: 'black',
              border: '2px solid #E8EBEF',
              '& .MuiTooltip-arrow': {
                color: 'white',
                fontSize: 20
              },
              '& .MuiTooltip-arrow::before': {
                border: '2px solid #E8EBEF',
                boxSizing: 'border-box'
              }
            }
          },
          arrow: {
            fontSize: 50
          }
        }} arrow
      >
        <p>{params.value ? moment(params.value).format('YYYY-MM-DD') : ''}</p>
      </TooltipWrapper>
    </Grid>
  )
}

const SkuRenderCell = (params) => {
  const [viewAllModalOpen, setViewAllModalOpen] = useState(false)
  let haveMultiple = params?.value?.length > 1;
  if(params.row.type === BUY_N_GET_X && haveMultiple){
    haveMultiple = !(params.row.promoBuyNGetX?.baseSku?.length > 1);
  }
 if(params.value !==undefined && !params.value?.brandId){
  return (
    <ShowMoreRenderCellWrapper marginTop={haveMultiple ? '22px' : '0px'}>
      <TooltipWrapper
        title={params?.value[0]?.sku} enterTouchDelay={0} componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'white',
              color: 'black',
              border: '2px solid #E8EBEF',
              '& .MuiTooltip-arrow': {
                color: 'white',
                fontSize: 20
              },
              '& .MuiTooltip-arrow::before': {
                border: '2px solid #E8EBEF',
                boxSizing: 'border-box'
              }
            }
          },
          arrow: {
            fontSize: 50
          }
        }} arrow
      >
       <PMargin0>{params?.value[0]?.sku}</PMargin0>
      </TooltipWrapper>
      {haveMultiple &&
        <ShowMoreDiv onClick={(e) => { 
          setViewAllModalOpen(true);
          e.stopPropagation();
        }}>
          <ShowMoreWrapper>
          + {params.value.length - 1}
          </ShowMoreWrapper>
        </ShowMoreDiv>}
      <ViewAllProductNameModal open={viewAllModalOpen} value={params.row} onClose={() => setViewAllModalOpen(false)} editPromoHandler={params.colDef.editPromoHandler} />
    </ShowMoreRenderCellWrapper>
  )
 }else{
  return(<BlankText> - </BlankText>)
 }
  
}

const ProductNameRenderCell = (params) => {
  const { t } = useTranslation()
  const [viewAllModalOpen, setViewAllModalOpen] = useState(false)
  const haveMultiple = params?.value?.length > 1;

  let haveMultiplePaidSku = false

  if (!params.value) {
    return <>{t('brandPromo.allProduct')}</>
  }

  if(params.value?.selectionCriteria === ANY){
    return <>{t('createPromo.anyQtySKU').replace('{qty}',params.value.qty[0])}</>
  }

  if(params.row.type === BUY_N_GET_X){
    haveMultiplePaidSku = params.row.promoBuyNGetX?.baseSku?.length > 1;
  }

  return (
    <ShowMoreRenderCellWrapper marginTop={haveMultiple ? '22px' : '0px'}>
      <TooltipWrapper
        title={ `${params?.value[0]?.sku} : ${params?.value[0]?.name}`} enterTouchDelay={0} componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'white',
              color: 'black',
              border: '2px solid #E8EBEF',
              '& .MuiTooltip-arrow': {
                color: 'white',
                fontSize: 20
              },
              '& .MuiTooltip-arrow::before': {
                border: '2px solid #E8EBEF',
                boxSizing: 'border-box'
              }
            }
          },
          arrow: {
            fontSize: 50
          }
        }} arrow
      >
        <PMargin0>{params?.value[0]?.name}</PMargin0>
      </TooltipWrapper>
      {
        haveMultiplePaidSku && (
          <ShowMoreDiv onClick={() => params.colDef.viewPromoDetailsHandler(params.row,
            (params.colDef.canUserEdit && !checkISDisable(params.row.endDate)))}>
            <ShowMoreWrapper>
              {t('brandPromo.viewDetails')}
            </ShowMoreWrapper>
          </ShowMoreDiv>
        )
      }
      {haveMultiple && !haveMultiplePaidSku &&
        <ShowMoreDiv onClick={(e) => { 
          setViewAllModalOpen(true);
          e.stopPropagation();
         }}>
          <ShowMoreWrapper>
            {t('brandPromo.viewAll')}
          </ShowMoreWrapper>
        </ShowMoreDiv>
      }
      <ViewAllProductNameModal open={viewAllModalOpen} value={params.row} onClose={() => setViewAllModalOpen(false)} editPromoHandler={params.colDef.editPromoHandler}/>
    </ShowMoreRenderCellWrapper>
  )
}

const ViewAllProductNameModal = (props) => {
  const { t } = useTranslation()

  const { open, value, editPromoHandler } = props
  let promoSkus = value.promoSkus;

  if(value.type === BUY_N_GET_X){
    const paidSku = value.promoBuyNGetX?.baseSku;
    if(paidSku?.length > 0){
      promoSkus = paidSku[0].promoSkus
    }
  }

  const getDiscountForViewAllPopup = (value) => {
    if(value.type !== BUY_N_GET_X){
        return value.discount;
    }

    if(value?.selectionCriteria === ANY){
      return <>{t('createPromo.anyQtySKU').replace('{qty}',value.qty[0])}</>
    }
    const disc_val = DiscountValueGetter({row:value})

    const freeSku = value.promoBuyNGetX?.freeSku;
    const haveMultiple = disc_val?.length > 1 || freeSku?.length > 1;

    if(haveMultiple){
      return "-";
    }

    if(disc_val !==undefined && !disc_val?.brandId){
    const freeQty = freeSku[0]?.qty?.length > 0 ? freeSku[0].qty[0] : 0
    let val = disc_val[0]?.sku
    if(freeQty){
      val = val + " : " + freeQty + " Units"
    }
      return val;
    } else {
      return "-";
    }
  }

  return (
    <ModalWrapper
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      {...props}
    >
      <PopupTablesWrapper in={open}>
        <Box>
          <Grid container>
            <Paper>
              <PopupFirstTableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('brandPromo.promoType')}</TableCell>
                      <TableCell>{t('brandPromo.discount')}</TableCell>
                      <TableCell>{t('brandPromo.startDate')}</TableCell>
                      <TableCell>{t('brandPromo.endDate')}</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key={0}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell padding='none'>{value.type}</TableCell>
                      <TableCell padding='none'>{getDiscountForViewAllPopup(value)}</TableCell>
                      <TableCell padding='none'>{moment(value.startDate).format('DD/MM/YYYY') }</TableCell>
                      <TableCell padding='none'>{moment(value.endDate).format('DD/MM/YYYY')}</TableCell>
                      {!checkISDisable(value.endDate) && <TableCell padding='none'><EditCell onClick={(e)=>{
                        editPromoHandler(value, e)
                      }}>{t('brandPromo.edit')}</EditCell></TableCell>}
                      
                    </TableRow>
                  </TableBody>
                </Table>
              </PopupFirstTableWrapper>
              <PopupSecondTableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('brandCatalouge.sku')}</TableCell>
                      <TableCell>{t('addProduct.productName')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {promoSkus?.map((row, index) => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>{row.sku}</TableCell>
                        <TableCell>{row.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </PopupSecondTableWrapper>
            </Paper>
          </Grid>
        </Box>
      </PopupTablesWrapper>
    </ModalWrapper>
  )
}

const DiscountRenderCell = (params) => {
  const { t } = useTranslation()

  if(params.row.type !== BUY_N_GET_X){
    return <RenderCellWithToolTip t={t} {...params}/>
  }

  if(params.value?.selectionCriteria === ANY){
    return <>{t('createPromo.anyQtySKU').replace('{qty}',params.value.qty[0])}</>
  }

  const freeSku = params.row.promoBuyNGetX?.freeSku;
  const haveMultiple = params?.value?.length > 1 || freeSku?.length > 1;

  if(haveMultiple){
    return (
      <ShowMoreDiv onClick={() => params.colDef.viewPromoDetailsHandler(params.row,
        (params.colDef.canUserEdit && !checkISDisable(params.row.endDate)))}>
        <ShowMoreWrapper>
          {t('brandPromo.viewDetails')}
        </ShowMoreWrapper>
      </ShowMoreDiv>
    )
  }

  if(params.value !==undefined && !params.value?.brandId){

    const freeQty = freeSku[0]?.qty?.length > 0 ? freeSku[0].qty[0] : 0

    return (
      <ShowMoreRenderCellWrapper marginTop={haveMultiple ? '22px' : '0px'}>
        <TooltipWrapper
        title={params?.value[0]?.sku} enterTouchDelay={0} componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'white',
              color: 'black',
              border: '2px solid #E8EBEF',
              '& .MuiTooltip-arrow': {
                color: 'white',
                fontSize: 20
              },
              '& .MuiTooltip-arrow::before': {
                border: '2px solid #E8EBEF',
                boxSizing: 'border-box'
              }
            }
          },
          arrow: {
            fontSize: 50
          }
        }} arrow
        >
          <PMargin0>{params?.value[0]?.sku}{freeQty && `:`}</PMargin0>
        </TooltipWrapper>
        {
          freeQty && <FreeUnitWrapper>{`${freeQty} Units`}</FreeUnitWrapper>
        }
      </ShowMoreRenderCellWrapper>
    )
 }else{
  return(<BlankText> - </BlankText>)
 }
}

const ProductNameValueGetter = (params) => {
  if(params.row.type === BUY_N_GET_X){
    const paidSku = params.row.promoBuyNGetX?.baseSku;
    if(paidSku?.length > 0){
      if( paidSku[0].brandId){
        return paidSku[0]
      }
      return paidSku[0].promoSkus
    }
  }
  return params.row.promoSkus
}

const DiscountValueGetter = (params) => {

  if(params.row.type === BUY_N_GET_X){
    const freeSku = params.row.promoBuyNGetX?.freeSku;
    if(freeSku?.length > 0){
      if( freeSku[0].brandId){
        return freeSku[0]
      }
      return freeSku[0].promoSkus
    }
  }
  return params.row.discount

}

const columns = (t, editPromoHandler, features, viewPromoDetailsHandler) => ([
  {
    field: 'promoSkus',
    headerName: t('brandCatalouge.skuID'),
    selectable: false,
    editable: false,
    sortable: false,
    renderCell: SkuRenderCell,
    valueGetter: ProductNameValueGetter,
    editPromoHandler: editPromoHandler
  },
  {
    field: 'name',
    headerName: t('addProduct.productName'),
    minWidth: 350,
    sortable: false,
    renderCell: ProductNameRenderCell,
    valueGetter: ProductNameValueGetter,
    editPromoHandler: editPromoHandler,
    viewPromoDetailsHandler: viewPromoDetailsHandler,
    canUserEdit : features['Brand_Promo_Edit'],
    flex: 1
  },
  {
    field: 'type',
    headerName: t('brandPromo.promoType'),
    sortable: true,
    renderCell: RenderCellWithToolTip,
    valueGetter: (params) => params?.value?.replaceAll("_"," "),
    minWidth: 80,
    flex: 1
  },
  {
    field: 'discount',
    headerName: t('brandPromo.discount'),
    renderCell: DiscountRenderCell,
    valueGetter: DiscountValueGetter,
    minWidth: 95,
    viewPromoDetailsHandler: viewPromoDetailsHandler,
    canUserEdit : features['Brand_Promo_Edit'],
    flex: 1
  },
  {
    field: 'startDate',
    headerName: t('brandPromo.startDate'),
    renderCell: StartDateRenderCell,
    minWidth: 95,
    flex: 1
  },
  {
    field: 'endDate',
    headerName: t('brandPromo.endDate'),
    renderCell: EndDateRenderCell,
    editPromoHandler: editPromoHandler,
    canUserEdit : features['Brand_Promo_Edit'],
    flex: 1,
    t
  }
])

const loadingRow = (t) => ([
  { id: 0, promoSkus: [{ name: t('brandCatalouge.loading'), sku: t('brandCatalouge.loading') }], type: t('brandCatalouge.loading'), discount: t('brandCatalouge.loading'), startDate: '', endDate: '' }
])

const DataTable = ({ loading, promoList, sortingHandler, pageChangehandler, totalPromo, editPromoHandler, features, viewPromoDetailsHandler }) => {
  const { t } = useTranslation()

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5
    }
  }, [])

  const handleSortModelChange = React.useCallback(sortModel => {
    if (sortModel.length) {
      let field = null
      if (sortModel[0].field === 'type') {
        field = 'PROMO_TYPE'
      } else if (sortModel[0].field === 'startDate') {
        field = 'START_DATE'
      } else if (sortModel[0].field === 'endDate') {
        field = 'END_DATE'
      } else if (sortModel[0].field === 'discount') {
        field = 'DISCOUNT'
      }

      if (field) {
        sortingHandler({
          sort: field,
          sortOrder: sortModel[0].sort.toUpperCase()
        })
      }
    } else {
      sortingHandler({})
    }
  }, [sortingHandler])

  return (
    <DataGridWrapper container>
      <MuiDataGrid
        loading={loading}
        getRowSpacing={getRowSpacing}
        rows={!loading ? promoList : loadingRow(t)}
        columns={columns(t, editPromoHandler, features, viewPromoDetailsHandler)}
        pageSize={20}
        onPageChange={pageChangehandler}
        rowHeight={70}
        rowCount={totalPromo}
        onSortModelChange={handleSortModelChange}
        onRowClick={(data)=> {
          handleClickRow(data, viewPromoDetailsHandler)
        }}
      />
    </DataGridWrapper>
  )
}

DataTable.displayName = 'BrandPromoDataTableComponent'
export default DataTable
