import styled from 'styled-components'

export const OrderInfoWrapper = styled.div`
  font-family: Noto Sans;
  margin: 20px 40px 0 29px;
`

export const SummaryWrapper = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #222222;
`

export const InfoGrid = styled.div`
  margin: 10px 20px 10px 0;
  margin-right: 20px;
  display: grid;
  grid-template-columns: 3fr 2fr 5fr;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }  
`

export const NameWrapper = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #000;
`

export const PhoneEmailWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  margin-top: 2px;
  text-align: left;
  color: #21212199;
`

export const OrderAddress = styled.div`
  color: #21212199;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

`
