import backButton from '../../../assets/images/icons/icon-back-order-details.svg'
import { useTranslation } from 'react-i18next'

import {
  OrderModalHeaderWrapper,
  BackImg,
  HeadingTitle,
  HeadingSection,
  HeadingContent,
  StatusWrapper
} from './style'

const Header = ({
  status,
  orderNo,
  storeName,
  setModalVisibility
}) => {
  const { t } = useTranslation()

  return (
    <OrderModalHeaderWrapper>
      <BackImg
        onClick={() => setModalVisibility(false)}
        src={backButton}
        alt='modal_back_close_button'
      />
      <HeadingSection>
        <HeadingTitle>
          {t('orderDetails.orderNo')}
        </HeadingTitle>
        <HeadingContent>
          {orderNo}
        </HeadingContent>
      </HeadingSection>
      <HeadingSection>
        <HeadingTitle>
          {t('orderDetails.storeName')}
        </HeadingTitle>
        <HeadingContent>
          {storeName}
        </HeadingContent>
      </HeadingSection>
      <StatusWrapper>
        {status?.charAt(0).toUpperCase()}{status?.slice(1).toLowerCase()}
      </StatusWrapper>
    </OrderModalHeaderWrapper>
  )
}

export default Header
