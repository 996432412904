import styled from 'styled-components'

export const ModalWrapper = styled.div`
  & > div > div:last-child{
    left: 0 !important;
    max-width: calc(100vw - 20px);
  }
`

export const Wrapper = styled.div`
  width:800px;
  max-height:calc(100vh - 120px);
  overflow:auto;
  @media (max-width: 1000px) {
    width:calc(100vw - 100px);
    max-width:800px;
  }
`

export const Heading = styled.h4`
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #360D20;
  width:100%;
  margin:0;
  padding-bottom:40px;
`

export const Content = styled.div`
  display:flex;
  justify-content:space-around;
  @media (max-width: 768px) {
    flex-direction:column;
  }
`

export const Image = styled.img`
  width:100%;
  max-width:305px;
  height:270px;
`

export const ListWrapper = styled.ul`
  min-width:300px;
  list-style:none;
  padding:0 0 0 20px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding-top:10px;
  }
`

export const List = styled.li`
  font-size: 16px;
  line-height: 26px;
  color: #262928;
  position:relative;
  margin:0;
  padding-bottom:30px;
  padding-left:40px;
  &:before{
    content:attr(data-text);
    border-radius:50%;
    width:30px;
    height:30px;
    /* text-align:center; */
    display: flex;
    justify-content: center;
    align-items: center;
    background:#B9C3C0;
    color:#fff;
    margin-right:10px;
    /* display:inline-block; */
    font-size:16px;
    font-weight:bold;
    line-height: 17px;
    position: absolute;
    top: -2px;
    left: 0;
  }
  &[data-flag='active']:before{
    background:#4A4A4A;
  }

  @media (max-width: 768px) {
    padding-bottom:10px;
  }
`

export const ButtonWrapper = styled.div`
  padding-top:20px;
  display:flex;
  justify-content:space-between;
`

export const Button = styled.button`
  padding:5px 10px;
  color:#DA2A52;
  border:none;
  background:transparent;
  font-weight:500;
  cursor:pointer
`
