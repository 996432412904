// import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Modal from '../Modal'
import {
  ModalWrapper,
  Wrapper,
  Heading,
  Content,
  Image,
  ListWrapper,
  List,
  ButtonWrapper,
  Button
} from './style'

import HowItWorksImage from '../../assets/images/how-it-works.png'

const HowItWorks = () => {
  const [show, setShow] = useState(!localStorage?.connectionsMessage)
  useEffect(() => {
    localStorage.setItem('connectionsMessage', false)
  }, [])

  const dontShowAgain = () => {
    setShow(false)
    localStorage.setItem('connectionsMessage', false)
  }

  return (
    <ModalWrapper>
      <Modal showModal={show} toggleModal={() => setShow(false)}>
        <Wrapper>
          <Heading>How it Work's</Heading>
          <Content>
            <Image src={HowItWorksImage} />
            <ListWrapper>
              <List data-text='a' data-flag='active'>Click on Add New Store</List>
              <List data-text='b'>Login to your store</List>
              <List data-text='c'>Sync Data</List>
              <List data-text='d'>You are all set.</List>
            </ListWrapper>
          </Content>
          <ButtonWrapper>
            <Button onClick={dontShowAgain}>Don't show it again</Button>
            <Button onClick={() => setShow(false)}>Okay Got it</Button>
          </ButtonWrapper>
        </Wrapper>
      </Modal>
    </ModalWrapper>
  )
}

HowItWorks.displayName = 'HowItWorks'

export default HowItWorks
