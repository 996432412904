import { styled } from '@mui/system';
import {
  Box, Grid, CardHeader, InputAdornment, Button, TextField, Autocomplete, Chip, TableHead, ListItemText,
} from '@mui/material';
import MuiButton from '../MuiButton';

export const FileUploadWrapper = styled(Box)(`
  width:100%;
  display: flex;
  flex-wrap: wrap;
`);

const media = {
  large_laptop: '@media(max-width: 2560px)',
  laptop: '@media(max-width: 1000px)',
  tablet: '@media(max-width: 768px)',
  mobile: '@media(max-width: 600px)',
};

export const Main = styled(Grid)(`
  padding-left: 3vw;
  padding-right: 3vw;

  ${media.laptop} {
    padding-left: 0vh;
  } 
`);

export const BoxM = styled(Box)(`
  margin-top: 3vh;
  padding: 2vh;
`);

export const SelectWrapper = styled('div')`
margin-top: 10px;
padding-right:20px;
width: '40%'
`;

export const SelectLabel = styled('div')`
margin-bottom: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
`;

export const ProductOuterWrapper = styled(Box)(`
  padding: 2rem 2rem 2rem 1rem;
  width: 90%;
  margin: 0 auto;
  & div {
    box-sizing: border-box;
  }
  @media (max-width: 600px){
    padding: 1rem 1rem 1rem .5rem;
  }
`);

export const MuiSelectWrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
`);

export const ProductWrapper = styled(Box)((props) => `
  padding-left: 20px;
  width: 100%;
  background: #FFFFFF;
  margin-top: 36px;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(136, 150, 203, 0.121312);
  border-radius: 10px;
  ${props.isAddToStore && `
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
  `}
  & > div:last-child {
    padding-bottom:0;
  }
  @media (max-width: 425px) {
    margin: 0px;
    padding: .5rem;
  }
`);

export const Title = styled('h1')(`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.48px;
`);

export const SelectTitle = styled('label')((props) => `
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${props.error ? '#FF5151' : '#536082'};
  margin-top: 12px;
  margin-bottom: 10px;
`);

export const TextTitle = styled('label')((props) => `
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: ${props.margin ? props.margin : '10px'};
  color: ${props.error ? '#FF5151' : '#536082'};
  margin-top: ${props.margintop ? props.margintop : '20px'};
`);

export const FormControl = styled(Box)(`
  width:100%;
  display: flex;
  flex-direction: column;
  padding:0 20px;
  @media (max-width: 1000px) {
    margin: .5rem 0;
    // width: auto;
  }
  @media (max-width: 600px) {
    padding: 0 10px;
  }
`);

export const FormButtonControl = styled(Box)(`
  display: flex;
  flex-direction: row;

  padding:0 20px 15px;

  & button {
    margin: 0 5px;
  }

  & button svg {
    margin-left:4px;
  }

  @media (max-width: 1000px) {
    margin: .5rem 0;
    width: auto;
  }
  @media (max-width: 600px) {
    padding: 0 10px;
    & > div > a {
      width: calc(100% - 100px);
    }
  }
`);

export const FormLabel = styled('label')(`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
  width:100%;
`);

export const FormField = styled(Box)(`
  width: 100%;
  & > div {
    min-width: 100%;
  }
`);

export const SectionTitle = styled('h2')(`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #360D20;
  border-bottom: #360D20 solid 2px;
  width: fit-content;
  @media (max-width: 600px){
    font-size: 15px;
  }
`);

export const SectionContent = styled(Box)(`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px){
    flex-direction: column;
    width: 100%; 
  }
`);

export const InputAdornments = styled(InputAdornment)((props) => `
  opacity:.4;
  font-size:12px;
`);

export const FormControlCol1 = styled(Box)(`
  display:flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 30px;
  @media (max-width: 1000px){
    width: 100%;
  }
  @media (max-width: 600px){
    padding: 0 10px 20px;
  }
`);

export const FormControlCol2 = styled(Box)(`
  display:flex;
  flex-direction:column;
  width: 50%;
  padding:0 20px 30px;
  @media (max-width: 1000px){
    width: 100%;
  }
  @media (max-width: 600px){
    padding: 0 10px 20px;
  }
`);

export const FormControlCol3 = styled(Box)((props) => `
  display:flex;
  flex-direction:column;
  width: 33.33%;
  padding:0 20px 30px;
  ${props.isAddToStore && `
    // border: 1px red solid;
  `}
  @media (max-width: 1000px){
    width: 100%;
  }
  @media (max-width: 600px){
    padding: 0 10px 20px;
  }
`);

export const FormControlInput = styled(Box)(`
  width: 66.33%;
  display:flex;
  @media (max-width: 1000px){
    flex-direction: column;
    width: 100%;
  }
`);

export const VariationWrapper = styled(Box)(`
  display: flex;
  width: 100%;
  @media (max-width: 600px){
    width: 380px;
  }
`);

export const VariationFormWrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom:15px;
  }
`);

export const ProductVarientWrapper = styled(Box)(`
  padding:0 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (max-width: 600px){
    padding: 0 10px;
  }
  & > button {
    @media (max-width: 600px){
      min-width: 120px;
    }
  }
`);

export const BackButton = styled(MuiButton)(`
  margin-right: 15px;
`);

export const SubmitWrapper = styled(Box)(`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0 20px;
  @media (max-width: 1000px){
    padding: 0 10px;
  }
  @media (max-width: 600px){
    flex-direction: column;
  }

  & > div > button {
    @media (max-width: 600px){
      flex-direction: column;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  & > div > button:last-child{
    @media (max-width: 600px){
      margin-bottom: 0;
    }
  }
  & > a > button {
    @media (max-width: 600px){
      margin-bottom: 10px;
      width: 100%;
    }
  }
`);

export const FormControlSwitchWrap = styled(Box)(`
  border: 1px #DADADA solid;
  background-color: #F9FBFD;
  border-radius: 4px;
  padding:0px 10px 10px;
  margin-top:8px;
`);

export const CardHeaderWrap = styled(CardHeader)(`
  padding:10px 10px 10px 0;
  align-items: flex-start;
  span {
    display: flex;
    align-items: center;
  }
`);

export const CardOuterWrap = styled(Grid)((props) => `
  border-bottom: 1px #f1f1f1 solid;
  margin-bottom: 10px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
  ${props?.isAddToStore && `
    flex-direction: column;
    & > div {
      width: calc(100% - 20px);
      padding: 10px 20px;
    }
  `}
  @media (max-width: 1000px){
    flex-direction: column;
    & > div{
      width: 100%;
      padding: 10px 20px 0;
    }
  }
  @media (max-width: 600px){
    & > div{
      padding: 0 10px 0;
    }
  }
`);

export const ImgWrap = styled(Grid)(`
  width:115px;
  height:90px;
  background:#d3d3d3;
  overflow:hidden;
  img {
    max-width:100%;
  }
`);

export const StoreLogo = styled('img')(`
  width:30px;
  max-height:30px;
`);

export const ProductAttributeWrapper = styled('div')((props) => `
  width: 100%;
  ${props.isAddToStore && `
    & > div {
      width: 100%;
    }
    & > div > div{
      width: calc(50% - 20px);
      padding:0 10px 30px;
      @media (max-width: 600px){
        width: calc(100% - 20px);
      }
    }
  `};
`);

export const MuiTextFieldWithSelect = styled('div')((props) => `
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between;
    & > label {
      width: 100%;
    }
    & > div {
      width: 48%;
    }
`);

export const SelectField = styled(TextField)((props) => `
    margin-top: 0px;
    justify-content: space-between;
    background-clor: #FBFBFB;
    & > label {
      width: 100%;
    }
    & > div {
      height: 42px;
      border-radius: 6px;
    }
`);

export const SelectDateWrapper = styled('div')`
margin-top: 10px;
padding-right:20px;
width: '60%'
`;

export const CustomGrid = styled(Grid)((props) => `
  display: flex;
  width: ${props.type === '' ? '57%' : props.width};
`);

export const CreateButton = styled(Button)((props) => `
  border: 1px #DADADA solid;
  background-color: ${props.isDisable ? '#EEE' : '#162E9A'};
  border-radius: 8px;
  margin-top: 8px;
  width: 15%;
  color: #FFFFFF;
  align-item: center;
  height: 40px;
  ml: 1;
  "&.MuiButtonBase-root:hover": {
        background-color: '#162E9A';
    }
`);

export const BottomGrid = styled(Grid)(`
align-items: center;
margin-bottom: 30px;
margin-top: 70px;
`);

export const InputLabel = styled('span')(`
  margin-left: 15px;
  margin-bottom: -30px;
  margin-top: 11px;
  opacity: 50%;
  font-size: 14px;
  z-index: 1;
  position: absolute;
  width: fit-content;
`);

export const ChipWrapper = styled('div')(`
width: '90%';
margin-bottom: 10px;
& > div {
  width:90%;
}
`);

export const PromoBack = styled(Button)(`
  color: #536082;
`);

export const SelectAutoComplete = styled(Autocomplete)((props) => `
    background-clor: #FBFBFB;
    margin-bottom: 12px;
    margin-left:-10px;
    padding: 0px;
    & > label {
      width: 100%;
    }
    & > div {
      height: 42px;
      border-radius: 6px;
    }
     > div > div{
      padding: 0px;
    }
`);

export const ProgressContainer = styled(Box)((props) => `
    display: flex;
    justify-content: 'center';
`);

export const SelectGrid = styled(Box)((props) => `
  width: ${props.type === '' ? '20%' : props.width};
  display:flex;
  flex-direction:column;
  & > div {
    padding:0;
    width:100%;
    background-color: white;
    border-radius: 4px;
  }
  & > div > div{
    padding-left:14px;
    font-size:14px;
  }
`);

export const ChipContainer = styled(Chip)((props) => `
    color: ${props.error ? '#FF5151' : 'default'};
`);

export const ModalButton = styled(Button)((props) => `
  background-color: #000;
  border-radius: 8px;
  margin-top: 8px;
  width: 15%;
  color: #fff;
  align-item: center;
  height: 40px;
`);

export const ModalBottom = styled('div')`
display: flex;
justify-content: 'center';
`;

export const ModalTitle = styled('span')((props) => `
  font-style: normal;
  font-weight: ${props.weight};
  font-size: ${props.size};
  display: flex;
  justify-content: ${props.type};
  color: ${props.isTitle ? '#19284D' : '#19284D99'};
  text-align: ${props.type};
  padding-bottom: 10px;
  padding-left: ${props.left};
  padding-right: ${props.right};
`);

export const CreateTitle = styled('h1')((props) => `
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.48px;
  color: #19284D;
  margin-left : ${props.marginleft};
  margin-top : ${props.margintop};
`);

export const SvgContainer = styled('img')`
height: 255px;
width:162px;
`;

export const OfferGrid = styled(Box)((props) => `
  width: ${props.width};
  display:flex;
  flex-direction:column;
  & > div {
    width:85%;
    background-color: #E8EBEF;
    height:${props.height};
  }
`);
export const OfferWarpper = styled('div')((props) => `
display: flex;
background-color: '#E8EBEF';
justify-content: center;
padding: 4px;
width:80%;
flex:1;
`);

export const OfferTitle = styled('div')`
display: flex;
flex:1;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 22px;
color: #536082;
padding-left:0px;
`;

export const SKUText = styled('span')((props) => `
display: flex;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
color: ${props.error ? `red` : `#19284D`};
font-family: 'Noto Sans';
text-align: center;
align-items: center;
height:34px;,
flex:1; 
justify-content: 'center';
`);

export const RowWrapper = styled('div')((props) => `
display: flex;
justify-content: center;
flex:1;
padding: 4px;
background-color: '#FFF';
`);

export const OfferField = styled(TextField)((props) => `
    margin-top: 0px;
    height:34px;
    display:flex;
    background-color: '#E5E5E5';
    margin-bottom: ${props.bottom};
    & > div {
      height: 34px;
      border-radius: 6px;
      width:${props.width};
      background-color: '#E5E5E5';
    }
`);

export const MOQContainer = styled('div')((props) => `
display: flex;
flex-direction: row;
justify-content: flex-end;
width: ${props.width};
align-items: ${props.position};
margin-top:  ${props.margin};
`);

export const OfferContainer = styled('div')`
display: flex;
flex-direction: column;
flex: 1;
`;

export const OfferBox = styled('div')`
display: flex;
flex-direction: row;
`;
export const OfferAction = styled('div')`
flex-direction: row;
background-color: transparent;
justifyContent:'center';
height: 34px;
margin-left: 10px;
display: flex;
justify-content: 'center';
`;

export const MovHeader = styled(TableHead)`
background-color: #E8EBEF;
height: 34px;
'thead':{
  '& th:first-child': {
    borderRadius: '1em 0 0 1em'
  },
  '& th:last-child': {
    borderRadius: '0 1em 1em 0'
  }
}
`;
export const ActionContainer = styled('img')((props) => `
height: ${props.height ? props.height : `24px`};
width: ${props.width ? props.width : `24px`};
`);

export const IconContainer = styled(Grid)`
display: flex;
justify-content: center;
`;

export const DescriptionText = styled('label')((props) => `
font-family: 'Noto Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
color: rgba(22, 46, 154, 0.6);
`);

export const ErrorText = styled('label')((props) => `
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  color: #D1432F;
  font-weight: 400;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 3px
`);

export const ListText = styled(ListItemText)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #19284D;
`;

export const TextView = styled(TextField)((props) => `
    margin-top: 0px;
    justify-content: space-between;
    background-clor: #FBFBFB;
    & > label {
      width: 100%;
    }
    & > div {
      height: 42px;
      border-radius: 6px;
      padding: 0px;
    }
`);

export const SearchGrid = styled(Box)((props) => `
  width: ${props.type === '' ? '20%' : props.width};
  display:flex;
  flex-direction:column;
  & > div {
    padding:0;
    width:100%;
    background-color: white;
    border-radius: 4px;
  }
`);

export const SearchListContainer = styled('div')`
  width: 560px;
  height: 400px;
`;

export const Separator = styled('div')`
height: 10px;
background-color: #F7F7F7;
width: '100%';
margin-top: 8px;
`;

export const ImgIconInfo = styled('img')(`
  width: 18px;
  height: 18px;
  margin-left: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
`);

export const ImgIconAddSku = styled('img')(`
  width: 22px;
  height: 22px;
  margin-right: 3px;
`);

export const ImgIconDelteSku = styled('img')((props) => `
  width: 20px;
  height: 20px;
  margin-left: 20px;
  margin-top: ${props.margintop || '55px'};
  cursor: pointer;
`);

export const AddOfferButton = styled(Button)`
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  width: 220px;
  height: 48px;
  border: 1px solid #162E9A;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  color: #162E9A;
  background: #FFFFFF;
  cursor: pointer;
  margin-top: 15px;
`;

export const AddSkuButton = styled('div')((props) => `
margin-top: ${props.margintop};
height: ${props.height};
font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 14px;
color: #25AE88;
cursor: pointer;
margin-left: 40px;
align-items: center;
display: flex;
`);

export const BorderContainer = styled('div')`
  padding: 7px 9px 18px 9px;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  margin-top: 13px;
`;

export const FreeSKUContainer = styled(Box)`
background: #F9F9F9;
border: 2px solid #F1F1F1;
border-radius: 10px;
display: flex;
flex-direction: row;
padding-bottom: 25px;
margin-bottom: 10px;
`;

export const ImgFreeSkuTag = styled('img')(`
  width: 28px;
  height: 55px;
  margin: 1px
`);

export const SearchContainer = styled('div')`
  width: 100%;
`;

export const NoSKUText = styled('div')`
margin-top: 10px;
justify-content: center;
text-align: center;
`;