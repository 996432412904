import React from 'react'
import { useTranslation } from 'react-i18next';
import { NoPromoWrapper, NoPromoIconDiv, NoPromoIconWrapper, PrimaryText, SecondaryText } from './style'
import NoPromoIcon from '../../assets/images/msp-compare-icon.svg'

const NoPromo = () => {
  const { t } = useTranslation()

  return (
    <NoPromoWrapper>
      <NoPromoIconDiv>
        <NoPromoIconWrapper src={NoPromoIcon} alt='NoPromoIcon' />
      </NoPromoIconDiv>
      <PrimaryText>{t(`pageBuilderLink.comingSoon`)}</PrimaryText>
      <SecondaryText>{t(`pageBuilderLink.mspCompareDescription`)}</SecondaryText>
    </NoPromoWrapper>
  )
}

NoPromo.displayName = 'NoPromoComponent'
export default NoPromo
