import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import NewStoreModal from '../NewStoreModal'
import StoreCard from '../StoreCard'
import LoadingSpinner from '../LoadingSpinner'
import HowItWorks from '../HowItWorks'
import NeedHelp from '../NeedHelp'
import ConnectionHome from '../ConnectionHome'
import { useTranslation } from 'react-i18next'
import EventEmitter, { Events } from '../../utilities/eventEmitter'

import {
  Button,
  ConnectedStoreList,
  ConnectionContainer,
  ModalWrapper,
  PageHeader,
  PageTitle,
  DisconnectedStoresWrapper
} from './style'

const Connections = props => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [pageTitle, setPageTitle] = useState('Connect Store')
  const [delay, setDelay] = useState(true)
  const ConnectionCount = useSelector((state) => state.stores.get('content').toJS())

  const {
    error = '',
    isLoading = false,
    disconnectedStores = [],
    connectedStores = [],
    onGetStoreList,
    marketplaceListError,
    // marketplaceListIsLoading,
    integratedMarketplaces,
    onConnectShopee,
    onDisconnectStore,
    onSyncProducts,
    onSyncOrders,
    onGetMarketplaceList,
    storesTotalRecords,
    onGetUserInfo
  } = props

  const errorMessage = error || marketplaceListError
  useEffect(() => {
    setTimeout(() => {
      setDelay(false)
    }, 2000)
  }, [])
  useEffect(() => {
    // console.log(errorMessage)
    if (errorMessage === 'Products sync completed' || errorMessage === 'Orders sync completed') {
      toast.success(errorMessage)
    } else if (errorMessage !== '') {
      toast.error(errorMessage)
    }
  }, [errorMessage])

  const connectedStoreCount = connectedStores?.length || 0
  const disconnectedStoreCount = disconnectedStores?.length || 0

  const isStoreVisible = (connectedStoreCount + disconnectedStoreCount) > 0

  useEffect(() => {
    if (connectedStoreCount) {
      setPageTitle(`${t('connections.currentStoreConnections')} (${connectedStoreCount}) `)
    }
  }, [connectedStoreCount, t])

  const toggleModal = () => {
    EventEmitter.emit(Events.CONNECT_STORE, { page: 'connections' })
    setShowModal(!showModal)
    // toast.info('errorMessage', { theme: 'light' })
    // toast.info('errorMessage', { theme: 'dark' })
    // toast.success('errorMessage')
    // toast.info('errorMessage')
  }

  useEffect(() => {
    onGetMarketplaceList()
  }, [onGetMarketplaceList])

  useEffect(() => {
    onGetStoreList()
  }, [onGetStoreList])

  useEffect(() => {
    onGetUserInfo()
  }, [onGetUserInfo])

  const renderConnectedStore = () => (
    <ConnectedStoreList>
      {connectedStores.map((store, key) => <StoreCard key={key} {...store} onSyncOrders={onSyncOrders} onSyncProducts={onSyncProducts} onDisconnectStore={onDisconnectStore} />)}
    </ConnectedStoreList>
  )

  const reconnectStoreClick = () => {
    EventEmitter.emit(Events.CONNECT_STORE, { page: 'connections' })
    onConnectShopee()
  }

  const renderDisconnectedStores = () => {
    return (
      <DisconnectedStoresWrapper>
        <PageHeader>
          <PageTitle>{t('storeCard.disconnectedConnections')}  ({disconnectedStoreCount})</PageTitle>
        </PageHeader>
        <ConnectedStoreList>
          {disconnectedStores.map((store, key) => <StoreCard key={key} {...store} reconnectStoreClick={reconnectStoreClick} />)}
        </ConnectedStoreList>
      </DisconnectedStoresWrapper>
    )
  }

  const renderPageHeader = () => {
    return (
      <PageHeader>
        <PageTitle>{connectedStoreCount > 0 ? pageTitle : ''}</PageTitle>
        <ModalWrapper>
          <NewStoreModal
            showModal={showModal}
            toggleModal={toggleModal}
            marketplaceListContent={integratedMarketplaces}
            onConnectShopee={onConnectShopee}
          />
          {
            isStoreVisible && <Button onClick={toggleModal}>{t('connections.addNewStore')}</Button>
          }
        </ModalWrapper>
      </PageHeader>
    )
  }
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isLoading &&
        <>
          <ConnectionContainer>
            {delay === false ? ConnectionCount.length > 0 ? '' : <HowItWorks /> : ''}
            {(isStoreVisible || toggleModal) && renderPageHeader()}
            {connectedStoreCount > 0 && renderConnectedStore()}
            {disconnectedStoreCount > 0 && renderDisconnectedStores()}
            {!isStoreVisible && storesTotalRecords <= 0 && <ConnectionHome NewStoreClick={toggleModal} />}
          </ConnectionContainer>
          <NeedHelp />
        </>}
    </>
  )
}

Connections.defaultProps = {
  onGetUserInfo: () => {}
}

Connections.propTypes = {
  connectedStores: PropTypes.array,
  disconnectedStores: PropTypes.array,
  error: PropTypes.string,
  onGetStoreList: PropTypes.func,
  isLoading: PropTypes.bool,
  marketplaceListError: PropTypes.string,
  marketplaceListIsLoading: PropTypes.bool,
  marketplaceListContent: PropTypes.array,
  onConnectShopee: PropTypes.func,
  onGetMarketplaceList: PropTypes.func,
  onGetUserInfo: PropTypes.func
}
Connections.displayName = 'Connections'
export default Connections
