import React from 'react'
import { Fade, Backdrop, Grid } from '@mui/material'
import { DateRangePicker } from 'materialui-daterange-picker'
import { CalendarMonth, Clear } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { ModalWrapper, CalanderIconWrapper, DateRangeDiv, DateRangeWrapper, SearchIconWrapper, SearchInputWrapper, SelectWrapper, SelectLabel, SelectedItemsCount } from './style'
import MuiTextField from '../MuiTextField'
import MuiSelect from '../MuiSelect/MuiSelect'
import SearchIcon from '../../assets/images/searchIcon.svg'

const FilterRow = ({
  skuList,
  handleSKUSelect,
  searchtext,
  handleSearchTextChange,
  handleDateRangeSelect,
  handlePromoTypeSelect,
  dateRange,
  promoTypeList,
  selectedSKU,
  selectedPromoType
}) => {
  const { t } = useTranslation()
  const [openDateRangeSelector, setOpenDateRangeSelector] = React.useState(false)
  const anchorRef = React.useRef(null)

  const dateRangeSelectorToggle = () => {
    setOpenDateRangeSelector((prevOpen) => !prevOpen)
  }
  const clearDateHandler = (e) => {
    e.stopPropagation()
    handleDateRangeSelect({ startDate: null, endDate: null })
  }

  const customSelectedChip = (selected) => {
    return (
      <>
        <SelectedItemsCount>{selected.length > 0 ? selected.length + ' Item' : 'All'} </SelectedItemsCount>
      </>
    )
  }

  const promoTypeSelectedChip = (selected) => {
    return (
      <>
        <SelectedItemsCount>{selected.length > 0 ? selected.length + ' Item' : 'All'} </SelectedItemsCount>
      </>
    )
  }

  return (
    <Grid container columns={16}>
      <Grid item md={6} sm={16} xs={16}>
        <SearchInputWrapper>
          <MuiTextField
            onChange={(e) => {
              handleSearchTextChange(e.target.value)
            }}
            margin='normal'
            value={searchtext}
            name='searchtext'
            type='text'
            variant='outlined'
            size='small'
            placeholder={t('brandCatalouge.searchByProdOrSku')}
            InputProps={{
              startAdornment: <SearchIconWrapper><img src={SearchIcon} alt='search-icon' /></SearchIconWrapper>
            }}
          />
        </SearchInputWrapper>
      </Grid>
      <Grid item md={10} sm={16} xs={16}>
        <Grid container direction={{ md: 'row-reverse' }} columns={16}>
          <Grid item md={3} sm={8} xs={8}>
            <SelectWrapper
              onClick={dateRangeSelectorToggle}
              ref={anchorRef}
            >
              <SelectLabel>{t('brandPromo.dateRange')}</SelectLabel>
              <DateRangeDiv>{dateRange.startDate && dateRange.endDate ? t('brandPromo.change') : t('brandPromo.select')}
                <CalanderIconWrapper>
                  {dateRange.startDate && dateRange.endDate ? <Clear onClick={clearDateHandler} /> : <CalendarMonth />}
                </CalanderIconWrapper>
              </DateRangeDiv>
            </SelectWrapper>
            <ModalWrapper
              open={openDateRangeSelector}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade in={openDateRangeSelector}>
                <DateRangeWrapper>
                  <DateRangePicker
                    open
                    initialDateRange={dateRange}
                    toggle={() => { dateRangeSelectorToggle() }}
                    onChange={(range) => {
                      if (range) {
                        handleDateRangeSelect(range)
                      } else {
                        handleDateRangeSelect({ startDate: null, endDate: null })
                      }
                      dateRangeSelectorToggle()
                    }}
                  />
                </DateRangeWrapper>
              </Fade>
            </ModalWrapper>
          </Grid>
          <Grid item md={3} sm={8} xs={8}>
            <SelectWrapper>
              <SelectLabel>{t('brandPromo.promoType')}</SelectLabel>
              <MuiSelect
                name='productType'
                size='small'
                value={selectedPromoType}
                type='MULTIPLE_SELECT_COMBO_BOX'
                onChange={(event, selectedData) => {
                  handlePromoTypeSelect(selectedData)
                }}
                data={[...promoTypeList.map((val) => { return { name: val } }), {
                  id: 'archived',
                  name: 'Archived',
                  switchButton: true
                }]}
                selectlabel='name'
                selectvalue='name'
                customSelectedChip={promoTypeSelectedChip}
                sx={{ minWidth: '100px' }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 300, minWidth: 300 } } }}
              />
            </SelectWrapper>
          </Grid>
          <Grid item md={3} sm={8} xs={8}>
            <SelectWrapper>
              <SelectLabel>{t('brandCatalouge.sku')}</SelectLabel>
              <MuiSelect
                name='category'
                value={selectedSKU}
                size='small'
                type='MULTIPLE_SELECT_COMBO_BOX'
                onChange={(event, selectedData) => {
                  handleSKUSelect(selectedData)
                }}
                data={skuList.map((val) => { return { name: val.sku + ' : ' + val.name, sku: val.sku } })}
                selectlabel='name'
                selectvalue='sku'
                customSelectedChip={customSelectedChip}
                sx={{ width: '100px' }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                dropdownMaxWidth='15%'
              />
            </SelectWrapper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

FilterRow.displayName = 'BrandPromoFilterRowComponent'
export default FilterRow
