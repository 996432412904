import { styled } from '@mui/system'
import { Box, Grid, Paper, LinearProgress, IconButton } from '@mui/material'
import Shimmer from "react-shimmer-effect";
const media = {
  large_laptop: '@media(max-width: 2560px)',
  laptop: '@media(max-width: 1000px)',
  tablet: '@media(max-width: 768px)',
  mobile: '@media(max-width: 600px)'
}

export const Main = styled('div')(`
padding-left: 3vw;
padding-right: 3vw;
`)
export const GridTop = styled(Grid)(`
margin-top: 4vh
`)

export const GridSelect = styled(Grid)(`
margin-left: 0px
`)

export const DivWraper = styled('div')(`
margin: 3vh 2% 3vh;
`)

export const DashboardTitle = styled('h1')`
height: 41px;
font: normal;
font-weight: 600;
font-size: 28px;
line-height: 38px;
letter-spacing: 0.56px;
color: #19284D;
margin: unset;
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 38px;
letter-spacing: 0.56px;
  ${media.tablet}{
  font-size: 18px;
}
`
export const Titlebottom = styled('p')`
font-size: 12px;
color: #7D86A9;
`
export const BoxM = styled(Box)(`
margin-top: 3vh
`)

export const GridM = styled(Grid)(`
${media.tablet}{
  &.center{
    justify-content: center !important;
  }
}
`)

export const GridP = styled(Grid)(`
padding: 5px;
${media.tablet}{
  &.center{
    justify-content: center !important;
  }
}
`)

export const GraphGrid = styled(Grid)(`
height: 500px;
overflow:auto;
${media.tablet}{
  &.center{
    justify-content: center !important;
  }
}
`)

export const SectionTitleGrid = styled(Grid)(`
margin-top: 2vh;
margin-bottom : 3vh;
${media.tablet}{
  &.center{
    justify-content: center !important;
  }
}
`)

export const GraphTitle = styled(Grid)(`
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 28px;  
padding-left: 20px;
padding-top: 15px;
color: #536082;
font-weight:600;
`)

export const PieGraphTitle = styled(Grid)(`
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 28px;
text-align: center;
padding-top: 4vh;
`)

export const SelectWrapper = styled('div')`
padding-right : 2vh;
`

export const SelectLabel = styled('div')`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
`

export const ItemM = styled(Paper)((props) => `
margin: 5px 5px;
text-align: left;
padding: 1.1vw 0.7vw 0.4px 0.9vw;
background-color: ${props.backgroundColor};
${media.tablet}{
  height: 9vw;
  font-size: 19px;
  padding: 15px 10px 5px 10px;
}
${media.mobile}{
  height: 9vw;
  font-size: 19px;
  padding: 15px 10px 25px 10px;
}
`)

export const AggCardTitle = styled('p')((props) =>`
font-style: normal;
font-size: 1.1vw;
font-weight: 700;
line-height: 19px;
height: ${props.height ? props.height : 'auto'};
margin: 0px 0px 0px 0px;
color: white;
${media.tablet}{
  font-size: 10px;
}
`)

export const AggCardImg = styled('img')`
height: 1.4vw;
margin: 0.5vw 0px 0px -0.1vw;
${media.tablet}{
  height: 12px;
  margin: 8px 0px 0px -3px;
}
`
export const AggCardImprove = styled('p')`
font-style: normal;
font-weight: 600;
font-size: 0.8vw;
color: white;
float: right;
margin-left: auto;
${media.tablet}{
  font-size: 9px;
}
`

export const AggCardImproveDiv = styled('div')`
width: 100%;
`

export const AggCardValue = styled('p')`
font-style: normal;
font-weight: 600;
font-size: 1.3vw;
text-align: center;
margin: 0.4vw 0.3vw 0px 0.2vw;
color: white;
${media.tablet}{
  font-size: 12px;
  margin: 7px 3px 0px 5px;
}
`

export const LinearProgressM = styled(LinearProgress)`
`
export const ItemProgress = styled(Paper)(`
height: 12vh;
margin: 15px 25px;
padding: 0.4rem 1.4rem;
height: auto;
`)
export const Itemheading = styled('h3')(`
font-size: 1.7rem;
font-weight: normal;
margin: unset;
margin-top: 1vh;
`)
export const ItemProgressp = styled('p')(`
font-size: 12px;
color: #7D86A9;
`)
export const HelpWraper = styled('p')(`
font-size: 2rem;
margin: 10vh 0px 0px;
`)
export const TextHelperW = styled('p')(`

`)
export const ImageWraper = styled('img')(`
float: right;
margin: 10px 0px;
`)
export const BtnWraper = styled('div')(`
margin-bottom:4vh;
`)
export const TableWraper = styled(Paper)(`
height: 12vh;
margin: 15px 25px;
padding: 0.4rem 1.4rem;
height: auto;
`)

export const ProgressContainer = styled(Box)(props => `
    display: flex;
    justify-content: 'center';
`)

export const CharTitle = styled('label')`
position: absolute;
width: 30px;
height: 22px;
left: 319px;
top: 687px;
font-family: 'Noto Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
color: #536082;
`

export const IconWrapper = styled('img')`
visibility: visible;
height: 10px;
width: 10px;
`

export const ChartLegendWrapper = styled('div')`
display: flex;
justify-content: center;
gap: 2em;
margin-top: 10px;
`

export const ChartLegend = styled('div')`
display: flex;
justify-content: center;
color: #19284D; 
font-weight: '500';
font-size: '16px'; 
margin-bottom:16px;
`

export const LegendGroup = styled('div')`
justify-content: center;
text-align: center;
display: flex;
gap: 2em;
padding: 10px 0px 10px 0px;
`

export const BarContainer = styled('div')`
display: flex;
flex-direction: row;
flex:'1';
`

export const BarContainerLeft = styled(Grid)`
display: flex;
flex-direction: row;
flex:'1';
`

export const BarContainerRight = styled(Grid)`
display: flex;
flex-direction: row-reverse;
flex:'1';
float: right;
`

export const SortWrapper = styled('div')`
display: flex;
flex-direction: column;
`

export const LegendColorBox = styled('div')((props) => `
background-color: ${props.backgroundColor};
width:15px; 
height: 15px; 
margin-right: 10px;
margin-top: 5px;
`)

export const SortIconWrapper = styled(IconButton)`
margin-top: 15px !important;
`
export const BarToolTipWrapper = styled('div')`
padding: 10px;
`

export const BarToolTipLabel = styled('div')`
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 14px;
color: #222222;
`

export const BarToolTipValue = styled('div')((props) =>`
color: ${props.color};
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 16px;
`)

export const BarToolTipPaper = styled(Paper)`
border: 1px solid #E1E1E1;
`

export const BarToolTipQTY = styled('div')`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
color: #221122;
`

export const BarToolTipLabelValDiv = styled('div')`
margin-bottom: 7px;
`

export const BarToolTipValueWrapper = styled('div')`
display: flex;
`

export const BarToolTipValuePercentage =  styled('div')`
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 16px;
color: #212121;
margin-left: 10px;
`

export const BarToolTipQTYAndChange =  styled('div')`
font-style: normal;
font-weight: 700;
font-size: 8px;
line-height: 16px;
color: #222222
`
export const ShimmerView = styled(Paper)((props) => `
margin: 5px 5px;
text-align: left;
padding: 0.7rem 1rem;
height: 6vw;
background-color: ${props.backgroundColor};
${media.large_laptop}{
  height: 4.25vw;
  width: 12vw;
}
${media.laptop}{
  height: 5vw;
  width: 9vw;
}
${media.tablet}{
  width: 41vw;
  height: 9vw;
  font-size: 19px;
}
${media.mobile}{
  width: 36vw;
  height: 14vw;
  font-size: 19px;
}
`)

export const ShimmerGrid = styled(Paper)(`
margin: 5px 5px;
text-align: left;
padding: 0.7rem 1rem;
height: 20%;
width: 90%;
background-color: #FFF;
${media.large_laptop}{
  height: 90vh;
}
${media.laptop}{
  height: 90vh;
}
${media.tablet}{
  height: 90vh;
  font-size: 19px;
}
`)

export const AggCardValuesGrid =  styled(Grid)`
margin-top: 0.2vw; 
margin-bottom: 0.5vw;
height: 2.7vw;
`