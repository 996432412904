import React, { Component } from 'react'

import { Wrapper } from './style'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError () {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    const stack = errorInfo.componentStack
      .split(/\n\s+/)
      .filter(Boolean)

    // Todo: Add this API Later
    // api.logError({
    //   ...serializeError(error),
    //   url: window.location.href,
    //   stack
    // })
    console.error(error, errorInfo, stack)
  }

  render () {
    const { hasError } = this.state
    const { children } = this.props

    if (!hasError) return children

    return (
      <Wrapper>
        <h1>Something went wrong</h1>
      </Wrapper>
    )
  }
}

export default ErrorBoundary
