import React, { useEffect, useState } from 'react';
import { IconButton, Table, TableRow, TableCell, TableContainer, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OfferTitle, SKUText, OfferField, OfferAction, OfferBox, MovHeader, IconContainer, ActionContainer, OfferContainer, ErrorText } from './styles';
import { removeElement, checkIsValueExist, checkMoqValue, showToast } from "../../utilities/utils";
import addSku from '../../assets/images/brandDashboard/addSku.svg';
import deletePromo from '../../assets/images/brandDashboard/deleteSku.svg';

const OfferTypeComponent = (props) => {
    const { dataModel, columns, onListUpdate, isUpdate, isDisable } = props;
    const { t } = useTranslation()
    const [list, setList] = useState(dataModel);
    const [isModified, setIsModified] = useState(false);

    useEffect(() => {
        setList(dataModel);
    }, [isUpdate]);

    const handleQtyChange = (qty, index) => {
        list.map((model) => {
            const obj = model;
            obj.isError = false;
            obj.isQtyError = false;
            return { ...obj };
        })
        list[index].moq = qty;
        list[index].isQtyError = false;
        isDisable(false)
        if (checkMoqValue(list, index, list[index], 'discount', 'moq', true)) {
            list[index].isQtyError = true;
            isDisable(true)
        }
        setList(list);
        setIsModified(!isModified);
        onListUpdate(list);
    }

    const handleDiscountChange = (discount, index) => {
        list.map((model) => {
            const obj = model;
            obj.isError = false;
            obj.isQtyError = false;
            return { ...obj };
        })
        isDisable(false)
        list[index].isError = false;
        if (discount < 1 || discount > 100) {
            list[index].isError = true;
            showToast(t('errors.discountMax'), 5000);
            isDisable(true)
        }
        list[index].discount = discount;
        if (checkMoqValue(list, index, list[index], 'moq', 'discount', false)) {
            list[index].isError = true;
            isDisable(true)
        }
        setList(list);
        setIsModified(!isModified);
        onListUpdate(list);
    }

    const handleDelete = (item, index) => {
        const current = removeElement(list, index);
        const count = current.filter((model) => model.name === item.name)
        current[index - 1].isAdd = true;
        current[index - 1].isDelete = true;
        if (count.length === 1) {
            current[index - 1].isDelete = false;
        }
        setList(current);
        setIsModified(!isModified);
        onListUpdate(current);
    };

    const addNewRow = (item, index) => {

        if (!checkIsValueExist([...list], 'moq', 'discount')) {
            showToast(t('errors.fillDetails'), 5000);
        } else {
            const obj = {
                name: item.name,
                isAdd: true,
                isDelete: true,
                isParent: false,
                moq: '',
                discount: '',
                skuId: item.skuId
            };
            list[index].isAdd = false;
            list[index].isDelete = false;
            list.splice(index + 1, 0, obj)
            setList(list);
            setIsModified(!isModified);
            onListUpdate(list);
        }

    };
    const loadTable = () => {
        return (
            <TableContainer style={{ background: 'transparent' }}>
                {
                    list.length > 0 && (
                        <Table size="small">
                            <MovHeader
                                sx={{
                                    background: '#E8EBEF',
                                    '& th:first-child': {
                                        borderRadius: '10px 0 0 10px'
                                    },
                                    '& th:last-child': {
                                        borderRadius: '0 10px 10px 0'
                                    }
                                }}
                            >
                                <TableRow >
                                    {
                                        columns.map((item) => (<TableCell style={{ textTransform: 'none', letterSpacing: '0px' }} ><OfferTitle>{item}</OfferTitle></TableCell>))
                                    }
                                </TableRow>
                            </MovHeader>
                            <TableBody>
                                {
                                    loadMOQRow(list)
                                }
                            </TableBody>
                        </Table>
                    )
                }
            </TableContainer>
        )
    }

    const loadMOQRow = (model) => {
        return (
            <>
                {
                    model.map((item, index) => {
                        return (
                            <TableRow
                                key={0}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" >{item.isParent && (<SKUText>{item.name}</SKUText>)}</TableCell>
                                {loadSubRow(item, index)}
                            </TableRow>
                        )
                    })
                }
            </>

        )
    }

    const loadSubRow = (item, index) => {
        return (
            <>
                <TableCell>
                <OfferContainer>
                    <OfferField
                        type='number'
                        id='minQty'
                        value={item.moq}
                        onChange={(e) => handleQtyChange(e.target.value, index)}
                        margin="normal"
                        variant="outlined"
                        placeholder=''
                        width={'90px'}
                        error={item.isQtyError}
                        bottom={item.isError ? '48px': '0px'}
                    />
                    {item.isQtyError && <ErrorText error={true}>{t('errors.moqError')}</ErrorText>}
                    </OfferContainer>
                </TableCell>
                <TableCell>
                   <OfferContainer>
                    <OfferBox>
                        <OfferField
                            type='number'
                            id='discount'
                            value={item.discount}
                            onChange={(e) => handleDiscountChange(e.target.value, index)}
                            margin="normal"
                            variant="outlined"
                            placeholder='%'
                            width={'90px'}
                            error={item.isError}
                            bottom={item.isQtyError ? '48px': '0px'}
                        />
                        <OfferAction>
                            {item.isAdd && (<IconButton onClick={() => addNewRow(item, index)} sx={{ padding: '0px'}}>
                                <IconContainer container={true}>
                                    <ActionContainer src={addSku} alt='confirmPromoImg' />
                                </IconContainer></IconButton>)}
                            {item.isDelete && (<IconButton disableRipple={true} onClick={() => handleDelete(item, index)} sx={{ paddingLeft: '0px' }}> <IconContainer container={true} ><ActionContainer src={deletePromo} alt='confirmPromoImg' height={'34px'} width={'34px'} /></IconContainer> </IconButton>)}
                        </OfferAction>
                    </OfferBox>
                    {item.isError && <ErrorText error={true}>{t('errors.moqError')}</ErrorText>}
                    </OfferContainer> 
                </TableCell>
            </>
        )
    }

    return (
        <>
            {loadTable()}
        </>

    )
};

export default OfferTypeComponent;