import styled from 'styled-components'

const media = {
  large_laptop: '@media(max-width: 2560px)',
  laptop: '@media(max-width: 1000px)',
  tablet: '@media(max-width: 768px)',
  mobile: '@media(max-width: 600px)'
}

export const Main = styled.div`
  /* background: #FAFCFE; */
`

export const Divwraper = styled.div`
text-align: center;
   ${media.laptop}{
    }
`
export const DivwraperCenter = styled.div`
text-align: center;
margin-top: 5vh;
`

export const Imagetag = styled.img`
  background-color: #E4E6EA;
    width: 110px;
    height: 110px;
    border-radius: 105px;
    object-fit: scale-down;
    margin: 46px 0px 2vh 0px;
`
export const TextWraper = styled.p`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: center;
    ${media.mobile}{
      padding: 0px 20px;
      padding-bottom: 3vh;
    }
`

export const TextWraperNoBold = styled.p`
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  ${media.mobile}{
    padding: 0px 20px;
    padding-bottom: 3vh;
  }
`
