import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

import {
  InfoCardWrapper,
  SingleInfoCardWrapper,
  Header,
  Content,
  LeftSideContent,
  RightSideContent
} from './style'

import greaterarrow from '../../../assets/images/greaterarrow.svg'

const InfoCard = ({
  data,
  error = '',
  isLoading = false
}) => {
  const SingleInfoCard = ({
    heading,
    text,
    showArrow = false,
    link = '/',
    status,
    bgColor,
    icon
  }) => {
    if (text === 'Rp undefined') { text = undefined }
    return (
      <SingleInfoCardWrapper style={{ background: bgColor }}>
        <LeftSideContent>
          <Content isSales={!showArrow}>
            {heading}
          </Content>
          <Header isSales={!showArrow}>
            <img src={icon} alt='' style={{ float: 'left', marginTop: '6px', marginRight: '5px' }} />
            <span>{isLoading && !error ? <CircularProgress style={{ color: '#1D2334' }} /> : text || 0}</span>
          </Header>

        </LeftSideContent>
        {
          showArrow &&
            <RightSideContent>
              <Link
                to={link}
                state={{ status }}
              >
                <img src={greaterarrow} alt='' />
              </Link>
            </RightSideContent>
        }
      </SingleInfoCardWrapper>
    )
  }

  return (
    <>
      <InfoCardWrapper>
        {data.map((each, index) => {
          return (
            <SingleInfoCard
              key={index}
              heading={each.heading}
              text={each.text}
              link={each.link}
              showArrow={each.showArrow}
              status={each.status}
              bgColor={each.bgColor}
              icon={each.icon}
            />
          )
        })}
      </InfoCardWrapper>
    </>
  )
}

InfoCard.propTypes = {
  totalOrder: PropTypes.string,
  sales: PropTypes.string,
  pendingOrder: PropTypes.string,
  canceledOrder: PropTypes.string
}

export default InfoCard
