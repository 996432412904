import React, { useState } from 'react'
import { Main, BoxWraper, InputWraper, Label, BtnWraper, UploadImageWrapper, FlexWraper, MuiButton, MuiButtonCancel } from './style'
import { STORES_RENAME, UPLOADIMAGES_CLEAR } from '../../actions'
import { Modal } from '@mui/material'
import Input from '../Input'
import UploadImages from '../../containers/UploadImages'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'

const RenameStore = (props) => {
  const { StoreObject } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ProfileimageKey = useSelector((state) => state?.uploadImages?.get('uploadImagecontent').key)
  const [shopName, setShopName] = useState(StoreObject.shop_name)
  const [description, setDescription] = useState(StoreObject.description)
  const [uploadImageArray, setUploadImageArray] = useState(StoreObject.shop_logo ? [{ url: StoreObject.shop_logo }] : [])

  const formikInitialValues = {
    images: []
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues
  })
  const shopNameHandler = (value) => {
    setShopName(value)
  }
  const desHandler = (value) => {
    setDescription(value)
  }
  const handleImageChange = (data) => {
    setUploadImageArray(data)
    // const imageList = data?.map(i => { return { key: i.key, priority: 1 } }) || []
    // formik.setValues({ ...formik.values, ...{ images: imageList } })
  }
  const updateButtonHandler = () => {
    dispatch({
      type: STORES_RENAME,
      payload: { shopName: shopName, shoplogourl: uploadImageArray?.[0]?.key || '', description: description, id: StoreObject.id }
      // payload: { shopName: shopName, shoplogourl: ProfileimageKey, description: description, id: StoreObject.id }
    })
    props.handleClose()
    dispatch({ type: UPLOADIMAGES_CLEAR })
  }
  const CancelButtonHandler = () => {
    dispatch({ type: UPLOADIMAGES_CLEAR })
    props.handleClose()
  }

  return (
    <>
      <Main>
        <Modal
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <BoxWraper>
            <Label>
              Rename Store
            </Label>
            <InputWraper>
              <Input
                label='Store Name'
                value={shopName}
                onInput={shopNameHandler}
                type='text'
                onEnter={shopNameHandler}
              />
            </InputWraper>
            <FlexWraper>
              <UploadImageWrapper>
                <UploadImages
                  title={t('addProduct.uploadImage')}
                  description={t('addProduct.kindlyAddText')}
                  format='image/jpg, image/jpeg'
                  onImageChange={handleImageChange}
                  error={formik.errors.images}
                  // value={formikInitialValues.images || null}
                  value={uploadImageArray || null}
                  isRequired
                  autoClear={false}
                  mode='addRenamingStore'
                  name='addRenamingStore'
                  hideBrowse={
                    { maxLength: 1 }
                  }
                />
              </UploadImageWrapper>
            </FlexWraper>
            <InputWraper>
              <Input
                label='Description'
                value={description}
                onInput={desHandler}
                type='text'
                onEnter={desHandler}
              />
            </InputWraper>
            <BtnWraper>
              <MuiButtonCancel color='primary' size='large' variant='outlined' onClick={CancelButtonHandler}>
                Cancel
              </MuiButtonCancel>
              <MuiButton styled='primary' size='large' variant='contained' onClick={updateButtonHandler}>
                Update
              </MuiButton>
            </BtnWraper>
          </BoxWraper>
        </Modal>
      </Main>
    </>
  )
}

export default RenameStore
