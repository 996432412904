import styled from 'styled-components'
const media = {
  xl: '@media(max-width: 2560px)',
  lg: '@media(max-width: 1500px)',
  md: '@media(max-width: 850px)',
  sm: '@media(max-width: 600px)'
}
export const ProductListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 37px;
  padding: 23px 2rem 0.5rem 46px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(136, 150, 203, 0.121312);
  border-radius: 10px;
  @media (max-width: 425px) {
    margin: 0px;
    padding: .5rem;
    overflow-x: hidden;
  }
`

export const ProductTopBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const Title = styled.div`
  align-self: center;
  font-weight: 700;
  font-family: Noto Sans;
  line-height: 33px;
  font-size: 24px;
  letter-spacing: 0.48px;
`

export const ProductToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 3vh;
  /* margin: 2rem 0; */
  ${media.sm}{
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
  }
`

export const ProductLabelSelectorWrapper = styled.div`
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
`

export const ProductCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${media.md}{
  justify-content: space-around;
  }
`
export const TextWraperEmpty = styled.p`
  font-size: 14px;
  line-height: 30px;
  font-style: normal;
  font-weight: 500;
  color: #33333A;
`
export const NoRecordWraper = styled.p`
    font-size: 24px;
    margin-top: 20px;
    font-weight: 500;
`
