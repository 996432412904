import styled from 'styled-components'
import {
  Select,
  FormControl
} from '@mui/material'

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    display: block;
  }
`

export const LeftSideContentWrapper = styled.div`
  align-self: center;

  @media (max-width: 800px) {
    text-align: center;
    margin-bottom: 10px;
  }
`

export const DropDownWrapper = styled.div`
  @media (max-width: 640px) {
    text-align: center;
  }
`

export const HeaderImgWrapper = styled.span`
`

export const DateRangeHeading = styled.div`
  color: #212121;
  font-size: 12px;
  margin-left: 2px;
`

export const HeaderImg = styled.img`
  margin-right: 7px;
  margin-top: 8px;
  float: left;
  height:26px; width: 21px;
`

export const WelcomeMessage = styled.span`
  font-family: Noto Sans;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.48px;
  line-height: 33px;
  @media (max-width: 640px) {
    font-size: 25px;
  }

  @media (max-width: 400px) {
    text-align: center;
  }

`

export const LastUpdatedHeaderTextWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #7D86A9;
  margin-top: -5px;
`

export const NoneWrapper = styled.div`
  background:white;
`

export const CustomSelect = styled(Select)((props) => `
  background: #fff;
  width: 130px;
  height: 50px;
`)

export const CustomFormControl = styled(FormControl)`
`
