import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const Hr = styled(Box)(`
    height:0;
    width:100%;
    display:block;
    border:0;
    padding: 50px 0 10px;
    border-bottom: 1px solid #E5E5E5;
    margin: 0;
`)
