import React from 'react'

import Layout from '../Layout'
import BrandsMspDetailsComponent from '../../components/BrandsMspDetails'
import { connect } from 'react-redux'
import { BRAND_MSP_GET_DETAIL, BRAND_MSP_DETAIL_EXPORT, BRAND_MSP_GET_DETAIL_FILTERS } from '../../actions'

const BrandsMspDetails = (props) => {
  return (
    <Layout>
      <BrandsMspDetailsComponent {...props} />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { brandMspDetails, brands, subscription } = state
  return {
    mspDetailsIsloading: brandMspDetails.get('mspDetailsIsloading'),
    mspStoreList: brandMspDetails.get('mspStoreList'),
    mspStoreTotal: brandMspDetails.get('mspStoreTotal'),
    selectedBrand: brands.get('selectedBrand'),
    mspDetailsExportIsloading: brandMspDetails.get('mspDetailsExportIsloading'),
    brandIsLoading: brands.get('isLoading'),
    mspDetailsFilterIsloading: brandMspDetails.get('mspDetailsFilterIsloading'),
    filtersData: brandMspDetails.get('filtersData'),
    brandDetails: brandMspDetails.get('brandDetails'),
    features : subscription.get('features')
  }
}

const mapDispatchToProps = dispatch => ({
  onGetBrandMSPDetails: (query) => {
    dispatch({ type: BRAND_MSP_GET_DETAIL, payload: query })
  },
  downlaodMspDetails: (query) => {
    dispatch({ type: BRAND_MSP_DETAIL_EXPORT, payload: query })
  },
  onGetBrandMSPDetailsFilters: (query) => {
    dispatch({ type: BRAND_MSP_GET_DETAIL_FILTERS, payload: query })
  }
})

BrandsMspDetails.displayName = 'BrandsMspDetailsContainer'
export default connect(mapStateToProps, mapDispatchToProps)(BrandsMspDetails)
