import React, { useState } from 'react';
import { MinMaxWrapper, MinMaxInputsGroup, MinInputWrapper, MaxInputWrapper, MinMaxTitle, MinMaxSpanText, MinMaxError } from './style'
import { useTranslation } from 'react-i18next'

const MinMaxInput = ({title, handleFilterValueChange, filterName, selectedFilterValues, minValue, maxValue}) => {
  const { t } = useTranslation()
    const [minError, setMinError] = useState("");
    const [maxError, setMaxError] = useState("");

    const onChangeHandler = (value,variant) => {
        let errorMessage = ""
        console.log(value)
        //validation
        if(value || value === 0){
            if(value < minValue) {
                errorMessage = `${t('mspCompare.minAllowedValue')} ${minValue}`
            }
    
            if(value > maxValue){
                errorMessage = `${t('mspCompare.maxAllowedValue')} ${maxValue}`
            }
            
            if(variant === 'min'){

                if(errorMessage){
                    setMinError(errorMessage)
                    return;
                }else{
                    setMinError("")
                }   
            }else{ 
                if(errorMessage){
                    setMaxError(errorMessage)
                    return;
                }else{
                    setMaxError("")
                }   
            } 
        }

        if(!value){
            if(variant === 'min'){
                setMinError("")
            }else{
                setMaxError("")
            }
        }
        
        const filterObj = {};
        filterObj[variant] = value;

        if(variant === 'min' && selectedFilterValues?.[filterName]?.['max']){
            filterObj['max'] = selectedFilterValues[filterName]['max']
        }

        if(variant === 'max' && selectedFilterValues?.[filterName]?.['min']){
            filterObj['min'] = selectedFilterValues[filterName]['min']
        }

        handleFilterValueChange(filterName, filterObj);
   }

   const onMinKeyPressHanlder = (e) => {
    if (isNaN(e.key)) {
        e.preventDefault();
    }
   }
   
   const onMaxKeyPressHanlder = (e) => {
    if (isNaN(e.key)) {
        e.preventDefault();
    }
   }
   
    return <MinMaxWrapper>
        <MinMaxTitle>{title}</MinMaxTitle>
        <MinMaxInputsGroup>
        <MinInputWrapper>
        <MinMaxSpanText>{t('mspCompare.min')}</MinMaxSpanText>
            <input placeholder={Math.round(minValue) || 0} type='text'
            onKeyPress={(e) => {
                onMinKeyPressHanlder(e)
            }}
            onChange={(e)=>{
                onChangeHandler(parseInt(e.target.value), 'min');
            }}/>
        </MinInputWrapper>

        <MaxInputWrapper>
        <MinMaxSpanText>{t('mspCompare.max')}</MinMaxSpanText>
            <input placeholder={Math.round(maxValue) || 0} type='text' 
            onKeyPress={(e) => {
                onMaxKeyPressHanlder(e)
            }}
            onChange={(e)=>{
                onChangeHandler(parseInt(e.target.value), 'max');
            }}/>
        </MaxInputWrapper>

        </MinMaxInputsGroup>
        <MinMaxError>{minError || maxError}</MinMaxError>
    </MinMaxWrapper>
}

MinMaxInput.displayName = 'MinMaxInputComponent'
export default MinMaxInput