import React from 'react'
import { Grid, Paper, ClickAwayListener, Grow, MenuItem, MenuList } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TitleGrid, Title, ButtonGrid, UploadHistoryBtn, TopButtonsGroup, BulkCreateBtn, CreateNewBtn, BulkCreateBtnText, CreateNewBtnText, BulkCreatePopper } from './style'

const TitleRow = ({ totalCatalouge }) => {
  const { t } = useTranslation()
  const [openBulkOptions, setOpenBulkOptions] = React.useState(false)
  const anchorRef = React.useRef(null)

  const bulkOptionsHandleToggle = () => {
    setOpenBulkOptions((prevOpen) => !prevOpen)
  }

  const bulkOptionsHandleClose = (event) => {
    if (
      anchorRef.current &&
        anchorRef.current.contains(event.target)
    ) {
      return
    }
    setOpenBulkOptions(false)
  }

  const prevOpen = React.useRef(openBulkOptions)
  React.useEffect(() => {
    prevOpen.current = openBulkOptions
  }, [openBulkOptions])

  function handleListKeyDown (event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenBulkOptions(false)
    } else if (event.key === 'Escape') {
      setOpenBulkOptions(false)
    }
  }

  return (
    <TitleGrid container>
      <Grid item md={6} sm={12} xs={12}>
        <Title>{t('brandCatalouge.sku')} ({totalCatalouge})</Title>
      </Grid>
      {false &&
        <TopButtonsGroup item md={6} sm={12} xs={12}>
          <Grid container direction='row-reverse'>
            <ButtonGrid item md={4} sm={12} xs={12}>
              <CreateNewBtn>
                <CreateNewBtnText>
                  + Create New
                </CreateNewBtnText>
              </CreateNewBtn>
            </ButtonGrid>
            <ButtonGrid item md={4} sm={12} xs={12}>
              <BulkCreateBtn
                onClick={bulkOptionsHandleToggle}
                ref={anchorRef}
              >
                <BulkCreateBtnText>
                  Bulk Create
                </BulkCreateBtnText>
                <BulkCreatePopper
                  open={openBulkOptions}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement='bottom-start'
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom'
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={bulkOptionsHandleClose}>
                          <MenuList
                            id='composition-menu'
                            aria-labelledby='composition-button'
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem onClick={bulkOptionsHandleClose}>Upload</MenuItem>
                            <MenuItem onClick={bulkOptionsHandleClose}>Download Template</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </BulkCreatePopper>
              </BulkCreateBtn>
            </ButtonGrid>
            <ButtonGrid item sm={12} xs={12} md={4}>
              <UploadHistoryBtn>
                Upload History
              </UploadHistoryBtn>
            </ButtonGrid>
          </Grid>
        </TopButtonsGroup>}
    </TitleGrid>
  )
}

TitleRow.displayName = 'BrandCatalougeTitleRowComponent'
export default TitleRow
