import { useTranslation } from 'react-i18next'

import {
  LogisticsWrapper,
  HeaderWrapper,
  ContentWrapper,
  ContentHeading,
  ContentTextWrapper,
  SectionWrapper,
  ArrangePickup
} from './style'

const LogisticDetails = ({
  threePLPartner,
  url,
  showShip,
  showCancel,
  shippingType,
  onClickShip,
  onClickCancel,
  awbNumber
}) => {
  const { t } = useTranslation()
  const getURLExtension = url => {
    return url.split(/[#?]/)[0].split('.').pop().trim()
  }

  return (
    <LogisticsWrapper>
      <HeaderWrapper>
        {t('orderDetails.logisticDetails')}
      </HeaderWrapper>
      <ContentWrapper>
        <SectionWrapper>
          <ContentHeading>
            {t('orderDetails.awbNumber')}
          </ContentHeading>
          <ContentTextWrapper>
            {awbNumber}
          </ContentTextWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <ContentHeading>
            {t('orderDetails.shippingType')}
          </ContentHeading>
          <ContentTextWrapper>
            {shippingType}
          </ContentTextWrapper>
        </SectionWrapper>
        <div>
          <ArrangePickup
            show={showCancel}
            onClick={onClickCancel}
          >
            {t('orderDetails.cancelOrder')}
          </ArrangePickup>
        </div>
        <SectionWrapper>
          <ContentHeading>
            {t('orderDetails.threePLPartner')}
          </ContentHeading>
          <ContentTextWrapper>
            {threePLPartner}
          </ContentTextWrapper>
        </SectionWrapper>
        <SectionWrapper>
          {url &&
            <>
              <ContentHeading>
                {t('orderDetails.shippingLabel')}
              </ContentHeading>
              <ContentTextWrapper>
                <a href={url} target='_blank' rel='noreferrer'>Shoping_ey.{getURLExtension(url)}</a>
              </ContentTextWrapper>
            </>}
        </SectionWrapper>
        <div>
          <ArrangePickup
            show={showShip}
            onClick={onClickShip}
          >
            {t('orderDetails.shipOrder')}
          </ArrangePickup>
        </div>
      </ContentWrapper>
    </LogisticsWrapper>
  )
}

export default LogisticDetails
