// Todo: Revisit this toJS() logic
// and check do we need to store content as immutable list?
export const getConnectedStores = (state) => {
  return state.stores.get('content').toJS().filter(
    store => store?.is_connected
  )
}

export const getDisconnectedStores = (state) => {
  return state.stores.get('content').toJS().filter(
    store => !store?.is_connected
  )
}
