import styled from 'styled-components'

export const Button = styled.button`
  border-radius: 4px;
  color: #FFFFFF;
  min-width: 160px;
  max-width: fit-content;
  height: 40px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  border: none;
  cursor: pointer;
  ${props => props.variant === 'primary' && 'color: #FFFFFF;background: #162E9A;'}
  ${props => props.variant === 'secondary' && 'color: #000000;background: #FFFFFF;border: 1px solid #E4E4E5;'}
  ${props => props.disabled && 'background: grey;'}
  ${props => props.variant === 'producthomeCatelog' && 'background-color: #162E9A;color:#FFFFFF;font-size: 14px;margin: 20px;padding: 0px 20px;font-weight: 500;'}
  ${props => props.variant === 'producthomeAddnewProduct' && 'background-color:#FFFFFF;color: #1D2334;font-size: 14px;margin: 20px;padding: 0px 20px;box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.350579);font-weight: 500;'}
`
