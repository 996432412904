import { styled } from '@mui/system'
import { Box } from '@mui/material'
import { Link as RouteLink } from 'react-router-dom'

export const LayoutContainer = styled(Box)(`
  width: 100%;
  min-height:calc(100vh - 50px);
  border-bottom: 10px #212121 solid;
`)

export const HeaderWrapper = styled(Box)(`
  background: #1D2334;
  height: 70px;
  display: flex;
  align-items: center;
`)

export const Link = styled(RouteLink)(`
  text-decoration:none;
`)
