import { call, put, takeLatest } from 'redux-saga/effects'

import {
  UPLOADIMAGES_LOADING,
  UPLOADIMAGES_POST,
  UPLOADIMAGES_POST_SUCCESS,
  UPLOADIMAGES_POST_FAILURE
} from '../actions'

import {
  // API_POST_IMAGES,
  API_UPLOAD_S3_URL
} from '../constants'
import { postApi, putApi } from '../utilities/axios'

// function * onPostUploadImages (action) {
//   yield put({ type: UPLOADIMAGES_LOADING, isLoading: true })
//   try {
//     const response = yield call(postApi, API_POST_IMAGES, action.payload, { isLegacyApiHost: true })
//     yield put({ type: UPLOADIMAGES_POST_SUCCESS, data: response?.data?.data || {} })
//   } catch (e) {
//     yield put({ type: UPLOADIMAGES_POST_FAILURE, message: e.message })
//     // This is temporary as BE is returning 404 error
//     yield put({ type: UPLOADIMAGES_POST_SUCCESS, data: {} })
//   }
//   yield put({ type: UPLOADIMAGES_LOADING, isLoading: false })
// }

function * getUploadS3Url (action) {
  // yield put({ type: UPLOADIMAGES_LOADING, uploadImagesIsLoading: true })
  yield put({ type: UPLOADIMAGES_LOADING, uploadImagesIsLoading: { value: true, mode: action.payload.type } })
  try {
    const response = yield call(postApi, API_UPLOAD_S3_URL, action.payload[action.payload.type], { isLegacyApiHost: true })
    const { url = '', key = '' } = response?.data?.data
    yield call(putApi, url, action.payload[action.payload.type].file, {
      customHeaders: {
        'Content-Type': action.payload[action.payload.type].ContentType,
        imageKey: key
      },
      token: ''
    })
    const responseUrl = `${response?.data?.data.url?.split(key)[0]}${key}`
    yield put({ type: UPLOADIMAGES_POST_SUCCESS, data: { [action.payload.type]: { ...response?.data?.data, url: responseUrl } || {} } })
  } catch (e) {
    yield put({ type: UPLOADIMAGES_POST_FAILURE, message: e.message })
    // This is temporary as BE is returning 404 error
    yield put({ type: UPLOADIMAGES_POST_SUCCESS, data: {} })
  }
  // yield put({ type: UPLOADIMAGES_LOADING, uploadImagesIsLoading: false })
  yield put({ type: UPLOADIMAGES_LOADING, uploadImagesIsLoading: { value: false, mode: action.payload.type } })
}

function * UPLOADIMAGESSaga () {
  // yield takeLatest(UPLOADIMAGES_POST, onPostUploadImages)
  yield takeLatest(UPLOADIMAGES_POST, getUploadS3Url)
}

export default UPLOADIMAGESSaga
