import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import MuiButton from '../MuiButton'

const MuiAlertDialog = (props) => {
  const {
    open = false,
    title,
    description,
    onCancelled,
    onConfirmed
  } = props

  return (
    <Dialog
      open={open}
      onClose={onCancelled}
      style={{ minWidth: '250px' }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {description && <DialogContent><DialogContentText>{description}</DialogContentText></DialogContent>}
      <DialogActions>
        <MuiButton size='small' color='primary' variant='outlined' minwidth='true' onClick={onCancelled}>Cancel</MuiButton>
        <MuiButton size='small' styled='primary' variant='contained' minwidth='true' onClick={onConfirmed} autoFocus>Delete</MuiButton>
      </DialogActions>
    </Dialog>
  )
}

export default MuiAlertDialog
