import React, { useEffect, useState, useRef } from 'react'
// import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
// import { useNavigate } from 'react-router-dom'
import Joi from 'joi'
import { serializeError } from 'serialize-error'
import { useTranslation } from 'react-i18next'

import AuthLayout from '../AuthLayout'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import Loading from '../LoadingSpinner'
// EventEmitter.emit(Events.SAAS_LOGIN, { email })

import {
  Button,
  Form,
  Input,
  BottomLinkHref,
  BottomLinkWrapper,
  Title,
  AuthParent,
  LoginImageWrapper,
  LoginImage,
  LeftSideContainer,
  ImageWrapperText
} from '../AuthLayout/style'

import { ROUTE_LOGIN } from '../../constants'
import LoginImageLeft from '../../assets/images/login.svg'

const ForgotPassword = props => {
  const {
    error,
    onPostForgotPassword,
    isLoadingForgotPassword,
    forgotPasswordSuccess
  } = props
  const [email, setEmail] = useState('')
  const [isScroll, setIsScroll] = useState(false)
  const imageRef = useRef()
  // const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    const height = imageRef.current.offsetHeight + 20
    setIsScroll(height > document.body.offsetHeight)
  }, [imageRef])

  // useEffect(() => {
  //   onMount()
  // }, [onMount])

  useEffect(() => {
    if (error) toast.error(error)
  }, [error])

  useEffect(() => {
    if (forgotPasswordSuccess === 200) toast.success('Successfully sent the email')
  }, [forgotPasswordSuccess])

  // useEffect(() => {
  //   // if (isLoggedIn) {
  //   //   navigate(ROUTE_CONNECTIONS)
  //   // }
  // }, [isLoggedIn, navigate])

  const renderFormTitle = () => <Title>Forgot Password</Title>

  const loginFormInput = Joi.object({
    email: Joi.string().pattern(/\S+@\S+\.\S+/).message({
      'string.pattern.base': t('auth.invalidEmail')
    })
  })

  const handleFormSubmit = async e => {
    e.preventDefault()
    EventEmitter.emit(Events.SAAS_LOGIN, { email })

    const data = { email }
    const response = await loginFormInput.validate(data)
    const { message: errorMessage } = serializeError(response?.error || {})

    if (errorMessage) {
      return toast.error(errorMessage)
    }
    onPostForgotPassword(data)
  }

  const renderForm = () => (
    <Form onSubmit={handleFormSubmit}>
      <Input
        id='email'
        name='email'
        onChange={e => setEmail(e.target.value)}
        placeholder={t('auth.email')}
        type='email'
        value={email}
      />
      <Button type='submit'>
        {isLoadingForgotPassword ? <Loading /> : 'CONFIRM EMAIL'}
      </Button>
    </Form>
  )

  const renderBottomLink = () => (
    <BottomLinkWrapper>
      {t('auth.loginToYourAccount')}?
      <BottomLinkHref to={ROUTE_LOGIN}>
        {t('auth.signIn')}
      </BottomLinkHref>
    </BottomLinkWrapper>
  )

  return (
    <AuthParent>
      <LeftSideContainer isScroll={isScroll} ref={imageRef}>
        <LoginImageWrapper>
          <LoginImage src={LoginImageLeft} />
        </LoginImageWrapper>
        <ImageWrapperText>{t('auth.imageText')}!</ImageWrapperText>
      </LeftSideContainer>
      <AuthLayout>
        {renderFormTitle()}
        {renderForm()}
        {renderBottomLink()}
      </AuthLayout>
    </AuthParent>
  )
}

// ForgotPassword.propTypes = {
//   error: PropTypes.string,
//   onMount: PropTypes.func,
//   onLogin: PropTypes.func,
//   isLoggedIn: PropTypes.bool
// }

ForgotPassword.displayName = 'ForgotPasswordComponent'
export default ForgotPassword
