import React from 'react'
import { connect } from 'react-redux'
import Layout from '../Layout'
import PageBuilderRaenaProductsComponent from '../../components/PageBuilderRaenaProducts'
import {
  PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST,
  PAGEBUILDER_RAENAPRODUCTS_UPDATE,
  PAGEBUILDER_RAENAPRODUCTS_DELETE,
  USERS_GET_INFO
} from '../../actions'

const PageBuilderRaenaProducts = props => {
  return (
    <Layout>
      <PageBuilderRaenaProductsComponent {...props} />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { pageBuilderRaenaProducts } = state
  return {
    pageBuilderRaenaProductsResellerList: pageBuilderRaenaProducts.get('pageBuilderRaenaProductsResellerList'),
    pageBuilderRaenaProductsResellerIsLoading: pageBuilderRaenaProducts.get('pageBuilderRaenaProductsResellerIsLoading'),

    pageBuilderRaenaProductsUpdateData: pageBuilderRaenaProducts.get('pageBuilderRaenaProductsUpdateData'),
    pageBuilderRaenaProductsUpdateIsLoading: pageBuilderRaenaProducts.get('pageBuilderRaenaProductsUpdateIsLoading'),

    pageBuilderRaenaProductsDeleteData: pageBuilderRaenaProducts.get('pageBuilderRaenaProductsDeleteData'),
    pageBuilderRaenaProductsDeleteIsLoading: pageBuilderRaenaProducts.get('pageBuilderRaenaProductsDeleteIsLoading')
  }
}

const mapDispatchToProps = dispatch => ({
  onGetPageBuilderRaenaProductResellerList: (query) => {
    dispatch({ type: PAGEBUILDER_RAENAPRODUCTS_RESELLER_LIST, payload: query })
  },
  onGetPageBuilderRaenaProductsUpdateData: (query) => {
    dispatch({ type: PAGEBUILDER_RAENAPRODUCTS_UPDATE, payload: query })
  },
  onGetPageBuilderRaenaProductsDeleteData: (query) => {
    dispatch({ type: PAGEBUILDER_RAENAPRODUCTS_DELETE, payload: query })
  },
  onGetUserInfo: () => {
    dispatch({ type: USERS_GET_INFO })
  }
})

PageBuilderRaenaProducts.displayName = 'ProductListContainer'
export default connect(mapStateToProps, mapDispatchToProps)(PageBuilderRaenaProducts)
