import React from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Line } from 'react-chartjs-2'

import hamburgerIcon from '../../../assets/images/hamburgerhorizontal.svg'
import growthIcon from '../../../assets/images/growth.svg'
import declineIcon from '../../../assets/images/decline.svg'

import {
  GraphWrapper,
  SummaryGraphh,
  HeadingWrapper,
  Heading,
  HamburgerIcon,
  GrowthWrapper,
  GrowthText
} from './style'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

function commarize (value) {
  if (value < 1000) return value

  let newValue = value
  const suffixes = ['', 'K', 'M', 'B', 'T']
  let suffixNum = 0
  while (newValue >= 1000) {
    newValue /= 1000
    suffixNum++
  }

  newValue = newValue.toPrecision(3)

  newValue += suffixes[suffixNum]
  return newValue
}

const Graph = ({
  growthOrders = '+32',
  growthSales = '+40',
  growthProfits = '-20',
  labelsOrders = ['Jan', 'Feb', 'Mar', 'Apr'],
  labelsSales = ['Jan', 'Feb', 'Mar', 'Apr'],
  labelsProfits = ['Jan', 'Feb', 'Mar', 'Apr'],
  dataOrders = [9, 10, 7, 3],
  dataSales = [9, 10, 7, 3],
  dataProfits = [9, 10, 7, 3]
}) => {
  const options = {
    responsive: true,
    layout: { padding: { top: 15 } },
    plugins: {
      datalabels: {
        align: 'top',
        display: true,
        formatter: function (value, context) {
          return commarize(value)
        }
      },
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          display: false,
          callback: function (value, index, values) {
            return commarize(value)
          }
        },
        grid: {
          drawBorder: false,
          display: false
        }
      }
    }
  }

  const dataValues = (labels, data) => {
    return {
      labels,
      datasets: [
        {
          fill: true,
          lineTension: 0.3,
          label: '',
          data,
          borderColor: '#162E9A',
          pointBorderColor: '#577FDC',
          pointBackgroundColor: '#fff',
          pointHoverBackgroundColor: '#577FDC',
          pointHoverBorderColor: '#fff',
          backgroundColor: (context) => {
            const ctx = context.chart.ctx
            const gradient = ctx.createLinearGradient(0, 0, 0, 200)
            gradient.addColorStop(0, 'RGBA(87,127,220,0.4)')
            gradient.addColorStop(1, 'RGBA(87,127,220,0.0)')
            return gradient
          }
        }
      ]
    }
  }

  const SummaryGraph = ({ heading, growth, data }) => {
    const isGrowthPositive = growth >= 0 || growth === 'NA'
    const growthImg = isGrowthPositive ? growthIcon : declineIcon
    const growthText = isGrowthPositive ? 'Growth' : 'Decline'

    return (
      <SummaryGraphh>
        <HeadingWrapper>
          <Heading>
            {heading}
          </Heading>
          {/* <HamburgerIcon src={hamburgerIcon} /> */}
        </HeadingWrapper>
        <GrowthWrapper green={isGrowthPositive}>
          <img src={growthImg} alt='' />
          <GrowthText>
            {growth}{ growth !== 'NA' && '%'} {growthText}
          </GrowthText>
        </GrowthWrapper>
        <Line options={options} plugins={[ChartDataLabels]} data={data} />
      </SummaryGraphh>
    )
  }

  return (
    <GraphWrapper>
      <SummaryGraph
        heading='Sales'
        growth={growthSales}
        data={dataValues(labelsSales, dataSales)}
      />
      <SummaryGraph
        heading='Orders'
        growth={growthOrders}
        data={dataValues(labelsOrders, dataOrders)}
      />
      <SummaryGraph
        heading='Profits'
        growth={growthProfits}
        data={dataValues(labelsProfits, dataProfits)}
      />
    </GraphWrapper>
  )
}

export default Graph
