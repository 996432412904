import { connect } from 'react-redux'
import MuiFileUpload from '../../components/MuiFileUpload'

import {
  UPLOADIMAGES_POST,
  UPLOADIMAGES_CLEAR
} from '../../actions'

const UploadImages = props => {
  return (
    <MuiFileUpload {...props} uploadImages={props.uploadImages[props.mode]} />
  )
}

const mapStateToProps = (state) => {
  const {
    uploadImages
  } = state
  return {
    uploadImagesError: uploadImages.get('uploadImagesError'),
    uploadImagesIsLoading: uploadImages.get('uploadImagesIsLoading'),
    uploadImages: uploadImages.get('uploadImagecontent')
  }
}

const mapDispatchToProps = dispatch => ({
  onPostUploadImages: (query) => {
    dispatch({ type: UPLOADIMAGES_POST, payload: query })
  },
  clearUploadedImage: () => {
    dispatch({ type: UPLOADIMAGES_CLEAR })
  }
})

UploadImages.displayName = 'UploadImagesContainer'
export default connect(mapStateToProps, mapDispatchToProps)(UploadImages)
