import React from 'react'
import BackIcon from '../../assets/images/icons/back-icon.svg'
import RulesImage from '../../assets/images/icons/rules-image.svg'
import './styles.css'
import { useTranslation } from 'react-i18next'

export const WithdrawlRules = ({ goBack }) => {
  const { t } = useTranslation()
  const onClickToEmail = () => window.open('mailto:cs@raenabeauty.com')

  return (
    <div className='withdrawMainBox'>
      <div className='headerContainer'>
        <img onClick={goBack} className='backImage' src={BackIcon} alt='back' />
        <p className='withdrawHeading'>{t('pageBuilderAbout.Withdraw')}</p>
      </div>

      <div className='earningsRuleBox'>
        <div className='ruleContainer'>
          <img src={RulesImage} alt='wallet' />
          <div className='rulesContentBox'>
            <p className='ruleContentheading'>{t('pageBuilderAbout.WithdrawRules')}</p>
            <ul className='listContainer'>
              <li className='listItemOfRule'>{t('pageBuilderAbout.WithdrawRuleOne')}</li>
              <li className='listItemOfRule'>
                {t('pageBuilderAbout.WithdrawRuleTwo')}
                <span onClick={onClickToEmail} className='emailStyle'>cs@raenabeauty.com</span>
                {t('pageBuilderAbout.WithdrawRuleTwoExtend')}
              </li>
            </ul>
          </div>
        </div>

        <div className='earningContentContainer'>
          <p className='ruleContentheading'>{t('pageBuilderAbout.earningRules')}</p>
          <ul className='listContainer'>
            <li className='listItemOfRule'>{t('pageBuilderAbout.EarningRuleOne')}</li>
            <li className='listItemOfRule'>{t('pageBuilderAbout.EarningRuleTwo')}</li>
            <li className='listItemOfRule'>{t('pageBuilderAbout.EarningRuleThree')}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
