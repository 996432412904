export const ROUTE_HOMEPAGE = '/'
export const ROUTE_LOGIN = '/login'
export const ROUTE_SIGNUP = '/signup'
export const ROUTE_DASHBOARD = '/dashboard'
export const ROUTE_FORGOT_PASSWORD = '/forgot-password'
export const ROUTE_RESET_PASSWORD = '/reset-password'
export const ROUTE_CONNECTIONS = '/connections'
export const ROUTE_ANALYTICS = '/analytics'
export const ROUTE_ORDERS = '/orders'
export const ROUTE_PRODUCTS_HOME = '/products/home'
export const ROUTE_PRODUCTS = '/products'
export const ROUTE_PRODUCTS_CREATE = '/products/create'
export const ROUTE_PRODUCTS_EDIT = '/products/edit/:productId'
export const ROUTE_COMING_SOON = '/coming-soon'
export const ROUTE_E_PRODUCT = '/products/edit/'
export const ROUTE_BRANDS_DASHBOARD = '/brands/dashboard/'
export const ROUTE_BRANDS_CATALOUGE = '/brands/catalogue/'
export const ROUTE_BRANDS_PROMO = '/brands/promo/'
export const MSP_COMPARE = '/brands/msp-compare/'
export const MSP_DETAILS = '/brands/msp-details/:skuId'
export const ROUTE_LINKSTORE = '/linkstore'
export const ROUTE_LINKSTORE_RAENAPRODUCTS = '/linkstore/raena-products'
export const ROUTE_LINKSTORE_RAENAPRODUCTS_ADD = '/linkstore/raena-products/add'
export const ROUTE_PAGE_BUILDER = '/pagebuilder'
export const ROUTE_CREATE_PROMO = '/create/promo'
