import { useState, useRef, useEffect } from 'react'
import { useFormik } from 'formik'
// import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { ROUTE_PRODUCTS } from '../../constants'
import MuiRadio from '../MuiRadio'
import MenuField from '../MenuField'
import MuiTextField from '../MuiTextField'
import MuiSwitch from '../MuiSwitch'
import UploadImages from '../../containers/UploadImages'
import MuiButton from '../MuiButton'
import { Box } from '@mui/material'
import { getUrlStringInObject } from '../../utilities/app'
import EventEmitter, { Events } from '../../utilities/eventEmitter'

import {
  ProductWrapper,
  Title,
  FormControlCol1,
  FormControlCol2,
  FormControlCol3,
  FormControlInput,
  FormControl,
  SectionTitle,
  SectionContent,
  SubmitWrapper,
  FormControlSwitchWrap,
  InputAdornments,
  BackButton
} from './style'

const ProdCreate = (props) => {
  const {
    categoryList,
    onGetCategoryList,
    onGetProductCreateData,
    onGetStoreList = () => {},
    productEditData,
    onGetProductUpdateData,
    submitType,
    setSubmitType,
    // onProductFormUnmount = () => {},
    storeList = [],
    mode,
    setModalStatus
  } = props
  const { t } = useTranslation()
  const form = useRef()
  // const navigate = useNavigate()
  const [imageIdList, setImageIdList] = useState([])
  const [validateOnBlur, setValidateOnBlur] = useState(false)
  const [uploadImageArray, setUploadImageArray] = useState(productEditData?.images || [])
  const [weightUnit, setWeightUnit] = useState(productEditData?.shipping?.weight.unit || 'gm')

  const isStorePresent = !!storeList.length

  useEffect(() => {
    onGetStoreList()
    // return () => onProductFormUnmount()
    // ^ Commenting this line as product submitting was not working
  }, [])

  const handleProductConditionChange = (event) => {
    formik.setValues({ ...formik.values, ...{ condition: event.target.value } })
  }

  const handleIsMustInsuranceChange = (event) => {
    formik.setValues({ ...formik.values, ...{ shipping_isMustInsurance: event.target.value === 'Mandatory' } })
  }

  const handleFeeTypeChange = (event) => {
    const val = (event.target.value === 'Regular') ? 'Fixed' : 'Variable'
    formik.setValues({ ...formik.values, ...{ fee_type: val } })
  }
  const handleCoverShippingFeeChange = (value) => {
    formik.setValues({ ...formik.values, ...{ fee_shippingCovered: value } })
  }
  const handlePreOrderChange = (value) => {
    formik.setValues({ ...formik.values, ...{ others_preOrder: value } })
  }

  const handleMenuFieldClosed = (allBrandId, categoryID, categoryObject) => {
    formik.setValues({ ...formik.values, ...{ categoryId: categoryID } })
    // setCategorySelectedObject(categoryObject)
  }

  const handleImageChange = (data) => {
    setImageIdList(data)
    setUploadImageArray(data)
    // const imageList = data?.map(i => { return { key: i.key, priority: 1 } }) || []
    // formik.setValues({ ...formik.values, ...{ images: imageList } })
    formik.setFieldError('images', '')
  }

  const validation = (values) => {
    setValidateOnBlur(true)
    const errors = {}
    // if (values.images.length < 1) { errors.images = 'Minimum 1 Image should be added' }
    // if (values.images.length === 0) { errors.images = 'Image is required' }
    if (uploadImageArray.length < 1) { errors.images = 'Minimum 1 Image should be added' }
    return errors
  }

  let formikInitialValues = {
    categoryId: productEditData?.categoryId || '',
    name: productEditData?.name || '',
    shortDescription: productEditData?.shortDescription || '',
    description: productEditData?.description || '',
    images: productEditData?.images || [],
    condition: productEditData?.condition || 'New',
    type: 'New',
    productSource: productEditData?.productSource || '',
    fullFillVia: productEditData?.fullFillVia || '',
    pricing_minOrderQty: productEditData?.pricing?.minOrderQty || '',
    pricing_maxOrderQty: productEditData?.pricing?.maxOrderQty || '',
    // pricing_maxQuanityRule: productEditData?.pricing?.maxQuanityRule || '',
    pricing_price: productEditData?.pricing?.price || '',
    pricing_currency: productEditData?.pricing?.currency || '',
    stock_available: productEditData?.stock?.available || '',
    details_countryId: productEditData?.details?.countryId || '',
    details_composition: productEditData?.details?.composition || '',
    details_skinType: productEditData?.details?.skinType || '',
    // details_sku: productEditData?.details?.sku || '',
    details_raenaSku: productEditData?.details?.raenaSku || '',
    // details_expirationDate: productEditData?.details?.expirationDate || '',
    weight_value: productEditData?.shipping?.weight?.value || '',
    weight_unit: productEditData?.shipping?.weight?.unit || 'kg',
    dimension_length: productEditData?.shipping?.dimension?.length || '',
    dimension_width: productEditData?.shipping?.dimension?.width || '',
    dimension_height: productEditData?.shipping?.dimension?.height || '',
    fee_type: productEditData?.shipping?.fee?.type || 'Fixed',
    fee_shippingCovered: productEditData?.shipping?.fee?.shippingCovered || false,
    shipping_isMustInsurance: productEditData?.shipping?.isMustInsurance || false,
    sku: productEditData?.sku || '',
    wholesale: {
      minOrderQty: 0,
      maxOrderQty: 0,
      price: 0,
      currency: 'INR'
    },
    others_preOrder: productEditData?.others?.preOrder || false,
    others_days: productEditData?.others?.days || ''
  }

  if (getUrlStringInObject('autofill')) {
    formikInitialValues = {
      categoryId: 0,
      name: `Product - ${getUrlStringInObject('autofill')}`,
      shortDescription: `Feature of ${getUrlStringInObject('autofill')} : Easy twist up format, It is dermatologically tested and is safe to use, Deep black finish, twist- up format for deep strokes`,
      description: `Feature of ${getUrlStringInObject('autofill')} : Easy twist up format, It is dermatologically tested and is safe to use, Deep black finish, twist- up format for deep strokes`,
      images: [],
      condition: 'New',
      brand: {
        id: 1,
        name: 'Test Brand'
      },
      type: 'New',
      productSource: 'Merchant',
      fullFillVia: 'OwnInventory',
      pricing_minOrderQty: 2,
      pricing_maxOrderQty: getUrlStringInObject('autofill').length * 2,
      pricing_maxQuanityRule: getUrlStringInObject('autofill').length * 3 - 2,
      pricing_price: getUrlStringInObject('autofill').length * 12,
      pricing_currency: 'INR',
      stock_available: getUrlStringInObject('autofill').length * 3,
      details_countryId: 1,
      details_composition: 'Test composition',
      details_skinType: '',
      sku: `TST-${getUrlStringInObject('autofill')}-${getUrlStringInObject('autofill').length}`,
      details_raenaSku: '',
      // details_expirationDate: '2022-05-13',
      weight_value: 12,
      weight_unit: 'kg',
      dimension_length: `1.${getUrlStringInObject('autofill').length - 2}`,
      dimension_width: `2.${getUrlStringInObject('autofill').length - 2}`,
      dimension_height: `4.${getUrlStringInObject('autofill').length - 2}`,
      fee_type: 'Fixed',
      fee_shippingCovered: false,
      shipping_isMustInsurance: false,
      wholesale: {
        minOrderQty: 0,
        maxOrderQty: 0,
        price: 100,
        currency: 'INR'
      },
      others_preOrder: false,
      others_days: 1
    }
  }

  const formik = useFormik({
    validateOnChange: validateOnBlur,
    validateOnBlur: validateOnBlur,
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: Yup.object({
      categoryId: Yup.number('').required('Category is required'),
      name: Yup.string().required('Name is required'),
      type: Yup.string().required('Type is required'),
      // images: Yup.array().min(2, 'Min 3 images').required('Images is required'), // .transform((value, originalValue) => { if (originalValue.length < 3){console.log('originalValue.len =', originalValue.length)} }),
      condition: Yup.string().required('Condition is required'),
      description: Yup.string().required('Description is required').min(20, 'Please add 20 characters'),
      pricing_minOrderQty: Yup.number().required('Min Order Qty is required'),
      pricing_maxOrderQty: Yup.number().required('Max Order Qty is required'),
      pricing_price: Yup.number().required('Price is required'),
      details_storageTime: Yup.number().min(1, 'The minimum time is one').typeError('The amount invalid'),
      // // // // pricing_currency: Yup.number().required('Currency is required'),  // This is showing ERROR
      sku: Yup.string().required('SKU is required'), // Need to recheck on this
      stock_available: Yup.number().required('Stock is required'),
      weight_value: Yup.number().required('Weight is required'), // This is showing Internal ERROR in EDIT mode
      dimension_length: Yup.number().required('Length is required'),
      dimension_height: Yup.number().required('Height is required'),
      dimension_width: Yup.number().required('Width is required'),
      fee_type: Yup.string().required('Fee Type is required')
    }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = values
      const objectData = {
        categoryId: updatedValues.categoryId,
        raenaProductId: updatedValues.categoryId,
        name: updatedValues.name,
        type: updatedValues.type,
        shortDescription: updatedValues.shortDescription,
        description: updatedValues.description,
        // images: updatedValues.images,
        images: imageIdList?.map(i => { return { key: i.key, priority: 1 } }) || [],
        condition: updatedValues.condition,
        // brand: updatedValues.brand,
        // typeoriginal_attribute_name: 'New',
        productSource: 'Merchant',
        fullFillVia: 'OwnInventory',
        variant: [],
        pricing: {
          minOrderQty: updatedValues.pricing_minOrderQty,
          maxOrderQty: updatedValues.pricing_maxOrderQty,
          // maxQuanityRule: updatedValues.pricing_maxQuanityRule,
          price: updatedValues.pricing_price,
          currency: updatedValues.pricing_currency || 'INR'
        },
        stock: {
          available: updatedValues.stock_available
        },
        details: {
          countryId: 1,
          composition: 'Test composition',
          skinType: ''
          // sku: updatedValues.details_sku,
          // raenaSku: '', //string //optional
          // expirationDate: '2022-05-13'
        },
        sku: productEditData?.sku || updatedValues.sku,
        shipping: {
          weight: {
            value: updatedValues.weight_value,
            unit: weightUnit
          },
          dimension: {
            length: updatedValues.dimension_length,
            width: updatedValues.dimension_width,
            height: updatedValues.dimension_height
          },
          fee: {
            type: updatedValues.fee_type,
            shippingCovered: updatedValues.fee_shippingCovered
          },
          isMustInsurance: updatedValues.shipping_isMustInsurance
        },
        wholesale: {
          minOrderQty: 10,
          maxOrderQty: 0,
          price: 100,
          currency: 'INR'
        },
        others: {
          preOrder: updatedValues.others_preOrder,
          days: updatedValues.others_days
        },
        submitType: submitType
      }
      if (mode === 'create') {
        setModalStatus(true)
        onGetProductCreateData(objectData) // CREATE
        // if (submitType === 'submit') onGetStoreList()
      } else if (mode === 'edit') {
        EventEmitter.emit(
          Events.PRODUCT_EDIT_LISTING, {
            product_id: productEditData?.rsellViewId
          }
        )

        delete objectData.raenaCategoryId
        delete objectData.raenaProductId
        delete objectData.productSource
        delete objectData.variant

        if (submitType === 'draft') {
          delete objectData.status
          delete objectData.slug
        }
        objectData.brands = productEditData?.brands
        onGetProductUpdateData({ rsellViewId: productEditData?.rsellViewId, data: objectData }) // UPDATE
        // navigate(`${ROUTE_PRODUCTS}?q=${productEditData.name}`)
      }
    }
  })

  const saveAsDraft = () => {
    setSubmitType('draft')
    formik.handleSubmit()
  }

  useEffect(() => {
    setImageIdList(productEditData?.images || [])
    setUploadImageArray(productEditData?.images || [])
  }, [productEditData?.images])

  return (
    <form onSubmit={formik.handleSubmit} ref={form} autocomplete='off'>
      <ProductWrapper>
        <SectionContent>
          <FormControl>
            <Title>{mode === 'create' ? t('addProduct.addProduct') : t('addProduct.editProduct')}</Title>
          </FormControl>
        </SectionContent>
        <SectionContent>
          <FormControl>
            <MenuField
              name='categoryId'
              label={t('addProduct.category')}
              error={formik.errors.categoryId}
              // placeholder={productEditData?.categoryPath || 'Select'}
              placeholder='Select'
              setCategorySelectedValue={s => console.log('-')}
              categoryList={categoryList}
              onGetCategoryList={onGetCategoryList}
              onClose={handleMenuFieldClosed}
              editObjectArray={productEditData?.categoryPath?.reverse().map(e => {
                e.masterCategoryId = e.parentCategoryId
                e.hasChildren = e.hasChild
                return e
                // Temp Hack
              }) || []}
              disabled={productEditData?.categoryPath}
              isRequired
            />
          </FormControl>
        </SectionContent>
      </ProductWrapper>

      <ProductWrapper>
        <SectionContent>
          <FormControl>
            <SectionTitle>{t('addProduct.basicInfo')}</SectionTitle>
          </FormControl>
        </SectionContent>
        <SectionContent>

          <FormControlCol2>
            <MuiTextField
              title={t('addProduct.productName')}
              error={Boolean(
                formik.touched.name && formik.errors.name
              )}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
              margin='normal'
              name='name'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.name}
              variant='outlined'
              size='small'
              placeholder={t('addProduct.enterProductName')}
              multiline
              rows={4}
              InputProps={{
                maxLength: 2000,
                endAdornment: (<InputAdornments position='end'>{formik.values.name.length} - 2000</InputAdornments>)
              }}
              isRequired
            />
          </FormControlCol2>

          <FormControlCol2>
            <MuiTextField
              title={t('addProduct.productDescription')}
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              helperText={formik.touched.description && formik.errors.description}
              fullWidth
              margin='normal'
              name='description'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.description}
              variant='outlined'
              size='small'
              placeholder={t('addProduct.enterProductDescription')}
              multiline
              rows={4}
              InputProps={{
                maxLength: 2000,
                endAdornment: (<InputAdornments position='end'>{formik.values.description.length} - 2000</InputAdornments>)
              }}
              isRequired
            />
          </FormControlCol2>

          <FormControlCol1>
            {mode === 'create' &&
              <UploadImages
                title={t('addProduct.uploadImage')}
                description={t('addProduct.kindlyAddText')}
                format='image/jpg, image/jpeg'
                onImageChange={handleImageChange}
                error={formik.errors.images}
                // value={formikInitialValues.images || null}
                value={uploadImageArray || null}
                isRequired
                mode='createUploadImage'
                name='createUploadImage'
              />}
            {mode === 'edit' &&
              <UploadImages
                title={t('addProduct.uploadImage')}
                description={t('addProduct.kindlyAddText')}
                format='image/jpg, image/jpeg'
                onImageChange={handleImageChange}
                error={formik.errors.images}
                value={productEditData?.images || formikInitialValues.images || null}
                isRequired
                mode='editUploadImage'
                name='editUploadImage'
              />}
          </FormControlCol1>

          <FormControlCol2>
            <MuiRadio title={t('addProduct.productCondition')} value={formik.values.condition} error={formik.errors.condition} name='condition' options={[{ value: 'New', label: t('addProduct.new') }, { value: 'Used', label: t('addProduct.used') }]} onChange={handleProductConditionChange} />
          </FormControlCol2>

        </SectionContent>
      </ProductWrapper>

      <ProductWrapper>
        <SectionContent>
          <FormControl>
            <SectionTitle>{t('addProduct.pricing')}</SectionTitle>
          </FormControl>
        </SectionContent>

        <SectionContent>

          <FormControlCol3>
            <MuiTextField
              title={t('addProduct.minOrderQ')}
              error={Boolean(
                formik.touched.pricing_minOrderQty && formik.errors.pricing_minOrderQty
              )}
              helperText={formik.touched.pricing_minOrderQty && formik.errors.pricing_minOrderQty}
              fullWidth
              margin='normal'
              name='pricing_minOrderQty'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              dataType='number'
              type='text'
              value={formik.values.pricing_minOrderQty}
              variant='outlined'
              size='small'
              placeholder={t('addProduct.enterMinOrderQ')}
              InputProps={{
                maxLength: 10
              }}
              isRequired
            />
          </FormControlCol3>
          <FormControlCol3>
            <MuiTextField
              title={t('addProduct.maxOrderQ')}
              error={Boolean(
                formik.touched.pricing_maxOrderQty && formik.errors.pricing_maxOrderQty
              )}
              helperText={formik.touched.pricing_maxOrderQty && formik.errors.pricing_maxOrderQty}
              fullWidth
              margin='normal'
              name='pricing_maxOrderQty'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              dataType='number'
              type='text'
              value={formik.values.pricing_maxOrderQty}
              variant='outlined'
              size='small'
              placeholder={t('addProduct.enterMaxOrderQ')}
              InputProps={{
                maxLength: 10
              }}
              isRequired
            />
          </FormControlCol3>

          {/* <FormControlCol3>
            <MuiTextField
              title={t('addProduct.maxQuantityRule')}
              error={Boolean(
                formik.touched.pricing_maxQuanityRule && formik.errors.pricing_maxQuanityRule
              )}
              helperText={formik.touched.pricing_maxQuanityRule && formik.errors.pricing_maxQuanityRule}
              fullWidth
              margin='normal'
              name='pricing_maxQuanityRule'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              dataType='number'
              type='text'
              value={formik.values.pricing_maxQuanityRule}
              variant='outlined'
              size='small'
              placeholder={t('addProduct.enterMaxQuanityRule')}
            />
          </FormControlCol3> */}

          <FormControlCol3>
            <MuiTextField
              title={t('addProduct.stock')}
              error={Boolean(
                formik.touched.stock_available && formik.errors.stock_available
              )}
              helperText={formik.touched.stock_available && formik.errors.stock_available}
              fullWidth
              margin='normal'
              name='stock_available'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              dataType='number'
              type='text'
              value={formik.values.stock_available}
              variant='outlined'
              size='small'
              placeholder={t('addProduct.enterStockAvailable')}
              InputProps={{
                maxLength: 10
              }}
              isRequired
            />
          </FormControlCol3>
          <FormControlCol3>
            <MuiTextField
              title={t('addProduct.price')}
              error={Boolean(
                formik.touched.pricing_price && formik.errors.pricing_price
              )}
              helperText={formik.touched.pricing_price && formik.errors.pricing_price}
              fullWidth
              margin='normal'
              name='pricing_price'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              dataType='number'
              type='text'
              value={formik.values.pricing_price}
              variant='outlined'
              size='small'
              placeholder={t('addProduct.enterPrice')}
              InputProps={{
                maxLength: 10
              }}
              isRequired
            />
          </FormControlCol3>

        </SectionContent>
      </ProductWrapper>

      <ProductWrapper>
        <SectionContent>
          <FormControl>
            <SectionTitle>{t('addProduct.productSpecificDetails')}</SectionTitle>
          </FormControl>
        </SectionContent>

        <SectionContent>

          <FormControlCol3>
            <MuiTextField
              title='SKU ID'
              error={Boolean(
                formik.touched.sku && formik.errors.sku
              )}
              helperText={formik.touched.sku && formik.errors.sku}
              fullWidth
              margin='normal'
              name='sku'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.sku}
              variant='outlined'
              size='small'
              placeholder={`${t('addProduct.enter')} SKU`}
              disabled={productEditData?.sku}
              InputProps={{
                maxLength: 20
              }}
              isRequired
            />
          </FormControlCol3>

        </SectionContent>
      </ProductWrapper>

      {/* <ProductVariant /> */}

      <ProductWrapper>
        <SectionContent>
          <FormControl>
            <SectionTitle>{t('addProduct.shipping')}</SectionTitle>
          </FormControl>
        </SectionContent>

        <SectionContent>

          <FormControlCol3>
            <MuiTextField
              title={t('addProduct.parcelWeight')}
              error={Boolean(
                formik.touched.weight_value && formik.errors.weight_value
              )}
              helperText={formik.touched.weight_value && formik.errors.weight_value}
              margin='normal'
              fullWidth
              name='weight_value'
              onBlur={formik.handleBlur}
              dataType='decimal'
              onChange={formik.handleChange}
              type='text'
              value={formik.values.weight_value}
              variant='outlined'
              size='small'
              placeholder={t('addProduct.enterWeight')}
              InputProps={{
                maxLength: 10
              }}
              isRequired
            />
            <MuiRadio
              name='weight'
              style={{ marginLeft: '5px', marginTop: '-5px' }}
              options={[{ value: 'gm', label: 'gm' }, { value: 'kg', label: 'kg' }]}
              onChange={(e, selectData) => setWeightUnit(e.target.value, selectData)}
              defaultValue={weightUnit}
              size='small'
            />
          </FormControlCol3>
          <FormControlInput>
            <FormControlCol3>
              <MuiTextField
                title={t('addProduct.length')}
                error={Boolean(
                  formik.touched.dimension_length && formik.errors.dimension_length
                )}
                helperText={formik.touched.dimension_length && formik.errors.dimension_length}
                fullWidth
                margin='normal'
                name='dimension_length'
                onBlur={formik.handleBlur}
                dataType='number'
                onChange={formik.handleChange}
                type='text'
                value={formik.values.dimension_length}
                variant='outlined'
                size='small'
                placeholder='L'
                InputProps={{
                  maxLength: 10,
                  endAdornment: (<InputAdornments position='end'>cm</InputAdornments>)
                }}
                isRequired
              />
            </FormControlCol3>

            <FormControlCol3>
              <MuiTextField
                title={t('addProduct.height')}
                error={Boolean(
                  formik.touched.dimension_height && formik.errors.dimension_height
                )}
                fullWidth
                helperText={formik.touched.dimension_height && formik.errors.dimension_height}
                margin='normal'
                name='dimension_height'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                dataType='number'
                type='text'
                value={formik.values.dimension_height}
                variant='outlined'
                size='small'
                placeholder='H'
                InputProps={{
                  maxLength: 10,
                  endAdornment: (<InputAdornments position='end'>cm</InputAdornments>)
                }}
                isRequired
              />
            </FormControlCol3>

            <FormControlCol3>
              <MuiTextField
                title={t('addProduct.width')}
                error={Boolean(
                  formik.touched.dimension_width && formik.errors.dimension_width
                )}
                fullWidth
                helperText={formik.touched.dimension_width && formik.errors.dimension_width}
                margin='normal'
                name='dimension_width'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                dataType='number'
                type='text'
                value={formik.values.dimension_width}
                variant='outlined'
                size='small'
                placeholder='W'
                InputProps={{
                  maxLength: 10,
                  endAdornment: (<InputAdornments position='end'>cm</InputAdornments>)
                }}
                isRequired
              />
            </FormControlCol3>

          </FormControlInput>

          <FormControlCol3>
            <MuiRadio title={t('addProduct.shippingFee')} />
            <FormControlSwitchWrap>
              <MuiRadio value={(formik.values.fee_type === 'Fixed') ? 'Regular' : 'Economical'} error={formik.errors.fee_type} name='fee_type' options={[{ value: 'Regular', label: t('addProduct.regular') }, { value: 'Economical', label: t('addProduct.economical') }]} onChange={handleFeeTypeChange} />
              <MuiSwitch value={formik.values.fee_shippingCovered} error={formik.errors.fee_shippingCovered} name='fee_shippingCovered' type='ios' label={t('addProduct.iWillCoverText')} onChange={handleCoverShippingFeeChange} />
            </FormControlSwitchWrap>
          </FormControlCol3>

          <FormControlCol3>
            <MuiRadio value={(formik.values.shipping_isMustInsurance) ? 'Mandatory' : 'Optional'} title={t('addProduct.shippingIns')} error={formik.errors.shipping_isMustInsurance} name='shipping_isMustInsurance' options={[{ value: 'Mandatory', label: t('addProduct.mandatory') }, { value: 'Optional', label: t('addProduct.optional') }]} onChange={handleIsMustInsuranceChange} />
          </FormControlCol3>

        </SectionContent>
      </ProductWrapper>

      <ProductWrapper>
        <SectionContent>
          <FormControl>
            <SectionTitle>{t('addProduct.others')}</SectionTitle>
          </FormControl>
        </SectionContent>

        <SectionContent>
          <FormControlCol3>
            <MuiSwitch value={formik.values.others_preOrder} error={formik.errors.others_preOrder} name='others_preOrder' type='ios' label={t('addProduct.enablePreOrder')} onChange={handlePreOrderChange} />
            {formik.values.others_preOrder &&
              <MuiTextField
                error={Boolean(
                  formik.touched.others_days && formik.errors.others_days
                )}
                helperText={formik.touched.others_days && formik.errors.others_days}
                fullWidth
                margin='normal'
                name='others_days'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type='text'
                value={formik.values.others_days}
                variant='outlined'
                size='small'
                placeholder={t('addProduct.enterDays')}
              />}
          </FormControlCol3>

        </SectionContent>
      </ProductWrapper>

      <ProductWrapper>
        <SectionContent>
          <SubmitWrapper>
            <div />
            <Box>
              <BackButton minwidth='true' color='secondary' variant='outlined' to={ROUTE_PRODUCTS}>{t('addProduct.back')}</BackButton>
              <MuiButton minwidth='true' color='primary' variant='outlined' onClick={() => saveAsDraft()} sx={{ mr: 2 }}>{t('addProduct.saveAsDeList')}</MuiButton>
              {isStorePresent && mode === 'create' && <MuiButton minwidth='true' styled='primary' variant='contained' type='submit'>{t('addProduct.submit')}</MuiButton>}
              {mode === 'edit' && <MuiButton minwidth='true' styled='primary' variant='contained' type='submit'>{t('addProduct.update')}</MuiButton>}
            </Box>
          </SubmitWrapper>
        </SectionContent>
      </ProductWrapper>
    </form>
  )
}

export default ProdCreate
