import { call, put, takeLatest } from 'redux-saga/effects'
import {
    GET_REVENUE_DATA,
    GET_REVENUE_DATA_SUCCESS,
    GET_REVENUE_DATA_FAILURE,
    GET_SKU_DATA,
    GET_SKU_DATA_SUCCESS,
    GET_SKU_DATA_FAILURE,
    GET_PROVINCE_DATA,
    GET_PROVINCE_DATA_SUCCESS,
    GET_PROVINCE_DATA_FAILURE,
    GET_CATEGORY_REVENUE,
    GET_CATEGORY_REVENUE_SUCCESS,
    GET_CATEGORY_REVENUE_FAILURE,
    GET_PRODUCT_DATA,
    GET_PRODUCT_DATA_SUCCESS,
    GET_PRODUCT_DATA_FAILURE,
} from '../actions'
import { BrandManagementPostApi } from '../utilities/axios';
import {
    GET_REVENUE_GRAPH,
    GET_SKU_REVENUE_GRAPH,
    GET_PROVINCE_REVENUE_GRAPH,
    GET_CATEGORY_REVENUE_GRAPH,
    GET_PRODUCT_GRAPH
} from '../constants';

function* getRevenue(action) {
    try {
        const { brandId, data } = action?.payload;
        const response = yield call(BrandManagementPostApi, GET_REVENUE_GRAPH(brandId), data, {}, false);
        yield put({ type: GET_REVENUE_DATA_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: GET_REVENUE_DATA_FAILURE, message: error.message })
    }
}

function* getSkuData(action) {
    try {
        const { brandId, data } = action?.payload;
        const response = yield call(BrandManagementPostApi, GET_SKU_REVENUE_GRAPH(brandId), data, {}, false);
        yield put({ type: GET_SKU_DATA_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: GET_SKU_DATA_FAILURE, message: error.message })
    }
}

function* getProvinceData(action) {
    try {
        const { brandId, data } = action?.payload;
        const response = yield call(BrandManagementPostApi, GET_PROVINCE_REVENUE_GRAPH(brandId), data, {}, false);
        yield put({ type: GET_PROVINCE_DATA_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: GET_PROVINCE_DATA_FAILURE, message: error.message })
    }
}

function* getCategoryData(action) {
    try {
        const { brandId, data } = action?.payload;
        const response = yield call(BrandManagementPostApi,GET_CATEGORY_REVENUE_GRAPH(brandId), data, {}, false);
        yield put({ type: GET_CATEGORY_REVENUE_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: GET_CATEGORY_REVENUE_FAILURE, message: error.message })
    }
}

function* getProductData(action) {
    try {
        const { brandId, data } = action?.payload;
        const response = yield call(BrandManagementPostApi, GET_PRODUCT_GRAPH(brandId), data, {}, false);
        yield put({ type: GET_PRODUCT_DATA_SUCCESS, data: response?.data?.data || response.data || {} });
    } catch (error) {
        yield put({ type: GET_PRODUCT_DATA_FAILURE, message: error.message })
    }
}


function* createSaga() {
    yield takeLatest(GET_REVENUE_DATA, getRevenue);
    yield takeLatest(GET_SKU_DATA, getSkuData);
    yield takeLatest(GET_PROVINCE_DATA, getProvinceData);
    yield takeLatest(GET_CATEGORY_REVENUE, getCategoryData);
    yield takeLatest(GET_PRODUCT_DATA, getProductData);
}

export default createSaga;