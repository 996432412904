import { useTranslation } from 'react-i18next'

import {
  OrderInfoWrapper,
  SummaryWrapper,
  InfoGrid,
  NameWrapper,
  PhoneEmailWrapper,
  OrderAddress
} from './style'

const OrderInfo = ({
  name,
  email = '',
  address,
  phone = ''
}) => {
  const { t } = useTranslation()

  return (
    <OrderInfoWrapper>
      <SummaryWrapper>
        {t('orderDetails.orderSummary')}
      </SummaryWrapper>
      <InfoGrid>
        <NameWrapper>
          {name}
        </NameWrapper>
        <PhoneEmailWrapper>
          {phone && <>| {phone}</>}
        </PhoneEmailWrapper>
        <PhoneEmailWrapper>
          {email && <>| {email}</>}
        </PhoneEmailWrapper>
      </InfoGrid>
      <OrderAddress>
        {address}
      </OrderAddress>
    </OrderInfoWrapper>
  )
}

export default OrderInfo
