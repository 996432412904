import { call, put, takeLatest } from 'redux-saga/effects'
import { messageStrToArray } from '../utilities/app'
import { productEditData, productAddData } from './__mock__'

import {
  PRODUCTS_LOADING,
  PRODUCTS_GET_LIST,
  PRODUCTS_GET_LIST_FAILURE,
  PRODUCTS_GET_LIST_SUCCESS,
  PRODUCTS_CREATE_LOADING,
  PRODUCTS_CREATE_POST_DATA,
  // PRODUCTS_CREATE_POST_DATA_FAILURE,
  PRODUCTS_CREATE_POST_DATA_SUCCESS,
  PRODUCTS_CREATE_INFO_LOADING,
  PRODUCTS_CREATE_INFO_POST_DATA,
  PRODUCTS_CREATE_INFO_POST_DATA_FAILURE,
  PRODUCTS_CREATE_INFO_POST_DATA_SUCCESS,
  PRODUCTS_EDIT_LOADING,
  PRODUCTS_EDIT_GET_DATA,
  PRODUCTS_EDIT_GET_DATA_FAILURE,
  PRODUCTS_EDIT_GET_DATA_SUCCESS,
  PRODUCTS_UPDATE_LOADING,
  PRODUCTS_UPDATE_SET_DATA,
  PRODUCTS_UPDATE_SET_DATA_FAILURE,
  PRODUCTS_UPDATE_SET_DATA_SUCCESS,
  PRODUCTS_DELETE_LOADING,
  PRODUCTS_DELETE_DATA,
  PRODUCTS_DELETE_DATA_FAILURE,
  PRODUCTS_DELETE_DATA_SUCCESS,
  RAENA_PRODUCTS_LOADING,
  RAENA_PRODUCTS_GET_LIST,
  RAENA_PRODUCTS_GET_LIST_FAILURE,
  RAENA_PRODUCTS_GET_LIST_SUCCESS,
  PRODUCTS_ALL_RECORDS,
  PRODUCTS_ALL_RECORDS_GN,
  PRODUCTS_ALL_RECORDS_LOADING,
  ADD_TO_STORE_RAENA_PRODUCTS_LOADING,
  ADD_TO_STORE_RAENA_PRODUCTS_EXECUTE,
  ADD_TO_STORE_RAENA_PRODUCTS_FAILURE,
  ADD_TO_STORE_RAENA_PRODUCTS_SUCCESS,
  UPDATE_PRODUCT_FULLFILL_VIA,
  UPDATE_PRODUCT_FULLFILL_VIA_LOADING,
  UPDATE_PRODUCT_FULLFILL_VIA_FAILURE,
  UPDATE_PRODUCT_FULLFILL_VIA_SUCCESS
} from '../actions'
import {
  API_GET_PRODUCTS,
  API_CREATE_PRODUCTS,
  API_CREATE_INFO_PRODUCTS,
  API_EDIT_PRODUCTS,
  API_UPDATE_PRODUCTS,
  API_DELETE_PRODUCTS,
  API_PRODUCTS_ALL_RECORDS,
  ADD_TO_STORE_RAENA_PRODUCTS,
  API_PATCH_PRODUCTS_FULLFIL_VIA
} from '../constants'
import { getApi, postApi, putApi, patchApi, deleteApi } from '../utilities/axios'
import getProductsQuery from '../graphql/fetchRaenaProducts'
import { toast } from 'react-toastify'
import { add, format } from 'date-fns'

function * onAddToStoreRaenaProduct (action) {
  const { data, history, setTab } = action?.payload
  yield put({ type: ADD_TO_STORE_RAENA_PRODUCTS_LOADING, isLoading: true })
  try {
    const query = {
      sku: data?.sku,
      name: data?.name,
      raenaProductId: data?.id,
      raenaCategoryId: data?.category?.id,
      shortDescription: data?.name,
      description: data?.description,
      images: data?.productImages ? data?.productImages?.map((item, i) => ({ key: item?.url, priority: i })) : [],
      brand: data?.brand,
      productSource: 'Raena',
      fullFillVia: 'Raena',
      pricing: {
        price: data?.retailPrice
      },
      stock: {
        available: data?.stockLimit - data?.reserveStock
      },
      type: 'New',
      details: {
        storageTime: '24 Month',
        expirationDate: format(add(new Date(), { years: 3 }), 'yyyy-MM-dd')
      },
      shipping: {
        weight: {
          value: data?.weight,
          unit: 'kg'
        },
        dimension: {
          length: data?.length,
          width: data?.width,
          height: data?.height
        },
        fee: {
          type: 'Fixed',
          shippingCovered: false
        }
      }
    }
    const response = yield call(postApi, ADD_TO_STORE_RAENA_PRODUCTS, query, {})
    yield put({ type: ADD_TO_STORE_RAENA_PRODUCTS_SUCCESS, data: response?.data?.data || response.data || {} })
    // window.location.replace(`/products?q=${data?.name}`)
    history(`/products?q=${encodeURIComponent(data?.name)}&productCatalog=${true}`)
    toast.success('Product is added successfully', { autoClose: 5000 })
    setTab('')
  } catch (e) {
    yield put({ type: ADD_TO_STORE_RAENA_PRODUCTS_FAILURE, message: e.message })
    toast.error(messageStrToArray(e?.response?.data?.message))
    // Todo: Find a better way of resetting the error message
    yield put({ type: ADD_TO_STORE_RAENA_PRODUCTS_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: ADD_TO_STORE_RAENA_PRODUCTS_SUCCESS, data: {} })
  }
  yield put({ type: ADD_TO_STORE_RAENA_PRODUCTS_LOADING, isLoading: false })
}

function * onGetRaenaProductList (action) {
  const { limit = 10, offSet = 0, searchTerm = '' } = action?.payload
  yield put({ type: RAENA_PRODUCTS_LOADING, isLoading: true })
  try {
    const query = { query: getProductsQuery(offSet, limit, searchTerm) }
    const response = yield call(postApi, '/products', query, {}, true)
    yield put({ type: RAENA_PRODUCTS_GET_LIST_SUCCESS, data: response?.data?.data || response.data || {} })
  } catch (e) {
    yield put({ type: RAENA_PRODUCTS_GET_LIST_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: RAENA_PRODUCTS_GET_LIST_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: RAENA_PRODUCTS_GET_LIST_SUCCESS, data: {} })
  }
  yield put({ type: RAENA_PRODUCTS_LOADING, isLoading: false })
}

function * onGetProductList (action) {
  try {
    const { limit, q, status, page, storeId } = action?.payload
    // console.log(action, 'action')
    yield put({ type: PRODUCTS_LOADING, isLoading: true })
    const query = {
      limit,
      stores: storeId || null,
      page,
      q: q || null,
      // store_id: action?.payload.store_id || null,
      status: status || null
    }
    const response = yield call(getApi, API_GET_PRODUCTS, query)
    yield put({ type: PRODUCTS_GET_LIST_SUCCESS, data: response?.data?.data || response.data || {} })
  } catch (e) {
    console.log(e)
    yield put({ type: PRODUCTS_GET_LIST_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: PRODUCTS_GET_LIST_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: PRODUCTS_GET_LIST_SUCCESS, data: {} })
  }
  yield put({ type: PRODUCTS_LOADING, isLoading: false })
}

function * onFetchProductsTotalRecords (action) {
  const { limit } = action?.payload
  yield put({ type: PRODUCTS_ALL_RECORDS_LOADING, productAllRecordLoading: true })
  try {
    const query = { limit }
    const response = yield call(getApi, API_PRODUCTS_ALL_RECORDS, query)
    yield put({ type: PRODUCTS_ALL_RECORDS, data: response?.data.data || {} })
  } catch (e) {
  }
  yield put({ type: PRODUCTS_ALL_RECORDS_LOADING, productAllRecordLoading: false })
}

function * onGetProductCreateData (action) {
  const successMsg = (action.payload.submitType === 'submit')
    ? 'Your Product is added successfully. Please add your Store to publish the Product'
    : 'Your Product is Delisted successfully'

  delete action.payload.submitType

  yield put({ type: PRODUCTS_CREATE_LOADING, isLoading: true })
  try {
    const query = action.payload || productAddData || null
    const response = yield call(postApi, API_CREATE_PRODUCTS, query)
    yield put({ type: PRODUCTS_CREATE_POST_DATA_SUCCESS, data: response?.data?.data || {} })
    toast.success(successMsg)
  } catch (e) {
    const { message } = e?.response?.data
    if (message.includes('same SKU against the user')) {
      toast.error('The SKU ID already exist. Please enter an different SKU Id for the product')
    } else toast.error(message)

    yield put({ type: PRODUCTS_CREATE_POST_DATA_SUCCESS, data: {} })
  }
  yield put({ type: PRODUCTS_CREATE_LOADING, isLoading: false })
}

function * onGetProductCreateInfoData (action) {
  yield put({ type: PRODUCTS_CREATE_INFO_LOADING, isLoading: true })
  try {
    const query = action.payload || productAddData || null
    query?.products.map(p => {
      if (p.attributes.length === 0) delete p.attributes
      return p
    })
    const response = yield call(postApi, API_CREATE_INFO_PRODUCTS, query)
    yield put({ type: PRODUCTS_CREATE_INFO_POST_DATA_SUCCESS, data: response?.data?.data || {} })
    toast.info('Product addition to store in progress. This list will get refreshed after 3 minutes.')
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PRODUCTS_CREATE_INFO_POST_DATA_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: PRODUCTS_CREATE_INFO_POST_DATA_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: PRODUCTS_CREATE_INFO_POST_DATA_SUCCESS, data: {} })
  }
  yield put({ type: PRODUCTS_CREATE_INFO_LOADING, isLoading: false })
}

function * onGetProductEditData (action) {
  // yield put({ type: PRODUCTS_EDIT_GET_DATA_SUCCESS, data: productEditData })

  yield put({ type: PRODUCTS_EDIT_LOADING, isLoading: true })
  try {
    const query = {
      rsellViewId: action?.payload?.rsellViewId || null
    }
    const response = yield call(getApi, API_EDIT_PRODUCTS(query?.rsellViewId))
    yield put({ type: PRODUCTS_EDIT_GET_DATA_SUCCESS, data: response?.data?.data || {} })
  } catch (e) {
    yield put({ type: PRODUCTS_EDIT_GET_DATA_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: PRODUCTS_EDIT_GET_DATA_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: PRODUCTS_EDIT_GET_DATA_SUCCESS, data: productEditData || {} }) // USING MOCK DATA
  }
  yield put({ type: PRODUCTS_EDIT_LOADING, isLoading: false })
}

function * onGetProductUpdateData (action) {
  delete action?.payload?.data.submitType
  yield put({ type: PRODUCTS_UPDATE_LOADING, isLoading: true })
  try {
    const query = {
      rsellViewId: action?.payload?.rsellViewId || null,
      data: action?.payload?.data || null
    }
    const response = yield call(putApi, API_UPDATE_PRODUCTS(query?.rsellViewId), query?.data)
    yield put({ type: PRODUCTS_UPDATE_SET_DATA_SUCCESS, data: response?.data?.data || {} })
    toast.success('Product Updated')
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PRODUCTS_UPDATE_SET_DATA_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: PRODUCTS_UPDATE_SET_DATA_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: PRODUCTS_UPDATE_SET_DATA_SUCCESS, data: {} })
  }
  yield put({ type: PRODUCTS_UPDATE_LOADING, isLoading: false })
}

function * onGetProductDeleteData (action) {
  console.log('SAGA > onGetProductDeleteData ----- ', action)
  yield put({ type: PRODUCTS_DELETE_LOADING, productDeleteIsLoading: true })
  try {
    const response = yield call(deleteApi, API_DELETE_PRODUCTS(action?.payload?.id))
    yield put({ type: PRODUCTS_DELETE_DATA_SUCCESS, data: response?.data?.data || {} })
    toast.success('Product Deleted')
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: PRODUCTS_DELETE_DATA_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: PRODUCTS_DELETE_DATA_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: PRODUCTS_DELETE_DATA_SUCCESS, data: {} })
  }
  yield put({ type: PRODUCTS_DELETE_LOADING, productDeleteIsLoading: false })
}

function * onUpdateProductFullFilVia (action) {
  yield put({ type: UPDATE_PRODUCT_FULLFILL_VIA_LOADING, isLoadingUpdateProductFullFilVia: true })
  try {
    const { rsellViewId, fullFillVia } = action?.payload || null
    const query = { fullFillVia }

    const response = yield call(patchApi, API_PATCH_PRODUCTS_FULLFIL_VIA(rsellViewId), query)
    yield put({ type: UPDATE_PRODUCT_FULLFILL_VIA_SUCCESS, data: response?.data?.data || {} })
    toast.success('Product fullfil is set successfully.')
  } catch (e) {
    toast.error(messageStrToArray(e?.response?.data?.message))
    yield put({ type: UPDATE_PRODUCT_FULLFILL_VIA_FAILURE, message: e.message })
    // Todo: Find a better way of resetting the error message
    yield put({ type: UPDATE_PRODUCT_FULLFILL_VIA_FAILURE, message: '' })
    // This is temporary as BE is returning 404 error
    yield put({ type: UPDATE_PRODUCT_FULLFILL_VIA_SUCCESS, data: {} })
  }
  yield put({ type: UPDATE_PRODUCT_FULLFILL_VIA_LOADING, isLoadingUpdateProductFullFilVia: false })
}

function * productsSaga () {
  yield takeLatest(ADD_TO_STORE_RAENA_PRODUCTS_EXECUTE, onAddToStoreRaenaProduct)
  yield takeLatest(RAENA_PRODUCTS_GET_LIST, onGetRaenaProductList)
  yield takeLatest(PRODUCTS_GET_LIST, onGetProductList)
  yield takeLatest(PRODUCTS_CREATE_POST_DATA, onGetProductCreateData)
  yield takeLatest(PRODUCTS_CREATE_INFO_POST_DATA, onGetProductCreateInfoData)
  yield takeLatest(PRODUCTS_EDIT_GET_DATA, onGetProductEditData)
  yield takeLatest(PRODUCTS_UPDATE_SET_DATA, onGetProductUpdateData)
  yield takeLatest(PRODUCTS_DELETE_DATA, onGetProductDeleteData)
  yield takeLatest(PRODUCTS_ALL_RECORDS_GN, onFetchProductsTotalRecords)
  yield takeLatest(UPDATE_PRODUCT_FULLFILL_VIA, onUpdateProductFullFilVia)

  // onUpdateProductFullFilVia
}

export default productsSaga
