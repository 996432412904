import React from 'react'
import Layout from '../Layout'
import ProductHome from '../../components/ProductHome'
const ProductWraper = (props) => {
  return (
    <Layout>
      <ProductHome {...props} />
    </Layout>
  )
}
export default ProductWraper
