import { styled } from '@mui/system'
import { Box, InputAdornment, Typography } from '@mui/material'

const media = {
  xl: '@media(max-width: 2560px)',
  lg: '@media(max-width: 1500px)',
  md: '@media(max-width: 850px)',
  sm: '@media(max-width: 600px)'
}
export const PageBuilderWrapper = styled(Box)(props => `
  padding: 1rem 20px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(136, 150, 203, 0.121312);
  border-radius: 10px;
  margin-top: 20px;
  ${media.sm}{
    padding: 1rem 10px;
    margin-bottom:100px;
  }
`)

export const InputAdornments = styled(InputAdornment)(props => `
  opacity:.4;
  font-size:12px;
`)

export const CountContainer = styled(Box)(`
  display:flex;
  align-items:center;
  p{
    margin-left:10px;
  }
`)

export const SectionContent = styled(Box)(`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px){
    flex-direction: column;
    width: 100%; 
  }
`)

export const FormControl = styled(Box)(`
  width:100%;
  display: flex;
  flex-direction: column;
  padding-bottom:20px;
  @media (max-width: 1000px) {
    margin: .5rem 0;
  }
  @media (max-width: 600px) {
    padding: 0 10px;
  }
`)

export const FormHeading = styled(Typography)(`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #536082;
  width:100%;
  margin-bottom: 10px;
  ${media.sm}{
    color: #19284D;
  }
`)

export const UploadImageWrapper = styled(Box)(`
`)

export const ViewMoreWrapper = styled(Box)(`
  text-align: right;
`)

export const MobileShareButtons = styled(Box)(`
  display:none;
  ${media.sm}{
    display:block;
    position:absolute;
    bottom: 0;
    left: 0;
    width:100%;
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.2) !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`)

export const WebShareButtons = styled('div')(props => `
  ${media.sm}{
    display:none;
  }
`)

export const DivWraper = styled('div')(props => `
  width: auto;
  height: 45px;
  background: #F1F2F8;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #162E9A;
  font-size: 16px;
  font-weight: 500;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  a{
    color: #162E9A;
  }
`)
