import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DataGridWrapper, ViewDetailBtn, ViewDetailText, MspCellDiv, MspCellEditBtn } from './style'
import MuiDataGrid from '../MuiDataGrid'
import RenderCellWithToolTip from '../MuiDataGrid/RenderCellWithToolTip'

const editClickHandler = (params) => {
  params.colDef.setEditableCell(params.id)
  params.colDef.setEditableCellValue(params.row.msp)
}
const saveClickHandler = async (params) => {
 params.colDef.mspUpdatehandler(params.row.sku, params.colDef.editableCellValue);
}

const renderMspCell = (params) => {
  if(params.colDef.editableCell === params.id){
    return <MspCellDiv>
    <input type="number" disabled={params.colDef.configIsLoading} value={params.colDef.editableCellValue} onChange={(e)=>{
      if(e.target.value < 1 && e.target.value !== ''){
        params.colDef.setEditableCellValue(1)
      } else {
        params.colDef.setEditableCellValue(e.target.value)
      }
    }}/>
    {params.colDef.editableCell === params.id && <MspCellEditBtn style={{opacity:(params.colDef.configIsLoading || params.colDef.editableCellValue === '')? 0.2: 1}} onClick={()=>{
      if(params.colDef.editableCellValue !== ''){
        saveClickHandler(params)
      }
    }}>{params.colDef.t('pageBuilderLink.form.save')}
    </MspCellEditBtn>}
    </MspCellDiv>
  }

  return <MspCellDiv>
  <p>{params.value}</p>
  {params.colDef.editableCell !== params.id && <MspCellEditBtn onClick={()=>{
    editClickHandler(params)
  }}>{params.colDef.t('brandPromo.edit')}
  </MspCellEditBtn>}
  </MspCellDiv>
}

const columns = (t, editableCell,setEditableCell, editableCellValue, setEditableCellValue, mspUpdatehandler, configIsLoading, viewDetailHandler) => ([
  {
    field: 'sku',
    headerName: t('brandCatalouge.skuID'),
    selectable: false,
    editable: false,
    sortable: true
  },
  {
    field: 'name',
    headerName: t('addProduct.productName'),
    minWidth: 250,
    sortable: true,
    renderCell: RenderCellWithToolTip,
    flex: 1
  },
  {
    field: 'cogs',
    headerName: t('mspCompare.raenaCogs'),
    sortable: false,
    renderCell: RenderCellWithToolTip,
    minWidth: 40,
    valueGetter: (params) => params?.value ? 'RP ' + params?.value : "--",
    flex: 1
  },
  {
    field: 'msp',
    headerName: t('mspCompare.mspForComparison'),
    minWidth: 230,
    sortable: false,
    renderCell: renderMspCell,
    valueGetter: (params) => params?.value ? 'RP ' + params?.value : "--",
    editableCell: editableCell,
    setEditableCell: setEditableCell,
    setEditableCellValue: setEditableCellValue,
    editableCellValue: editableCellValue,
    mspUpdatehandler: mspUpdatehandler,
    configIsLoading: configIsLoading,
    t:t,
    flex: 1
  },
  {
    field: 'totalListingCount',
    headerName: t('mspCompare.noOfListing'),
    minWidth: 70,
    sortable: false,
    flex: 1
  },
  {
    field: 'none',
    headerName: '',
    minWidth: 70,
    sortable: false,
    flex: 1,
    renderCell: (params)=> {
      return <ViewDetailBtn onClick={()=>{
        viewDetailHandler(params.row.sku)
      }}>
        <ViewDetailText>{t('brandPromo.viewDetails')}</ViewDetailText>
      </ViewDetailBtn>
    }
  }
])

const loadingRow = (t) => ([
  { sku: 0, promoSkus: [{ name: t('brandCatalouge.loading'), sku: t('brandCatalouge.loading') }], type: t('brandCatalouge.loading'), discount: t('brandCatalouge.loading'), startDate: '', endDate: '' }
])

const DataTable = ({ loading, promoList, sortingHandler, pageChangehandler, totalPromo, features, mspUpdatehandler, configIsLoading, configUpdateSuccess }) => {
  const { t } = useTranslation()
  const navigate = useNavigate();

  const [editableCell,setEditableCell] = useState(null);
  const [editableCellValue, setEditableCellValue] = useState(0);

  const viewDetailHandler = (skuId) => {
    navigate(`/brands/msp-details/${skuId}`)
  }

  useEffect(()=>{
    if(configUpdateSuccess === true){
      setEditableCell(null);
    }
  },[configUpdateSuccess])

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5
    }
  }, [])

  const handleSortModelChange = React.useCallback(sortModel => {
    if (sortModel.length) {
      let field = null
      if (sortModel[0].field === 'name') {
        field = 'name'
      } else if (sortModel[0].field === 'sku') {
        field = 'sku'
      }

      if (field) {
        sortingHandler({
          sort: field,
          sortOrder: sortModel[0].sort.toUpperCase()
        })
      }
    } else {
      sortingHandler({})
    }
  }, [sortingHandler])

  return (
    <DataGridWrapper container>
      <MuiDataGrid
        loading={loading}
        getRowSpacing={getRowSpacing}
        rows={!loading ? promoList : loadingRow(t)}
        columns={columns(t, editableCell, setEditableCell,editableCellValue, setEditableCellValue, mspUpdatehandler,configIsLoading, viewDetailHandler,features)}
        pageSize={20}
        onPageChange={pageChangehandler}
        rowHeight={70}
        rowCount={totalPromo}
        onSortModelChange={handleSortModelChange}
        getRowId={(row) => row.sku}
      />
    </DataGridWrapper>
  )
}

DataTable.displayName = 'BrandPromoDataTableComponent'
export default DataTable
