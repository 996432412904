import React from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import './style.css'
import {
  HeaderWrapper,
  WelcomeMessage,
  HeaderImgWrapper,
  HeaderImg,
  LeftSideContentWrapper,
  DateRangeHeading
} from './style'

import graphicon from '../../../assets/images/graphicon.svg'

const Header = ({ value, setValue }) => {
  function BasicDateRangePicker () {
    return (
      <div>
        <DateRangeHeading>Date Range</DateRangeHeading>
        <DateRangePicker
          calendarAriaLabel='Toggle calendar'
          dayAriaLabel='Day'
          maxDate={new Date()}
          minDate={new Date('1/1/2000')}
          monthAriaLabel='Month'
          className='react-daterange-picker_2'
          nativeInputAriaLabel='Date'
          onChange={setValue}
          value={value}
          clearIcon={null}
          yearAriaLabel='Year'
        />
      </div>
    )
  }

  return (
    <>
      <HeaderWrapper>
        <LeftSideContentWrapper>
          <HeaderImgWrapper>
            <HeaderImg src={graphicon} alt='' />
          </HeaderImgWrapper>
          {/* <WelcomeMessage>Welcome John Doe</WelcomeMessage> */}
          <WelcomeMessage>Welcome {localStorage.userName}</WelcomeMessage>
          {/* <LastUpdatedHeaderTextWrapper>
            Updated at 12 Novenber 2018 - 12:00 PM
          </LastUpdatedHeaderTextWrapper> */}
        </LeftSideContentWrapper>
        <BasicDateRangePicker />
      </HeaderWrapper>
    </>
  )
}

export default Header
