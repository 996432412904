import { styled } from '@mui/system'
import { Box, Typography, ListItem } from '@mui/material'

export const PageBuilderWrapper = styled(Box)(props => `
  padding: 1rem 20px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(136, 150, 203, 0.121312);
  border-radius: 10px;
  margin-top: 20px;
  ${
    props?.type === 'edit' && `
      min-width: 500px;
      margin:0;
      box-shadow:none;
      border:0;
      padding:0;
      @media (max-width: 600px){
        min-width: auto;
        width: 100%;
      }
    `
  }
`)

export const ListWrapper = styled('div')(props => `
  margin:0;
  padding:0 0 50px 0;
  width: calc(100%);
  & > div {
    margin: 10px 0 0;
  }
`)

export const SectionContent = styled(Box)(`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px){
    flex-direction: column;
    width: 100%;
  }
`)

export const SectionContentTitle = styled(Box)(`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom:10px;
  & > a {
    color: #162E9A;
    text-decoration:underline;
    padding-top: 0;
    padding-bottom: 0;
  }
`)

export const FormLinkWrapper = styled(Box)(`
  width:calc(100% - 100px);
  display: flex;
  flex-direction: column;
`)

export const FormOuterControl = styled(Box)(`
  width:100%;
  display: flex;
`)

export const UploadImageWrapper = styled(Box)(`
  width:100px;
`)

export const DraggableContainerLoader = styled(Box)(`
  width:100vw;
  height:100vh;
  position:absolute;
  top:0;
  left:0;
  z-index:2;
  background:rgba(255, 255, 255, 0.2);
`)

export const FormHeading = styled(Typography)(`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #536082;
  width:100%;
  margin-bottom: 10px;
`)
