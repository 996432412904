import { styled } from '@mui/system'
import { Button as Btn } from '@mui/material'
import { Link as Lnk } from 'react-router-dom'
const media = {
  xl: '@media(max-width: 2560px)',
  lg: '@media(max-width: 1500px)',
  md: '@media(max-width: 850px)',
  sm: '@media(max-width: 600px)'
}
export const Button = styled(Btn)((props) => `
  box-sizing: border-box;
  width: fit-content;
  font-family:'Noto Sans';
  border-radius: ${props?.theme?.components?.MuiButton?.styleOverrides?.shape?.borderRadius || 4}px;
  ${(props?.color === 'secondary' && props?.variant === 'outlined') && `color: ${props?.theme?.palette?.secondary?.color}`};
  ${(props?.styled === 'NeedAnyHelp' && props?.variant === 'contained') && 'min-width: 200px; margin-right: 90px;background-color: #162E9A; font-weight: 500; :hover{background-color: #162E9A}'};
  ${(props?.styled === 'primary') && 'background-color: #162E9A; font-weight: 500;height: 40px;:hover{background-color: #162E9A}'};
  ${(props?.styled === 'pageBuilderAboutBlue') && 'background-color: #162E9A; font-weight: 500;width: 100%;height: 50px;border-radius: 0px;:hover{background-color: #162E9A}'};
  ${(props?.styled === 'pageBuilderAboutWhite') && 'background-color: #FFFFFF; color:#162E9A; font-weight: 600; font-size:16px;width: 100%;height: 40px;:hover{background-color: #FFFFFF}'};
  ${props?.minwidth === 'true' && 'min-width: 140px'};
  ${props?.fullwidth === 'true' && 'min-width: 100%'};
  text-transform: capitalize;
  ${props?.className === 'shadow' && 'box-shadow: 0px 2px 10px rgb(0 0 0 / 10%)'};
  @media (max-width: ${props?.theme?.breakpoints?.values?.md || 1000}px){
    ${props?.minwidth === 'true' && 'min-width: auto'};
  }
  ${media.sm}{
  width:${props?.styled === 'linkStoreRaenaProductMobile' && '80%'};
  }
`)

export const Link = styled(Lnk)(`
  text-decoration: none;
  & button {
    text-decoration: none;
  }
`)
