import styled from 'styled-components'

export const ModalWrapper = styled.div`
  display: ${props => props.showModal ? 'block' : 'none'};
`

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
`

export const Popup = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #FFFFFF;
  width: 40%;
  height: auto;
  padding: 2rem;
  border-radius: 10px;
  position: fixed;
  left: calc(100% - 70%);
  @media (max-width: 1200px) {
    width: 60%;
    left: calc(100% - 70%);
  }
  @media (max-width: 768px) {
    width: 62%;
    left: 11%;
  }
 
`
export const DivCloseBtn = styled.div``

export const PopupHeader = styled.div`
  font-family: Noto Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  height: 50px;
  width: 180px;
  border: 1px solid ${props => props.colorShade ? props.colorShade : '#F3571E'};
  box-sizing: border-box;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 10px 20px;
  cursor: pointer;
`

export const ButtonIcon = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 20px;
`

export const ButtonText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`
export const DivWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #D6DAEA;
  text-align: center;
  justify-content: center;
  border-top: none;
`
export const DivWComing = styled.div`
  padding: 15px 0px 0px 0px;
  border: 1px solid #D6DAEA;
  border-bottom: none;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
`
