import React from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TitleGrid, Title, ButtonGrid, TopButtonsGroup, CreateNewBtn, DownloadBtn } from './style'
import downloadIcon from '../../assets/images/downloadIcon.svg'

const TitleRow = ({ mspStoreTotal, downloadHandler }) => {
  const { t } = useTranslation()

  return (
    <TitleGrid container>
      <Grid item md={6} sm={12} xs={12}>
        <Title>{t('brandCatalouge.listing')}({mspStoreTotal})</Title>
      </Grid>
      <TopButtonsGroup item md={6} sm={12} xs={12}>
        <Grid container direction='row-reverse'>
        <ButtonGrid item md={4} sm={12} xs={12}>
        <CreateNewBtn onClick={()=>{
          downloadHandler();
        }}>
          <DownloadBtn>
          <img src={downloadIcon} alt={"downloadIcon"} height={14} style={{paddingRight:8}} /> 
          {t('mspCompare.download')}
          </DownloadBtn>
        </CreateNewBtn>
      </ButtonGrid>
        </Grid>
      </TopButtonsGroup>
    </TitleGrid>
  )
}

TitleRow.displayName = 'BrandPromoTitleRowComponent'
export default TitleRow
