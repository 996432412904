import { call, put, takeLatest } from 'redux-saga/effects'

import {
  BRAND_GET_LIST,
  BRAND_GET_LIST_SUCCESS,
  BRAND_GET_LIST_FAILURE,
  BRAND_GET_LIST_LOADING
} from '../actions'

import {
  API_GET_BRANDS_LIST
} from '../constants'

import { BrandManagementGetApi } from '../utilities/axios'

function * onGetBrandList (action) {
  yield put({ type: BRAND_GET_LIST_LOADING, isLoading: true })
  try {
    const response = yield call(BrandManagementGetApi, API_GET_BRANDS_LIST(action.payload.userId))
   //keeping it here for dev purposes will remove it later
    // const response = {
    //   data: {
    //     user: 'd37a7fb6-7647-4d68-a34c-873c4b371a2b',
    //     brands: [
    //       {
    //         id: 'f7001e0e-cf28-4010-a7e2-2c81cf18887c',
    //         name: 'Scarlet'
    //       },
    //       {
    //         id: 'd37a7fb6-7647-4d68-a34c-873c4b371a2b',
    //         name: 'Skintific'
    //       }
    //     ]
    //   }
    // }

    yield put({ type: BRAND_GET_LIST_SUCCESS, data: response?.data?.brands || [] })
  } catch (e) {
    yield put({ type: BRAND_GET_LIST_FAILURE, message: e?.response?.data.message || e.message })
  }
  yield put({ type: BRAND_GET_LIST_LOADING, isLoading: false })
}

function * brands () {
  yield takeLatest(BRAND_GET_LIST, onGetBrandList)
}

export default brands
