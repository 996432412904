import { List, Map } from 'immutable'

import {
  RAENA_PRODUCTS_LOADING,
  RAENA_PRODUCTS_GET_LIST_SUCCESS,
  RAENA_PRODUCTS_GET_LIST_FAILURE
} from '../actions'

const initialState = new Map({
  isLoading: false,
  error: '',
  totalRecords: 0,
  content: List([])
})

export const raenaProducts = (state = initialState, action) => {
  const {
    data,
    message,
    type,
    isLoading
  } = action
  switch (type) {
    case RAENA_PRODUCTS_LOADING:
      return state.set('isLoading', isLoading)
    case RAENA_PRODUCTS_GET_LIST_SUCCESS:
      return state.merge({
        totalRecords: data?.products?.totalCount || 0,
        content: List(data?.products?.item || []),
        isLoading: false
      })
    case RAENA_PRODUCTS_GET_LIST_FAILURE:
      return state.set('error', message)
    default:
      return state
  }
}
