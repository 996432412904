import React, { useState, useEffect } from 'react'
import Loading from '../LoadingSpinner'
// import CloseIcon from '@mui/icons-material/Close'
// import CancelOrder from '../OrdersActions/CancelOrder'
import { OrderCancelContainer, OrderShipContainer } from '../../containers/Orders'
// import { useTranslation } from 'react-i18next'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import Header from './Header'
import OrderInfo from './OrderInfo'
import OrderProducts from './OrderProducts'
import PaymentDetails from './PaymentDetails'
import LogisticDetails from './LogisticDetails'

import {
  OrderDetailsModalWrapper
} from './style'

const OrderDetailsModal = ({
  details,
  isLoading,
  error,
  storeID,
  onPostCancelOrder,
  orderParams,
  orderParamsError,
  orderParamsIsLoading,
  onPostShipOrder,
  setModalVisibility,
  ordersShipStatus,
  shipOrderError,
  isShipOrderLoading
}) => {
  // const { t } = useTranslation()
  const [showcancelOrderModal, setVisibilityCancelOrderModal] = useState(false)
  const [showShipOrderModal, setVisibilityShipOrderModal] = useState(false)

  useEffect(() => {
    if (details?.id) {
      EventEmitter.emit(Events.VIEW_ORDER_DETAIL, { order_id: details?.id })
    }
  }, [details?.id])
  const isShip = details?.actions?.includes('ship') || false
  const isCancel = details?.actions?.includes('cancel') || false

  if ((isLoading && !error) || !Object.keys(details).length) {
    return (
      <OrderDetailsModalWrapper>
        <Loading />
      </OrderDetailsModalWrapper>
    )
  }

  const onShipOrderClicked = () => {
    if (details?.id) {
      EventEmitter.emit(
        Events.ORDER_CLICK_CTA, {
          order_id: details?.id,
          status: 'processed'
        }
      )
    }

    setVisibilityShipOrderModal(true)
  }

  const onCancelOrderClicked = () => {
    if (details?.id) {
      EventEmitter.emit(
        Events.ORDER_CLICK_CTA, {
          order_id: details?.id,
          status: 'cancelled'
        }
      )
    }

    setVisibilityCancelOrderModal(true)
  }

  const getItemIDs = () => details.orderItems.map(({ itemId, modelId }) => ({ itemId, modelId }))

  return (
    <OrderDetailsModalWrapper>
      <Header
        orderNo={details?.marketPlaceOrderId}
        storeName={details?.marketPlaceName}
        status={details?.status}
        setModalVisibility={setModalVisibility}
      />
      <OrderInfo
        name={details?.customerFullName}
        address={details?.recipientFullAddress}
        email={details?.email || ''}
        phone={details?.phone || ''}
      />
      <OrderProducts
        items={details?.orderItems}
        amountPayable={details?.totalAmount || 0}
        shippingFee={details?.shippingFee || 0}
        voucher={details?.voucher || 0}
        totalAmount={details?.totalAmount || 0}
      />
      {details?.status !== 'CANCELLED' &&
        <LogisticDetails
          awbNumber={details?.awbNumber || '-'}
          threePLPartner={details?.shipping_carrier || '-'}
          url={details?.shippingLabelUrl}
          shippingType={details?.shippingType || 'Regular'}
          showShip={isShip}
          showCancel={isCancel}
          onClickShip={onShipOrderClicked}
          onClickCancel={onCancelOrderClicked}
        />}
      <PaymentDetails
        paymentMethod={details?.paymentMethod}
        tax={details?.tax || 0}
        commission={details?.commission || 0}
        shippingFee={details?.shippingFee || 0}
        totalAmount={details?.totalAmount || 0}
        orderIncome={details?.orderIncome || 0}
      />
      {showcancelOrderModal &&
        <OrderCancelContainer
          marketplaceStoreId={storeID}
          orderId={details?.marketPlaceOrderId}
          setShowModal={setVisibilityCancelOrderModal}
          itemIds={getItemIDs()}
          onPost={onPostCancelOrder}
        />}
      {showShipOrderModal &&
        <OrderShipContainer
          marketplaceStoreId={storeID}
          marketplaceOrderId={details?.marketPlaceOrderId}
          setShowModal={setVisibilityShipOrderModal}
          orderParams={orderParams}
          orderParamsError={orderParamsError}
          orderParamsIsLoading={orderParamsIsLoading}
          onPost={onPostShipOrder}
        />}
    </OrderDetailsModalWrapper>
  )
}

export default OrderDetailsModal
