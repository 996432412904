import styled from 'styled-components'

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
`

export const ModalHeader = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #360D20;
  margin-top:0;
`

export const StoreCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
  justify-content: space-around;
  margin-bottom: 20px;
  @media (max-width: 600px){
    max-width: 480px;
  }
`

export const StoreImgWrap = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  background: #f1f1f1;

  img {
    width: 60px;
    height: 60px;
  }
`

export const StoreContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left:30px ;

  div {
    margin-top:-3px;
    max-width: 120px;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #525665;
    text-overflow: ellipsis;
    word-wrap: break-word;
    margin: unset;

  }
`

export const StoreCard = styled.div`
  cursor:pointer;
  width: 250px;
  position:relative;
  display: flex;
  justify-content:space-between;
  border: solid 1px #333;
  border-radius: 5px;
  padding: .5rem;
  margin: 10px;
  ${props => props.selected ? 'border: 1px solid #000000;' : ''}
`
export const StoreSelectedWrap = styled.div`
  position:absolute;
  top:8px;
  left:8px;
  width:16px;
  height:16px;
  overflow:hidden;
  border:1px #333 solid;
  svg {
    width:100%;
    height:100%;
  }
`
