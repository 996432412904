/*
 * GraphQl query to fetch products from Raena
 */
const getProductsQuery = (offSet, limit, searchTerm) => (
    `query all_product {
        products (offset: ${offSet}, limit: ${limit}, warehouses: ["${process.env.REACT_APP_JAKARTA_WAREHOUSE_CODE}"], isDelisted: false, 
        hasProductImages: true, productClass: "Product", ${searchTerm && `searchTerm: "${searchTerm}"`} ) {
            item {
                sku,
                name,
                description,
                wholesalePrice,
                retailPrice,
                stockLimit,
                weight,
                height,
                width,
                length,
                maxPermissibleQty,
                category {
                    id,
                    name
                },
                brand {
                    name
                    id
                }
                productImages {
                    url,
                    position
                },
                thumbnailImages {
                    url,
                    position
                },
                class,
                reserveStock,
                id
            },
            totalCount
        }
    }
    `
)

export default getProductsQuery
