import React from 'react'

import Layout from '../Layout'
import ComingSoonComponent from '../../components/ComingSoon'

const ComingSoon = () => {
  return (
    <Layout>
      <ComingSoonComponent />
    </Layout>
  )
}
export default ComingSoon
