import { Map } from 'immutable'

import {
  BRAND_MSP_DETAIL_LOADING,
  BRAND_MSP_GET_DETAIL_FAILURE,
  BRAND_MSP_GET_DETAIL_SUCCESS,
  BRAND_MSP_DETAIL_EXPORT_LOADING,
  BRAND_MSP_DETAIL_EXPORT_SUCCESS,
  BRAND_MSP_DETAIL_EXPORT_FAILURE,
  BRAND_MSP_DETAIL_FILTERS_LOADING,
  BRAND_MSP_GET_DETAIL_FILTERS_SUCCESS,
  BRAND_MSP_GET_DETAIL_FILTERS_FAILURE,
  BRAND_MSP_GET_DETAIL_FILTERS_RESET
} from '../actions'

const initialState = new Map({
  mspDetailsIsloading: false,
  mspStoreList: [],
  totalMsp: 0,
  mspDetailsExportIsloading: false,
  mspDetailsFilterIsloading: false,
  filtersData: {}
})

export const brandMspDetails = (state = initialState, action) => {
  const {
    data,
    type,
    isLoading
  } = action
  switch (type) {
    case BRAND_MSP_DETAIL_LOADING:
      return state.set('mspDetailsIsloading', isLoading)
    case BRAND_MSP_GET_DETAIL_SUCCESS:
      return state.merge({
        mspStoreList: data?.data || [],
        mspStoreTotal: data?.total || 0
      })
    case BRAND_MSP_GET_DETAIL_FAILURE:
      return state.merge({
        mspStoreList: []
      })

      case BRAND_MSP_DETAIL_EXPORT_LOADING:
        return state.set('mspDetailsExportIsloading', isLoading)

    case BRAND_MSP_DETAIL_EXPORT_SUCCESS:
        if(action?.data?.url){
            window.location = action?.data?.url
        }
      return state;
    case BRAND_MSP_DETAIL_EXPORT_FAILURE:
      return state.merge({
        exportError: ""
      })

      case BRAND_MSP_DETAIL_FILTERS_LOADING:
        return state.set('mspDetailsFilterIsloading', isLoading)
      case BRAND_MSP_GET_DETAIL_FILTERS_SUCCESS:
        return state.merge({
          filtersData: data || {}
        });
      case BRAND_MSP_GET_DETAIL_FILTERS_FAILURE:
        return state.merge({
          filtersData: {}
        });
      case BRAND_MSP_GET_DETAIL_FILTERS_RESET:
        return state.merge({
          filtersData: {}
        });
        
    default:
      return state
  }
}
