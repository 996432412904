import React from 'react'
import TablePagination from '@mui/material/TablePagination'
import { useTranslation } from 'react-i18next'

const Pagination = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalRecords,
  rowsPerPageOptions
}) => {
  const { t } = useTranslation()

  const handleChangePage = (event, newPage) => {
    newPage === 1 ? setPage(page + 1) : setPage(page - 1)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  return (
    <TablePagination
      component='div'
      count={totalRecords}
      page={page}
      labelRowsPerPage={t('placeHolderText.rowsPerPage')}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      showFirstButton
      showLastButton
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions || [12, 24, 36, 48]}
    />
  )
}

export default Pagination
