export const API_GET_OTP = '/v1/auth/v1/generate-saas-otp'
export const API_VERIFY_OTP = '/v1/auth/v1/validate-saas-otp'
export const API_LOGIN = '/v1/auth/saas-login'
export const API_REFRESH_TOKEN = '/v1/auth/refresh'
export const API_FORGOT_PASSWORD = '/v1/auth/saas-forgot-password'
export const API_RESET_PASSWORD = '/v1/auth/saas-reset-password'

export const API_GET_ANALYTICS = '/api/v1/orders/analytics'
export const API_GET_ANALYTICSOSP_LIST = '/api/v1/orders/timeseries'
export const API_GET_ANALYTICS_ORDER_STATUS = '/api/v1/orders/trends'
export const API_GET_ANALYTICS_TOTAL_ORDER = '/api/v1/orders/total'
export const API_GET_ANALYTICS_REVENUE_SUMMARY = '/api/v1/orders/summary'
export const API_GET_ANALYTICS_RECENT_ORDERS = '/api/v1/orders/recent'

export const API_GET_STORES = '/api/v1/stores?limit=100'
export const API_GET_PRODUCTS = '/api/v1/products'
export const API_PRODUCTS_ALL_RECORDS = '/api/v1/products'
export const API_GET_PRODUCTS_LISTED_ON = id => `/api/v1/products/${id}`
export const API_PATCH_PRODUCTS_LISTED_ON = id => `/api/v1/products/price/${id}`
export const API_CREATE_PRODUCTS = '/api/v1/products'
export const API_CREATE_INFO_PRODUCTS = '/api/v1/products/sync/publish'
export const API_EDIT_PRODUCTS = id => `/api/v1/products/${id}`
export const API_UPDATE_PRODUCTS = id => `/api/v1/products/${id}`
export const API_DELETE_PRODUCTS = id => `/api/v1/products/${id}`
export const API_GET_MARKETPLACES = '/api/v1/marketplaces/'
export const API_MARKETPLACES_CONNECT_SHOPEE = `/api/v1/marketplaces/shopee/connect?callback_url=${window.location.origin}`
export const API_DISCONNECT_STORE = id => `/api/v1/stores/${id}/disconnect`
export const API_SYNC_PRODUCTS = id => `/api/v1/stores/${id}/inventories/sync`
export const API_SYNC_ORDERS = id => `/api/v1/orders/stores/${id}/sync`
export const API_STORE_RENAME = id => `/api/v1/stores/${id}`
export const API_GET_CATEGORIES = '/api/v1/categories'
export const API_GET_CATEGORY_ATTRIBUTES = '/api/v1/attributes'
export const API_GET_CATEGORY_BRANDS = '/api/v1/brands'
export const API_POST_PRODUCTS = '/api/v1/products'
export const API_PATCH_PRODUCTS_FULLFIL_VIA = (rSellViewID) => `/api/v1/products/fullFillVia/${rSellViewID}`
export const API_PRODUCT_IMAGE_UPLOAD_URL = '/api/v1/products/img/upload'
export const API_GET_ORDERS = '/api/v1/orders'
export const API_UPDATE_PARTIAL_FLAG = '/api/v1/orders/update-fulfil-partial-flag'
export const API_GET_ORDER_DETAILS = id => `/api/v1/orders/${id}`
export const API_CANCEL_ORDER = '/api/v1/orders/cancel'
export const API_SHIP_ORDER = '/api/v1/orders/ship'
export const API_GET_SHIP_PARAMS = '/api/v1/orders/shopee/shipping-parameters'
export const API_GET_ORDERS_STATUS = '/api/v1/orders/marketplace/status'
export const API_POST_IMAGES = '/api/v1/products/img/upload'
export const API_UPLOAD_S3_URL = '/v1/files/urlGenerator'
export const ADD_TO_STORE_RAENA_PRODUCTS = '/api/v1/products/raena'
export const API_GET_BRANDS_MANAGEMENT_CATEGORIES = brandId => `/rbms/api/v1/brand-catalogue/category/${brandId}`
export const API_GET_BRANDS_MANAGEMENT_PRODUCT_TYPE = brandId => `/rbms/api/v1/brand-catalogue/product-type/${brandId}`
export const API_GET_BRANDS_MANAGEMENT_CATALOUGE = brandId => `/rbms/api/v1/brand-catalogue/${brandId}`
export const API_GET_BRANDS_MANAGEMENT_PROMO_TYPES = brandId => `/rpms/api/v1/promo/get/promo-type/${brandId}`
export const API_GET_BRANDS_MANAGEMENT_SKU = brandId => `/rpms/api/v1/promo/get/sku/${brandId}`
export const API_GET_BRANDS_MANAGEMENT_MSP = brandId => `/rbms/api/v1/marketplace-compare/get/${brandId}`
export const API_BRANDS_MANAGEMENT_MSP_EXPORT = brandId => `/rbms/api/v1/marketplace-compare/export/${brandId}`
export const API_BRANDS_MANAGEMENT_MSP_CONFIG = (brandId, sku) => `/rbms/api/v1/marketplace-compare/patch/${brandId}/${sku}/config`
export const API_GET_BRANDS_MSP_DETAILS = (brandId, sku) => `/rbms/api/v1/marketplace-compare/get/${brandId}/${sku}`
export const API_GET_BRANDS_MSP_DETAILS_FILTERS = (brandId, sku) => `/rbms/api/v1/marketplace-compare/get/${brandId}/${sku}/filters`
export const API_GET_BRANDS_MSP_DETAILS_DOWNLOAD = (brandId, sku) => `/rbms/api/v1/marketplace-compare/export/${brandId}/${sku}`
export const API_GET_BRANDS_MANAGEMENT_PROMO = brandId => `/rpms/api/v1/promo/get/${brandId}`
export const API_GET_BRANDS_LIST = () => '/rbms/api/v1/brand-manager'
export const API_GET_SUBSCRIPTION = (userId) => `/rsms/api/v1/user-subscription/${userId}`
export const API_GET_SELECTED_STORES = id => `/api/v1/products/${id}/pre-publish`

export const API_CREATE_PAGEBUILDER_LINKS = id => `/api/v1/dsf/sellers/links/${id}`
export const API_GET_PAGEBUILDER_LINKS = id => `/api/v1/dsf/sellers/links/saas?resellerId=${id}`
export const API_CREATE_PAGEBUILDER_SECTIONS_REORDER = '/api/v1/dsf/sellers/sections/reorder'
export const API_CREATE_PAGEBUILDER_LINKS_REORDER = '/api/v1/dsf/sellers/links/reorder'
export const API_CREATE_PAGEBUILDER_ABOUT = '/api/v1/dsf/sellers/storeview'
export const API_FETCH_EARNINGS_VIEW = 'api/v1/dsf/sellers/earning/ledger'
export const API_FETCH_PAGEBUILDER_ABOUT = id => `/api/v1/dsf/sellers/storeview/${id}`
export const API_GET_PAGEBUILDER_RAENAPRODUCTS_RESELLER = id => `/api/v1/dsf/sellers/${id}/products`
export const API_GET_PAGEBUILDER_RAENAPRODUCTS_ALL = '/api/v1/dsf/products'
export const API_GET_PAGEBUILDER_RAENAPRODUCTS_UPDATE = '/api/v1/dsf/store/products/manage'
export const API_GET_PAGEBUILDER_RAENAPRODUCTS_DELETE = '/api/v1/dsf/store/products/manage/remove'

export const API_GET_USER_INFO = '/api/v1/accounts/me/'
export const API_UPDATE_USER_PROFILE_IMAGE = id => `/v1/users/${id}`
export const GET_SKU_API = id => `rbms/api/v1/brand-catalogue/${id}`
export const POST_CREATE_API = id => `rpms/api/v1/promo/create/${id}`
export const UPDATE_PROMO_API = (brandId, id) => `rpms/api/v1/promo/update/${brandId}/${id}`
export const UALIDATE_PROMO_API = (brandId, id) => `rpms/api/v1/promo/validate/${brandId}/${id}`
export const GET_PROVINCE = 'rbms/api/v1/brand-analytics/province'
export const GET_DASHBOARD_MONTH_RANGE = 'rbms/api/v1/brand-analytics/month-range'
export const GET_DASHBOARD_YEAR_SKU = 'rbms/api/v1/brand-analytics/year-range'
export const DASHBOARD_POST_SKU = brandId => `rbms/api/v1/brand-analytics/year-range/${brandId}`
export const TOTAL_UNITS_SOLD = brandId => `rbms/api/v1/brand-analytics/total-units-sold/${brandId}`
export const DAYS_REVENUE = brandId => `rbms/api/v1/brand-analytics/days-revenue/${brandId}`
export const MONTHLY_REVENUE = brandId => `rbms/api/v1/brand-analytics/month-revenue/${brandId}`
export const MONTH_SHIPPING_COST = brandId => `rbms/api/v1/brand-analytics/month-shipping-cost/${brandId}`
export const DAYS_UNITS_SOLD = brandId => `rbms/api/v1/brand-analytics/days-unit-sold/${brandId}`
export const GET_REVENUE_GRAPH = brandId => `rbms/api/v1/brand-analytics/revenue/${brandId}`
export const GET_SKU_REVENUE_GRAPH = brandId => `rbms/api/v1/brand-analytics/sku-revenue/${brandId}`
export const GET_PROVINCE_REVENUE_GRAPH = brandId => `rbms/api/v1/brand-analytics/province-revenue/${brandId}`
export const GET_CATEGORY_REVENUE_GRAPH = brandId => `rbms/api/v1/brand-analytics/category-revenue/${brandId}`
export const GET_PRODUCT_GRAPH = brandId => `rbms/api/v1/brand-analytics/product-type-revenue/${brandId}`
