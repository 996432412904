import React from 'react'
import { connect } from 'react-redux';
import Layout from '../Layout'
import BrandDashboardComponent from '../../components/BrandDashboard/BrandDashboard'
import {
  GET_DASHBOARD_PROVINCE, DAY_UNIT_SOLD, TOTAL_UNIT_SOLD, DAY_REVENUE, MONTH_REVENUE, MONTH_SHIPPING, GET_SKU,
  GET_REVENUE_DATA, GET_SKU_DATA, GET_PROVINCE_DATA, GET_PRODUCT_DATA, GET_CATEGORY_REVENUE
} from '../../actions'
const BrandDashboard = (props) => {
  return (
    <Layout>
      <BrandDashboardComponent {...props} />
    </Layout>
  )
}

const mapStateToProps = state => ({
  userName: state.users.get('name'),
  isLoading: state.dashboard.isLoading,
  isAPIError: state.dashboard.isAPIError,
  isAPISuccess: state.dashboard.isAPISuccess,
  provinces: state.dashboard.provinces,
  yearRange: state.dashboard.yearRange,
  monthRange: state.dashboard.monthRange,
  selectedBrand: state.brands.get('selectedBrand'),
  isDayUnit: state.dashboard.isDayUnit,
  isDayUnitSuccess: state.dashboard.isDayUnitSuccess,
  dayUnits: state.dashboard.dayUnits,
  isTotalUnit: state.dashboard.isTotalUnit,
  isTotalUnitSuccess: state.dashboard.isTotalUnitSuccess,
  totalUnits: state.dashboard.totalUnits,
  isDayRevenue: state.dashboard.isDayRevenue,
  isDayRevenueSuccess: state.dashboard.isDayRevenueSuccess,
  dayRevenue: state.dashboard.dayRevenue,
  isMonthRevenue: state.dashboard.isMonthRevenue,
  isMonthRevenueSuccess: state.dashboard.isMonthRevenueSuccess,
  monthRevenue: state.dashboard.monthRevenue,
  isMonthShipping: state.dashboard.isMonthShipping,
  isMonthShippingSuccess: state.dashboard.isMonthShippingSuccess,
  monthShipping: state.dashboard.monthShipping,
  isUnitsError: state.dashboard.isUnitsError,
  errorDetails: state.dashboard.errorDetails,
  skuDetails: state.createPromo.skuDetails,
  isSkuLoading: state.createPromo.isSkuLoading,
  isSkuLoaded: state.createPromo.isSkuLoaded,
  isRevenueLoading: state.brandData.isRevenueLoading,
  isRevenueLoaded: state.brandData.isRevenueLoaded,
  isRevenueError: state.brandData.isRevenueError,
  revenueDetails: state.brandData.revenueDetails,
  isSkuDataLoading: state.brandData.isSkuDataLoading,
  isSkuDataLoaded: state.brandData.isSkuDataLoaded,
  isSkuDataError: state.brandData.isSkuDataError,
  skuData: state.brandData.skuData,
  isProvinceLoading: state.brandData.isProvinceLoading,
  isProvinceLoaded: state.brandData.isProvinceLoaded,
  isProvinceError: state.brandData.isProvinceError,
  provinceData: state.brandData.provinceData,
  isCategoryLoading: state.brandData.isCategoryLoading,
  isCategoryLoaded: state.brandData.isCategoryLoaded,
  isCategoryError: state.brandData.isCategoryError,
  categoryData: state.brandData.categoryData,
  isProductLoading: state.brandData.isProductLoading,
  isProductLoaded: state.brandData.isProductLoaded,
  isProductError: state.brandData.isProductError,
  productData: state.brandData.productData
});

const mapDispatchToProps = dispatch => ({
  getDashboards: (brandId) => {
    dispatch({ type: GET_DASHBOARD_PROVINCE })
    dispatch({ type: GET_SKU, payload: brandId })
  },
  getMTD:(brandId, data) =>{
    dispatch({ type: TOTAL_UNIT_SOLD, payload: { brandId, data } })
    dispatch({ type: MONTH_REVENUE, payload: { brandId, data } })
    dispatch({ type: MONTH_SHIPPING, payload: { brandId, data } })
  },
  getUnits: (brandId, data) => {
    dispatch({ type: DAY_UNIT_SOLD, payload: { brandId, data } })
    dispatch({ type: DAY_REVENUE, payload: { brandId, data } })
  },
  getRevenueDetails: (brandId, data) => {
    dispatch({ type: GET_REVENUE_DATA, payload: { brandId, data } })
    dispatch({ type: GET_SKU_DATA, payload: { brandId, data } })
    dispatch({ type: GET_PROVINCE_DATA, payload: { brandId, data } })
    dispatch({ type: GET_CATEGORY_REVENUE, payload: { brandId, data } })
    dispatch({ type: GET_PRODUCT_DATA, payload: { brandId, data } })
  }
});

BrandDashboard.displayName = 'BrandDashboardContainer'
export default connect(mapStateToProps, mapDispatchToProps)(BrandDashboard)
