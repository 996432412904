import { styled } from '@mui/system'
import { Box, Grid } from '@mui/material'
import bannerImage from '../../assets/images/homepage/Mobile-BG.png'

const media = {
  xl: '@media(max-width: 2560px)',
  lg: '@media(max-width: 1500px)',
  md: '@media(max-width: 850px)',
  sm: '@media(max-width: 600px)',
  xs: '@media(max-width: 0px)'
}

export const BannerOuterWrapper = styled(Box)((props) => `
  background: url(${props.bgImage}) no-repeat;
  background-size: cover;
  background
  /* min-height:550px; */
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: left;
  background-position-x: center;
  background-position-y: center;
  ${media.xl}{
   height: 50vh;
  }
  ${media.lg}{
   height: auto;
   background-position-y: unset;
  }
  ${media.md}{
   height: auto;
   background-position-y: unset;
  } 
  ${media.sm}{
    background: url(${bannerImage}) no-repeat;
    background-position-x: center;
    width: 100%;
    height: 55vh;
    background-position-y: center;
    background-size: cover;
    justify-content: center;
  }  
`)

export const GridWraper = styled(Grid)(`
    display: flex;
    flex-direction: row;
    ${media.sm}{
    justify-content: center;
  }
`)
export const BannerInnerWrapper = styled(Box)(`
  padding: 100px 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:30%;
  ${media.md}{
  width: 38%;
  padding: 16px 10%;
  }
  ${media.sm}{
  width: 90%;
  padding: 10px 10%;
  height: 45vh;
  align-items: center;
  text-align: center;
  }
  & h2 {
    color: #fff;
    padding-bottom: 20px;
    text-align: center;
    ${media.sm}{
      padding-bottom: 10px;
  }
  }
  & h6 {
    padding-bottom: 20px;
  }
`)
