import { connect } from 'react-redux'

import Layout from '../Layout'
import ProductCreateComponent from '../../components/ProductCreate'

import {
  CATEGORIES_GET_LIST,
  CATEGORIES_BRANDS_GET_LIST,
  CATEGORIES_ATTRIBUTES_GET_LIST,
  CATEGORIES_DROPDOWN_GET_LIST,
  PRODUCTS_CREATE_POST_DATA,
  PRODUCTS_CREATE_INFO_POST_DATA,
  RESET_PRODUCT_CREATE_FORM,
  STORES_GET_LIST
} from '../../actions'

const ProductCreate = props => {
  return (
    <Layout>
      <ProductCreateComponent {...props} />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const {
    categories,
    stores,
    products
  } = state

  return {
    error: categories.get('error'),
    categoryIsLoading: categories.get('categoryIsLoading'),
    categoryList: categories.get('content').toJS(),
    // categoryBrandsError: categoriesBrands.get('error'),
    // categoryBrandsIsLoading: categoriesBrands.get('categoryBrandsIsLoading'),
    categoryBrandsList: categories.get('categoryBrandsList').toJS(),
    // categoryAttributesError: categoriesAttributes.get('error'),
    // categoryAttributesIsLoading: categoriesAttributes.get('categoryAttributesIsLoading'),
    categoryAttributesList: categories.get('categoryAttributesList').toJS(),
    storeError: stores.get('error'),
    storeIsLoading: stores.get('isLoading'),
    storeList: stores.get('content').toJS(),
    productCreateData: products.get('productCreateData')
  }
}

const mapDispatchToProps = dispatch => ({
  onGetCategoryBrandsList: (query) => {
    dispatch({ type: CATEGORIES_BRANDS_GET_LIST, payload: query })
  },
  onGetCategoryAttributesList: (query) => {
    dispatch({ type: CATEGORIES_ATTRIBUTES_GET_LIST, payload: query })
  },
  onGetCategoryList: (query) => {
    dispatch({ type: CATEGORIES_GET_LIST, payload: query })
  },
  onGetCategoryDropdownData: (query) => {
    dispatch({ type: CATEGORIES_DROPDOWN_GET_LIST, payload: query })
  },
  onGetProductCreateData: (query) => {
    dispatch({ type: PRODUCTS_CREATE_POST_DATA, payload: query })
  },
  onGetProductCreateInfoData: (query) => {
    dispatch({ type: PRODUCTS_CREATE_INFO_POST_DATA, payload: query })
  },
  onGetProductUpdateData: (query) => {
    dispatch({ type: PRODUCTS_CREATE_POST_DATA, payload: query })
  },
  onGetStoreList: () => {
    dispatch({ type: STORES_GET_LIST })
  },
  onProductFormUnmount: () => {
    dispatch({ type: RESET_PRODUCT_CREATE_FORM })
  }
})

ProductCreate.displayName = 'ProductCreateContainer'

export default connect(mapStateToProps, mapDispatchToProps)(ProductCreate)
