import logoTokopedia from '../../assets/images/homepage/logo-tokopedia.svg'
import logoBukalapak from '../../assets/images/homepage/logo-bukalapak.svg'
import logoLazada from '../../assets/images/homepage/logo-lazada.svg'
import logoJsCentral from '../../assets/images/homepage/logo-js-central.svg'
import logoBlibli from '../../assets/images/homepage/logo-blibli.svg'
import logoJdId from '../../assets/images/homepage/logo-jd.id.svg'

export const ConnectionModalData = {
  imagespath: [
    {
      title: 'JD Central',
      src: logoJsCentral,
      alt: 'JD Central'
    },
    {
      title: 'blibli',
      src: logoBlibli,
      alt: 'blibli'
    },
    {
      title: 'JD.ID',
      src: logoJdId,
      alt: 'JD.ID'
    },
    {
      title: 'Tokopedia',
      src: logoTokopedia,
      alt: 'Tokopedia',
      url: 'https://www.tokopedia.com/'
    },
    {
      title: 'Bukalapak',
      src: logoBukalapak,
      alt: 'Bukalapak'
    },
    {
      title: 'Lazada',
      src: logoLazada,
      alt: 'Lazada'
    }
  ]
}
