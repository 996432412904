import React, { useEffect, useState } from 'react';
import { IconButton, Table, TableRow, TableCell, TableContainer, TableBody } from '@mui/material';
import { OfferTitle, OfferAction, OfferField, MovHeader, IconContainer, ActionContainer, ErrorText, OfferBox, OfferContainer } from './styles';
import { useTranslation } from 'react-i18next';
import addSku from '../../assets/images/brandDashboard/addSku.svg';
import deletePromo from '../../assets/images/brandDashboard/deleteSku.svg';
import { removeElement, addMOV, checkIsValueExist, checkValuePercentage, showToast } from "../../utilities/utils";

const MOVComponent = (props) => {
    const { columns, dataModel, isUpdate, onMovUpdate, isDisable } = props;
    const { t } = useTranslation()
    const [list, setList] = useState(dataModel);
    const [isModified, setIsModified] = useState(false);


    useEffect(() => {
        setList(dataModel);
        onMovUpdate(1, dataModel);
    }, [isUpdate]);


    const handleQtyChange = (qty, index) => {
        list.map((model) => {
            const obj = model;
            obj.isQtyError = false;
            obj.isError = false;
            return { ...obj };
        })
        list[index].min = qty;
        list[index].isError = false;
        list[index].isQtyError = false;
        isDisable(false)
        if (checkValuePercentage(list, index, 'discount', 'min', true)) {
            list[index].isQtyError = true;
            isDisable(true)
        }
        setList(list);
        setIsModified(!isModified);
        onMovUpdate(1, list);
    }

    const handleDiscountChange = (discount, index) => {
        list.map((model) => {
            const obj = model;
            obj.isError = false;
            obj.isQtyError = false;
            return { ...obj };
        })
        isDisable(false)
        list[index].isError = false;
        if (discount < 1 || discount > 100) {
            list[index].isError = true;
            isDisable(true)
        }
        list[index].discount = discount;
        if (checkValuePercentage(list, index, 'min', 'discount', false)) {
            list[index].isError = true;
            isDisable(true)
        }
        setList(list);
        setIsModified(!isModified);
        onMovUpdate(1, list);
    }

    const handleDelete = (item, index) => {
        removeElement(list, index);
        list[list.length - 1].isAdd = true;
        list[list.length - 1].isDelete = true;

        if (index === 1) {
            list[list.length - 1].isDelete = false;
        }
        setList(list);
        setIsModified(!isModified);
        onMovUpdate(1, list);
    };

    const addNewRow = (item, index) => {
        if (!checkIsValueExist([...list], 'min', 'discount')) {
            showToast(t('errors.fillDetails'), 5000);
        } else {
            addMOV(list, '', '', true);
            list.map((model) => {
                const obj = model;
                obj.isAdd = false;
                obj.isDelete = false;
                return { ...obj };
            });
            list[list.length - 1].isAdd = true;
            list[list.length - 1].isDelete = true;
            setList(list);
            setIsModified(!isModified);
        }

    };

    const loadMOVRow = (item, index) => {
        return (
            <>
                <TableCell>
                    <OfferContainer>
                    <OfferField
                        type='number'
                        id='minQty'
                        value={item.min}
                        onChange={(e) => handleQtyChange(e.target.value, index)}
                        margin="normal"
                        variant="outlined"
                        placeholder='Rp'
                        width={'186px'}
                        error={item.isQtyError}
                        bottom={item.isError ? '48px': '0px'}
                    />
                    {item.isQtyError && <ErrorText error={true}>{t('errors.movError')}</ErrorText>}
                    </OfferContainer>
                    
                </TableCell>

                <TableCell>
                    <OfferContainer>
                    <OfferBox>
                        <OfferField
                            type='number'
                            id='discount'
                            value={item.discount}
                            onChange={(e) => handleDiscountChange(e.target.value, index)}
                            margin="normal"
                            variant="outlined"
                            placeholder='%'
                            width={'100px'}
                            error={item.isError}
                            bottom={item.isQtyError ? '48px': '0px'}
                        />
                        <OfferAction>
                            {item.isAdd && (<IconButton onClick={() => addNewRow(item, index)} sx={{ padding: '0px'}}>
                                <IconContainer container={true}>
                                    <ActionContainer src={addSku} alt='confirmPromoImg' />
                                </IconContainer></IconButton>)}
                            {item.isDelete && (<IconButton disableRipple={true} onClick={() => handleDelete(item, index)} sx={{ paddingLeft: '0px' }}> <IconContainer container={true} ><ActionContainer src={deletePromo} alt='confirmPromoImg' height={'34px'} width={'34px'} /></IconContainer> </IconButton>)}
                        </OfferAction>
                    </OfferBox>
                    {item.isError && <ErrorText error={true}>{t('errors.movError')}</ErrorText>}
                    </OfferContainer>
                </TableCell>

            </>
        )
    }


    return (
        <>
            <TableContainer style={{ background: 'transparent' }}>
                {
                    list.length > 0 && (
                        <Table size="small">
                            <MovHeader
                                sx={{
                                    background: '#E8EBEF',
                                    '& th:first-child': {
                                        borderRadius: '10px 0 0 10px'
                                    },
                                    '& th:last-child': {
                                        borderRadius: '0 10px 10px 0'
                                    }
                                }}
                            >
                                <TableRow

                                >
                                    {
                                        columns.map((item) => (<TableCell style={{ textTransform: 'none', letterSpacing: '0px' }} ><OfferTitle>{item}</OfferTitle></TableCell>))
                                    }
                                </TableRow>
                            </MovHeader>
                            <TableBody>
                                <>
                                    {
                                        list.map((item, index) => {
                                            return (
                                                <TableRow
                                                >
                                                {loadMOVRow(item, index)} 
                                                </TableRow>
                                            )
                                        })
                                    }
                                </>
                            </TableBody>
                        </Table>
                    )
                }
            </TableContainer>
        </>
    )
};

export default MOVComponent;