import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  NeedHelpOuterWrapper,
  NeedHelpWrapper,
  Image,
  ContentWrapper,
  Heading,
  SubHeading,
  LinkWrapper
} from './style'
import MuiButton from '../MuiButton'
import needHelpImage from '../../assets/images/need-help.png'

import EventEmitter, { Events } from '../../utilities/eventEmitter'

const NeedHelp = ({ data }) => {
  const { t } = useTranslation()

  const contactNowClick = () => {
    EventEmitter.emit(Events.CONTACT_NOW, { page: 'Connections' })
  }

  const initialData = {
    heading: data?.heading || t('connections.needAnyHelp'),
    subHeading: data?.subHeading || t('connections.portalLongQuestion'),
    image: data?.image || needHelpImage,
    cta: data?.cta || [
      {
        title: t('connections.contactNow'),
        url: 'https://www.raenabeauty.com/kontak-kami/'
      }
    ]
  }
  return (
    <NeedHelpOuterWrapper>
      <NeedHelpWrapper>
        {initialData.image && <Image src={initialData.image} alt={initialData.heading} />}
        <ContentWrapper>
          {initialData.heading && <Heading>{initialData.heading}</Heading>}
          {initialData.subHeading && <SubHeading>{initialData.subHeading}</SubHeading>}
        </ContentWrapper>
        {initialData.cta &&
          <LinkWrapper onClick={contactNowClick}>
            {initialData.cta.map(link => (<MuiButton styled='NeedAnyHelp' variant='contained' key={link.title} href={link.url} target='_blank'>{link.title}</MuiButton>))}
          </LinkWrapper>}
      </NeedHelpWrapper>
    </NeedHelpOuterWrapper>
  )
}

NeedHelp.propTypes = {
  data: PropTypes.object
}

NeedHelp.displayName = 'NeedHelp'

export default NeedHelp
